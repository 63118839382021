import { makeStyles, Switch as MUISwitch } from "@material-ui/core";
import React from "react";
import styles from "./switch.style";
import clsx from "clsx";

const Switch = ({ switchChange, disabled = false, ...props }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <MUISwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      onChange={(event: any) => !disabled && switchChange(event)}
      disabled={disabled}
      classes={{
        root: clsx(classes.root, disabled && classes.switchDisabled),
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled
      }}
      {...props}
    />
  );
};

export default Switch;
