import * as React from 'react';

function SvgCheckThick(props) {
  return (
    <svg
      width="24"
      height="15"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.00016 12.1701L4.83016 8.00009L3.41016 9.41009L9.00016 15.0001L21.0002 3.00009L19.5902 1.59009L9.00016 12.1701Z"
        fill="#4B1C46"
      />
    </svg>
  );
}

export default SvgCheckThick;
