import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  tableContainer: {
    display: "flex"
  },

  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    overflow: "auto",
    height: "100%",
    padding: "1rem",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px",
    marginTop: 0
  },

  table: {
    background: theme.palette.background.paper
  },
  overWritePadding: {
    ...theme.tables.tableCell,
    padding: "0 15px",
    height: "35px"
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  editIcon: {
    marginLeft: "-6px"
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial"
  },

  anomaliesContainer: {
    height: "82%",
    display: "flex",
    paddingBottom: "1rem"
  },

  titleBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  title: {
    height: "18px",
    width: "72px",
    color: "#312e34",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px"
  },
  "[class^=\"NavigationBar\"]": {
    padding: "0"
  },
  smallWidth: { width: "20%", wordBreak: "break-word" },
  mediumWidth: { width: "60%", wordBreak: "break-word" },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  iconColor: {
    color: "#AAA2AA"
  },
  disabledIcon: {
    color: "#d5d0d5"
  },

  headerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px"
  },
  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    height: "100%",
    alignSelf: "center"
  },
  pageContentBox: {
    maxHeight: "100%",
    overflow: "auto",
    maxWidth: "calc(100vw - 240px)",
    display: "flex",
    padding: "16px",
    height: "100%"
  },
  dialogStyle: {
    padding: "30px 26px 27px 26px",
    minWidth: "400px",
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  contentStyle: {
    paddingBottom: "22px",
    color: "#545964",
    fontWeight: "normal",
    lineHeight: "normal",
    fontSize: "18px",
    fontFamily: "Roboto",
    textAlign: "center",
    maxWidth: "100%",
    wordBreak: "break-word"
  },
  textNotCentered: {
    textAlign: "unset"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
    marginRight: "40px"
  },
  exportIcon: {
    paddingLeft: "5px"
  },
  importIcon: {
    paddingTop: "5px",
    marginLeft: "10px"
  },
  ImportExportContainer: {
    padding: "16px",
    marginRight: "16px"
  }
}));
export default useStyles;
