import React from "react";

const SvgArrowUp = (props) => (
  <svg width={36} height={14} {...props}>
    <path
      fill="none"
      stroke="#FFF"
      strokeLinecap="round"
      strokeWidth={1.459}
      d="M0 13.838L17.841 0l17.841 13.838"
    />
  </svg>
);

export default SvgArrowUp;
