import {
    CircularProgress,
    Dialog,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { SiteSubscriptions as sdkSiteSubscriptions } from "coolremote-sdk";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import Button from "../../../cool_widgets/Button";
import { useStoreState } from "../../../models/RootStore";
import useStyles from "./SiteSubscriptions.style.ts";

const SiteSubscriptions = (props: any) => {
    const {
        handleCancel,
        open,
        site
    } = props;

    const {
        id,
        permissions: {
            canAssignSiteSubscriptions
        },
        customerId
    } = site;

    const classes = useStyles();
    const [customerSubscriptions, setCustomerSubscriptions] = useState<any>({});
    const [assginPopupOpen, setAssginPopupOpen] = useState<boolean>(false);
    const [subscriptionIdToAssign, setSubscriptionIdToAssign] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { siteSubscriptionStatusEnums = {} } = useStoreState((state) => state.types);
    const sitePackageMirror = useStoreState((state) => state.sitePackageMirror);

    useEffect(() => {
        if (customerId) {
            setIsLoading(true);
            sdkSiteSubscriptions.getCustomerSiteSubscriptions(customerId)
                .then((res: any) => {
                    setCustomerSubscriptions(res);
                })
                .finally(() => setIsLoading(false))
        }
    }, [refresh]);

    const AssignSubscriptionToSite = () => {
        sdkSiteSubscriptions.assignSiteSubscriptionToSite({ subscriptionId: subscriptionIdToAssign, siteId: id })
            .finally(() => { setAssginPopupOpen(false); setRefresh(!refresh); });
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleCancel(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialog, paperWidthSm: isLoading ? "" : classes.dialogPaper }}
        >
            {isLoading
                ?
                <div className={classes.loaderContainer}>
                    <CircularProgress style={{ alignSelf: "center" }} />
                </div>
                :
                <>
                    <div className={classes.titleContent}>
                        <Typography className={classes.headerTitle}>{t`Subscriptions`}</Typography>
                        <IconButton disableRipple onClick={() => handleCancel(false)} className={classes.overWriteIcon}>
                            <Close />
                        </IconButton>
                    </div>
                    <div className={classes.dialogContent}>
                        <Typography className={clsx(classes.boldText, classes.DialogContentText)}>{t`Existing subscriptions`}</Typography>
                        {!_.isEmpty(customerSubscriptions) ?
                            <Paper elevation={0} style={{ height: "100%" }}>
                                <TableContainer className={classes.tableContainer}>
                                    <Table stickyHeader={true} className={classes.subscriptionsTable} aria-label="customized table">
                                        <TableHead>
                                            <TableRow className={classes.tableHeadRow}>
                                                <TableCell
                                                    classes={{ root: classes.tableHeadCell }}
                                                    align="left"
                                                >
                                                    {t`status`}
                                                </TableCell>
                                                <TableCell
                                                    classes={{ root: classes.tableHeadCell }}
                                                    align="left"
                                                >
                                                    {t`start time`}
                                                </TableCell>
                                                <TableCell
                                                    classes={{ root: classes.tableHeadCell }}
                                                    align="left"
                                                >
                                                    {t`end time`}
                                                </TableCell>
                                                <TableCell
                                                    classes={{ root: classes.tableHeadCell }}
                                                    align="left"
                                                >
                                                    {t`number of units`}
                                                </TableCell>
                                                <TableCell
                                                    classes={{ root: classes.tableHeadCell }}
                                                    align="left"
                                                >
                                                    {t`packages`}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.values(customerSubscriptions).map((subscription: any, index: number) => {
                                                const { status, createdAt, durationInMonth, numberOfUnits, packages, site } = subscription || {};
                                                if (site !== props.site.id) {
                                                    return null;
                                                }

                                                return (
                                                    <TableRow
                                                        hover={true}
                                                        tabIndex={-1}
                                                        key={index}
                                                        classes={{ root: classes.overWritePadding }}
                                                    >
                                                        <TableCell
                                                            align="left"
                                                        >
                                                            {siteSubscriptionStatusEnums[status]}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                        >
                                                            {moment(createdAt).format("llll")}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                        >
                                                            {moment(createdAt).add(durationInMonth, "M").format("llll")}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                        >
                                                            {numberOfUnits}
                                                        </TableCell>

                                                        <TableCell
                                                            align="left"
                                                        >
                                                            {packages.map((pkg: any, index: number) => {
                                                                return <span className={classes.daysItem} key={`pakcage-${pkg}`}> {packages.length === index + 1 ? sitePackageMirror[pkg] : `${sitePackageMirror[pkg]},`} </span>;
                                                            })}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper> :
                            <div className={classes.dialogContent}>
                                <Typography className={classes.DialogContentText}>{t`No Existing subscriptions`}</Typography>
                            </div>
                        }
                    </div>

                    <div className={classes.dialogContent} style={{ margin: "10px 0" }}>
                        <Typography className={clsx(classes.boldText, classes.DialogContentText)}>{t`Assign subscriptions`}</Typography>
                        {!_.isEmpty(customerSubscriptions) ? <Paper elevation={0} style={{ height: "100%" }}>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader={true} className={classes.subscriptionsTable} aria-label="customized table">
                                    <TableHead>
                                        <TableRow className={classes.tableHeadRow}>
                                            <TableCell
                                                classes={{ root: classes.tableHeadCell }}
                                                align="left"
                                            >
                                                {t`status`}
                                            </TableCell>
                                            <TableCell
                                                classes={{ root: classes.tableHeadCell }}
                                                align="left"
                                            >
                                                {t`number of units`}
                                            </TableCell>
                                            <TableCell
                                                classes={{ root: classes.tableHeadCell }}
                                                align="left"
                                            >
                                                {t`packages`}
                                            </TableCell>
                                            <TableCell
                                                classes={{ root: classes.tableHeadCell }}
                                                align="left"
                                            >

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(customerSubscriptions).map((subscription: any, index: number) => {
                                            const { id, status, numberOfUnits, packages, site } = subscription || {};
                                            if (!!site) {
                                                return null;
                                            }
                                            return (
                                                <TableRow
                                                    hover={true}
                                                    tabIndex={-1}
                                                    key={index}
                                                    classes={{ root: classes.overWritePadding }}
                                                >
                                                    <TableCell
                                                        align="left"
                                                    >
                                                        {siteSubscriptionStatusEnums[status]}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                    >
                                                        {numberOfUnits}
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                    >
                                                        {packages.map((pkg: any, index: number) => {
                                                            return <span className={classes.daysItem} key={`pakcage-${pkg}`}> {packages.length === index + 1 ? sitePackageMirror[pkg] : `${sitePackageMirror[pkg]},`} </span>;
                                                        })}
                                                    </TableCell>

                                                    <TableCell
                                                        align="right"
                                                    >
                                                        <Button
                                                            width={100}
                                                            onClick={() => { setAssginPopupOpen(true); setSubscriptionIdToAssign(id); }}
                                                            disabled={!canAssignSiteSubscriptions}
                                                        >
                                                            {t`Assign`}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper> :
                            <div className={classes.dialogContent}>
                                <Typography className={classes.DialogContentText}>{t`No subscriptions to assign`}</Typography>
                            </div>
                        }
                    </div>

                    <div className={classes.actionsHolder}>
                        <Button
                            className={classes.yesBtn}
                            onClick={() => handleCancel(false)}
                            white
                            width={130}
                            marginRight
                        >
                            {t`Cancel`}
                        </Button>
                    </div>

                    {assginPopupOpen && <ConfirmationDialog
                        onCancel={() => setAssginPopupOpen(false)}
                        onConfirm={AssignSubscriptionToSite}
                        onClose={() => setAssginPopupOpen(false)}
                        title={t`Assign subscription`}
                        text={t`Are you sure?`}
                        confirmLabel={t`Assign`}
                        cancelLabel={t`No`}
                        dialogType="normal"
                        openDialog={assginPopupOpen}
                    />}
                </>
            }
        </Dialog>
    );
};

export default SiteSubscriptions;
