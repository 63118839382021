import { createStyles } from "@material-ui/styles";

export const messageStyles = (theme: any) =>
  createStyles({
    dialogStyle: {
      padding: "30px 26px 27px 26px",
      minWidth: "400px",
      height: "auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    contentStyle: {
      paddingBottom: "22px",
      color: "#545964",
      fontWeight: "normal",
      lineHeight: "normal",
      fontSize: "18px",
      fontFamily: "Roboto",
      textAlign: "center",
      maxWidth: "100%",
      wordBreak: "break-word",
      whiteSpace: "pre-line"
    },
    textNotCentered: {
      textAlign: "unset"
    },
    btnsContainer: {
      width: "360px",
      justifyContent: "center",
      display: "flex"
    },
    moveToRight: {
      alignSelf: "end",
      justifyContent: "space-between"
    }
  });
