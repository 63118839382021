import React from "react";

function SvgExpandMore(props: any) {
    return (
        <svg width="1em" height="17px" viewBox="0 0 20 20" {...props}>
            <g fill="white" fillRule="evenodd">
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={.2}
                    d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                />
            </g>
        </svg>
    );
}

export default SvgExpandMore;
