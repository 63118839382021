import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    outline: 0
  },
  formWrapper: {
    paddingTop: 0
  },
  textField: {
    width: "90%",
    backgroundColor: "white",
    "& div>.MuiFilledInput-root": {
      backgroundColor: "#ffffff",
      border: "1px solid #d5d2d5"
    }
  },
  selectField: {
    width: "90%",
    textTransform: "capitalize"
  },
  MenuItem: {
    textTransform: "capitalize"
  },
  textFieldContent: {
    backgroundColor: "#ffffff"
  },

  registerDevice: {
    margin: "0",
    maxWidth: "100%"
  },
  title: {
    fontWeight: 700,
    marginBottom: "1rem"
  },

  divider: {
    margin: "1.5rem"
  },

  imgContainer: {
    display: "flex",
    justifyContent: "center",
    maxHeight: "430px",
    marginBottom: 10
  },
  deviceImg: {
    width: "100%",
    objectFit: "cover"
  },
  loadingModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  iconButton: {
    fontSize: "1rem"
  },
  lableStyle: {
    lineHeight: "unset",
  },
  checkBoxRed: {
    "& svg": {
      color: "red"
    }
  }
}));

export default useStyles;
