import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  powerGraphContainer: {
    display: "flex",
    marginTop: "10px",
    width: "100%",
    height: "100%",
    flexDirection: "column"
  },
  cardHeader: {
    background: theme.palette.background.default,
    height: "30px",
    alignItems: "center",
    display: "flex",
    padding: "0px 10px",
    margin: "0 5px 15px 5px",
    width: "340px"
  },
  cardTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.overrides.MuiButton.outlinedPrimary.color
  },
  graphContainer: {

  },
  doubleGraphCont: {
    display: "flex",
    width: "100%",
    flex: 1,
    height: "calc(100% - 15px)",
    paddingTop: "20px"
  },
  loaderHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  }
}));

export default useStyles;
