import React from "react";

const SvgAlert = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <defs>
      <path id="a" d="M0 .149h2.749v9.153H0z" />
      <path id="c" d="M.07.3h2.608v2.61H.069z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill={props.color || "#F05146"} fillRule="nonzero" d="M8.75 2.628L.828 17.778c-1.685 3.237.062 6.04 3.72 6.04h14.891c3.695.029 5.423-2.767 3.72-6.04L15.197 2.627c-1.72-3.26-4.742-3.26-6.448 0zm5.482.507l7.96 15.149c1.322 2.54.13 4.466-2.749 4.443H4.547c-2.845 0-4.055-1.941-2.751-4.445l7.92-15.149c1.298-2.48 3.206-2.48 4.516.002z" />
      <g transform="translate(10.91 7.273)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path fill={props.color || "#F05146"} d="M.007 1.626C-.07.891.477.232 1.233.156c.755-.077 1.43.459 1.509 1.195.01.09.008.188 0 .275L1.98 8.77a.604.604 0 0 1-.67.53.603.603 0 0 1-.543-.53l-.76-7.143z" mask="url(#b)" />
      </g>
      <g transform="translate(10.91 17.455)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path fill={props.color || "#F05146"} d="M1.373 2.91a1.304 1.304 0 1 1 0-2.609 1.304 1.304 0 0 1 0 2.608" mask="url(#d)" />
      </g>
    </g>
  </svg>
);

export default SvgAlert;
