import { alpha, makeStyles } from "@material-ui/core";

export default makeStyles((theme: any) => ({
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
  },
  view: {
    display: "flex",
    height: "100%",
  },
  sideMenuContent: {
    backgroundColor: "white",
    padding: "0 20px",
    paddingTop: 10,
    height: "100%",
  },

  SliderFont: {
    "& input": {
      fontSize: "8px",
    },
  },

  container: {
    padding: "18px",
    overflow: "auto",
    maxWidth: "100%",
    margin: 0,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "0.5rem",
    borderBottom: "2px solid rgba(60, 60, 60, 0.2)",
    margin: "1rem",
    zIndex: 100,
  },
  headerApplyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "0.5rem",
    borderBottom: "2px solid rgba(60, 60, 60, 0.2)",
    margin: "1rem",
  },
  unitContainer: {
    border: "1px solid rgba(60, 60, 60, 0.2)",
    height: "5rem",
    margin: "1rem",
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "1rem",
    boxShadow: "0 2px 0 -1px rgba(205, 205, 205, 0.5)",
  },
  unitsContainer: {
    overflow: "auto",
    height: "calc(100vh - 370px)",
  },
  selectedUnit: {
    backgroundColor: "#edebed",
  },
  modeHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  tempRangSlider: {
    marginBottom: "1rem",
  },

  restrictContainer: {
    marginTop: "1.75rem",
    marginRight: "2rem",
    borderBottom: "1px solid #e1e2ea",
    display: "flex",
    justifyContent: "space-between",
  },

  unitRestrictContainer: {
    marginBottom: "1.5rem",
    marginTop: 0,
  },
  modeTitle: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "2rem",
    "& >svg>path": {
      stroke: "#4b1c46 !important",
    },
  },

  unitModeTitle: {
    marginLeft: 0,
  },

  title: {
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: "1.3rem",
    display: "inline",
    marginLeft: ".5rem",
  },

  topTitle: {
    fontFamily: "RobotoMedium",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    marginBottom: "1.3rem",
    display: "inline",
    marginLeft: ".5rem",
  },

  unitTitle: {
    fontFamily: "RobotoMedium",
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
  },

  modesTitle: {
    // marginTop: "0.5rem"
  },

  modeImg: {
    marginBottom: "1.3rem",
    width: "26px",
    height: "26px",
    position: "absolute",
    top: "0",
    left: "0",
  },

  modeSlider: {
    marginBottom: "1.3rem",
  },
  modeContainer: {
    height: "172px",
    minHeight: "172px",
    padding: 20,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    borderRadius: 6,
    border: "solid 1px #d5d2d5",
  },

  modeContainerUnitSettings: {
    flex: 1,
  },
  switchTitle: {
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
  },

  modesContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    width: "97%",
  },

  saveActionBtn: {
    color: "#fff",
    height: "40px",
    fontSize: "16px",
    minWidth: "120px",
    // background: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    fontWeight: "normal",
    lineHeight: "normal",
    borderRadius: "4px",
    textTransform: "uppercase",
  },
  cancelActionBtn: {
    color: "#4b1c46",
    height: "40px",
    fontSize: "16px",
    minWidth: "120px",
    background: "#fff",
    fontWeight: 500,
    lineHeight: "normal",
    borderRadius: "4px",
    textTransform: "uppercase",
    border: "solid 1px #413044",
  },

  scheduleWrapper: {
    // marginTop: "3rem"
  },
  gridLeft: {
    // marginRight: "2rem"
    // height: "100%"
  },

  gridContainer: {
    display: "inline-block",
  },
  unitActions: {
    display: "flex",
    paddingBottom: 20,
    paddingTop: 10,
    justifyContent: "flex-end",
    alignItems: "center",
  },

  disabledSaveBtn: {
    background: "#fff",
    color: "#4b1c46",
  },

  tStatContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    gap: "10px",
  },

  tStatUnitContainer: {
    justifyContent: "space-between",
    height: " 4rem",
  },
  modeContent: {
    overflow: "auto",
    // height: "28rem",
    backgroundColor: "white",
    width: "100%",
    marginBottom: "3rem",
  },
  modeContentUnitSettings: {
    padding: "0 20px",
    paddingTop: 10,
  },

  modeCardUnitSettings: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    justifyContent: "space-between",
  },

  emptyCard: {
    width: "45%",
  },

  limitsLineGroup: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
  },

  unitVisibleToggleContainer: {
    display: "flex",
    // flexWrap: "wrap",
    justifyContent: "space-evenly",
    width: "47.5%",
  },

  switchListContainer: {
    width: "47.5%",
  },

  tooltip: {
    zIndex: 0,
    width: "4rem",
    height: "4rem",
  },
  tooltipBox: {
    lineHeight: ".4em",
    fontSize: "1rem",
    textAlign: "center",
    backgroundColor: "inherit",
    color: "rgba(97, 97, 97, 0.9)",
    padding: "0",
  },
  addIcon: {
    width: "30px",
    height: "30px",
    borderRadius: "4px",
    backgroundColor: "#f6f5f6",
    color: "#7f7692",
  },

  fanModes: {
    // inactiveText: {
    // "& p": { color: "#4b1c46" },
    "& *": {
      // color: "#4b1c46",
      // opacity: 1,
      // stroke: "#4b1c46",
      fill: "#4b1c46",
      // },
      // "& g": {
      //   stroke: "#4b1c46",
      // },
      // "& path": {
      //   stroke: "#4b1c46",
      // },
    },
  },
  unitListVisibility: {
    display: "flex",
    padding: "0 20px 23px 20px",
    justifyContent: "space-between",
  },

  saveBtn: {
    display: "flex",
    justifyContent: "flex-start",
    // margin: "1rem",
    "& button": {
      width: "10rem",
    },
    padding: "20px 0 20px 20px",
    paddingLeft: 0,
  },

  cancelBtn: {
    display: "flex",
    justifyContent: "flex-start",
    // margin: "1rem",
    "& button": {
      width: "10rem",
    },
    padding: 20,
    paddingLeft: 0,
  },

  applyBtn: {
    marginLeft: "1.1rem",
    marginTop: "1rem",
  },

  scheduleContainer: {
    background: "white",
    padding: "15px 20px",
  },
  newScheduleBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  plusIcon: {
    padding: "2px",
    borderRadius: "5px",

    "&:hover": {
      "& $addIcon": {
        display: "none",
      },
      "& $addOnHoverIcon": {
        display: "inline",
      },
    },
  },

  nameSettingContainer: {
    height: " 3.5rem",
    width: "93%",
    display: "flex",
    padding: "11px 307px 7px 16px",
    borderRadius: "6px",
    border: "solid 1px #d5d2d5",
    marginBottom: "1rem",
    // backgroundColor: "#ffffff"
  },
  timeSettingContainer: {
    height: " 3.5rem",
    width: "93%",
    display: "flex",
    // justifyContent: "space-evenly",
    padding: "0 307px 7px 0",
    borderRadius: "6px",
    border: "solid 1px #d5d2d5",
    marginBottom: "1rem",
    // marginLeft: '1rem',

    // backgroundColor: "#ffffff"
  },
  timeSettingsTitle: {
    marginBottom: "20px",
    display: "inline-block",
    color: "#545964",
    fontSize: "15px",
    fontFamily: "RobotoBold",
    // fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginRight: 15,
  },

  weekDaysConatiner: {
    display: "flex",
    alignItems: "baseline",
    marginTop: 6,
    marginBottom: 15,
  },

  timeBtns: {
    borderRadius: "6px",
    border: "solid 1px #d5d2d5",
    color: "#545964",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  timeCheckbox: {
    color: "#545964",
    fontSize: "13px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginRight: "30px",
  },

  rightTimeBtn: {
    marginRight: 20,
    marginLeft: 20,
    // marginTop: 9,
    minWidth: 126,
  },

  topTitlePosition: {
    marginRight: "1.5rem",
    fontSize: "16px",
    marginTop: ".5rem",
  },

  nameInput: {
    "& input": {
      color: "#545964",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },

  schedulerContainer: {
    width: "32%",
  },

  limitsSchedulerLineGroup: {
    display: "flex",
    // flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    margin: "0",
  },

  modeCardUnitScheduleSettings: {
    // height: "14rem",
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: "space-between",
  },

  tStatScheduleContainer: {
    height: "initial",
    marginBottom: "1rem",
    margin: "initial",
    width: "100%",
  },
  modeContentUnitScheduleSettings: {
    padding: " 1rem",
    // height: "56vh",
  },

  localTimePicker: {
    width: "0",
    height: "50%",

    "& div button": {
      color: "#545964",
    },

    "& > div > div": {
      bottom: "11rem",
      left: "11rem",

      width: "24%",
      height: "187px",
      border: "1px solid #d5d2d5",
      backgroundColor: "white",
      /* max-height: 280px; */
    },
  },

  viewModeContent: {
    border: "solid 1px #968896",
    margin: "1rem 0 1rem 0",
  },

  viewNameSettingContainer: {
    border: "none",
    marginBottom: 0,
    padding: "11px 0 7px 0",
    justifyContent: "space-between",
    width: "100%",
  },

  editNameSettingContainer: {
    width: "100%",
  },

  viewTopTitle: {
    fontSize: "16px",
    color: "#545964",
    marginLeft: 0,
  },

  viewModeContainer: {
    height: "initial",
    margin: "0 1rem 1rem 0",
    width: "33%",
  },

  editModeContainer: {
    margin: "0",
    width: "33%",
  },

  viewLimitsSchedulerLineGroup: {
    display: "flex",
    width: "97%",
    margin: "0",
    justifyContent: "flex-start",
  },
  viewTimeSettingContainer: {
    justifyContent: "flex-start",
    border: "none",
    width: "100%",
    marginLeft: "-7px",
    paddingRight: 0,
  },
  editTimeSettingContainer: {
    width: "100%",
  },

  viewTimeBtns: {
    display: "inline-block",
  },

  unitScheduleActions: {
    flexDirection: "row-reverse",
    marginLeft: "1.5rem",
    width: "100%",
  },

  viewDegree: {
    color: "#545964",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginLeft: "2.5rem",
    alignSelf: "flex-start",
    width: "calc(100% - 2.5rem)",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  autoSlider: {
    left: "10px",
    "& .MuiSlider-root": {
      left: "10px",
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% - 8px)",
      color: "inherit",
      top: 24,
      fontSize: "1rem",
      "& *": {
        background: "transparent",
        color: "rgba(97, 97, 97, 0.9)",
        fontSize: "1rem",
        width: "50px",
      },
    },
  },
  sliderHolder: {
    width: "calc(100% - 40px)",
    marginBottom: 15,
  },
  heatSlider: {
    marginLeft: -10,
    color: "#f05146",
    left: "10px",
    "& .MuiSlider-root": {
      left: "10px",
      color: "#f05146",
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% - 8px)",
      color: "inherit",
      top: 24,
      fontSize: "1rem",
      "& *": {
        background: "transparent",
        color: "rgba(97, 97, 97, 0.9)",
        fontSize: "1rem",
        width: "50px",
      },
    },
    "& .MuiSlider-rail": {
      color: "rgba(180, 175, 203, 0.63)",
    },
  },

  coolSlider: {
    color: "#35a8e0",
    left: "10px",
    "& .MuiSlider-root": {
      left: "10px",
      color: "#35a8e0",
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% - 8px)",
      color: "inherit",
      top: 24,
      fontSize: "1rem",
      "& *": {
        background: "transparent",
        color: "rgba(97, 97, 97, 0.9)",
        fontSize: "1rem",
        width: "50px",
      },
    },
    "& .MuiSlider-rail": {
      color: "rgba(180, 175, 203, 0.63)",
    },
  },

  confirmationDialog: {
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5",
  },

  confirmationTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #d5d2d5",
    padding: "16px 9px 16px 24px",
    color: "#29132e",
  },

  confirmationDialogContent: {
    margin: "1rem",
    padding: "1.25rem",
    paddingBottom: 0,
  },
  confirmationContentText: {
    fontFamily: "Roboto",
    color: "#545964",
  },
  confirmationDialogActions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "1rem",
  },
  settingsHeaderContainer: {
    display: "flex",
    alignItems: "center",
    height: 65,
    minHeight: 65,
    borderBottom: "solid 1.2px #e1e2ea",
    marginBottom: 20,
    justifyContent: "space-between",
  },

  errorTitle: {
    color: "red",
  },
  withMessage: {
    justifyContent: "space-between",
  },

  headerTitle: {
    fontFamily: "RobotoMedium",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
  },
  addRightMargin: {
    marginRight: 10,
  },
  switchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 15px",
    borderRadius: "6px",
    border: "solid 1px #d5d2d5",
    height: 50,
    flex: 1,
  },
  scheduleItemContainer: {
    borderRadius: 6,
    border: "solid 1px #968896",
    marginTop: 15,
    padding: 20,
    paddingBottom: 0,
  },
  scheduleName: {
    fontFamily: "RobotoBold",
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
  },
  scheduleItemheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  deleteIcon: {
    marginRight: 25,
    marginLeft: 10,
  },
  iconBtn: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  modeCardScheculeView: {
    padding: 15,
    borderRadius: 6,
    border: "solid 1px #d5d2d5",
    // flex: 1,
    marginRight: 10,
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    // minHeight: 172,
    marginBottom: 10,
  },
  limitedCard: {
    // maxWidth: 375,
    minHeight: 80,
  },
  limitedLargeWidth: {
    width: 389,
  },
  unitModeHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modeTempRangeContainer: {
    display: "flex",
    margin: "15px 0",
    width: "100%",
  },
  sliderContainer: {
    marginTop: 30,
    marginBottom: 20,
    width: "calc(100% - 60px)",
    marginLeft: "-13px",
  },
  imgIconStyle: {
    marginRight: 10,
    maxWidth: 32,
    maxHeight: 32,
  },
  removeRightMargin: {
    marginRight: 0,
  },
  fanIcon: {
    marginRight: 10,
    transform: "scale(0.94)",
    "& path": { stroke: "#4b1c46" },
  },
  clearButton: {
    width: "30px",
    height: "30px",
    padding: 0,
    borderRadius: 6,
  },
  dialogText: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 15,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 0,
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogStyle: {
    width: "fit-content",
    maxWidth: "95%",
    display: "flex",
    minHeight: "fit-content",
    maxHeight: "calc(100% - 140px)",
    minWidth: 564,
    overflow: "hidden",
  },
  timeContainer: {
    padding: 15,
    borderRadius: 6,
    border: "solid 1px #d5d2d5",
    display: "flex",
    alignItems: "flex-start",
    marginTop: 15,
    flexFlow: "column",
  },
  hourBtn: {
    width: 155,
    height: 50,
    borderRadius: 6,
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    marginRight: 10,
  },
  addRightMargin30: {
    marginRight: 30,
  },
  weekDaysConatinerEdit: {
    display: "flex",
    flexFlow: "column nowrap",
    "& div": {
      "& *": {
        marginTop: 0,
      },
    },
  },
  onOffSettings: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 15,
    width: "100%",
    gap: "10px",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 20px",
    paddingBottom: "30px",
  },
  nameLabel: {
    fontFamily: "RobotoMedium",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    marginTop: 15,
    display: "flex",
    alignItems: "center",
  },
  inputField: {
    width: 292,
    height: 45,
    padding: "7px 15px",
    borderRadius: 4,
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    marginLeft: 15,
  },
  onEndSelect: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  endActionTypeSelect: {
    marginLeft: "1rem",
    height: "2rem",
  },
  endTypeLabel: {
    fontFamily: "RobotoMedium",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    display: "flex",
    alignItems: "center",
  },
  timeBtnView: {
    marginTop: 10,
    marginLeft: 0,
    textTransform: "lowercase",
  },
  viewContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    marginBottom: 15,
    width: "100%",
    gap: "10px",
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  cutLongNames: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "70%",
  },
  scheduleIcon: {
    transform: "scale(0.9)",
    marginLeft: "-6px",
    marginRight: "8px",
    "& g": {
      opacity: 1,
    },
  },
  timeInput: {
    background: "#e1dfe1",
    height: "30px",
    width: "50px",
    borderRadius: "3px",
    paddingTop: "3px",
    marginLeft: "30px",
    "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: "0",
    },
    "& input": {
      "-moz-appearance": "textfield",
      textAlign: "center",
    },
  },
  timeInputsContainer: {
    display: "flex",
    alignItems: "center",
    flexFlow: "row wrap",
    paddingTop: "20px",
    alignSelf: "self-start",
  },
  add15leftMargin: {
    marginLeft: "15px",
  },
  add11leftMargin: {
    marginLeft: "11px",
  },
  add20leftMargin: {
    marginLeft: "20px",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    margin: "auto",
  },
  root: {
    width: 56,
    height: 24,
    padding: "5px 7px !important",
    marginRight: -6,
  },
  switchBase: {
    left: 6,
    padding: 1,
    color: "#D8D8D8",
    "&$checked": {
      left: 0,
      transform: "translateX(26px)",
      color: theme.palette.primary.main,
      "& + $track": {
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
        border: "none",
        opacity: 1,
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
    },
  },
  thumb: {
    color: theme.palette.primary.main,
    width: 24,
    height: 23,
    boxShadow: "none",
  },
  track: {
    borderRadius: 50 / 2,
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
    opacity: 1,
    height: 15,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  setpointResolutionContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    width: "150px",
    justifyContent: "space-between",
  },
  hide: {
    visibility: "hidden",
  },
  selectContainer: {
    // padding: '0 9px',
    height: "36px",
    width: "20%",
    backgroundColor: "transparent !important",
    "&:disabled": {
      backgroundColor: "transparent !important",
    },
    // display: 'flex',
    // alignItems: 'center'
  },
  selectStyle: {
    // width: "10rem",
    color: "#000",
    // backgroundColor: theme.palette.primary.light,
    borderRadius: "4px",
    margin: "1rem .5rem",
    padding: "0 9px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      // backgroundColor: theme.palette.primary.light
    },
  },
  inputStyle: {},
  iconStyle: {
    right: "0",
  },
  focusStyle: {
    color: "#000",
    "&:focus": {
      // backgroundColor: theme.palette.primary.light,
      borderRadius: "4px",
    },
  },
  paperStyle: {},
  removeSelected: { background: "transparent !important" },
  selected: {
    backgroundColor: "rgba(0, 0, 0, 0.08) !important",
  },
  selectSubType: {
    display: "flex",
    minWidth: "160px",
    alignSelf: "center",
    justifyContent: "space-between",
    padding: "0 10px",
  },
  disabledItem: {
    opacity: 0.5,
  },
  arrowIcon: {
    color: "#fff",
    fontSize: "2.5rem !important",
    top: "0",
    height: "36px",
  },
  selectText: {
    lineHeight: "36px",
  },
  sliderAndLabelContainer: {
    display: "flex",
  },
  rightLabelForamt: {
    width: "60px",
    textAlign: "center",
    marginLeft: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftLabelForamt: {
    width: "60px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  divider: {
    margin: "20px 0",
    borderTop: "solid 1px #d5d2d5",
    width: "100%",
  },
  selectModeStyle: {
    fontSize: "18px",
    lineHeight: "normal",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    color: theme.overrides.MuiButton.contained.color,
    width: "100%",
    marginLeft: "4px",
    marginBottom: "16px",
  },
  daysContainer: {
    padding: "0px 0px",
    margin: "0px 0px",
    justifyContent: "space-between",
    width: "auto",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "5px",
  },
  thresholdBlock: {
    width: "47%",
  },
  modeThresholdRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 10px",
    alignItems: "center",
  },
  modeIcon: {
    transform: "scale(1.5)",
    marginRight: "10px",
  },
  thresholdInput: {
    width: "86%",
  },
  AutoDSMode: {
    marginRight: "10px",

  },
}));
