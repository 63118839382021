// Code from the following thread:
// https://github.com/mui-org/material-ui-pickers/issues/364
//
import { alpha, makeStyles } from "@material-ui/core/styles";
import { DatePicker, DatePickerProps, useUtils } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import { addDays, max as maxDate, min as minDate } from "date-fns";
import React, { useEffect, useState } from "react";

export interface DateRange {
  begin: MaterialUiPickersDate;
  end: MaterialUiPickersDate;
}

export interface DateRangePickerProps
  extends Omit<DatePickerProps, "onChange" | "value" | "labelFunc"> {
  value?: DateRange;
  onChange: (value: DateRange) => void;
  labelFunc?: (value: DateRange, invalidLabel: string) => string;
  disableToolbar?: boolean;
  customRange?: DateRange;
  noRangeLimit?: boolean;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  labelFunc,
  format,
  emptyLabel,
  autoOk,
  onOpen,
  onClose,
  open: openForward,
  disableToolbar,
  customRange,
  noRangeLimit = false,
  ...rest
}) => {
  const classes = useStyles();

  const [begin, setBegin] = useState<MaterialUiPickersDate>(value?.begin ?? null);
  const [end, setEnd] = useState<MaterialUiPickersDate>(value?.end ?? null);
  const [prevBegin, setPrevBegin] = useState<MaterialUiPickersDate>(begin);
  const [prevEnd, setPrevEnd] = useState<MaterialUiPickersDate>(end);
  const [hasClicked, setHasClicked] = useState(false);

  const [hover, setHover] = useState<MaterialUiPickersDate>(null);
  const [accepted, setAccepted] = useState(false);
  const utils = useUtils();

  const min = begin && (end || hover) ? minDate([begin, end || hover!]) : null;
  const max = begin && (end || hover) ? maxDate([begin, end || hover!]) : null;

  const [open, setOpen] = useState(false);

  const isOpen = openForward !== undefined ? openForward : open;

  useEffect(() => {
    //Only way to get to this state is if openForward is used
    if (isOpen && accepted && !prevBegin && !prevEnd) {
      setAccepted(false);
      setPrevBegin(begin);
      setPrevEnd(end);
      return;
    }
    //Closed without accepting, reset to prev state, don't call onChange
    if (!isOpen && !accepted) {
      setBegin(prevBegin);
      setEnd(prevEnd);
      setHover(null);
      setHasClicked(false);
    }
    //Auto ok and hasn't been accepted, but has all the items set, accept and close.
    if (isOpen && !accepted && begin && end && hasClicked) {
      //This will also trigger the onChange event by setting isOpen to false
      if (autoOk) {
        setAccepted(true);
        onClose ? onClose() : setOpen(false);
      } else {
        // Manually trigger onChange
        setHasClicked(false);
        onChange({ begin, end });
      }
    }
    if (accepted && begin && end && !isOpen && hasClicked) {
      setHasClicked(false);
      onChange({ begin, end });
      onClose ? onClose() : setOpen(false);
    }
  }, [begin, end, autoOk, accepted, isOpen, prevBegin, hasClicked, prevEnd, onChange, onClose]);

  useEffect(() => {
    if (!customRange) {
      return;
    }

    setBegin(customRange?.begin);
    setEnd(customRange?.end);
  }, [customRange]);

  const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    const datePickerElement = dayComponent as React.ReactElement<DatePickerProps>;
    return React.cloneElement(datePickerElement, {
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setHasClicked(true);
        event.stopPropagation();
        if (!begin) {
          setBegin(day);
        } else if (!end) {
          if (utils.isBeforeDay(day, begin)) {
            setEnd(begin);
            setBegin(day);
          } else {
            setEnd(day);
          }
          if (autoOk) {
            setPrevBegin(null);
            setPrevEnd(null);
          }
        } else {
          setBegin(day);
          setEnd(null);
        }
      },
      onMouseEnter: () => requestAnimationFrame(() => setHover(day)),
      onFocus: () => requestAnimationFrame(() => setHover(day)),
      className: clsx(classes.day, {
        [classes.hidden]: dayComponent.props.hidden,
        [classes.current]: dayComponent.props.current,
        [classes.dayDisabled]: dayComponent.props.disabled,
        [classes.focused]:
          (utils.isAfterDay(day, min) && utils.isBeforeDay(day, max)) ||
          utils.isSameDay(day, min) ||
          utils.isSameDay(day, max),
        [classes.focusedRange]:
          (utils.isAfterDay(day, min) && utils.isBeforeDay(day, max)) ||
          (utils.isSameDay(day, min) && !utils.isSameDay(day, max)) ||
          (utils.isSameDay(day, max) && !utils.isSameDay(day, min)),
        [classes.focusedFirst]: utils.isSameDay(day, min) && !utils.isSameDay(day, max),
        [classes.focusedLast]: utils.isSameDay(day, max) && !utils.isSameDay(day, min),
        [classes.beginCap]: utils.isSameDay(day, min),
        [classes.endCap]: utils.isSameDay(day, max)
      })
    });
  };

  const formatDate = (date: Date) => utils.format(date, format || utils.dateFormat);

  return (
    <DatePicker
      {...rest}
      value={begin}
      renderDay={renderDay}
      open={isOpen}
      disableToolbar={true}
      maxDate={(!!end || noRangeLimit) ? new Date("2999-01-01") : addDays(begin || new Date(), 30)}
      onOpen={() => {
        setAccepted(false);
        setPrevBegin(begin);
        setPrevEnd(end);
        onOpen ? onOpen() : setOpen(true);
      }}
      onAccept={() => {
        if (!begin || !end) {
          if (hover && utils.isBeforeDay(begin, hover)) {
            setEnd(hover);
          } else {
            setEnd(begin);
            setBegin(hover);
          }
        }
        setPrevBegin(null);
        setPrevEnd(null);
        // if (!autoOk) {
        setAccepted(true);
        // }
      }}
      onClose={() => {
        onClose ? onClose() : setOpen(false);
      }}
      onChange={() => {}}
      labelFunc={(date, invalid) =>
        !isOpen
          ? labelFunc
            ? labelFunc({ begin, end }, invalid)
            : date && begin && end
              ? `${formatDate(begin)} - ${formatDate(end)}`
              : emptyLabel || ""
          : prevBegin && prevEnd
            ? labelFunc
              ? labelFunc({ begin: prevBegin, end: prevEnd }, invalid)
              : `${formatDate(prevBegin)} - ${formatDate(prevEnd)}`
            : emptyLabel || ""
      }
      DialogProps={{ className: classes.dateRangePickerDialog }}
    />
  );
};

const useStyles = makeStyles((theme) => {
  const focusedRangeColor = alpha(theme.palette.primary.main, 0.3);
  const focusedRangeGradient = `linear-gradient(to right, ${focusedRangeColor}, ${focusedRangeColor})`;
  const transparentRangeGradient = `linear-gradient(to right, rgba(0,0,0,0.0), rgba(0,0,0,0.0))`;
  return {
    dateRangePickerDialog: {
      "& .MuiPickersCalendar-transitionContainer": {
        minHeight: 218,
        marginTop: 10
      }
    },
    day: {
      width: 40,
      height: 36,
      fontSize: 16,
      margin: 0,
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      padding: 0,
      transition: "none",
      "&::after": {
        borderRadius: "100%",
        bottom: 0,
        boxSizing: "border-box",
        content: "\"\"",
        height: 36,
        width: 36,
        left: 0,
        margin: "auto",
        position: "absolute",
        right: 0,
        top: 0,
        transform: "scale(0)",
        zIndex: 2
      },
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
        "&::after": {
          backgroundColor: theme.palette.background.paper,
          border: `2px solid ${theme.palette.primary.main}`,
          bottom: -2,
          left: -2,
          height: 36,
          width: 36,
          right: -2,
          top: -2,
          boxSizing: "content-box",
          transform: "scale(1)"
        }
      },
      "& > .MuiIconButton-label": {
        zIndex: 3
      }
    },
    hidden: {
      opacity: 0,
      pointerEvents: "none"
    },
    current: {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    dayDisabled: {
      pointerEvents: "none",
      color: theme.palette.text.hint
    },
    focused: {
      color: "#545964"
    },
    focusedRange: {
      background: "rgba(229, 223, 229, 0.6)",
      fontWeight: theme.typography.fontWeightMedium,
      width: 40,
      marginRight: 0,
      marginLeft: 0,
      borderRadius: 0
    },
    focusedFirst: {
      color: "#fff",
      background: `linear-gradient(to right, #fff, #fff) no-repeat 0/20px 40px,linear-gradient(to right, rgba(229, 223, 229, 0.6), rgba(229, 223, 229, 0.6)) no-repeat 20px 0/20px 40px`
    },
    focusedLast: {
      color: "#fff",
      background: `linear-gradient(to right, rgba(229, 223, 229, 0.6), rgba(229, 223, 229, 0.6)) no-repeat 0/20px 40px,${transparentRangeGradient} no-repeat 20px 0/20px 40px`
    },
    beginCap: {
      "&::after": {
        transform: "scale(1)",
        backgroundColor: theme.palette.primary.main
      }
    },
    endCap: {
      "&::after": {
        transform: "scale(1)",
        backgroundColor: theme.palette.primary.main
      }
    }
  };
});
