import { alpha } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100% - 133px)",
      display: "flex",
      flexFlow: "column nowrap",
      overflow: "auto"
    },
    pageContent: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
      flex: 1,
      overflow: "auto",
      padding: "0 10px",
      alignItems: "center"
    },
    section: {
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5"
    },
    nameSection: {
      minHeight: 80,
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5"
    },
    moreInfo: {
      minHeight: "max-content",
      paddingBottom: "6px",
      display: "flex",
      justifyContent: "center",
      width: "100%"
    },
    controlSection: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight: "max-content",
      padding: "0 10px"
    },
    controlSectionHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      boxSizing: "border-box",
      height: "60px"
    },
    controlSectionTitle: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.gray,
      display: "flex",
      alignItems: "center"
    },
    controlSectionBody: {
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box"
    },
    saveButton: {
      margin: "25px auto",
      width: "calc(100% - 20px) !important",
      minHeight: 50
    },
    textStyle: {
      width: "100%",
      height: 50,
      "& div": {
        height: 50
      }
    },
    unitListVisibility: {
      display: "flex",
      padding: "0 20px 23px 20px",
      justifyContent: "space-between"
    },
    normalSection: {
      width: "100%",
      boxSizing: "border-box",
      borderBottom: "solid 1px #e5e2e5",
      minHeight: 60,
      padding: "0 10px"
    },
    noContentContainer: {
      boxShadow: "0 2px 5px -5px " + alpha(theme.palette.colors.gray3 || "rgb(180, 175, 203)", 0.63),
      backgroundColor: "#fefefe",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "100%",
      height: "100%",
      padding: 20
    },
    grayOval: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "136px",
      height: "136px",
      backgroundColor: alpha(theme.palette.colors.gray1 || "rgb(170, 162, 170)", 0.1),
      borderRadius: "50%"
    },
    arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
    noUnitText: {
      fontSize: "26px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.colors.gray,
      marginTop: "43px"
    },
    titleSection: {
      borderBottom: "solid 1px #e5e2e5"
    },
    mainSectionTitle: {
      fontFamily: "'RobotoMedium'",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#15111f",
      borderBottom: "solid 1px #e5e2e5",
      display: "flex",
      alignItems: "center",
      padding: "0 20px",
      minHeight: 60,
      minWidth: "100%"
    },
    alertIcon: {
      marginRight: 15,
      width: 25,
      height: 25
    },
    rowSectionTitle: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.gray,
      borderBottom: "solid 1px #e5e2e5",
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      minHeight: 60,
      justifyContent: "space-between",
      minWidth: "100%"
    },
    checkboxSection: {
      fontFamily: "Roboto",
      fontSize: 15,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.gray,
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      minHeight: 60
    },
    checkboxStyle: {
      marginRight: "10px",
      padding: 0,
      "& span": {
        width: "24px",
        height: "24px",
        borderRadius: "3px",
        backgroundColor: "#fff"
      }
    },
    controlsSection: {
      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fdfdfe",
      marginBottom: 7
    },
    coolSlider: {
      color: theme.palette.colors.summerSky,
      "& input": {
        fontSize: "8px"
      }
    },
    sliderRoot: {
      touchAction: "auto",
      color: theme.palette.colors.summerSky
    },
    sliderValueLabel: {
      left: "calc(-50% - 8px)",
      color: "inherit",
      top: 24,
      fontSize: "1rem",
      "& *": {
        background: "transparent",
        color: alpha(theme.palette.colors.gray2 || "rgb(97, 97, 97)", 0.9),
        fontSize: "1rem",
        width: "50px"
      }
    },
    sliderRail: {
      color: alpha(theme.palette.colors.gray3 || "rgb(180, 175, 203)", 0.63)
    },
    controlSectionContainer: {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      borderBottom: "solid 1px #e5e2e5",
      minWidth: "100%"
    },
    sliderHolder: {
      width: "calc(100% - 40px)",
      minHeight: "45px",
      marginBottom: "15px"
    },
    titleContainer: {
      display: "flex",
      minHeight: 60,
      justifyContent: "space-between",
      padding: "0 10px",
      alignItems: "center",
      marginBottom: 15,
      width: "100%"
    },
    coldIcon: {
      transform: "scale(2)",
      marginRight: "21px",
      marginLeft: 10
    },
    heatSlider: {
      color: theme.palette.colors.carminePink,
      "& input": {
        fontSize: "8px"
      }
    },
    heatSliderRoot: {
      touchAction: "auto",
      color: theme.palette.colors.carminePink
    },
    autoSlider: {
      color: theme.palette.colors.purple1,
      "& input": {
        fontSize: "8px"
      }
    },
    autoSliderRoot: {
      touchAction: "auto",
      color: theme.palette.colors.purple1
    },
    autoIcon: {
      width: 32,
      marginRight: 15
    },
    scheduleName: {
      fontFamily: "RobotoMedium",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.purple1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "calc(100% - 170px)"
    },
    enablesText: {
      borderRadius: 6,
      backgroundColor: "#efeff2",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.gray,
      width: "fit-content",
      padding: 10,
      marginRight: 10,
      marginBottom: 15
    },
    modesContainer: {
      borderRadius: 6,
      border: "solid 1px #d5d2d5",
      marginBottom: 15,
      display: "flex",
      minWidth: "100%",
      flexFlow: "column nowrap"
    },
    enablesContainer: {
      display: "flex",
      flexFlow: "row wrap"
    },
    actionsContainer: {
      display: "flex",
      alignItems: "center"
    },
    timeLabel: {
      height: 38,
      marginBottom: 11,
      borderRadius: 6,
      border: "solid 1px #d5d2d5",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "38px",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.colors.gray,
      padding: "0 20px",
      width: "fit-content"
    },
    degreeLabel: {
      paddingLeft: 57,
      marginTop: -11,
      fontFamily: "Roboto",
      fontSize: 15,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.gray,
      marginBottom: 15
    },
    scheduleContainer: {
      minHeight: 50,
      padding: 10,
      borderBottom: `solid 1px ${theme.palette.colors.lightGray}`,
      width: "calc(100% - 20px)",
      marginTop: 15
    },
    scheduleTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 15
    },
    addIcon: {
      transform: "scale(1.5)"
    },
    errorLabelStyle: {
      color: "red",
      fontSize: "15px",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "10px",
      minHeight: "15px"
    },
    btnIcon: {
      margin: "auto",
      marginRight: 0
    },
    title: {
      marginRight: "auto",
      padding: 10,
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: 500,
      color: theme.palette.colors.gray
    },
    schedulesSection: {
      background: "white",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      borderTop: `solid 7px ${theme.palette.colors.lightGray}`
    }
  });
