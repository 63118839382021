import { alpha, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  bacnetUnitItem__container: {
    width: '100%',
    height: '50px',
    marginBottom: '6px',
    borderRadius: '6px',
    padding: '3px 9px 3px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: alpha('#fff', 0.1),
    color: alpha('#fff', 0.6),
  },
  'bacnetUnitItem--selected': {
    border: '2px solid rgba(255, 255, 255, 0.6)',
  },  
  'bacnetUnitItem--selectable': {
    'cursor': 'pointer',
    color: '#fff',
  },
  bacnetUnitItem__locationSection: {
    display: 'flex',
    paddingLeft: '32px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%',
    overflow: 'hidden',
  },
  bacnetUnitItem__name: {
    fontSize: '20px',
    fontWeight: 300,
    fontFamily: 'RobotoLight',
  },
  'bacnetUnitItem__name--bold': {
    fontFamily: 'RobotoMedium',
    fontWeight: 500,
  },
}));

export default useStyles;
