import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { getParsedColor } from '@utils/ColorsUtils';



/**
 * Determines if the text color should be black or white based on the background color.
 * Uses the luminance of the color to decide.
 *
 * @param {string} color - The color name, hex color, or RGB/RGBA value of the background.
 * @returns {string} - Returns '#FFFFFF' for white or '#000000' for black.
 */
const getTextColor = (color) => {
  // Convert named colors to hex if necessary
  let hexColor = getParsedColor(color).hex;
  let r, g, b;

  if (hexColor.startsWith('#')) {
    // Hex color format
    r = parseInt(hexColor.slice(1, 3), 16);
    g = parseInt(hexColor.slice(3, 5), 16);
    b = parseInt(hexColor.slice(5, 7), 16);
  } else if (hexColor.startsWith('rgb')) {
    // RGB or RGBA format
    const rgbValues = hexColor.match(/\d+/g);
    [r, g, b] = rgbValues.map((num) => parseInt(num, 10));
  } else {
    // If the format is unknown, default to black text color
    return '#000000';
  }

  // Calculate relative luminance
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 186 ? '#000000' : '#FFFFFF'; // Light background -> black text, dark background -> white text
};


/**
 * DynamicCircleIcon component that renders a 30X30 circle with centered uppercase letters inside.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.color - The background color of the circle.
 * @param {string} props.content - The text content inside the circle (1-4 uppercase letters).
 * @returns {JSX.Element} The rendered CircleIcon component.
 */
const DynamicCircleIcon = ({ color, content,title }) => {
  const textColor = getTextColor(color);

  return (
    <Box
      width={30}
      height={30}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      bgcolor={color}
      boxShadow={`0 0 1px ${textColor}`} // Add subtle shadow to enhance visibility
      sx={{
        userSelect:'none'
      }}
      title={title ?? content}
    >
      <Typography
        variant="caption"
        style={{
          color: textColor,
          fontWeight: 'bold',
          fontSize: '12px',
          lineHeight: '1',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {content}
      </Typography>
    </Box>
  );
};

DynamicCircleIcon.propTypes = {
  /** The background color of the circle. */
  color: PropTypes.string,

  /** The text content inside the circle (1-4 uppercase letters). */
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
};

DynamicCircleIcon.defaultProps = {
  color: '#1976d2', // Default color
  title:null,
};

export default DynamicCircleIcon;
