import React, { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  Button as MUIButton,
  CircularProgress
} from "@material-ui/core";
import { CloudUploadOutlined, ErrorOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { t } from "ttag";
import jsPDF from 'jspdf';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import autoTable from 'jspdf-autotable'
import { useDropzone } from "react-dropzone";
import Button from "../../cool_widgets/Button";
import { Close, Download as DownloadIcon, Excel } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";

import useStyles from "./ImportSiteStructureModal.style";

interface ISiteStructureSample {
  headers: {title: string, width: number}[];
  rows: (string | number)[][];
}

const downloadSampleFile = async (siteStructureSample: ISiteStructureSample) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Sample-File');

  sheet.addRow(siteStructureSample.headers.map(({ title }) => title));
  sheet.getRow(1).font = { bold: true, size: 12 };
  sheet.getRow(1).height = 45;
  sheet.getRow(1).alignment = { wrapText: true, vertical: 'top' };

  siteStructureSample.headers.forEach(({ width }, index: number) => {
    sheet.getColumn(index + 1).width = width;
  });

  sheet.addRows(siteStructureSample.rows);

  const buffer = await workbook["xlsx"].writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, `Sample-File.xlsx`);
};

interface IImportProps {
  siteId: string;
  siteName: string;
  fetchData: (isDelete?: boolean, silent?: boolean) => void;
  onClose: () => void;
}

interface IImportResultRow {
  rowNumber: number;
  updates: string[];
  errors: string[];
}

interface IImportResult {
  results: IImportResultRow[];
  resultsSummary: string;
}

const ImportSiteStructureModal = ({
  siteId,
  siteName,
  fetchData,
  onClose
}: IImportProps) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [errorText, setErrorText] = useState<string>('');
  const [importResult, setImportResult] = useState<IImportResult | null>(null);

  const siteStructureSample = useStoreState((state) => state.sites.siteStructureSample);
  const { importSiteStructure, getSiteStructureSample } = useStoreActions((action) => action.sites);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { dateFormat } = useStoreState((state) => state.users);
  const getSystems = useStoreActions((action) => action.systems.getSystems);

  useEffect(() => {
    if (siteStructureSample.headers.length === 0) {
      getSiteStructureSample();
    }
  }, [])

  const onDrop = useCallback((acceptedFiles: any) => {
    setErrorText('');

    const file = acceptedFiles[0];
    if (!file) {
      setErrorText('no file was uploaded.');
      setFile(null);
      return;
    }

    // check file size
    if (file.size > 1 * 1024 * 1024) { // 1MB in bytes
      setErrorText('File size exceeds the maximum allowed size (1MB).');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const fileContent = reader.result as string;
      const rows = fileContent.split('\n');
      if (rows.length < 2) {
        setErrorText('File must have at least 2 rows.');
        return;
      }

      const firstRow = rows[0].trim();
      if (firstRow === '') {
        setErrorText('File is empty.');
        return;
      }

      setFile(file);
    };

    reader.onerror = () => {
      setErrorText('Error reading file. Please try again.');
    };
    reader.readAsText(file);
  }, []);

  const uploadFile = async () => {
    setIsLoading(true);

    // create FormData object and append file
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await importSiteStructure({ siteId, formData });
      setImportResult(result);
      await Promise.all([fetchData(false, true), getSystems()]);
    } catch (error) {
      onClose();
      addMessage({ message: (error as Error).message });
    }

    setIsLoading(false);
  }

  const downloadPdfFile = () => {
    if (!importResult) {
      return;
    }

    const doc = new jsPDF({
      unit: 'px',
      format: [500, 700], // custom paper width and height
    });

    // adding result summary as subheader
    doc.setFontSize(12);
    doc.text(importResult.resultsSummary, 30, 40);

    const tableData: any[] = [];
    importResult.results.forEach((row: IImportResultRow) => {
      const { rowNumber, updates, errors } = row;
      const content = updates.length ? updates : errors;

      // calculate content height (a cell can have multiple lines)
      const lineHeight = 4;
      const contentHeight = content.length * lineHeight;
      const textColor = errors.length ? '#ff0000' : '#12a312';

      const rowData = [
        {
          content: `${rowNumber}`,
          styles: {
            cellPadding: { left: 10 },
            valign: 'middle',
            cellWidth: 30,
          }
        },
        {
          content: content.join('\n'),
          styles: {
            valign: 'middle',
            cellWidth: 'wrap',
            cellPadding: 5,
            textColor,
            cellHeight: contentHeight
          }
        }
      ];

      tableData.push(rowData);
    });

    // configure autoTable settings
    autoTable(doc, {
      body: tableData,
      startY: 50,
      theme: 'plain',
      styles: { fontSize: 10 },
      alternateRowStyles: { fillColor: '#fafafa' }, // light gray background for alternate rows
    });

    doc.save(`${siteName}-import-results-${moment().format(dateFormat)}.pdf`);
  };


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ['.xlsx', '.csv'], maxFiles: 1 });

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={true}
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Import units/systems info from file`}</Typography>
        <IconButton disabled={isLoading} disableRipple className={classes.iconBtnStyle} onClick={onClose}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <div className={classes.note}>
          <ErrorOutlined className={classes.warningIcon} />
          <Typography className={classes.noteText}>{t`Example file format`}</Typography>
        </div>
        <div className={clsx(classes.tableHolder, classes.fileExample)}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  {siteStructureSample.headers.map(({ title }) => <TableCell align="left" className={clsx(classes.tableHeadStyle, {[classes.wideCell]: title === 'Unit Name' || title === 'CA Device SN'})}>{title}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {siteStructureSample.rows.map((row: (string | number)[], index) => {
                  return (
                    <TableRow
                      key={`unit-row-${index}-${siteId}`}
                      className={clsx({ [classes.oddRow]: index % 2 })}
                    >
                      {row.map((value: string | number) => <TableCell align="left" className={classes.tableCellStyle}>{value}</TableCell>)}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {!!importResult ?
          <>
            <div className={classes.uploadSection}>
              <Typography className={classes.label}>{t`Import results per row`}</Typography>
              <MUIButton
                startIcon={<DownloadIcon className={classes.downloadIcon} />}
                onClick={downloadPdfFile}
                className={classes.downloadBtn}
              >{t`Save Log`}</MUIButton>
            </div>
            <div className={classes.resultsContainer}>
              {importResult.results.map((row: IImportResultRow, index: number) => (
                <div className={clsx(classes.resultRow, { [classes.oddRow]: !(index % 2) })}>
                  <div className={clsx(classes.label, classes.rowIndexCell)}>
                    {row.rowNumber}
                  </div>
                  <div className={classes.resultsHolder}>
                    {row.updates.map((message: string) => <Typography className={classes.resultText}>{message}</Typography>)}
                    {row.errors.map((message: string) => <Typography className={clsx(classes.resultText, classes.errorResult)}>{message}</Typography>)}
                  </div>
                </div>
              ))
              }
            </div>
            <Typography className={clsx(classes.label, classes.summaryText)}>{t`Import completed. ${importResult.resultsSummary} see above log for details.`}</Typography>
          </> :
          <div className={classes.uploadContainer}>
            <div className={classes.uploadSection}>
              <Typography className={classes.label}>{t`Upload your file (XLS)`}<span className={classes.errorText}>{errorText}</span></Typography>
              <MUIButton
                startIcon={<DownloadIcon className={classes.downloadIcon} />}
                onClick={() => downloadSampleFile(siteStructureSample)}
                className={classes.downloadBtn}
              >{t`Download Sample File`}</MUIButton>
            </div>
            <div {...getRootProps()} className={classes.uploadInput}>
              <input {...getInputProps()}  disabled={isLoading}/>
              {file ?
                <div className={clsx(classes.label, classes.fileIconHolder)}>
                  <Excel className={classes.fileIcon} />
                  {file.name}
                </div>
                :
                <CloudUploadOutlined className={classes.uploadIcon} />
              }

              <Typography>{t`Drag and drop a file here`}</Typography>
              <Typography>{t`or`}</Typography>
              <Button>{t`Choose file`}</Button>
            </div>
          </div>
        }
      </div>
      <div className={classes.actionsHolder}>
        <Button
          white
          onClick={onClose}
          disabled={isLoading}
          width={150}
          marginRight
        >
          {!!importResult ? t`Close` : t`Cancel`}
        </Button>
        <Button
          width={180}
          disabled={!file || !!errorText || isLoading || !!importResult}
          onClick={uploadFile}
        >
          {isLoading && <CircularProgress size={21} className={classes.loader} />}
          {t`Submit & Upload`}
        </Button>
      </div>
    </Dialog>
  )
}

export default ImportSiteStructureModal;
