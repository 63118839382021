import React from 'react';
import PropTypes from 'prop-types';

const ClosedSensor = ({ width, height }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.2 4C10.2 5.65685 8.85685 7 7.2 7C5.54315 7 4.2 5.65685 4.2 4M10.2 4C10.2 2.34315 8.85685 1 7.2 1C5.54315 1 4.2 2.34315 4.2 4M10.2 4H14.4M4.2 4H0M19.8 4C19.8 5.65685 18.4569 7 16.8 7C15.1431 7 13.8 5.65685 13.8 4C13.8 2.34315 15.1431 1 16.8 1C18.4569 1 19.8 2.34315 19.8 4ZM19.8 4H24"
			stroke="white"
		/>
	</svg>
);

ClosedSensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

ClosedSensor.defaultProps = {
	width: 24,
	height: 8
};

export default ClosedSensor;