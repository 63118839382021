import { Card, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";
import React from "react";
import { t } from "ttag";
import ErrorLogs from "../../components/ErrorLogs/ErrorLogs";
import SystemOverview from "../../components/SystemOverview/SystemOverview";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./SystemOverviewPopUp.style";

export default function SystemOverviewPopUp(props: { open: boolean, onSystemSelect: any, systemId: string, isUnit: boolean, handleClose: any }) {
  const { open, onSystemSelect, systemId, isUnit, handleClose } = props;
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const getSystem = useStoreState((s) => s.systems.getSystem);

  const system = getSystem(systemId);
  const classes = useStyles();
  const handleAgree = () => {
    onSystemSelect(systemId);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
        classes={{ paper: classes.dialogWidth }}
      >
        <div className={classes.titleContent}>
          <Typography className={classes.headerTitle}>{t`System Overview`}</Typography>
          <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Card className={classes.container}>
            <Card className={classes.title}>
              <Typography variant="h6">{t`System Information`}</Typography>
            </Card>
            <Card className={classes.systemOverviewContent}>
              <SystemOverview system={system} hideTitle={true} />
            </Card>
          </Card>
          <Card className={classes.container}>
            <Card className={classes.title}>
              <Typography variant="h6">{t`Open Alerts`}</Typography>
            </Card>
            <Card>
              <ErrorLogs
                systemId={systemId}
                usePadding={true}
                hideColumns={["customerName", "systemName", "status"]}
                order={["alertType", "time", "unitName", "siteName", "alertType1", "description", "errorCode"]}
                showTitle={false}
                hideAlertOfType={[2]}
                hideEntity={false}
              />
            </Card>
          </Card>
        </div>
        <div className={classes.actionsHolder}>
          <Button onClick={handleAgree}
            white width={210}>
            {t`System Diagnostics`}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
