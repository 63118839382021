import { Card, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { t } from 'ttag';
import useStyles from './styles';

const BACnetUnitInfo = (props) => {
  const classes = useStyles();
  const { bacnetUnit, siteName } = props;
  const { title, isEngaged } = bacnetUnit;

  return (
    <Card className={classes.card}>
      <div className={classes.unitInfoHeader}>
        <Typography className={classes.unitInfoTitle}>
          {t`BACnet Device Information`}
        </Typography>
        {!isEngaged && (
          <Typography className={classes.unitInfoDisconnectedText}>
            {t`- BACnet Device is disconnected`}
          </Typography>
        )}
      </div>
      <div className={classes.itemsWrapper}>
        <div
          className={clsx(classes.item, classes.firstItem)}
          style={{ maxWidth: '50%' }}
        >
          <Typography className={classes.infoTitle}>
            {t`BACnet Device Name`}
          </Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>
            {title}
          </Typography>
        </div>
        <div className={classes.item} style={{ maxWidth: '50%' }}>
          <Typography className={classes.infoTitle}>{t`Site Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>
            {siteName}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default BACnetUnitInfo;
