import React from "react";

function MobileSite(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="35"
      viewBox="0 0 34 35"
      {...props}
    >
      <defs>
        <path id="site_svg__a" d="M0 .802h8.812V15.4H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#AAA2AA"
          d="M26.402 6.722H14.834c-.85 0-1.54.69-1.54 1.54v20.391h13.108c.85 0 1.54-.687 1.54-1.539V8.261a1.54 1.54 0 00-1.54-1.539m-.018 1.56l.018 18.814h-11.55L14.833 8.28h11.55"
        />
        <g transform="translate(6 13.254)">
          <mask id="site_svg__b" fill="#fff">
            <use xlinkHref="#site_svg__a" />
          </mask>
          <path
            fill="#AAA2AA"
            d="M8.812.802H1.515C.678.802 0 1.48 0 2.317v11.567c0 .837.68 1.516 1.516 1.516h7.297V.802zM7.277 2.337V13.87l-5.742.014-.02-11.547h5.762z"
            mask="url(#site_svg__b)"
          />
        </g>
        <path
          fill="#AAA2AA"
          d="M17.507 14.032h2.177v-2.176h-2.177zm4.046 0h2.176v-2.176h-2.176zm-4.046 4.135h2.177V15.99h-2.177zm4.046 0h2.176V15.99h-2.176z"
        />
      </g>
    </svg>
  );
}

export default MobileSite;