import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  filterContainer: {
    height: '100%',
    paddingRight: '1rem',
    overflow: 'auto'
  },
  filterGridItem: {
    height: '40%',
    background: theme.palette.background.paper,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    borderBottom: '1rem solid #efeff2'
  },
  filter: {
    height: '100%',
    overflow: 'auto',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column'
  },
  popUpContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0'
  }
}));

export default useStyles;
