import { IconButton, makeStyles, Theme } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { ArrowDownO, CheckboxChecked, Close } from "../../icons";
import styles from "./AddRule.style";

declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  node?: any;
  className?: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
({
  root: {
    color: "#545964",
    fontSize: "14px",
    width: "100%",
    minHeight: "40px",
    "&:hover > $content": {
      backgroundColor: "transparent"
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, #fff)`,
      color: "var(--tree-view-color)"
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent"
    }
  },
  content: {
    color: "#545964",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    paddingRight: "10px",
    fontWeight: 400,
    "$expanded > &": {
      fontWeight: 400
    }
  },
  group: {
    borderLeft: "1px solid #d5d2d5",
    marginLeft: "31px"
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
    padding: 0,
    display: "flex"
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: "9px",
    paddingLeft: 0
  },
  labelIcon: {
    marginRight: "10px"
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    fontSize: 15
  },
  iconContainer: {
    alignItems: "center",
    width: "unset",
    marginRight: "5px"
  }
})
);

const Checkbox1 = (props: any) => {
  const classes = styles();

  return <Checkbox
    color="default"
    edge="end"
    variant="outlined"
    onChange={() => { }}
    onClick={(event: any) => event.stopPropagation()}
    checkedIcon={<CheckboxChecked />}
    className={clsx(classes.smallCheckbox, { [props.className]: props.className })}
    {...props}
  />;
};

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={clsx(classes.labelRoot, { [className]: className })}>
          {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
          {node && node}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer
      } as any}
      {...other}
    />
  );
}

const useStyles = makeStyles(
  ({
    root: {
      height: "100%",
      flexGrow: 1,
      maxWidth: 400,
      maxHeight: "calc(100% - 65px)",
      overflow: "auto",
      background: "#fff",
      padding: "0 20px",
      paddingTop: "20px"
    },
    actions: {
      backgroundColor: "#fff",
      display: "flex",
      position: "absolute",
      justifyContent: "flex-end",
      width: "100%",
      bottom: "10px",
      right: "20px"
    },
    indeterminate: {
      color: "#fff"
    },
    indetCheckbox: {
      "& > span": {
        background: "red !important",
        border: "1px solid gray"
      },
      "& svg": { width: "23px", height: "23px" }
    },
    smallCheckbox: {
      marginRight: "10px",
      padding: 0,
      "& span": { width: "18px", height: "18px", borderRadius: "3px", backgroundColor: "#fff" }
    },
    labelStyle: {
      marginLeft: "-19px",
      "& p": { fontWeight: "bold" }
    },
    treeHeader: {
      borderBottom: "solid 1px #d5d2d5",
      width: "1005",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      maxHeight: "60px !important",
      backgroundColor: "#f6f6f7",
      alignItems: "center",
      minHeight: "60px"
    },
    headerTitle: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#29132e"
    },
    customerIconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "13px",
      "& div": { display: "none" },
      marginLeft: "10px"
    },
    customerGroup: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "21px"
    },
    label: {
      color: "#545964",
      padding: 0
    },
    customerContent: {
      backgroundColor: "rgba(170, 162, 170, 0.1)"
    },
    IconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
  })
);

const ItemsTree = ({
  operational,
  systemsToSites,
  allSites,
  customers,
  brandSystems,
  selectedUnits,
  selectedSystems,
  allUnits,
  onClose,
  onSave,
  mode,
  brandCustomers,
  userPref,
  isODRule = false
}: any) => {
  const classes = useStyles();
  const [currentSelectedUnits, setCurrentSelectedUnits] = useState<any>(selectedUnits);
  const [currentSelectedSystems, setCurrentSelectedSystems] = useState<any>(selectedSystems);
  const [sitesUnits, setSitesUnits] = useState<any>({});

  useEffect(() => {
    const unitsToSites: any = {};
    Object.keys(systemsToSites).forEach((siteId: string) => systemsToSites[siteId]?.forEach((systemId: string) => unitsToSites[siteId] = [...(unitsToSites[siteId] || []), ...brandSystems[systemId]?.[mode === "systems" ? "units" : mode] || []]));
    setSitesUnits(unitsToSites);

  }, []);

  const customersNodes = () => {
    const items = [];
    for (const customer in customers) {
      let customerObject = brandCustomers[customer];
      if (!customerObject) {
        customerObject = customers[customer];
        customerObject.sites = [];
      }

      const { sites, id, name } = customerObject;
      const customerName = (userPref?.nicknames && userPref.nicknames[id]) || name;
      customerObject && items.push(
        <StyledTreeItem
          key={`customer-${id}`}
          nodeId={`customer-${id}`}
          labelText={customerName}
          classes={{ iconContainer: classes.customerIconContainer, group: classes.customerGroup, label: classes.label, content: classes.customerContent }}
          color="#545964"
          bgColor="#fff"
        >{sitesNodes(sites)}</StyledTreeItem>);

    }

    return items;
  };

  const sitesNodes = (sites: any) => {
    const items = [];
    for (const i in sites) {
      const siteId: any = sites[i];
      const { id, name } = allSites[siteId];
      const systems = systemsToSites[id] || [];
      const isAllSystemsChecked = systems && systems instanceof Array && systems.every((systemId: string) => currentSelectedSystems.includes(systemId));
      let checked = false;

      if (operational) {
        checked = isAllSystemsChecked || (sitesUnits[id] && sitesUnits[id] instanceof Array && sitesUnits[id]?.length !== 0 && sitesUnits[id].every((unitId: string) => currentSelectedUnits.includes(unitId) || currentSelectedSystems.includes(allUnits[unitId].system)));
      } else {
        checked = mode === "systems" ? isAllSystemsChecked : sitesUnits[id] && sitesUnits[id] instanceof Array && !!sitesUnits[id].length && sitesUnits[id].every((unitId: string) => currentSelectedUnits.includes(unitId) || currentSelectedSystems.includes(allUnits[unitId].system));
      }
      const indeterminate = (systems && systems instanceof Array && systems.some((systemId: string) => currentSelectedSystems.includes(systemId))) || (sitesUnits[id] && sitesUnits[id] instanceof Array && sitesUnits[id].some((unitId: string) => currentSelectedUnits.includes(unitId)));

      id && items.push(
        <StyledTreeItem
          key={`site-${id}`}
          nodeId={`site-${id}`}
          labelText={name}
          node={systems.length > 0 ? <Checkbox1
            indeterminate={!checked && indeterminate}
            checked={checked}
            onChange={(event: any) => {
              const siteUnits = sitesUnits[id] || [];
              const { target: { checked: value } } = event;
              if (operational) {
                if (value) {
                  setCurrentSelectedSystems([...new Set([...currentSelectedSystems, ...systems])]);
                  setCurrentSelectedUnits(currentSelectedUnits.filter((unitId: string) => !siteUnits.includes(unitId)));
                } else {
                  setCurrentSelectedUnits(currentSelectedUnits.filter((unitId: string) => !siteUnits.includes(unitId)));
                  setCurrentSelectedSystems(currentSelectedSystems.filter((systemId: string) => !systems.includes(systemId)));

                }
                return;
              }
              if (!value) {
                setCurrentSelectedUnits(currentSelectedUnits.filter((unitId: string) => !siteUnits.includes(unitId)));
                setCurrentSelectedSystems(currentSelectedSystems.filter((systemId: string) => !systems.includes(systemId)));
              } else {
                setCurrentSelectedUnits(currentSelectedUnits.filter((unitId: string) => !siteUnits.includes(unitId)));
                setCurrentSelectedSystems([...new Set([...currentSelectedSystems, ...systems])]);
              }
            }}
          /> : undefined}
          color="#545964"
          bgColor="#fff"
        >
          {systemsNodes(systems)}
        </StyledTreeItem>);

    }

    return items;
  };
  const unitsNodes = (units: any) => {
    const items = [];
    for (const i in units) {
      const unit = allUnits[units[i]];
      const { id, name = "", system, type, controlUnit, address } = unit;
      let unitName: string = name;
      if (type === 3) {
        const controlName = allUnits[controlUnit]?.name || t`Unassigned`;
        unitName = `${controlName} (${address})`;
      }
      const disabled = mode === "systems" || currentSelectedSystems.includes(system);

      unit && items.push(
        <StyledTreeItem
          key={`unit-${id}`}
          nodeId={`unit-${id}`}
          labelText={unitName}
          style={{ opacity: disabled ? 0.5 : 1 }}
          node={
            <Checkbox1
              disabled={disabled}
              checked={currentSelectedUnits.includes(id) || currentSelectedSystems.includes(system)}
              onChange={(event: any) => event.target.checked ? setCurrentSelectedUnits([...currentSelectedUnits, id]) : setCurrentSelectedUnits(currentSelectedUnits.filter((index: any) => id !== index))}
            />}
          color="#545964"
          bgColor="#fff"
        />);
    }
    return items;
  };

  const systemsNodes = (systems: any) => {
    const items: any = [];

    for (const i in systems) {
      const systemObject = brandSystems[systems[i]];
      if(!systemObject?.id) {
        continue;
      }
      const { id, name } = systemObject;
      const systemUnits = mode !== "systems" ? systemObject[mode] : [...systemObject?.indoorUnits, ...systemObject?.outdoorUnits];
      const checked = currentSelectedSystems.includes(id);

      items.push(
        <StyledTreeItem
          key={`system-${id}`}
          nodeId={`system-${id}`}
          labelText={name}
          node={
            isODRule ?
              <span /> :
              <Checkbox1
                checked={checked}
                indeterminate={!checked && (mode !== "systems" && systemUnits && systemUnits instanceof Array && systemUnits.some((unitId: any) => currentSelectedUnits.includes(unitId)))
                }
                onChange={() => {
                  if (currentSelectedSystems.includes(id)) {
                    setCurrentSelectedSystems(currentSelectedSystems.filter((index: any) => id !== index));
                    return;
                  }
                  setCurrentSelectedSystems([...currentSelectedSystems, id]);
                  setCurrentSelectedUnits(currentSelectedUnits.filter((unitId: string) => !systemUnits.includes(unitId)));
                }}
              />}
          color="#545964"
          bgColor="#fff"
        >
          {unitsNodes(systemUnits)}
        </StyledTreeItem >);
    }
    return items;
  };

  const extractUnitsFromSystems = () => {
    if (currentSelectedSystems.length === 0) {
      return [];
    }
    const units: any = [];
    currentSelectedSystems.forEach((systemId: any) => units.push(...(brandSystems[systemId]?.indoorUnits || [])));
    return units;
  };

  return (
    <div>
      <div className={classes.treeHeader}>
        <Typography className={classes.headerTitle}>{t`Select Units`}</Typography>
        <IconButton disableRipple onClick={onClose} className={classes.IconBtnStyle}><Close color="#7f7692" /></IconButton>
      </div>
      <TreeView
        className={classes.root}
        defaultExpanded={["1"]}
        defaultCollapseIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ marginLeft: "7px", marginRight: "2px" }} /> </>}
        defaultExpandIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ transform: "rotate(-90deg)", marginLeft: "7px", marginRight: "2px" }} /></>}
        defaultEndIcon={<div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} />}
      >
        {customersNodes()}
      </TreeView>
      <div className={classes.actions}>
        <Button width={130} onClick={() => operational ? onSave([], [...currentSelectedUnits, ...extractUnitsFromSystems()]) : onSave(currentSelectedSystems, currentSelectedUnits)}>Save</Button>
      </div>
    </div>
  );
};

export default ItemsTree
