import * as React from 'react';

function SvgUpload(props) {
  const {border = "#421A40"} = props
  return (
    <svg width="1em" height="1em" viewBox="0 0 106 36" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width={105} height={35} x={0.5} y={0.5} fill="#FFF" stroke={border} rx={4} />
        <text fill="#421A40" fontFamily="Roboto-Medium, Roboto" fontSize={15} fontWeight={400}>
          <tspan x={15} y={22}>
            {'Choose file'}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SvgUpload;
