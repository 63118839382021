import { makeStyles, Theme } from "@material-ui/core";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => ({
  navMenuItem: {
    color: theme.palette.colors.menuFont,
    textTransform: "none",
    justifyContent: "flex-start",
    paddingRight: "1rem",
    paddingLeft: "0.8rem",
    // width: "15rem",
    opacity: 0.6,
    textAlign: "start",
    width: "16.5rem",
    ...placeBackgroundColor(theme.palette.background.sideNavItem),
    "&:hover": {
      ...placeBackgroundColor(theme.palette.background.sideNavItemHover)
    }
  },
  navMenuItemContainer: {
    display: "flex",
    flexDirection: "column"
  },
  navMenuSubItem: {
    color: theme.palette.colors.menuFont,
    textTransform: "none",
    justifyContent: "flex-start",
    paddingRight: "0.3rem",
    paddingLeft: "66px",
    width: "16rem",
    opacity: 0.6,
    ...placeBackgroundColor(theme.palette.background.sideNavSubItem),
    "&:hover": {
      ...placeBackgroundColor(theme.palette.background.sideNavSubItemHover),

    }
  },
  navMenuSubItemDisabled: {
    color: theme.palette.colors.menuFont,
    textTransform: "none",
    justifyContent: "flex-start",
    paddingRight: "0.3rem",
    paddingLeft: "3.2rem",
    width: "16rem",
    opacity: 0.25,
    cursor: "not-allowed",
    backgroundColor: theme.palette.primary.dark
  },
  navMenuItemSelected: {
    borderRadius: 0,
    borderLeft: "3px solid rgb(240, 83, 71)",
    opacity: 1,
    ...placeBackgroundColor(theme.palette.background.sideNavItemSelected),
    "&>svg": { opacity: 1 }
  },
  navMenuItemDisabled: {
    opacity: 0.25,
    cursor: "not-allowed",
    color: theme.palette.colors.menuFont,
    textTransform: "none",
    justifyContent: "flex-start",
    paddingRight: "1rem",
    paddingLeft: "0.8rem",
    textAlign: "start",
    width: "265px",
    backgroundColor: theme.palette.primary.dark
  },
  navIcon: {
    color: "white",
    opacity: 0.6,
    margin: "0.5rem",
    marginLeft: "10px",
    marginRight: '5px'
    // fontSize: "30px"
  },
  navIconDisabled: {
    // color: 'white',
    opacity: 0.2
  },
  navIconSelected: {
    // borderRadius: 0,
    // borderLeft: '3px solid rgb(240, 83, 71)',
    // backgroundColor: 'rgb(87,69,92, 0.6)',
    opacity: 1
    // '&>svg': { opacity: 1 }
  },
  // ---
  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.colors.menuFont,
    width: "100%",
    padding: "1.5rem",
    boxSizing: "border-box"
  },
  navItemTitle: {
    width: "calc(100% - 85px)"
  }
}));

export default useStyles;
