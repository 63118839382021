import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  drpContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "0.7rem"
  },
  drpButtons: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: "0.5rem"
  },
  cancelbtn: {
    marginRight: "10px"
  },
  drpContainerMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: 0,
    minWidth: 310,
    maxWidth: 310
  },
  drpButtonsMobile: {
    display: "flex",
    width: "215px",
    justifyContent: "space-between"
  }
}));

export default useStyles;
