import { alpha } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      flexWrap: "wrap"
    },
    modeStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "80px",
      padding: "0 15px",
      height: "60px",
      borderRadius: "6px",
      backgroundColor: alpha("#fff", 0.1),
      marginBottom: 15,
      marginRight: 15,
      textTransform: "capitalize",
      "& *": {
        color: "#fff",
        fontSize: "20px"
      }
    },
    activeModeStyle: {
      backgroundColor: "#d5d2d5"
    },
    inactiveText: {
      backgroundColor: "#f6f5f6"
    },
    typoIconStyle: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#545964"
    },
    typoPurpleColor: {
      color: "#4b1c46"
    }
  });
