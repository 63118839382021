import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(()=>({
	item:{
		display:'flex',
		justifyContent:'flex-start',
		gap:20
	},
	centerItem:{
		width:'30%',
		textAlign:'start'
	}
}));

export default useStyles;