import * as React from 'react';

function SvgPlus(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 13" {...props}>
      <path
        d="M6.309 1v11M.971 6.5h10.676"
        fill="none"
        fillRule="evenodd"
        stroke="#AAA2AA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.8}
      />
    </svg>
  );
}

export default SvgPlus;
