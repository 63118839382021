import React from "react";

const List = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(3.475 4.061)" stroke="#AAA2AA" strokeWidth="1.4" fill="none" fillRule="evenodd">
      <rect x=".7" y=".7" width="16.65" height="2.828" rx="1" />
      <rect x=".7" y="6.525" width="16.65" height="2.828" rx="1" />
      <rect x=".7" y="12.35" width="16.65" height="2.828" rx="1" />
    </g>
  </svg>
);

export default List;
