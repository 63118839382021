import React from 'react';

const SvgSwitcherOff = props => (
	<svg width={50} height={50} {...props}>
		<g fill="none" fillRule="evenodd">
			<circle cx={25} cy={25} r={25} fill="#FDFDFE" opacity={0.1} />
			<path
				d="M18.876 17.63c-2.52 1.83-4.165 4.772-4.165 8.102 0 5.549 4.547 10.047 10.156 10.047 5.61 0 10.156-4.498 10.156-10.047 0-3.33-1.644-6.273-4.165-8.101m-6.22-2.921v9.99"
				stroke="#AAA2AA"
				strokeLinecap="round"
				strokeWidth={1.8}
			/>
		</g>
	</svg>
);

export default SvgSwitcherOff;
