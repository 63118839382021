import * as React from "react";

function SvgCoolMode(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 21" {...props}>
      <g
        stroke="#35A8E0"
        strokeWidth={0.872}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path d="M8.96.436v19.646m2.889-17.431L8.96 4.417 6.071 2.651m0 15.375l2.89-1.767 2.888 1.767M17.436 5.295L.484 15.223m16.5-6.316l-2.983-1.601.063-3.385M.797 11.69l2.983 1.602-.063 3.384M.436 5.377l16.978 9.885M3.804 3.994l.072 3.386L.897 8.988m13.286 7.737l-.072-3.386 2.98-1.608" />
      </g>
    </svg>
  );
}

export default SvgCoolMode;
