import * as React from 'react';

function SvgSelectArrow(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 7" {...props}>
      <path
        d="M.236 1.495l5.16 5.245c.34.347.87.347 1.21 0l5.141-5.245a.877.877 0 000-1.235.833.833 0 00-.595-.26.832.832 0 00-.595.26L5.992 4.92 1.448.282a.83.83 0 00-.595-.26.826.826 0 00-.594.26.87.87 0 00-.022 1.213z"
        fill="#7F7692"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSelectArrow;
