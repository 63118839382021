import * as React from "react";

const LCDIcon = (props) => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" d="M0 .84h34v34H0z" />
      <path fill="#fff" d="M.5 7.591h33V28.09H.5z" />
      <path
        d="M23.27 11.745H10.83c-.551 0-.998.483-.998 1.08v10.03c0 .598.447 1.081.999 1.081h12.44c.552 0 .999-.483.999-1.08V12.825c0-.597-.447-1.08-1-1.08z"
        fill="#AAA2AA"
      />
      <path
        d="M12.412 14.84h9.277M12.412 17.84h9.277M12.412 20.84h9.277"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.438 19.043h-3.096c-.859 0-1.555-.767-1.555-1.715V8.546M1.5 18.492h3.068c.852 0 1.542-.71 1.542-1.584V8.796"
        stroke="#AAA2AA"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={1.262}
        y={8.259}
        width={31.435}
        height={19.163}
        rx={1.3}
        stroke="#AAA2AA"
        strokeWidth={1.4}
      />
    </svg>
  );

export default LCDIcon;
