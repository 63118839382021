import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from '../../../../AddEditDialog.style';
import styles from './DaySelector.module.css';

const DaySelector = ({value,onChange,error,disabled}) => {
	const classes = useStyles();
	const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];

	const updateSelectedDay = (day) => () => {
		if(disabled){
			return null;
		}

		onChange(day);

	};

	return (
		<div className={styles.container}>
			<div className={classes.btnsContainer}>
				{days.map(day => {
					const computedClassName = clsx(classes.btnStyle,styles.button,{ [classes.selectedBtnDays]: value === day,[styles.disabled]:disabled,[styles.error]:error});
					return (
						<div key={day} onClick={updateSelectedDay(day)} className={computedClassName}>
							{day}
						</div>
					);
				})}		
			</div>
			<Typography className={classes.error}>{error}</Typography>
		</div>
	);
};

DaySelector.propTypes = {
	onChange:PropTypes.func.isRequired,
	value:PropTypes.string,
	error:PropTypes.string,
	disabled:PropTypes.bool
};

DaySelector.defaultProps = {
	error:'',
	disabled:false,
	value:''
};

export default DaySelector;