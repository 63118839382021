import React, { useState, useMemo } from "react";

import { IconButton, Tooltip,  makeStyles } from "@material-ui/core";
import { Field, getIn } from "formik";
import { filter, cloneDeep } from "lodash";
import { t } from "ttag";
import clsx from "clsx";

import {
  EntityOption,
  UnitType,
  IConditionProps,
  scriptSystemUnitTypes
} from '../../hooks/useScripts/types';
import { useStoreState } from "../../models/RootStore";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { Delete, MoreVert, CheckboxChecked } from "../../icons";
import { CustomAutoComplete } from "../AddRule/CustomedComponents";
import { CustomedTextField, CustomSelect, ParamToolTip } from "./CustomedComponents";
import MultiSelect from "./UnitsMultiSelect";

import styles from "./AddEditScript.style";

const theresholdOptions = [
  { value: "threshold", label: " >" },
  { value: "thresholdWithin", label: " <" }
];

const addDynamicSeparators = (options: any[]) => {
  const result: any[] = [];

  options.forEach((option, index: number) => {
    let newOption = cloneDeep(option)
    if (index > 0) {
      const prevOption = cloneDeep(options[index - 1]);
      // ddd a separator if the previous option does not start with '*' and the current option does
      // or if the previous param is the special new parameter Procedure Status
      if ((!prevOption.title.startsWith("*") && newOption.title.startsWith("*")) ||
        prevOption.code === "procedureStatus"
      ) {
        newOption.isSeparator = true;
      }
    }

    result.push(newOption);
  });

  return result;
}

 const Condition = (props: IConditionProps) => {
  const {
    errors,
    touched,
    name,
    condition,
    deleteCondition,
    index,
    setFieldValue,
    disabled = false,
    moveProps = {},
    params,
    systemUnitsOptions,
    entitiesMap,
    specificUnitsEnabled = false,
    serviceParamTypes,
    paramsMap,
    indoorParams,
    inGroup = false,
    onSelect = () => { },
    handleUnitTypeChange = () => { }
  } = props;

   const handleCheck = (event: any) => {
     const { target: { checked } } = event;
     onSelect(condition.id, checked);
   };

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const pressureUnit = useStoreState((s) => s.users.me.measurementUnits);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);

   const [selectTrigger, setSelectTrigger] = useState<boolean>(false);

  const { stepsOptions, commandsOptions, types, conditionsOptions, checkOperatorsOptions, enumOperatorsOptions }: any = useStoreState((s) => s);
  const { procedureStepTypes, stepsRunningModes, actionParameterOperators, actionValueSources, actionValueSourceOptions } = types;
  const commandsOptionsArray = Object.values(commandsOptions);
  const conditionsOptionsArray = Object.values(conditionsOptions);
  const enableConditions = condition.type === procedureStepTypes.condition;
  const enableCommands = condition.type === procedureStepTypes.command;
  const enumText: string = enableCommands ? commandsOptions[condition.command]?.enum :
    enableConditions ? conditionsOptions[condition.condition]?.enum : "";
  const enumValues: any = types[enumText] || {};
  const enumArray = Object.keys(enumValues).map((key: any) => ({ name: enumValues[key], value: key }));
  const enableValueSoruce = enableCommands && condition.command && !enumText;

    const measuresMap: any = {
      "°C": temperatureScale === 2 ? "°F" : "°C",
      "kg/cm2": pressureUnit === 2 ? "PSI" : "kg/cm2",
      "MPa": pressureUnit === 2 ? "PSI" : "MPa",
      "PSI": pressureUnit === 1 ? "kg/cm2" : "PSI"
    };

   const paramEnum = condition.condition ? paramsMap[condition.condition]?.enum : undefined;
    const paramEnumTypes = paramEnum ? (serviceParamTypes[paramEnum]) : {};
    const paramEnumsOptions = !paramEnum ? [] : Object.keys(paramEnumTypes).map((key: any) => ({ name: paramEnumTypes[key], value: key }));
   const paramUnitScale = paramsMap[condition.condition]?.data_unit_of_measurement &&
     measuresMap[paramsMap[condition.condition]?.data_unit_of_measurement] ?
     " " + measuresMap[paramsMap[condition.condition]?.data_unit_of_measurement] : "";

   const isThreshHold = condition.operator == 'threshold';
   const thresholdParams = useMemo(() => {
     if (!isThreshHold || !condition.condition) {
       return [];
     }
     return params.filter((param: any) => {
       return (
         param.data_unit_of_measurement === paramsMap[condition.condition]?.data_unit_of_measurement &&
         !param.enum &&
         param.code !== condition.condition &&
         param.unitType === paramsMap[condition.condition]?.unitType
       );
     });
   }, [isThreshHold, condition.condition, params, paramsMap]);


   const filteredIndoorParams = useMemo(() => {
     if (enumText || !condition.command) {
       return [];
     }
     return indoorParams.filter((param: any) => {
       return (param.data_unit_of_measurement === commandsOptions[condition.command]?.data_unit_of_measurement && !param.enum);
     });
   }, [enumText, condition.command, indoorParams, paramsMap]);

   const renderSystemUnitMultiSelect = () => {
     if (inGroup) {
       return null;
     }

     if (!specificUnitsEnabled) {
       return null;
     }

     if (!condition.type) {
       return null;
     }

     if (condition.type === procedureStepTypes.wait) {
       return null
     }

     if (condition.condition === 'procedureStatus') {
       return null
     }

     const selectDisabled = disabled || (condition.type === procedureStepTypes.condition && !condition.condition);
     const unitType = !enableConditions ? scriptSystemUnitTypes.service : condition.unitType;

     return (
       <MultiSelect
         options={systemUnitsOptions[unitType] || []}
         entitiesMap={entitiesMap}
         onChange={(value: any) => setFieldValue(`${name}.${index}.selectedEntities`, value)}
         disabled={selectDisabled}
         error={!!(getIn(errors, `${name}[${index}].selectedEntities`) && getIn(touched, `${name}[${index}].selectedEntities`))}
         name={`${name}.${index}.selectedEntities`}
         value={condition.selectedEntities}
         placeholder={t`Specific Unit/s`}
         forceRender={selectTrigger}
       />
     )
   }

   const renderCommandValueField = () => {
     if (condition.type !== procedureStepTypes.command) {
       return null;
     }

     if (condition.valueSource === actionValueSources.parameter && condition.operator === "=") {
       return null;
     }

     if (enumText) {
       return (
         <CustomSelect
          disabled={disabled}
          className={classes.width110}
          error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
          placeholder={t`Value`}
          name={`${name}[${index}].value`}
          onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
          value={condition.value}
          options={enumArray}
        />
      );
     }

     return (
      <CustomedTextField
       disabled={disabled || (condition.type !== procedureStepTypes.wait && (!condition.command && !condition.condition))}
       className={classes.width110}
       error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
       onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
       value={condition.value}
       name={`${name}.${index}.value`}
       type="number"
       label={t`Value` + (!condition.command ? "" : temperatureScale === 2 ? " (°F)" : " (°C)")}
      />
     )
   }

  return (
    <div className={clsx(classes.conditionContainer, { [classes.groupConditionContainer]: inGroup })}>
      <div className={classes.valuesContainer}>
        {inGroup ? <MoreVert className={classes.purbleMoreEvert} /> :
          <div {...moveProps} className={clsx(classes.moreVertContainer, { [classes.groupConditionMoreVertContainer]: inGroup })}>
          <MoreVert className={classes.whiteMoreVert} />
        </div>
        }
        {!inGroup &&
          <Checkbox
            color="default"
            edge="end"
            variant="outlined"
            onChange={handleCheck}
            checkedIcon={<CheckboxChecked />}
            className={classes.checkboxStyle}
            disabled={disabled}
          />}
        <CustomSelect
          disabled={disabled}
          className={classes.width165}
          error={getIn(errors, `${name}[${index}].type`) && getIn(touched, `${name}[${index}].type`)}
          placeholder={t`Type`}
          name={`${name}[${index}].type`}
          onChange={(event: any) => {
            condition.type = event.target.value;
            condition.value = '';
            condition.unitType = '';
            condition.condition = '';
            condition.thresholdParameter = '';
            condition.thresholdOperator = '';
            condition.operator = '=';
            condition.replacedCondition = '';
            condition.replacedParameter = '';
            condition.replacedThresholdParameter = '';

            setFieldValue(`${name}[${index}]`, condition);

            if (event.target.value === procedureStepTypes.wait || condition.condition === 'procedureStatus') {
              setFieldValue(`${name}[${index}].selectedEntities`, []);
            } else if (condition.unitType === scriptSystemUnitTypes.outdoor && condition.selectedEntities.length) {
              const filteredOptions: EntityOption[] = filter(condition.selectedEntities, (option) => option.entityType === 'system');
              setFieldValue(`${name}.${index}.selectedEntities`, filteredOptions)
              setSelectTrigger(!selectTrigger)
            }
            handleUnitTypeChange('', index);
          }}
          value={condition.type}
          options={stepsOptions}
        />
        {enableCommands && (
          <CustomSelect
            disabled={disabled}
            className={classes.width110}
            error={getIn(errors, `${name}[${index}].command`) && getIn(touched, `${name}[${index}].command`)}
            placeholder={t`Action`}
            name={`${name}[${index}].command`}
            onChange={(event: any) => {
              condition.command = event.target.value;
              condition.value = "";
              condition.valueSource = "";
              condition.operator = "=";
              condition.replacedParameter = "";
              setFieldValue(`${name}[${index}]`, condition);
            }}
            value={condition.command}
            options={commandsOptionsArray}
          />
        )}
        {enableConditions && <CustomAutoComplete
          useTooltip
          disabled={disabled}
          className={classes.autoComplete}
          error={getIn(errors, `${name}[${index}].condition`) && getIn(touched, `${name}[${index}].condition`)}
          placeholder={t`Parameter`}
          name={`${name}[${index}].condition`}
          onChange={(event: any, selectedOption: any) => {
            const value = selectedOption.code || "";
            condition.condition = value;
            condition.replacedCondition = '';

            const param = paramsMap[value];
            const thresholdParam = paramsMap[condition.thresholdParam] || condition.replacedThresholdParameter;
            if(param?.enum ||
              !thresholdParam ||
               param?.data_unit_of_measurement !== thresholdParam.data_unit_of_measurement ||
               param?.unitType !== thresholdParam.unitType
             ) {
              condition.value = '';
              condition.operator = '=';
              condition.thresholdParameter = '';
              condition.thresholdOperator = '';
              condition.replacedThresholdParameter = '';
            }
            setFieldValue(`${name}[${index}]`, condition);

            const unitType = selectedOption.unitType;
            if (unitType !== condition.unitType) {
              setFieldValue(`${name}[${index}].unitType`, unitType);

              if (condition.selectedEntities.length) {
                const filteredOptions: EntityOption[] = filter(condition.selectedEntities, (option) => option.entityType === 'system');
                setFieldValue(`${name}.${index}.selectedEntities`, filteredOptions)
                setSelectTrigger(!selectTrigger)
              }

              handleUnitTypeChange(unitType, index);
            }
          }}
          value={paramsMap[condition.condition]}
          options={params}
          label="title"
          optionValue="code"
          tooltipProp="title"
          modifyOptions={addDynamicSeparators}
        />}
        <ParamToolTip
          param={condition.replacedCondition}
          onClose={() => setFieldValue(`${name}[${index}].replacedCondition`, "")}
        />
        {enableValueSoruce && (
          <CustomSelect
            className={classes.valueSourceSelect}
            error={getIn(errors, `${name}[${index}].valueSource`) && getIn(touched, `${name}[${index}].valueSource`)}
            placeholder={t`Value Source`}
            name={`${name}[${index}].valueSource`}
            onChange={(event: any) => {
              condition.replacedParameter = '';
              condition.valueSource = event.target.value;
              if (event.target.value === actionValueSources.constant) {
                condition.operator = '=';
              }
              setFieldValue(`${name}[${index}]`, condition);
            }}
            value={condition.valueSource}
            options={actionValueSourceOptions}
            disabled={disabled}
            label={"label"}
          />
        )}
        {enableValueSoruce && condition.valueSource === actionValueSources.parameter &&
        <CustomAutoComplete
          useTooltip
          disabled={disabled}
          className={classes.autoComplete}
          error={getIn(errors, `${name}[${index}].parameter`) && getIn(touched, `${name}[${index}].parameter`)}
          placeholder={t`Parameter`}
          name={`${name}[${index}].parameter`}
          onChange={(event: any, selectedOption: any) => {
            const value = selectedOption.code || "";
            condition.parameter = value;
            condition.value = '';
            condition.replacedParameter = "";
            setFieldValue(`${name}[${index}]`, condition);
          }}
          value={paramsMap[condition.parameter]}
          options={filteredIndoorParams}
          label="title"
          optionValue="code"
          tooltipProp="title"
          modifyOptions={addDynamicSeparators}
        />}
        <ParamToolTip
          param={condition.replacedParameter}
          onClose={() => setFieldValue(`${name}[${index}].replacedParameter`, "")}
        />
        {enableValueSoruce && condition.valueSource === actionValueSources.parameter && (
          <CustomSelect
            className={classes.autoComplete}
            error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
            placeholder={t`Operator`}
            name={`${name}[${index}].operator`}
            onChange={(event: any) => {
              setFieldValue(`${name}[${index}].operator`, event.target.value);
              if (event.target.value === "=") {
                setFieldValue(`${name}[${index}].value`, "");
              }
            }}
            value={condition.operator}
            options={actionParameterOperators}
            disabled={disabled}
            label={"label"}
          />
        )}
        {enableConditions && (
          <CustomSelect
            className={classes.operatorSelect}
            error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
            placeholder={t`Operator`}
            name={`${name}[${index}].operator`}
            onChange={(event: any) => {
              condition.operator = event.target.value;
              condition.replacedThresholdParameter = '';
              setFieldValue(`${name}[${index}]`, condition);
            }}
            value={condition.operator}
            options={ !!paramEnum ? enumOperatorsOptions : checkOperatorsOptions }
            disabled={disabled}
            label={"label"}
          />
        )}
        {enableConditions && isThreshHold && <CustomAutoComplete
          useTooltip
          disabled={disabled || !condition.condition}
          className={classes.autoComplete}
          error={getIn(errors, `${name}[${index}].thresholdParameter`) && getIn(touched, `${name}[${index}].thresholdParameter`)}
          placeholder={t`Parameter 2`}
          name={`${name}[${index}].thresholdParameter`}
          onChange={(event: any, selectedOption: any) => {
            const value = selectedOption.code || "";
            condition.replacedThresholdParameter = "";

            setFieldValue(`${name}[${index}].thresholdParameter`, value);
          }}
          value={paramsMap[condition.thresholdParameter]}
          options={thresholdParams}
          label="title"
          optionValue="code"
          tooltipProp="title"
          modifyOptions={addDynamicSeparators}
        />}
        <ParamToolTip
          param={condition.replacedThresholdParameter}
          onClose={() => setFieldValue(`${name}[${index}].replacedThresholdParameter`, "")}
        />
        {isThreshHold &&
          <CustomSelect
            placeholder={t`Difference`}
            className={classes.width140}
            error={getIn(errors, `${name}[${index}].thresholdOperator`) && getIn(touched, `${name}[${index}].thresholdOperator`)}
            onChange={(event: any) => setFieldValue(`${name}[${index}].thresholdOperator`, event.target.value)}
            value={condition.thresholdOperator}
            label="label"
            optionValue="value"
            options={theresholdOptions}
            name={`${name}[${index}].thresholdOperator`}
            disabled={disabled}
          />}
        {(condition.type === procedureStepTypes.wait) &&
          <CustomedTextField
            disabled={disabled}
            className={classes.width140}
            error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
            onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
            value={condition.value}
            name={`${name}.${index}.value`}
            type="number"
            label={t`Time in minutes`}
          />
        }
        {renderCommandValueField()}
        {(condition.type === procedureStepTypes.condition) && (paramEnum ?
          <CustomSelect
            disabled={disabled}
            className={classes.width110}
            error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
            placeholder={t`Value`}
            name={`${name}[${index}].value`}
            onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
            value={condition.value}
            options={paramEnumsOptions}
          /> :
           <CustomedTextField
           disabled={disabled || (condition.type !== procedureStepTypes.wait && (!condition.command && !condition.condition))}
            className={classes.width110}
           error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
           onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
           value={condition.value}
           name={`${name}.${index}.value`}
           type="number"
           label={t`Value` + paramUnitScale}
           />
         )
        }
        {renderSystemUnitMultiSelect()}
      </div>
      <div>
        {
          enableConditions ?
            <>
              <span>{t`continue if check fails`}</span>
              <Field
                name={`${name}.${index}.proceedOnFailure`}
                value={condition.proceedOnFailure}
                default={true}
                as={() => (
                  <CoolSwitch
                    disabled={disabled}
                    onChange={() => {
                      setFieldValue(`${name}[${index}].proceedOnFailure`, condition.proceedOnFailure ? false : true);
                    }}
                    checked={condition.proceedOnFailure}
                    name={`${name}.${index}.proceedOnFailure`}
                  />

                )}
              />
            </>
            : <></>
        }
        <IconButton disabled={disabled} disableRipple className={classes.iconBtnStyle} onClick={deleteCondition}>
          <Delete style={{ width: 20, height: 20 }} />
        </IconButton>
      </div>
    </div>
  );
};

export default Condition
