import { createStyles } from "@material-ui/styles";
export default (theme: any) =>
  createStyles({
    groupView: {
      width: "100%",
      height: "100%",
      background:
        theme.gradiants.panel ||
        "linear-gradient(to bottom, rgba(22,18,32,1) 0%, rgba(58,24,58,0.7) 53%)",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      borderTopRightRadius: "25px",
      borderTopLeftRadius: "25px",
      padding: 0,
      paddingTop: "40px",
      paddingBottom: "20px",
      minHeight: "500px",
      overflow: "auto",
      maxHeight: "100%",
      boxSizing: "border-box",
    },
    firstRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      alignItems: "center",
      alignContent: "center",
      boxSizing: "border-box",
      padding: "0 20px",
    },
    siteTemp: {
      width: "55px",
      opacity: 0.5,
      fontSize: 26,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
      minWidth: "fit-content",
    },
    scaleStyle: {
      fontSize: 14,
    },
    secondRowContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      paddingBottom: "20px",
    },
    nameSection: {
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "22px",
      fontWeight: "bold",
      width: "calc(100% - 120px)",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      maxHeight: "60px",
      "& p": {
        whiteSpace: "unset",
        textOverflow: "unset",
        color: "#fff",
        fontFamily: "Roboto",
        fontSize: "22px",
      },
      padding: "0 15px",
      textAlign: "center",
    },
  });
