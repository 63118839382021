import React from "react";

const SvgColdBlue = props => (
  <svg width={16} height={18} {...props}>
    <g fill={props.white ? "#fff" : "#35A8E0"}>
      <path d="M7.824-.371c.253 0 .462.158.495.364l.005.058v17.735c0 .233-.224.422-.5.422-.253 0-.463-.159-.496-.365l-.004-.057V.051c0-.233.224-.422.5-.422z" />
      <path d="M10.136 1.693a.422.422 0 0 1 .486.687l-.047.033-2.83 1.726-2.829-1.726a.422.422 0 0 1 .388-.747l.051.027 2.39 1.458 2.391-1.458zM4.916 15.57l2.83-1.726 2.83 1.726a.422.422 0 0 1-.39.747l-.05-.027-2.39-1.458-2.39 1.458a.422.422 0 0 1-.547-.093l-.034-.047a.422.422 0 0 1 .094-.547l.047-.033zM15.182 4.074a.422.422 0 0 1 .474.696l-.047.032-15.3 8.96a.422.422 0 0 1-.474-.695l.047-.032 15.3-8.96z" />
      <path d="M12.36 2.773a.422.422 0 0 1 .412.372l.002.058-.052 2.802 2.468 1.324a.422.422 0 0 1 .196.519l-.024.052a.422.422 0 0 1-.519.196l-.052-.024-2.921-1.567.06-3.318a.422.422 0 0 1 .43-.414zM.005 10.01a.422.422 0 0 1 .52-.196l.051.024 2.922 1.567-.061 3.318a.422.422 0 0 1-.841.042l-.003-.058.052-2.802L.177 10.58a.422.422 0 0 1-.196-.519l.024-.052zm-.319-5.711a.422.422 0 0 1 .525-.178l.052.026 15.328 8.92a.422.422 0 0 1-.373.756l-.051-.026L-.162 4.876a.422.422 0 0 1-.152-.577z" />
      <path d="M3.085 2.84a.422.422 0 0 1 .426.357l.005.057.067 3.311-2.915 1.58a.422.422 0 0 1-.45-.712l.048-.03 2.463-1.335-.057-2.797a.422.422 0 0 1 .356-.425l.057-.005zm11.801 7.038a.422.422 0 0 1 .45.711l-.05.031-2.462 1.329.057 2.797a.422.422 0 0 1-.356.425l-.057.005a.422.422 0 0 1-.425-.356l-.005-.057-.068-3.312 2.916-1.573z" />
    </g>
  </svg>
);

export default SvgColdBlue;
