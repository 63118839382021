import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
    view: {
        display: "flex",
        height: "100vh",
        overflow: "hidden"
    },
    contentArea: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        background: "rgb(236, 235, 239)",
        maxHeight: "-webkit-fill-available"
    },
    page: {
        display: "flex",
        flex: 1,
        margin: "10px",
        backgroundColor: "#fff",
        position: "relative"
    },
    planContainer: {
        width: "100%",
        flex: 1,
        position: "relative"
    },
    planImg: {
        position: "absolute",
        maxWidth: "100%",
        maxHeight: "100%"
    },
    canv: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10
    },
    popover: {
        padding: "20px",
        backgroundColor: "#fefdfe",
        display: "flex",
        flexDirection: "column",
        width: "300px",
        alignItems: "center",
        justifyContent: "space-between"

    },
    heat: {
        backgroundColor: "rgba(254, 0,0, 0.3)"
    },
    cool: {
        backgroundColor: "rgba(0, 0,254, 0.3)"
    },
    other: {
        backgroundColor: "rgba(0, 254,0, 0.3)"
    }

}));
export default useStyles;
