import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import DynamicBattery from '@icons/DynamicBatteryIcon';
import { sensorTypes } from '../../models/Sensors';
import useStyles from './SensorIcon.styles';

import CO2Sensor from './CO2Sensor.js';
import COSensor from './COSensor.js';
import HumiditySensor from './HumiditySensor.js';
import LightLevelSensor from './LightLevelSensor.js';
import OzoneSensor from './OzoneSensor.js';
import PM1Sensor from './PM1Sensor.js';
import PM25Sensor from './PM25Sensor.js';
import PM4Sensor from './PM4Sensor.js';
import PM10Sensor from './PM10Sensor.js';
import SoundSensor from './SoundSensor.js';
import TempSensor from './TempSensor.js';
import NitrogenSensor from './NitrogenSensor.js';
import ErrorSensor from './ErrorSensor.js';
import ClosedSensor from './ClosedSensor.js';
import OpenedSensor from './OpenedSensor.js';

/**
 * Renders a sensor icon based on its type and value.
 * @param {Object} props - The props object.
 * @param {boolean} [props.smallIcon] - Flag to render a small icon or thumbnail.
 * @param {number} props.type - The sensor type.
 * @param {number} [props.readingValue] - The reading value of the sensor.
 * @param {number} [props.width] - The width of the icon.
 * @param {number} [props.height] - The height of the icon.
 * @returns {JSX.Element} The rendered sensor icon component.
 */

export default function SensorIcon({ smallIcon, type, readingValue, width, height }) {
	const classes = useStyles();
	const icons = {
		[sensorTypes.TEMP_SENSOR]: <TempSensor width={width} height={height} />,
		[sensorTypes.HUMIDITY]: <HumiditySensor width={width} height={height} />,
		[sensorTypes.CO2_SENSOR]: <CO2Sensor width={width} height={height} />,
		[sensorTypes.CO_SENSOR]: <COSensor width={width} height={height} />,
		[sensorTypes.HUMIDITY_SENSOR]: <HumiditySensor width={width} height={height} />,
		[sensorTypes.PM25_SENSOR]: <PM25Sensor width={width} height={height} />,
		[sensorTypes.PM10_SENSOR]: <PM10Sensor width={width} height={height} />,
		[sensorTypes.OZONE_SENSOR]: <OzoneSensor width={width} height={height} />,
		[sensorTypes.NITROGEN_DIOXIDE_SENSOR]: <NitrogenSensor width={width} height={height} />,
		[sensorTypes.PM1_SENSOR]: <PM1Sensor width={width} height={height} />,
		[sensorTypes.PM4_SENSOR]: <PM4Sensor width={width} height={height} />,
		[sensorTypes.SOUND_SENSOR]: <SoundSensor width={width} height={height} />,
		[sensorTypes.LIGHT_LEVEL_SENSOR]: <LightLevelSensor width={width} height={height} />,
		[sensorTypes.ERROR_STATUS_SENSOR]: <ErrorSensor width={width} height={height} />,
		[sensorTypes.BATTERY_LEVEL_SENSOR]: <DynamicBattery width={smallIcon ? 32 : 60} height={smallIcon ? 22 : 30} percentage={readingValue} color={readingValue > 10 ? '#fff' : '#f00'} />,
		[sensorTypes.VOC_SENSOR]: <Typography className={classes.inLetters}>VOC</Typography>,
		[sensorTypes.BINARY_SENSOR]: readingValue === 1 ? <OpenedSensor height={height} width={width} /> : <ClosedSensor height={height} width={width} />,
		[sensorTypes.DIGITAL_INPUT_SENSOR]: readingValue === 1 ? <OpenedSensor height={height} width={width} /> : <ClosedSensor height={height} width={width} />,
	};

	return icons[type] || null;
}

SensorIcon.propTypes = {
	smallIcon: PropTypes.bool,
	type: PropTypes.number.isRequired,
	readingValue: PropTypes.number,
	height: PropTypes.number,
	width: PropTypes.number
};

SensorIcon.defaultProps = {
	smallIcon: false,
	readingValue: 0,
	height: 70,
	width: 70
};


export {
	CO2Sensor,
	COSensor,
	HumiditySensor,
	LightLevelSensor,
	OzoneSensor,
	PM1Sensor,
	PM25Sensor,
	PM4Sensor,
	PM10Sensor,
	SoundSensor,
	TempSensor,
	NitrogenSensor,
	ErrorSensor,
	ClosedSensor,
	OpenedSensor
};