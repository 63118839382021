import React from "react";

const ExportPDF = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="#AAA2AA" fillRule="nonzero">
      <path d="M7.987 17.044v-1.808h.766c.501 0 .898-.148 1.19-.445.292-.297.438-.688.438-1.173 0-.483-.145-.873-.436-1.171-.291-.298-.688-.447-1.192-.447H7v5.044h.987zm.766-2.598h-.766V12.79h.766a.55.55 0 0 1 .483.237c.11.158.165.358.165.598a.986.986 0 0 1-.166.589.556.556 0 0 1-.482.232zm3.783 2.598a1.62 1.62 0 0 0 1.282-.585c.337-.39.506-.894.506-1.51v-.85c0-.616-.169-1.12-.506-1.512A1.619 1.619 0 0 0 12.536 12h-1.524v5.044h1.524zm-.055-.786h-.482V12.79h.482c.275 0 .487.119.636.357.149.238.223.553.223.946v.855c0 .395-.074.712-.223.951a.708.708 0 0 1-.636.359zm3.506.786v-2.096H17.7v-.786h-1.712V12.79H18V12h-3v5.044h.987z" />
      <path d="M11.984 2v1.443h-6.19c-.697 0-1.27.528-1.339 1.203l-.007.138v14.432c0 .694.53 1.265 1.208 1.334l.137.007h12.414c.696 0 1.269-.528 1.338-1.203l.007-.138V11.67H21v7.547a2.788 2.788 0 0 1-2.616 2.779l-.177.005H5.793a2.79 2.79 0 0 1-2.788-2.607L3 19.216V4.784a2.788 2.788 0 0 1 2.616-2.779L5.793 2h6.19z" />
      <path d="M20.785 3.292 19.664 2.17 13.44 8.394a.793.793 0 1 0 1.12 1.121l6.224-6.223z" />
      <path d="M21 2v6.795a.793.793 0 0 1-1.586 0v-5.21h-5.186a.793.793 0 0 1 0-1.585H21z" />
    </g>
  </svg>


);

export default ExportPDF;
