import { Box, ClickAwayListener, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, TextField, Tooltip as MUIToolTip, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { Reporting as sdkReporting } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import DateRangePicker from "../../cool_widgets/DateRangePicker";
import MonthPicker from "../../cool_widgets/MonthPicker";
import { ExportPDF, Maximize, Search, ZoomOut } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import GraphPlaceHolder from "./GraphPlaceHolder";
import TruncatedText from "@components/TruncatedText";

const DiversityReport = ({ customerId, siteId, classes, setIsLoading, maximize, small, id, maxed, isCustomer = false, updatedDate }: any) => {

  const firstUpdate = useRef(true);
  const [openTt, setOpenTt] = useState<boolean>(false);
  const [loadDataFailed, setLoadDataFailed] = useState<boolean>(false);

  const allSystems = useStoreState((state) => state.systems.allSystems);
  const { date, data } = useStoreState((state) => state.ReportsData.SysDiversityReportsData);
  const getData = useStoreActions((actions) => actions.ReportsData.getSysDiversityData);
  const setInfo = useStoreActions((actions) => actions.ReportsData.setSysDiversityData);
  const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
  const [searchVal, setSearchVal] = useState<any>(null);

  useEffect(() => {
    updatedDate && setInfo({ date: updatedDate });
  }, [updatedDate, siteId]);

  useEffect(() => {
    setLoadDataFailed(false);
    //prevent firing at first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if ((!customerId && !siteId) || !date) {
      return;
    }
    setIsLoading(true);

    getData({ date, site: siteId, customer: customerId })
      .catch(() => {
        setLoadDataFailed(true);
      })
      .finally(() => setIsLoading(false));
  }, [date, customerId, siteId]);

  useEffect(() => {
    if (!data.length && !!siteId && !!date) {
      setLoadDataFailed(true);
    }
  }, [data]);

  const isMaxed = id === maxed;

  let maxTotalServiceHours = 1;
  for (const key in data) {
    if (data[key].total_service_hours > maxTotalServiceHours)
      maxTotalServiceHours = data[key].total_service_hours;
  }

  return (
    <div className={clsx(classes.reportContainer, small && classes.smallContainer, isMaxed && classes.maxedContainer)} id={!Object.keys(data).length ? "diversityXX" : "diversityGraphId"}>
      <div className={classes.reportContainerHeader}>
        <Typography className={classes.reportTitle}>{t`System Diversity Indication`}</Typography>
        <div className={classes.headerMid}>

          {
            isMaxed && !!Object.keys(data).length &&
            <FormControl variant="outlined" size="small"
              style={{
                marginRight: "50px",
                backgroundColor: "#fff"
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password">{t`Search System`}</InputLabel>
              <OutlinedInput
                value={searchVal}
                onChange={(e: any) => setSearchVal(e?.target?.value)}
                endAdornment={
                  <InputAdornment position="end">

                    <IconButton onClick={() => setSearchVal("")}
                      style={{
                        visibility: !searchVal?.length ? "hidden" : "visible"
                      }}>
                      <Close />
                    </IconButton>
                    <Search />
                  </InputAdornment>

                }
                label="Search System"
              />
            </FormControl>
          }
          <DateRangePicker disabled={!siteId} date={date} setDate={(date: any) => setInfo({ date })} autoSelectMonth={true} />
        </div>
        <div className={classes.reportHeaderActions}>
          <IconButton onClick={() => maximize(id)} data-html2canvas-ignore="true">
            {isMaxed ? <ZoomOut /> : <Maximize />}
          </IconButton>
        </div>
      </div>
      <div className={classes.reportContainerBody} style={{ overflowY: "auto" }}>
        {!!Object.keys(data).length ?
          (<div className={classes.diversityGraphsContent}>
            {
              _.orderBy(Object.keys(data), (o) => data[o].total_service_hours, ["desc"])?.map((systemId: string) => {
                const sys: any = allSystems[systemId];
                if (searchVal?.length && sys.name.toLowerCase().indexOf(searchVal?.toLowerCase()) === -1) {
                  return null;
                }
                const rowData = data[systemId];
                const { from0to50percent, from50to100percent, over100percent, system_capacity, total_service_hours } = rowData;
                const totalByMaxTotal = total_service_hours / maxTotalServiceHours;
                return (
                  <div key={systemId} className={classes.systemHolder}>
                    <div className={classes.system}>
                      <div className={classes.systemInfo}>
                        <Typography className={"sysCustomer"}>{getCustomerName(sys?.customer)}</Typography>
                        <Typography className={clsx("sysInfo", { [classes.error]: !system_capacity })}><TruncatedText text={sys?.name} maxLength={40}/></Typography>
                        <Typography className={"sysInfo"}>{t`Capacity ` + system_capacity + ` BTU`}</Typography>
                      </div>
                      <div className={classes.systemGraphs}>
                        <div className={classes.systemGraph}>
                          <Typography className={classes.barLabel}>{">100%"}</Typography>
                          <Box sx={{ display: "flex", alignItems: "center", flex: 1, position: "relative" }}>
                            <Box sx={{ width: "88%", mr: 1 }}>
                              <LinearProgress variant="determinate" value={Number(over100percent * 100 * totalByMaxTotal)} classes={{ root: classes.barRoot, bar: classes.darkBar }} />
                            </Box>
                            <Box style={{
                              position: "absolute",
                              width: "55px",
                              left: `${over100percent * 89 * totalByMaxTotal}%`
                            }}>
                              <Typography variant="body2">{`${Math.round(over100percent * total_service_hours * 10) / 10} Hrs`}</Typography>
                            </Box>
                          </Box>
                        </div>
                        <div className={classes.systemGraph}>
                          <Typography className={classes.barLabel}>{">50-100%"}</Typography>
                          <Box sx={{ display: "flex", alignItems: "center", flex: 1, position: "relative" }}>
                            <Box sx={{ width: "88%", mr: 1 }}>
                              <LinearProgress variant="determinate" value={Number(from50to100percent * 100 * totalByMaxTotal)} classes={{ root: classes.barRoot, bar: classes.minBar }} />
                            </Box>
                            <Box style={{
                              position: "absolute",
                              width: "55px",
                              left: `${from50to100percent * 89 * totalByMaxTotal}%`
                            }}>
                              <Typography variant="body2">{`${Math.round(from50to100percent * total_service_hours * 10) / 10} Hrs`}</Typography>
                            </Box>
                          </Box>
                        </div>
                        <div className={classes.systemGraph}>
                          <Typography className={classes.barLabel}>{"0-50%"}</Typography>
                          <Box sx={{ display: "flex", alignItems: "center", flex: 1, position: "relative" }}>
                            <Box sx={{ width: "88%", marginRight: 1 }}>
                              <LinearProgress variant="determinate" value={Number(from0to50percent * 100 * totalByMaxTotal)} classes={{ root: classes.barRoot, bar: classes.lightBar }} />
                            </Box>
                            <Box style={{
                              position: "absolute",
                              width: "55px",
                              left: `${from0to50percent * 89 * totalByMaxTotal}%`
                            }}>
                              <Typography variant="body2">{`${Math.round(from0to50percent * total_service_hours * 10) / 10} Hrs`}</Typography>
                            </Box>
                          </Box>
                        </div>
                      </div>

                    </div>
                    {!system_capacity && <Typography className={classes.capacityMsgError}>{t`Missing Capacity Info`}</Typography>}
                  </div>
                );
              })
            }
          </div>) :
          <GraphPlaceHolder loadDataFailed={loadDataFailed} />}
      </div>

    </div >
  );
};

export default DiversityReport;
