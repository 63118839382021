import { t as translate } from 'ttag';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OnGrey from '@icons/OnGrey';
import InfoIcon from '@icons/InfoIcon';
import ToggleIcon from '@icons/ToggleIcon';
import ListIcon from '@icons/ListIcon';
import NumericControlIcon from '@icons/numericControlIcon';

/*
Icons
*/
const OnOffIcon = () => {
  const classes = makeStyles(() => ({
    onOffWrap: {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      backgroundColor: '#6B4B68',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&>svg': {
        transform: 'scale(0.5)',
      },
    },
  }))();

  return (
    <div className={classes.onOffWrap}>
      <OnGrey stroke={'#fff'} />
    </div>
  );
};

export const labelSizes = {
  extraSmall: 12,
  small: 18,
  medium: 22,
  large: 28,
};

export const labelConfig = {
  position: [
    { id: 'top', name: translate`Top` },
    { id: 'bottom', name: translate`Bottom` },
    { id: 'left', name: translate`Left` },
    { id: 'right', name: translate`Right` },
  ],
  size: [
    { id: 'extraSmall', name: translate`Extra Small` },
    { id: 'small', name: translate`Small` },
    { id: 'medium', name: translate`Medium` },
    { id: 'large', name: translate`Large` },
  ],
  color: [
    '#000000',
    '#FFFFFF',
    '#F65385',
    '#FF5C58',
    '#FD9038',
    '#F5B903',
    '#10C27B',
    '#18BDE9',
    '#276EF0',
    '#9162F9',
  ],
};
export const controlTypes = {
  informationText: 'informationText',
  toggle: 'toggle',
  onOff: 'onOff',
  list: 'list',
  numericValue: 'numericValue'
};

export const controlTypeConfig = {
  [controlTypes.informationText]: {
    id: controlTypes.informationText,
    name: translate`Information Text`,
    icon: <InfoIcon />,
  },
  [controlTypes.toggle]: {
    id: controlTypes.toggle,
    name: translate`Toggle`,
    icon: <ToggleIcon />,
  },
  [controlTypes.onOff]: {
    id: controlTypes.onOff,
    name: translate`On/Off`,
    icon: <OnOffIcon />,
  },
  [controlTypes.list]: {
    id: controlTypes.list,
    name: translate`List`,
    icon: <ListIcon />,
  },
  [controlTypes.numericValue]: {
    id: controlTypes.numericValue,
    name: translate`Numeric`,
    icon: <NumericControlIcon />,
  },
};

const bacnetUnitTypes = {
  'unknown': 0,
  'binary': 1,
  'reverseBinary': 2,
  'enumeration': 4,
  'value': 5,
};

const supportedControlByType = {
  [bacnetUnitTypes.unknown]: [controlTypes.informationText],
  [bacnetUnitTypes.binary]: [controlTypes.informationText, controlTypes.toggle, controlTypes.onOff],
  [bacnetUnitTypes.reverseBinary]: [controlTypes.informationText, controlTypes.toggle, controlTypes.onOff],
  [bacnetUnitTypes.enumeration]: [controlTypes.informationText, controlTypes.list],
  [bacnetUnitTypes.value]: [controlTypes.informationText, controlTypes.numericValue],
};

export const getDummyDataPointData = (dataPointConfig) => {
  const data = {
    labelPosition: 'top',
    labelColorConfig: '#000000',
    labelSize: 'small',
    controlType: supportedControlByType[dataPointConfig.type]?.[0],
    position: { x: 50, y: 50 },
    label: dataPointConfig.name,
    config: {
      ...dataPointConfig,
      supportedControls: supportedControlByType[dataPointConfig.type] || [],
    },
  };

  return data;
};

export const modalStages = {
  image: 'image',
  template: 'template',
};

export const templateSource = {
  new: 'new',
  existing: 'existing',
};

export const mergeUiTemplatePointsWithParamTemplate = (
  paramTemplateData,
  uiTemplatePoints
) => {
  if (!uiTemplatePoints || !paramTemplateData) {
    return {};
  }

  const points = { ...uiTemplatePoints };
  Object.keys(points).forEach((pointId) => {
    points[pointId].config = {
      ...paramTemplateData[pointId],
      supportedControls:
        supportedControlByType[paramTemplateData[pointId]?.type] || [],
    };
  });

  return points;
};
