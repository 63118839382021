import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useStoreState } from "../../../../models/RootStore";
import SensorGroupPanelStyles from "./SensorGroupPanel.style";
import { SensorThumbnail } from "../SensorThumbnail";

const SensorGroupPanel: React.FC<any> = (props) => {
  const useStyles = makeStyles(SensorGroupPanelStyles);
  const classes = useStyles();
  const allSensors = useStoreState((state) => state.sensors.allSensors);

  const {
    group,
    isCelsius,
    siteTemp,
    tempSymbol,
  } = props;

  const {
    name: groupName = "",
    sensors: groupSensors,
  } = group || {};

  return (
    <div className={classes.groupView}>
      <div className={classes.firstRowContainer}>
        <Typography className={classes.siteTemp}>{(isCelsius ? Math.round(siteTemp.C) || "" : Math.round(siteTemp.F) || "") + " "}<span className={classes.scaleStyle}>{siteTemp.C && tempSymbol || ""}</span></Typography>
        <div className={classes.nameSection}>
          <Typography>{groupName}</Typography>
        </div>
        <div className={classes.placeholderItem}></div>
      </div>

      <div className={classes.secondRowContainer}>
        
        {
          groupSensors?.map((sensorId: string, index: any) => {
            const sensorObj = allSensors[sensorId];
            if (!sensorObj) {
              return;
            }
            if (!sensorObj.isVisible) {
              return;
            }
            return (
              <SensorThumbnail
                key={`sensorThumb-${sensorId}`}
                sensor={sensorObj}
                isCelsius={isCelsius}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default SensorGroupPanel;
