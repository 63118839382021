import React from 'react';

function SvgHeatModeOff(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 35" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#D5D2D5"
          fillRule="nonzero"
          d="M22.5 34C31.613 34 39 26.613 39 17.5c0-9.005-7.215-16.326-16.18-16.497L22.5 1H0V0h22.5C32.165 0 40 7.835 40 17.5S32.165 35 22.5 35H0v-1h22.5z"
        />
        <path
          d="M22.442 17.469a4.974 4.974 0 11-9.948 0 4.974 4.974 0 019.948 0zm-4.974 9.947v-2.631m0-14.632V7.521m4.974 18.617l-1.34-2.318m-7.317-12.674l-1.289-2.235M23.78 21.168h.001l2.303 1.33m-14.975-8.646L8.854 12.55m18.563 4.975h-2.634m-14.629-.001H7.522m18.562-4.974l-2.255 1.302M8.854 22.499l2.302-1.331h.001M22.442 8.911l-1.29 2.235M13.834 23.82l-1.338 2.319"
          stroke="#F05146"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default SvgHeatModeOff;
