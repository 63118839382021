import { Typography } from "@material-ui/core";
import React from "react";
import { t } from "ttag";
import assets from "../../services/assetsService";

const GraphPlaceHolder = ({ loadDataFailed = false, type = "site/date" }) => {

    return (
        <div style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center"
        }}>
            <img
                style={{
                    height: loadDataFailed ? "30%" : "65%"
                }}
                src={assets.getImagesPath(loadDataFailed ? "fail.png" : "graphPH.png")}
                alt="place holder"
            />

            <Typography
                style={{
                    fontSize: "18px",
                    color: "#4b1c46",
                    fontWeight: 500,
                    textAlign: "center"
                }}
            >
                {loadDataFailed ? t`Data is not Available for the selected ${type}` : t`Please select ${type} to see data`}
            </Typography>
        </div>
    );
};

export default GraphPlaceHolder;
