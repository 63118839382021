import {
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { FastField, FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Loading from "../../components/Loading/Loading";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import ErrorBox from "../../components/WarnningBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { Close, PressedStar, SelectArrow as SvgArrow, SmallSideArrow, Star } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AlthermaUnitSettings.style";
import EmptyScreen from "./EmptyScreen";

const validateValue = (value: any, param: any) => {
  const { rangeMax, rangeMin, step, isWritable } = param;
  if (!isWritable) {
    return "";
  }

  if (!value && value !== 0) {
    return "Required";
  }

  if (isNaN(value)) {
    return "Value must be a number";
  }

  if ((rangeMax || rangeMax === 0) && +value > rangeMax) {
    return "Max value is " + rangeMax + ".";
  }

  if ((rangeMin || rangeMax === 0) && +value < rangeMin) {
    return "Min value is " + rangeMin + ".";
  }

  if (step && +value % step !== 0) {
    return "Allowed step is " + step + ".";
  }

  return "";
};

interface WarningShownState {
  [key: string]: boolean;
}

const AlthermaUnitSettings: React.FC<any> = (props: any) => {
  const { unitId, onClose } = props;
  const classes = useStyles();
  const { serviceParamTypesOptions, isInitialized } = useStoreState((s) => s);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { getUnitCategories, getUnitCategoryParams, updateUnitParamsValues, getUnitCategoryParamsByParams } = useStoreActions((a) => a.units);
  const { getUserPreferences, updateUserPreferences } = useStoreActions((action) => action.users);
  const { resetAlthermaSettings } = useStoreActions((action) => action.devices);
  const { getUnitName, allUnits } = useStoreState((s) => s.units);

  const [open, setOpen] = useState<boolean>(true);
  const [unitName, setUnitName] = useState<string>("");
  const [categories, setCategories] = useState<any>([]);
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [toBeSelectedLevel, setToBeSelectedLevel] = useState<string>("");
  const [ctg1OpenId, setCtg1OpenId] = useState<string>("");
  const [ctg2OpenId, setCtg2OpenId] = useState<string>("");
  const [paramsLoading, setParamsLoading] = useState<boolean>(false);
  const [params, setParams] = useState<any>([]);
  const [showWarningPopup, setShowWarningPopup] = useState<string>("");
  const [newParams, setNewParams] = useState<any>([]);
  const [favCategoryParams, setFavCategoryParams] = useState<any>(null);
  const [userPreferences, setUserPreferences] = useState<any>({});
  const [onChangeWarningPopup, setOnChangeWarningPopup] = useState<any>({});
  const [warningShown, setWarningShown] = useState<WarningShownState>({});

  const favCategory = { id: "Favorites", name: "Favorites", children: [], collapsible: false, params: [] };

  const isAlthermaBrand = allUnits[unitId]?.brand === 10;

  const { canUpdateUnitConfigParams } = allUnits[unitId]?.permissions;

  useEffect(() => {
    setUnitName(getUnitName(unitId, false));
    setCategoriesLoading(true);
    getUnitCategories(unitId)
      .then((res: any) => {
        const sortedResp: any[] = _.orderBy(Object.values(res), ["level"], ["desc"]);

        const lvl1: any = {}, lvl2: any = {}, lvl3: any = {};
        for (let category of sortedResp) {
          if (category.level === 3) {
            lvl3[category.parent] = [...(lvl3[category.parent] || []), category];
          }
          if (category.level === 2) {
            const obj = category;
            if (category.id in lvl3) {
              obj["children"] = _.orderBy(lvl3[category.id], [(child: any) => child.name?.toLowerCase()], ["asc"]);
              if (obj["children"].length > 0) {
                obj["collapsible"] = true;
              }
            }
            lvl2[category.parent] = [...(lvl2[category.parent] || []), obj];
          }
          if (category.level === 1) {
            const obj = category;
            if (lvl2.hasOwnProperty(category.id)) {
              obj["children"] = _.orderBy(lvl2[category.id], [(child: any) => child.name?.toLowerCase()], ["asc"]);
              if (obj["children"].length > 0) {
                obj["collapsible"] = true;
              }
            }
            lvl1[category.id] = obj;
          }
        }
        const orderedCategories = _.orderBy(lvl1, [(category: any) => category.name?.toLowerCase()], ["asc"]);
        setCategories(_.isEmpty(orderedCategories) ? [] : [favCategory, ...orderedCategories]);
      })
      .catch((err: any) => {
        setCategories({});
        addMessage({ message: err.message });
      })
      .finally(() => setCategoriesLoading(false));

    // don't call the API if we alredy have the userPreferences
    if (_.isEmpty(userPreferences)) {
      getUserPreferences()
        .then((res: any) => {
          setFavCategoryParams(res.altherma_inst_favorites || []);
          setUserPreferences(res);
        })
    }
    else {
      setFavCategoryParams(userPreferences.altherma_inst_favorites || []);
      setUserPreferences(userPreferences);
    }
  }, []);

  useEffect(() => {
    if (favCategoryParams === null) {
      return;
    }
    setUserPreferences({ ...userPreferences, altherma_inst_favorites: favCategoryParams });
    updateUserPreferences({ ...userPreferences, altherma_inst_favorites: favCategoryParams });
  }, [favCategoryParams]);

  const checkForChanges = (id: string) => {
    let changed = false;
    newParams.forEach((param: any, index: number) => {
      if (+param.value !== +params[index].value && changed === false) {
        changed = true; // break it here
      }
    });
    if (changed) {
      setShowWarningPopup(id);
      return true;
    }

    return false;
  };

  const handleCtgClick = (id: string, lvl: string) => {
    if (id !== selectedId) {
      setToBeSelectedLevel(lvl);
      if (!_.isEmpty(params)) {
        if (checkForChanges(id)) {
          return;
        }
      }
      selectParam(id, false);
    }

    if (lvl === "ctg1") {
      if (ctg1OpenId === id) {
        setCtg1OpenId("");
        setCtg2OpenId("");
      } else {
        setCtg1OpenId(id);
      }
      return;
    }

    if (lvl === "ctg2") {
      if (ctg2OpenId === id) {
        setCtg2OpenId("");
      } else {
        setCtg2OpenId(id);
      }
      return;
    }
  };

  const selectParam = (categoryId: string, isMovingFromDiscard: boolean) => {
    if (categoryId === selectedId) {
      return;
    }
    if (isMovingFromDiscard) {
      if (toBeSelectedLevel === "ctg1") {
        setCtg1OpenId(categoryId);
      }
      if (toBeSelectedLevel === "ctg2") {
        setCtg2OpenId(categoryId);
      }
    }
    setSelectedId(categoryId);
    setParamsLoading(true);
    setParams([]);

    if (categoryId === "Favorites") {

      if (_.isEmpty(favCategoryParams)) {
        setParams([]);
        setParamsLoading(false);
        return;
      }

      getUnitCategoryParamsByParams({ unitId, params: favCategoryParams })
        .then((data: any) => {
          if (_.isEmpty(data)) {
            setParams([]);
            setParamsLoading(false);
            return;
          }
          const params = Object.values(data).map((param: any) => ({ ...param.dbData, liveData: param?.liveData, value: `${param.liveData?.value}` ? +param.liveData?.value : null, fav: true }));
          setParams(params);
          setParamsLoading(false);
        })
        .catch((err: any) => {
          setParamsLoading(false);
          addMessage({ message: err.message });
        });

      return;
    }

    getUnitCategoryParams({ unitId, category: categoryId })
      .then((data: any) => {
        if (_.isEmpty(data)) {
          setParams([]);
          setParamsLoading(false);
          return;
        }

        const favIds: any = {};
        favCategoryParams.forEach((id: string) => {
          favIds[id] = true;
        });
        const params = Object.values(data).map((param: any) => ({ ...param.dbData, liveData: param?.liveData, value: `${param.liveData?.value}` ? +param.liveData?.value : null, fav: !!favIds[param.dbData.id] }));
        setParams(params);
        setParamsLoading(false);
      })
      .catch((err: any) => {
        setParamsLoading(false);
        addMessage({ message: err.message });
      });
  };

  const handleReset = (values: any) => {
    const array = values.map((param: any, index: number) => ({ ...params[index], fav: param.fav }));
    setParams([...array]);
  };

  const discardChanges = () => {
    const categoryId = showWarningPopup;
    setParams([]);
    selectParam(categoryId, true);
    setShowWarningPopup("");
  };

  const handleClose = () => {
    setIsSaving(false);
    setOpen(false);
    onClose();
  };
  const updateFavorites = (param: any, index: number, setFieldValue: any) => {
    if (!param.fav) {
      if (favCategoryParams.length === 20) {
        addMessage({ message: "You can't add more than 20 items to favorite" });
        return;
      }
      setFavCategoryParams([...favCategoryParams, param.id]);
    } else {
      setFavCategoryParams(favCategoryParams.filter((paramId: string) => paramId !== param.id));
    }
    setFieldValue(`params.${index}.fav`, !param.fav);
  };

  if (!isInitialized) { return <Loading />; }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Unit Settings`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleClose}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      {isSaving ? (<EmptyScreen loader message={t`Saving`} />)
        : categoriesLoading ? <EmptyScreen loader /> :
          _.isEmpty(categories) ? <EmptyScreen message={t`This unit does not have configuration parameters.`} /> :
            <Paper elevation={0} className={classes.paperTableContainer}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>

                <Typography className={classes.unitName}>{unitName}</Typography>
                {isAlthermaBrand && <Tooltip title={t`Reset Settings`} arrow>
                  <Button
                    white
                    disabled={!canUpdateUnitConfigParams}
                    className={classes.althermaSettings}
                    onClick={() => setOnChangeWarningPopup({
                      showChangeWarning: true,
                      changeWarningString: t`Are you sure you want to reseting settings?`,
                      cancelBtnTitle: "No",
                      AcceptBtnTitle: "Yes",
                      callback: () => {
                        setOnChangeWarningPopup({});
                        setIsSaving(true);
                        resetAlthermaSettings({ deviceId: allUnits[unitId]?.device, lineId: allUnits[unitId]?.line })
                          .catch((err: any) => addMessage({ message: err.message }))
                          .finally(() => handleClose());
                      }
                    })}>
                    {t`Reset Settings`}
                  </Button>
                </Tooltip>}
              </div>
              <div className={classes.borderedArea}>
                <div className={classes.categoryList}>
                  <div className={classes.ctgColumn}>
                    {categories.map((category: any) => {
                      const { id, children = [], name, collapsible } = category;

                      return (
                        <div key={`category-box-${id}`}>
                          <div className={clsx(classes.ctgLvl1Holder, { [classes.selected]: selectedId === id })} onClick={() => handleCtgClick(id, "ctg1")}>
                            {collapsible && <SmallSideArrow className={clsx(classes.tabsArrow, { [classes.rotateArrow]: ctg1OpenId === id })} color="#7f7692" />}
                            <Typography className={classes.ctgLvl1Name}>{name}</Typography>
                          </div>
                          {ctg1OpenId === id && children.map((category2: any) => {
                            const { id, children, name, collapsible } = category2;

                            return (
                              <>
                                <div className={clsx(classes.ctgLvl2Holder, { [classes.selected]: selectedId === id })} onClick={() => handleCtgClick(id, "ctg2")}>
                                  {collapsible && <SmallSideArrow className={clsx(classes.tabsArrow, { [classes.rotateArrow]: ctg2OpenId === id })} color="#7f7692" />}
                                  <Typography className={classes.ctgLvl2Name}>{name}</Typography>
                                </div>
                                {
                                  ctg2OpenId === id && children?.map((child: any) =>
                                    <Typography
                                      key={`ctg3-${child.id}`}
                                      className={clsx(classes.ctglvl3Name, { [classes.selected]: selectedId === child.id })}
                                      onClick={() => handleCtgClick(child.id, "ctg3")}
                                    >
                                      {child.name}
                                    </Typography>)
                                }
                              </>
                            );
                          })
                          }
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={classes.rightSideContainer}>
                  <TableContainer className={classes.tableContainer}>
                    {!selectedId ? <EmptyScreen message={t`Please select a category.`} /> : paramsLoading ? <EmptyScreen loader /> :
                      selectedId && _.isEmpty(params) ? <EmptyScreen message={t`No configuration parameters are available for this unit in this category.`} /> :
                        <Formik
                          initialValues={{ params }}
                          enableReinitialize={true}
                          onSubmit={(values) => {
                            const paramsToUpdate = _.differenceWith(values.params, params, _.isEqual).map((param: any) => ({ id: param.id, value: +param.value }));
                            setIsSaving(true);
                            updateUnitParamsValues({ id: unitId, data: { values: paramsToUpdate } })
                              .then(() => {
                                setParams(values.params);
                                if (isAlthermaBrand) {
                                  setOnChangeWarningPopup({
                                    showChangeWarning: true,
                                    changeWarningString: t`Some parameter changes require a unit restart. Do you want to reset the unit?`,
                                    cancelBtnTitle: "No",
                                    AcceptBtnTitle: "Yes",
                                    close: () => handleClose(),
                                    callback: () => {
                                      setOnChangeWarningPopup({});
                                      resetAlthermaSettings({ deviceId: allUnits[unitId]?.device, lineId: allUnits[unitId]?.line })
                                        .catch((err: any) => addMessage({ message: err.message }))
                                        .finally(() => handleClose());
                                    }
                                  });
                                }
                                else {
                                  handleClose();
                                }
                              })
                              .catch((err: any) => {
                                addMessage({ message: err.message });
                                handleClose();
                              });
                          }}
                          render={({ values, errors, touched, handleSubmit, setFieldValue, setValues }: any) => {

                            setNewParams(values.params);

                            return (
                              // @ts-ignore
                              <Form translate="yes" onSubmit={handleSubmit} style={{ width: "100%" }}>
                                <Table stickyHeader className={classes.table} aria-label="parameters table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell classes={{ root: classes.tableHeadCell }} align="left">{t`Field Code`}</TableCell>
                                      <TableCell classes={{ root: classes.tableHeadCell }} align="left">{t`Settings Name`}</TableCell>
                                      <TableCell classes={{ root: classes.tableHeadCell }} align="left">{t`Range`}</TableCell>
                                      <TableCell classes={{ root: classes.tableHeadCell }} align="left">{t`Current Value`}</TableCell>
                                      <TableCell classes={{ root: classes.tableHeadCell }} align="left">{t`Favorites`}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <FieldArray
                                      name="params"
                                      render={({ insert, remove, push }: any) => (
                                        <>
                                          {
                                            values.params.length > 0 &&
                                            values.params
                                            .sort((paramA: any, paramB: any) => {
                                              const keyA = paramA.codeName || paramA.code;
                                              const keyB = paramB.codeName || paramB.code;
                                              return keyA.localeCompare(keyB);
                                            })
                                            .map((param: any, index: number) => (
                                              <TableRow key={param.id} style={{ height: 60 }}>
                                                <TableCell classes={{ root: classes.overWritePadding }} style={{ minWidth: 101 }} align="left">
                                                  {param.codeName || param.code}
                                                </TableCell>
                                                <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                  {param.name}
                                                </TableCell>
                                                <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                  {param.rangeMinString || ""}{param.rangeSeparatorString || ""}{param.rangeMaxString || ""}{param.measurementUnits || ""}
                                                </TableCell>
                                                <TableCell classes={{ root: classes.overWritePadding }} style={{ minWidth: 130, position: "relative" }} align="left">
                                                  {param.enum && serviceParamTypesOptions[param.enum] ?
                                                    <FastField
                                                      label={""}
                                                      name={`params.${index}.value`}
                                                      options={serviceParamTypesOptions[param.enum]}
                                                      component={Select}
                                                      variant="outlined"
                                                      placeholder={t`Select value`}
                                                      value={+param.value}
                                                      className={classes.selectField}
                                                      classes={{ icon: classes.arrowIconSelect }}
                                                      IconComponent={SvgArrow}
                                                      disabled={!param.isWritable || !canUpdateUnitConfigParams}
                                                      margin="none"
                                                      MenuProps={{
                                                        anchorOrigin: {
                                                          vertical: "bottom",
                                                          horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                          vertical: "top",
                                                          horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                      }}
                                                      onChange={(event: any) => {
                                                        if (param.isChangeWarning && !warningShown[`params.${index}.value`]) {
                                                          event.persist(); // keep the original event object around
                                                          const newValue = event.target.value;
                                                          setOnChangeWarningPopup({
                                                            showChangeWarning: true,
                                                            changeWarningString: param.changeWarningString,
                                                            callback: () => {
                                                              setOnChangeWarningPopup({});
                                                              setFieldValue(`params.${index}.value`, newValue);
                                                              setWarningShown((prevState) => ({ ...prevState, [`params.${index}.value`]: true }));
                                                            }
                                                          });
                                                        }
                                                        else {
                                                          setFieldValue(`params.${index}.value`, event.target.value);
                                                        }
                                                      }}
                                                    /> : param.isWritable ?
                                                      <>
                                                        <FastField
                                                          name={`params.${index}.value`}
                                                          placeholder={t`Value`}
                                                          value={param.value}
                                                          validate={(value: any) => validateValue(value, param)}
                                                          className={classes.txtField}
                                                          disabled={(param?.liveData === null ? true : false) || !canUpdateUnitConfigParams}
                                                          onChange={(event: any) => {
                                                            if (param.isChangeWarning && !warningShown[`params.${index}.value`]) {
                                                              event.persist(); // keep the original event object around
                                                              const newValue = event.target.value;
                                                              setOnChangeWarningPopup({
                                                                showChangeWarning: true,
                                                                changeWarningString: param.changeWarningString,
                                                                callback: () => {
                                                                  setOnChangeWarningPopup({});
                                                                  setFieldValue(`params.${index}.value`, newValue);
                                                                  setWarningShown((prevState) => ({ ...prevState, [`params.${index}.value`]: true }));
                                                                }
                                                              });
                                                            }
                                                            else {
                                                              setFieldValue(`params.${index}.value`, event.target.value);
                                                            }
                                                          }}
                                                        />
                                                        {touched.params && errors.params && touched?.params[index]?.value && errors?.params[index]?.value ?
                                                          <Typography className={classes.errorStyle}>{errors?.params[index]?.value}</Typography> : null} </>
                                                      : param.value
                                                  }
                                                </TableCell>
                                                <TableCell classes={{ root: classes.overWritePadding }} align="center">
                                                  <LightTooltip title={t`Save to Favorites`}>
                                                    <IconButton disableRipple onClick={() => updateFavorites(param, index, setFieldValue)} className={classes.iconBtnStyle}>
                                                      {param.fav ? <PressedStar /> : <Star />}
                                                    </IconButton>
                                                  </LightTooltip>
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                        </>
                                      )}
                                    />
                                  </TableBody>
                                </Table>
                                <div className={classes.actionsHolder}>
                                  <Button width={150} marginRight white className={classes.cancelBtn} onClick={() => {
                                    const array = values.params.map((param: any, index: number) => ({ ...params[index], fav: param.fav }));
                                    setValues({ params: array });
                                    onClose();
                                  }}>{t`Cancel`}</Button>
                                  <Button disabled={!canUpdateUnitConfigParams} width={159} type="submit">{t`Update Unit`}</Button>
                                </div>
                              </Form>
                            );
                          }}
                        />
                    }
                  </TableContainer>
                </div>
              </div>
              <div className={classes.actionsContainer}>
              </div>
            </Paper>}
      {showWarningPopup && <ErrorBox error={t`Do you want to discard changes?`} onAccept={discardChanges} onClose={() => setShowWarningPopup("")} />};
      {onChangeWarningPopup.showChangeWarning && <ErrorBox
        title={t`Warning!`}
        error={onChangeWarningPopup.changeWarningString}
        onAccept={onChangeWarningPopup.callback}
        onClose={() => {
          onChangeWarningPopup?.close && onChangeWarningPopup?.close();
          setOnChangeWarningPopup({});
        }}
        cancelBtnTitle={onChangeWarningPopup?.cancelBtnTitle || null}
        AcceptBtnTitle={onChangeWarningPopup?.AcceptBtnTitle || null}
      />}
    </Dialog>
  );
};

export default AlthermaUnitSettings;
