import { makeStyles } from "@material-ui/core";

export default makeStyles((theme: any) => ({
  dialog: {
    width: "100%",
    borderRadius: "8px",
    maxWidth: "850px",
    height: "100%"
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden",
    padding: "25px",
    height: "calc(100% - 70px)"
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  field: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "space-between",
    marginBottom: "15px",
    width: "100%"
  },
  fieldTitle: {
    marginBottom: "15px",
    fontWeight: 500
  },
  fieldContent: {
    marginBottom: "15px",
    color: "#747881"
  },
  expireField: {
    display: "flex",
    width: "100%"
  },
  userInfo: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: "20px"
  },
  fullName: {
    fontSize: "20px",
    fontWeight: "bold",
    color: theme.palette.primary.light2 || "#4B1C46",
    marginBottom: "14px"
  },
  buttonsContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  datePickerRoot: {
    flexDirection: "row-reverse"
  }
}));
