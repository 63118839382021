import { CircularProgress, Grid, IconButton, Paper, TextField, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import { Plus, Search } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AddEditSetback from "./AddEditSetback";
import useStyle from "./Setback.style";
import SetbackScheduleTable from "./SetbackScheduleTable";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import useChurnZero from "@hooks/useChurnZero";

const Setback = (props: any) => {
  const classes = useStyle();
  const [setbackSchedules, setSetbackSchedules] = useState<any>({});
  const [filteredSetbackSchedules, setFilteredSetbackSchedules] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [openAddEdit, setOpenAddEdit] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { siteId } = useStoreState((state) => state.selections.selections);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { deleteSchedule, updateSetbackSchedule } = useStoreActions((actions) => actions.schedules);
  const userTemperatureScale = useStoreState((s) => s.users.me.temperatureScale || 1);
  const { temperatureScaleMirror } = useStoreState((state) => state);
  const isCelsius = +temperatureScaleMirror.celsius === +userTemperatureScale;
  const types = useStoreState((state) => state.types);
  const { timeFormat: timeFormatTypes } = types;
  const allSites = useStoreState((s) => s.sites.allSites);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const user = useStoreState((state) => state.users.me);
  const { timeFormat: myTimeFormat } = user;
  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours" ? true : false;
  const { getSiteSetbackSchedule } = useStoreActions((actions) => actions.schedules);
  const site = allSites[siteId || ""] || {};
  const { canCreateSchedules } = site?.permissions || {};

  const {trackEvent} = useChurnZero()

  useEffect(() => {
    if (siteId) {
      setIsLoading(true);
      getSiteSetbackSchedule(siteId)
        .then((schedules: any) => {
          setSetbackSchedules(schedules);
          setFilteredSetbackSchedules(Object.values(schedules));
          setIsLoading(false);
        })
        .catch((error: any) => {
          addMessage({ message: error.message });
        });
    }
  }, [siteId, userTemperatureScale]);

  useEffect(() => {
    if (!searchValue?.length) {
      setFilteredSetbackSchedules(Object.values(setbackSchedules));
      return;
    }
    const filteredSchedules = Object.values(setbackSchedules)?.filter((item: any) => (item?.name?.toUpperCase()?.includes(searchValue?.toUpperCase()) || item?.units?.find((unit: any) => allUnits[unit]?.name?.toUpperCase()?.includes(searchValue?.toUpperCase()))));
    setFilteredSetbackSchedules(filteredSchedules);

  }, [searchValue, setbackSchedules]);

  const handleCloseDialog = () => {
    setOpenAddEdit(null);
  };

  const deleteSelectedSchedule = (scheduleId: string) => {
    deleteSchedule(scheduleId)
      .then(() => {
        const { [scheduleId]: toDelete, ...restOfSchedules } = setbackSchedules;

        setSetbackSchedules(restOfSchedules);
        // setFilteredSetbackSchedules(Object.values(restOfSchedules))
      })
      .catch((err: any) =>
        addMessage({ message: err.message })
      );
  };

  const updateSetBackStatus = (setbackSchedule: any) => {
    const data: any = {
      name: setbackSchedule?.name,
      isDisabled: !setbackSchedule?.isDisabled
    };
    updateSetbackSchedule({ id: setbackSchedule?.id, data })
      .then((res: any) => {
        setSetbackSchedules({ ...setbackSchedules, [setbackSchedule?.id]: res });
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const handleAddScheduleButtonClick = () => {
    trackEvent('SetbackAddRule','The user initiated a setback rule setup');
    setOpenAddEdit({});
  };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchValue}
      onChange={(event: any) => setSearchValue(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot },
        endAdornment:
          !searchValue ? (<Search />) : (
            <IconButton
              onClick={() => setSearchValue("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={[t`Setback`]}
          searchComponent={searchComponent}
          showDateRangePicker={false}
          hideSystemSelection={true}
          countControlUnits
          hideOutdoor
          hideBsBox
          hideIndoor
          hideOthe
          hideUnitSelection={true}
          screenTitle="Setback"
        />
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <Typography className={classes.title}>{t`Setbacks`}</Typography>
          </div>
          {!siteId ? <FilterRequire type={t`site`} /> :
            <Paper elevation={0} className={classes.paperTableContainer}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid className={classes.titleContainer}>
                    <Typography className={classes.title}>{t`Schedules`}</Typography>
                    <Button
                      white
                      disabled={!canCreateSchedules}
                      className={classes.btn}
                      startIcon={<Plus className={classes.plusIcon} />}
                      onClick={handleAddScheduleButtonClick}>
                      {t`Add Schedule`}
                    </Button>
                  </Grid>
                  {isLoading ?
                    <div className={classes.loaderContainer}>
                      <CircularProgress />
                    </div>
                    :
                    <SetbackScheduleTable
                      deleteSelectedSchedule={deleteSelectedSchedule}
                      updateSetBackStatus={updateSetBackStatus}
                      setOpenAddEdit={setOpenAddEdit}
                      setbackSchedules={filteredSetbackSchedules}
                      setSetbackSchedules={setSetbackSchedules}
                      is12Hours={is12Hours}
                    />}
                </Grid>
              </Grid>
            </Paper>}
          {
            !!openAddEdit && <AddEditSetback
              siteId={siteId}
              isEdit={!_.isEmpty(openAddEdit)}
              handleCloseDialog={handleCloseDialog}
              setbackSchedules={setbackSchedules}
              setSetbackSchedules={setSetbackSchedules}
              schedule={openAddEdit}
              isCelsius={isCelsius}
              is12Hours={is12Hours}
            />
          }
        </div>
      </div>
    </div>
  );
};
export default Setback;
