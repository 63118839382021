import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core";
import styles from "./AddEditScript.style";
import Select, {components} from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const customStyles = (isError: boolean, theme: any) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    width: '300px',
    minHeight: '38px',
    maxHeight: "38px",
    boxShadow: "none",
    borderColor: isError ? theme.palette.secondary.main : '#cccccc !important',
    "&hover": {
      borderColor: isError ? theme.palette.secondary.main : '#cccccc'
    },
    fontFamily: 'Roboto',

  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '300px',
    zIndex: 9999,
    fontFamily: 'Roboto',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.colors.gray,
    display: "flex",
    alignItems: "center",
    fontFamily: 'Roboto',
  }),
  multiValue: (provided: any) => ({
  ...provided,
  marginTop: '7px',
  marginBottom: '7px',
  minWidth: "75px",
  fontFamily: 'Roboto',
}),
  valueContainer: (provided: any) => ({
  ...provided,
  maxHeight: "36px",
  overflowX: "hidden",
  flexFlow: "row nowrap",
  fontFamily: 'Roboto',
}),
placeholder: (provided: any) => ({
...provided,
fontFamily: 'Roboto',
color: "#545964",
opacity: 0.7,
fontSize: "16px",
fontWeight: "normal",
}),
input: (provided: any) => ({
...provided,
fontFamily: 'Roboto',
color: "#545964",
opacity: 0.7,
fontSize: "16px",
fontWeight: "normal",
}),
menuList: (provided: any) => ({
...provided,
maxHeight: "285px",
}),
});

const CustomOption = ({ selectedValues, classes, ...props}: any) => {
  if(props.data.id === "fake-system")
      return <div className={classes.fakeSystemBlock}>{props.label}</div>

  const unitSystemIsSelected = !!selectedValues[props.data.system];

  const {Option} = components

  return (
    <Option {...props}>
    <span className={clsx(classes.multiSelectcheckbox, {[classes.subItemMargin]: props.data.entityType === "unit", [classes.disabledOption]: props.isDisabled})}>
      {props.isSelected  || unitSystemIsSelected ? '\u2713' : ''}
    </span>
    <label className={clsx(classes.multiSelectcheckboxLabel, {[classes.disabledOption]: props.isDisabled})} >{props.label}</label>
    </Option>
  )
};

const SearchableSelect = ({ name, options, onChange, forceRender, ...props }: any) => {

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const theme = useTheme();

  const [selected, setSelected] = useState([]);
  const [selectedObj, setSelectedObj] = useState<any>({});

  useEffect(() => {
    setSelected(props.value);
    const selectedIds: any = {};
    props.value?.forEach((item: any) => selectedIds[item.id] = true)
    setSelectedObj(selectedIds);
  }, [forceRender])

  const handleChange = (selectedOption: any) => {
    const selectedIds: any = {};
    selectedOption?.forEach((item: any) => selectedIds[item.id] = true)
    let options: any = selectedOption.filter((option: any) => !selectedIds[option.system])
    setSelected(options);
    setSelectedObj(selectedIds);
    onChange(options);
  };

  return (
    <Select
      name={name}
      isDisabled={props.disabled}
      options={options}
      onChange={handleChange}
      value={selected}
      isSearchable
      menuPlacement="auto"
      menuPosition="fixed"
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.id}
      components={{ Option: (props: any) => <CustomOption classes={classes} selectedValues={selectedObj} {...props}/>}}
      styles={customStyles(!!props.error && !selected.length, theme)}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      placeholder={props.placeholder}
      isOptionDisabled={(option: any) => (!!selectedObj[option.system] || (option.entityType === "system" && option.isDisabled))}
    />
  );
};

export default SearchableSelect;
