import React, { memo, useCallback } from 'react';
import Draggable from 'react-draggable';
import DraggableDataPoint from '../DraggableDataPoint';
import useStyles from './styles';

const MappingArea = memo(
  ({
    dataPoints = {},
    selectedDataPoint = null,
    onSelectDataPoint = () => {},
    onUpdateDataPointPosition = () => {},
    onDeleteDataPoint = () => {},
    templateImg,
    mode = 'edit',
    onChangeControlValue = () => {},
    dataPointsValues = {},
  }) => {
    const classes = useStyles();
    const isEditMode = mode === 'edit';

    const handleDelete = useCallback(
      (dataPointId) => {
        onSelectDataPoint(null);
        onDeleteDataPoint(dataPointId);
      },
      [onSelectDataPoint, onDeleteDataPoint]
    );

    const handleSelect = useCallback(
      (dataPointId) => {
        if (isEditMode) {
          onSelectDataPoint(dataPointId);
        }
      },
      [isEditMode, onSelectDataPoint]
    );

    const handleDrag = useCallback(
      (dataPointId, { x, y }) => {
        if (isEditMode) {
          onUpdateDataPointPosition(dataPointId, { x, y });
        }
      },
      [isEditMode, onUpdateDataPointPosition]
    );

    const renderDataPoint = useCallback(
      ([dataPointId, dataPoint]) => {
        const draggableProps = {
          key: dataPointId,
          bounds: 'parent',
          position: dataPoint.position,
          disabled: !isEditMode,
          onStart: () => handleSelect(dataPointId),
          onClick: () => handleSelect(dataPointId),
          onDrag: (_, position) => handleDrag(dataPointId, position),
        };

        return (
          <Draggable {...draggableProps}>
            <div className={classes.dataPointMapWrapper}>
              <DraggableDataPoint
                isSelected={selectedDataPoint === dataPointId}
                dataPoint={dataPoint}
                onDelete={() => handleDelete(dataPointId)}
                mode={mode}
                controlValue={dataPointsValues[`${dataPointId}/85`]}
                onChangeControlValue={(value) => {
                  onChangeControlValue(`${dataPointId}`, value);
                }}
              />
            </div>
          </Draggable>
        );
      },
      [selectedDataPoint, isEditMode, handleSelect, handleDrag, handleDelete, dataPointsValues]
    );

    return (
      <div className={classes.mappingContainer}>
        <div
          className={classes.mappingHolder}
          style={{ backgroundImage: `url(${templateImg})` }}
        >
          {Object.entries(dataPoints).map(renderDataPoint)}
        </div>
      </div>
    );
  }
);

MappingArea.displayName = 'MappingArea';

export default MappingArea;
