/* eslint-disable react/display-name */
import { isEmpty } from 'lodash';
import React, { useState, useCallback, memo, useEffect, useMemo } from 'react';
import { t as translate } from 'ttag';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import ButtonV2 from '@cool_widgets/ButtonV2';
import {
  useCreateBacnetUiTemplate,
  useUpdateBacnetUiTemplate,
  useUploadBacnetUiTemplateImg,
  useGetSiteBacnetUiTemplates,
} from '@hooks/useBacnetUiTemplatesApi';
import { useStoreState } from '@models/RootStore';
import useBacNet from '@hooks/useBacNet';
import TemplateArea from './TemplateArea';
import InitialScreen from './InitialScreen';
import {
  modalStages,
  templateSource,
  mergeUiTemplatePointsWithParamTemplate,
} from './constants';
import useSaveConfirmationDialog from './SaveConfirmationModal'; // Adjust path as needed

import useStyles from './styles';

const AddEditBacnetUiTemplateDialog = ({
  open,
  onClose,
  defaultParamTemplate,
}) => {
  const classes = useStyles();
  const { siteId } = useStoreState((s) => s.selections.selections);
  const [showConfirmation, DialogComponent] = useSaveConfirmationDialog();

  const [stage, setStage] = useState(modalStages.image);
  const [isCreating, setIsCreating] = useState(false);

  // Inital data
  const { data: templates, refetch: refetchGetSiteBacnetUiTemplates } =
    useGetSiteBacnetUiTemplates(siteId);
  const { mutate: uploadBacnetUiTemplateImg } = useUploadBacnetUiTemplateImg();
  const [templateImgPreview, setTemplateImagePreview] = useState(null);
  const [initialData, setInitialData] = useState({
    name: '',
    templateImg: null,
    source: templateSource.new,
  });

  const isNextButtonDisabled = useMemo(() => {
    return (
      !templateImgPreview ||
      (initialData.source === templateSource.new && isEmpty(initialData.name)) ||
      (initialData.source === templateSource.existing && !initialData.template)
    );
  }, [initialData, templateImgPreview]);

  const handleGoToTemplateStage = useCallback(() => {
    setStage(modalStages.template);
  });

  useEffect(() => {
    if (initialData?.templateImg) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTemplateImagePreview(reader.result);
      };
      reader.readAsDataURL(initialData.templateImg.file);
    }
  }, [initialData.templateImg]);

  // Template area data
  const { mutate: createBacnetUiTemplate } = useCreateBacnetUiTemplate();
  const { mutate: updateBacnetUiTemplate } = useUpdateBacnetUiTemplate();
  const { getSiteBacNetParameterTemplates, siteBacNetParameterTemplates } =
    useBacNet();
  const [selectedParamTemplate, setSelectedParamTemplate] = useState(null);
  const [currentDataPoints, setCurrentDataPoints] = useState({});

  useEffect(() => {
    if (siteId) {
      getSiteBacNetParameterTemplates(siteId);
    }
  }, [siteId]);

  const handleClose = useCallback(() => {
    onClose?.();
    setTimeout(() => {
      //Reset data
      setInitialData({
        name: '',
        templateImg: null,
        source: templateSource.new,
      });
      setSelectedParamTemplate(null);
      setCurrentDataPoints({});
      setTemplateImagePreview(null);
      setStage(modalStages.image);
    }, 100);
  }, [onClose]);

  useEffect(() => {
    setSelectedParamTemplate(defaultParamTemplate);
  }, [defaultParamTemplate]);

  // watch for template data and initialize the stage
  useEffect(() => {
    if (initialData.template) {
      setSelectedParamTemplate(initialData.template.paramTemplate);
      const points = mergeUiTemplatePointsWithParamTemplate(
        siteBacNetParameterTemplates[initialData.template.paramTemplate]?.data,
        initialData.template?.data?.points
      );
      setCurrentDataPoints(points);
      setTemplateImagePreview(initialData.template?.fileURL);
    }
  }, [initialData.template]);

  // Handle save template area
  const handleSave = useCallback(async () => {
    let isNewTemplate = true;
    let templateName = initialData.name;
    if (initialData?.source === templateSource.existing) {
      const result = await showConfirmation();
      if (!result) {
        return;
      }
      isNewTemplate = result.isNew;
      templateName = isNewTemplate ? result.newName : templateName;
    }

    setIsCreating(true);

    let fileId = initialData?.template?.fileId;

    if (initialData.templateImg) {
      try {
        fileId = await uploadBacnetUiTemplateImg({
          filePayload: initialData.templateImg,
        });
      } catch (error) {
        console.error('Failed to upload Bacnet UI template image:', error);
        setIsCreating(false);
        return;
      }
    }

    try {
      if (!isNewTemplate) {
        await updateBacnetUiTemplate({
          templateId: initialData.template.id,
          payload: {
            data: {
              points: currentDataPoints,
            },
            paramTemplate: selectedParamTemplate,
            fileId,
          },
        });
      } else {
        await createBacnetUiTemplate({
          data: {
            points: currentDataPoints,
          },
          name: templateName,
          description: `BACnet UI template for ${siteId} ${templateName}`,
          site: siteId,
          paramTemplate: selectedParamTemplate,
          fileId,
        });
      }
      handleClose();
    } catch (error) {
      console.error('Failed to save Bacnet UI template:', error);
    } finally {
      setIsCreating(false);
      refetchGetSiteBacnetUiTemplates();
    }
  }, [
    uploadBacnetUiTemplateImg,
    createBacnetUiTemplate,
    currentDataPoints,
    handleClose,
    siteId,
    initialData,
    selectedParamTemplate,
  ]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle className={classes.dialogTitle}>
          {initialData?.source === templateSource.existing
            ? translate`Edit control UI`
            : translate`Add control UI`}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeButton}
            disabled={isCreating}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {stage === modalStages.image ? (
            <InitialScreen
              data={initialData}
              onChange={setInitialData}
              templates={templates}
              templateImgPreview={templateImgPreview}
              resetToSelectedTemplateImg={() => {
                setTemplateImagePreview(initialData.template?.fileURL);
              }}
              removeTemplateImg={() => {
                setInitialData({
                  ...initialData,
                  templateImg: null,
                });
                setTemplateImagePreview(null);
              }}
            />
          ) : (
            <TemplateArea
              templateImgPreview={templateImgPreview}
              dataPoints={currentDataPoints}
              onChange={setCurrentDataPoints}
              paramTemplates={siteBacNetParameterTemplates}
              selectedParamTemplate={selectedParamTemplate}
              onSelectParamTemplate={(newVal) => {
                setSelectedParamTemplate(newVal);
                setCurrentDataPoints({});
              }}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <ButtonV2
            onClick={handleClose}
            disabled={isCreating}
            variant="outlined"
          >
            {translate`Cancel`}
          </ButtonV2>
          {stage === 'image' ? (
            <ButtonV2
              onClick={handleGoToTemplateStage}
              variant="contained"
              disabled={isNextButtonDisabled}
            >
              {translate`Next`}
            </ButtonV2>
          ) : (
            <ButtonV2
              onClick={handleSave}
              variant="contained"
              disabled={isCreating}
            >
              {translate`Save`}
            </ButtonV2>
          )}
        </DialogActions>
      </Dialog>
      <DialogComponent />
    </>
  );
};

export default memo(AddEditBacnetUiTemplateDialog);
