import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Switch as MUISwitch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { CoolMode, HotMode } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./SiteCountCard.style";

const SiteCountCard: React.FC<any> = (props) => {
  const { totalIndoorUnits, conditionIndoorUnits, blueIndoorUnits, redIndoorUnits, title, customerId, siteId } = props;
  const classes = useStyles();
  const redAndBlueValue = ((redIndoorUnits + blueIndoorUnits) / totalIndoorUnits) * 100;
  const blueValue = (blueIndoorUnits / totalIndoorUnits) * 100;
  const [openWorkingUnits, setOpenWorkingUnits] = useState<any>(null);
  const [workingUnits, setWorkingUnits] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const getAllOnUnits = useStoreActions((actions) => actions.units.getAllOnUnits);
  const { operationStatusesMirror } = useStoreState((state) => state);
  const types = useStoreState((state) => state.types);
  const { operationStatuses } = types;
  const { on: powerOnVal, off: powerOffVal } = operationStatusesMirror;
  const allUnits = useStoreState((state) => state.units.allUnits);
  const unitPowerControl = useStoreActions((state) => state.units.changePowerState);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const [allWorkingUnits, setAllWorkingUnits] = useState<any>(null);
  const [mode, setMode] = useState<any>("");

  const handleWorkingUnits = (unitsCount: number, mode: any) => {
    if (unitsCount > 0) {
      setOpenWorkingUnits(mode);
      setLoading(true);
      setMode(mode);
      mode === "ALL" ? setAllWorkingUnits("open") : setAllWorkingUnits("");
      getAllOnUnits({ customerId, siteId })
        .then((res: any) => {
          setWorkingUnits(res);
          setLoading(false);
        });
    }
  };

  const toggleUnitPower = (event: any, unitId: string, activeOperationStatus: number) => {
    event.stopPropagation();
    event.preventDefault();
    const changeStatusTo: number =
      operationStatuses[activeOperationStatus] === "on" ? +powerOffVal : +powerOnVal;

    unitPowerControl({ unitId, state: +changeStatusTo })
      .catch((error: any) => addMessage({ message: error.message }));
  };

  return (
    <div id="site" className={classes.siteCard}>
      <div className={classes.container}>
        <div className={classes.cardHeader}>
          <Typography className={classes.cardTitle}>{t`Units ON`}</Typography>
        </div>
        <div className={classes.row}>
          <div
            id="column1"
            className={classes.column1Holder}
            onClick={() => handleWorkingUnits(conditionIndoorUnits, "ALL")}
          >
            <div className={classes.relativeContainer}>
              <Typography className={classes.onIndoorCountStyle}>{Math.round(conditionIndoorUnits)}</Typography>
              <Typography className={classes.indoorUnitsCountStyle}>{Math.round(totalIndoorUnits)}</Typography>
              <CircularProgressbarWithChildren
                value={totalIndoorUnits !== 0 ? redAndBlueValue : 0}
                strokeWidth={4}
                styles={buildStyles({
                  pathColor: "#f00",
                  trailColor: "#eee"
                })}
              >
                <CircularProgressbar
                  value={totalIndoorUnits !== 0 ? blueValue : 0}
                  strokeWidth={4}
                  styles={buildStyles({
                    trailColor: "transparent"
                  })}
                />
              </CircularProgressbarWithChildren>
            </div>
          </div>
          <div id="column2" className={classes.column2Holder}>
            <Typography className={classes.modesCount} onClick={() => handleWorkingUnits(blueIndoorUnits, "COOL")}><CoolMode className={classes.modesIcons} /> {blueIndoorUnits}{" "}{t`Units`}</Typography>
            <Typography className={classes.modesCount} onClick={() => handleWorkingUnits(redIndoorUnits, "HEAT")}><HotMode className={classes.modesIcons} /> {redIndoorUnits}{" "}{t`Units`}</Typography>
          </div>
        </div>
        <div className={classes.row}>
          <Typography className={classes.indoorUnitsText}>{title}</Typography>
        </div>
      </div>

      {openWorkingUnits &&
        <Dialog
          onClose={() => setOpenWorkingUnits(null)}
          aria-labelledby="working-units-dialog"
          open={true}
          fullScreen={true}
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle className={classes.workingUnitsTitle} id="customized-dialog-title">
            {openWorkingUnits + t` working units`}
          </DialogTitle>
          <DialogContent>
            <div className={classes.dialogContent}>
              {loading ? (
                <div className={classes.loaderHolder}>
                  <CircularProgress />
                </div>
              ) : (<>
                {workingUnits && workingUnits.sites && Object.entries(workingUnits.sites)?.map((site: any, index: any) => {
                  if (!workingUnits.sites) {
                    return;
                  }
                  return (
                    site?.map((item: any) => {
                      const modes = ["cool", "heat", "other"];
                      if (!item.name) {
                        return;
                      }
                      const showHeatUnits = mode === "HEAT" && item?.numOfWorkingUnitsHeat > 0;
                      const showCoolUnits = mode === "COOL" && item?.numOfWorkingUnitsCool > 0;

                      return (
                        <>
                          <div>
                            {(allWorkingUnits || showHeatUnits || showCoolUnits) &&
                              <div className={classes.reportsHeader}>
                                <Typography className={classes.siteName}>
                                  {item?.name}
                                </Typography>
                                <div className={classes.headerInfoContainer}>
                                  {(allWorkingUnits || showCoolUnits) && <div className={classes.headerInfoSection}>
                                    <Typography className={classes.headerInfoVal}>
                                      {item?.numOfWorkingUnitsCool}
                                    </Typography>
                                    <Typography
                                      className={classes.headerInfoTitle}
                                      style={{ color: "#35a8e0" }}
                                    >{t`COOL`}</Typography>
                                  </div>}
                                  {(allWorkingUnits || showHeatUnits) && <div className={classes.headerInfoSection}>
                                    <Typography className={classes.headerInfoVal}>
                                      {item?.numOfWorkingUnitsHeat}
                                    </Typography>
                                    <Typography
                                      className={classes.headerInfoTitle}
                                      style={{ color: "#ff0000" }}
                                    >{t`HEAT`}</Typography>
                                  </div>}
                                  {allWorkingUnits && <div className={classes.headerInfoSection}>
                                    <Typography className={classes.headerInfoVal}>
                                      {item?.numOfWorkingUnitsOther}
                                    </Typography>
                                    <Typography
                                      className={classes.headerInfoTitle}
                                    >{t`OTHER`}</Typography>
                                  </div>}
                                </div>
                              </div>
                            }
                          </div>

                          {allWorkingUnits ? <>
                            {modes.map((mode: any, i: any) => {
                              return (
                                <TableContainer key={i} className={classes.tableContainer}>
                                  <Table stickyHeader className={classes.table} aria-label="customized table" key={i}>
                                    <TableHead>

                                      {item[`${mode}WorkingUnitIds`].length > 0 && <TableRow >
                                        <TableCell classes={{ root: classes.tableHeadCell }} style={{ minWidth: 250 }} align="left">  {t`Units in ${mode} mode`}
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tableHeadCell }} style={{ width: 200 }} align="right"> {t`Power`}
                                        </TableCell>
                                      </TableRow>}
                                    </TableHead>
                                    <TableBody>
                                      {item[`${mode}WorkingUnitIds`].map((unit: any) => {
                                        const unitObj = allUnits[unit];
                                        if (!unitObj) {
                                          return;
                                        }
                                        const { name, activeOperationStatus, permissions = {} } = unitObj;
                                        const { canControlOperationStatus } = permissions;
                                        const isItemOn = activeOperationStatus === +powerOnVal;
                                        const disabled = !canControlOperationStatus;

                                        return (
                                          <TableRow key={unit} classes={{ root: classes.overWritePaddingRow }} >
                                            <TableCell
                                              classes={{ root: classes.overWritePadding }}
                                              align="left"
                                            >
                                              {name}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.overWritePadding }} align="right">
                                              <MUISwitch
                                                className={disabled ? classes.switchDisabled : ""}
                                                checked={isItemOn}
                                                disableRipple={true}
                                                onChange={(e: any) => !disabled && toggleUnitPower(e, unit, activeOperationStatus)}
                                                value="checkedA"
                                                color="primary"
                                                size="medium"
                                              />
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              );
                            })}</> :
                            (<TableContainer className={classes.tableContainer}>
                              <Table stickyHeader className={classes.table} aria-label="customized table">
                                <TableHead>
                                  {item[`${mode.toLowerCase()}WorkingUnitIds`].length > 0 && <TableRow>
                                    <TableCell classes={{ root: classes.tableHeadCell }} style={{ minWidth: 250 }} align="left">
                                      {t`Unit Name`}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableHeadCell }} style={{ width: 200 }} align="right">
                                      {t`Power`}
                                    </TableCell>
                                  </TableRow>}
                                </TableHead>
                                <TableBody>
                                  {(item[`${mode.toLowerCase()}WorkingUnitIds`] && item[`${mode.toLowerCase()}WorkingUnitIds`].map((unit: any) => {
                                    const unitObj = allUnits[unit];
                                    if (!unitObj) {
                                      return;
                                    }
                                    const { name, activeOperationStatus, permissions = {} } = unitObj;
                                    const { canControlOperationStatus } = permissions;
                                    const isItemOn = activeOperationStatus === +powerOnVal;
                                    const disabled = !canControlOperationStatus;

                                    return (
                                      <TableRow key={`unit-row-${unit}`} classes={{ root: classes.overWritePaddingRow }}>
                                        <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                          {name}
                                        </TableCell>
                                        <TableCell classes={{ root: classes.overWritePadding }} align="right">
                                          <MUISwitch
                                            className={disabled ? classes.switchDisabled : ""}
                                            checked={isItemOn}
                                            disableRipple={true}
                                            onChange={(e: any) => !disabled && toggleUnitPower(e, unit, activeOperationStatus)}
                                            value="checkedA"
                                            color="primary"
                                            size="medium"
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }))}
                                </TableBody>
                              </Table>
                            </TableContainer>)}
                        </>);
                    }));
                })}
              </>)}
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setOpenWorkingUnits(null)} color="primary">
              {t`Close`}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

export default SiteCountCard;
