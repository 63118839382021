import React from 'react';
import { useTheme } from '@material-ui/core';

const SvgSwitcherOn = props => {
	const theme = useTheme();
	
	return (
		<svg width={50} height={50} {...props}>
			<g fill="none" fillRule="evenodd">
				<circle cx={25} cy={25} r={25} fill={theme.palette?.colors?.switch?.colorChecked || '#F05146'} />
				<g fill="#FDFDFE" fillRule="nonzero">
					<path d="M30.099 17.08a.937.937 0 0 1 1.309-.208c2.839 2.059 4.552 5.316 4.552 8.86 0 6.069-4.968 10.985-11.093 10.985S13.773 31.8 13.773 25.732c0-3.544 1.714-6.801 4.553-8.86a.937.937 0 1 1 1.1 1.518c-2.359 1.71-3.778 4.408-3.778 7.342 0 5.029 4.125 9.11 9.219 9.11 5.093 0 9.218-4.081 9.218-9.11 0-2.934-1.419-5.631-3.778-7.342a.937.937 0 0 1-.208-1.31z" />
					<path d="M24.639 13.772c.478 0 .872.358.93.82l.007.117v9.992a.938.938 0 0 1-1.868.117l-.007-.117v-9.992c0-.517.42-.937.938-.937z" />
				</g>
			</g>
		</svg>
	);
};

export default SvgSwitcherOn;
