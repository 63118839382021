import { Grid, Typography } from "@material-ui/core/";
import clsx from "clsx";
import React from "react";
import { Disconnect as DisconnectIcon } from "../../../../icons";
import { useStoreState } from "../../../../models/RootStore";
import useStyle from "../UnitItem/UnitItem.style";

const PowerMeterItem = (props: any) => {
  const classes = useStyle();

  const allPowerMeters = useStoreState((state) => state.powerMeters.allPowerMeters);
  const devices = useStoreState((state) => state.devices.allDevices);

  const { id, siteId, selectItem, groupId, selectedItem } = props;
  const item = allPowerMeters[id];
  if (!item) {
    return null;
  }
  const { name = "", power = 0, device = "" } = item;
  const selected = selectedItem.id === id && selectedItem.groupId === groupId;
  const deviceAndItemAreConnected = devices[device] && devices[device].isConnected && item.isConnected;

  return (
    <div
      className={clsx(classes.unitStyle, { [classes.selected]: selected }, !deviceAndItemAreConnected && classes.erroredDiv)}
      onClick={() => deviceAndItemAreConnected ?
        selectItem(siteId, { isGroup: false, id, groupId, type: "powerMeter" }) : {}}
    >
      <div className={classes.locSec}>
        <Typography noWrap className={clsx(classes.nameStyle, { [classes.boldText]: selected })}>{name}</Typography>
      </div>
      {deviceAndItemAreConnected ?
        <>
          <div className={classes.tempSec}>
            <div className={classes.iconDiv}>
            </div>
            <Typography className={clsx(classes.tempTextSensor, { [classes.boldText]: selected })}>
              {Math.round(power * 100) / 100}<span className={classes.powerUnit}>kW</span>
            </Typography>
          </div>
          <Grid className={classes.onOffSensor}>
            <div className={classes.notesSec}>
              <div></div>
            </div>
          </Grid>
        </> :
        <div className={classes.disconnectedIconContainer}>
          <DisconnectIcon />
        </div>
      }

    </div>
  );
};

export default PowerMeterItem;
