import React from 'react';
import { useStoreState } from '@models/RootStore';
import {
    AppLogic,
    AppUser as UserIcon,
    HomeAuto as HomeIcon,
    HVACIcon,
    Timer as ScheduleIcon,
    LCDIcon,
} from '@icons';
import useStyle from './AuditSource.style';
import { t } from 'ttag';

interface IconComponents {
    [key: string]: JSX.Element;
}

interface AuditSourceIconProps {
    source: string | number;
}

const AuditSource: React.FC<AuditSourceIconProps> = ({ source }) => {
    const classes = useStyle();
    const { actionSources } = useStoreState((s) => s.types);

    const iconComponents: IconComponents = {
        [+actionSources.homeAutomation]: <HomeIcon className={classes.homeIcon} />,
        [+actionSources.schedule]: <ScheduleIcon className={classes.scheduleIcon} />,
        [+actionSources.user]: <UserIcon className={classes.userIcon} />,
        [+actionSources.hvac]: <HVACIcon className={classes.hvacIcon} />,
        [+actionSources.applicationLogic]: <AppLogic width='27' height='27' />,
        [+actionSources.ecobee]: <span>eco</span>,
        [+actionSources.deviceLCD]: <LCDIcon className={classes.lcdIcon} />,
    };

    return (
        <div className={classes.iconWrapper}>
            {<div className={classes.iconWrapper}>{iconComponents[source] || source || '-'}</div>}
        </div>
    );
};

/**
* Maps source numbers to corresponding source names.
*
* @param {number} source - The source number.
* @param {string} [sourceType=''] - The source type (optional).
* @returns The corresponding source name or a default value if not found.
*/
export const getSourceNames = (source: string, sourceType: string = ''): string => {
    const sourceMatching: Record<string, string> = {
        1: t`Application User`,
        2: t`Scheduled Operation`,
        3: sourceType ? sourceType : t`Home Automation`,
        4: 'HVAC',
        5: sourceType ? t`Application logic` + ` (${sourceType})` : t`Application logic`,
        6: sourceType ? sourceType : t`Ecobee`,
        7: t`CA Device LCD`
    };
    return sourceMatching[source] || '-';
};



export default AuditSource;