import {
  Site as siteSdk,
  BacnetDevice as bacnetDeviceSdk,
} from 'coolremote-sdk';

import useQuery from './useQuery';
import useMutation from './useMutation';

export const getSiteBacnetUnits = async (siteId) => {
  return siteSdk.getSiteBacnetUnits(siteId);
};

export const setBacnetUnitObjectValue = async (unitId, payload) => {
  return bacnetDeviceSdk.setBacnetUnitObjectValue(unitId, payload);
};

/**
 * Hook to get all Bacnet units for a site.
 *
 * @param {string} siteId - The ID of the site.
 * @returns {Object} data, error, loading status, and refetch function
 */
export const useGetSiteBacnetUnits = (siteId) => {
  return useQuery({
    queryKey: ['siteBacnetUnits', siteId],
    queryFn: async () => {
      return getSiteBacnetUnits(siteId);
    },
    enabled: !!siteId,
  });
};

/**
 * Hook to update bacnet unit object value.
 *
 * @param {string} unitId - The ID of the unit.
 * @param {object} payload - object with value and object id.
 * @returns {Object} data, error, loading status, and refetch function
 */
export const useSetBacnetObjectValue = () => {
  return useMutation({
    mutationFn: async ({ unitId, payload }) => {
      return setBacnetUnitObjectValue(unitId, payload);
    },
  });
};
