import React from "react";

function MobileLogout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="none">
        <path
          fill="#AAA2AA"
          d="M26.605 16.248l-5.074-5.074a1.068 1.068 0 10-1.51 1.51l3.251 3.251h-8.406a1.068 1.068 0 000 2.136h8.406l-3.251 3.251a1.068 1.068 0 101.51 1.51l5.074-5.074a1.068 1.068 0 000-1.51z"
        ></path>
        <path
          stroke="#AAA2AA"
          strokeLinecap="round"
          strokeWidth="1.6"
          d="M21.114 6.029H10.116a2 2 0 00-2 2V25.97a2 2 0 002 2h10.998"
        ></path>
      </g>
    </svg>
  );
}

export default MobileLogout;