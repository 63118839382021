import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';


import clsx from 'clsx';
import {
	InputAdornment,
	IconButton,
	Paper,
	Popover,
	TextField,
	Typography,
} from '@material-ui/core';
import { t } from 'ttag';

import { Add, Close } from '../../../icons';
import { Search as SearchIcon } from '@material-ui/icons';

import PropsList from './components/PropsList';

import {useStyles} from './PropsFilter.style';
import PropsListDraggable from './components/PropsListDraggable';

/**
 * Interface representing a filter with dynamic keys.
 * @typedef {Object} IPropsFilter
 * @property {any} [key] - The key can be of any type.
 */

/**
 * Interface representing the properties for the filter component.
 * @typedef {Object} IPropsFilterProps
 * @property {string} title - The title of the filter.
 * @property {any} type - The type of the filter.
 * @property {any} orderedParams - Ordered parameters for the filter.
 * @property {any} paramsObj - Object containing the parameters.
 * @property {any} setSystemParams - Function to set the system parameters.
 * @property {any} systemId - ID of the system.
 * @property {any} updateUserSystemPreferences - Function to update user system preferences.
 */


/**
 * Gets the sorted parameters based on the sorter array and params object.
 * @param {Array<Array<string>>} sorterArray - An array of arrays, where each sub-array contains a parameter code.
 * @param {Object<string, Object>} paramsObject - An object containing parameters keyed by their code.
 * @returns {Array<Object>} - An array of sorted parameter objects that are set to be shown in the list.
 */
const getSortedParams = (sorterArray,paramsObject) => {

	return sorterArray.map(([code])=>{
		const item = paramsObject[code];
		if(!item || typeof item !== 'object'){
			return false;
		}

		const {showInList} = item;

		if(!showInList){
			return false;
		}

		return item;

	}).filter(item => !!item);

};

/**
 * @param {IPropsFilterProps} props
 */
const PropsFilter = ({title, type, paramsObj,  orderedParams,  setSystemParams,  systemId,  updateUserSystemPreferences,...rest}) => {

	/** The styles */
	const styles = useStyles();

	/** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
	const [openParamsDialog, setOpenParamsDialog] = useState(false);

	/** @type {[null | HTMLElement, React.Dispatch<React.SetStateAction<null | HTMLElement>>]} */
	const [anchorEl, setAnchorEl] = useState(null);

	/** @type {[string, React.Dispatch<React.SetStateAction<string>>]} */
	const [searchText, setSearchText] = useState('');

	/**
   * Opens the parameters list dialog.
   * @param {React.MouseEvent<HTMLElement>} event - The event triggered by clicking the button.
   */
	const openParamsList = (event) => {
		setOpenParamsDialog(true);
		setAnchorEl(event.currentTarget);
	};

	/**
   * Closes the parameters list dialog.
   */
	const handleClose = () => {
		setOpenParamsDialog(false);
		setAnchorEl(null);
	};

	/**
   * Reorders the items in the array.
   * @param {Array<any>} arr - The array to reorder.
   * @param {number} source - The source index.
   * @param {number} dest - The destination index.
   * @returns {Array<any>} - The reordered array.
   */
	const reorder = (arr, source, dest) => {
		const sourceCode = arr[source];
		arr.splice(source, 1);
		arr.splice(dest, 0, sourceCode);
		return arr;
	};

	/**
   * Handles the drag end event to reorder parameters.
   * @param {Object} result - The result object from the drag end event.
   */
	function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const newOrder = reorder(
			[...orderedParams],
			result.source.index,
			result.destination.index
		);

		setSystemParams({
			type: 'reorder',
			paramsType: type,
			paramsOrder: [...newOrder],
			systemId,
			updateAPI: updateUserSystemPreferences,
		});
	}


	/**
 * Handles the item click event to select or unselect a parameter.
 * @typedef {(code:string,flag:string,value:boolean) => void} HandleItemClick
 * @type {HandleItemClick}
 */
	const handleItemClick = (code,flag,value) => {
		setSystemParams({
			type: 'selectUnSelect',
			paramsType: type,
			code: code,
			flag,
			value,
			systemId,
			updateAPI: updateUserSystemPreferences,
		});
	};

	/**
 * Handles the change event for the search field to update the search text.
 * @param {React.ChangeEvent<HTMLInputElement>} event - The change event from the search field.
 */
	const handleSearchFieldChange = (event) => {
		setSearchText(event.target.value);
	};

	/**
 * Handles the click event for the search field to stop propagation.
 * @param {React.MouseEvent<HTMLInputElement>} event - The click event from the search field.
 */
	const handleSearchFieldClick = (event) => event.stopPropagation();

	/**
 * Handles the key down event for the search field to stop propagation for keys other than 'Escape'.
 * @param {React.KeyboardEvent<HTMLInputElement>} event - The key down event from the search field.
 */
	const handleSearchFieldKeyDown = (event) => {
		if (event.key !== 'Escape') {
			event.stopPropagation();
		}
	};

	/** Array of parameters derived from paramsObj. @type {Object[]} */
	const paramsArr = useMemo(() => Object.values(paramsObj),[paramsObj]);
	/** Number of parameters shown in the list. @type {number} */
	const shownInListParams = useMemo(() => paramsArr.filter((param) => param.showInList)?.length , [paramsArr]);
	/** Boolean indicating if all parameters are checked. @type {boolean} */
	const allChecked = paramsArr.length === shownInListParams;
	/** Boolean indicating if some parameters are checked. @type {boolean} */
	const someChecked = !allChecked && paramsArr.length !== 0;

	/** Array of computed parameters based on search text and order. @type {Object[]} */
	const computedParams = useMemo(() => {
		if(!paramsObj){
			return [];
		}
		const sortedParams = getSortedParams(orderedParams,paramsObj);
		if(!searchText){
			return sortedParams;
		}

		return sortedParams.filter(({name})=> {
			const lowercaseName = name.toLowerCase();
			const lowercaseSearchText = searchText.toLowerCase();
			return lowercaseName.includes(lowercaseSearchText);});

	},[searchText,paramsObj,orderedParams]);

	/**
 * Input properties for the TextField component, including the end adornment with a search icon.
 * @type {Object}
 * @property {JSX.Element} endAdornment - The adornment component to display at the end of the input.
 */
	const inputProps = {
		endAdornment: (
			<InputAdornment position="start">
				<SearchIcon style={{ fill: '#aaa2aa' }} />
			</InputAdornment>
		),
	};

	return (
		<Paper {...rest} className={styles.paper}>
			<div className={styles.filterHeaderContainer}>

				<Typography variant="h6" className={styles.filterHeader}>
					{title}
				</Typography>

				<IconButton
					id={`open-${type}`}
					disableRipple
					className={clsx(styles.addIcon, someChecked && styles.pushedAddIcon)}
					onClick={openParamsList}
				>
					<Add />
				</IconButton>

			</div>
			<div>
				<TextField
					size="small"
					variant="outlined"
					autoFocus
					placeholder="Type to search..."
					fullWidth
					InputProps={inputProps}
					onChange={handleSearchFieldChange}
					onClick={handleSearchFieldClick}
					onKeyDown={handleSearchFieldKeyDown}
				/>
			</div>

			<Popover
				id={`paramsListPopup-${type}`}
				disableAutoFocus={false}
				disableEnforceFocus={false}
				open={openParamsDialog}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper:
            type === 'outdoor' ? styles.outdoorPaper : type === 'indoor'? styles.indoorPaper	: '',
				}}
			>
				<div className={styles.filterPopupHeader}>
					<Typography
						className={styles.headerTitle}
					>{t`Edit Parameters list`}</Typography>
					<IconButton
						disableRipple
						onClick={handleClose}
						className={styles.iconBtnStyle}
					>
						<Close color="#7f7692" />
					</IconButton>
				</div>
				{paramsArr?.length ? (
					<PropsList
						params={paramsArr}
						type={type}
						onItemClick={handleItemClick}
						flag={'showInList'}
					/>
				) : (
					<Typography
						style={{ padding: '15px', fontSize: '18px' }}
					>{t`No parameters for this system`}</Typography>
				)}
			</Popover>
			{/* params side filters */}
			<PropsListDraggable
				items={computedParams}
				type={type}
				onDragEnd={onDragEnd}
				onItemClick={handleItemClick}
				flag={'showInTable'}
			/>
		</Paper>
	);
};

PropsFilter.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.any.isRequired,
	paramsObj: PropTypes.object.isRequired,
	orderedParams: PropTypes.any.isRequired,
	setSystemParams: PropTypes.func.isRequired,
	systemId: PropTypes.any.isRequired,
	updateUserSystemPreferences: PropTypes.func.isRequired,
};


export default PropsFilter;
