import React from "react";

function MobileGroups(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <path
        fill="#AAA2AA"
        d="M26.8 22.623a.8.8 0 01-.323 1.001l-.097.05L17 27.703l-9.333-4.03a.8.8 0 01.532-1.505l.102.036L17 25.96l8.749-3.756a.8.8 0 011.05.42zm0-3.64a.8.8 0 01-.323 1.002l-.097.05L17 24.062l-9.333-4.03a.8.8 0 01.532-1.505l.102.037 8.701 3.756 8.748-3.757a.8.8 0 011.05.42zm0-3.853a.8.8 0 01-.323 1l-.097.05L17 20.21l-9.333-4.03a.8.8 0 01.532-1.505l.102.036 8.701 3.756 8.748-3.757a.8.8 0 011.05.42zm-9.516-8.136l.138.046 8.759 3.65a1.095 1.095 0 01.117 1.963l-.117.058-8.76 3.649c-.224.095-.473.11-.705.047l-.138-.047-8.759-3.65a1.095 1.095 0 01-.117-1.962l.117-.057 8.76-3.65c.224-.094.472-.11.705-.047zM17 8.598L9.555 11.7l7.444 3.102 7.445-3.102L17 8.598z"
      ></path>
    </svg>
  );
}

export default MobileGroups;