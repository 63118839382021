import React from 'react';

const UploadFile = props => (
  <svg width="49" height="39" viewBox="0 0 49 39" xmlns="http://www.w3.org/2000/svg">
    <g fill="#AAA2AA" fillRule="evenodd">
      <path d="M17.006 21.94a.906.906 0 0 1 .001-1.248l7.107-7.369 7.105 7.366a.907.907 0 0 1-.002 1.25l-.005.006a.824.824 0 0 1-1.196-.003l-5.053-5.252v19.787c0 .49-.383.887-.854.887s-.854-.398-.854-.887V16.69l-5.05 5.25a.825.825 0 0 1-1.199 0" />
      <path d="M39.219 13.047c0-.13.02-.258.02-.388C39.24 5.743 33.673.14 26.928.14c-4.86 0-9.045 2.916-11.006 7.132a6.105 6.105 0 0 0-2.825-.688c-3.12 0-5.703 2.538-6.21 5.592C3.177 13.477.5 17.2.5 21.458c0 5.346 4.228 9.702 9.445 9.702h8.792a.852.852 0 0 0 .843-.86.853.853 0 0 0-.843-.862H10.03c-4.644 0-8.358-4.178-7.759-9.056.388-3.16 2.64-5.757 5.59-6.795l.523-.185.159-.947c.38-2.324 2.33-4.227 4.628-4.227.727 0 1.444.172 2.097.516l1.424.731.684-1.472c1.751-3.755 5.493-6.175 9.53-6.175 5.83 0 10.638 4.658 10.638 10.638 0 1.43-.022 2.184-.022 2.184l1.602.01c3.858.054 6.915 3.593 6.915 7.562 0 3.958-3.141 7.196-7.01 7.216h-9.751a.852.852 0 0 0-.843.861c0 .476.377.86.843.86h9.805c4.743 0 8.643-4.011 8.643-8.904 0-4.895-3.742-9.197-8.507-9.208" />
    </g>
  </svg>

);

export default UploadFile;
