import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  draggableDataPoint: {
    border: '2px solid #4B1C46',
    borderRadius: '8px',
    display: 'flex',
    padding: '6px',
    position: 'relative',
    '&.isSelected': {
      border: '2px dashed #4B1C46',
    },
  },
  draggableDataPoint__CloseBtn: {
    top: '-8px',
    right: '-8px',
    height: '16px',
    width: '16px',
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: '#4B1C46',
    cursor: 'pointer',
    '& >svg': {
      color: '#fff',
      fontSize: '16px',
    },
  },
  labelTop: {
    flexDirection: 'column',
    alignItems: 'center',
    '& >div:first-child': {
      marginBottom: '8px',
    },
  },
  labelBottom: {
    alignItems: 'center',
    flexDirection: 'column-reverse',
    '&>div:first-child': {
      marginTop: '8px',
    },
  },
  labelLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    '&>div:first-child': {
      marginRight: '8px',
    },
  },
  labelRight: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    '&>div:first-child': {
      marginLeft: '8px',
    },
  },
}));

export default useStyles;