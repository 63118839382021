import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import assetsService from '../../../../services/assetsService';
import Button from '../../../../cool_widgets/Button';
import ReportProblem from '../../../../screens/ReportProblem/ReportProblem'; 
import { SupportTicket } from '../../../../icons/';

/**
 * The LoginReportProblem component renders a button to open a support ticket and manages the corresponding dialog.
 *
 * @param {object} configFile The configuration file containing the support button link and support image
 * @param {object} classes CSS classes for styling the report problem button
 *
 * @returns The login report problem component
 */
const LoginReportProblem = ({ classes, configFile }) => {

	// State variable for controlling the report ticket dialog
	const [openReport, setOpenReport] = useState(false);
	// Destructure support image from configFile
	const { supportImg = null } = configFile;

	/**
     * Handles clicking the "Open Support Ticket" button.
     *
     * - If a support button link is provided, opens the link in a new tab.
     * - Otherwise, opens the report ticket dialog.
     */
	const handleOpenTicketClick = () => {

		if (configFile?.supportBtnLink?.length) {
			window?.open(configFile?.supportBtnLink, '_blank')?.focus();
			return;
		}
		setOpenReport(true);

	};

	return (
		<div className='login-report-problem'>
			<Button
				disableRipple
				variant="contained"
				className={classes.reportProblemButton}
				onClick={handleOpenTicketClick}
				title={t`Open Support Ticket`}
				red
			>
				{
					supportImg ? // Display support image if available
						<img src={assetsService.getResourcePath(supportImg)} alt="" style={{ width: '3.4rem', height: '3.4rem' }} /> :
						<SupportTicket width={'2rem'} height={'2rem'} />
				}
			</Button>
			{openReport && ( // Render report ticket dialog if open
				<ReportProblem openReport location={'login'} onClose={() => setOpenReport(false)} />
			)}</div>
	);
};

// Define propTypes for the component
LoginReportProblem.propTypes = {
	configFile: PropTypes.object,
	classes:PropTypes.object
};

export default LoginReportProblem;