import {
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { t } from "ttag";
import { TopBar } from "../../components";
import { MobileLogo } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import FilterRequire from "../MobileFilterRequire/FilterRequire";
import statisticsDialogStyle from "./MobileUnitStatistics.style";

const legends: any = [
  { name: t`Outside Temp.`, color: "#efeff2" },
  { name: t`Room Temp.`, color: "#99939e" },
  { name: t`Setpoint`, color: "#6f6f6f" },
  { name: t`Cool`, color: "#35a8e0" },
  { name: t`Heat`, color: "#f05146" }
];

const CustomizedXAxisTick = (props: any) => {
  const {
    x, y, stroke, payload, isDay, isMonth
  } = props;

  const format = isDay ? "HH:mm" : "DD/MM";
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={isDay ? 25 : 10} dy={20} textAnchor="end" fill="#9aa1a9" opacity={0.6} fontSize={12} transform={isDay ? "" : "rotate(-35)"}>{moment(payload.value).format(format)}</text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const {
    x, y, stroke, payload, unit
  } = props;
  const val = payload.value;
  const dx = 2;
  const rotation = 0;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={dx} dy={3} textAnchor="start" fill="#9aa1a9" opacity={0.6} fontSize={12}>{val}{unit}</text>
    </g>
  );
};

const CustomizedY2AxisTick = (props: any) => {
  const {
    x, y, stroke, payload, unit, isDayRange
  } = props;
  const val = (isDayRange ? `${Math.round(payload.value / (1000 * 60))}` : `${Math.round(payload.value / (1000 * 60 * 60))}`);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={-2} dy={3} textAnchor="end" fill="#9aa1a9" opacity={0.6} fontSize={12} >{`${val} ${unit}`}</text>
    </g>
  );
};

const CustomizedLegend = ({ payload }: any) => {
  const useStyles = makeStyles(statisticsDialogStyle);
  const classes: any = useStyles();

  return (
    <div className={classes.legendsContainer}>
      {payload.map((legend: any, index: any) =>
        <div className={classes.legendHolder} key={`legend - ${index}`}>
          <div className={classes.legendColor} style={{ backgroundColor: legend.color }}></div>
          <Typography className={classes.legendName}>{legend.name}</Typography>
        </div>
      )}
    </div>
  );
};

const MobileUnitStatistics = (props: any) => {
  const history = useHistory();
  const useStyles = makeStyles(statisticsDialogStyle);
  const classes: any = useStyles();
  const location: any = useLocation();

  const [timeUnit, setTimeUnit] = useState<string>("day");
  const [results, setResults] = useState<any>({ buckets: [] });
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);
  const [paramsData, setParamsData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enabledlimits, setEnabledLimits] = useState<any>({ enableWeek: false, enableMonth: false });

  const getUnitStats = useStoreActions((state) => state.units.getUnitStatistics);
  const fetchUnitDiagByParams = useStoreActions((a) => a.units.fetchUnitDiagByParams);
  const { unitId: selectedUnitId, siteId } = useStoreState((state) => state.selections.mobileSelections);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const allSites = useStoreState((state) => state.sites.allSites);

  const user = useStoreState((state) => state.users.me);
  const { temperatureScale } = user;
  const temperatureScaleMirror = useStoreState((s) => s.temperatureScaleMirror);
  const temperatureSample = + temperatureScaleMirror.fahrenheit === temperatureScale ? "°F" : "°C";

  const isDayRange = timeUnit === "day";
  const isWeekRange = timeUnit === "week";
  const isMonthRange = timeUnit === "month";
  const timezone = allSites[siteId || ""]?.timezone || "utc";

  useEffect(() => {
    if (!siteId) {
      return;
    }

    const timelimit = sitesFlags[siteId]?.numOfHourLimitUnitStats || 0;
    const allTimeFramsAllowed = timelimit === 0;
    if (allTimeFramsAllowed) {
      setEnabledLimits({ enableWeek: true, enableMonth: true })
    } else {
      const limits = { enableWeek: timelimit > 24, enableMonth: timelimit > 168 };
      if ((timeUnit === "week" && !limits.enableWeek) || (timeUnit === "month" && !limits.enableMonth)) {
        setTimeUnit("day");
      }
      setEnabledLimits(limits)
    }
  }, [siteId]);

  useEffect(() => {
    if (!selectedUnitId) {
      onUnitSelect(null);
    } else {
      onUnitSelect(selectedUnitId);
    }
  }, [selectedUnitId]);

  const setRange = useCallback(() => {

    const startDate = moment().tz(timezone).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).tz("utc");
    const endDate = moment().tz(timezone).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).add(1, "second").tz("utc");
    const currentTimeStamp = moment().valueOf();

    let startTimeGMT = startDate.clone().tz("gmt");
    const endTimeGMT = endDate.clone().tz("gmt");
    if (isWeekRange) {
      startTimeGMT = startTimeGMT.subtract(7, "days");
    } else if (isMonthRange) {
      startTimeGMT = startTimeGMT.subtract(1, "months");
    }

    let startTime = startTimeGMT.valueOf();
    const endTime = endTimeGMT.valueOf();

    //check if selected site has a limited number of hours for unit stats
    const hourslimit = sitesFlags[siteId || ""]?.numOfHourLimitUnitStats || 0;
    const miliSecondsLimit = hourslimit * 3600 * 1000;
    if (miliSecondsLimit && (currentTimeStamp - startTime > miliSecondsLimit)) {
      startTime = currentTimeStamp - miliSecondsLimit;
    }

    return {
      bucketSizeMsec: isDayRange ? 60 * 60 * 1000 : 24 * 60 * 60 * 1000,
      startTime,
      endTime
    };
  }, [timeUnit, siteId]);

  const fetchData = useCallback(() => {
    setResults({ buckets: [] });
    setParamsData(null);

    const { bucketSizeMsec, startTime, endTime } = setRange();

    if (!selectedUnitId) {
      setIsLoading(false);
      return;
    }

    getUnitStats({
      unitId: selectedUnitId,
      startTime,
      endTime,
      size: bucketSizeMsec
    })
      .then((basicState: any) => {
        const { buckets } = basicState;

        setStart(startTime);
        setEnd(endTime);
        if (buckets) {
          setResults(basicState);
        } else {
          setResults({ buckets: [] });
        }

        return fetchUnitDiagByParams({
          unitId: selectedUnitId,
          startTime,
          endTime,
          params: ["50", "57", "49"]
        });
      })
      .then((diag: any) => {
        setParamsData(diag);
      })
      .catch((err: any) => {
        setResults({ buckets: [] });
      })
      .finally(() => setIsLoading(false));
  }, [selectedUnitId, timeUnit]);

  useEffect(() => {
    if (!selectedUnitId) {
      return;
    }
    setIsLoading(true);
    fetchData();
  }, [selectedUnitId, timeUnit]);

  const onUnitSelect = (unitId: string | null) => {
    const { state } = location;
    const unit = unitId || state?.unitId;
    if (unit) {
      history.push({
        pathname: `/unit-statistics/${unit}`,
        state
      });
    } else {
      history.push("/unit-statistics/");
    }
  };

  const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTimeUnit(newValue);
  };

  const ticksArr = results.buckets.map((bucket: any) => bucket.timestamp) || [];

  return (<>
    <TopBar leftAction={() => history.push("/dashboard")}
      leftIconComponent={<MobileLogo />}
      screenTitle="unitStats"
    />
    {!selectedUnitId ? <FilterRequire type={t`unit`} /> : (

      <div className={classes.root}>
        <div className={classes.pageContent}>
          <Tabs
            classes={{ root: classes.tabStyle, indicator: classes.indicator }}
            variant="fullWidth"
            value={timeUnit}
            onChange={handleTabsChange}
            indicatorColor="primary"
            centered
          >
            <Tab value={"day"} label={t`Day`} classes={{ root: classes.tabMedia }} />
            <Tab disabled={!enabledlimits.enableWeek} value={"week"} label={t`last 7 days`} classes={{ root: classes.tabMedia }} />
            <Tab disabled={!enabledlimits.enableMonth} value={"month"} label={t`Last 30 days`} classes={{ root: classes.tabMedia }} />
          </Tabs>
          <Grid className={classes.graphContainer}>
            <ResponsiveContainer width="100%" height="80%">

              <ComposedChart height={500} data={results ? results.buckets : []}>
                <XAxis
                  xAxisId={"mainX"}
                  dataKey="timestamp"
                  height={60}
                  interval={0}
                  type="number"
                  tickCount={isDayRange ? 8 : (isWeekRange ? 7 : 10)}
                  domain={[start, end]}
                  tick={<CustomizedXAxisTick isDay={isDayRange} isMonth={isMonthRange} />}
                  ticks={ticksArr.length > 10 ? ticksArr.filter((tick: any, index: any) => (index % 3 === 0 && index !== ticksArr.length - 2) || index === ticksArr.length - 1) : ticksArr}
                  stroke="#9aa1a9"
                  opacity={0.2}
                  tickLine={false}
                />
                <YAxis
                  yAxisId={"left"}
                  width={50}
                  type={"number"}
                  domain={isDayRange ? [0, (1000 * 60 * 60) + (5000)] : [0, (1000 * 60 * 60 * 24) + 60000]}
                  allowDecimals={false}
                  tick={<CustomizedY2AxisTick unit={isDayRange ? "min" : "h"} isDayRange={isDayRange} />}
                  stroke="#9aa1a9"
                  opacity={0.2}
                  tickLine={false}
                />
                <YAxis
                  yAxisId={"right"}
                  width={50}
                  type={"number"}
                  allowDecimals={false}
                  orientation={"right"}
                  domain={["auto", "auto"]}
                  tick={<CustomizedYAxisTick unit={temperatureSample} />}
                  stroke="#9aa1a9"
                  opacity={0.2}
                  tickLine={false}
                />
                <CartesianGrid stroke="#9aa1a9" opacity={0.2} />

                {paramsData &&
                  (
                    <Area
                      name={t`Outside Temp`}
                      xAxisId={"mainX"}
                      yAxisId={"right"}
                      data={paramsData}
                      dataKey={57}
                      type="monotone"
                      stroke="none"
                      fillOpacity={1}
                      fill="#efeff2"
                      dot={false}
                    />

                  )}

                <Bar yAxisId={"left"} name="Heat" xAxisId={"mainX"} dataKey="unitBucketHeatTime" stackId={"a1"} barSize={10} fill="#f05146" />
                <Bar yAxisId={"left"} name="Cool" xAxisId={"mainX"} dataKey="unitBucketCoolTime" stackId={"a1"} barSize={10} fill="#35a8e0" />
                <Bar yAxisId={"left"} name="Other" xAxisId={"mainX"} dataKey="unitBucketOtherTime" stackId={"a1"} barSize={10} fill="#aaa2aa" />

                {paramsData &&
                  (
                    <Line
                      name={t`Set Point`}
                      xAxisId={"mainX"}
                      yAxisId={"right"}
                      data={paramsData}
                      dataKey={50}
                      type="monotone"
                      stroke="#6f6f6f"
                      strokeWidth={3}
                      // height={3}
                      dot={false}
                    />
                  )}
                {paramsData &&
                  (
                    <Line
                      name={t`Room Temp`}
                      xAxisId={"mainX"}
                      yAxisId={"right"}
                      data={paramsData}
                      dataKey={49}
                      type="monotone"
                      stroke="#99939e"
                      strokeWidth={3}
                      // height={3}
                      dot={false}
                    />
                  )}
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  content={<CustomizedLegend />}
                  payload={
                    legends.map((legend: any) => {
                      return legend;
                    })
                  }
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Grid>
          <div className={classes.workingHoursContainer}>
            <Typography className={classes.workingHours}>
              {results && results.onTime ? (results.onTime / (1000 * 60 * 60)).toFixed(2) : 0}
              <span style={{ fontSize: 14 }}>{t`Hrs`}</span>
            </Typography>
            <Typography className={classes.workingHoursTextStyle}>
              {t`Working Hours`}
            </Typography>
          </div>
        </div>
      </div>)}
  </>
  );
};

export default MobileUnitStatistics;
