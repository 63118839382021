import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    iconWrapper: {
        display: 'flex',
        width: 35,
        alignItems: 'center'
    },
    scheduleIcon: {
        fontSize: 18,
        marginLeft: '3.5px',
        '&>g': {
            fill: 'grey',
            width: '10px'
        }
    },
    homeIcon: {
        fontSize: 25
    },
    userIcon: {
        fontSize: 23,
        marginLeft: '1px'
    },
    hvacIcon: {
        fontSize: 21,
        marginLeft: '2px'
    },
    lcdIcon: {
        fontSize: 27,
        marginLeft: '1px'
    }
}));

export default useStyles;
