import coolremoteSDK from "coolremote-sdk";
import {
  Action,
  Thunk,
  action,
  thunk,
} from "easy-peasy";
import { IRootStoreModel } from "./RootStore";

export interface IAudit {
  id: string;
  timestamp: string;
  action: string;
  actor: string;
  device?: string;
  unit: string;
  customer?: string;
  site?: string;
  system?: string;
  group?: string;
  schedule?: string;
  trap?: string;
  changeover?: string;
  user?: string;
  value?: any;
  data?: any;
  source?: number;
  line?: number;
  sourceType?: string;
  sensor?: string;
}

export interface IAuditMap {
  [key: string]: IAudit;
}

export interface IAuditsModel {
  allAudits:IAuditMap,
  setAllAudits:Action<IAuditsModel,IAuditMap>,
  getCustomerAudits: Thunk<IAuditsModel,{ customerId: string; params: any }>;
  shareAuditsByEmail: Thunk<IRootStoreModel, { params: any; data: any }>;
}

export const auditsModel: IAuditsModel = {
  allAudits:{},
  setAllAudits:action((state,payload)=>{
    state.allAudits = payload;
  }),
  getCustomerAudits: thunk(async (actions, { customerId, params }) => {
    const audits = await coolremoteSDK.Audit.getCustomerAudits(
      customerId,
      params
    );
    actions.setAllAudits(audits)
    return audits;
  }),
  shareAuditsByEmail: thunk(async (actions, { params, data }) => {
    const audits = await coolremoteSDK.Audit.sendAuditReportByEmail(
      params,
      data
    );
    return audits;
  })
};
