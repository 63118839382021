import React, { useState } from "react";
import {
  Dialog,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import { CustomedTextField, CustomSelect } from "../../../components/AddRule/CustomedComponents";
import { Close } from "../../../icons";
import LightTooltip from "../../../components/Tooltip/LightTooltip";
import SvgQuestionMark from "../../../icons/QuestionMark";
import Button from "../../../cool_widgets/Button";
import NewApplySettings from "../../Settings/NewApplySettings";
import { t } from "ttag";
import clsx from "clsx";
import { SmartRule as SDKSmartRule } from "coolremote-sdk";
import { useStoreState } from "../../../models/RootStore";
import useStyle from "../SmartRules.style";
import useChurnZero from "@hooks/useChurnZero";


const smartRuleTypesMirror: any = {
  "0": "Abnormal unit operation time",
  "1": "High rate of Setpoint Changes",
  "2": "SetPoint Maintenance",
  "3": "Unit on High Demand"
};

const smartRuleTypesDescriptions: any = {
  "0": <>{t`Unit is turned ON at a time it is  'normally' OFF.   The analysis is made for each slot of hour & weekday and is calculated automatically.
            The frequency threshold settings define what OFF occasions frequency is considered as 'normal' unit operation status.
            - Example: a Meeting room unit that was actually OFF during Wednesday at 8pm, in 7 times out of 10 occurrences (70%). If the threshold is set to 60% it means that the auto logic assumes the unit should be OFF on Wednesday at 8pm. Therefore, an alert will trigger when the unit is turned ON on a Wed at 8pm. But, if the Threshold was set to 80%, the logic will assume that unit ON during Wed at 8pm is also normal, and an alert will not trigger.
            - Potential Impact: High energy consumption. Higher wear and tear of HVAC systems, higher maintenance & support costs.
            - Things to look into when this happens:`}
    <ul key={"ui-" + 0}>
      <li key={"li-" + 0}>Use schedules to turn OFF units when not needed.</li>
      <li key={"li-" + 1}>Use Interlock on unit operation, with presence sensors.</li>
      <li key={"li-" + 2}>Restrict user controls during non working periods.</li>
    </ul>
  </>,
  "1": <>{t`Too many SetPoint changes within 1 hour. Users can set the threshold of maximum changes before sending an alert.
            Usually happens when the original room temp is far from the comfort setpoint. Users tend to 'play' with the setpoint to get their comfort level.
            - Example: in an office space where setpoint is expected to be quite fixed, a low rate of changes is expected, so a threshold of 3 is reasonable, and an alert will trigger if more then 3 changes occur during 1 hour.
            - Potential Impact: inefficient unit operation resulting in higher energy consumption. Tenant comfort is affected.
            - Things to look into when this happens:`}
    <ul key={"ui-" + 1}>
      <li key={"li-" + 0}>{`Apply schedules with unit setpoint that mostly satisfy tenants' comfort.`}</li>
      <li key={"li-" + 1}>{`Limit/Block local setpoint changes.  Set a schedule to turn unit ON before people arrive at the office so they come in to a comfort temperature.`}</li>
      <li key={"li-" + 2}>{`Use setback to keep the room in comfort temp zone.`}</li>
    </ul>
  </>,
  "2": <>{t`Alert will trigger when the room temp is not meeting the set point in high percent of the runtime (when unit is ON)
            Users can set what percentage of time that the setpoint is off range (±2C) will be considered as an anomaly. They can also define for what timeslot window the percentage is calculated.
            - Example: if defined at 30% and 12 hours time slot window, and the unit was off setpoint in 40% of occurrences between 8am-8pm, then the alert will trigger
            - Potential Impact: High energy consumption. Comfort is affected. High wear and tear of HVAC systems resulting in higher maintenance & support costs.
            - Things to look into when this happens:`}
    <ul key={"ui-" + 2}>
      <li key={"li-" + 0}>Check the room to see if a window/door is open. Consider adding door/window sensors and activate interlocks</li>
      <li key={"li-" + 1}>Verify setpoint is set correctly for the space.</li>
      <li key={"li-" + 2}>Check refrigerant levels and potential leaks.</li>
      <li key={"li-" + 3}>Verify AC unit capacity meets space needs.</li>
      <li key={"li-" + 4}>{`Unit was turned on for too short time and didn't have the time to reach the setpoint before it was turned off.`}</li>
      <li key={"li-" + 5}>Required setpoint is too far from starting room temp and it takes long time to get to the setpoint. (Consider applying setbacks)</li>
    </ul>
  </>,
  "3":<>
    <p>
      {t`Alert will trigger when the unit was on demand (Therm on) over 80%  (configurable) of the runtime during the last 4 (configurable) hours.
      Users can set what percentage of demand time will be considered as high and will create anomaly alert. They can also define for what period the percentage is calculated.
      - Example: if defined at 70% and a 12 hours period, and the unit was on demand 90% of runtime between 8am-8pm, then the alert will trigger.`}
    </p>
    <div style={{ marginTop: '5px' }}>{t`Potential Impacts:`}</div>
    <ul key={"PI-" + 3} style={{ marginTop: 0 }}>
      <li key={"li-" + 0}>{t`High energy consumption.`}</li>
      <li key={"li-" + 1}>{t`Comfort is affected.`}</li>
      <li key={"li-" + 2}>{t`High wear and tear of HVAC systems resulting in higher maintenance & support costs.`}</li>
    </ul>
    <div style={{ marginTop: '5px' }}>{t`Things to look into when this happens:`}</div>
    <ul key={"TTL-" + 3} style={{ marginTop: 0 }}>
      <li key={"li-" + 0}>{t`Check room space to see if a window/door is open`}</li>
      <li key={"li-" + 1}>{t`Verify SetPoint is set correctly for the space.`}</li>
      <li key={"li-" + 2}>{t`Check refrigerant levels and potential leaks.`}</li>
      <li key={"li-" + 3}>{t`Verify  unit and system capacity meets space needs.`}</li>
    </ul>
  </>
};

const smartRuleTypesInputs: any = {
  "0": [{
    min: 0,
    max: 100,
    defaultValue: 80,
    key: "samplesOnPercentage",
    label: t`Threshold for 'normally' OFF `,
    get rangeHint() { return `(${this.min}-${this.max}%)`; }
  }],
  "1": [{
    // min: 0,
    defaultValue: 5,
    key: "setpointChangesThreshold",
    label: t`Setpoint Changes (Per Hour) Threshold `
    // get rangeHint() { return `(Min ${this.min})`; }
  }],
  "2": [{
    min: 1,
    max: 12,
    defaultValue: 4,
    key: "numberOfHoursToCheck",
    label: t`Time length to calculate percentage `,
    get rangeHint() { return `(${this.min}-${this.max}Hrs)`; }
  },
  {
    min: 1,
    max: 100,
    defaultValue: 30,
    key: "outOfRangePercentage",
    label: t`Percent time off setpoint `,
    get rangeHint() { return `(${this.min}-${this.max}%)`; }
  }
  ],
  "3": [{
    min: 1,
    max: 12,
    defaultValue: 4,
    key: "numberOfHoursToCheck",
    label: t`Time length to calculate percentage `,
    get rangeHint() { return `(${this.min}-${this.max}hours)`; }
  },
  {
    min: 1,
    max: 100,
    defaultValue: 70,
    key: "thermOnPercentage",
    label: t`% time Demand (of total running time) `,
    get rangeHint() { return `(${this.min}-${this.max}%)`; }
  }
  ]
};

const AddEditRule = (props: any) => {
  const { rule, setRefresh, setError, onClose, setSmartRules, smartRules, viewOnly } = props;
  const { id: smartRuleId } = rule;
  const classes = useStyle();
  const [smartRuleType, setSmartRuleType] = useState(rule?.type || "0");
  const [smartRuleName, setSmartRuleName] = useState<string | undefined>(rule?.name);
  const [priority, setPriority] = useState(rule?.priority || 3);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);
  const [smartRuleData, setSmartRuleData] = useState<any>(rule?.data || {});
  const [unitsToSave, setUnitsToSave] = useState<string[] | undefined>(rule?.units || []);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allSites = useStoreState((s) => s.sites.allSites);
  const selections = useStoreState((s) => s.selections.selections);
  const { trapPriorityTypes } = useStoreState((s) => s.types);
  const { siteId = "" } = selections;
  const { canUpdateSmartRules } = allSites?.[siteId || ""]?.permissions || {};

  const {trackEvent} = useChurnZero();

  const trapPriorityOptions = Object.keys(trapPriorityTypes).reduce((obj: any, key: any) => {
    obj.push({ name: trapPriorityTypes[key], value: +key });
    return obj;
  }, []);

  const smartRuleTypeOptions = Object.keys(smartRuleTypesMirror).reduce((obj: any, key: any) => {
    obj.push({ name: smartRuleTypesMirror[key], value: +key });
    return obj;
  }, []);

  const getInputs = (smartRuleType: string) => {
    return <div style={showAdvancedSettings ? {} : { display: "none" }}>
      {smartRuleTypesInputs[smartRuleType]?.map((inputData: any) => {
        let val = smartRuleData?.[inputData.key];
        if (val === undefined) {
          val = inputData.defaultValue;
          setSmartRuleData({ ...smartRuleData, [inputData.key]: val });
        }
        const hasError = (
          (val === "") ||
          (val !== undefined && isNaN(+val)) ||
          (typeof inputData.min === "number" && +val < inputData.min) ||
          (typeof inputData.max === "number" && +val > inputData.max)
        );
        return <div key={inputData.key}>
          <div className={classes.textFieldLabelDiv}            >
            {inputData.label + (inputData.rangeHint || "")}
          </div>
          <TextField
            disabled={viewOnly || !canUpdateSmartRules}
            key={`input-${inputData.key}`}
            placeholder={`${inputData.label + (inputData.rangeHint || "")}`}
            variant="outlined"
            classes={{ root: classes.textFieldValue }}
            value={val || ""}
            // defaultValue={inputData.defaultValue}
            error={hasError}
            helperText={hasError && (inputData.label + t` is required ` + (inputData.rangeHint || ""))}
            onChange={({ target: { value } }) => {
              // if (
              //   (+value >= inputData.min && +value <= inputData.max) ||
              //   (!inputData.min && +value <= inputData.max) ||
              //   (!inputData.max && +value >= inputData.min) ||
              //   (!isNaN(+value)
              // )
              setSmartRuleData({ ...smartRuleData, [inputData.key]: value });
            }} />
        </div>;
      }
      )}
    </div>;
  };

  const handleAgree = (units: any) => {
    trackEvent('SmartRuleAddUnits','The user added units to the Smart rule');
    setUnitsToSave(units);
  };

  const onSave = async () => {

    trackEvent('SmartRuleSaveRule','The user created a Smart rule');

    if (!smartRuleName) {
      setSmartRuleName("");
    }

    const newSmartRuleData: any = {};
    const emptyValues = smartRuleTypesInputs[smartRuleType]?.filter(({ key }: { key: string }) => {
      if (smartRuleData[key] === "" || smartRuleData[key] === undefined) {
        newSmartRuleData[key] = "";
        return true;
      }
      newSmartRuleData[key] = smartRuleData[key];
      return false;
    });
    if (emptyValues?.length) {
      setSmartRuleData(newSmartRuleData);
      setShowAdvancedSettings(true); // open advance setting section if value is missing
    }

    if (!siteId || !unitsToSave?.[0]) {
      setUnitsToSave([]);
    }
    if (!smartRuleName || emptyValues.length || !siteId || !unitsToSave?.[0])
      return;
    const data = {
      priority,
      name: smartRuleName,
      data: newSmartRuleData,
      type: smartRuleType,
      units: unitsToSave.filter((unitId) => !!allUnits?.[unitId])
    };
    if (smartRuleId) {
      SDKSmartRule.updateSmartRule(smartRuleId, data).then(() => {
        setSmartRules(smartRules.map((rule: any) => rule.id === smartRuleId ? ({ ...rule, ...data }) : rule));
        onClose();
      })
        .catch((e: any) => { setRefresh(true); setError(e.message); });
    }
    else {
      SDKSmartRule.createSmartRule({ ...data, site: siteId }).then((res: any) => {
        setSmartRules([...smartRules, res]);
        onClose();
      });
    }
  };

  if (!siteId)
    return (<></>);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      classes={{ paper: classes.dialog }}
    >

      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Auto Learning Adaptive Rules`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={onClose}><Close color="#7f7692" /></IconButton>
      </div>

      <div className={classes.dialogContent}>
        <Grid item={true} xs={7} className={classes.leftContainer}>
          <CustomedTextField
            disabled={viewOnly || !canUpdateSmartRules}
            label={t`Rule Name`}
            value={smartRuleName}
            error={smartRuleName === ""}
            onChange={(e: any) => setSmartRuleName(e.target.value)}
          />
          <div className={classes.rowContainer}>
            <div className={classes.ruleTypeHolder}>
              <CustomSelect
                disableTooltip
                disabled={viewOnly || !canUpdateSmartRules}
                className={classes.selectMenu}
                placeholder={t`Rule Type`}
                value={smartRuleType}
                options={smartRuleTypeOptions}
                onChange={(event: any) => setSmartRuleType(event.target.value + "")}
              />
            </div>
            <div className={classes.priorityHolder}>
              <CustomSelect
                disableTooltip
                disabled={viewOnly || !canUpdateSmartRules}
                className={classes.selectMenu}
                placeholder={t`Priority`}
                value={priority}
                options={trapPriorityOptions}
                onChange={(event: any) => setPriority(event.target.value)}
              />
            </div>
          </div>
          <Typography className={classes.noteStyle}>
            <strong style={{ fontWeight: "bold" }}>{t`Note: `}</strong>{smartRuleTypesDescriptions[smartRuleType]}
          </Typography>

          <div className={classes.AdvancedHolder}>
            <div>
              <Typography
                className={clsx(
                  classes.boldText,
                  classes.clickable,
                  classes.underlined
                )}
                onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
              >
                {t`Advanced Settings…`}
              </Typography>
              {getInputs(smartRuleType)}
            </div>
          </div>

        </Grid>

        <Grid item={true} xs={5} className={classes.rightCard}>
            <Typography className={classes.headerStyle}>{t`Unit selection`}</Typography>
            <FormControl component="fieldset" className={classes.unitListContainer}>
              <div className={classes.smartRuleUnitLists} >
                {unitsToSave?.length === 0 && <p className={classes.error}>{t`*Check at least one unit`}</p>}
                <NewApplySettings
                  disabled={viewOnly || !canUpdateSmartRules}
                  save={handleAgree}
                  units={unitsToSave || []}
                  noPopup={true}
                  oneSite={siteId}
                  useControlUnits={true}
                  showSiteCheckbox={true}
                />
              </div>
            </FormControl>
        </Grid>

      </div>

      <div className={classes.actionsHolder}>
        <Button
          disabled={viewOnly || !canUpdateSmartRules}
          width={150}
          onClick={onSave}>
          {t`Apply`}
        </Button>
      </div>
    </Dialog>
  );
};

export default AddEditRule;
