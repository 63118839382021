import { Collapse } from "@material-ui/core";
import _ from "lodash";
import React, { ReactNodeArray, useEffect } from "react";
import useStyles from "./GroupSearchMenuItem.style";

interface IGroupSearchMenuItemProps {
  title: string;
  onClick: () => void;
  onToggle?: (isOpen: boolean) => void;
  numItems?: number;
  isOpen?: boolean;
  className?: string;
  onChange?: (searchIndoor: boolean) => void;
}

export default function GroupSearchMenuItem(props: React.PropsWithChildren<IGroupSearchMenuItemProps>) {
  const [isOpen, setIsOpen] = React.useState(props.isOpen);
  const classes = useStyles();

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);



  return (
    <div className={props.className}>
      <div className={classes.item}>
        <label className={classes.locationTitle}>{props.title}</label>
        <div className={classes.lengthTitle}>
          <span>{props.numItems ?? (props.children as ReactNodeArray).length}</span>{" "}
        </div>
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div className={classes.unitsList}>{props.children}</div>
      </Collapse>
    </div>
  );
}
