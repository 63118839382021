import React, { useMemo } from 'react';
import PropTypes from 'prop-types';


import {List} from '@material-ui/core';
import PropsListItem from './PropsListItem';

import { useStyles } from '../PropsFilter.style';

/**
 * @typedef {Object} Param
 * @property {string|number} code - The code of the parameter.
 */

/**
 * @typedef {Object} PropsListProps
 * @property {string} type - The type of the parameter.
 * @property {string} flag - The flag of the parameter.
 * @property {Param[]} params - Array of parameter objects.
 * @property {(code: string, flag: string, newValue: boolean) => void} onItemClick - Function to handle item click.
 */

/**
 * PropsList Component
 *
 * @param {PropsListProps} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
const PropsList = ({type, params, flag ,onItemClick}) => {

	const styles = useStyles();

	/**
     * Handles item click events.
     *
     * @param {string} code - The code of the clicked item.
     * @param {boolean} [forcedValue] - A forced value to set (optional).
     * @returns {Function} Event handler function.
     */
	const handleItemClick = (code,forcedValue) => (event) => {
		const {checked} = event.target;
		const newValue = forcedValue !== undefined ? forcedValue : checked;
		onItemClick(code,flag,newValue);
	};

	/**
   * Checks if all parameters are shown in the list.
   * @type {boolean}
   */
	const allChecked = useMemo(() => {
		return params.every((param) => param[flag]);
	}, [params]);

	return (
		<List
			id={`paramsOption-${type}`}
			disablePadding={true}
			className={styles.filterPopupList}
		>
			<PropsListItem
				item={'all'}
				onChange={handleItemClick('all',!allChecked)}
				allChecked={allChecked}
			/>
			{params.map((param) => {
				const {code} = param;
				return (
					<PropsListItem
						key={code}
						onChange={handleItemClick(code)}
						item={param}
						flag={flag}
					/>
				);
			})}
		</List>
	);
};

PropsList.propTypes = {
	type: PropTypes.string.isRequired,
	flag: PropTypes.string.isRequired,
	params: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.oneOfType([PropTypes.string.isRequired,PropTypes.number.isRequired]),
		})
	).isRequired,
	onItemClick: PropTypes.func.isRequired,
};

export default PropsList;
