import { makeStyles } from "@material-ui/core";
// @ts-ignore
const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto",
  },

  nameContainer: {
    paddingRight: "15px",
    paddingBottom: "10px"
  },

  headerStyle: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#4b1c46",
  },

  leftContainer: {
    paddingRight: "15px",
    paddingBottom: "10px",
    borderRight: "1px solid #d5d2d5",
  },

  underlined: {
    '&:hover':{
    textDecoration: "underline"
  }
  },

  clickable: {
    cursor: "pointer"
  },

  noteStyle: {
    fontSize: "12px",
    color: "#4b1c46",
    marginBottom: "15px",
    marginTop: "20px",
    whiteSpace: "break-spaces",
    "& ul": {
      listStyle: "unset",
      marginLeft: 35,
      marginTop: 12,
    },
  },

  textFieldValue: {
    margin: "5px 0",
    color: "#aaa2aa",
    width: "100%",
    height: 50,
    "& div": {
      height: 50,
    },
  },

  iconButton: {
    fontSize: "1rem",
  },
  textFieldLabelDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    color: "#4b1c46",
    fontWeight: "bold",
  },

  switchAndTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  thumb: {
    backgroundColor: theme.palette.primary.dark,
  },
  track: {
    backgroundColor: theme.palette.primary.light,
  },

  rightCard: {
    paddingLeft: "15px",
    overflow: "hidden"
  },

  unitListContainer: {
    width: "100%",
  },

  smartRuleUnitLists: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "75vh",
    width: "100%",
  },

  error: {
    color: "#f44336",
    fontWeight: "600",
  },
  dialog: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },

  // **************************************

  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px",
  },

  // ************************

  paperTableContainer: {
    width: "inherit",
  },

  tableHeadRow: {
    height: 45,
    padding: 0,
  },
  tableHeadCell: {
    backgroundColor: theme.tables.headerCell.backgroundColor,
  },

  overWritePadding: {
    ...theme.tables.tableCell,
    padding: 8,
  },

  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red",
    },
  },

  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },

  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
    color: "#4b1c46",
  },
  AdvancedHolder: {
    display: "flex",
    justifyContent: "flex-start",
    color: theme.palette.colors.controlButtonColor
  },

  boldText:{
    fontWeight: 'bold',
  },

  actionsHolder: {
    padding: '10px 20px',
    display: "flex",
    justifyContent: "flex-end",
  },
  
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 15,
    display: "flex",
    flexFlow: "row nowrap",
  },

  loaderContainer: {
    alignSelf: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexFlow: "column nowrap",
    justifyContent: "center",
  },

  smartRulesContainer: {
    height: "100%",
    display: "flex",
    paddingBottom: "1rem",
    overflow: "hidden",
  },
  smartRulesTableContainer: {
    display: "flex",
  },
  smartRulesPaper: {
    boxSizing: "border-box",
    margin: "1rem",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "20px",
    maxHeight: "calc(100% - 10px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px",
    width: "100%",
  },
  smartRulesTable: {
    background: theme.palette.background.paper,
  },

  // ************************* SEARCH INPUT
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
    marginRight: "40px",
  },
  inputBase: {
    width: "190px !important",
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px",
    },
  },

  loaderPaper: {
    height: "50vh",
    width: "180%",
  },
  noData: {
    margin: "10vh auto",
    fontSize: "xx-large",
    fontWeight: 600,
    color: "#00000099",
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "200px",
  },
  filterStyle: {
    marginLeft: "10px",
  },
  blueFilter: {
    fill: theme.palette.colors.darkBlue,
  },
  disabledStyle: {
    cursor: "not-allowed",
    opacity: 0.4,
  },
  selectMenu: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%"
  },
  ruleTypeHolder: {
    marginRight: "10px",
    width: "60%"
  },
  priorityHolder: {
    flex: 1
  },
  rowContainer: {
    display: "flex"
  }
}));

export default useStyles;
