import React from 'react';

const RedAlertBold = (props) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.8661 2.99996L22.3921 19.5C22.4799 19.652 22.5261 19.8244 22.5261 20C22.5261 20.1755 22.4799 20.3479 22.3921 20.4999C22.3043 20.652 22.1781 20.7782 22.0261 20.866C21.8741 20.9537 21.7016 21 21.5261 21H2.4741C2.29856 21 2.12612 20.9537 1.9741 20.866C1.82209 20.7782 1.69585 20.652 1.60809 20.4999C1.52032 20.3479 1.47412 20.1755 1.47412 20C1.47412 19.8244 1.52033 19.652 1.6081 19.5L11.1341 2.99996C11.2219 2.84795 11.3481 2.72172 11.5001 2.63396C11.6521 2.5462 11.8246 2.5 12.0001 2.5C12.1756 2.5 12.3481 2.5462 12.5001 2.63396C12.6521 2.72172 12.7783 2.84795 12.8661 2.99996ZM4.2061 19H19.7941L12.0001 5.49996L4.2061 19ZM11.0001 16H13.0001V18H11.0001V16ZM11.0001 8.99996H13.0001V14H11.0001V8.99996Z"
			fill="#F05146"
		/>
	</svg>


);

export default RedAlertBold;
