import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, Dialog, FormControlLabel, IconButton, InputLabel, TextField, Typography } from "@material-ui/core";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Invite as sdkInvite } from "coolremote-sdk";
import { fromUnixTime, getUnixTime } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../../cool_widgets/Button";
import { Close } from "../../../icons";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import { PermissionsTree } from "../PermissionsTree";
import useStyles from "./NewInvite.style";
interface INewInvite {
    onClose: any;
    createInvite: any;
    refreshData: any;
    customerId: any;
    inviteObj?: any;
    editInvite?: boolean;
}

const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const NewInvite = ({ onClose, createInvite, refreshData, customerId, inviteObj, editInvite = false }: INewInvite) => {
    const [inviteEmail, setInviteEmail] = useState<string>("");
    const [withExpire, setWithExpire] = useState<boolean>(false);
    const [expireDate, setExpireDate] = useState<any>(null);
    const [policies, setPolicies] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const sendInviteEmail = useStoreActions((a) => a.invites.sendInviteEmail);
    const { addMessage } = useStoreActions((action) => action.errorMessage);
    const userPref = useStoreState((s) => s.users.userPreferences);
    const customerNickname = (userPref?.nicknames && userPref.nicknames[customerId]);

    useEffect(() => {
        if (!inviteObj) {
            return;
        }
        setInviteEmail(inviteObj.email);
        setWithExpire(!!inviteObj?.expiryTimestamp);
        if (inviteObj?.expiryTimestamp) {
            setExpireDate(fromUnixTime(inviteObj?.expiryTimestamp));
        }
        const pols: any = inviteObj?.policies?.reduce((data: any, item: any) => {
            const { entityId, level, application, role, expiryTimestamp } = item;
            if (data[entityId]) {
                data[entityId][application] = { entityId, level, application, role, expiryTimestamp };
                return data;
            }
            data[entityId] = {
                [application]: { entityId, level, application, role, expiryTimestamp }
            };
            return data;
        }, {});

        setPolicies(pols);

    }, [inviteObj]);

    const checkErrors = () => {
        const locErrors: any = {};
        if (!inviteEmail?.length) {
            locErrors["email"] = t`Required`;
        }
        else {
            if (!validateEmail(inviteEmail)) {
                locErrors["email"] = t`Invalid email format`;
            }
        }
        if (withExpire && !expireDate) {
            locErrors["date"] = t`Expire date is required`;
        }

        setErrors(locErrors);
        return locErrors;
    };
    const submit = () => {

        if (!_.isEmpty(checkErrors())) {
            return;
        }

        const arrPolicies: any = Object.values(policies).reduce((data: any, entity: any) => {
            const apps: any = Object.values(entity);
            if (apps !== -1) {
                data.push(...apps);
            }
            return data;
        }, []);

        if (!arrPolicies?.length) {
            addMessage({ message: t`Please add policies to the invite before you send it` });
            return;
        }

        const data: any = {
            email: inviteEmail,
            policies: arrPolicies,
            customer: customerId,

        };

        if (customerNickname) data.customerNickname = customerNickname;

        if (withExpire && expireDate) {
            data["expiryTimestamp"] = getUnixTime(expireDate);
        }

        createInvite(data)
            .then((res: { inviteId: any; email: any; }) => sendInviteEmail({ inviteId: res.inviteId, email: res.email }))
            .finally(() => {
                refreshData();
                onClose();
            });
    };

    const classes = useStyles();
    const arrPolicies = Object.values(policies).reduce((data: any, entity: any) => {
        const apps: any = Object.values(entity);
        data.push(...apps);
        return data;
    }, []);
    const data: any = {
        customer: customerId,
        policies: arrPolicies
    };

    if (customerNickname) data.customerNickname = customerNickname;

    if (withExpire && expireDate) {
        data["expiryTimestamp"] = getUnixTime(expireDate);
    }

    const updateInvite = async () => {
        if (!_.isEmpty(checkErrors())) {
            return;
        }
        try {
            await sdkInvite.deleteInvitePolicies(inviteObj?.inviteId, inviteObj?.policies.map((p: any) => p));
            await sdkInvite.updateInvite(inviteObj?.inviteId, data);
            sendInviteEmail({ inviteId: inviteObj?.inviteId, email: inviteEmail });
        } catch (err) {
            return;
        }
        refreshData();
        onClose();

    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            classes={{ paper: classes.dialog }}
        >
            <div className={classes.titleContent}>
                <Typography className={classes.headerTitle}>{inviteObj ? t`View Invite` : t`Invite new user`}</Typography>
                <IconButton disableRipple onClick={onClose} className={classes.iconBtnStyle}>
                    <Close color="#7f7692" />
                </IconButton>
            </div>

            <div className={classes.dialogContent}>

                <div className={classes.emailField}>
                    <Typography className={classes.emailTitle}>{t`Email`}</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={inviteEmail}
                        onChange={(e: any) => setInviteEmail(e.target.value)}
                        error={!!errors?.email}
                        helperText={errors?.email || ""}
                        disabled={editInvite}
                        size="small"
                    />
                </div>

                <>
                    <div className={classes.expireField}>

                        <FormControlLabel
                            style={{
                                marginRight: "30px"
                            }}
                            control={
                                <Checkbox
                                    checked={withExpire}
                                    onChange={(e: any) => { setWithExpire(e.target.checked); setExpireDate(null); setErrors({ date: "" }); }}
                                    name="expires"
                                    disabled={editInvite}
                                />
                            }
                            label="Invitation expires"
                        />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            {expireDate ? <DatePicker
                                disabled={!withExpire || editInvite}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                value={expireDate}
                                onChange={setExpireDate}
                                inputVariant="standard"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                style={{
                                    marginTop: "8px"
                                }}
                            /> : <KeyboardDatePicker
                                disabled={!withExpire || editInvite}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                value={expireDate}
                                onChange={setExpireDate}
                                inputVariant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    classes: { root: classes.datePickerRoot }
                                }}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                                style={{
                                    marginTop: "8px"
                                }}
                            />}

                        </MuiPickersUtilsProvider>
                    </div>
                    <InputLabel
                        className={classes.errorLabelStyle}
                    >{errors?.date || ""}</InputLabel>
                </>

                <PermissionsTree
                    policies={policies}
                    setPolicies={setPolicies}
                // disabled={editInvite}
                />

            </div>

            <div className={classes.buttonsContainer}>
                <Button
                    onClick={onClose}
                    width={150}
                    white
                    marginRight
                >
                    {t`Cancel`}
                </Button>
                <Button onMouseUp={editInvite ? updateInvite : submit} width={150}                >
                    {t`Send Invite`}
                </Button>
            </div>
        </Dialog>
    );
};

export default NewInvite;
