import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    // paddingBottom: '0.5rem'
    padding: 0
  },

  title: {
    fontSize: "1.25rem",
    fontFamily: "RobotoMedium",
    // color: '#4b1c46',
    display: "inline-block",
    // fontSize: '0.875rem',
    // fontWeight: 'bold',
    padding: "0 15px !importnant",
    color: "rgb(75, 28, 70)"
  },
  tableHead: {
    background: "rgb(236,235,239,0.5)",
    color: "rgb(84,89,100)"
  },
  elevation: {
    boxShadow: "none"
  },
  root: {
    /* width: '100%',
    height: '100% */
    height: "calc(100% - 20px)"
    /* padding: '1rem */
  },

  root_padding: {
    padding: "1rem"
  },

  titleUnit: {
    fontSize: "1.25rem",
    padding: "1rem",
    fontFamily: "RobotoMedium",
    color: "#4b1c46"
  },

  showAllAlertsWrapper: {
    marginRight: "0",
    alignSelf: "center",
    "& span": {
      fontSize: "0.875rem"
    }
  },

  tableCell: {
    ...theme.tables.tableCell,
    padding: "0 15px !important"
    /* padding: '6px 10px */
    /* width: '100% */
  },

  container: {
    /* maxHeight: '400px',
    overflow: 'auto */
    paddingBottom: "0.5rem",
    height: "100%"
  },
  tableHeadCell: {
    padding: "0 15px !important",
    ...theme.tables.headerCell,
    height: "45px"
  },
  paginationSection: { display: "flex !important", width: "100%", justifyContent: "flex-end" },
  removeBottomBorder: {"& td": {border: 0}},
  movePageSelectionToTop: {
    position: "absolute",
    right: "20px",
    zIndex: 1,
    top: "18px"
  }
}));

export default useStyles;
