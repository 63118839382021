import React from 'react';
import { c, t as translate } from 'ttag';
import {
  TextField,
  Radio,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search as SearchIcon, Delete } from '@material-ui/icons';

import UploadFile from '@icons/UploadFile';
import useStyles from './styles';

import { templateSource } from './../constants';

const InitialScreen = ({
  data,
  onChange,
  templates,
  templateImgPreview,
  resetToSelectedTemplateImg,
  removeTemplateImg,
}) => {
  const classes = useStyles();

  const setSource = (source) => {
    onChange({
      ...data,
      source: source,
    });
  };

  return (
    <div className={classes.initialScreen}>
      <div>
        <div className={classes.source__radioWrap}>
          <Radio
            className={classes.source__radio}
            checked={data.source === templateSource.new}
            onChange={() => setSource(templateSource.new)}
            value={templateSource.new}
            name="new-or-existing"
            inputProps={{ 'aria-label': translate`Add new` }}
          />
          <span
            onClick={() => setSource(templateSource.new)}
          >{translate`Add new`}</span>
        </div>
        <div>
          <TextField
            variant="outlined"
            name="templateName"
            placeholder={translate`Enter name`}
            className={classes.customTextField}
            disabled={data.source === templateSource.existing}
            value={data?.name}
            fullWidth
            onChange={(e) => {
              onChange({
                ...data,
                name: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div>
        <div className={classes.source__radioWrap}>
          <Radio
            className={classes.source__radio}
            checked={data.source === templateSource.existing}
            onChange={() => setSource(templateSource.existing)}
            value={templateSource.existing}
            name="new-or-existing"
            inputProps={{ 'aria-label': translate`From list` }}
          />
          <span
            onClick={() => setSource(templateSource.existing)}
          >{translate`From list`}</span>
        </div>
        <div>
          <Autocomplete
            className={classes.customAutocompleteField}
            fullWidth
            options={templates}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              onChange({
                ...data,
                template: newValue,
              });
            }}
            disabled={data.source === templateSource.new}
            renderInput={(params) => (
              <TextField
                fullWidth
                placeholder={translate`Search...`}
                {...params}
                variant="outlined"
                className={classes.customTextField}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ fill: '#aaa2aa' }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>
      {data.source === templateSource.existing &&
        templateImgPreview != data.template?.fileURL && (
        <div className={classes.resetButtonWrapper}>
            Click <span onClick={resetToSelectedTemplateImg}>here</span> to
            reset to selected template image
        </div>
      )}
      <div className={classes.dropzoneAreaWrapper}>
        <DropzoneAreaBase
          fileObjects={data?.templateImg ? [data?.templateImg] : []}
          acceptedFiles={['image/*']}
          dropzoneText={translate`Drag and drop an image here or click`}
          onAdd={(files) => {
            onChange({
              ...data,
              templateImg: files[0],
            });
          }}
          onDelete={() => {
            onChange({
              ...data,
              templateImg: null,
            });
          }}
          filesLimit={1}
          Icon={UploadFile}
          classes={{
            root: classes.dropZone,
          }}
        />
        {templateImgPreview && (
          <div className={classes.template__imagePreviewWrapper}>
            <div
              className={classes.template__imagePreview}
              style={{
                backgroundImage: `url(${templateImgPreview})`,
              }}
            ></div>
            <div className={classes.template__imagePreviewActions}>
              <IconButton onClick={removeTemplateImg}>
                <Delete />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default InitialScreen;
