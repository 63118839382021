import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiButton-contained': {
      backgroundColor: '#29132E',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#3a1a40',
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(41, 19, 46, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
    '&.MuiButton-outlined': {
      color: '#29132E',
      borderColor: '#29132E',
      '&:hover': {
        backgroundColor: 'rgba(41, 19, 46, 0.04)',
        borderColor: '#3a1a40',
      },
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        borderColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
    '&.MuiButton-text': {
      color: '#29132E',
      '&:hover': {
        backgroundColor: 'rgba(41, 19, 46, 0.04)',
      },
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
    '&$primary': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
    '&$secondary': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
    '&$error': {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
  },
  primary: {},
  secondary: {},
  error: {},
}));

const CustomButton = ({ color, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root,
        containedPrimary: classes.primary,
        containedSecondary: classes.secondary,
      }}
      color={color}
      {...props}
    />
  );
};

export default CustomButton;