import React from "react";

const PendingIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 20 20">
    <defs>
      <filter id="fa2jtl8cea">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <path stroke="#AAA2AA" d="M.951 15v-.719c0-.784.636-1.418 1.419-1.418h13.216c.783 0 1.418.634 1.418 1.418V15" transform="translate(-1265 -221) translate(270 181) translate(975 27) translate(20 13) translate(1 1)" />
                <path stroke="#AAA2AA" d="M17.492 17.474H.463c-.184 0-.333-.15-.333-.334v-1.763c0-.339.274-.612.612-.612h16.472c.338 0 .612.273.612.612v1.763c0 .184-.15.334-.334.334z" transform="translate(-1265 -221) translate(270 181) translate(975 27) translate(20 13) translate(1 1)" />
                <path d="M12.263 3.41c0 1.814-1.47 3.285-3.285 3.285-1.815 0-3.286-1.471-3.286-3.286 0-1.814 1.471-3.285 3.286-3.285 1.814 0 3.285 1.47 3.285 3.285" transform="translate(-1265 -221) translate(270 181) translate(975 27) translate(20 13) translate(1 1)" />
                <path stroke="#AAA2AA" d="M12.263 3.41c0 1.814-1.47 3.285-3.285 3.285-1.815 0-3.286-1.471-3.286-3.286 0-1.814 1.471-3.285 3.286-3.285 1.814 0 3.285 1.47 3.285 3.285zM10.383 6.38s-.512 5.503 1.88 6.483M7.572 6.38s.512 5.503-1.88 6.483" transform="translate(-1265 -221) translate(270 181) translate(975 27) translate(20 13) translate(1 1)" />
              </g>
              <g>
                <path fill="#AAA2AA" stroke="#AAA2AA" strokeWidth="1.3" d="M7.99 4.03c0 2.187-1.773 3.96-3.96 3.96C1.845 7.99.073 6.216.073 4.03.072 1.845 1.844.073 4.031.073c2.186 0 3.958 1.772 3.958 3.959z" transform="translate(-1265 -221) translate(270 181) translate(975 27) translate(20 13) translate(15 4)" />
                <g filter="url(#fa2jtl8cea)" transform="translate(-1265 -221) translate(270 181) translate(975 27) translate(20 13) translate(15 4)">
                  <g>
                    <path fill="#AAA2AA" fillRule="nonzero" d="M4.298 0.58L5.146 1.428 2.312 4.263 0.627 2.578 1.475 1.729 2.312 2.566z" transform="translate(1.16 1.877)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

export default PendingIcon;
