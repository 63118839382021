import React, { useState, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';

interface LinearProgressBarProps {
    durationInSeconds: number;
}

/**
 * Linear progress bar component that displays progress over a specified duration.
 * @typedef {Object} ILinearProgressBarProps
 * @property {number} durationInSeconds - The duration in seconds for the progress completion.
 * @param {ILinearProgressBarProps} props - The props object containing the duration for the progress in seconds.
 * @returns {JSX.Element} Linear progress bar component.
 */
const LinearProgressBar: React.FC<LinearProgressBarProps> = ({ durationInSeconds }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const startTime = performance.now();
        const durationInMilliseconds = durationInSeconds * 1000;

        const updateProgress = () => {
            const currentTime = performance.now();
            const elapsed = currentTime - startTime;
            const progressPercentage = (elapsed / durationInMilliseconds) * 100;
            setProgress(Math.min(progressPercentage, 100));
            if (progressPercentage < 100) {
                requestAnimationFrame(updateProgress);
            }
        };
        const timeoutId = setTimeout(() => {
            setProgress(100);
        }, durationInMilliseconds);
        requestAnimationFrame(updateProgress);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [durationInSeconds]);
    return <LinearProgress variant="determinate" value={progress} />;
};

export default LinearProgressBar;
