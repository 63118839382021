/**  A map that helps to convert brand names to brand numbers */
export const BrandNamesIDsMap = {
  DAIKIN: 1,
  TADIRAN: 2,
  LG: 3,
  MELCO: 4,
  GREE: 5,
  SAMSUNG: 6,
  HITACHI: 7,
  UMMCLIVET: 8,
  TOSHIBA: 9,
  ALTHERMA: 10,
  FUJITSU: 11,
  HAIER_MODBUS: 12,
  MIDEA: 13,
  PETRA: 14,
  MIDEAAIRTOWATER: 15,
  UMM: 16,
  WELLEA: 17,
  LGMV: 18,
  ECODAN: 19,
  SENSIBO: 20,
  MONNIT: 21,
  CARRIER: 22,
  'TOSHIBA-CARRIER': 23,
  PANASONIC: 24,
  GENERAL: 25,
  BLUESTAR: 28,
  HEAVY: 29,
  DCHK: 30,
  HAIER: 31
}