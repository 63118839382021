import { Dialog, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Checkbox } from "../../components/Checkbox";
import ErrorBox from "../../components/WarningBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import Switch from "../../cool_widgets/Switch/Switch";
import { Arrow as SvgArrow, Close } from "../../icons";
import ChangeoverGraph from "../../icons/ChangeoverGraph";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { toC, toF } from "../../services/converter";
import { ColdMode, HotMode } from "../../svgComponents";
import UnitsItemsTree from "../Setback/ItemsTree";
import useStyles from "./AddEditChangeoverRule.style";
import ItemsTree from "./ItemsTree";
import useChurnZero from "@hooks/useChurnZero";

const AddEditChangeoverRule = (props: any) => {
  const classes = useStyles();
  const { onClose, rule = {}, open, allChangeovers, setAllChangeovers, disabledSystems, setDisabledSystems, siteId } = props;
  const { id, name, changeoverType, masterUnit, heatSiteTemp, coolSiteTemp, heatTch, coolTch, enableChangeover: enabledChangeover = false, checkSiteTemp, systems, units, turnUnitsOn: turnedUnitsOn } = rule;
  const isEdit = !_.isEmpty(rule);

  const selections = useStoreState((s) => s.selections.selections);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const { changeoverTypes, unitTypes } = useStoreState((s) => s.types);
  const userTemperatureScale = useStoreState((s) => s.users.me.temperatureScale || 1);
  const temperatureScaleMirror = useStoreState((s) => s.temperatureScaleMirror);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const isCelsius = userTemperatureScale === +temperatureScaleMirror.celsius;
  const minTempValue = isCelsius ? 10 : 50;
  const maxTempValue = isCelsius ? 32 : 90;
  const minThresholdValue = 1;
  const maxThresholdValue = 10;

  const [ruleName, setRuleName] = useState<string>(name || "");
  const [selectedReferenceMethod, setSelectedReferenceMethod] = useState<any>(changeoverType || changeoverTypes.master);
  const [selectedControlUnit, setSelectedControlUnit] = useState<string>(masterUnit || "");
  const [controlUnits, setControlUnits] = useState<any>([]);
  const [heatTemp, setHeatTemp] = useState<number>(isCelsius ? heatSiteTemp || minTempValue : Math.round(toF(heatSiteTemp)) || minTempValue);
  const [coolTemp, setCoolTemp] = useState<number>(isCelsius ? coolSiteTemp || minTempValue : Math.round(toF(coolSiteTemp)) || minTempValue);
  const [heatThreshold, setHeatThreshold] = useState<number>(heatTch || 1);
  const [coolThreshold, setCoolThreshold] = useState<number>(coolTch || 1);
  const [enableChangeover, setEnableChangeover] = useState<boolean>(!!enabledChangeover || false);
  const [turnUnitsOn, setTurnUnitsOn] = useState<boolean>(!!turnedUnitsOn || false);
  const [siteTempEnable, setSiteTempEnable] = useState<boolean>(checkSiteTemp || false);
  const [currentSelectedSystems, setCurrentSelectedSystems] = useState<any>(systems || []);
  const [systemsToSitesMap, setSystemsToSitesMap] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const createChangeover = useStoreActions((action) => action.operationAutomate.createChangeover);
  const updateChangeover = useStoreActions((action) => action.operationAutomate.updateChangeover);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [dirty, setDirty] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [currentSelectedUnits, setCurrentSelectedUnits] = useState<any>(units || []);
  const { canUpdateAutoChangeOverRule = true } = rule;

  const {trackEvent} = useChurnZero()

  const changeoverOptions = [
    { name: t`Specific Reference Unit`, value: changeoverTypes.master },
    { name: t`Average of all operating units`, value: changeoverTypes.average },
    { name: t`Majority of all operating units`, value: changeoverTypes.majority },
    { name: t`Individual Unit Auto mode change`, value: changeoverTypes.units }
  ];

  useEffect(() => {
    if (currentSelectedSystems) {
      const tempControlUnits = Object.values(allUnits).filter((unit: any) => currentSelectedSystems.indexOf(unit.system) !== -1 && unit.type === unitTypes.indoor);
      setControlUnits(tempControlUnits);
      const selectedControlUnitObj = allUnits[selectedControlUnit];
      if (selectedControlUnitObj && currentSelectedSystems.indexOf(selectedControlUnitObj?.system) === -1) {
        setSelectedControlUnit("");
      }
    }
  }, [currentSelectedSystems]);

  useEffect(() => {
    let systemsMappedToSites: any = {};

    for (const system in allSystems) {
      const { site } = allSystems[system];

      if (systemsMappedToSites[site]) {
        systemsMappedToSites[site][system] = true;
      } else {
        systemsMappedToSites[site] = { [system]: true };
      }
    }

    setSystemsToSitesMap(systemsMappedToSites);
  }, []);

  const validate = () => {
    setErrors({});
    setErrorMsg("");

    if (!ruleName) {
      setErrors({ ruleName: true });
      setErrorMsg(t`Rule Name is Required`);
      return false;
    }

    if (ruleName.length < 3 || ruleName.length > 25 || !ruleName?.match(/\S/g)?.length) {
      setErrors({ ruleName: true });
      setErrorMsg(t`Rule Name should be from 3 to 25 chars`);
      return false;
    }

    if (selectedReferenceMethod === changeoverTypes.master && !selectedControlUnit) {
      setErrors({ controlUnit: true });
      setErrorMsg(t`Select Control Unit`);
      return false;
    }
    if (coolTemp < minTempValue || coolTemp > maxTempValue) {
      setErrors({ coolTemp: true });
      setErrorMsg(t`Cool temperature range is ` + minTempValue + ` - ` + maxTempValue);
      return false;
    }
    if (coolThreshold < minThresholdValue || coolThreshold > maxThresholdValue) {
      setErrors({ coolThreshold: true });
      setErrorMsg(t`Cool threshold range is ` + minThresholdValue + ` - ` + maxThresholdValue);
      return false;
    }
    if (heatTemp < minTempValue || heatTemp > maxTempValue) {
      setErrors({ heatTemp: true });
      setErrorMsg(t`Heat temperature range is ` + minTempValue + ` - ` + maxTempValue);
      return false;
    }
    if (heatThreshold < minThresholdValue || heatThreshold > maxThresholdValue) {
      setErrors({ heatThreshold: true });
      setErrorMsg(t`Heat threshold range is ` + minThresholdValue + ` - ` + maxThresholdValue);
      return false;
    }

    if (currentSelectedSystems.length < 1 && selectedReferenceMethod !== changeoverTypes.units) {
      setErrors({ selectedSystems: true });
      setErrorMsg(t`Select System From Apply Settings to`);
      return false;
    }
    if (selectedReferenceMethod === changeoverTypes.units && currentSelectedUnits.length < 1) {
      setErrors({ selectedSystems: true });
      setErrorMsg(t`Select Unit From Apply Settings to`);
      return false;
    }

    return true;
  };
  const Save = () => {

    trackEvent('AutoChangeoverApplyRule','The user created an Auto Changeover rule');

    const pass = validate();
    if (!pass) {
      return;
    }

    const changeoverObj: any = {
      name: ruleName,
      coolTch: coolThreshold,
      heatTch: heatThreshold,
      checkSiteTemp: siteTempEnable,
      changeoverType: selectedReferenceMethod,
      enableChangeover,
      turnUnitsOn,
      systems: currentSelectedSystems,
      coolSiteTemp: isCelsius ? coolTemp : Math.round(toC(coolTemp)),
      heatSiteTemp: isCelsius ? heatTemp : Math.round(toC(heatTemp)),
      site: siteId
    };

    if (selectedReferenceMethod === changeoverTypes.master) {
      changeoverObj.masterUnit = selectedControlUnit;
    }
    if (selectedReferenceMethod === changeoverTypes.units) {
      delete changeoverObj.systems;
      changeoverObj.units = currentSelectedUnits;
    }

    if (!isEdit) {
      createChangeover({ data: changeoverObj })
        .then((res: any) => {
          setAllChangeovers({ ...allChangeovers, [res.id]: res });
          onClose();
        })
        .catch((err: any) => addMessage({ message: err.message }));

    } else {
      delete changeoverObj.site;
      updateChangeover({ id, updatedData: changeoverObj })
        .then((res: any) => {
          setAllChangeovers({ ...allChangeovers, [id]: res });
          onClose();
        })
        .catch((err: any) => addMessage({ message: err.message }));
    }
  };

  const disabled = !canUpdateAutoChangeOverRule;

  return (
    <Dialog
      open={open}
      onClose={() => dirty ? setOpenConfirmDialog(true) : onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{isEdit ? t`Edit Auto Changeover` : t`Create Auto Changeover`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => disabled || !dirty ? onClose() : setOpenConfirmDialog(true)}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContainer}>
        <div className={classes.dialogContent}>

          <div className={classes.leftSide}>
            <div className={classes.blockContainer}>
              <TextField
                disabled={disabled}
                variant={"outlined"}
                name={"ruleName"}
                label={"Rule Name"}
                placeholder={"Rule Name"}
                value={ruleName}
                onChange={(e: any) => { setRuleName(e.target.value); !dirty && setDirty(true); }}
                error={errors.ruleName}
              />
            </div>
            <div className={classes.blockContainer}>
              <Typography className={classes.title}>{t`Select Unit Reference Method`}</Typography>
              <Select
                labelId="changeoverSelect"
                value={selectedReferenceMethod}
                className={classes.select}
                onChange={(event: any) => { setSelectedReferenceMethod(event.target.value); !dirty && setDirty(true); }}
                IconComponent={SvgArrow}
                variant="outlined"
                disableUnderline
                disabled={disabled}
              >
                {changeoverOptions.map((option: any) => (
                  <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
                ))}
              </Select>
            </div>
            {selectedReferenceMethod === changeoverTypes.master &&
              <div className={classes.blockContainer}>
                <Typography className={classes.title}>{t`Select Unit`}</Typography>
                <Select
                  disabled={disabled}
                  value={selectedControlUnit}
                  className={classes.select}
                  onChange={(event: any) => { setSelectedControlUnit(event.target.value); !dirty && setDirty(true); }}
                  IconComponent={SvgArrow}
                  variant="outlined"
                  disableUnderline
                  error={errors.controlUnit}
                  MenuProps={{
                    classes: { paper: classes.menuItemsContainer }
                  }}
                >
                  {controlUnits.length !== 0 ?
                    Object.values(controlUnits).map((unit: any) => (
                      <MenuItem className={clsx(classes.menuItem, !unit.isVisible && classes.hideOption)} value={unit.id} key={unit.id}>{unit.name}</MenuItem>
                    ))
                    : <MenuItem key={`no-units-option`} disabled value={""}>
                      {t`Choose the system first`}
                    </MenuItem>}
                </Select>
              </div>}
            <div className={classes.blockContainer}>
              <Typography className={classes.noteTxt}>
                <span className={classes.bold}> {t`Note: `}</span>{t`Please be advised to avoid mode change conflicts,
               make sure Changeover Logic is not defined for the selected systems from another source
               (e.g. Central controller, Local wall controller).`}
              </Typography>
            </div>
          </div>
          <div className={clsx(classes.rightSide, classes.limitMinWidth)}>
            <Typography className={classes.sectionTitle}>{t`When to Trigger the Changeover`}</Typography>
            <div className={clsx(classes.blockContainer, classes.thresholdContainer)}>
              <div className={classes.thresholdBlock}>
                <Typography className={classes.title}>{t`Indoor Unit Condition`}</Typography>
                <Typography className={classes.differentialText}>{t`Differential - Threshold`}</Typography>
                <div className={classes.modeThresholdRow}>
                  <ColdMode className={classes.modeIcon} />
                  <TextField
                    disabled={disabled}
                    id="Cool-Threshold"
                    label="Cool (Tc)"
                    type="number"
                    value={coolThreshold}
                    onChange={(e) => { setCoolThreshold(+e.target.value); !dirty && setDirty(true); }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.thresholdInput}
                    variant="outlined"
                    InputProps={{ inputProps: { min: minThresholdValue, max: maxThresholdValue } }}
                    error={errors.coolThreshold}
                  />
                </div>
                <div className={classes.modeThresholdRow}>
                  <HotMode className={classes.modeIcon} />
                  <TextField
                    disabled={disabled}
                    id="Heat-Threshold"
                    label="Heat (Th)"
                    type="number"
                    value={heatThreshold}
                    onChange={(e) => { setHeatThreshold(+e.target.value); !dirty && setDirty(true); }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.thresholdInput}
                    variant="outlined"
                    InputProps={{ inputProps: { min: minThresholdValue, max: maxThresholdValue } }}
                    error={errors.heatThreshold}
                  />
                </div>
              </div>
              <ChangeoverGraph className={classes.thresholdSvg} />
            </div>
            <div className={classes.blockContainer}>
              <div className={classes.siteTemp}>
                <Checkbox
                  disabled={disabled}
                  onChange={() => { setSiteTempEnable(!siteTempEnable); !dirty && setDirty(true); }}
                  checked={!!siteTempEnable}
                  className={classes.checkboxStyle}
                  color={"primary"}
                />
                <Typography className={classes.title}>{t`External Site Temperature Considerations`}</Typography>
              </div>
              <div className={classes.modeThresholdRow}>
                <ColdMode className={classes.modeIcon} />
                <TextField
                  disabled={disabled}
                  id="Cool-Threshold"
                  label="Cool"
                  type="number"
                  value={coolTemp}
                  onChange={(e) => { setCoolTemp(+e.target.value); !dirty && setDirty(true); }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.tempInput}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{isCelsius ? "°C" : "°F"}</InputAdornment>,
                    inputProps: { min: minTempValue, max: maxTempValue }
                  }}
                  error={errors.coolTemp}
                />
                <Typography className={classes.siteTempNote}>
                  {t`Changeover to Cool Mode will only occur if external site temperature is above this value`}
                </Typography>
              </div>
              <div className={classes.modeThresholdRow}>
                <HotMode className={classes.modeIcon} />
                <TextField
                  disabled={disabled}
                  id="Heat-Threshold"
                  label="Heat"
                  type="number"
                  value={heatTemp}
                  onChange={(e) => { setHeatTemp(+e.target.value); !dirty && setDirty(true); }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{isCelsius ? "°C" : "°F"}</InputAdornment>,
                    inputProps: { min: minTempValue, max: maxTempValue }
                  }}
                  className={classes.tempInput}
                  variant="outlined"
                  error={errors.heatTemp}
                />
                <Typography className={classes.siteTempNote}>
                  {t`Changeover to Heat Mode will only occur if external site temperature is under this value`}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.endRightSide}>
            <Typography className={classes.sectionTitle}>{selectedReferenceMethod === changeoverTypes.units ? t`Select Units` : t`Select Systems`}</Typography>
            {selectedReferenceMethod !== changeoverTypes.units ? <ItemsTree
              disableAll={disabled}
              allSystems={allSystems}
              sitesWithSystems={systemsToSitesMap}
              site={allSites[siteId]}
              // customers={[allCustomers[selectedCustomerId]]}
              selectedSystems={currentSelectedSystems}
              updateSelectedSystems={setCurrentSelectedSystems}
              disabledSystems={disabledSystems}
              setDisabledSystems={setDisabledSystems}
              dirty={dirty}
              setDirty={setDirty}
            />
              :
              <UnitsItemsTree
                // allUnits={allUnits}
                // customers={[allCustomers[selectedCustomerId]]}
                site={allSites[siteId]}
                currentSelectedUnits={currentSelectedUnits}
                setCurrentSelectedUnits={setCurrentSelectedUnits}
                setDirty={setDirty}
              />
            }
            <div>
              <div className={classes.switchAndTitle}>
                <Typography className={classes.switchTitle}>{t`Turn units on after changeover`}</Typography>
                <Checkbox
                  disabled={disabled}
                  onChange={() => { setTurnUnitsOn(!turnUnitsOn); !dirty && setDirty(true); }}
                  checked={!!turnUnitsOn}
                  className={classes.checkboxStyle}
                  color={"primary"}
                />
              </div>

              <div className={classes.switchAndTitle}>
                <Typography className={classes.switchTitle}>{t`Activate Auto Changeover Mode`}</Typography>
                <Switch
                  disabled={disabled}
                  color={"default"}
                  checked={!!enableChangeover}
                  disableRipple={true}
                  onChange={() => { setEnableChangeover(!enableChangeover); !dirty && setDirty(true); }}
                  value={true}
                  classes={{
                    thumb: enableChangeover && classes.thumb,
                    track: classes.track
                  }}
                />
              </div>

            </div>

          </div>
        </div>
        <div className={classes.dialogActions}>
          <Typography className={classes.errorMsg}> {errorMsg || " "}</Typography>
          <Button
            onClick={Save}
            onMouseDown={(event: any) => event.preventDefault()}
            marginRight
            width={150}
            disabled={!dirty || disabled}
          >
            {t`Apply`}
          </Button>
        </div>
        {openConfirmDialog && <ErrorBox
          title={t`Discard Changes`}
          error={"Are you sure you want to discard changes made on this page?"}
          onAccept={() => { onClose(); setOpenConfirmDialog(false); }}
          onClose={() => setOpenConfirmDialog(false)} />
        }
      </div>
    </Dialog >
  );
};

export default AddEditChangeoverRule;
