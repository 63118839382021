import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import useStyles from './styles';

/**
 * NumericValue component allows incrementing and decrementing a numeric value within specified bounds.
 *
 * @param {Object} props - Component properties.
 * @param {number} props.value - The current numeric value.
 * @param {number} props.max - The maximum allowable value.
 * @param {number} props.min - The minimum allowable value.
 * @param {boolean} props.isDisabled - Whether the component is disabled.
 * @param {number} props.step - Step value for increment/decrement (default: 1)
 * @param {function} props.onChange - Callback function when the value changes.
 * @returns {JSX.Element} The rendered component.
 */
const NumericValue = ({
  value,
  max,
  min,
  isDisabled,
  step,
  onChange,
}) => {
  const { container, button } = useStyles();

  // Check if value is a valid number
  const valueIsNumber = typeof value === 'number' && !isNaN(value);

  // If value is not a number, display 0 and disable the component
  const displayValue = valueIsNumber ? parseInt(value) : 0;
  const componentDisabled = isDisabled || !valueIsNumber || !step;

  // Determine if the increment and decrement buttons should be disabled
  const disableUp = componentDisabled || displayValue >= max;
  const disableDown = componentDisabled || displayValue <= min;

  /**
   * Handler for incrementing the value.
   */
  const handleUpClick = () => {
    if (!componentDisabled && displayValue < max) {
      onChange(displayValue + parseInt(step));
    }
  };

  /**
   * Handler for decrementing the value.
   */
  const handleDownClick = () => {
    if (!componentDisabled && displayValue > min) {
      onChange(displayValue - parseInt(step));
    }
  };

  return (
    <div className={container}>
      <IconButton
        color="primary"
        className={button}
        onClick={handleDownClick}
        disabled={disableDown}
      >
        <ArrowDownward />
      </IconButton>
      <div>{displayValue}</div>
      <IconButton
        color="primary"
        className={button}
        onClick={handleUpClick}
        disabled={disableUp}
      >
        <ArrowUpward />
      </IconButton>
    </div>
  );
};

NumericValue.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
};

NumericValue.defaultProps = {
  isDisabled: false,
};

export default NumericValue;
