import { useStoreState } from '@models/RootStore';
import moment from 'moment-timezone';

/**
 * 
 * @typedef {Object} UseDateTimeOutput
 * @property {(timeStamp:Number)=> String} timeStampToDateString - A function to convert a server timestamp to date + time string with consideration for user settings
 */

/**
 * A hook that provide data and functions for converting between different date-time formats.
 * The hook uses timeFormat and dateFormat defined in user settings.
 * @returns {UseDateTimeOutput}
 */
const useDateTime = () => {

	const { timeFormat, dateFormat } = useStoreState(state => state.users ?? {});

	const timezone = moment.tz.guess();

	const timeStampToDateString = (timeStamp) => {
		return moment(timeStamp).tz(timezone).format(`${dateFormat} ${timeFormat}`);
	};

	return {
		timeStampToDateString
	};

};

export default useDateTime;