import { PowerReportSchedule as PowerReportScheduleSdk } from "coolremote-sdk";
import {
  thunk,
  Thunk
} from "easy-peasy";

export interface IGeneralReportsModel {
  getGeneralReports: Thunk<IGeneralReportsModel>;
  getGeneralReportById: Thunk<IGeneralReportsModel, { id: string }>;
  createGeneralReport: Thunk<IGeneralReportsModel, { data: any }>;
  updateReport: Thunk<IGeneralReportsModel, { id: string; data: any }>;
  removeGeneralReport: Thunk<IGeneralReportsModel, { id: string }>;
  getReportHistoryFiles: Thunk<IGeneralReportsModel>;
  getReportHistoryFileById: Thunk<IGeneralReportsModel, { id: string }>;
  deleteReportHistoryFile: Thunk<IGeneralReportsModel, { id: string }>;
}

export const generalReportsModel: IGeneralReportsModel = {
  getGeneralReports: thunk(async (actions) => {
    const allReports = await PowerReportScheduleSdk.getGeneralReports();
    return allReports;
  }),
  getGeneralReportById: thunk(async (actions, payload) => {
    return PowerReportScheduleSdk.getGeneralReportById(payload.id);
  }),
  createGeneralReport: thunk((actions, payload) => {
    return PowerReportScheduleSdk.createGeneralReport(payload.data);
  }),
  updateReport: thunk(async (actions, payload) => {
    const data = await PowerReportScheduleSdk.updateGeneralReport(payload.id, payload.data);

    return data;
  }),
  removeGeneralReport: thunk(async (actions, payload) => {
    const newData = await PowerReportScheduleSdk.removeGeneralReport(payload.id);
    return newData;
  }),
  getReportHistoryFiles: thunk(async (actions) => {
    const allReports = await PowerReportScheduleSdk.getReportHistoryFiles();
    return allReports;
  }),
  getReportHistoryFileById: thunk(async (actions, payload) => {
    return PowerReportScheduleSdk.getReportHistoryFileById(payload.id);
  }),
  deleteReportHistoryFile: thunk(async (actions, payload) => {
    const newData = await PowerReportScheduleSdk.deleteReportHistoryFile(payload.id);
    return newData;
  })

};
