
import React, { useEffect } from 'react';

import { xhr } from 'coolremote-sdk';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useStoreActions, useStoreState } from '@models/RootStore';
import Loading from '@components/Loading/Loading';
import useChurnZero from '@hooks/useChurnZero';


/**
 * @typedef PrivateRouteProps
 * @property {any} [children] - The content of the component.
 * @property {string} [pageName=""] - The name for the rendered page.
 * @property {boolean} [exact]
 * @property {string} [path]
 * @property {any} [component]
 * @property {Function} [render]
 */

/**
 * PrivateRoute component for handling protected routes with tracking and authentication.
 *
 * This component checks if the user is logged in and initialized, and tracks page visits
 * using the `useChurnZero` hook. It redirects to the login page if the user is not logged in
 * or if there is no valid token.
 *
 * @component
 * @param {PrivateRouteProps} props - The content of the component.
 * @returns {JSX.Element}
 *
 * @example
 * <PrivateRoute pageName="Dashboard" path="/dashboard" exact>
 *   <Dashboard />
 * </PrivateRoute>
 */
const PrivateRoute = ({
	children,
	pageName,
	...rest
}) => {
	const isLoggedIn = useStoreState((s) => s.isLoggedIn);
	const isInitializesd = useStoreState((s) => s.isInitialized);
	const location = useLocation();
	const doLogout = useStoreActions((a) => a.doLogout);
	const startLoader = useStoreActions((a) => a.loader.startLoader);
	const finishLoader = useStoreActions((a) => a.loader.finishLoader);
	const adminLogin = useStoreActions((a) => a.adminLogin);

	const validToken = xhr.getToken();
	const localStorageToken = localStorage.getItem('token');
	const values = queryString.parse(rest.location.search);
	const username = values && values.username || '';
	const pathname = rest.location.pathname;

	const { trackEvent } = useChurnZero();

	useEffect(() => {
		if(pageName){
			const trimmedName = pageName.split(' ').join('');
			trackEvent('PV_'+trimmedName, `User entered page ${pageName}`);
			return () => {};
		}

		if (pathname) {
			const trimmedPath = pathname.replace('/', '');
			trackEvent('PV_'+trimmedPath,`User entered page ${trimmedPath}`);
		}
	}, [pathname,pageName]);


	useEffect(() => {
		if (isInitializesd || !isLoggedIn) {
			finishLoader();
		}
		if (!isInitializesd && isLoggedIn) {
			if (window.location.href.includes('airconnectpro')) {
				startLoader();
			}
		}
	}, [isInitializesd, isLoggedIn]);

	useEffect(() => {
		if (pathname === '/dashboard' && username && isLoggedIn && isInitializesd) {
			adminLogin(username).catch(() => {
				doLogout();
				finishLoader();
			});
		}
	}, [isLoggedIn, isInitializesd]);

	if (isInitializesd && (!validToken || !localStorageToken)) {
		doLogout();
		finishLoader();
		return (
			<Redirect
				to={{
					pathname: '/',
					state: { from: location }
				}}
			/>
		);
	}


	return isLoggedIn ?
		((pathname === '/dashboard' && isMobile) ? <Route {...rest}>{children}</Route> :
			(isInitializesd ? <Route {...rest}>{children}</Route> : <Loading mobile={isMobile} />))
		:
		<Redirect
			to={{
				pathname: '/',
				state: { from: location }
			}}
		/>;
};

export default PrivateRoute;
