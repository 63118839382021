import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
    filterItem: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#efeff2",
        borderRadius: 6,
        marginRight: 15,
        padding: "0 8px",
        height: 32,
        maxWidth: "100%"
    },
    filterItemText: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#545964",
        whiteSpace: "nowrap",
        marginRight: 8,
        maxWidth: "calc(100% - 26px)",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    filterItemCloseIcon: {
        transform: "scale(0.5)",
        marginLeft: "-0.5px"
    },
    filterItemIconBtn: {
        backgroundColor: "#aaa2aa",
        borderRadius: "50%",
        width: 18,
        height: 18,
        padding: 0
    }
}));

export default useStyles;
