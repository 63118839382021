import {
  Dialog,
  makeStyles,
  TextField
} from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { systemViewStyle } from "./SystemView.style";

const CreateOtherUnitPopUp = (props: any) => {
  const classes = systemViewStyle();
  const { device, line, close, onAdd } = props;
  const [value, setValue] = useState<string>("");
  const [touched, setTouched] = useState<boolean>(false);

  const handleInputChange = (event: any) => {
    !touched && setTouched(true);
    setValue(event.target.value);
  };

  const handleOnAdd = () => {
    if (!value) {
      !touched && setTouched(true);
      return;
    }
    onAdd(device, line, value);
  };

  return <Dialog
    open={true}
    onClose={close}
    aria-labelledby="create-other-unit-dialog"
    classes={{ paper: classes.otherUnitDialog }}
  >
    <TextField
      label={t`Unit Name`}
      variant="outlined"
      className={classes.unitTxtFeild}
      value={value}
      onChange={handleInputChange}
      error={touched && !value}
    />
    <div className={classes.actionsHolder}>
      <Button width={150} white marginRight onClick={close}>{t`Cancel`}</Button>
      <Button width={150} onClick={handleOnAdd}>{t`Add`}</Button>
    </div>
  </Dialog>;
};

export { CreateOtherUnitPopUp };
