import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({

  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px",
    overflowY: "auto"
  },
  noUnitText: {
    fontSize: "34px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    width: "570px",
    marginTop: "43px",
    color: theme.overrides.MuiButton.outlinedPrimary.color
  },
  imageContainer: {
    height: "50%",
    "&>svg": {
      width: "100%",
      height: "100%"
    }
  },
  textContainer: {
    height: "30%"
  },
  expand: {
    width: '100%',
    height: '100%',
    marginTop: 0
  }

}));

export default useStyles;
