import React from 'react';

import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { t } from 'ttag';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';


import Button from '../../../../cool_widgets/Button';
import { LoginSchema } from '../../utils/login';
import { useStoreState } from './../../../../models/RootStore';

/**
 * The LoginForm component renders a login form with username and password fields.
 * It uses Formik to handle form validation and submission.
 *
 * @param {object} loginState - The login state object, containing any errors or messages.
 * @param {function} onSubmit - The callback function to call when the form is submitted.
 * @param {object} classes - The CSS class names for the form elements.
 * @param {function} InputField - The custom input field component to use.
 *
 * @returns {ReactElement} - The login form component.
 */
const LoginForm = ({ loginState, onSubmit: handleLogin, classes, inputField:InputField }) => {
	const configFile = useStoreState((s) => s.config);

	return (
	// Use Formik to handle form validation and submission
		<div className={clsx('login-form',classes.form)}>
			<Formik
				initialValues={loginState} // Set initial form values
				onSubmit={handleLogin} // Function to call when form is submitted
				enableReinitialize={true} // Reinitialize form on value changes
				validationSchema={LoginSchema}  // Define form validation rules
				validateOnChange={false} // Disable validation on value changes
				validateOnBlur={false} // Disable validation on blur
			>
				{({ handleSubmit, errors, touched, values }) => (
					<form onSubmit={handleSubmit} className={classes.form}>
						<Grid container className={classes.container}>
							<Grid item className={classes.fieldContainer}>
								<Field
									tabIndex={1}
									label={t`Username`}
									value={values.username}
									name="username"
									component={InputField}
									className={classes.inputField}
									error={errors.username && touched.username ? true : false}
									helperText={errors.username && touched.username ? errors.username : ''}
								/>
								{errors.username ? <Typography style={{ color: 'red' }}> </Typography> : null}
							</Grid>
							<Grid item className={classes.fieldContainer}>
								<Field
									type="password"
									tabIndex={2}
									label={t`Password`}
									value={values.password}
									name="password"
									component={InputField}
									className={classes.inputField}
									error={errors.password && touched.password ? true : false}
									helperText={errors.password && touched.password ? errors.password : ''}
								/>
							</Grid>
							<Grid item className={classes.fieldContainer}>
								{loginState.error ? (
									<span className={classes.error}>{loginState.error}</span>
								) : null}
							</Grid>
							<Grid item className={classes.buttonContainer}>
								<Button
									title={t`LOGIN`}
									className={classes.buttonLogin}
									uppercase
									bold
									onClick={handleSubmit}
								>
									{t`LOGIN`}
								</Button>
								<button type="submit" style={{ display: 'none' }}>login2</button>
								{/* *this second button is only to capture the "enter" button.. the first button uses on mouse up due to bug in MUI */}
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</div>
	);
};

// Define propTypes for the component
LoginForm.propTypes = {
	loginState: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	classes: PropTypes.object,
	inputField:PropTypes.element
};

LoginForm.defaultProps = {
	loginState:{},
	classes:{},
	inputField: 'input'
};

export default LoginForm;
