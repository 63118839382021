import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import React from "react";
import useStyles from "./UserDetailsField.style";

const UserDetailsField: React.FC<FieldProps & TextFieldProps> = ({
  field,
  meta,
  form,
  ...props
}) => {
  const classes = useStyles();
  return (
    <TextField
      error={props.error}
      helperText={props.helperText}
      {...field}
      {...props}
      margin="normal"
      variant="filled"
      FormHelperTextProps={{
        classes: {
          contained: classes.helperTextStyle
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          shrink: classes.shrinkStyle
        }
      }}
      InputProps={{
        classes: {
          input: classes.inputStyle,
          root: classes.rootStyle
        }
      }}
    />
  );
};

export default UserDetailsField;
