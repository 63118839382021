import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '50%',
    maxWidth: '90% !important',
    height: '780px',
    maxHeight: '90%',
    overflow: 'hidden',
    borderRadius: '8px',
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  dialogContent: {
    padding: '15px 20px 0px 20px',
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: 1,
  },
  rightSideContainer: {
    padding: '16px',
  },
  leftSideContainer: {
    padding: '16px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '10%',
      bottom: '5%',
      right: 0,
      width: '1px',
      backgroundColor: '#d5d2d5',
    },
  },
  tableContainer: {
    height: '700px',
    overflowY: 'auto',
  },
  tableCell: {
    ...theme.tables.tableCell,
    padding: '0 15px !important',
    height: '40px',
    border: 0,
  },
  tableHeadCell: {
    padding: '0 15px !important',
    backgroundColor: '#fff',
    textTransform: 'none',
    height: '40px',
  },
  actionsContainer: {
    borderTop: '1px solid #d5d2d5',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '20px 10px',
    width: '100%',
    bottom: 0,
    left: 0,
    position: 'sticky',
    zIndex: 3,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
    marginLeft: 10,
  },
  label: {
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'left',
    paddingLeft: '10px',
  },
  inputRoot: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    height: '25px',
    fontSize: '14px',
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
  },
  enumerationsListWrapper:{
    height:90,
    overflow:'auto'
  },
  enumerationsFormWrapper:{
    height:210,
    overflow:'auto'
  },
  //This is here because style attribute does not allow "important".
  enumerationCellStyles:{
    paddingTop:'10px !important',
    verticalAlign:'top'
  }
}));

export default useStyles;
