import * as React from 'react';

function InfoIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM8.6875 4.625C8.87292 4.625 9.05418 4.67998 9.20835 4.783C9.36252 4.88601 9.48268 5.03243 9.55364 5.20373C9.6246 5.37504 9.64316 5.56354 9.60699 5.7454C9.57082 5.92725 9.48153 6.0943 9.35042 6.22541C9.2193 6.35652 9.05226 6.44581 8.8704 6.48199C8.68854 6.51816 8.50004 6.49959 8.32874 6.42864C8.15743 6.35768 8.01101 6.23752 7.908 6.08335C7.80499 5.92918 7.75 5.74792 7.75 5.5625C7.75 5.31386 7.84878 5.0754 8.02459 4.89959C8.20041 4.72377 8.43886 4.625 8.6875 4.625ZM9.625 13.375C9.29348 13.375 8.97554 13.2433 8.74112 13.0089C8.5067 12.7745 8.375 12.4565 8.375 12.125V9C8.20924 9 8.05027 8.93415 7.93306 8.81694C7.81585 8.69973 7.75 8.54076 7.75 8.375C7.75 8.20924 7.81585 8.05027 7.93306 7.93306C8.05027 7.81585 8.20924 7.75 8.375 7.75C8.70652 7.75 9.02447 7.8817 9.25889 8.11612C9.49331 8.35054 9.625 8.66848 9.625 9V12.125C9.79076 12.125 9.94974 12.1908 10.0669 12.3081C10.1842 12.4253 10.25 12.5842 10.25 12.75C10.25 12.9158 10.1842 13.0747 10.0669 13.1919C9.94974 13.3092 9.79076 13.375 9.625 13.375Z"
        fill="#6B4B68"
      />
    </svg>
  );
}

export default InfoIcon;
