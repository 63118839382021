import { alpha, createStyles } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    pickerContainer: {
      backgroundImage: "linear-gradient(to left, #421a40, #29132e 100%)",
      width: "100%",
      maxHeight: "280px",
      height: "280px",
      bottom: 0,
      position: "absolute",
      zIndex: 1100,
      overflow: "auto",
      display: "flex",
      flexDirection: "column"
      // "@media (min-width: 769px)": { maxWidth: "375px" },
      // "@media (min-height: 1025px)": {
      //   maxWidth: "375px"
      // }
    },
    actionsContainer: {
      width: "100%",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    itemsContainer: {
      width: "100%",
      display: "flex",
      maxHeight: "238px",
      position: "relative",
      borderTop: `solid 1px ${alpha("#fdfdfe", 0.2)}`,
      borderBottom: `solid 1px ${alpha("#fdfdfe", 0.2)}`,
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    topBorders: {
      width: "100%",
      height: "34px",
      position: "absolute",
      left: 0,
      top: "34px",
      zIndex: 0,
      borderTop: `solid 1px ${alpha("#fdfdfe", 0.2)}`,
      borderBottom: `solid 1px ${alpha("#fdfdfe", 0.2)}`
    },
    bottomBorders: {
      width: "100%",
      height: "34px",
      position: "absolute",
      left: 0,
      top: "170px",
      zIndex: 0,
      borderTop: `solid 1px ${alpha("#fdfdfe", 0.2)}`,
      borderBottom: `solid 1px ${alpha("#fdfdfe", 0.2)}`
    },
    indicator: {
      boxSizing: "border-box",
      width: "100%",
      height: "34px",
      position: "absolute",
      left: 0,
      top: "102px",
      zIndex: 3,
      backgroundColor: alpha("#fdfdfe", 0.2)
    },
    pickerStyle: {
      height: "238px",
      display: "block",
      position: "relative",
      overflow: "hidden",
      width: "100%",
      flex: 1,
      textAlign: "center"
    },
    pickerItem: {
      fontSize: "16px",
      height: "34px",
      lineHeight: "34px",
      padding: "0 10px",
      whiteSpace: "nowrap",
      position: "relative",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      boxSizing: "border-box",
      fontFamily: "Roboto",
      color: alpha("#fdfdfe", 0.6)
    },
    selected: { color: "#fdfdfe", fontSize: "22px" },
    pickerWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "15%",
      alignItems: "center"
    },
    middayWrapper: {
      color: "white",
      "&:before": {
        borderColor: "white"
      },
      "&:after": {
        borderColor: "white"
      }
    },
    colonWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "5%",
      alignItems: "center",
      "& *": {
        color: "white",
        fontWeight: "bold"
      }
    },
    pickerIcon: {
      color: "white",
      fill: "white"
    },
    timeInput: {
      color: "white",
      border: `1px solid ${alpha("#fdfdfe", 0.5)}`,
      borderRadius: "6px",
      padding: "5px",
      textAlign: "center",
      boxSizing: "border-box",
      height: "40px",
      width: "40px",
      "& input::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      "& input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
        textAlign: "center",
        padding: 0
      }
    },
    btnStyle: {
      background: "transparent",
      color: alpha("#fff", 0.6),
      textTransform: "capitalize",
      textDecoration: "underline"
    }
  });
