import { Action, action } from 'easy-peasy';

export interface ILoaderModel {
  isLoading: boolean;
  startLoader: Action<ILoaderModel>;
  finishLoader: Action<ILoaderModel>;
}

export const loaderModel: ILoaderModel = {
  isLoading: false,
  startLoader: action(state => {
    state.isLoading = true;
  }),
  finishLoader: action(state => {
    state.isLoading = false;
  })
};
