import { alpha, makeStyles } from "@material-ui/core";

export default makeStyles({
  menuStyle: { "& ul": { display: "flex", flexFlow: "column nowrap" } },
  menuHeader: {
    borderBottom: "solid 1px #e5e2e5",
    // backgroundColor: "#f6f6f7",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    height: 83,
    alignItems: "flex-end",
    paddingLeft: 20,
    paddingRight: 10,
    paddingBottom: 12
  },
  menuItemStyle: { padding: 0, width: "100%" },
  applyBtn: { margin: "15px 0", width: "90%", alignSelf: "center", height: "50px !important" },
  resetBtn: { width: "90%", alignSelf: "center" },
  optionsContainer: { width: "100%", height: "calc(100% - 245px)", overflow: "auto", padding: "0 20px" },
  optionTextStyle: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    // line-height: 3.4,
    letterSpacing: "normal",
    color: "#545964",
    maxWidth: "calc(100vw - 88px)",
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "calc(100% - 70px)"
    }
  },
  filtersPopup: {
    minWidth: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    top: 0,
    margin: 0,
    borderRadius: 0,
    justifyContent: "space-between",
    background: "white",
    alignItems: "center"
  },
  mainTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#29132e"
  },
  tabTitle: {
    fontSize: 16,
    fontWeight: "normal",
    letterSpacing: "normal",
    color: alpha("#15111f", 0.6),
    flex: 1,
    lineHeight: "52px",
    textAlign: "center",
    cursor: "pointer",
    textTransform: "capitalize"
  },
  selectedTab: {
    borderBottom: "solid 1px #4b1c46",
    fontFamily: "RobotoMedium",
    fontWeight: 500,
    color: "#15111f"
  },
  iconBtn: {
    borderRadius: "6px", height: 30, width: 30, padding: 0, marginBottom: -4
  },
  tabContainer: {
    display: "flex", padding: "0 20px", width: "100%", height: 52
  },
  inputRoot: {
    width: "100%",
    paddingLeft: 9,
    paddingRight: 4,
    height: "100%",
    borderRadius: "4px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b4850" //charcoal-grey
  },
  searchBox: {
    height: 52,
    width: "100%",
    margin: "20px 0",
    padding: "0 20px"
  },
  searchIcon: {
    transform: "scale(0.8695)"
  },
  closeIconStyle: {
    width: 25,
    height: 25,
    borderRadius: 6,
    padding: 0,
    "& svg": {
      transform: "scale(0.9)"
    }
  },
  listItemStyle: {
    height: 50,
    cursor: "pointer"
  },
  noValuesText: {
    textAlign: "center"
  },
  filtersContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    height: "100%",
    maxHeight: "calc(100% - 80px)"
  }
});

export const paperStyle = {
  marginTop: "43px",
  borderRadius: "6px",
  boxShadow: "rgba(0, 0, 0, 0.46) 0px 6px 17px 3px",
  backgroundColor: "#FFF",
  padding: "5px",
  minWidth: "200px",
  maxWidth: "200px",
  minHeight: "150px",
  maxHeight: "320px",
  overflow: "hidden"
};
