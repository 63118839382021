import { makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  tableContainer: {
    height: "100%"
  },
  searchField: {
    marginRight: "14px",
    marginLeft: "5px",
    "& div": { height: "40px", marginBottom: "5px" }
  },
  filterStyle: { marginLeft: "10px" },
  blueFilter: { fill: "#1976d2" },
  ImportExportContainer: {
    padding: "15px",
    marginRight: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  paperTableContainer: {
    flex: "1 1 auto",
    padding: 20,
    minHeight: 250
  },
  dialogPaper: {
    width: "fit-content",
    maxWidth: "95% !important",
    minWidth: "850px",
    height: "fit-content",
    maxHeight: "calc(100vh - 140px)",
    borderRadius: "8px",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    justifyContent: "space-between",
    overflow: "hidden"
  },
  dialogHeader: {
    width: "1005",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    height: "60px",
    alignItems: "center",
    ...theme.dialogs.header
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  selectedTrap: {
    backgroundColor: "#efeff2"
  },
  actionsContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  uploadContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  },
  table: {
    background: theme.palette.background.paper
  },
  overWritePadding: {
    padding: "0 15px",
    height: "35px",
    cursor: "pointer",
    ...theme.tables.tableCell
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  editIcon: {
    marginLeft: "-5px",
    width: 19,
    height: 20
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial"
  },

  anomaliesContainer: {
    height: "82%",
    display: "flex",
    paddingBottom: "1rem"
  },

  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  barRIghtSide: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center"
  },
  title: {
    height: "18px",
    width: "72px",
    color: "#312e34",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px"
  },
  "[class^=\"NavigationBar\"]": {
    padding: "0"
  },
  smallWidth: { width: "20%", wordBreak: "break-word" },
  brandCell: { width: "10%", wordBreak: "break-word" },
  nameCell: { width: "25%", wordBreak: "break-word", cursor: "pointer" },
  mediumWidth: { width: "60%", wordBreak: "break-word" },
  fixedWidth: { width: "100px" },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  filterIconContainer: {
    display: "flex",
    alignItems: "center"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  exportIcon: {
    paddingLeft: 5
  },
  importIcon: {
    paddingTop: 5,
    marginLeft: 10
  },
  descriptionCell: {
    padding: "8px 15px"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  }
  ,
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
    marginRight: "40px"
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px"
  },
  searchIcon: {
    transform: "scale(0.7)"
  },
  duplicateButton: {
    color: theme.palette.colors.darkBlue,
    textDecoration: "underline",
    background: "transparent",
    textTransform: "unset",
    "&:hover,&:focus": {
      textDecoration: "underline",
      background: "transparent"
    },
    "&:disabled": {
      color: alpha(theme.palette.colors.black, 0.26),
      textDecoration: "underline",
      background: "transparent"
    }
  },
  disabledStyle: {
    cursor: "not-allowed",
    opacity: 0.4
  }
}));
export default useStyles;
