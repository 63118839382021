import React from 'react'

const DisconnectedResources: React.FC<{ type?: number | null, resources: any[] | null }> = ({
	resources,
	type,
}) => {
	if (type !== 7 || !resources || resources.length === 1 || resources.length === 0) {
		return null;
	}

	const disconnectedCount = resources.filter(
		(entry: any | null) => entry && !entry.isConnected
	).length;

	return (
		<span>
			({disconnectedCount}/{resources.length})
		</span>
	);
};

export default DisconnectedResources;