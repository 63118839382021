/**
 * @typedef {Object} StringFormats
 * @property {string} kebab - The string converted to **kebab-case**.
 *   - **Format:** All words are in lowercase and separated by hyphens (`-`).
 *   - **Example:** `'example-string'` from `'Example String'`.
 * @property {string} camelCase - The string converted to **camelCase**.
 *   - **Format:** First word is lowercase; subsequent words start with uppercase letters. No spaces or separators.
 *   - **Example:** `'exampleString'` from `'Example String'`.
 * @property {string} pascalCase - The string converted to **PascalCase**.
 *   - **Format:** Every word starts with an uppercase letter. No spaces or separators.
 *   - **Example:** `'ExampleString'` from `'Example String'`.
 * @property {string} snake_case - The string converted to **snake_case**.
 *   - **Format:** All words are in lowercase and separated by underscores (`_`).
 *   - **Example:** `'example_string'` from `'Example String'`.
 * @property {string} capitalized - The string with each word **capitalized**.
 *   - **Format:** Each word starts with an uppercase letter; words are separated by spaces.
 *   - **Example:** `'Example String'` from `'example string'`.
 * @property {string} normal - The string in lowercase words separated by spaces (**normal case**).
 *   - **Format:** All words are in lowercase; words are separated by spaces.
 *   - **Example:** `'example string'` from `'Example String'`.
 * @property {string} original - The **original input** string without any modifications.
 *   - **Example:** Whatever the input was, e.g., `'ExampleString'`.
 */

/**
 * Splits the input string into words based on common delimiters and capitalization.
 *
 * @param {string} str - The string to split.
 * @returns {string[]} - An array of words.
 */
const splitIntoWords = (str) => {
  return (
    str
      // Replace underscores and hyphens with spaces
      .replace(/[_-]+/g, ' ')
      // Insert spaces before uppercase letters that follow lowercase letters or numbers
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      // Insert spaces between sequences of uppercase letters and lowercase letters
      .replace(/([A-Z]+)([A-Z][a-z0-9]+)/g, '$1 $2')
      // Convert multiple spaces into a single space
      .replace(/\s+/g, ' ')
      .trim()
      // Split by spaces
      .split(' ')
  );
};

/**
 * Converts a given string into various formats.
 * The input string can be in any format (kebab-case, camelCase, PascalCase, snake_case, normal text, etc.).
 *
 * @param {string} input - The string to convert.
 * @returns {StringFormats} - An object containing the string in different formats.
 */
export const smartConvertToStringFormats = (input) => {
  const words = splitIntoWords(input);

  // Lowercase all words for consistent processing
  const lowerWords = words.map((word) => word.toLowerCase());

  // Kebab-case: lowercase words joined by hyphens
  const kebab = lowerWords.join('-');

  // Snake_case: lowercase words joined by underscores
  const snake_case = lowerWords.join('_');

  // CamelCase: first word lowercase, subsequent words capitalized
  const camelCase = [
    lowerWords[0],
    ...lowerWords
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)),
  ].join('');

  // PascalCase: all words capitalized and joined
  const pascalCase = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');

  // Capitalized: first letter of each word capitalized, words separated by spaces
  const capitalized = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  // Normal: words separated by spaces, all lowercase
  const normal = lowerWords.join(' ');

  return {
    kebab,
    camelCase,
    pascalCase,
    snake_case,
    capitalized,
    normal,
    original: input,
  };
};
