import { alpha, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  groupStyle: {
    width: "100%",
    height: "50px",
    marginBottom: "6px",
    borderRadius: "6px",
    padding: "3px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    background: alpha("#fff", 0.2)
  },
  selected: { border: "solid 2px rgba(255, 255, 255, 0.6)" },
  locSec: {
    // minWidth: "40ch",
    display: "flex",
    paddingLeft: 0,
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%",
    overflow: "hidden"
  },
  iconStyle: {
    fontSize: "17.5px"
  },
  modeIcon: {
    margin: "0 5px"
  },
  iconButton: {
    backgroundColor: "gray",
    minWidth: "40px",
    height: "40px",
    margin: "0 5px",
    "& *": {
      color: "purple"
    }
  },
  smallIcons: {
    transform: "scale(0.5)",
    margin: "-10px",
    marginRight: "-4px"
  },
  hideElement: {
    visibility: "hidden"
  },
  nameStyle: {
    fontSize: "20px",
    fontWeight: 300,
    fontFamily: "RobotoLight"
  },
  boldText: {
    fontWeight: 600
  },
  noteIcon: {
    fontSize: "20px"
  },
  notesSec: {
    display: "flex",
    height: "100%",
    width: "42%",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "4px"
  },
  powerAlertsSchedulesContainer: {
    flexFlow: "row nowrap",
    alignItems: "center",
    minWidth: "48%",
    justifyContent: "space-between",
    height: "100%",
    width: "max-content"
  },
  loader: {
    minWidth: "33px"
  },
  unitsCount: {
    opacity: 0.7,
    fontSize: 16,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    marginRight: 5
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    maxWidth: "calc(100% - 30px)"
  },
  iconHolder: {width: "30px", height: "100%", padding: 0, borderRadius: 0 },
  hidden: {
    display: "none"
  },
  sensorGroupInfoSection: {
    display: "flex",
    flexDirection: "column",
    width: "100px",
  },
  sensorGroupInfoLine: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: "14px"
    }
  }
}));

export default useStyles;
