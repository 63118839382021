import {  useRef, useState } from 'react';
import {getValueFromNativeElement,getParsedValidation} from './utils';

/**
 * Custom hook for managing form state, validation, and error handling.
 *
 * @param {object} [config] Optional configuration object.
 * @param {boolean} [config.validateOnChange=false]
 *   Whether to automatically validate on each value change.
 *
 * @returns {object} Object containing form manipulation functions and state:
 * {connect, change, init, validateForm, values, errors}
 * 
 * connect - a function that connects the element to the form. it returns the props that the element needs.
 * 
 * init - a function to set the state of the entire form manually.
 * 
 * change - a function to set the state of the a specific form element manually.
 * 
 * validateForm - a function to validate the entire form.
 * 
 * values - the object representing the VALUES of the connected fields (by key).
 * 
 * errors - the object representing the ERRORS of the connected fields (by key).
 *
 */
const useMiniForm = (config = {}) => {

	//get the validateOnChange flag from the config
	const {validateOnChange} = config;

	//initialize the state for the form.
	const [values,setValues] = useState({});
	const [errors, setErrors] = useState({});

	//initialize the container for the validations object.
	//It basically holds an array of validation objects for each connected element (if validations was provided to the connect function)
	const elementsValidations = useRef({});

	/**
	 * Sets validation rules for a specific form element.
	 *
	 * @param {string} key Unique identifier of the element.
	 * @param {Array<function>} validations Array of validation functions to apply.
	 *
	 */
	const setValidationsForElement = (key,validations=[]) => {
		if(!Array.isArray(validations)){
			console.error(`useMiniForm: please pass an array of validations/customValidations to ${key} connect function`);
			return false;
		}

		if(!validations.length){
			return false;
		}

		const parsedValidation = getParsedValidation(validations, key);
		if(parsedValidation.length){
			elementsValidations.current[key] = parsedValidation;
		}
	}; 

	/**
 	* Updates the error message for a specific form element.
 	*
 	* @param {string} key Unique identifier of the element.
 	* @param {string} [message=''] Error message to display. Defaults to an empty string.
 	*/
	const updateErrorByKey = (key, message = '') => {
		setErrors(oldErrors => {
			return {...oldErrors, [key]:message};
		});
	};

	/**
	* Validates a specific form field against its assigned validation rules.
	*
	* @param {string} key Unique identifier of the field.
	* @param {any} value Value to validate.
	*
	* @returns {boolean} True if the field is valid, false otherwise.
	*/
	const validateField = (key,value) => {
		const fieldValidators = elementsValidations.current[key] ?? [];
        
		const firstFailedValidator = fieldValidators.find(({validator}) => validator(value,values));
		if(firstFailedValidator){
			updateErrorByKey(key,firstFailedValidator.message);
			return false;
		}
		updateErrorByKey(key);
		return true;
	};

	/**
	 * Initializes the form values with the provided object.
	 *
	 * @param {Object} [newValues={}] Object containing initial values for form fields.
	 */
	const init = (newValues = {}) => {
		setValues(newValues);
	};
    
	/**
 	* Updates the value of a specific form field and validates it if validateOnChange flag is true.
 	*
 	* @param {string} key Unique identifier of the field.
 	* @param {any} newValue New value to set for the field.
 	*/
	const change = (key, newValue) => {
		if(validateOnChange){
			validateField(key,newValue);
		}
		setValues(oldValues => {
			return {...oldValues,[key]:newValue};
		});
	};

	/**
	 * Connects a form element to the hook and returns its props.
	 *
	 * @param {string} key Unique identifier of the element.
	 * @param {Object} [config={}] Configuration options for the element.
	 * @param {string} [config.customValuePropName] Name of the prop for the element's value. Defaults to "value".
	 * @param {string} [config.customOnChangePropName] Name of the prop for the element's onChange handler. Defaults to "onChange".
	 * @param {string} [config.customErrorPropName] Name of the prop for the element's error message. Defaults to "error".
	 * @param {function} [config.customOnChange] Custom onChange handler for the element.
	 * @param {function} [config.customValueModifier] Function to modify the value before saving it to state.
	 * @param {function} [config.customValueGetter] Function to modify the value before providing it to the element.
	 * @param {Array<function>} [config.validations] Array of validation objects for the element.
	 * @param {any} [config.initialValue] Initial value for the element.
	 * @param {boolean} [config.allowUndefinedValue=true] Whether to allow undefined values. Defaults to true. set to false if working with native DOM elements or elements that has no prop type validations.
	 *
	 * @returns {Object} Props to be spread onto the connected form element.
	 * 
	 * @example
	 * //simple connection:
	 * <Element {...connect('name')} />
	 * 
	 * //simple connection DOM:
	 * <input {...connect('name',{allowUndefinedValue:false})}  />
	 * 
	 * //connection with required and email validation:
	 * <Element {...connect('name',{validations:['required','email']})} />
	 * 
	 * //connection with required and custom message:
	 * <Element {...connect('name',{validations:[{required:'a name for your cat is required! meow!'}]})} />
	 * 
	 * //connection with custom a validation:
	 * const myAwesomeValidationFunction = (newValue, values) => {
	 * 	 //some logic that returns true or false
	 * }
	 * <Element {...connect('name',{validations:[{validator:myAwesomeValidationFunction,message:'the element did not pass my awesome validation!'}]})} />
	 */
	const connect = (key, config = {}) => {
		// Extract all options from config.
		const {customValuePropName, customOnChangePropName,customErrorPropName} = config;
		const {customOnChange, customValueModifier,customValueGetter} = config;
		const {validations = [], initialValue, allowUndefinedValue = true} = config;

		// Register elements validations
		setValidationsForElement(key,validations);

		// Set the initial value of the element.
		if(initialValue !== undefined && values[key] === undefined){
			change(key,initialValue);
		}

		//Verify that customOnChange is a function.
		if(customOnChange && typeof customOnChange !== 'function'){
			console.error(`useMiniForm: customOnChange on ${key} has to be a function (gets {newValue,values,change,updateErrorByKey})`);
		}

		//Verify that customValueModifier is a function.
		if(customValueModifier && typeof customValueModifier !== 'function'){
			console.error(`useMiniForm: customValueModifier on ${key} has to be a function (gets the value from state expected to return a modified value to save)`);
		}

		//Verify that customValueGetter is a function.
		if(customValueGetter && typeof customValueGetter !== 'function'){
			console.error(`useMiniForm: customValueGetter on ${key} has to be a function (gets the value from state expect to return a modified value)`);
		}

		//Get the value after a 'CustomValueGetter' is used (if provided).
		const modifiedValue = customValueGetter ? customValueGetter(values[key]) : values[key];

		//if allowUndefinedValue flag is false and the value is undefined - return empty string instead.
		const computedValue =(!allowUndefinedValue && modifiedValue === undefined) ? '' : modifiedValue;

		// Initialize the elements dynamic props
		const props = {
			[customValuePropName ?? 'value']:computedValue,
			[customErrorPropName ?? 'error']:errors[key],
			[customOnChangePropName ?? 'onChange']:(newValue) => {
				if(customOnChange){
					customOnChange({newValue,values,change,updateErrorByKey});
				}
				const value = newValue?.target ? getValueFromNativeElement(newValue) : newValue;
				const modifiedValue = customValueModifier ? customValueModifier(value) : value;
				change(key,modifiedValue);
			},
		};

		// If we use customErrorPropName - return the error prop as bool.
		if(customErrorPropName){
			props.error = !!errors[key];
		}

		// Return the props.
		return props; 
	};

	const validateForm = () => {
		setErrors({});
		const results = [];
		Object.entries(elementsValidations.current).forEach(([key])=>{
			const value = values[key] ?? '';
			results.push(validateField(key,value));
		});
		return !results.includes(false);
	};
    
	return {
		connect,
		change,
		init,
		validateForm,
		values,
		errors,
	}; 
};

export default useMiniForm;