
export const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTrimmedText = (text: any) => {
	if (!text || typeof text !== 'string') {
		return text;
	}
	return text.trim();
};
