import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  infoDialogStyle: {
    padding: 0,
    minWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    bottom: 0,
    margin: 0,
    borderRadius: 0,
    paddingBottom: 20,
    background: "white"
  },
  loaderHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
  tableContainer: {
    display: "flex"
  },
  table: {
    background: theme.palette.background.paper
  },
  tableHeadCell: {
    padding: "0 25px",
    height: 45,
    ...theme.tables.headerCell
  },
  overWritePadding: {
    ...theme.tables.tableCell,
    padding: "10px 25px"
  },
  overWritePaddingRow: {
    fontSize: "0.8rem",
    padding: "0 15px",
    cursor: "pointer"
  },
  dataContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "60px",
    borderBottom: "solid 1px #e5e2e5",
    padding: "0 20px",
    justifyContent: "space-between"
  },
  iosTopHeight: {
    paddingTop: "40px",
    minHeight: "100px"
  },
  closeIcon: {
    "& mask": {
      fill: "#7f7692"
    }
  },
  workingUnitsTitle: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 600,
    color: "#4b1c46",
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "capitalize"
    }
  },
  reportsHeader: {
    width: "100%",
    height: "70px",
    paddingTop: "30px",
    paddingLeft: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "flex-start"
  },
  siteName: {
    color: "#79797d",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px"
  },
  headerInfoContainer: {
    display: "flex",
    width: "90%",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  headerInfoSection: {
    display: "flex"
  },
  headerInfoVal: {
    fontSize: "13px",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    marginRight: "6px"
  },
  headerInfoTitle: {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#79797d"
  },
  unitsContainer: {
    height: "calc(100% - 80px)",
    overflowY: "auto"
  },
  switchDisabled: {
    opacity: 0.7,
    "& span": {
      cursor: "default"
    }
  }
}));

export default useStyles;
