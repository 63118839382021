import { makeStyles, Theme } from "@material-ui/core";
import { BorderBottom } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    width: "100vw",
    background: "#f2f2f7",
    height: "calc(100% - 83px)"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto",
    position: "relative"
  },
  nameContainer: {
    width: "100%",
    height: "120px",
    backgroundColor: "#fff",
    padding: "30px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)"

  },
  nameField: {
    width: "100%",
    [`& fieldset`]: {
      borderRadius: "10px"
    }
  },
  searchField: {
    width: "100%",
    [`& fieldset`]: {
      borderRadius: "6px"
    }
  },
  contentContainer: {
    width: "100%",
    backgroundColor: "#fff",
    padding: "30px 20px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
    marginTop: "15px",
    flexDirection: "column",
    flex: 1,
    marginBottom: "20px"
  },
  siteCont: {
    width: "100%",
    border: `1px solid #d5d2d5`,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "8px 0",
    maxHeight: "250px"
  },
  siteHeader: {
    width: "100%",
    display: "flex",
    height: "50px",
    minHeight: "50px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  expandedHeader: {
    borderBottom: `2px solid #d5d2d5`
  },
  expandIconNormal: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })

  },
  expandedIcon: {
    transform: "rotate(-180deg)"
  },
  siteName: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontWeight: "bold",
    padding: "0 16px"
  },
  unitsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: 0
  },
  unitRow: {
    display: "flex",
    padding: "17px",
    borderBottom: "1.5px solid #d5d2d5",
    width: "100%",
    alignItems: "center"
  },
  unitName: {
    color: theme.palette.colors.gray,
    fontSize: "16px"
  },
  checkbox: {
    marginRight: "10px"
  },
  saveBtn: {
    position: "sticky",
    bottom: "20px",
    width: "100%",
    height: "50px",
    borderRadius: "6px"
  },
  containerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    width: "100%"
  },
  containerTitle: {
    color: theme.palette.primary.dark,
    fontSize: "20px",
    fontWeight: 500
  },
  toggleButtons: {
    height: "44px",
    "& button": {
      "&:first-child": {
        borderRadius: "6px 0 0 6px"
      },
      "&:last-child": {
        borderRadius: "0 6px 6px 0"
      }
    }
  },
  sitesCont: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  tabText: {
    fontSize: "15px",
    textTransform: "none"
  },
  tabContainer: {
    width: "80px"
  },
  siteNameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center"
  }
}));

export default useStyles;
