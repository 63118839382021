import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {  Search } from '@icons/index';
import clsx from 'clsx';

import {t} from 'ttag';

import useStyles from '../../AddEditDialog.style';
import RecipientsList from './components/RecipientsList';
import AddUserToReportDialog from './components/AddUserToReportDialog';

const UsersSelector = ({disabled,error,onChange,value}) => {

	const classes = useStyles();

	const [searchVal,setSearchVal] = useState('');
	const [showAddUserDialog, setShowAddUserDialog] = useState(false);

	const searchFieldProps = {
		endAdornment: (
			<InputAdornment position="end">
				<Search />
			</InputAdornment>
		)

	};

	const handleSearchFieldChange = (event) => {
       
		setSearchVal(event.target.value);
        
	};

	const handleAddButtonClick = () => {
		setShowAddUserDialog(true);
	};

	const deleteUser = (user)=>{ 
		const {id} = user;
		const newValue = value.filter(({userEmail}) => userEmail !== id);
		onChange(newValue); 
	};

	const addUser = (newUser) => {
		const newValue = [...value,newUser];
		onChange(newValue);
		hideAddUserToReportDialog();
	};

	const hideAddUserToReportDialog = () =>{
		setShowAddUserDialog(false);
	};

	return (
		<div className={'users-selector'}>	
			<div className={classes.sectionTitleContainerOnly}>
				<Typography className={classes.sectionTitleTextOnly}>{t`Add Recipients`}</Typography>
				<IconButton disabled={disabled} disableRipple className={clsx(classes.iconBtnStyle, classes.addIcon)} onClick={handleAddButtonClick}>
					<AddIcon />
				</IconButton>
			</div>

			<Grid container={true} className={classes.reportsFormatWrtapper}>
				<TextField id="outlined-basic"
					placeholder={t`Search User`}
					variant="outlined"
					className={classes.searchUserScheduler}
					value={searchVal}
					onChange={handleSearchFieldChange}
					autoComplete="off"
					InputProps={searchFieldProps}
				/>

				<RecipientsList
					selectedReportUsers={value}
					onUserDelete={deleteUser}
					classes={classes}
					searchVal={searchVal}
				/>		

				<Typography className={classes.error}>{error}</Typography>		
							
			</Grid>

			<AddUserToReportDialog
				show={showAddUserDialog}
				onSave={addUser}
				onCancelClick={hideAddUserToReportDialog}
				classes={classes}
			/>	
		</div>
	);
};

UsersSelector.propTypes = {
	onChange:PropTypes.func.isRequired,
	value:PropTypes.array,
	error:PropTypes.string,
	disabled:PropTypes.bool
};

UsersSelector.defaultProps = {
	value:[],
	error:'',
	disabled:false
};

export default UsersSelector;