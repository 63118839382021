import {
  Button as MUiButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Typography,
  Tooltip
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import useStyles, { paperStyle } from "./lookup.style";

export default function ErrorBox(props: any) {
  const classes = useStyles(),
    { onApply, lookupAnchor, filtersList = [], onClose, appliedFilters = [], tableHasFilters = false, clearAllFilters, extraWidth } = props,
    [selectedFilters, setSelectedFilters] = React.useState<string[]>(appliedFilters),
    [selectAll, setSelectAll] = React.useState<boolean>(selectedFilters.length === 0 || appliedFilters.length === filtersList.length),
    handleFilters = (value: string) => {
      if (selectedFilters.length === 0 && selectAll) {
        const list = [...filtersList];
        const index = list.indexOf(value);
        list.splice(index, 1);
        setSelectedFilters([...list]);
        setSelectAll(false);
        return;
      }

      if (selectedFilters.includes(value)) {
        const index = selectedFilters.indexOf(value);
        selectedFilters.splice(index, 1);
        setSelectedFilters([...selectedFilters]);
      } else {
        setSelectedFilters([...selectedFilters, value]);
      }
      if (filtersList.length === selectedFilters.length) {
        setSelectAll(true);
        setSelectedFilters([]);
      } else {
        setSelectAll(false);
      }
    },
    handleAllFilters = (checked: boolean) => {
      setSelectAll(checked);
      setSelectedFilters([]);
    },
    apply = () => {
      onApply &&
        onApply(selectAll ? [] : selectedFilters);
    },
    clearFilters = () => {
      clearAllFilters();
      onClose();
    };

  return (
    <Menu
      id="filters--menu"
      anchorEl={lookupAnchor}
      keepMounted
      open={true}
      onClose={onClose}
      className={classes.menuStyle}
      classes={{ paper: extraWidth ? classes.menuExtraWidth : "" }}
      PaperProps={{
        style: paperStyle
      }}
    >
      <div className={classes.menuHeader}>
        <Typography>{t`Filters`}</Typography>
        <Clear onClick={onClose} />
      </div>
      <List disablePadding={true} className={classes.optionsContainer}>
        {(!filtersList || !filtersList.length) && (<Typography>{t`No Values to select`}</Typography>)}
        {filtersList && filtersList.length > 1 && (<ListItem dense autoFocus divider key={"all"}>
          <ListItemText id={"all"} primary={` All`} />
          <ListItemSecondaryAction>
            <Checkbox
              color="default"
              edge="end"
              onChange={(event: any) => handleAllFilters(event.target.checked)}
              checked={selectAll}
              indeterminate={!selectAll && selectedFilters.length !== 0}
              inputProps={{ "aria-labelledby": `toggle all` }}
            />
          </ListItemSecondaryAction>
        </ListItem>)}
        {filtersList.map((option: string, index: number) => (
          <ListItem dense autoFocus divider key={`lookup-${index}`}>
            <Tooltip title={option} placement="bottom" arrow>
              <ListItemText id={`${index}`} primary={option || t`BLANK`} className={classes.optionTextStyle} />
            </Tooltip>
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onClick={() => handleFilters(option)}
                checked={selectedFilters.includes(option) || selectAll}
                inputProps={{ "aria-labelledby": option }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Button
        className={classes.applyBtn}
        onClick={apply}
        disabled={(!filtersList || filtersList.length <= 1)}
      >{t`Apply`}</Button>
      <MUiButton
        variant="text"
        onClick={clearFilters}
        disabled={!tableHasFilters}
        className={classes.resetBtn}
      >{t`Reset All Filters`}</MUiButton>
    </Menu>
  );
}
