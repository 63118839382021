import { alpha, makeStyles } from "@material-ui/core";

const drawerWidth = 377;

export default makeStyles((theme: any) => ({
  dialogPaper: {
    width: "fit-content",
    maxWidth: "95% !important",
    minWidth: "850px",
    height: "fit-content",
    maxHeight: "calc(100vh - 100px)",
    borderRadius: "8px",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    justifyContent: "space-between",
    overflow: "hidden",
    position: "absolute",
    top: "70px",
  },
  textField: {
    width: "100%",
    backgroundColor: "#fff",
    "&>div": {
      color: "#545964",
      height: "36px",
      borderRadius: "3px",
      "&>input": {
        padding: "0 12px",
        height: "1.13rem",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:focus-within": {
      backgroundColor: "rgb(255, 255, 255);",
    },
  },
  descriptionContainer: {
    width: "45%",
    position: "relative",
  },
  descriptionLabel:{
    position: 'absolute',
    top: -5,
    left: 15,
    background: '#fff',
    fontSize: 13,
    padding: '0 5px'
  },

  attachmentFloatingBtn: {
    position: "absolute",
    bottom: 10,
    right: 10,
    zIndex: 999,
  },
  textArea: {
    maxWidth: "770px !important",
    width: "100%",
    borderRadius: "3px",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    padding: "13px 24px",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    fontFamily: "Roboto",
    outline:'none',
    "&::placeholder": {
      color: alpha("#545964", 0.6),
    },
  },

  readOnlyTextArea: {
    maxWidth: "440px !important",
    width: "100%",
    borderRadius: "3px",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    padding: "13px 24px",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    fontFamily: "Roboto",
    "&::placeholder": {
      color: alpha("#545964", 0.6),
    },
  },

  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  dialogContent: {
    overflow: "hidden",
    display: "flex",
    flexFlow: "row nowrap",
    flex: 1,
  },
  basicInfoContainer: { display: "flex", paddingBottom: 10 },
  addMargin: { marginBottom: "10px", marginTop: "10px" },
  select: {
    lineHeight: "36px",
    width: "100%",
    height: "36px",
    // marginRight: "15px",
    borderRadius: "3px",
    // border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    "&>div": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: "30px",
      paddingLeft: "12px",
    },
  },
  disabledSelect: { background: "#d5d2d57d" },
  btn: { height: "36px !important", fontSize: "14px !important" },
  addMargin13: { marginRight: "13px" },
  boxTitle: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
  },
  boxContent: { overflow: "auto", padding: "13px 10px", maxHeight: "368px" },
  plusIcon: { fontSize: "13px !important" },
  boxHeader: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    height: "50px",
    backgroundColor: "#f6f5f6",
    borderBottom: "solid 1px #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px 0px 10px",
  },
  boxContainer: {
    width: "1312px",
    marginBottom: "20px",
    height: "422px",
    borderRadius: "4px",
    border: "solid 1px #d5d2d5",
  },
  buttonsContainer:{
    display: "flex"
  },
  small: { height: "183px", marginBottom: "8px" },
  AddRightMargin15: { marginRight: "15px" },
  selectStyle: { height: "36px" },
  arrowDownIcon: {
    top: 14.5,
    right: 9,
  },
  checkboxStyle: {
    marginRight: "6px",
    "& span": {
      width: "24px",
      height: "24px",
      borderRadius: "3px",
      backgroundColor: "#fff",
    },
  },
  whiteMoreVert: { height: 24, width: 3, "& path": { fill: "#fff" } },
  andOrButton: {
    width: "60px",
    height: 36,
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#fff",
    textTransform: "capitalize",
    border: "solid 1px #d5d2d5",
    color: "#545964",
  },
  selectedAndOrButton: {
    backgroundColor: "#aaa2aa",
    color: "#fff",
    border: 0,
    "&:hover": {
      backgroundColor: "#aaa2aa",
    },
  },
  disconnectIcon: {
    width: "28px",
    height: "24px",
    "& path": { stroke: "#fff" },
  },
  view: {
    display: "flex",
    height: "100%",
  },
  contentArea: {
    width: "100%",
  },
  title: {
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "25px",
    color: "#4b1c46",
    backgroundColor: "white",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  contentAreaWrapper: {
    height: "calc(100% - 70px)",
  },
  formikFeild: {
    fontFamily: "Roboto",
    fontSize: 16,
    minWidth: "180px",
    width: "180px",
    height: 36,
    backgroundColor: "#fff",
    color: "#545964 !important",
    border: "solid 1px #d5d2d5",
    borderRadius: "4px",
    marginRight: "15px",
    padding: "5px",
    "&>input": {
      padding: "0 12px",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:focus-within": {
      backgroundColor: "rgb(255, 255, 255);",
    },
  },
  treeContainer: {
    minWidth: "400px",
    padding: "20px",
  },
  boxMargin: { marginBottom: "90px !important" },
  unitsSystemsContainer: { display: "flex", flexFlow: "row wrap" },
  actionsContainer: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "10px 20px",
    width: "100%",
  },
  topMargin: { marginRight: "15px" },
  mainDataContainer: { minWidth: "375px" },
  opacityStyle: {
    opacity: 0.2,
    backgroundColor: "#000",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
  },
  bottomWhite: {
    background: "#fff",
    position: "absolute",
    width: "calc(100% - 385px)",
    height: "52px",
    bottom: "8px",
    zIndex: 2,
  },
  wrapperContainer: {
    width: "219px",
    height: "36px",
    display: "flex",
    justifyContent: "space-between",
    marginRight: "10px",
    marginBottom: "10px",
  },
  namesContainer: {
    width: "184px",
    fontSize: "15px",
    fontWeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    border: "solid 1px #d5d2d5",
    flex: 1,
    color: "#545964",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
    lineHeight: "36px",
    textTransform: "capitalize",
    padding: "0 15px",
    display: "flex",
  },
  name: {
    width: "59.5px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  middle: { width: "13px", padding: "0 3px" },
  removeButton: {
    width: "36px",
    height: "36px",
    backgroundColor: "#aaa2aa",
    borderRadius: 0,
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    "&:disabled": {
      backgroundColor: "#aaa2aa",
    },
    "&:hover": {
      backgroundColor: "#aaa2aa",
    },
  },
  moreVertContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: 16,
    backgroundColor: "#aaa2aa",
    marginRight: 20,
    marginLeft: -1,
  },
  purbleMoreEvert: { height: 24, width: 3, marginRight: 3, marginLeft: 13 },
  width120: { width: "120px !important" },
  width100: { width: "100px !important" },
  width135: { width: "135px !important" },
  width210: { width: "210px !important" },
  conditionContainer: {
    width: "1288px",
    height: "60px",
    border: "solid 1px #d5d2d5",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    display: "flex",
    alignItems: "center",
  },
  conditionInGroupContainer: {
    backgroundColor: "transparent",
    border: 0,
    width: "1222px",
  },
  groupContainer: {
    minWidth: "100%",
    width: "fit-content",
    border: "solid 1px #d5d2d5",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    display: "flex",
    alignItems: "center",
  },
  groupContent: { display: "flex", width: "100%", height: "100%" },
  groupLeftPart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 16,
    backgroundColor: "#aaa2aa",
    marginRight: 1,
    marginLeft: -1,
  },
  ingroupConditionsContainer: { padding: "7px 0" },
  dissconnnectRect: {
    width: "50px",
    backgroundColor: "#aaa2aa",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  switchButton: {
    marginLeft: "auto",
  },
  switchSvg: { color: "#fff" },
  smallCheckbox: {
    marginRight: "10px",
    padding: 0,
    "& span": {
      width: "18px",
      height: "18px",
      borderRadius: "3px",
      backgroundColor: "#fff",
    },
  },
  hideCheckbox: { visibility: "hidden" },
  grayCheckbox: {
    "&$checked": {
      color: "#736973",
    },
  },
  requiredError: {
    border: "1px solid #cf6679",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    width: "100%",
    paddingBottom: 0,
    display: "flex",
    flexFlow: "column nowrap",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    width: `calc(100% - ${drawerWidth})`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: "absolute",
    zIndex: 999999,
    "& div:first-child": {
      overflow: "hidden",
      maxHeight: "calc(100% - 100px)",
      height: "calc(100% - 100px)",
    },
  },
  closeIcon: {
    cursor: "pointer",
  },
  unitsNumsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "150px",
  },
  label: { "& label": { transform: "translate(14px, 11px) scale(1)" } },
  fieldContainer: { position: "relative" },
  hideBorder: {
    background: "#fff",
    height: "3px",
    position: "absolute",
    left: 5,
  },
  fieldLabel: {
    position: "absolute",
    fontWeight: "bold",
    fontSize: "12px",
    top: "-4px",
    left: 7,
    color: "rgba(0, 0, 0, 0.74)",
  },
  formControl: {
    width: 180,
    // marginTop: "10px",
    marginRight: "10px",
  },
  labelSelect: { transform: "translate(14px, 11px) scale(1)" },
  errorLabelSelect: { color: "#f44336" },
  mainSelect: { marginTop: "10px", width: "242.5px !important" },
  removeRightMargin: { marginRight: "0 !important" },
  errorMessage: {
    color: "#f44336",
    fontSize: "14px",
    marginRight: "30px",
  },
  width140: {
    width: "140px !important",
    marginRight: "10px",
  },
  disabledWhiteButton: {
    opacity: 0.5,
  },
  addButton: {
    borderRadius: 6,
    margin: "10px 0",
    marginLeft: 30,
    minWidth: "0 !important",
    width: "120px !important",
    background: "#aaa2aa !important",
    color: "#fff",
    border: 0,
    height: "36px !important",
    fontSize: "14 !important",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textTransform: "capitalize",
  },
  hideElement: {
    visibility: "hidden",
  },
  addBottomMargin: {
    marginBottom: "22px",
  },
  errorText: {
    color: "#f44336",
    position: "absolute",
    marginTop: "2px",
    minWidth: 270,
  },
  unitsAppliedBox: {
    maxHeight: "67%",
  },
  removeTopMargin: {
    marginTop: "0px !important",
  },
  boxesHolder: {
    overflow: "auto",
    padding: 20,
    paddingBottom: 0,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  thumb: {
    backgroundColor: theme.palette.primary.dark,
  },
  track: {
    backgroundColor: theme.palette.primary.light,
  },
  flexStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    opacity: 0.4,
  },
  width180: {
    width: "180px !important",
  },
  width180Text: {
    width: "180px !important",
    marginRight: "15px",
  },
  autoComplete: {
    marginRight: 10,
    height: 36,
    width: 180,
    "&:div": {
      height: 36,
    },
  },
  endAdornment: {
    top: 0,
    height: "36px",
    right: "1px !important",
    width: "25px",
  },
  popupIndicator: {
    marginRight: 0,
    padding: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "unset !important",
    },
  },
  inputRoot: {
    padding: "0 !important",
    // paddingRight: "25px !important",
    paddingLeft: "1px !important",
  },
  brandTypeContainer: {
    display: "flex",
    alignItems: "flex-end",
    height: "50%",
  },
  closedDrawer: {
    zIndex: -1,
  },
  fileName: {
    width: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineBreak: "anywhere",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    alignSelf: "center",
  },
  tooltip: {
    fontSize: "14px",
    wordBreak: "break-all",
  },
  bold: {
    fontWeight: "bold",
    fontSize: "16px",
  },

  parameterFiledMixedMode:{
    width: 240
  },
  autoCompleteLi: {
    width: "100%",
    position: "relative"
  },
  separator: {
    borderTop: "1px dashed #aeaaae",
    width: "100%",
    position: "absolute",
    top: "-8px",
    left: 0
  }
}));
