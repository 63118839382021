import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import CButton from "../../cool_widgets/Button";
import { useStoreActions } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import InputFieldPassword from "./InputFieldPassword";
import useStyles from "./Login.style";

interface IResetState {
  token: string;
  password: string;
  verifyPassword: string;
  error?: string;
}

const ResetPasswordSchema = Yup.object().shape({
  token: Yup.string().required("Required"),
  password: Yup.string()
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, "must be at least 1 capital letter and 1 digit")
    .required("Required"),
  verifyPassword: Yup.string()
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, "must be at least 1 capital letter and 1 digit")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required")
});
const ResetPasswordWithToken: React.FC<any> = (props) => {
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [isLoginBack, setIsLoginBack] = useState(false);
  const resetPassword = useStoreActions((action) => action.users.resetPassword);
  const [error, setError] = useState<any>(null);
  const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;
  const classes = useStyles();
  const match = useRouteMatch<{ token: string }>();
  let token: string = match.params.token;
  //console.log(token);
  //console.log(match);

  const initialValues: IResetState = {
    token,
    password: "",
    verifyPassword: ""
  };
  const handleBack = () => {
    setIsLoginBack(true);
  };

  // const handleEnter = (event: any) => {
  //     const code = event.keyCode || event.which;
  //     if (code === 13) {
  //         handleSetPassword();
  //     }
  // };
  const handleSetPassword = (values: IResetState, actions: any) => {
    resetPassword({ token: values.token, password: values.password })
      .then(() => setIsPasswordSet(true))
      .catch((err: any) => {
        setError(err.message);
      });
  };
  if (isLoginBack) {
    return <Redirect to={"/"} />;
  }
  if (isPasswordSet) {
    return <Redirect to={"/reset-success"} />;
  }

  return (
    <div className={classes.Login}>
      <div className={classes.main}>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("BlackLogo.png")}
            className={classes.logo}
          />
        </div>
        {
          <div className={classes.interactionSection}>
            <div className={classes.content}>
              <Typography className={classes.title}>{t`Reset Password`}</Typography>
              {errorBox}
              <Typography
                variant="body2"
                className={classes.text}
              >{t`Enter the new password you wish to use`}</Typography>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSetPassword}
                enableReinitialize={true}
                validationSchema={ResetPasswordSchema}
              >
                {({ handleSubmit, errors, touched, values, ...restFormikProps }) => (
                  <form style={{ width: "calc(100% - 50px)" }} onSubmit={handleSubmit}>
                    <Grid container className={classes.container}>
                      {/* Why form in the middle of nowhere? So that browsers won't autocomplete the token field with an email. */}
                      <form className={classes.newPasswordForm}>
                        <div className={classes.hidden}>
                          <Field
                            label={t`Token`}
                            value={values.token}
                            name="token"
                            component={InputFieldPassword}
                            error={errors.token && touched.token ? true : false}
                            helperText={errors.token && touched.token ? errors.token : ""}
                            type="token"
                          />
                        </div>

                        <Field
                          label={t`New password`}
                          value={values.password}
                          name="password"
                          component={InputFieldPassword}
                          error={errors.password && touched.password ? true : false}
                          helperText={errors.password && touched.password ? errors.password : ""}
                          type="password"
                        />
                        <Field
                          label={t`Verify new password`}
                          value={values.verifyPassword}
                          name="verifyPassword"
                          component={InputFieldPassword}
                          error={errors.verifyPassword && touched.verifyPassword ? true : false}
                          helperText={
                            errors.verifyPassword && touched.verifyPassword
                              ? errors.verifyPassword
                              : ""
                          }
                          type="password"
                        // onKeyPress={handleEnter}
                        />
                      </form>
                      <Grid item className={classes.resetButtonContainer}>
                        <CButton
                          disableTouchRipple
                          disableFocusRipple
                          title={t`Back to Login`}
                          className={classes.buttonReset1}
                          onClick={handleBack}
                        >
                          {t`Back to Login`}
                        </CButton>
                        <CButton
                          title={t`Set New Password`}
                          className={classes.buttonReset1}
                          type="submit"
                          onMouseUp={handleSubmit}
                          red
                        >
                          {t`Reset Password`}
                        </CButton>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default ResetPasswordWithToken;
