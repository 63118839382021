import React from 'react';
import PropTypes from 'prop-types';

const TempSensor = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M12.1091 17.1053V9.34931C12.1091 9.08723 11.8803 8.8584 11.6182 8.8584C11.3561 8.8584 11.1273 9.08723 11.1273 9.34931V17.1053C10.0151 17.3342 9.16364 18.316 9.16364 19.4947C9.16364 20.837 10.2758 21.9492 11.6182 21.9492C12.9605 21.9492 14.0727 20.837 14.0727 19.4947C14.0727 18.316 13.2213 17.3022 12.1091 17.1053Z" fill="white" />
		<path fillRule="evenodd" clipRule="evenodd" d="M14.0727 15.8296V4.11293C14.0727 2.77059 12.9605 1.65839 11.6182 1.65839C10.2759 1.65839 9.16365 2.77059 9.16365 4.11293V15.8296C7.953 16.6478 7.20001 18.0221 7.20001 19.4947C7.20001 21.916 9.1969 23.9129 11.6182 23.9129C14.0395 23.9129 16.0364 21.916 16.0364 19.4947C16.0364 18.022 15.2834 16.6477 14.0727 15.8296Z" stroke="white" />
	</svg>
);

TempSensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

TempSensor.defaultProps = {
	width: 24,
	height: 25
};

export default TempSensor;
