import { Customer as sdkCustomer, Script as sdkScript, Site as sdkSite } from "coolremote-sdk";
import { Thunk, thunk } from "easy-peasy";

interface IScriptPayload {
  id: any;
  data: object;
}

export interface IScriptModel {
  getSiteScripts: Thunk<IScriptModel, { siteId: string, type: number }>;
  getCustomerProceduresByCustomer: Thunk<IScriptModel, string>;
  deleteScript: Thunk<IScriptModel, string>;
  updateScript: Thunk<IScriptModel, IScriptPayload>;
  updateScriptState: Thunk<IScriptModel, IScriptPayload>;
  createScript: Thunk<IScriptModel, IScriptPayload>;
  getCommissioningReportsBySite: Thunk<IScriptModel, IScriptPayload>;
  getCommissioningReportsByCustomer: Thunk<IScriptModel, IScriptPayload>;
  deleteCommissioningReport: Thunk<IScriptModel, string>;
  getProceduresInstances: Thunk<IScriptModel, { siteId: string, type: number }>;
  getProceduresInstancesByCustomer: Thunk<IScriptModel, string>;
  runProcedure: Thunk<IScriptModel, IScriptPayload>;
  stopProcedureInstance: Thunk<IScriptModel, string>;
  getProcedureInstance: Thunk<IScriptModel, { id: string, type: number }>;
  reRunProcedure: Thunk<IScriptModel, string>;
  getProcedureInstanceByCustomer: Thunk<IScriptModel, string>;
  createMultiScripts: Thunk<IScriptModel, {site: string, procedures: any[]}>;

}

export const scriptModel: IScriptModel = {
  getSiteScripts: thunk((actions, payload) => {
    return sdkSite.getProcedures(payload);
  }),
  getCustomerProceduresByCustomer: thunk((actions, payload) => {
    return sdkCustomer.getProceduresByCustomer(payload);
  }),
  deleteScript: thunk((actions, payload) => {
    return sdkScript.delete(payload);
  }),
  updateScript: thunk((actions, payload) => {
    const { id, data } = payload;
    return sdkScript.update(id, data);
  }),
  updateScriptState: thunk((actions, payload) => {
    const { id, data } = payload;
    return sdkScript.updateScriptState(id, data);
  }),
  createScript: thunk((actions, payload) => {
    return sdkScript.create(payload);
  }),
  createMultiScripts: thunk((actions, payload) => {
    return sdkScript.createMulti(payload);
  }),
  getCommissioningReportsBySite: thunk((actions, payload) => {
    return sdkSite.getProceduresReports(payload.id, payload.data);
  }),
  getCommissioningReportsByCustomer: thunk((actions, payload) => {
    return sdkCustomer.getProcedureReportByCustomer(payload.id, payload.data);
  }),
  deleteCommissioningReport: thunk((actions, payload) => {
    return sdkScript.deleteCommissioningReport(payload);
  }),
  getProceduresInstances: thunk((actions, payload) => {
    return sdkSite.getProceduresInstances(payload);
  }),
  getProceduresInstancesByCustomer: thunk((actions, payload) => {
    return sdkCustomer.getProcedureInstancesByCustomer(payload);
  }),
  runProcedure: thunk((actions, payload) => {
    const { id, data } = payload;
    return sdkScript.runProcedure(id, data);
  }),
  reRunProcedure: thunk((actions, payload) => {
    return sdkScript.reRunProcedure(payload);
  }),
  stopProcedureInstance: thunk((actions, payload) => {
    return sdkScript.stopProcedureInstance(payload);
  }),
  getProcedureInstance: thunk((actions, payload) => {
    return sdkScript.getProcedureInstance(payload);
  }),
  getProcedureInstanceByCustomer: thunk((actions, payload) => {
    return sdkCustomer.getProcedureInstancesByCustomer(payload);
  })
};
