import { alpha, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    width: "100%",
    borderRadius: "8px"
  },
  title: {
    height: "60px",
    ...theme.dialogs.header,
    display: "flex",
    alignItems: "center",
    "& h2": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      ...theme.dialogs.title
    }
  },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflowX: "hidden",
    overflowY: "auto"
  },
  leftCard: {
    marginBottom: "15px"
  },
  headerStyle: {
    fontWeight: "bold",
    color: theme.palette.primary.light,
    marginBottom: "5px"
  },
  personalDetailsContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    width: "335px"
  },
  actionsHolder: {
    padding: "20px 0",
    display: "flex",
    justifyContent: "flex-end"
  },
  mainSelect: { width: "100%" },
  textArea: {
    height: "100px",
    maxWidth: "770px !important",
    width: "100%",
    borderRadius: "3px",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    padding: "13px 24px",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    fontFamily: "Roboto",
    "&::placeholder": {
      color: alpha("#545964", 0.6)
    }
  },
  userInfoContainer: {
    justifyContent: "space-between",
    marginTop: "15px"
  },
  topicContainer: {
    marginTop: "10px",
    display: "flex"
  },
  descriptionContainer: {
    margin: "0 0 20px 0"
  },
  subjectContainer: {
    margin: "20px 0"
  },
  label: {
    marginBottom: "10px"
  },
  requiredError: {
    color: "#FF0000",
    marginTop: "-3px",
    fontSize: "11px"
  },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal",
    paddingTop: "7px"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  selectStyle: {
    width: "315px"
  },
  labelSelect: { transform: "translate(14px, 11px) scale(1)" },
  arrowDownIcon: {
    top: 14.5,
    right: 9
  },
  select: {
    lineHeight: "36px",
    width: "100%",
    height: "36px",
    borderRadius: "3px",
    backgroundColor: "#ffffff",
    "&>div": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: "30px",
      paddingLeft: "12px"
    }
  },
  disabledSelect: { background: "#d5d2d57d" },
  fullWidth: {
    width: "100%"
  },
  loaderContainer: {
    width: 32.8,
    alignSelf: "center",
    marginLeft: 20
  },
  uploadContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  },
  textField: {
    width: "320px",
    height: "45px",
    borderRadius: "4px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    "& div": {
      height: 45,
      backgroundColor: "unset"
    },
    "& input": {
      padding: "0 9px"
    }
  },
  getBorder: {
    border: "1px solid red !important"
  },
  selectWidth: {
    width: "300px"
  },
  attachLabel: {
    width: 106,
    height: 36,
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#421A40",
    alignItems: "center",
    marginLeft: "30px"
  },
  chosenFile: {
    height: 36,
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#421A40",
    alignItems: "center",
    marginLeft: "20px"
  },
  attachIcons: {
    color: "#7f7182"
  },
  addRightMargin: {
    marginRight: "5px"
  },
  errorSizeMsg: {
    color: "	#FF0000",
    marginLeft: "20px"
  },
  addTopMargin: {
    marginTop: "-1px"
  }
}));
export default useStyles;
