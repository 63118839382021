import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  userIcon: {
    fontSize: "4rem",
    background: "coral",
    padding: "4rem",
    borderRadius: "10rem",
    marginBottom: "2rem",
  },

  personalDetailsContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "10px 20px",
    paddingBottom: 20,
    overflowY: "auto",
  },

  generateButton: {
    alignSelf: "center",
    padding: "0.6rem",
    marginTop: "5px",
  },

  customer: {
    margin: "0.5rem auto",
    paddingBottom: "1rem",
  },

  submit: {
    gridColumn: "2/3",
    justifyContent: "end",
  },
  passwordTitle: {
    display: "flex",
    alignItems: "center",
  },
  operationModeContainer: {
    border: "1px solid #d5d2d5;",
    borderRadius: "6px",
    height: " 50px",
    padding: "5px 10px",
    "& label": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  unitsMeasurementContainer: {
    border: "1px solid #d5d2d5;",
    borderRadius: "6px",
    height: " 50px",
    padding: "5px 10px",
    "& label": {
      display: "flex",
      justifyContent: "space-between",
    },
    marginTop: "10px",
  },
  thumb: {
    backgroundColor: theme.palette.primary.dark,
  },
  thumbDisabled: {
    backgroundColor: "#d5d2d5",
  },
  track: {
    backgroundColor: theme.palette.primary.light,
  },
  trackDisabled: {
    backgroundColor: "#e6e4d6",
  },
  tempFontStyle: {
    color: "#4b1c46",
  },
  language: {
    marginTop: "10px",
  },

  selectFormat: {
    width: "100%",
    maxWidth: "initial",
  },

  selectLang: {
    marginRight: "1rem",
    marginBottom: ".5rem",
    marginTop: "0 !important",
    border: "none",
    "&:before": {
      borderColor: "white",
    },

    "&:after": {
      borderColor: "white",
    },
    width: "15rem",
    "&>div": {
      margin: "none",
    },
  },
  itemTitle: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
  },
  itemSwtich: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  switchContainer: {
    width: "auto",
    minWidth: "155px",
  },
  switchOptionText: {
    width: "70px",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.colors.controlButtonColor,
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
  },
  headerStyle: {
    fontWeight: "bold",
    color: theme.palette.primary.light,
    marginBottom: "5px",
    fontSize: "18px",
  },
  leftCard: {
    marginBottom: "15px",
    marginRight: "5px",
    paddingRight: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 0,
    borderRight: "1px solid #d5d2d5",
  },
  rightCard: {
    marginBottom: "15px",
    marginLeft: "15px",
  },
  dialogSubContent: {
    flex: "1 1 auto",
    overflowY: "auto",
    marginTop: 5,
    flexFlow: "row nowrap",
  },
  divider: {
    marginBottom: "15px",
  },
  newBlock: {
    marginTop: "25px",
  },
  dialog: {
    width: "100%",
    borderRadius: "8px",
  },
  dialogContent: {
    padding: "20px",
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  buttonsContainer: {
    padding: "10px 20px 20px 20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  input: {
    textAlign: "left",
    height: "44px",
    width: "100%",
    "& label": {
      color: "red",
      "&focus": {
        color: "red",
      },
    },
    "& div": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    "& MuiInputBase-root": {
      color: "white",
    },
    "&>svg": {
      width: "25px",
      height: "25px",
    },
  },
  languageTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    color: theme.palette.primary.light,
    marginBottom: "5px",
  },
  changePassword: {
    marginTop: "10px",
    width: "200px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    "&>svg": {
      transform: "rotate(-90deg)",
      width: "35px",
      height: "35px",
    },
  },
  authFactorContainer: {
    border: "1px solid #d5d2d5;",
    borderRadius: "6px",
    padding: "5px 10px",
    "& label": {
      display: "flex",
      justifyContent: "space-between",
    },
    marginTop: "10px",
  },
  authFactorItemSwtich: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
}));

export default useStyles;
