import { css } from "@emotion/core";
import { createStyles, IconButton, InputLabel, makeStyles, TextField, Theme } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { TreeView } from "@material-ui/lab/";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";
import { t } from "ttag";
import StyledTreeItem from "../../components/TreeView";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { ArrowDownO, CheckboxChecked, Close, Upload } from "../../icons";

const override = css`animation-duration: 2s;`;
const maxFileSizeAllowed = 50000000; //50MB

const useStyles = makeStyles(
  createStyles({
    root: {
      height: "100%",
      flexGrow: 1,
      maxWidth: 400,
      minHeight: "calc(100% - 40px)",
      background: "#fff",
      display: "flex",
      flexFlow: "column",
      overflow: "auto",
      padding: 0
    },
    actions: {
      backgroundColor: "#fff",
      display: "flex",
      position: "absolute",
      justifyContent: "flex-end",
      width: "100%",
      bottom: "24px",
      right: "20px"
    },
    indeterminate: {
      color: "#fff"
    },
    indetCheckbox: {
      "& > span": {
        background: "red !important",
        border: "1px solid gray"
      },
      "& svg": { width: "23px", height: "23px" }
    },
    smallCheckbox: {
      marginRight: "10px",
      padding: 0,
      "& span": { width: "18px", height: "18px", borderRadius: "3px", backgroundColor: "#fff" }
    },
    labelStyle: {
      marginLeft: "-19px",
      "& p": { fontWeight: "bold" }
    },
    treeHeader: {
      paddingLeft: 20,
      paddingRight: 13,
      backgroundColor: "#f6f6f7",
      borderBottom: "1px solid #d5d2d5",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
    headerTitle: {
      fontFamily: "RobotoMedium",
      color: "#29132e",
      lineHeight: "normal",
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "normal"
    },
    customerIconContainer: {
      alignItems: "center",
      width: "unset",
      marginRight: "13px",
      "& div": { display: "none" },
      marginLeft: "10px"
    },
    customerGroup: {
      borderLeft: "1px solid #d5d2d5",
      marginLeft: "21px"
    },
    label: {
      color: "#545964",
      padding: 0
    },
    customerContent: {
      backgroundColor: "rgba(170, 162, 170, 0.1)"
    },
    textField: {
      width: "320px",
      height: "45px",
      borderRadius: "4px",
      backgroundColor: "rgba(170, 162, 170, 0.1)",
      "& div": {
        height: 45,
        backgroundColor: "unset"
      },
      "& input": {
        padding: "0 9px"
      }
    },
    getBorder: {
      border: "1px solid red !important"
    },
    loaderContainer: {
      width: 32.8,
      alignSelf: "center",
      marginLeft: 20
    },
    uploadContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center"
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
  })
);

const Checkbox1 = (props: any) => {
  const classes = useStyles();

  return <Checkbox
    color="default"
    edge="end"
    variant="outlined"
    onChange={() => { }}
    onClick={(event: any) => event.stopPropagation()}
    checkedIcon={<CheckboxChecked />}
    className={clsx(classes.smallCheckbox, { [props.className]: props.className })}
    {...props}
  />;
};

export default ({ canEdit = true, site, siteTree, allUnits, file, uploadFile, updateFilesInfo, close, updateFileRow, addNewFile, updateFile, addMessage }: any) => {
  const classes = useStyles();
  const [selectedUnits, setSelectedUnits] = useState<any>({});
  const [selectedSystems, setSelectedSystems] = useState<any>({});
  const [name, setName] = useState<string>(file?.name || "");
  const [loading, setLoading] = useState<boolean>((false));
  const [errors, setErros] = useState<any>({ name: false, file: false, error: "" });

  useEffect(() => {
    if (!file) {
      return;
    }

    const { units = [], systems = [] } = file;
    const selectedUnitsObject: any = {};
    const selectedSystemsObject: any = {};
    units.forEach((unitId: string) => selectedUnitsObject[unitId] = true);
    systems.forEach((systemId: string) => selectedSystemsObject[systemId] = true);
    setSelectedUnits(selectedUnitsObject);
    setSelectedSystems(selectedSystemsObject);
  }, [file]);

  const handleUploadFile = () => {
    setErros({ file: false, name: false, error: "" });
    const doc: any = document?.getElementById("file");
    let files: any = doc?.files;
    const noFileChoosed = files.length < 1;

    if (!noFileChoosed) {

      if (files[0]?.size > maxFileSizeAllowed) {
        setErros({ file: false, name: false, error: t`files that larger than 5MB are not allowed` });
        return;
      }
    }

    if (noFileChoosed || !name) {
      setErros({ file: noFileChoosed, name: !name, error: "" });
      return;
    }

    let formData = new FormData();
    formData.append("file", files[0]);
    setLoading(true);
    uploadFile(formData)
      .then((fileId: string) =>
        updateFilesInfo({ data: { fileId, units: Object.keys(selectedUnits), systems: Object.keys(selectedSystems), name, site: site?.id, } })
          .then((file: any) => {
            addNewFile(file);
            close();
          })
          .catch((err: any) => addMessage({ message: err.message })))
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setLoading(false));
  };

  const updateCurrentFile = () => {
    if (!name) {
      setErros({ file: false, name: true, error: "" });
      return;
    }
    updateFile({ id: file._id || file.id, data: { units: Object.keys(selectedUnits), systems: Object.keys(selectedSystems), name } })
      .then((fileData: any) => {
        updateFileRow(file.index, fileData);
        close();
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const getFileName = (event: any) => {
    const fileName = event.target.files[0]?.name || "";
    if (fileName) {
      setName(fileName);
    }
  };

  const unitsNodes = (units: any) => {
    const items = [];
    for (const unitId in units) {
      const unit = units[unitId];
      const { name, system } = unit;

      const disabled = selectedSystems[system];
      const checked = !!(selectedUnits[unitId] || selectedSystems[system]);

      unit && items.push(
        <StyledTreeItem
          key={`unit-${unitId}`}
          nodeId={`unit-${unitId}`}
          labelText={name}
          style={{ opacity: disabled ? 0.5 : 1 }}
          node={
            <Checkbox1
              disabled={disabled || !canEdit}
              checked={checked}
              onChange={(event: any) => {
                const { target: { checked } } = event;
                if (checked) {
                  selectedUnits[unitId] = true;
                  setSelectedUnits({ ...selectedUnits });
                  return;
                }

                delete selectedUnits[unitId];
                setSelectedUnits({ ...selectedUnits });
              }
              }
            />}
          color="#545964"
          bgColor="#fff"
        />);
    }
    return items;
  };

  const systemsNodes = () => {
    const items: any = [];

    for (const systemId in siteTree) {
      const system = siteTree[systemId];
      const { name, units } = system;
      const checked = !!selectedSystems[systemId];
      const indeterminate = !checked && Object.keys(selectedUnits).some((unitId) => units[unitId]);

      items.push(
        <StyledTreeItem
          key={`system-${systemId}`}
          nodeId={`system-${systemId}`}
          labelText={name}
          node={
            <Checkbox1
              disabled={!canEdit}
              checked={checked}
              indeterminate={indeterminate}
              onChange={(event: any) => {
                const { target: { checked } } = event;
                if (checked) {
                  selectedSystems[systemId] = true;

                  Object.keys(selectedUnits).forEach((unitId: string) => {
                    if (units[unitId]) {
                      delete selectedUnits[unitId];
                    }
                  });
                  setSelectedUnits({ ...selectedUnits });
                  setSelectedSystems({ ...selectedSystems });
                  return;
                }

                delete selectedSystems[systemId];
                setSelectedSystems({ ...selectedSystems });
              }
              }
            />}
          color="#545964"
          bgColor="#fff"
        >
          {unitsNodes(units)}
        </StyledTreeItem>
      );
    }
    return items;
  };

  return (
    <div style={{
      display: "flex",
      flexFlow: "column nowrap",
      maxHeight: "100%",
      minHeight: "100%"
    }}>
      <div className={classes.treeHeader}>
        <Typography className={classes.headerTitle}>{file ? t`Edit File Associations` : t`Add new file`}</Typography>
        <IconButton disableRipple onClick={close} disabled={loading} className={classes.iconBtnStyle}><Close color="#7f7692" /></IconButton>
      </div>
      <div style={{
        display: "flex",
        padding: "20px",
        flexFlow: "column nowrap",
        paddingBottom: "32px"
      }}>
        {!file &&
          <div className={classes.uploadContainer}>
            <label htmlFor="file" style={{ width: 106, height: 36 }}><Upload style={{ width: 106, height: 36 }} border={errors.file ? "red" : undefined} /></label>
            <input disabled={loading} type="file" hidden name="file" id="file" onChange={getFileName} />
            {loading && <div className={classes.loaderContainer}><MoonLoader css={override} size={20} color={"#7f7e7e"} loading={true} /></div>}
          </div>
        }
        <InputLabel style={{
          marginTop: "18px",
          height: "64px",
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "normal",
          letterSpacing: "normal",
          color: "#7f7182"
        }}>{t`File Name`}
          <TextField disabled={!canEdit} InputProps={{ disableUnderline: true }} size="small"
            classes={{ root: clsx(classes.textField, { [classes.getBorder]: errors.name }) }}
            value={name} variant="filled" onChange={(event: any) => setName(event.target.value)} placeholder={t`File Name`} />
        </InputLabel>
      </div>
      <div style={{
        display: "flex",
        flexFlow: "column nowrap",
        padding: "0 20px",
        paddingBottom: 0,
        minHeight: "calc(100% - 309px)",
        maxHeight: "calc(100% - 309px)"
      }}>
        <Typography style={{
          height: "21px",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "normal",
          letterSpacing: "normal",
          color: "#29132e",
          marginBottom: 18
        }}>{t`Apply to System`}</Typography>
        <TreeView
          className={classes.root}
          defaultExpanded={["1"]}
          defaultCollapseIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ marginLeft: "7px", marginRight: "2px" }} /> </>}
          defaultExpandIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ transform: "rotate(-90deg)", marginLeft: "7px", marginRight: "2px" }} /></>}
          defaultEndIcon={<div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} />}
        >
          <StyledTreeItem
            key={`site-${site?.id}`}
            nodeId={`1`}
            labelText={site?.name}
            node={undefined}
            color="#545964"
            bgColor="#fff"
          >
            {systemsNodes()}
          </StyledTreeItem>
        </TreeView>
      </div>
      <div style={{ padding: "20px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        {errors.error && <Typography style={{ color: "red", fontSize: "12px", width: "100%" }}>{errors.error}</Typography>}
        <Button width={150} style={{ minHeight: "40px" }}
          onClick={() => file ? updateCurrentFile() : handleUploadFile()}
          disabled={loading || !canEdit}
        >{t`Save`}</Button>
      </div>
    </div>
  );
};
