import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dataPointConfig__item: {
    paddingBottom: '32px',
    borderBottom: '2px solid #D9D9D9',
    marginBottom: '32px',
    '&:last-Child': {
      marginBottom: 0,
      borderBottom: 'none',
    },
  },
  dataPointConfig__itemHead: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '&>div:first-child': {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px',
      marginRight: 'auto',
      color: '#6B4B68',
    },
  },
  dataPointConfig__itemContent: {},
  configInter: {
    marginBottom: '16px',
    '&:last-Child': {
      marginBottom: 0,
    },
  },
  configInter__head: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '&>div:first-child': {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px',
      marginRight: 'auto',
      color: '#9E839B',
    },
  },
  configInter__content: {},
  labelColorConfig: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gap: '2px', // Adjust the gap between items as needed
  },
  colorBox: {
    width: '18px',
    height: '18px',
    border: '1px solid transparent',
    borderRadius: '5px',
    padding: '1px',
    cursor: 'pointer',
    boxSize: 'border-box',
    '&>div': {
      width: '14px',
      height: '14px',
      borderRadius: '4px',
      boxSize: 'border-box',
    },
    '&.isSelected': {
      border: '1px solid #000',
    },
  },
  controlTypeSelection: {
    display: 'flex',
    alignItems: 'center',
  },
  controlTypeSelection__radio: {
    padding: '10px 8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .MuiRadio-root': {
      padding: 0,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        '&:first-child': {
          color: '#545964',
        },
        '&:last-child': {
          color: '#545964',
        },
      },
    },
    '& .MuiSvgIcon-root:first-child': {
      color: '#545964',
    },
  },
  controlTypeSelection__label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&>span:first-child': {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '19.74px',
      color: '#545964',
      marginRight: '8px',
    },
  },
  labelSizeConfig: {
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      width: '40px',
      height: '40px',
      textAlign: 'center',
      lineHeight: '40px',
      color: '#828282',
      border: '1px solid #fff',
      marginRight: '14px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      cursor: 'pointer',
      '&.isSelected': {
        borderColor: '#000',
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  customTextField: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none', // Hides the fieldset
      },
    },
    '& .MuiInputBase-root': {
      border: 'none !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.06px',
      backgroundColor: 'white',
      padding: '13px 8px',
      borderRadius: '4px',
      color: '#828282',
    },
  },
  customSelectField: {
    '&.MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none', // Hides the fieldset
      },
    },
    '& .MuiInputBase-root': {
      border: 'none !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.06px',
      backgroundColor: 'white',
      padding: '5px 8px',
      borderRadius: '4px',
      color: '#828282',
    },
  },
}));

export default useStyles;
