import * as React from 'react';

function SvgHomeAuto(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 34 26" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#AAA2AA">
          <path d="M14.304 15.6a1.716 1.716 0 100 3.432 1.716 1.716 0 000-3.432M19.091 15.6a1.716 1.716 0 100 3.432 1.716 1.716 0 000-3.432M23.816 15.6a1.716 1.716 0 100 3.432 1.716 1.716 0 000-3.432M9.516 15.6a1.716 1.716 0 100 3.432 1.716 1.716 0 000-3.432" />
        </g>
        <path
          d="M16.9 6.5a2.6 2.6 0 10-.001 5.199A2.6 2.6 0 0016.9 6.5m0 1.3c.717 0 1.3.583 1.3 1.3 0 .717-.583 1.3-1.3 1.3-.717 0-1.3-.583-1.3-1.3 0-.717.583-1.3 1.3-1.3"
          fill="#AAA2AA"
        />
        <rect
          stroke="#AAA2AA"
          strokeWidth={1.5}
          x={0.75}
          y={0.75}
          width={32.3}
          height={24.5}
          rx={2}
        />
        <path stroke="#AAA2AA" strokeWidth={0.8} d="M3.9 3.9h26v18.2h-26z" />
      </g>
    </svg>
  );
}

export default SvgHomeAuto;
