import {
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import React from "react";
import { t } from "ttag";
import { useStoreState } from "../../../../models/RootStore";
import unitPanelStyle from "../UnitPanel/UnitPanel.style";

const PowerMeterPanel: React.FC<any> = (props) => {
  const useStyles = makeStyles(unitPanelStyle);
  const classes = useStyles();
  const allPowerMeters = useStoreState((state) => state.powerMeters.allPowerMeters);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);

  const {
    id,
    isCelsius,
    siteTemp,
    tempSymbol,
    timezoneOffset
  } = props;
  const item = allPowerMeters[id];

  if (!item) {
    return null;
  }

  const { name = "", power = 0, lastReadingTimestamp } = item;

  const timeToString = (timestamp: any) => {
    return moment(timestamp).utcOffset(timezoneOffset).format(timeFormat);
  };
  const dateToString = (timestamp: any) => {
    return moment(timestamp).utcOffset(timezoneOffset).format(dateFormat);
  };

  return (
    <div className={classes.unitView} >
      <div className={classes.firstRowContainer}>
        <Typography className={classes.siteTemp}>{(isCelsius ? siteTemp.C || "" : siteTemp.F || "") + " "}<span className={classes.scaleStyle}>{siteTemp.C && tempSymbol || ""}</span></Typography>
        <div className={classes.nameSection}>
          <Typography >{name}</Typography>
        </div>
        {/* this div is to keep name in middle instead of right */}
        <div className={classes.powerContainer}>
        </div>
      </div>
      <div className={classes.secondRowContainerSensor}>
        <div className={classes.controlDiv}>
          <div className={classes.powerWrapperStyle} >
            <Typography className={classes.power}>
              <span>{t`Power`}</span>
              <span>{Math.round(power * 100) / 100}<span className={classes.powerUnit}>kW</span></span>
            </Typography>
            <div className={clsx(classes.timeDateStyle)}>
              <div className={clsx(classes.dataTimeFont, classes.timeStyle)}> {timeToString(lastReadingTimestamp)}</div>
              <div className={classes.dataTimeFont}> {dateToString(lastReadingTimestamp)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.lastRowContainer}>
      </div>
    </div>
  );
};

export default PowerMeterPanel;
