import {
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import _, { mapValues } from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { EditUser as SvgUser } from "../../icons/";
import { DeleteIcon, EditIcon } from "../../logos";
import { IAlertGroup } from "../../models/AlertGroups";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import AlertGroup2 from "./AlertGroup2";
import useStyles from "./AlertSettings.style";
import AlertSettingsContacts from "./AlertSettingsContacts";
import AlertsPriority from "./PriorityPopup";
import useChurnZero from "@hooks/useChurnZero";
interface IColumn {
  id: string;
  label: string;
  style?: any;
}

interface Rule {
  id: string;
  name: string;
  isEnabled: boolean;
}

interface RuleMap {
  [key: string]: Rule;
}

const AlertGroupsSetting: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const isInitialized = useStoreState((s) => s.isInitialized);
  const getSiteSmartRules = useStoreActions((action) => action.sites.getSiteSmartRules);
  const getSiteAlertGroups = useStoreActions((action) => action.alertGroups.getSiteAlertGroups);
  const updateContactAlertGroup = useStoreActions((action) => action.alertGroups.updateContactAlertGroup);
  const updateContactsAlertGroup = useStoreActions((action) => action.alertGroups.updateContactsAlertGroup);
  const getUserContacts = useStoreActions((action) => action.contacts.getSiteContacts);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const updateAlertGroup = useStoreActions((action) => action.alertGroups.updateAlertGroup);
  const createAlertGroup = useStoreActions((action) => action.alertGroups.createAlertGroup);
  const deleteAlertGroup = useStoreActions((action) => action.alertGroups.deleteAlertGroup);
  const getTraps = useStoreActions((action) => action.traps.getTraps);
  const allSites = useStoreState((state) => state.sites.allSites);
  const { siteId } = useStoreState((s) => s.selections.selections);
  const types = useStoreState((s) => s.types);

  const { trapPriorityTypes } = types;
  const { permissions = {} } = siteId ? allSites[siteId] : {};
  const { canCreateAlertGroups, canUpdateAlertGroups } = permissions;
  const [allAlertGroups, setAllAlertGroups] = useState<any>({});
  const [selectedAlertGroup, setSelectedAlertGroup] = useState<any>({});
  const [contactsNewAlertGroup, setContactsNewAlertGroup] = useState<any>([]);
  const [editAlertGroup, setEditAlertGroup] = useState<any>(null);
  const [openAddNewAlertGroup, setOpenAddNewAlertGroup] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [traps, setTraps] = useState<any>(null);
  const [smartRules, setSmartRules] = useState<RuleMap>({});
  const [userContacts, setUserContacts] = useState<any>(null);

  const [automationRules, setAutomationRules] = useState<any>(null);
  const [allowSms, setAllowSms] = useState<boolean>(true);
  const [settingContactsProps, setSettingContactsProps] = useState<any>({});
  const [openCreateNewRecipients, setOpenCreateNewRecipients] = useState<boolean>(false);
  const [toDeleteItem, setToDeleteItem] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredAlertGroups, setFilteredAlertGroups] = useState<any>({});

  const {trackEvent} = useChurnZero()

  const fetchSmartRules = async () => {
    if (!siteId) return;

    try {
      const data = await getSiteSmartRules(siteId);
      setSmartRules(data);
    } catch (error) {
      addMessage({ message: (error as Error).message });
    }
  };

  const closeSettingContactsProps = () => {
    setSettingContactsProps({});
    setAnchorEl(null);
  };
  useEffect(() => {
    closeSettingContactsProps();
    if (!siteId) {
      return;
    }
    setSmartRules({});
    setAllAlertGroups({});
    setTraps(null);
    setAutomationRules(null);
    setUserContacts(null);
    getSiteAlertGroups(siteId)
      .then((res: any) => {
        setAllAlertGroups(res);
        if (Object.values(res).length) {
          setSelectedAlertGroup(Object.values(res)[0] || {});
        }
      })
      .catch((error: any) => addMessage({ message: error.message }));

    const anomalies: any = {};
    getTraps({ type: null, siteId })
      .then((res: any) => {
        Object.values(res).forEach((rule: any) => {
          const { type, id } = rule;
          if (type === 1 || type === 50) {
            anomalies[id] = rule;
          }
          if (type === 1 || type === 50) {
            anomalies[id] = rule;
          }
          if (type !== 201) {
            delete res[id];
          }
        });
        setTraps(anomalies);
        setAutomationRules(res);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });

    getUserContacts(siteId)
      .then((res: any) => {
        setUserContacts(res);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });

      fetchSmartRules();
  }, [siteId]);

  useEffect(() => {
    if (!selectedAlertGroup) {
      return;
    }
    checkAlertGroupSites(selectedAlertGroup);
  }, [selectedAlertGroup]);

  useEffect(() => {
    /**
     * this code to discard the deleted contact from usersContacts DB and still contact Id in alertGroups.contacts
     * should be fix from backend
     */
    if (_.isEmpty(userContacts) || _.isEmpty(allAlertGroups)) {
      return;
    }
    const newAllAlertGroups: any = {};
    let isThereContactDeleted = false;
    Object.values(allAlertGroups).forEach((alertGroup: any) => {
      const newContacts = alertGroup.contacts.filter((contact: any) => userContacts?.[contact?.contact]);
      newAllAlertGroups[alertGroup.id] = { ...alertGroup, contacts: newContacts };
      isThereContactDeleted = isThereContactDeleted || newContacts.length !== alertGroup.contacts.length;
    });
    if (isThereContactDeleted) {
      setAllAlertGroups(newAllAlertGroups);
    }
  }, [allAlertGroups, userContacts]);

  useEffect(() => {
    setFilteredAlertGroups(filterGroups(allAlertGroups));
  }, [searchValue, allAlertGroups, siteId]);

  if (!isInitialized) { return <Loading />; }

  const columns: IColumn[] = [
    { id: "groupName", label: t`Group Name`, style: { width: "20%" } },
    { id: "priority", label: t`Priority` },
    { id: "systemError", label: t`System Error` },
    { id: "indoorError", label: t`Indoor Error` },
    { id: "Communication", label: t`Communication` },
    { id: "anomalies", label: t`Anomalies` },
    { id: "maintenance", label: t`Maintenance` },
    { id: "automationLogic", label: t`Automation Logic` },
    { id: "smartRules", label: t`Smart Rules` },
    { id: "status", label: t`Status` },
    { id: "contacts", label: "" },
    { id: "tools", label: "" }
  ];

  const closeDialog = () => {
    setEditAlertGroup(null);
    setOpenAddNewAlertGroup(false);
    setSelectedAlertGroup(null);
    setContactsNewAlertGroup([]);
  };

  const handleDelete = (itemId: string) => {
    setSelectedAlertGroup(_.values(allAlertGroups)[0] ? _.values(allAlertGroups)[0] : "");
    deleteAlertGroup({ id: itemId })
      .then(() => {
        const { [itemId]: toBeDeleted, ...restOfAlertGroups } = allAlertGroups;
        setAllAlertGroups(restOfAlertGroups);
      });

  };

  const checkAlertGroupSites = (selectedAlertGroup: any) => {
    const selectedAlertGroupContacts = selectedAlertGroup?.contacts || {};

    const siteObj = allSites[selectedAlertGroup.site];
    if (siteObj?.hvacAdvancedOperations || siteObj?.predictiveMaintenance || siteObj?.basicRemoteDiagnostics) {
      setAllowSms(true);
      return;
    }

    setAllowSms(false);
    Object.values(selectedAlertGroupContacts).forEach(async (contact: any) => {
      if (contact.sendSms) {
        let data: any = {
          sendEmail: contact.sendEmail,
          sendSms: false
        };
        await updateContactAlertGroup({
          id: selectedAlertGroup.id,
          contactId: contact.contact,
          updatedData: data
        });
      }
    });
  };

  const handleAddNewAlertGroup = (data: any) => {
    if (!siteId) {
      return;
    }
    const enablesObject = data.enablesObject;
    delete data.enablesObject;

    const alertGroupNew = {
      ...data,
      ...enablesObject
    };
    createAlertGroup(alertGroupNew)
      .then((res: any) => {
        updateContactsAlertGroup({ ...res, contacts: selectedAlertGroup.contacts })
          .then((updatedAlert: any) => setAllAlertGroups({ ...allAlertGroups, [res.id]: updatedAlert }));
      })
      .catch((err: any) => {
        setError(err.message);
      });
  };

  const handleAlertSwitch = (alertGroup: IAlertGroup) => () => {

    const action = alertGroup.enable ? 'Disabled':'Enabled';
    const eventType = `NotificationsSettings${action}Group`;
    const eventDescription =  `Notification group ${action}`;
    trackEvent(eventType,eventDescription);

    updateAlertGroup({ id: alertGroup.id, updatedData: { enable: !alertGroup.enable } });
    setAllAlertGroups({ ...allAlertGroups, [alertGroup.id]: { ...alertGroup, enable: !alertGroup.enable } });
  };

  const handleEditAlertGroup = (data: any) => {
    const id = data.id;
    const enablesObject = data.enablesObject;
    delete data.id;
    delete data.enablesObject;

    const alertGroup = {
      ...data,
      ...enablesObject
    };

    updateAlertGroup({ id, updatedData: alertGroup })
      .then((res: any) => {
        setAllAlertGroups({ ...allAlertGroups, [id]: res });
        if (data.id === selectedAlertGroup.id) {
          checkAlertGroupSites(alertGroup);
        }
      })
      .catch((err: any) => {
        setError(err.message);
      });
  };

  const openEditAlertGroupDialog = (alertGroup: any) => {
    const allTraps = (Object.entries(traps).reduce((acc: any, [key, value]: any) => {
      value.isEnabled = alertGroup.allUserTrapsEnable;
      acc[key] = value;
      return acc;
    }, {}));
    alertGroup.traps?.forEach((trapId: string) => allTraps[trapId] && (allTraps[trapId].isEnabled = true));

    const allAutomationRules = (Object.entries(automationRules).reduce((acc: any, [key, value]: any) => {
      value.isEnabled = alertGroup.allLogicTrapsEnable;
      acc[key] = value;
      return acc;
    }, {}));
    alertGroup.logicTraps?.forEach((ruleId: string) => allAutomationRules[ruleId] && (allAutomationRules[ruleId].isEnabled = true));

    const allSmartRules = Object.entries(smartRules).reduce((acc: RuleMap, [key, value]: any) => {
      acc[key] = {
        ...value,
        isEnabled: !!alertGroup.allSmartRulesEnable || alertGroup.smartRules?.includes(key)
      };
      return acc;
    }, {});

    setAutomationRules(allAutomationRules);
    setTraps(allTraps);
    setSmartRules(allSmartRules);
    setEditAlertGroup(alertGroup);
  };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchValue}
      onChange={(event: any) => setSearchValue(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot },
        endAdornment:
          !searchValue ? (<Search />) : (
            <IconButton
              onClick={() => setSearchValue("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  const filterGroups = (alertGroups: any[]) => {
    const filteredRows = _(alertGroups)
      .filter((alertGroup) => {
        const globalAlerts = [
          { isEnabled: !!alertGroup?.deviceDisconnectedEnable, title: t`Device Disconnect` },
          { isEnabled: !!alertGroup?.cleanFilterEnable, title: t`Clean Filter alerts (No SMS)` },
          { isEnabled: !!alertGroup?.indoorUnitErrorEnable, title: t`Unit indoor alerts` },
          { isEnabled: !!alertGroup?.outdoorUnitErrorEnable, title: t`Unit Outdoor alerts` },
          { isEnabled: !!alertGroup?.unitDisconnectedEnable, title: t`Unit Disconnected` },
          { isEnabled: !!alertGroup?.sensorDisconnectedEnable, title: t`Sensor Disconnected` },
          { isEnabled: !!alertGroup?.powerMeterDisconnectedEnable, title: t`Power Meter Disconnected` },
          { isEnabled: !!alertGroup?.lowLineQualityEnable, title: t`Line Quality alerts` },
          { isEnabled: !!alertGroup?.scheduleFailuresEnable, title: t`Schedule Failures` }
        ];
        return searchValue?.length ?
          ((searchValue.toLocaleLowerCase() === "sms") && alertGroup.contacts.length && alertGroup.contacts.every((contact: any) => contact?.sendSms)) ||
          alertGroup.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          (alertGroup.contacts.length && alertGroup.contacts.find((contact: any) => `${contact.firstName} ${contact.lastName}`.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))) ||
          alertGroup.traps.find((trap: any) => traps[trap]?.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) && traps[trap].isEnabled) ||
          alertGroup.logicTraps.find((rule: any) => automationRules[rule].name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) && automationRules[rule].isEnabled) ||
          alertGroup.smartRules.find((ruleId: string) => smartRules[ruleId].name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) && smartRules[ruleId].isEnabled) ||
          globalAlerts.find((element) => element.title?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) && element.isEnabled)
          : true;
      })
      .value();

    return filteredRows;
  };

  const handleAddGroupClick = () => {
    trackEvent('NotificationsSettingsAddGroup','The user initiated a notification group setup');
    setOpenAddNewAlertGroup(true);
    setSelectedAlertGroup({ new: true, contacts: contactsNewAlertGroup, site: siteId });
  }

  return (<div className={classes.view}>
    <ServiceNavigationBar {...props} />
    <div className={classes.contentArea}>
      <Header
        path={["Alert Groups"]}
        hideSystemSelection
        hideUnitSelection
        searchComponent={searchComponent}
        screenTitle="userMgmt"
      />
      {!siteId ?
        <FilterRequire type={t`site`} />
        :
        <>
          <div className={classes.titleBar}>
            <div>
              <Typography variant="h6" className={classes.title}>
                Notification Group Settings
              </Typography>
            </div>

            <div className={classes.barRightSide}>
              <AlertsPriority canUpdateAlertGroups={canUpdateAlertGroups} />
              <Button
                disabled={!canCreateAlertGroups}
                style={{ marginLeft: 16 }}
                onClick={handleAddGroupClick}
              >
                {t`+ Add Group`}
              </Button>
            </div>
          </div>
          <Paper elevation={0} className={classes.paperTableContainer}>
            <TableContainer >
              <Table stickyHeader className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    {columns.map((column) => <TableCell classes={{ root: classes.tableHeadCell }} align="left" style={column.style} key={column.id}>
                      {column.label}
                    </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.orderBy((Object.values(filteredAlertGroups)), [(alertGroup: any) => alertGroup.name.toUpperCase()], ["asc"]).map((alertGroup: any, index: number) => {
                    const systemErrors: any = [alertGroup?.outdoorUnitErrorEnable];
                    const indoorErrors: any = [alertGroup?.indoorUnitErrorEnable];
                    const maintenance: any = [alertGroup?.scheduleFailuresEnable, alertGroup?.lowLineQualityEnable, alertGroup?.cleanFilterEnable];
                    const communication: boolean[] = [
                      !!alertGroup?.deviceDisconnectedEnable,
                      !!alertGroup?.unitDisconnectedEnable,
                      !!alertGroup?.sensorDisconnectedEnable,
                      !!alertGroup?.powerMeterDisconnectedEnable
                    ];

                    const alertGroupPermissions = alertGroup.permissions || {};
                    return (
                      <TableRow hover tabIndex={-1} key={index} className={classes.tableBodyRow} onDoubleClick={() => alertGroupPermissions.canUpdate && openEditAlertGroupDialog(alertGroup)}>
                        <TableCell scope="row" align="left" >
                          {alertGroup.name}
                        </TableCell>

                        <TableCell align="left" >
                          {alertGroup.type === 2 ? trapPriorityTypes?.[alertGroup.priority || alertGroup.priorities?.[0]] : "-"}
                        </TableCell>

                        <TableCell scope="row" align="left" >
                          {alertGroup.type === 1 ? systemErrors.filter(Boolean).length : "-"}
                        </TableCell>

                        <TableCell scope="row" align="left" >
                          {alertGroup.type === 1 ? indoorErrors.filter(Boolean).length : "-"}
                        </TableCell>

                        <TableCell scope="row" align="left" >
                          {alertGroup.type === 1 ? communication.filter(Boolean).length : "-"}
                        </TableCell>

                        <TableCell scope="row" align="left" >
                          {alertGroup.type === 1 ? alertGroup.traps?.length : "-"}
                        </TableCell>

                        <TableCell scope="row" align="left" >
                          {alertGroup.type === 1 ? maintenance.filter(Boolean).length : "-"}
                        </TableCell>

                        <TableCell scope="row" align="left" >
                          {alertGroup.type === 1 ? alertGroup.logicTraps.filter(Boolean).length : "-"}
                        </TableCell>

                        <TableCell scope="row" align="left" >
                          {alertGroup.type === 1 ? alertGroup.smartRules.filter(Boolean).length : "-"}
                        </TableCell>

                        <TableCell scope="row" align="left" >
                          <CoolSwitch disabled={!canUpdateAlertGroups || !alertGroupPermissions.canUpdate} checked={alertGroup.enable} onChange={handleAlertSwitch(alertGroup)}
                            classes={{
                              root: classes.root,
                              switchBase: classes.switchBase,
                              thumb: classes.thumb,
                              track: classes.track,
                              checked: classes.checked
                            }} />
                        </TableCell>

                        <TableCell scope="row" align="left" classes={{ root: clsx(classes.overWritePadding) }}  >
                          <div className={classes.contactsTooltip}>
                            <LightTooltip placement="top" arrow title={alertGroup.contacts?.map((contact: any, index: number) => {
                              return <p key={index}>
                                <span> {userContacts?.[contact?.contact]?.firstName} {userContacts?.[contact?.contact]?.lastName} </span>
                                {contact?.sendSms && <span> SMS </span>}
                                {contact?.sendEmail && <span> Email </span>}
                              </p>;
                            })}>
                              <div className={classes.contactsTooltip}>
                                {alertGroup.contacts?.map((contact: any, index: number) => (index < 2 && alertGroup.contacts?.length > 3 || alertGroup.contacts?.length < 4) && <p key={index} className={classes.contactIcon}>{userContacts?.[contact?.contact]?.firstName?.toUpperCase()?.[0]}</p>)}
                                {alertGroup.contacts?.length > 3 && (<p className={classes.contactIcon}>+{alertGroup.contacts?.length - 2}</p>)}
                              </div>
                            </LightTooltip>
                            <SvgUser
                              onClick={(event: any) => {
                                if (!canUpdateAlertGroups) return;
                                setSelectedAlertGroup(alertGroup);
                                setAnchorEl(event.currentTarget);
                                setSettingContactsProps({ open: true, x: event.clientX, y: event.clientY });
                              }
                              }
                              className={classes.svgUserIcon} />
                          </div>
                        </TableCell>

                        <TableCell scope="row" style={{ width: 67 }} align="right">
                          <div className={classes.editIcons}>
                            {/* disabled={!alertGroupPermissions.canCopy} */}

                            {/* <IconButton disabled={!alertGroupPermissions.canUpdate} disableRipple onClick={() => openEditAlertGroupDialog(alertGroup)}>
                          <EditIcon />
                        </IconButton> */}

                            <IconButton disabled={!alertGroupPermissions.canUpdate} disableRipple onClick={() => openEditAlertGroupDialog(alertGroup)}>
                              <EditIcon />
                            </IconButton>

                            <IconButton disabled={!alertGroupPermissions.canDelete} disableRipple onClick={() => setToDeleteItem(alertGroup)}>
                              <DeleteIcon />
                            </IconButton>

                          </div>
                        </TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      }
    </div>
    {(editAlertGroup || openAddNewAlertGroup) && (
      <AlertGroup2
        canEdit={true}
        // canEdit={editAlertGroup.permissions.canUpdate}
        // canEdit={canUpdateAlertGroups}
        close={closeDialog}
        setSelectedAlertGroup={setSelectedAlertGroup}
        setAnchorEl={setAnchorEl}
        setSettingContactsProps={setSettingContactsProps}
        userContacts={userContacts}
        saveAlert={openAddNewAlertGroup ? handleAddNewAlertGroup : handleEditAlertGroup}
        editAlertGroup={editAlertGroup || selectedAlertGroup}
        allTraps={openAddNewAlertGroup ? (Object.entries(traps).reduce((acc: any, [key, value]: any) => {
          value.isEnabled = false;
          acc[key] = value;
          return acc;
        }, {}))
          : traps
        }
        allSites={_.sortBy(Object.values(allSites), ["name"], ["asc"])}
        automationRules={openAddNewAlertGroup ? (Object.entries(automationRules).reduce((acc: any, [key, value]: any) => {
          value.isEnabled = false;
          acc[key] = value;
          return acc;
        }, {}))
          : automationRules
        }
        siteId={siteId}
        smartRules={openAddNewAlertGroup ? mapValues(smartRules, rule => ({...rule, isEnabled: false})) : smartRules}
      />
    )}

    {
      anchorEl && !!settingContactsProps && <Popover
        open={!!settingContactsProps}
        anchorEl={anchorEl}
        onClose={() => { }}
        classes={{ paper: classes.popover }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <AlertSettingsContacts
          openCreateNewRecipients={openCreateNewRecipients}
          setOpenCreateNewRecipients={setOpenCreateNewRecipients}
          {...settingContactsProps}
          selectedAlertGroup={selectedAlertGroup}
          setSelectedAlertGroup={setSelectedAlertGroup}
          userContacts={userContacts}
          setUserContacts={setUserContacts}
          onClose={closeSettingContactsProps}
          handleUpdateContactsAlertGroup={(alertGroupUpdated: any) => {
            if (openAddNewAlertGroup) {
              setContactsNewAlertGroup(alertGroupUpdated.contacts);
              setSelectedAlertGroup(alertGroupUpdated);
              return;
            }
            if (editAlertGroup) {
              setEditAlertGroup(alertGroupUpdated);
              return;
            }
            setAllAlertGroups({ ...allAlertGroups, [alertGroupUpdated.id]: alertGroupUpdated });
          }}
        />
      </Popover>
    }
    {toDeleteItem && <DeleteDialog
      open={!!toDeleteItem}
      object={{ id: toDeleteItem.id, name: toDeleteItem.name + t` Alert Group` }}
      onClose={() => setToDeleteItem(null)}
      type={t`Alert Group`}
      detach={handleDelete}
    />
    }
  </div>);

};

export default AlertGroupsSetting;
