import { TableContainer, TableFooter, TablePagination } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/Units";
import useStyles from "./ErrorLogs.style";
import ErrorMouseOverPopover from "./ErrorPopUp";

interface IProps {
  unit?: IUnit;
  hideColumns?: string[];
  showTitle?: boolean;
  title?: string;
  hideEntity?: boolean;
  usePadding?: boolean;
  systemId?: string;
  showPointer?: boolean;
  onAlertClick?: any;
  inDashboard?: boolean;
  hideAlertOfType?: number[];
  filterType?: any;
  unitAlerts?: any;
  order?: string[];
  checkForTrapAlerts?: boolean;
  showAck?: boolean;
  showClearFilterAlerts?: boolean;
}
const ErrorLog: React.FC<IProps> = ({
  unit,
  hideColumns = ["description"],
  showTitle,
  hideEntity,
  systemId,
  usePadding = true,
  showPointer = false,
  onAlertClick,
  inDashboard = false,
  hideAlertOfType = [],
  filterType = null,
  unitAlerts = [],
  order = [],
  checkForTrapAlerts = false,
  title = "",
  showAck = false,
  showClearFilterAlerts = true
}) => {

  const columns: any = {
    alertType: {
      id: "alertType",
      label: "",
      width: "30px"
    },
    time: {
      id: "time",
      label: t`TIME`,
      width: "18ch"
    },
    errorCode: {
      id: "errorCode",
      label: t`CODE`,
      width: "5ch"
    },
    description: {
      id: "description",
      label: t`DESCRIPTION`,
      width: "31ch",
      multiLine: true
    },
    unitName: {
      id: "unitName",
      label: t`UNIT`,
      width: "25ch"
    },
    systemName: {
      id: "systemName",
      label: t`SYSTEM`,
      width: "25ch",
      dashboardUnitWidth: "16ch",
      notFixed: true
    },
    siteName: {
      id: "siteName",
      label: t`SITE`,
      width: "25ch"
    },
    alertType1: {
      id: "alertType1",
      label: t`ALERT TYPE`,
      width: "120px"
    },
    customerName: {
      id: "customerName",
      label: t`CUSTOMER`,
      width: "26ch"
    },
    status: {
      id: "status",
      label: t`STATUS`
    }
  };

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<any>([]);

  const selections = useStoreState((state) => state.selections.selections);
  const getAlertsByFilter = useStoreState((state) => state.alerts.getAlertsByFilter);

  const { customerId, siteId } = selections;
  const filter = unit ? { unitId: unit.id } : systemId ? { systemId } : { customerId, siteId };
  const [filteredRows, setFilteredRows] = useState<any>(rows);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orderedColumns = order?.length ? order.map((key: any) => columns[key]) : Object.values(columns);

  useEffect(() => {
    const rowsTemp: any = unitAlerts.length ? unitAlerts : unit ? getAlertsByFilter(filter, true, false, false) : getAlertsByFilter(filter);
    setRows(rowsTemp);
  }, [unit, systemId, siteId, customerId]);

  useEffect(() => {
    setPage(0);
    if (!rows) {
      return;
    }
    setFilteredRows(rows.filter((row: any) => {
      const ackFilterd = !showAck ? (row.status?.toUpperCase() !== "ACKNOWLEDGED") : true;
      const showCleanFilter = row.description?.toLowerCase() === "clean filter" ? showClearFilterAlerts : true;
      const typeFilterd = filterType ? row?.severity?.id === filterType : true;
      const hideTypeFiltered = hideAlertOfType.length ? hideAlertOfType.indexOf(row.type) === -1 : true;
      return hideTypeFiltered && ackFilterd && typeFilterd && showCleanFilter;
    }));

  }, [filterType, siteId, unit?.id, systemId, rows, showClearFilterAlerts]);

  return (
    <Paper
      classes={{ elevation1: classes.elevation }}
      className={usePadding ? clsx(classes.root, classes.root_padding) : classes.root}
    >
      {showTitle && (
        <div className={classes.header}>
          <Typography className={classes.title}>{title ? title : t`Open Alerts`}</Typography>
        </div>
      )}
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              {orderedColumns.map(
                (column: any) =>
                  (column.hideInSingleUnitMode && unit) ||
                  (hideColumns.indexOf(column.id) !== -1 ? null : (
                    <TableCell
                      key={column.id}
                      align={"left"}
                      classes={{ root: classes.tableHeadCell }}
                    >
                      {column.label}
                    </TableCell>
                  ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length ? (
              _.orderBy((filteredRows), ["timestamp", "status"], ["desc", "asc"])
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => {
                  const { id } = row;
                  return (
                    <ErrorMouseOverPopover
                      showPointer={showPointer}
                      key={id}
                      columns={orderedColumns}
                      hideColumns={hideColumns}
                      row={row}
                      onAlertClick={onAlertClick}
                      dashboardUnit={showTitle}
                      hideEntity={hideEntity}
                      checkForTrapAlerts={checkForTrapAlerts}
                    />
                  );
                })
            ) : (
              <TableRow>
                <TableCell>
                  <Typography>{t`No  active alerts `}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {!inDashboard && filteredRows.length > 10 && (
            <TableFooter className={clsx({ [classes.movePageSelectionToTop]: inDashboard })}>
              <TableRow className={clsx({ [classes.removeBottomBorder]: inDashboard })}>
                <TablePagination
                  colSpan={7}
                  count={filteredRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: false
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ErrorLog;
