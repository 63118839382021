import React, { useMemo, useState } from 'react';
import { MoonLoader } from 'react-spinners';

import { useGetBacnetUiTemplate } from '@hooks/useBacnetUiTemplatesApi';
import { useGetBacnetParamTemplate } from '@hooks/useBacnetParamTemplatesApi';
import { useSetBacnetObjectValue } from '@hooks/useBacnetDevicesApi';
import MappingArea from '@components/AddEditBacnetUiTemplateDialog/TemplateArea/MappingArea';
import { mergeUiTemplatePointsWithParamTemplate } from '@components/AddEditBacnetUiTemplateDialog/constants';

import useStyles from './styles';

const BacnetControlPanel = ({ entry, isCelsius, tempSymbol, siteTemp }) => {
  const classes = useStyles();
  const [dataPointsValues, setDataPointsValues] = useState(
    entry?.operationalData
  );

  const { data: uiTemplate, isLoading: isLoadingUiTemplate } =
    useGetBacnetUiTemplate(entry.uiTemplate);

  const { data: paramTemplate, isLoading: isLoadingParamTemplate } =
    useGetBacnetParamTemplate(uiTemplate?.paramTemplate);

  const { mutate: setObjectValue } = useSetBacnetObjectValue();

  const { title, name } = entry;

  const onUpdateObjectValue = (objectId, value) => {
    setDataPointsValues((prev) => ({
      ...prev,
      [`${objectId}/85`]: value,
    }));

    setObjectValue({
      unitId: entry.id,
      payload: {
        object: objectId,
        value: value,
      },
    });
  };

  const temperatureDisplay = useMemo(() => {
    if (isCelsius && !siteTemp?.C) {
      return null;
    }

    if (!isCelsius && !siteTemp?.F) {
      return null;
    }

    // Determine the temperature and symbol to display
    const temperature = isCelsius
      ? Math.round(siteTemp.C)
      : Math.round(siteTemp.F);

    return (
      <>
        <span>{temperature}</span>
        <span className={classes.scaleStyle}>{tempSymbol}</span>
      </>
    );
  }, [isCelsius, siteTemp, tempSymbol]);

  // Merge with paramTemplate configuration
  const mergedDataPoints = useMemo(() => {
    return mergeUiTemplatePointsWithParamTemplate(
      paramTemplate?.data,
      uiTemplate?.data?.points
    );
  }, [paramTemplate, uiTemplate]);

  return (
    <div className={classes.bacnetControlPanel}>
      <div className={classes.bacnetControlPanel__header}>
        {temperatureDisplay && (
          <div className={classes.bacnetControlPanel__temperature}>
            {temperatureDisplay}
          </div>
        )}
        <div className={classes.bacnetControlPanel__unitName}>
          {title ?? name}
        </div>
      </div>
      <div className={classes.bacnetControlPanel__body}>
        {isLoadingUiTemplate || isLoadingParamTemplate ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
              width: '100%',
            }}
          >
            <MoonLoader size={50} color="#A1899F" />
          </div>
        ) : (
          <MappingArea
            dataPoints={mergedDataPoints}
            onChangeControlValue={onUpdateObjectValue}
            templateImg={uiTemplate?.fileURL}
            mode="control"
            dataPointsValues={dataPointsValues}
          />
        )}
      </div>
    </div>
  );
};

export default BacnetControlPanel;
