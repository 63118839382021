import React from 'react';
import { t } from 'ttag';
import useStyle from './Filters.style';
import { Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import FilterItems from './FilterItems';

interface IFiltersProps {
    selectedFilters: {
        users: { [key: string]: any };
        source: { [key: string]: any };
        actions: { [key: string]: any };
    };
    setSelectedFilters: (filters: {
        users: { [key: string]: any };
        source: { [key: string]: any };
        actions: { [key: string]: any };
    }) => void;
    setOpenedFilters: (opened: boolean) => void;
    getFilterName: (category: string, filterName: string) => string | undefined;
}

const Filters: React.FC<IFiltersProps> = ({ selectedFilters, setSelectedFilters, setOpenedFilters, getFilterName }) => {
    const classes = useStyle();

    const handleRemoveFilter = (category: 'users' | 'source' | 'actions', filterName: string) => (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        event.preventDefault();
        delete selectedFilters[category][filterName];
        setSelectedFilters({ ...selectedFilters });
    };

    return (
        <div className={classes.filtersContainer} onClick={() => setOpenedFilters(true)}>
            <Typography className={classes.title}>
                {t`Filter By`}
            </Typography>
            <div className={classes.filterItemsContainer}>
                {['users', 'source', 'actions'].map(category => (
                    <FilterItems
                        key={category}
                        type={category as 'users' | 'source' | 'actions'}
                        selectedFilters={selectedFilters}
                        getFilterName={getFilterName}
                        handleRemoveFilter={handleRemoveFilter}
                    />
                ))}
            </div>
            <FilterList />
        </div>
    );
};

export default Filters;
