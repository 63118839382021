import React from 'react';
import PropTypes from 'prop-types';

const SoundSensor = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1114_9170)">
			<g clipPath="url(#clip1_1114_9170)">
				<mask id="mask0_1114_9170" maskUnits="userSpaceOnUse" x="0" y="1" width="20" height="20">
					<path d="M0.46991 1.10744H19.372V20.0095H0.46991V1.10744Z" fill="white" />
				</mask>
				<g mask="url(#mask0_1114_9170)">
					<path d="M1.02368 9.07488V12.042" stroke="white" strokeMiterlimit="10" />
				</g>
				<path d="M3.24799 6.85979V14.2571" stroke="white" strokeMiterlimit="10" />
				<path d="M18.8182 9.07488V12.042" stroke="white" strokeMiterlimit="10" />
				<path d="M7.69659 6.85979V14.2571" stroke="white" strokeMiterlimit="10" />
				<path d="M5.47235 4.64471V16.4722" stroke="white" strokeMiterlimit="10" />
				<path d="M12.1453 2.4295V18.6871" stroke="white" strokeMiterlimit="10" />
				<path d="M9.92096 4.64471V16.4722" stroke="white" strokeMiterlimit="10" />
				<path d="M16.5939 6.85979V14.2571" stroke="white" strokeMiterlimit="10" />
				<path d="M14.3696 4.64471V16.4722" stroke="white" strokeMiterlimit="10" />
			</g>
		</g>
		<defs>
			<clipPath id="clip0_1114_9170">
				<rect width="20" height="20" fill="white" transform="translate(0 0.458374)" />
			</clipPath>
			<clipPath id="clip1_1114_9170">
				<rect width="18.9021" height="18.9021" fill="white" transform="translate(0.46991 1.10742)" />
			</clipPath>
		</defs>
	</svg>

);

SoundSensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

SoundSensor.defaultProps = {
	width: 20,
	height: 21
};

export default SoundSensor;