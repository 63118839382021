import * as React from 'react';

function ToggleIcon(props) {
  return (
    <svg
      width="36"
      height="17"
      viewBox="0 0 36 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        opacity="0.5"
        y="2.7002"
        width="36"
        height="10.8"
        rx="5.4"
        fill="#4B1C46"
      />
      <circle cx="27.9" cy="8.1" r="8.1" fill="#6B4B68" />
    </svg>
  );
}

export default ToggleIcon;
