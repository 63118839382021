import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  buttonLogin: {
    minHeight: "53px",
    width: "480px",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "480px",
    margin: "1rem",
  },
  resetButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "1rem 0",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    width: "480px",
    "& >div": {
      width: "480px",
    },
    "& >div>div": {
      width: "480px",
    },
  },
  content: {
    width: "640px",
    borderRadius: "6px",
    backgroundColor: theme.boxes.passwordRecovery.backgroundColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  Login: {
    MozFlexDirection: "column",
    flexDirection: "column",
    height: "100vh",
    display: "flex",
    fontSize: "1.5rem",
  },

  logos: {
    justifyContent: "center",
    display: "flex",
    MozFlexDirection: "column",
    flexDirection: "column",
    alignItems: "center",
  },

  title: {
    ...theme.typography.themeTitle,
    paddingBottom: "20px",
    paddingTop: "40px",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  logo: {
    paddingBottom: "2rem",
    paddingTop: "2rem",
  },

  logoTitle: {
    height: "32px",
    width: "206px",
    paddingBottom: "40px",
  },
  splashScreenImg: {
    marginTop: "10rem",
  },

  interactionSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingBottom: "15rem",
  },

  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    MozFlex: "1",
    flex: "1",
    backgroundImage: theme.gradiants.themeBackground,
  },

  forgotPassBtn: {
    ...theme.link.button,
  },

  hidden: {
    display: "none",
  },

  newPasswordForm: {
    display: "inline-flex",
    flexDirection: "column",
  },
  buttonReset: {
    minHeight: "53px",
    width: "100%",
    marginBottom: "10px",
  },
  buttonReset1: {
    minHeight: "53px",
    width: "167px",
    marginBottom: "10px",
  },
  buttonBack: {
    minHeight: "53px",
    width: "167px",
    marginBottom: "10px",
    borderRadius: "4px",
    fontFamily: "roboto",
    margin: "0",
    ...theme.overrides.MuiButton.outlinedSecondary,
  },

  resetSuccessContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      width: "50%",
      textAlign: "center",
      marginBottom: "40px",
    },
    "& button": {
      backgroundColor: "#f05347",
      color: "#fff",
      fontWeight: 500,
      fontSize: "15px",
      width: "40%",
      "&:hover": {
        backgroundColor: "#f05347",
      },
    },
  },
  reportProblemButton: {
    backgroundColor: theme.specialButtons.reportProblemMain.backgroundColor,
    position: "absolute",
    zIndex: 99999,
    left: "15px",
    bottom: "40px",
    borderRadius: "2rem",
    height: "3.5rem",
    width: "3.5rem",
    minWidth: "3rem",
    "& span": {
      "& svg": {
        width: "3rem",
        height: "3rem",
      },
    },
  },
  error: {
    color: theme.errorMessages.loginForm.color,
    margin: "10px",
  },
  textFieldInputClass: {
    margin: "0.5rem 1.2rem",

    width: "354px",
    fontSize: `${theme.textField.loginForm.fontSize}px !important`,
    color: theme.textField.loginForm.color,
    border: "none",

    "& input": {
      color: theme.textField.loginForm.color,
      padding: "15px ",
    },
    "& input + fieldset": {
      border: "none",
    },
    "& input:disabled + fieldset": {
      border: "none",
    },
    "& div": {
      borderRadius: "4px 4px  0 0",
      backgroundColor: theme.textField.loginForm.backgroundColor,
    },
    "& ::placeholder": {
      color: theme.textField.loginForm.color,
      opacity: 0.5,
      fontSize: "16px",
      lineHieght: "normal",
      fontWeight: "normal",
    },
    "& label": {
      color: `${theme.textField.loginForm.color} !important`,
    },
  },
  select: {
    textAlign: "left",
    margin: "0.5rem 1.2rem",
    textTransform: "capitalize",
    width: "354px",
    borderBottom: "none",
    color: theme.textField.loginForm.color,
    backgroundColor: `${theme.textField.loginForm.backgroundColor} !important`,
    "&>svg": {
      top: "10px",
      width: "40px",
      height: "40px",
    },
  },
  selectLabel: {
    color: theme.textField.loginForm.color,
    lineHeight: "normal",
    paddingLeft: "20px",
    paddingTop: "5px",
    "&.Mui-focused": {
      color: theme.textField.loginForm.color,
    },
  },
  dialogStyle: {
    padding: "30px 26px 27px 26px",
    minWidth: "400px",
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentStyle: {
    paddingBottom: "22px",
    color: "#545964",
    fontWeight: "normal",
    lineHeight: "normal",
    fontSize: "18px",
    fontFamily: "Roboto",
    textAlign: "center",
    maxWidth: "100%",
    wordBreak: "break-word",
    whiteSpace: "pre-line",
  },
  textNotCentered: {
    textAlign: "unset",
  },
  btnsContainer: {
    width: "360px",
    justifyContent: "center",
    display: "flex",
  },
  moveToRight: {
    alignSelf: "end",
    justifyContent: "space-between",
  },
}));
export default useStyles;
