/* eslint-disable react/display-name */
import React, { memo, useCallback } from 'react';
import { t as translate } from 'ttag';
import { TextField, Select, MenuItem, Radio } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

import { controlTypeConfig, labelConfig, labelSizes } from './../../constants';

const DataPointConfigItem = memo(({ label, addon, children, classes }) => (
  <div className={classes.dataPointConfig__item}>
    {(label || addon) && (
      <div className={classes.dataPointConfig__itemHead}>
        <div>{label}</div>
        <div>{addon}</div>
      </div>
    )}
    <div className={classes.dataPointConfig__itemContent}>{children}</div>
  </div>
));

const ConfigInter = memo(({ label, children, addon, classes }) => (
  <div className={classes.configInter}>
    {(label || addon) && (
      <div className={classes.configInter__head}>
        <div>{label}</div>
        <div>{addon}</div>
      </div>
    )}
    <div className={classes.configInter__content}>{children}</div>
  </div>
));

const LabelSizeSelector = memo(
  ({ labelSize, onUpdateDataPoint, dataPoint, classes }) => (
    <div className={classes.labelSizeConfig}>
      {labelConfig.size.map((size) => (
        <div
          key={size.id}
          style={{
            fontSize: labelSizes[size.id],
          }}
          className={clsx({
            isSelected: labelSize === size.id,
          })}
          onClick={() =>
            onUpdateDataPoint({
              ...dataPoint,
              labelSize: size.id,
            })
          }
        >
          A
        </div>
      ))}
    </div>
  )
);

const LabelColorSelector = memo(
  ({ labelColorConfig, onUpdateDataPoint, dataPoint, classes }) => (
    <div className={classes.labelColorConfig}>
      {labelConfig.color.map((color) => (
        <div
          key={`color-${color}`}
          className={clsx(classes.colorBox, {
            isSelected: labelColorConfig === color,
          })}
          onClick={() =>
            onUpdateDataPoint({
              ...dataPoint,
              labelColorConfig: color,
            })
          }
        >
          <div style={{ backgroundColor: color }} />
        </div>
      ))}
    </div>
  )
);

const ControlTypeSelector = memo(
  ({ onUpdateDataPoint, classes, dataPoint }) => (
    <>
      {dataPoint.config.supportedControls.map((controlTypeId) => (
        <div key={controlTypeId} className={classes.controlTypeSelection}>
          <Radio
            className={classes.controlTypeSelection__radio}
            checked={dataPoint.controlType === controlTypeId}
            onChange={(e) =>
              onUpdateDataPoint({
                ...dataPoint,
                controlType: e.target.value,
              })
            }
            value={controlTypeId}
            name="control-type-selection"
            inputProps={{ 'aria-label': controlTypeConfig[controlTypeId].name }}
          />
          <div
            className={classes.controlTypeSelection__label}
            onClick={() => {
              onUpdateDataPoint({
                ...dataPoint,
                controlType: controlTypeId,
              });
            }}
          >
            <span>{controlTypeConfig[controlTypeId].name}</span>
            <span>{controlTypeConfig[controlTypeId].icon}</span>
          </div>
        </div>
      ))}
    </>
  )
);

const DataPointConfig = ({ dataPoint, onUpdateDataPoint }) => {
  const classes = useStyles();

  const handleLabelChange = useCallback(
    (e) => {
      onUpdateDataPoint({
        ...dataPoint,
        label: e.target.value,
      });
    },
    [onUpdateDataPoint]
  );

  const handleLabelPositionChange = useCallback(
    (e) => {
      onUpdateDataPoint({
        ...dataPoint,
        labelPosition: e.target.value,
      });
    },
    [onUpdateDataPoint]
  );

  return (
    <>
      <DataPointConfigItem label={translate`Label:`} classes={classes}>
        <ConfigInter classes={classes}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={translate`Room Temperature`}
            className={classes.customTextField}
            value={dataPoint?.label || ''}
            onChange={handleLabelChange}
          />
        </ConfigInter>
        <ConfigInter label={translate`Label Position`} classes={classes}>
          <Select
            value={dataPoint?.labelPosition || ''}
            variant="outlined"
            fullWidth
            className={classes.customSelectField}
            onChange={handleLabelPositionChange}
          >
            {labelConfig.position.map((position) => (
              <MenuItem key={position.id} value={position.id}>
                {position.name}
              </MenuItem>
            ))}
          </Select>
        </ConfigInter>
        <ConfigInter label={translate`Label Size`} classes={classes}>
          <LabelSizeSelector
            labelSize={dataPoint?.labelSize}
            onUpdateDataPoint={onUpdateDataPoint}
            dataPoint={dataPoint}
            classes={classes}
          />
        </ConfigInter>
        <ConfigInter label={translate`Label Color`} classes={classes}>
          <LabelColorSelector
            labelColorConfig={dataPoint?.labelColorConfig}
            onUpdateDataPoint={onUpdateDataPoint}
            dataPoint={dataPoint}
            classes={classes}
          />
        </ConfigInter>
      </DataPointConfigItem>
      <DataPointConfigItem label={translate`Control Type:`} classes={classes}>
        <ConfigInter classes={classes}>
          <ControlTypeSelector
            onUpdateDataPoint={onUpdateDataPoint}
            classes={classes}
            dataPoint={dataPoint}
          />
        </ConfigInter>
      </DataPointConfigItem>
    </>
  );
};

export default memo(DataPointConfig);
