import {
  Dialog,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { Formik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { useStoreActions } from "../../models/RootStore";
import useStyles from "./AddEditContact.style";
import { Services as sdkServices } from "coolremote-sdk";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { getPhoneValidation } from "@utils/validations";

const ContactSchema =
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, t`Too Long!`)
      .min(3, t`Too Short!`)
      .required(t`Required`),
    lastName: Yup.string()
      .max(20, t`Too Long!`)
      .min(3, t`Too Short!`)
      .required(t`Required`),
    email: Yup.string()
      .email(t`Invalid email format`)
      .required(t`Required`),
    phone: getPhoneValidation(),
  });
export { ContactSchema };

const AddEditContact: React.FC<any> = (props) => {
  const classes = useStyles();
  const { onClose, editContact: contact, localContacts, setLocalContacts, canEdit = true, siteId, customerId } = props;
  const [open, setOpen] = useState(true);
  const isEdit = contact ? true : false;

  const createContact = useStoreActions((action) => action.contacts.createContact);
  const updateContact = useStoreActions((action) => action.contacts.updateContact);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const initialValues: any = {
    firstName: isEdit ? (contact?.firstName || "") : "",
    lastName: isEdit ? (contact?.lastName || "") : "",
    email: isEdit ? contact?.email : "",
    phone: isEdit ? contact?.phone : ""
  };

  const editContact = (values: any) => {
    values.phone = values?.phone.replace(/[^a-zA-Z0-9 ]/g, "");
    values.phone = values?.phone.replace(/\s/g, "");
    updateContact({ id: contact.id, data: values })
      .then((res: any) => {
        const updatedCnotact = { ...localContacts[contact.id], ...values };
        setLocalContacts({ ...localContacts, [contact.id]: updatedCnotact });
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
    handleClose();

  };
  const createNewContact = (values: any) => {
    values.phone = values?.phone.replace(/[^a-zA-Z0-9 ]/g, "");
    values.phone = values?.phone.replace(/\s/g, "");

    createContact({ id: siteId, data: { ...values, site: siteId } })
      .then((res: any) => {
        // we multiply new with -1 to sort them in contacts list on ["new", "firstName", "lastName"], ["asc"]
        setLocalContacts({ ...localContacts, [res.id]: { ...res, new: new Date().getTime() * -1 } });
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{isEdit ? t`Edit Recipient` : t`Create New Recipient`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleClose}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <Formik
          initialValues={initialValues}
          onSubmit={isEdit ? editContact : createNewContact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
        >
          {({ handleSubmit, errors, touched, values, setFieldValue, ...restFormikProps }) => (
            <form onSubmit={handleSubmit}>
              <Paper elevation={0}>
                <Grid className={classes.personalDetailsContainer}>
                  <TextField
                    disabled={!canEdit}
                    label={t`First name`}
                    value={values.firstName}
                    onChange={(event: any) => setFieldValue("firstName", event.target?.value)}
                    name="firstName"
                    variant="outlined"
                    margin="dense"
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={errors.firstName && touched.firstName ? errors.firstName : ""}
                  />
                  <TextField
                    disabled={!canEdit}
                    label={t`Last name`}
                    value={values.lastName}
                    onChange={(event: any) => setFieldValue("lastName", event.target?.value)}
                    name="lastName"
                    variant="outlined"
                    margin="dense"
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={errors.lastName && touched.lastName ? errors.lastName : ""}
                  />
                  <div className={classes.emailContainer}>
                    <TextField
                      disabled={!canEdit}
                      label={t`Email`}
                      value={values.email}
                      onChange={(event: any) => setFieldValue("email", event.target?.value)}
                      name="email"
                      variant="outlined"
                      margin="dense"
                      error={Boolean(errors.email) ? true : false}
                      helperText={errors.email && touched.email ? errors.email : ""}
                      className={classes.emailField}
                    />
                    <Tooltip
                      title={t`this will send email to this address - please contact the relevant person and ensure they got this email.`}
                    >
                      <span>
                        <Button
                          width={205}
                          white
                          disabled={!values.email || Boolean(errors.email)}
                          onClick={() => { sdkServices.sendTestEmail({ emails: [values.email], customer: customerId }) }}
                          className={classes.sendTestEmailButton}
                        >
                          {t`Send Verification E-mail`}
                        </Button>
                      </span>
                    </Tooltip>
                  </div>

                  <div className={classes.emailContainer}>
                    <MuiPhoneNumber
                      defaultCountry={"us"}
                      disabled={!canEdit}
                      label={t`Phone Number`}
                      value={values.phone}
                      name="phone"
                      variant="outlined"
                      margin="dense"
                      error={Boolean(errors.phone) ? true : false}
                      helperText={errors.phone && touched.phone ? errors.phone : t`International format Phone number`}
                      onChange={(e: any) => setFieldValue("phone", e)}
                      inputClass={classes.emailField}
                    />
                    <Tooltip
                      title={t`this will send SMS to this number - please contact the relevant person and ensure they got this SMS.`}
                    >
                      <span>
                        <Button
                          width={205}
                          white
                          disabled={!values.phone || Boolean(errors.phone)}
                          onClick={() => { sdkServices.sendSMSTest({ phoneNumbers: [values.phone], customer: customerId }) }}
                          className={classes.sendTestEmailButton}
                        >
                          {t`Send Verification SMS`}
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                  <Typography variant="body2" style={{ fontSize: 11, marginLeft: 14 }}>
                    {t`By entering your number, you agree to receive mobile messages`}
                  </Typography>
                </Grid>
              </Paper>
              <div className={classes.actionsHolder}>
                <Button
                  disabled={!canEdit}
                  width={150}
                  white
                  marginRight
                  onClick={handleClose}
                  onMouseUp={handleClose}
                >
                  {t`Cancel`}
                </Button>
                <Button
                  disabled={!canEdit}
                  width={150}
                  type="submit"
                  onMouseUp={handleSubmit}
                  onClick={handleSubmit}
                >
                  {t`Save`}
                  {/* {isEdit ? t`Update` : t`Add`} */}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default AddEditContact;
