import { alpha, createStyles } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    itemsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    colon: {
      margin: " 0 5px",
      color: "white"
    },
    timeInput: {
      color: "white",
      border: `1px solid ${alpha("#545964", 0.5)}`,
      borderRadius: "6px",
      padding: "5px",
      textAlign: "center",
      boxSizing: "border-box",
      height: "40px",
      width: "40px",
      "& input::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      "& input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
        textAlign: "center",
        padding: 0
      }
    },

    middayWrapper: {
      marginLeft: "5px",
      color: "white",
      "&:before": {
        borderColor: "white"
      },
      "&:after": {
        borderColor: "white"
      }
    },
    middayBlackWrapper: {
      color: "black",
      "&:before": {
        borderColor: "black"
      },
      "&:after": {
        borderColor: "black"
      }
    },
    blackPickerIcon: {
      color: "black",
      fill: "black"
    },
    grayColor: {
      color: "#545964 !important"
    }

  });
