import React from 'react';

const RefreshRounded = (props) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.125 6.78516C10.125 6.78516 10.5532 6.57422 9 6.57422C8.44374 6.57422 7.89997 6.73917 7.43746 7.04821C6.97495 7.35725 6.61446 7.7965 6.40159 8.31042C6.18872 8.82434 6.13302 9.38984 6.24154 9.93541C6.35006 10.481 6.61793 10.9821 7.01126 11.3755C7.4046 11.7688 7.90574 12.0367 8.45131 12.1452C8.99688 12.2537 9.56238 12.198 10.0763 11.9851C10.5902 11.7723 11.0295 11.4118 11.3385 10.9493C11.6476 10.4867 11.8125 9.94298 11.8125 9.38672" stroke="#4B1C46" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M9 5.23828L10.4062 6.64453L9 8.05078" stroke="#4B1C46" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 2.25C5.27344 2.25 2.25 5.27344 2.25 9C2.25 12.7266 5.27344 15.75 9 15.75C12.7266 15.75 15.75 12.7266 15.75 9C15.75 5.27344 12.7266 2.25 9 2.25Z" stroke="#4B1C46" stroke-miterlimit="10" />
        </svg>

    );
}

export default RefreshRounded;
