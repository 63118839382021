import {
  IconButton,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { getIn } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { CheckboxChecked, Delete, MoreVert } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import CommonUtils from "../../utils/CommonUtils";
import styles from "./AddRule.style";
import AnomaliesUtils from "./AnomaliesUtils";
import { CustomAutoComplete, CustomedTextField, CustomSelect, GButton } from "./CustomedComponents";
import LightTooltip from "@components/Tooltip/LightTooltip";

const theresholdOptionOnly = [
  { value: "threshold", label: "Difference" },
  { value: "> parameter", label: ">" },
  { value: "< parameter", label: "<" }
];
const equalOptionOnly = [{ value: "=", label: "=" }, { value: "!=", label: "≠" }];
const iduNumOption = { value: "iduNum", name: "# of Indoor Units" };
const oduNumOption = { value: "oduNum", name: "# of Outdoor Units" };
const theresholdOptions = [
  { value: "threshold", label: " >" },
  { value: "thresholdWithin", label: " <" }
];
const unitsNumOptions = [{ value: 1, label: "Number of units" }, { value: 2, label: "% of total system units" }];

const Condition = (props: any) => {
  const {
    errors,
    touched,
    conditionsUnitsType,
    isUnitsSelected,
    showWranning,
    name,
    indoorParams,
    outdoorParams,
    condition,
    deleteCondition,
    index,
    onSelect,
    lastCondition,
    setFieldValue,
    disabled,
    groupKey,
    allParamsMap,
    unitTypes,
    enumParams,
    serviceParamTypes,
    nextCondition,
    hideIduNum,
    hideOduNum,
    ignoreUnitType,
    moveProps = {}
  } = props;
  
  const classes = styles();
  const { inGroup } = props;
  const unitTypesOptions = useStoreState((state) => state.unitTypesOptions);
  const trapOperatorsOptions = useStoreState((state) => state.trapOperatorsOptions);
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);
  const pressureUnit = useStoreState((s) => s.users.me.measurementUnits);
  const { getDurationByMin, selectableDurations } = AnomaliesUtils;
  const isPreDefined = CommonUtils.isValidDuration(condition.duration) && selectableDurations.find((element) => getDurationByMin(element.value) === getDurationByMin(condition.duration));
  const [isCustomDuration, setIsCustomDuration] = useState<boolean>(!isPreDefined);

  const handleCheck = (event: any) => {
    const { target: { checked } } = event;
    onSelect(condition.id, checked);
  };
  const removeCheck = () => {
    onSelect(condition.id, false);
  };

  const matchParamLabelWithUnitType: any = {
    param1: {
      [unitTypes.service]: "Indoor Parameter",
      [unitTypes.outdoor]: "Outdoor Parameter",
      mixed: "Indoor Parameter",
      "": "Parameter"
    },
    param2: {
      [unitTypes.service]: "Indoor Parameter",
      [unitTypes.outdoor]: "Outdoor Parameter",
      mixed: "Outdoor Parameter",
      "": "Parameter"
    }
  };

  const handleUnitsTypeChange = (event: any, name: any) => {
    const { target: { value } } = event;

    if (value === "iduNum" || value === "oduNum") {
      removeCheck();
      setFieldValue(name, value);
      return;
    }

    if (isUnitsSelected) {
      if (value === "mixed" || (value === unitTypes.service && conditionsUnitsType !== "indoorUnits") || (value === unitTypes.outdoor && conditionsUnitsType !== "outdoorUnits")) {
        showWranning(value, index, "unitType", !!inGroup, groupKey || "");
        return;
      }
    }

    condition.parameter = "";
    condition.parameter2 = "";
    setFieldValue(name, value);
  };
  const isMixed = condition.unitType === "mixed";
  const isThreshHold = condition.operator?.includes("threshold");
  const isParamOperator = condition.operator?.includes("parameter");
  const paramsOptions = (condition.unitType === unitTypes.service ? indoorParams : condition.unitType === unitTypes.outdoor ? outdoorParams : [...indoorParams, ...outdoorParams]);
  const paramList1Options = isMixed ? indoorParams : paramsOptions;
  const paramList2Options = isMixed ? outdoorParams : paramsOptions;
  const selectedParamEnum = condition.parameter ? allParamsMap[condition.parameter]?.enum : undefined;
  const paramsEnumTypes = selectedParamEnum ? (serviceParamTypes[selectedParamEnum]) : {};
  const valueDropdownOptions = Object.keys(paramsEnumTypes || {}).map((key: any) => ({ status: paramsEnumTypes[key], value: key }));

  const param1Unit = !!condition.parameter && allParamsMap[condition.parameter]?.data_unit_of_measurement;
  const paramList2Filtered = !condition.parameter ? paramList2Options : paramList2Options.filter((param: any) => {
    return param.data_unit_of_measurement === param1Unit && param.code !== condition.parameter;
  });

  const paramMeasurementMatch = (paramScale: any) => {

    if (temperatureScale === 2 && paramScale?.toUpperCase() === "°C") {
      return "°F";
    }
    if (pressureUnit === 2 && paramScale?.toUpperCase() === "KG/CM2") {
      return "PSI";
    }
    if (pressureUnit === 2 && paramScale?.toUpperCase() === "KPA") {
      return "PSI";
    }
    if (pressureUnit === 2 && paramScale?.toUpperCase() === "MPA") {
      return "PSI";
    }
    if (pressureUnit === 1 && paramScale?.toUpperCase() === "PSI") {
      return "kg/cm2";
    }

    return paramScale;
  };

  const valueUnit: any = !isThreshHold ?
    paramMeasurementMatch(allParamsMap[condition.parameter]?.data_unit_of_measurement) :
    (allParamsMap[condition.parameter]?.data_unit_of_measurement === allParamsMap[condition.parameter2]?.data_unit_of_measurement ?
      paramMeasurementMatch(allParamsMap[condition.parameter]?.data_unit_of_measurement) :
      "");

  const isFakeCondition = condition.unitType === "iduNum" || condition.unitType === "oduNum";
  const isNextConditionFake = inGroup ? false : lastCondition && (nextCondition?.unitType === "iduNum" || nextCondition?.unitType === "oduNum");
  let allunitsTypesOptions = unitTypesOptions;
  if (!hideIduNum) { allunitsTypesOptions = [...allunitsTypesOptions, iduNumOption]; }
  if (!hideOduNum) { allunitsTypesOptions = [...allunitsTypesOptions, oduNumOption]; }
  const isFakePercent = (condition.unitType === "iduNum" && condition.indoorPerc != 1)
    || (condition.unitType === "oduNum" && condition.outdoorPerc != 1);
  const fakeError = getIn(errors, `${name}[${index}].value`);

  /** a function that calculates and returns the placeholder for the Parameter 1 field */
  const getParamOnePlaceholder = ():string => {

    //If Mixed is selected - return the mixed placeholder.
    if(isMixed){
     return matchParamLabelWithUnitType.param1[condition.unitType]
    }

    if(!condition.parameter){
      if(isThreshHold || isParamOperator){
        return "Parameter 1"
      }else{
        return "Parameter"
      }
    }

    if (isThreshHold || isParamOperator){
     return `${matchParamLabelWithUnitType.param1[condition.unitType]} 1`
    }
     return matchParamLabelWithUnitType.param1[condition.unitType] 
  }

  /** a function that calculates and returns the placeholder for the Parameter 2 field */
  const getParamTowPlaceholder = ():string => {

    //If Mixed is selected - return the mixed placeholder.
    if(isMixed){
      return matchParamLabelWithUnitType.param2[condition.unitType]
    }

    if(!condition.parameter){
      if(isThreshHold || isParamOperator){
        return "Parameter 2"
      }else{
        return "Parameter"
      }
    }

    if (isThreshHold || isParamOperator){
      return matchParamLabelWithUnitType.param2[condition.unitType] + " 2"
     }
    
     return matchParamLabelWithUnitType.param2[condition.unitType] 

  }

  return (
    <>
      <div className={clsx(classes.conditionContainer, { [classes.conditionInGroupContainer]: inGroup })}>
        <LightTooltip title={t`Click & drag to change order of rule conditions`}>
          {inGroup ? <MoreVert className={classes.purbleMoreEvert} /> :
            <div {...moveProps} className={classes.moreVertContainer}><MoreVert className={classes.whiteMoreVert} /></div>
          }
        </LightTooltip>
        <Checkbox
          color="default"
          edge="end"
          variant="outlined"
          onChange={handleCheck}
          checkedIcon={<CheckboxChecked />}
          className={clsx(classes.checkboxStyle, { [classes.hideCheckbox]: inGroup, [classes.hideElement]: isFakeCondition })}
          disabled={disabled}
        />
        {!ignoreUnitType && <CustomSelect className={classes.width135} error={getIn(errors, `${name}[${index}].unitType`) && getIn(touched, `${name}[${index}].unitType`)}
          placeholder="Type"
          name={`${name}[${index}].unitType`}
          onChange={(event: any) => handleUnitsTypeChange(event, `${name}[${index}].unitType`)}
          value={condition.unitType}
          options={inGroup ? unitTypesOptions : !isFakeCondition ? allunitsTypesOptions : (condition.unitType === "iduNum" ? [...allunitsTypesOptions, iduNumOption] : [...allunitsTypesOptions, oduNumOption])}
          disabled={disabled}
        />}
        {isFakeCondition &&
          <>
            <CustomSelect className={classes.width210} error={getIn(errors, `${name}[${index}].unitType`) && getIn(touched, `${name}[${index}].unitType`)}
              placeholder=" # Or %"
              name={`${name}[${index}].${condition.unitType === "iduNum" ? "indoorPerc" : "outdoorPerc"}`}
              onChange={(event: any) => { setFieldValue(`${name}.${index}.value`, ""); setFieldValue(`${name}[${index}].${condition.unitType === "iduNum" ? "indoorPerc" : "outdoorPerc"}`, event.target.value); }}
              value={condition.unitType === "iduNum" ? condition.indoorPerc : condition.outdoorPerc}
              options={unitsNumOptions}
              label="label"
              optionValue="value"
              disabled={disabled}
            />
            <div className={classes.fieldContainer}>
              <CustomedTextField
                disabled={disabled}
                className={classes.width140}
                error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
                onChange={({ target: { value } }: any) => setFieldValue(`${name}[${index}].value`, value)}
                value={condition.value}
                name={`${name}.${index}.value`}
                type="number"
                label={t`Value` + `${isFakePercent ? ` (%)` : " (#)"}`}
              />
              {(getIn(touched, `${name}[${index}].value`) && fakeError && fakeError !== "Required") &&
                <Typography className={classes.errorText}>{fakeError}</Typography>}
            </div>
          </>}
        {!isFakeCondition &&
          <CustomAutoComplete
            disabled={disabled || (!ignoreUnitType && !condition.unitType)}
            error={getIn(errors, `${name}[${index}].parameter`) && getIn(touched, `${name}[${index}].parameter`)}
            placeholder={getParamOnePlaceholder()}
            className={clsx({[classes.parameterFiledMixedMode]:isMixed})}
            onChange={(event: any, value1: any) => {
              const value = value1?.code || "";
              if (allParamsMap[value]?.enum !== selectedParamEnum) { setFieldValue(`${name}[${index}].value`, ""); }
              if (allParamsMap[value]?.enum && !["=", "!="].includes(condition.operator)) { setFieldValue(`${name}[${index}].operator`, ""); setFieldValue(`${name}[${index}].parameter2`, ""); }
              setFieldValue(`${name}[${index}].parameter`, value);
            }}
            value={allParamsMap[condition.parameter] || ""}
            label="title"
            optionValue="code"
            useTooltip
            tooltipProp={"title"}
            options={isMixed ? indoorParams : paramList1Options}
            notDisabledOptions={isMixed ? enumParams : undefined}
            name={`${name}[${index}].parameter`}
          />}

        {!isFakeCondition && <CustomSelect
          placeholder="Operator"
          className={classes.width135}
          error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
          onChange={(event: any) => setFieldValue(`${name}[${index}].operator`, event.target.value)}
          value={condition.operator}
          label="label"
          optionValue="value"
          options={isMixed ? theresholdOptionOnly : (selectedParamEnum ? equalOptionOnly : trapOperatorsOptions)}
          name={`${name}[${index}].operator`}
          disabled={disabled}
        />}

        {(!isFakeCondition && (isMixed || isThreshHold || isParamOperator)) &&
          <CustomAutoComplete
            disabled={disabled || (!ignoreUnitType && !condition.unitType) || !condition.parameter}
            error={getIn(errors, `${name}[${index}].parameter2`) && getIn(touched, `${name}[${index}].parameter2`)}
            placeholder={getParamTowPlaceholder()}
            className={clsx({[classes.parameterFiledMixedMode]:isMixed})}
            onChange={(event: any, value: any) => setFieldValue(`${name}[${index}].parameter2`, value?.code || "")}
            value={allParamsMap[condition.parameter2] || ""}
            label="title"
            optionValue="code"
            options={paramList2Filtered}
            notDisabledOptions={isMixed || isThreshHold || isParamOperator ? enumParams : undefined}
            name={`${name}[${index}].parameter2`}
            useTooltip
            tooltipProp={"title"}
          />
        }

        {isThreshHold &&
          <CustomSelect
            placeholder="Difference"
            className={classes.width135}
            error={getIn(errors, `${name}[${index}].thresholdOperator`) && getIn(touched, `${name}[${index}].thresholdOperator`)}
            onChange={(event: any) => setFieldValue(`${name}[${index}].thresholdOperator`, event.target.value)}
            value={condition.thresholdOperator}
            label="label"
            optionValue="value"
            options={theresholdOptions}
            name={`${name}[${index}].thresholdOperator`}
            disabled={disabled}
          />}

        {isParamOperator || isFakeCondition ? null : selectedParamEnum ?
          (
            <CustomSelect
              disabled={disabled}
              className={classes.width140}
              placeholder={"Value"}
              onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
              value={condition.value + ""}
              label="status"
              optionValue="value"
              options={valueDropdownOptions}
              notDisabledOptions={["default"]}
              name={`${name}[${index}].value`}
            />
          )
          : (<CustomedTextField
            disabled={disabled}
            className={classes.width140}
            error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
            onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
            value={condition.value}
            name={`${name}.${index}.value`}
            type="number"
            label={t`Value` + `${!!valueUnit ? `(${valueUnit})` : ""}`}
          />)}

        {!isFakeCondition &&
          <CustomAutoComplete
            disabled={disabled}
            error={getIn(errors, `${name}[${index}].duration`) && getIn(touched, `${name}[${index}].duration`)}
            placeholder="Duration"
            customTooltip = {t`Period of time the condition should be met before triggering`}
            onChange={(event: any, value1: any) => {
              const value = value1?.value || "";
              setIsCustomDuration(value === "00:00");
              setFieldValue(`${name}[${index}].duration`, value);
            }}
            value={isPreDefined ? isPreDefined : { title: t`Custom`, value: "00:00" }}
            label="title"
            optionValue="value"
            options={[{
              title: t`Custom`,
              value: "00:00"
            }, ...selectableDurations]}
            name={`${name}[${index}].duration`}
          />}

        {!isFakeCondition && isCustomDuration &&
          <CustomedTextField
            className={classes.width120}
            error={!CommonUtils.isValidDuration(condition.duration) || (getIn(errors, `${name}[${index}].duration`) && getIn(touched, `${name}[${index}].duration`))}
            placeholder={t`hh:mm`}
            name={`${name}[${index}].duration`}
            onChange={(event: any) => setFieldValue(`${name}[${index}].duration`, event.target.value)}
            value={condition.duration}
            label={t`Duration`}
            optionValue="value"
            disabled={disabled}
            maxLength={5}
          />}
        <IconButton
          disabled={disabled}
          className={classes.iconBtnStyle}
          style={{ marginRight: inGroup ? 15 : 63, marginLeft: condition.operator?.includes("threshold") ? 40 : isFakeCondition ? 590 : 180 }}
          onClick={deleteCondition}
        >
          <Delete style={{ width: 20, height: 20 }} />
        </IconButton>
      </div>
      {!lastCondition ? null : isFakeCondition || isNextConditionFake ?
        (<Button disabled className={classes.addButton} >And</Button>) :
        (<GButton
          inGroup={inGroup}
          disabled={disabled}
          selected={condition.relation}
          onClick={(text: any) => setFieldValue(`${name}[${index}].relation`, text)}
        />)
      }
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`${name}[${index}].relation`}
        value="and"
        checked={condition.relation === "and"}
        disabled={disabled}
      />
      <input
        style={{ visibility: "hidden" }}
        type="radio"
        name={`${name}[${index}].relation`}
        value="or"
        checked={condition.relation === "or"}
        disabled={disabled}
      />
    </>
  );
};

export default Condition;
