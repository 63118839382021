import React, { useState } from "react";

import {
    Checkbox,
    Dialog,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import { t } from "ttag";
import { pick } from "lodash";
import { Close } from "../../icons";
import Button from "../../cool_widgets/Button";
import { IBaseProcedure } from '../../hooks/useScripts/types';

import useStyles from "./ImportProceduresDialog.style";

interface IProps {
  procedures: IBaseProcedure[];
  onClose: () => void;
  getBrandName: (brand: number | string) => string;
  addNewMultiScripts: (procedures: IBaseProcedure[]) => Promise<void>;
}

const ImportProcedures = (props: IProps) => {
    const classes = useStyles();

    const { procedures, onClose, getBrandName, addNewMultiScripts } = props;

    const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());

    const handleCheckboxChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        const selected = new Set(selectedItems);
        if (checked) {
            selected.add(index);
        } else {
            selected.delete(index);
        }

        setSelectedItems(selected);
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            setSelectedItems(new Set(procedures.map((procedure: IBaseProcedure, index: number) => index)));
        } else {
            setSelectedItems(new Set());
        }
    };

    const onSubmit = async () => {
        const selectedProcedures: any[] = [];

        Array.from(selectedItems).forEach((index: number) => {
            const procedure = procedures[index];
            if (!procedure) {
                return;
            }

            const newProcedure = pick(procedure, [
                'name',
                'description',
                'steps',
                'userSelections',
                'runningMode',
                'unitType',
                'conditions',
                'type',
                'stepsRunningMode'
            ]);

            selectedProcedures.push(newProcedure);
        });

        addNewMultiScripts(selectedProcedures);
        onClose();
    };

    return (
      <div>
        <Dialog
            disableEnforceFocus
            fullScreen={true}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="simple-dialog-title"
            open={true}
        >
          <div className={classes.dialogHeader}>
              <Typography className={classes.headerTitle}>{t`Import Procedures`}</Typography>
              <IconButton disableRipple onClick={onClose} className={classes.iconBtnStyle}><Close color="#7f7692" /></IconButton>
          </div>
          <Paper elevation={0} className={classes.paperTableContainer}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox" hidden>
                        </TableCell>
                        <TableCell
                            classes={{ root: classes.tableHeadCell }}
                            align="left"
                        >
                            {t`Rule Name`}
                        </TableCell>
                        <TableCell
                            classes={{ root: classes.tableHeadCell }}
                            align="left"
                        >{t`Brand`}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                  {procedures.length &&
                    <TableRow
                        hover
                        tabIndex={-1}
                        role="checkbox"
                    >
                        <TableCell padding="checkbox" hidden>
                            <Checkbox
                                color="default"
                                checked={selectedItems.size === procedures.length}
                                onChange={handleSelectAllChange}
                                inputProps={{ "aria-labelledby": `toggle all` }}
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                            align="left"
                        >
                            {t`All`}
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                            align="left"
                        >
                            {""}
                        </TableCell>
                    </TableRow>
                }
                {procedures.map((procedure: IBaseProcedure, index: number) => {
                  const { name = "", userSelections: { brand = -99 } = {} } = procedure;
                  const brandName = getBrandName(brand) || "All";
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`imported-procedure-${index}`}
                      role="checkbox"
                    >
                      <TableCell padding="checkbox" hidden>
                        <Checkbox
                            color="default"
                            onChange={handleCheckboxChange(index)}
                            checked={selectedItems.has(index)}
                            inputProps={{ "aria-labelledby": name }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                        align="left"
                      >
                        {name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                        align="left"
                      >
                        {brandName}
                      </TableCell>
                    </TableRow>
                  );
                })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className={classes.actionsContainer}>
              <Button onClick={onClose} marginRight white> {t`Cancel`}</Button>
              <Button onClick={onSubmit} disabled={!selectedItems.size}> {t`Import`}</Button>
          </div>
        </Dialog>
      </div>
    );
};

export default ImportProcedures;
