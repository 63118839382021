import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    view: {
        display: "flex",
        height: "100%"
    },
    contentArea: {
        width: "100%",
        height: "100vh",
        maxWidth: "calc(100% - 240px)",
        backgroundColor: "#fff"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        overflowY: "auto"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "20px"
    },
    boxTitle: {
        display: "flex",
        width: "100%",
        height: "40px",
        backgroundColor: "rgba(210, 210, 210, 0.5)",
        padding: "5px"
    },
    boxContent: {
        display: "flex",
        flex: 1,
        padding: "5px 20px",
        flexDirection: "column"
    },
    unitRow: {
        width: "100%",
        height: "70px",
        borderBottom: "1px solid #888",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    unitRowContent: {
        display: "flex",
        flex: 1,
        position: "relative",
        height: "100%"
    }
}));
export default useStyles;
