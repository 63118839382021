import React from "react";

function MobileAlert(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <defs>
        <path id="notifications_svg__a" d="M0 0h20.034v23H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(7.15 6)">
        <mask id="notifications_svg__b" fill="#fff">
          <use xlinkHref="#notifications_svg__a" />
        </mask>
        <path
          fill="#AAA2AA"
          d="M18.273 18.254H1.761V17.19a2.74 2.74 0 011.033-2.142l.435-.35a2.754 2.754 0 001.036-2.156V8.496a5.759 5.759 0 015.752-5.752c3.17 0 5.751 2.58 5.751 5.752v4.046a2.748 2.748 0 001.036 2.16l.437.353a2.73 2.73 0 011.032 2.133v1.066zm-6.318 2.124c-.4.69-1.14 1.12-1.938 1.122h-.002a2.253 2.253 0 01-1.93-1.122l-.21-.363h4.29l-.21.363zm6.387-6.703l-.436-.351a1.002 1.002 0 01-.376-.782V8.496a7.516 7.516 0 00-6.056-7.369 1.502 1.502 0 00-2.91 0 7.515 7.515 0 00-6.06 7.37v4.045a.999.999 0 01-.376.782l-.436.35A4.507 4.507 0 000 17.18v.831c0 1.107.898 2.004 2.004 2.004h4.137a4.007 4.007 0 007.747 0h4.142a2.003 2.003 0 002.004-2.004v-.82a4.509 4.509 0 00-1.692-3.516z"
          mask="url(#notifications_svg__b)"
        />
      </g>
    </svg>
  );
}

export default MobileAlert;