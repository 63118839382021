import React, { useMemo } from "react";

import clsx from "clsx";
import { Field, Formik } from "formik";
import {isNil} from "lodash";
import { t } from "ttag";
import * as yup from "yup";

import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { Select } from "material-ui-formik-components/Select";

import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import InputField from "../../screens/SiteManagement/InputField";

import useStyles from "./EditParamPopUp.style";

/**
 * An enum representing different value types.
 */
const valueTypes = {
  /** Represents a string value type. */
  "STRING":1,
  /** Represents an integer value type. */
  "INTEGER":2,
  /** Represents a float value type. */
  "FLOAT":3,
}

/** Computes the value based on the row configuration and provided values.
 * @param row - the row configuration object.
 * @param values - the values object from The form state manager.
 */
const getComputedValue = (row:any,values:any) => {
  // If a button is present and a buttonValue is provided, return the buttonValue
  if(row.button && row.buttonValue){
    return row.buttonValue
  }

  // Destructure value_type from row, default to 3 if not provided
  const {value_type = 3} = row

   // Determine the usedValue: prefer enumVal if it's not null or undefined, otherwise use value
  const usedValue = !isNil(values.enumVal) ? parseInt(values.enumVal) : values.value

  // If the value_type is INTEGER, parse and return the value as an integer.
  if(value_type === valueTypes.INTEGER){
    return parseInt(usedValue)
  }

  // If the value_type is FLOAT, parse and return the value as a float
  if(value_type === valueTypes.FLOAT){
    return parseFloat(usedValue)
  }

  // For any other value_type, return the usedValue as it is
  return usedValue
}

/**
 * Validation schema for a string value.
 *
 * @returns A Yup validation schema object for string values.
 */
const ValueSchemaString = yup.object().shape({
  value: yup.string()
});

/**
 * Validation schema for a numeric value.
 *
 * @returns A Yup validation schema object for numeric values.
 */
const ValueSchemaNum = yup.object().shape({
  value: yup.number()
});

/**
 * Validation schema for an enumerated numeric value.
 *
 * @returns A Yup validation schema object for enumerated numeric values.
 */
const ValueSchemaENum = yup.object().shape({
  enumVal: yup.number()
});

/**
 * Returns a validation schema condition based on the provided row configuration.
 *
 * @param row - The row configuration object.
 * @returns A Yup validation schema object with conditions based on the row's slider properties.
 */
const getValueSchemaCondition = (row: any) => {
  return yup.object().shape({
    value: yup
      .number()
      // Ensure the value is greater than the minimum slider value minus 1
      .moreThan(
        row ? row.slider.props.data.slider.min - 1 : 0, 
        row ? `Value should be >= ${row.slider.props.data.slider.min}` : ""
      )
      // Ensure the value is less than the maximum slider value plus 1
      .lessThan(
        row ? row.slider.props.data.slider.max + 1 : 0, 
        row ? `Value should be =< ${row.slider.props.data.slider.max}` : ""
      )
  });
}

/**
 * Returns the validation schema based on the provided row configuration.
 *
 * @param row - The row configuration object.
 * @returns The appropriate validation schema based on the row's value type and other properties.
 */
const getValidationSchema = (row:any) => {
 // If no row is provided, return the default number schema
  if(!row){
    return ValueSchemaNum
  }
// If the row is not an object, return the default number schema
  if(typeof row !== 'object'){
    return ValueSchemaNum
  }
// Destructure value_type and enum from the row
  const {value_type,enum:hasEnum} = row

  // If the row has an enum, return the enum schema
  if(hasEnum){
    return ValueSchemaENum
  }

  // Switch based on the value_type
  switch (value_type){
    case valueTypes.STRING:
      return ValueSchemaString
      case valueTypes.INTEGER:
        return getValueSchemaCondition(row)
        case valueTypes.FLOAT:
         return getValueSchemaCondition(row)
    default:
      return ValueSchemaNum
  }
}

export default function EditParamPopUp(props: any) {
  const { row, unit, reload, open, onClose, setValue, editedRowIndex, newValue, resetParams } = props;
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
  const setParamValue = useStoreActions((a) => a.units.setParamValue);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  const initialValues: any = {
    value: !isNil(newValue) ? parseInt(newValue) : (row && !row.enumVal) ? row.value : 0,
    enumVal: (!isNil(newValue) && row && row.enumVal) ? Object.keys(serviceParamTypes[row.enum]).find((key) => serviceParamTypes[row.enum][key] === newValue) :
      row && row.enumVal ?
        Object.keys(serviceParamTypes[row.enum]).find((key) => serviceParamTypes[row.enum][key] === row.enumVal) : null
  };

  /**
 * Handles the value change based on the type and sets the field value accordingly.
 *
 * @param setField - The function to set the field value.
 * @param type - The type of the value (default is 3).
 * @returns A function that processes the event and sets the field value.
 */
  const handleValueChange = (setField:any, type:number = 3) => (e:any) => {
    const {value} = e.target

    //If the type is string - pass it as it is.
    if(type === valueTypes.STRING){
      return setField('value',value)
    }

     // If the value is empty, allow it to be set
  if (value === '') {
    return setField('value',value);
  }

   // Allow the user to type negative values
   if(value === '-'){
    return setField('value',value);
  }

    //If the type is not string and the value cannot be converted to a number. do not change the field.
    if(isNaN(value)){
      return
    }
 
    //If the type is integer pass it as an Integer.
    if(type === valueTypes.INTEGER){
     return setField('value',parseInt(value))
    }
    //If the type is float the round and pass as float.
    if(type === valueTypes.FLOAT){
    // Allow intermediate float values like "0." or "1."
      if (/^-?\d*\.?\d{0,4}$/.test(value)) {
      return setField('value',value);
      }
      const roundedValue = parseFloat(parseFloat(value).toFixed(4))
      return setField('value',roundedValue)
    }

    return setField(value)

  }
  
  const onFormSubmit = (values: any) => {
    if (!allUnits?.[unit]?.canAddNote === false)
      return;
    handleClose();
    if (row.button) {
      resetParams(row.code, unit, row.buttonValue);
      return;
    }
    setParamValue({
        id: unit,
        serviceParamCode: row.code,
        value: getComputedValue(row,values)
      }
    ).then(() => {

      setValue(!isNil(values.enumVal) ?
        serviceParamTypes[row.enum][values.enumVal] : values.value, editedRowIndex);

    })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const enumValOptions = useMemo(() => {

    if(!row){
      return []
    }

    if(!serviceParamTypes[row.enum]){
      return []
    }

    const filteredValues = Object.entries(serviceParamTypes[row.enum]).filter(([,val]) => {
      return val !== "UNKNOWN";
    })

    return filteredValues.map(([key,val]) => (
      {
        value: key,
        label: val
      }
    ))

  },[serviceParamTypes,row]) 
  
  const unitObj = allUnits?.[unit];
  
  return (
    <Dialog
      key={`input-name ${row?.name}`}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      maxWidth="md"
      fullWidth
      classes={{ paper: clsx(classes.dialogStyle, { [classes.lessHeight]: row?.button }) }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{row?.name}</Typography>
        <IconButton disableRipple onClick={handleClose} className={classes.iconBtnStyle}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <Formik
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          enableReinitialize={true}
          validationSchema={getValidationSchema(row)}
          validateOnChange={false}
          validateOnBlur={false}

        >
          {({
            handleSubmit,
            errors,
            touched,
            values,
            resetForm,
            setFieldValue,
            ...restFormikProps
          }) => (
            <form onSubmit={handleSubmit}>
              <span className={classes.valueTitle}>{t`Current value`}</span>
              <div className={classes.oldValueBox}>
                {isNil(newValue) ? row?.enum ? row.enumVal : row.value :
                  newValue
                }
              </div>
              {!row.button && <>
                <span className={classes.valueTitle}>{t`Set value`}</span>
                {row?.enum ?

                  <Field
                    value={values.enumVal}
                    name="enumVal"
                    options={enumValOptions}
                    error={errors.enumVal && touched.enumVal ? true : false}
                    helperText={errors.enumVal && touched.enumVal ? errors.enumVal : ""}
                    component={Select}
                    variant="outlined"
                    margin="none"
                    style={{ maxHeight: "44px", height: "44px" }}
                  />
                  :
                  <Field
                    name={"value"}
                    value={values.value}
                    component={InputField}
                    variant="outlined"
                    error={errors.value && touched.value ? true : false}
                    helperText={errors.value && touched.value ? errors.value : ""}
                    onChange={handleValueChange(setFieldValue, row.value_type)}
                  />

                }
              </>}
              <div className={classes.dialogActions}>
                <Button
                  onClick={handleClose}
                  width={130}
                  white
                  marginRight
                >
                  {t`Cancel`}
                </Button>
                <Button
                  type="submit"
                  onMouseDown={(event: any) => event.preventDefault()}
                  className={classes.resetParamsButton}
                >
                  {row.button ? `${row.buttonText}` : t`Update System`}
                </Button>
              </div>
            </form>)}
        </Formik>
      </div>
    </Dialog >
  );
}
