import { createStyles, makeStyles } from "@material-ui/core";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => createStyles({
  menuHeader: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    color: "#fff",
    fontSize: "0.9375rem"
  },
  icon: {
    color: "#7f7692",
    paddingTop: "5px",
    fontSize: "1.5em"
  },
  menuTitle: {
    cursor: "pointer",
    display: "inline",
    lineHeight: "31px",
    verticalAlign: "middle",
    padding: "0 0.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "30ch",
    fontSize: "13px",
    fontWeight: 400,
    fontFamily: "Roboto"

  },
  selectedItem: {
    ...placeBackgroundColor(theme.palette.background.topDropDownDropDownSelected),
    "&>span": {
      fontWeight: "bold"
    }
  }
}));

export default useStyles;
