import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  tableContainer: {
    display: "flex"
  },
  table: {
    background: theme.palette.background.paper
  },
  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "20px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
  },
  overWritePadding: {
    padding: "0 0.8rem",
    cursor: "pointer",
    ...theme.tables.tableCell,
    lineHeight: 3
  },
  limitWidth: { wordBreak: "break-word", width: "15%" },
  breakLine: {
    wordBreak: "break-word", padding: "0 15px",
    height: "35px"
  },
  iconsColumnWidth: { maxWidth: "7rem", width: "5rem" },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },
  textAlign: {
    textAlign: "center"
  }
}));

export default useStyles;
