import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from "@material-ui/core";
import { ChevronRight, Close, ExpandMore, Search } from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import { Customer as sdkCustomer, Services as sdkServices } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BsInfoLg } from "react-icons/bs";
import { t } from "ttag";
import { useStoreState } from "../../../models/RootStore";
import useStyles from "./PermissionsTree.style";
import { QuestionMark } from "../../../icons/";
import LightTooltip from "@components/Tooltip/LightTooltip";
import SvgQuestionMark from "@icons/QuestionMark";

const createDataTree = (dataset: any) => {
    const hashTable = Object.create(null);
    dataset.forEach((aData: any) => hashTable[aData.id] = { ...aData, children: [] });
    const dataTree: any = [];
    dataset.forEach((aData: any) => {
        if (aData.parentId) hashTable[aData.parentId]?.children.push(hashTable[aData.id]);
        else dataTree.push(hashTable[aData.id]);
    });
    return dataTree;
};

interface IPermissionsTree {
    policies: any;
    setPolicies: any;
    disabled?: boolean;
}

const PermissionsTree = ({ policies, setPolicies, disabled = false }: IPermissionsTree) => {

    const allCustomers = useStoreState((state) => state.customers.allCustomers);
    const allSites = useStoreState((state) => state.sites.allSites);
    const allUnits = useStoreState((state) => state.units.allUnits);
    const allGroups = useStoreState((state) => state.groups.allGroups);
    const selections = useStoreState((state) => state.selections.selections);
    const userPref = useStoreState((state) => state.users.userPreferences);

    const [tree, setTree] = useState<any>({});
    const [searchTerm, setSearchTerm] = useState<any>("");
    const [flatTree, setFlatTree] = useState<any>({});
    const [policiesMap, setPoliciesMap] = useState<any>({});
    const [roles, setRoles] = useState<any>({});
    const [defaultExpanded, setDefaultExpanded] = useState<any>([]);
    const [expanded, setExpanded] = useState<any>([]);

    useEffect(() => {
        if (!selections.customerId) {
            return;
        }
        Promise.allSettled([
            sdkCustomer.getCustomerPoliciesAvailable(selections.customerId),
            sdkServices.getRoles()
        ])
            .then(([policies, roles]: any) => {
                if (policies.status === "fulfilled") {
                    const map = _.groupBy(policies.value, "entityId");
                    setPoliciesMap(map);
                }
                if (roles.status === "fulfilled") {
                    setRoles(roles.value);
                }
            });
    }, [selections.customerId]);

    useEffect(() => {

        if (!selections.customerId || _.isEmpty(policiesMap)) {
            return;
        }
        const selectedCustomer = selections.customerId;
        const expanded: any = new Set();
        expanded.add(selections?.customerId);
        const entitiesWithPols = Object.keys(policies);

        const data: any = {};
        data[selections.customerId] = {
            ...allCustomers[selections.customerId],
            hasPro: true,
            hasPermissions: true,
            removeIfEmpty: false,
            parents: [],
            name: (userPref?.nicknames && userPref.nicknames[selections.customerId]) || allCustomers[selections.customerId]?.name,
            type: t`Customer`,
            limitedOption: "",
            noneOption: false
        };

        const sitesArr = Object.values(allSites);
        for (let site of sitesArr) {

            if (site?.customer !== selectedCustomer || !policiesMap[site.id]) {
                continue;
            }

            data[site.id] = {
                ...site, parentId: site.customer,
                hasPro: true,
                hasPermissions: true,
                removeIfEmpty: false,
                parents: [site?.customer],
                name: site.name,
                type: t`Site`,
                limitedOption: "",
                noneOption: -1
            };

            data[`groups-${site.id}`] = {
                id: `groups-${site?.id}`,
                name: t`Groups`,
                parentId: site?.id,
                removeIfEmpty: true
            };

            data[`units-${site.id}`] = {
                id: `units-${site.id}`,
                name: t`Units`,
                parentId: site.id,
                removeIfEmpty: true
            };

            if (entitiesWithPols.indexOf(site.id) > -1) {
                expanded.add(site.id);
            }

        }

        const unitsArr = Object.values(allUnits);
        for (let unit of unitsArr) {
            if (unit?.customer !== selectedCustomer || !policiesMap[unit.id]) {
                continue;
            }
            data[unit.id] = {
                ...unit,
                parentId: `units-${unit.site}`,
                hasPro: false,
                hasPermissions: true,
                removeIfEmpty: false,
                parents: [unit?.site, unit?.customer, `units-${unit?.site}`],
                controlOnly: true,
                name: unit.name,
                type: "U",
                limitedOption: false,
                noneOption: ""
            };

            if (entitiesWithPols.indexOf(unit.id) > -1) {
                expanded.add(unit.site);
                expanded.add(`units-${unit.site}`);
            }
        }

        const groupsArr = Object.values(allGroups);
        for (let group of groupsArr) {
            if (group?.customer !== selectedCustomer || !policiesMap[group.id]) {
                continue;
            }
            data[group.id] = {
                ...group,
                parentId: `groups-${group.site}`,
                hasPro: false,
                hasPermissions: true,
                removeIfEmpty: false,
                parents: [group?.site, group?.customer, `groups-${group?.site}`],
                controlOnly: true,
                name: group.name,
                type: "G",
                isGroup: true,
                limitedOption: false,
                noneOption: ""
            };
            if (entitiesWithPols.indexOf(group.id) > -1) {
                expanded.add(group.site);
                expanded.add(`groups-${group.site}`);
            }
        }

        const treeData = createDataTree(Object.values(data));
        setTree(treeData[0]);
        setFlatTree(data);
        setDefaultExpanded(Array.from(expanded));
        setExpanded(Array.from(expanded));

    }, [selections.customerId, policiesMap]);

    useEffect(() => {
        if (!flatTree || _.isEmpty(flatTree)) {
            return;
        }
        if (!searchTerm) {
            setExpanded([...defaultExpanded]);
            const treeData = createDataTree(Object.values(flatTree));
            setTree(treeData[0]);
            return;
        }

        const filtered: any = Object.values(flatTree)?.reduce((data: any, item: any) => {
            if (!item.removeIfEmpty && item?.name?.toUpperCase()?.indexOf(searchTerm?.toUpperCase()) > -1 && !data[item.id]) {
                data[item.id] = item;

                for (let p of item?.parents) {
                    if (!data[p]) {
                        data[p] = flatTree[p];
                    }
                }
            }

            return data;
        }, {});

        setExpanded(Object.keys(filtered));
        const treeData = createDataTree(Object.values(filtered));
        setTree(treeData?.length ? treeData[0] : []);

    }, [searchTerm]);

    const updateRole = (event: any, entityId: any) => {
        event.preventDefault();
        event.stopPropagation();
        const tempPols = { ...policies };

        if (!event.target?.value) {
            const { [entityId]: temp, ...rest } = tempPols;
            setPolicies({ ...rest });
            return;
        }

        if (!!tempPols[entityId] && tempPols[entityId] !== -1) {
            const entityPolicies = { ...tempPols[entityId] };
            for (let app of Object.keys(entityPolicies)) {
                const policy = entityPolicies[app];
                policy.role = event.target.value;
            }
            tempPols[entityId] = entityPolicies;
            setPolicies(tempPols);
            return;
        }

        if (event.target?.value === -1) {
            const tempPolsEntities = Object.keys(tempPols);
            const toDelEnts = tempPolsEntities.filter((tempEntityId: any) => {
                const entityParents = flatTree[tempEntityId]?.parents;
                const isChild = entityParents?.indexOf(entityId) > -1;
                return isChild;
            });

            const filteredPols = tempPolsEntities.reduce((data: any, id: any) => {
                if (toDelEnts.indexOf(id) > -1) {
                    return data;
                }
                data[id] = tempPols[id];
                return data;
            }, {});

            filteredPols[entityId] = -1;
            setPolicies(filteredPols);
            return;
        }

        const roleObj = roles[event.target.value];

        const tempPolsEntities = Object.keys(tempPols);
        const toDelEnts = tempPolsEntities.filter((tempEntityId: any) => {
            const entityParents = flatTree[tempEntityId]?.parents;
            const isChild = entityParents?.indexOf(entityId) > -1;
            return isChild;
        });

        const filteredPols = tempPolsEntities.reduce((data: any, id: any) => {
            if (toDelEnts.indexOf(id) > -1) {
                return data;
            }

            data[id] = tempPols[id];
            return data;
        }, {});

        filteredPols[entityId] = {
            control: {
                role: event.target.value,
                level: roleObj.level,
                entityId,
                application: "control"
            }
        };

        setPolicies(filteredPols);
    };

    const toggleProPolicy = (event: any, entityId: any) => {
        event.preventDefault();
        event.stopPropagation();
        const tempPols = { ...policies };
        if (!!policies[entityId]?.professional) {
            const { professional: remove, ...rest } = policies[entityId];
            tempPols[entityId] = { ...rest };
            setPolicies({ ...tempPols });
            return;
        }

        tempPols[entityId] = {
            control: tempPols[entityId].control,
            professional: { ...tempPols[entityId].control, application: "professional" }
        };
        setPolicies({ ...tempPols });
    };

    const classes = useStyles();

    const renderTree = (nodes: any) => {
        if (nodes?.removeIfEmpty && !nodes?.children?.length) {
            return <></>;
        }

        const entityRole = policies[nodes.id] === -1 ? -1 : policies[nodes.id]?.control?.role;
        const entities = Object.keys(policies);
        const parentWithRole = entities?.length && nodes?.parents?.filter((p: any) => entities?.indexOf(p) > -1);
        const isDisabled = disabled || !!parentWithRole?.length;
        const parentPolicy = parentWithRole?.length && policies[parentWithRole[0]];
        const parentRoleName = parentPolicy && parentPolicy === -1 ? t`None` : (parentPolicy?.control ? roles[parentPolicy?.control.role]?.label : "");
        const nodeId = nodes.id || `${Math.round(Math.random() * 1000000)}`;

        return (
            <TreeItem
                key={nodeId}
                nodeId={nodeId}
                classes={{
                    content: classes.treeItemRoot,
                    label: classes.treeItemLabel
                }}
                label={
                    <div className={classes.itemRow}>
                        <div className={classes.itemName}>
                            {nodes.isGroup ?
                                <Tooltip
                                    placement="right"
                                    arrow
                                    title={
                                        <div style={{ whiteSpace: "pre-line" }}>
                                            {t`Units under this group` + "\n"}
                                            {nodes?.units?.map((unitId: any) => `${allUnits[unitId]?.name}\n`)}
                                        </div>
                                    }>
                                    <Typography >
                                        {nodes.type ? <p className={classes.nameContainer}>{nodes.name} (<sub className={classes.subName}>{nodes.type}</sub>)</p> : <p>{nodes.name}</p>}
                                    </Typography>
                                </Tooltip>
                                :
                                <Typography >
                                    {nodes.type ? <p className={classes.nameContainer}>{nodes.name} (<sub className={classes.subName}>{nodes.type}</sub>)</p> : <p>{nodes.name}</p>}
                                </Typography>
                            }
                        </div>
                        {nodes.hasPermissions && policiesMap[nodes.id]?.length &&
                            <div className={classes.rowControls}>
                                <Select
                                    className={classes.selectRoot}
                                    value={entityRole || parentRoleName || ""}
                                    onChange={(e) => updateRole(e, nodes.id)}
                                    displayEmpty
                                    variant="outlined"
                                    disabled={isDisabled}
                                >
                                    {nodes.noneOption !== false && <MenuItem value={nodes.noneOption}>{t`None`}</MenuItem>}
                                    {nodes.limitedOption !== false && <MenuItem value={nodes.limitedOption}>{t`Limited`}</MenuItem>}
                                    {parentRoleName && <MenuItem value={parentRoleName}>{parentRoleName}</MenuItem>}

                                    {
                                        Object.values(policiesMap[nodes.id]
                                            ?.sort((a: any, b: any) => roles[b.role]?.rank - roles[a.role]?.rank)
                                            ?.reduce((acc: any, p: any) => {
                                                acc[p.role] = roles[p.role]?.showInProfessional && <MenuItem value={p.role} key={`${nodes.id}-${p.role}`}>
                                                    {roles[p.role]?.label}
                                                </MenuItem>;
                                                return acc;
                                            }, {}) || {})
                                    }

                                </Select>

                                {!nodes.controlOnly &&
                                    <FormControlLabel
                                        style={{
                                            marginLeft: "30px"
                                        }}
                                        disabled={!policies[nodes.id]?.control || isDisabled}
                                        classes={{
                                            root: classes.chkboxRoot
                                        }}
                                        onClick={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                checked={!!policies[nodes.id]?.professional?.role?.length}
                                                onClick={(e: any) => toggleProPolicy(e, nodes.id)}
                                                name="checkedB"
                                                color={"primary"}
                                                classes={{
                                                    root: classes.chkboxRoot,
                                                    checked: classes.chkboxRoot,
                                                    disabled: classes.diabledChkbox
                                                }}
                                            />
                                        }
                                        label={t`Pro`}
                                    />}
                            </div>}

                    </div>
                }
            >
                {Array.isArray(nodes.children) ? nodes.children.map((node: any) => renderTree(node)) : null}
            </TreeItem>
        );
    };

    return (
        <div className={classes.permissionsBox}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <Typography className={classes.headerTitle}>
                        {t`Permission Settings`}
                    </Typography>
                    <LightTooltip title={
                        <>
                            <div>{t`Customer Administrator - Authorized to access all sites associated with the customer.`}</div>
                            <div>{t`Site Administrator - Permitted to access specific sites.`}</div>
                            <div>{t`Customer Installer - User who is allowed to create new sites but needs the site admin permission.`}</div> 
                        </>
                    }>
                            <IconButton disableRipple classes={{ root: classes.iconButton }}>
                                <SvgQuestionMark style={{ color: "#aaa2aa" }} />
                            </IconButton>
                    </LightTooltip>  
                </div>
                <TextField
                    value={searchTerm || ""}
                    onChange={(e: any) => setSearchTerm(e.target.value)}
                    variant={"outlined"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end" style={{ cursor: "pointer" }}>
                                {!!searchTerm.length && <Close onClick={() => setSearchTerm("")} />}
                            </InputAdornment>
                        )
                    }}
                    size="small"
                />
            </div>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMore />}
                expanded={expanded}
                defaultExpandIcon={<ChevronRight />}
                onNodeToggle={(e, ids) => setExpanded(ids)}
            >
                {renderTree(tree)}
            </TreeView>
        </div>
    );
};

export default PermissionsTree;
