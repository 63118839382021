import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100%",
    flex: 1,
    minHeight: "100%",
    backgroundColor: "#fff",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  },
  contentArea: {
    width: "100%",
    maxWidth: "calc(100% - 240px)",
    height: "calc(100% - 95px)",
    maxHeight: "calc(100% - 95px)",
    minWidth: "100%",
    background: "white"
  },
  progressBarContainer: {
    height: '5px',
  },
  iosPadding: {
    height: "calc(100% - 135px)",
    maxHeight: "calc(100% - 135px)"
  },
  iconBtn: {
    borderRadius: "6px", height: 30, width: 30, padding: 0, marginBottom: -4,
    "&:disabled": {
      opacity: 0.5
    }
  },
  shareButton: {
    minHeight: 50,
    maxHeight: 50,
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "6px",
    background: "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    width: "90%",
    margin: "20px",
    marginLeft: "5%",
    // marginBottom: 25,
    "&:disabled": {
      color: "#fff",
      opacity: 0.8
    },
    "&:hover": {
      boxShadow: "none"
    },
    "&:focus": {
      boxShadow: "none"
    }
  },  
  auditsRowsContainer: {
    height: "calc(100% - 270px )",
    minHeight: "calc(100% - 270px )",
    maxHeight: "calc(100% - 270px )",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    flexFlow: "row wrap"
  },
  auditRow: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    height: "60px",
    padding: "0 12px",
    borderBottom: "solid 1px #e5e2e5"
  },
  auditContainer: {
    paddingLeft: 15,
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    maxWidth: "100%"
  },
  auditCard: {
    display: "flex",
    flexFlow: "row nowrap",
    height: 19,
    alignItems: "center",
    maxWidth: "calc(100%)",
    justifyContent: "space-between"
  },
  auditAction: {
    flex: 1,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 600,
    color: "#545964",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "calc(100vw - 220px)",
    paddingRight: 5
  },
  auditSource: {
    height: "100%",
    minWidth: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  auditDateContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap"
  },
  auditDate: {
    width: "120px",
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#15111f"
  },
  arrow: {
    height: 8,
    width: 12,
  },
  transformRight: {
    transform: "rotate(-90deg)"
  },
  transformLeft: {
    transform: "rotate(90deg)"
  },
  alignCenter: {
    alignSelf: "center"
  },
  auditDetails: {
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#545964",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "70vw"
  },
  title: {
    marginLeft: "15px",
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    color: "#15111f"
  },
  titleIcon: {
    width: 25,
    height: 25
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "53px",
    borderBottom: "solid 1px #e5e2e5",
    padding: "0 20px"
  },
  datePickerButton: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    height: 50,
    borderBottom: "solid 1px #e5e2e5"
  },
  datePickerText: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 600,
    color: "#29132e"
  }
}));

export default useStyles;
