import { alpha } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";
const groupPanelStyles = (theme: any) =>
  createStyles({
    groupView: {
      width: "calc(100% - 9px)",
      marginTop: "43px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255,0.26)",
      },
    },
    firstRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      alignContent: "center",
      boxSizing: "border-box",
      padding: "0 20px",
    },
    siteTemp: {
      width: "55px",
      opacity: 0.5,
      fontSize: 26,
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
    },
    scaleStyle: {
      fontSize: 14,
    },
    nameSection: {
      display: "flex",
      flexFlow: "column nowrap",
      maxWidth: "calc(100% - 140px)",
      padding: "0 20px",
      alignItems: "center",
      minHeight: "60px",
      justifyContent: "space-between",
      "& p": {
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: "#fff",
        fontFamily: "Roboto",
        fontSize: "22px",
      },
    },
    secondRowContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent:'center',
    },

    placeholderItem:{
      height:20,
      width:55
    }

  });

export default groupPanelStyles;
