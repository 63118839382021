import { alpha, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
    width: "100%",
    lineHeight: "6rem",
    borderBottom: "1px rgb(87, 69, 92) solid",
    padding: "0 10px 0 10px",
    boxSizing: "border-box",
  },

  '[class^="NavigationBar"]': {
    padding: "0",
  },

  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  heading: {
    width: "100%",
  },

  alertTypesContainer: {
    width: "100%",
    height: "calc(100vh - 80px)",
    padding: "0 20px",
    display: "flex",
    overflow: "hidden",
  },

  alertTypesCard: {
    width: "inherit",
  },

  alertTypesItem: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginBottom: "0.5rem",
    border: "1px solid lightgray",
  },

  userName: {
    margin: "1rem 1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    "& >svg": {
      marginRight: "0.5rem",
    },
  },
  alertGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    margin: "1rem",
  },

  switch: {
    display: "flex",
    justifyContent: "flex-end",
  },

  icons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  content: {
    margin: "1rem",
  },
  userHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    margin: "1rem",
  },
  chosenAlert: {
    backgroundColor: "rgb(236, 235, 239)",
  },
  userIcon: {
    backgroundColor: "lightgray",
  },
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
  },
  alertsSettingsContainer: {
    height: "calc(100% - 140px)",
    display: "flex",
    padding: "1rem",
    paddingTop: 0,
    overflow: "hidden",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem 1.5rem",
    background: "rgb(236, 235, 239)",
  },
  tableContainer: {
    height: "calc(100vh - 175px)",
    padding: "0",
    width: "auto",
  },
  table: {
    padding: "0 1rem",
    background: theme.palette.background.paper,
    borderSpacing: "0 10px",
  },
  tableHeadRow: {
    height: "3rem",
  },
  overWritePadding: {
    fontSize: "0.8rem",
    padding: "0 0.8rem",
  },
  overrideFontSize: {
    fontSize: "2rem",
    "& svg": {
      width: "38px",
    },
  },
  tableHeadCell: {
    padding: "16px 15px 0",
    ...theme.tables.headerCell,
    backgroundColor: "white",
    fontWeight: 400,
    textTransform: "none",
    borderBottom: "none",
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
    border: "solid 1px",
    backgroundColor: "#EEE",
  },
  tableBodyRowHover: {
    backgroundColor: "#0000ff",
  },
  tableBodyRow: {
    border: "solid 1px",
    backgroundColor: "#f8f8f8",
    "&:hover": {
      backgroundColor: "#4499ff09 !important",
    },
    "& td": {
      borderTop: "solid 1px #ccc",
      borderBottom: "solid 1px #ccc",
    },
    "& td:first-child": {
      borderBottomLeftRadius: 5,
      borderTopLeftRadius: 5,
      borderLeft: "solid 1px #ccc",
    },
    "& td:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderRight: "solid 1px #ccc",
    },
    "&:hover button": {
      display: "unset",
    },
  },
  grayCheckbox: {
    "&$checked": {
      color: "#736973",
    },
  },
  selectedUnit: {
    backgroundColor: "#edebed",
  },
  tableContainer1: {
    height: "calc(100vh - 185px)",
    margin: "auto",
    width: "95.5%",
  },
  modeHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  alertGroupHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    margin: "1rem",
  },
  textField: {
    marginLeft: "10px",
    " & div": {
      height: "30px",
      width: "160px",
    },
  },
  plusIcon: {
    "& svg ": {
      "& g": {
        "& rect": {
          fill: "none",
        },
      },
    },
    "&:hover": {
      "& $addAlertIcon": {
        display: "none",
      },
      "& $addOnHoverIcon": {
        display: "inline",
      },
    },
  },
  container: {
    minHeight: "-webkit-fill-available",
    padding: "1rem",
    overflow: "auto",
  },
  user: {
    display: "flex",
    justifyContent: "space-between",
  },
  userContainer: {
    margin: "0 1rem 0 1rem",
    borderBottom: "1px solid lightgrey",
    "&:first-of-type": {
      margin: "-1rem 1rem 0 1rem",
    },
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  addContactIcon: {
    marginRight: "2px",
  },
  contactActionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  innerTableContainer: {
    marginBottom: "2rem",
  },
  tableData: {
    padding: "6px 24px 6px 11px",
  },
  root: {
    width: 56,
    height: 24,
    padding: "5px 7px",
    marginRight: -6,
  },
  switchBase: {
    left: 6,
    padding: 1,
    color: "#D8D8D8",
    "&$checked": {
      left: 0,
      transform: "translateX(26px)",
      color: theme.palette.primary.main,
      "& + $track": {
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
        border: "none",
        opacity: 1,
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
    },
  },
  thumb: {
    width: 24,
    height: 23,
    boxShadow: "none",
  },
  track: {
    borderRadius: 50 / 2,
    backgroundColor: "rgba(213, 210, 213, 0.6)",
    opacity: 1,
    height: 15,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "20px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px",
  },
  checked: {},
  contactIcon: {
    color: "#AAAAAA",
    border: "solid 1px",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    background: "#E7E7E7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -8,
  },
  tooltip: {
    color: "#7DC1FF",
    cursor: "pointer",
    width: "fit-content",
  },
  inputStyle: {
    height: 40,
    padding: "0 6px",
  },
  editIcons: {
    display: "flex",
    width: 100,
    height: 30,
    "& button": {
      display: "none",
    },
  },
  popover: {
    width: 400,
    height: 415,
  },
  contactsTooltip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginRight: 4,
    "& span": {
      color: "#ccc",
      fontWeight: 400,
    },
  },
  svgUserIcon: {
    marginLeft: 7,
  },
  title: {
    padding: "20px 0",
    margin: "0 1rem",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "26px",
    lineHeight: "30px",
    color: "#545964",
  },

  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px",
    },
  },
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
    marginRight: "40px",
  },

  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px",
  },
  barRightSide: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));
export default useStyles;
