import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { List } from '@material-ui/core';
import { useStyles } from '../PropsFilter.style';
import PropsListItem from './PropsListItem';

/**
 * @typedef {Object} Item
 * @property {string} code - The code of the item.
 * // Add other properties of the item as needed
 */

/**
 * @typedef {Object} PropsListDraggableProps
 * @property {Item[]} items - The list of items to render.
 * @property {string} type - The type of items being rendered.
 * @property {string} flag - A flag to indicate some state (description needed).
 * @property {Function} onDragEnd - Callback function when dragging ends.
 * @property {Function} onItemClick - Callback function when an item is clicked.
 */

/**
 * Component for rendering a draggable list of items.
 *
 * @param {PropsListDraggableProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const PropsListDraggable = ({ items, type, flag, onDragEnd, onItemClick }) => {
	const styles = useStyles();

	/**
     * Handles item click events.
     *
     * @param {string} code - The code of the clicked item.
     * @param {boolean} [forcedValue] - A forced value to set (optional).
     * @returns {Function} Event handler function.
     */
	const handleItemClick = (code, forcedValue) => (event) => {
		const { checked } = event.target;
		const newValue = forcedValue !== undefined ? forcedValue : checked;
		onItemClick(code, flag, newValue);
	};

	return (
		<List
			id={`selectedParams-${type}`}
			disablePadding={true}
			className={styles.filterList}
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="list">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{items.map((item, index) => {
								const { code } = item;
								return (
									<Draggable key={code} draggableId={`${code}`} index={index}>
										{(provided) => (
											<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
												<PropsListItem
													item={item}
													id={`item-${code}`}
													divider
													key={code}
													flag={flag}
													onChange={handleItemClick(code)}
													className={styles.moveCursor}
												/>
											</div>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</List>
	);
};

PropsListDraggable.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.oneOfType([PropTypes.string.isRequired,PropTypes.number.isRequired]),
		})
	).isRequired,
	type: PropTypes.string.isRequired,
	flag: PropTypes.string.isRequired,
	onDragEnd: PropTypes.func.isRequired,
	onItemClick: PropTypes.func.isRequired,
};

export default PropsListDraggable;
