import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({

  tableHeadCell: {
    ...theme.tables.headerCell
  },
  tableCell: {
    ...theme.tables.tableCell
  },
  largeWidth: {
    width: "50%"
  },
  smallWidth: {
    width: "20%"
  },
  table: {
    background: theme.palette.background.paper
  },
  tableContainer: {
    display: "flex",
    overflowY: "auto"
  },
  modalActions: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  middleModalActions: {
    padding: "0 20px",
    display: "flex",
    justifyContent: "flex-end"
  },
  settingsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "90%",
    backgroundColor: "#f6f6f7",
    margin: "30px",
    padding: "0 10px"
  },
  selectStyle: {
    lineHeight: "35px",
    width: "200px",
    height: "35px",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    "&>div": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: "32px",
      paddingLeft: "14px"
    }
  },
  arrowDownIcon: {
    top: 15,
    right: 9
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    padding: 0
  },
  closeBtnStyle: {
    width: 30,
    height: "100%",
    padding: 0,
    "&:hover": {
      background: "none"
    }
  },
  powerIcon: {
    paddingTop: 4,
    paddingLeft: 4
  },
  dialogHeader: {
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "center",
    height: 60,
    width: "100%",
    minHeight: 60,
    alignItems: "end"
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 0,
    width: "100%"
  },
  addIcon: {
    color: "#aaa2aa",
    marginLeft: "3px"
  },
  hidden: {
    display: "none"
  },
  systemTitleContainer: {
    ...theme.tables.headerCell,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "15px"
  },
  systemContainer: {
    minHeight: 45,
    display: "flex",
    flexFlow: "column nowrap",
    marginBottom: 10
  },
  rowContainer: {
    height: 50,
    padding: "0 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  disabled: {
    opacity: 0.5
  },
  tab: {
    // width: "350px"
  },
  indicatorColor: {
    backgroundColor: theme.palette.primary.main
  },
  root: {
    textTransform: "none",
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    width: "100%",
    "&:focus": {
      fontWeight: "bold"
    },
    "&:selected": {
      fontWeight: "bold"
    }
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 15,
    marginBottom: "15px"
  },
  createSpan: {
    overflowY: "auto",
    minHeight: "160px"
  },
  editSpan: {
    height: "75%"
  },
  outlinedInputRoot: {
    fontSize: 14,
    lineHeight: "normal",
    fontWeight: "normal",
    width: "400px",
    "& input": {
      width: "100%",
      height: "40px",
      padding: 0,
      paddingLeft: "15px",
      paddingRight: "15px"
    },
    "& input:focus + fieldset": {
      "&$notchedOutline": {
        border: "1px solid #4b1c46"
      }
    },
    "& ::placeholder": {
      fontSize: 14,
      color: "#545964",
      lineHieght: "normal",
      fontWeight: "normal"
    }
  },
  requiredError: {
    color: "#FF0000",
    margin: "5px 0"
  },
  inputLabel: {
    marginBottom: "5px"
  },
  textField: {
    width: "100%"
  },
  inputFieldStyle: {
    minWidth: "10ch",
    height: "44px",
    width: "100%",
    "& label": {
      color: "rgba(255, 255, 255, 0.7)",
      "&focus": {
        color: "rgba(255, 255, 255, 0.7)"
      }
    },
    "& div": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    },
    "& MuiInputBase-root": {
      color: "white"
    }
  },
  multiPowerMetersError: {
    marginRight: "8px"
  },
  loaderHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
}));
export default useStyles;
