import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
    title: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 600,
        color: '#29132e',
        marginRight: 18,
        whiteSpace: 'nowrap'
    },
    filtersContainer: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        minHeight: 50,
        borderBottom: 'solid 1px #e5e2e5'
    },
    filterItemsContainer: {
        display: "flex",
        alignItems: "center",
        maxWidth: "calc(100% - 120px)",
        overflow: "auto",
        height: "100%",
        width: "100%"
    },
}));

export default useStyles;
