import {
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import { SwitcherOffIcon as SvgPowerOff, SwitcherOnIcon as SvgPowerOn } from "../../../../svgComponents";
import unitPanelStyle from "../UnitPanel/UnitPanel.style";
import SensorValue from "./SensorValue";
import SensorIcon from "@icons/SensorIcon";
import { ScheduleOffIcon } from "@icons/index";

const sensorsAllowedToHaveSchedules: any = {
  5: true,
  6: true,
  129: true,
  130: true
};

const SensorPanel: React.FC<any> = (props) => {
  const {
    sensor,
    types,
    isCelsius,
    siteTemp,
    tempSymbol,
    timezoneOffset
  } = props;
  const history = useHistory();

  const useStyles = makeStyles(unitPanelStyle);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const { updateSensorValue, _storeUpdateSensor } = useStoreActions((actions) => actions.sensors);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const classes = useStyles();
  const { name,
    type,
    readingValue = 0,
    readingValueTimestamp, userData = {}, isWritable, id, schedules = [], canControl = true } = sensor || {};

  const hasSchedules = schedules?.length > 0;
  const { sensorTypes, sensorMeasurementUnits } = types;
  const sensorType = sensorTypes[type] || {};
  const { enableMinMaxSelection, enableNormalModeSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin } = sensorType;
  const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
  const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;

  const timeToString = (timestamp: any) => {
    return moment(timestamp).utcOffset(timezoneOffset).format(timeFormat);
  };
  const dateToString = (timestamp: any) => {
    return moment(timestamp).utcOffset(timezoneOffset).format(dateFormat);
  };

  const handleOnOff = () => {
    const updateValueTo = +readingValue === 0 ? 1 : 0;
    updateSensorValue({ id, data: { value: updateValueTo } })
      .then(() => {
        _storeUpdateSensor({ id, data: { readingValue: updateValueTo } });
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });

  };

  return (
    <div className={classes.unitView} >
      <div className={classes.firstRowContainer}>
        <Typography className={classes.siteTemp}>{(isCelsius ? siteTemp.C || "" : siteTemp.F || "") + " "}<span className={classes.scaleStyle}>{siteTemp.C && tempSymbol || ""}</span></Typography>
        <div className={classes.nameSection}>
          <Typography >{name}</Typography>
        </div>
        {/* this div is to keep name in middle instead of right */}
        <div className={classes.powerContainer}>
          {(sensorUnit === "Open/Close" && isWritable) && <>
            <IconButton disabled={!canControl} className={classes.powerIconStyle} onClick={handleOnOff}>
              {+readingValue === 0 ? <SvgPowerOff className={classes.powerOnButtonClick} /> : <SvgPowerOn className={classes.powerOffButtonClick} />}
            </IconButton>
            <Typography className={classes.powerTextStyle}>{+readingValue === 1 ? t`POWER OFF` : t`POWER ON`}</Typography>
          </>}
        </div>
      </div>
      <div className={classes.secondRowContainerSensor}>
        <div className={classes.controlDiv}>
          <div className={classes.sensorWrapperStyle} >
            <div className={clsx(classes.innerControlsSensor)}>
              {type !== 130 && <div className={classes.sensorIcon}>
                <SensorIcon type={type} width={70} height={70} readingValue={+readingValue}/>
              </div>}
              <SensorValue
                id={id}
                value={+readingValue}
                unit={activeMeasurementUnit || ""}
                enableRatio={enableMinMaxSelection}
                valueMax={valueMax}
                valueMin={valueMin}
                userMin={userData?.rangeMin}
                userMax={userData?.rangeMax}
                enableNormal={enableNormalModeSelection}
                userNormal={userData?.normalStateValue}
                isCelsius={isCelsius}
                isWritable={isWritable}
                type={type}
                disabled={!canControl}
              />
              <div className={clsx(classes.timeDateStyle, { [classes.addTopMargin]: type === 130 })}>
                <div className={clsx(classes.dataTimeFont, classes.timeStyle)}> {timeToString(readingValueTimestamp)}</div>
                <div className={classes.dataTimeFont}> {dateToString(readingValueTimestamp)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.lastRowContainer}>
        {
          (sensorsAllowedToHaveSchedules[type] && isWritable) && (
            <div className={classes.iconContainer}>
              <IconButton
                  disableFocusRipple
                  disableRipple
                  className={classes.mainIconsStyle}
                  name="schedule"
                  onClick={() => history.push(`/sensors/${id}/schedules`)}
                  >
                  <ScheduleOffIcon />
                  {hasSchedules && <span className={classes.badge}>{schedules.length}</span>}
                </IconButton>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default SensorPanel;
