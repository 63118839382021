import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import AddRule from "../../components/AddRule/AddRule";
import Delete from "../../components/Delete/Delete";

import { ArrowDown } from "../../icons";
import { EditIcon } from "../../logos";

import useStyles from "./ClickableRow.style";

const Groups = (props: any) => {
  const {noIcon = false, canEdit, canDelete, index, name, unitsNumber, onEdit, onDelete, selected, onSelect, collapse, onClick, removeMargin} = props;
  const classes = useStyles();

  return (
    <div
      key={t`group-item-${index}`}
      className={clsx(classes.clickableRow, {[classes.selected]: selected, [classes.removeMargin]: removeMargin})}
      onClick={onClick}
    >
      <Typography className={classes.groupName}>{name}</Typography>
      <div className={classes.leftSection}>
        <Typography className={clsx(classes.unitsNumber, {[classes.addRightMargin] : collapse})}>{unitsNumber ? unitsNumber : ""}</Typography>
        {noIcon ? null :  (collapse ?
        (<IconButton className={clsx(classes.arrowIconBtn, {[classes.expandOpen]: selected})}>
        <ArrowDown className={classes.arrowIcon}/>
        </IconButton>) :
        <>
        <IconButton disabled={!canEdit} className={classes.iconBtn} onClick={(event: any) => onEdit(event)}>
          <EditIcon />
        </IconButton>
        <Delete
          disabled={!canDelete}
          type={t`Group`}
          object={{name}}
          detach={onDelete}
        />
        </>)
       }
      </div>
    </div>
  );
};

export default Groups;
