import * as React from "react";

function SvgCheck(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 11" {...props}>
      <defs>
        <filter id="check_svg__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.294118 0 0 0 0 0.109804 0 0 0 0 0.274510 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#check_svg__a)"
        transform="translate(-361 -241)"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M376.81 241.254a.65.65 0 00-.913 0l-9.145 9.084-4.02-3.964c-.252-.25-.662-.25-.914 0s-.252.655 0 .905l4.48 4.415a.656.656 0 00.914 0l9.598-9.534a.636.636 0 000-.905c-.253-.25.252.25 0 0z"
          fill="#FDFDFE"
        />
      </g>
    </svg>
  );
}

export default SvgCheck;
