import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '24.2px',
  },
}));

const InformationText = ({ value, style }) => {
  const classes = useStyles();
  return <div className={classes.text} style={{...style}}>{value}</div>;
};

export default InformationText;
