import * as Yup from 'yup';

/**
 * Represents the schema for validating login credentials.
 *
 * @typedef {object} LoginSchema
 * @property {string} password - The user's password. It is required and must not be empty.
 * @property {string} username - The user's username. It is required, must not be empty, and must have a minimum length of 6 characters and a maximum length of 50 characters.
 */
export const LoginSchema = Yup.object({
	password: Yup.string().required(),
	username: Yup.string().required().min(6, 'Too Short!').max(50, 'Too Long!')
});

/**
 * Redirects the user to the control application based on the current environment.
 *
 * @param {object} urls - An object containing the URLs for the control application in different environments.
 * @property {string} urls.dev - The URL for the development environment.
 * @property {string} urls.stg - The URL for the staging environment.
 * @property {string} urls.prod - The URL for the production environment.
 */
export const goToControlApp = (urls) => {
	const env = determineEnvironment(); // The current environment (dev, stg, or prod).
	const controlUrl = urls[env];// The URL for the control application in the current environment.
	window.location.href = controlUrl;// Redirects to the control application URL.
};

/**
 * Determines the current environment based on the window's origin.
 *
 * @returns {string} The environment identifier (dev, stg, or prod).
 */
export const determineEnvironment = () => {
	// Check if the environment is development based on the presence of "-dev" or "localhost" in the origin.
	if (window.location.origin.indexOf('-dev') !== -1 || window.location.origin.indexOf('localhost') !== -1) {
		return 'dev';
		// Check if the environment is staging based on the presence of "-stg" in the origin.
	} else if (window.location.origin.indexOf('-stg') !== -1) {
		return 'stg';
		// Default to the production environment if no other matches are found.
	} else {
		return 'prod';
	}
};


/**
 * Extracts the login state from the provided state object.
 *
 * @param {object} state - The state object containing the login information.
 * @property {object} state.config - The configuration object for the application.
 * @property {boolean} state.isLoggedIn - A flag indicating whether the user is logged in.
 * @returns {object} An object containing the login state.
 * @property {object} loginState.configFile - The configuration object for the application.
 * @property {boolean} loginState.isLoggedIn - A flag indicating whether the user is logged in.
 */
export const getLoginState = (state) => {
	/**
     * The extracted login state.
     *
     * @typedef {object} LoginState
     * @property {object} configFile - The configuration object for the application.
     * @property {boolean} isLoggedIn - A flag indicating whether the user is logged in.
     */
	const loginState = {
		configFile: state.config ?? {},
		isLoggedIn: state.isLoggedIn
	};

	return loginState;
};

/**
 * Extracts the login-related actions from the provided actions object.
 *
 * @param {{doLogin: Function, verify2FA: Function}} actions - An object containing the login-related actions.
 * @returns {{doLogin: Function, verify2FA: Function}} - An object containing the extracted login-related actions.
 */
export const getLoginActions = ({ doLogin, verify2FA }) => {
	/**
     * The extracted login-related actions.
     *
     * @typedef {{doLogin: Function, verify2FA: Function}} LoginActions
     * @property {Function} doLogin - The action function for handling login.
     * @property {Function} verify2FA - The action function for verifying two-factor authentication.
     */
	const loginActions = {
		doLogin,
		verify2FA
	};

	return loginActions;
};
