import { Grid } from "@material-ui/core";
import React from "react";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { useStoreState } from "../../models/RootStore";
import useStyle from "./OneTenant.style";
import PowerMetersMappingDialog from "./PowerMetersControl";

const PPDSettings = (props: any) => {
    const classes = useStyle();
    const allSites = useStoreState((state) => state.sites.allSites);
    const { siteId } = useStoreState((state) => state.selections.selections);

    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header
                    path={[t`Power Distribution`]}
                    showDateRangePicker={false}
                    hideUnitSelection={true}
                    hideSystemSelection={true}
                    customGeneralNames={{ site: t`Select Site` }}
                    screenTitle="powerDistribution"
                    noRangeLimit={true}
                />

                <Grid container={true} className={classes.tableContainer} style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                        siteId ? (
                            <PowerMetersMappingDialog
                                site={allSites[siteId]}
                            />
                        )
                            : <FilterRequire type={t`site`} />
                    }
                </Grid>
            </div>
        </div>
    );
};

export default PPDSettings;
