import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "45px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.31)",
    borderRadius: "8px",
    padding: "2px 8px",
    backgroundColor: "#9e9d9d",
    "& *": {
      color: "#fff",
      userSelect: "none"
    },
    cursor: "pointer",
    userSelect: "none"
  },
  unitOn: {
    border: `2px solid #000`,
    "& *": {
      fontWeight: "bold",
      color: "#fff"
    }
  },
  infoSection: {
    display: "flex",
    width: "100%",
    marginTop: "5px"
  },
  iconContainer: {
    display: "flex",
    width: "33%",
    height: "100%",
    alignItems: "center"
  },
  infoContainer: {
    display: "flex",
    width: "67%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  isHeat: {
    backgroundColor: theme.palette.colors.carminePink || "#f05146"
  },
  isCool: {
    backgroundColor: theme.palette.colors.summerSky || "#35a8e0"
  },
  disconnected: {
    background: "repeating-linear-gradient(45deg,#2d3c43,#2d3c43 10px,#33444c 10px,#33444c 20px)"
  },
  isOther: {
    backgroundColor: "#266101"
  },
  isEdit: {
    borderColor: "#f00"
  },
  isNewUnit: {
    border: `2px solid #000`,
    backgroundColor: "#9e9d9d",
    "& *": {
      color: "#fff"
    }
  },
  unitTemp: {
    fontSize: "18px"
  },
  roomTemp: {
    fontSize: "12px"
  },
  isSelected: {
    border: `2px solid #000`
  },
  link: {
    backgroundColor: theme.palette.primary.dark
  },
  linkText: {
    fontSize: `16px`,
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textDecoration: "underline",
    color: theme.palette.primary.mid
  }
}));
export default useStyles;
