import * as React from 'react';

function SvgSmallSideArrow(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 13" {...props}>
      <path
        d="M1.995 12.264l5.245-5.16a.833.833 0 000-1.21L1.995.753a.877.877 0 00-1.235 0 .833.833 0 00-.26.595c0 .213.087.425.26.595l4.66 4.565-4.638 4.544a.83.83 0 00-.26.595c0 .212.086.425.26.594a.87.87 0 001.213.022z"
        fill="#7F7692"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSmallSideArrow;
