import React from 'react';
import useStyle from './FilterItems.style';
import { Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface FilterItemsProps {
    type: 'users' | 'source' | 'actions';
    selectedFilters: { [key: string]: any };
    getFilterName: (category: string, filterName: string) => string | undefined;
    handleRemoveFilter: (type: 'users' | 'source' | 'actions', filterName: string) => (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const FilterItems: React.FC<FilterItemsProps> = ({ type, selectedFilters, getFilterName, handleRemoveFilter }) => {
    const classes = useStyle();

    return (
        <>
            {Object.keys(selectedFilters[type] || {}).map((filterName) => (
                <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
                    <Typography className={classes.filterItemText}>{getFilterName(type, filterName)}</Typography>
                    <IconButton
                        disableRipple
                        className={classes.filterItemIconBtn}
                        onClick={handleRemoveFilter(type, filterName)}
                    >
                        <Close className={classes.filterItemCloseIcon} />
                    </IconButton>
                </div>
            ))}
        </>
    );
};

export default FilterItems;