import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, Typography } from '@material-ui/core';

import SvgArrow from '../../../../../../../../../../../icons/Arrow';

import useStyles from '../../../../../../AddEditDialog.style';
import styles from './SpecificDaySelector.module.css';
import clsx from 'clsx';

const ordinal_suffix_of = (i) => {
	let j = i % 10,
		k = i % 100;
	if (j === 1 && k !== 11) {
		return i + 'st';
	}
	if (j === 2 && k !== 12) {
		return i + 'nd';
	}
	if (j === 3 && k !== 13) {
		return i + 'rd';
	}
	return i + 'th';
};

//this should only be calculated once and not on every render.
const specificDaySelectOptions = new Array(27).fill(0).map((_, i) => { 
	const dayName = ordinal_suffix_of(i + 2);
	return <MenuItem key={i + 2} value={i + 2}>{dayName}</MenuItem>;
});


//todo: add jsdoc.
const SpecificDaySelector = ({disabled,value,onChange,placeholder,error}) => {

	const classes = useStyles();

	const menuProps = {
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left'
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left'
		},
		getContentAnchorEl: null
	};

	return (
		<div>
			<Select
				disabled={disabled}
				labelId="specific-day-selector"
				disableUnderline
				className={clsx(styles.select,{[styles.error]:error})}
				IconComponent={SvgArrow}
				MenuProps={menuProps}
				value={value}
				label={placeholder}
				onChange={onChange}
			>
				{specificDaySelectOptions}
			</Select>
			
			<Typography className={classes.error}>{error}</Typography>
		</div>
	);
};

SpecificDaySelector.propTypes = {
	onChange:PropTypes.func.isRequired,
	value:PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
	disabled:PropTypes.bool,
	placeholder:PropTypes.string,
	error:PropTypes.string
};

SpecificDaySelector.defaultProps = {
	value:'',
	disabled:false,
	placeholder:'',
	error:''

};

export default SpecificDaySelector;