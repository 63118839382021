import {
  CircularProgress,
  Dialog,
  IconButton,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import {
  Close,
} from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  toC,
  toF,
  toKgPerCm2,
  toPSI
} from "../../services/converter";
import CommonUtils from "../../utils/CommonUtils";
import styles from "./AddRule.style";
import Condition from "./Condition";
import ConditionsGroup from "./ConditionsGroup";
import conditionUtils from "./conditionUtils";
import { Box, CustomedTextField, CustomSelect, SystemWrapper, UnitWrapper } from "./CustomedComponents";

const mainRuleTypes = [{ id: "Custom Rule", name: "Custom Rule" }];
const newRule = { relation: "and", unitType: "", parameter: "", operator: "", thresholdOperator: "", value: "", duration: "0", parameter2: "", indoorPerc: 1, outdoorPerc: 1, type: null };
const operationalTelemetryValue = -77;

const ReadOnlyAddRule = (props: any) => {
  const classes = styles();
  const { close, trapId, history, brand: unitBrand, siteId } = props;

  const types = useStoreState((s) => s.types);
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  const allSites = useStoreState((state) => state.sites.allSites);
  const getServiceParams = useStoreActions((action) => action.traps.getServiceParams);
  const getTrapById = useStoreActions((action) => action.traps.getTrapById);
  const user = useStoreState((s) => s.users.me);
  const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
  const setSelectedtrapId = useStoreActions((a) => a.traps.setSelectedtrapId);

  const [ruleName, setRuleName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<any>("");
  const [ruleType, setRuleType] = useState<any>("Custom Rule");
  const [indoorParamsMap, setIndoorParams] = useState<any>([]);
  const [conditions, setConditions] = useState<any>([]);
  const [groups, setGroups] = useState<any>({});
  const [outdoorParamsMap, setOutdoorParams] = useState<any>([]);
  const [selectedUnits, setSelectedUnits] = useState<any>([]);
  const [selectedSystems, setSelectedSystems] = useState<any>([]);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const [enumParams, setEnumParams] = useState<any>([]);
  const [indoorParams, setFilteredIndoorParams] = useState<any>([]);
  const [outdoorParams, setFilteredOutdoorParams] = useState<any>([]);
  const { temperatureScale: userTempScale = 1, measurementUnits: userPressureScale = 2 } = user;
  const { hvacBrands, unitTypes, measurementUnitTypes, temperatureScale, pressureScale, trapTypes } = types;
  const [brands] = useState<any>(hvacBrands ? [...hvacBrands, { name: t`Operational Controls`, value: operationalTelemetryValue }] : [{ name: t`Operational Controls`, value: operationalTelemetryValue }]);
  const descriptionRef: any = useRef(null);
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [ruleTypes, setRuleTypes] = useState<any>(mainRuleTypes);
  const [paramsReady, setParamsReady] = useState<boolean>(false);

  useEffect(() => {
    conditionUtils.generateId.reset();

    getTrapById(trapId)
      .then((trap: any) => {
        const isTemplateB = !!trap.userSelections?.isTemplateBased;
        const templateId = (isTemplateB && trap.userSelections?.trapTemplateId) || "";
        templateId && isTemplateB && setRuleTypes([{ id: templateId, name: t`Library Rule` }]);
        extractConditions(trap, false);
      }).catch(() => { });

    Promise.all([getServiceParams({ site: siteId, brand: unitBrand, unitType: unitTypes.service }), getServiceParams({ site: siteId, brand: unitBrand, unitType: unitTypes.outdoor })])
      .then((response: any) => {
        const enumParamsValues: any = [];
        Object.values({ ...response[0], ...response[1] }).forEach((param: any) => param.enum && enumParamsValues.push(param.code));
        const indoorParamsArray = Object.values(response[0]).filter((param: any) => _.isUndefined(param.enabledInTriggers) || param.enabledInTriggers === true);
        const outdoorParamsArray = Object.values(response[1]).filter((param: any) => _.isUndefined(param.enabledInTriggers) || param.enabledInTriggers === true);
        setIndoorParams(response[0]);
        setOutdoorParams(response[1]);
        setEnumParams(enumParamsValues);
        setFilteredIndoorParams(indoorParamsArray);
        setFilteredOutdoorParams(outdoorParamsArray);
        setParamsReady(true);
      });
  }, []);

  const inOrder = (tree: any) => {
    let relations: any = [];
    let nodes: any = [];
    let current = tree;
    if (current) {
      if (current.isCondition || current.isGroup) {
        return { relations: [], nodes: [current] };
      }

      let traverse = (node: any) => {
        if (node.ruleSets && node.ruleSets[0]?.relation && !node.ruleSets[0]?.isGroup && !node.ruleSets[0]?.isCondition) {
          traverse(node.ruleSets[0]);
        }
        else {
          nodes.push(node.ruleSets[0]);
        }

        relations.push(node.relation);

        if (node.ruleSets && node.ruleSets[1]?.relation && !node.ruleSets[1]?.isGroup && !node.ruleSets[1]?.isCondition) {
          traverse(node.ruleSets[1]);
        }
        else {
          nodes.push(node.ruleSets[1]);
        }
      };

      traverse(current);
    }
    return { relations, nodes };
  };

  const reCreateCondition = (node: any, relation: string) => {
    const conditionId = conditionUtils.generateId();
    const nodeData: any = { id: conditionId };
    const subNode = "thresholdValue" in node ? node : node.rules[0];
    const { scaleType = -10, scale } = subNode;

    const scaleTypeString = +scaleType === +measurementUnitTypes["temperature"] ? "temp" : (+scaleType === +measurementUnitTypes["pressure"] ? "pressure" : "other");
    const needConvert = scaleTypeString !== "other" && ((scaleTypeString === "temp" && +scale !== +userTempScale) || (scaleTypeString === "pressure" && +scale !== +userPressureScale));

    let value: any = "thresholdValue" in node ? node.thresholdValue : node.rules[0].value;

    if (scaleTypeString !== "other" && needConvert) {
      let method: any = "";
      if (scaleTypeString === "temp") {
        if (temperatureScale[userTempScale] === "celsius") {
          method = toC;
        }
        else {
          method = toF;
        }
      }
      else {
        if (pressureScale[userPressureScale] === "PSI") {
          method = toPSI;
        }
        else {
          method = toKgPerCm2;
        }
      }

      if (method) {
        value = method(value);
      }
    }

    if ("thresholdValue" in node) {
      nodeData.operator = "threshold";
      nodeData.thresholdOperator = node.relation;
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.parameter2 = "" + node.rules[1].code;
      nodeData.unitType = +node.rules[0].unitType === +node.rules[1].unitType ? "" + node.rules[0].unitType : "mixed";
    }
    else if ("comparisonOperator" in node) {
      nodeData.operator = node.comparisonOperator + " parameter";
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.parameter2 = "" + node.rules[1].code;
      nodeData.unitType = +node.rules[0].unitType === +node.rules[1].unitType ? "" + node.rules[0].unitType : "mixed";
    }
    else {
      nodeData.operator = node.rules[0].operator;
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.unitType = node.rules[0].unitType || "";
    }
    nodeData.relation = relation;

    if (node.triggerDelayTimeInMinutes + "") {
      nodeData.duration = CommonUtils.toHoursAndMinutes(node.triggerDelayTimeInMinutes) + "" || "0";
    }

    let type: any;
    if (nodeData.unitType) {
      type = nodeData.unitType === "mixed" ? "mixed" : (+unitTypes["service"] === +nodeData.unitType ? "indoor" : "outdoor");
    }
    return { data: nodeData, type };
  };

  const extractConditions = (editTrap: any, onlyConditions: boolean) => {

    const mainBody = editTrap.trapSets[0].ruleSets[0];
    if (!onlyConditions) {
      setDescription(editTrap.description);
      setSelectedBrand(editTrap.type === trapTypes.operationalTelemetry ? operationalTelemetryValue : editTrap.userSelections?.brand);
      // setSelectedBrand(editTrap.userSelections?.brand);
      const isTemplateB = !!editTrap.userSelections?.isTemplateBased;
      isTemplateB && setRuleType(editTrap.userSelections?.trapTemplateId);
      setRuleName(editTrap.name);
      setIsEnabled(editTrap.isEnabled);

      if (mainBody.systemIds) {
        setSelectedSystems(mainBody.systemIds);
      }

      if (mainBody.unitIds) {
        setSelectedUnits(mainBody.unitIds);
      }
    }

    const treeRoot = mainBody.ruleSets[0];
    const data = inOrder(treeRoot);

    data.relations.push("and");
    const newConds: any = [];
    const newGroups: any = {};

    const { nodes, relations } = data;
    for (let x in nodes) {
      const node = nodes[x];
      const relation = relations[x];

      if (node.isCondition) {
        const nodeResult = reCreateCondition(node, relation);
        newConds.push({ ...newRule, ...nodeResult.data });
      }

      if (node.isGroup) {
        delete node.isGroup;
        const groupData = inOrder(node);
        groupData.relations.push("and");
        const groupConditions: any = [];
        const { relations: gRelations, nodes: gNodes } = groupData;
        const groupId = conditionUtils.generateId();

        for (let i in gNodes) {
          const gNode = gNodes[i];
          const gRelation = gRelations[i];

          if (gNode.isCondition) {
            const nodeResult = reCreateCondition(gNode, gRelation);
            groupConditions.push({ ...newRule, ...nodeResult.data });
          }

        }

        newGroups[groupId] = { relation, conditions: groupConditions, id: groupId };
      }
    }

    if (mainBody.numberOfIndoorUnitsForEvent) {
      newConds.push({ ...newRule, unitType: "iduNum", value: mainBody.numberOfIndoorUnitsForEvent, indoorPerc: mainBody.numberOfIndoorUnitsForEventIsPerc ? 2 : 1, id: conditionUtils.generateId() });
    }

    if (mainBody.numberOfOutdoorUnitsForEvent) {
      newConds.push({ ...newRule, unitType: "oduNum", value: mainBody.numberOfOutdoorUnitsForEvent, outdoorPerc: mainBody.numberOfOutdoorUnitsForEventIsPerc ? 2 : 1, id: conditionUtils.generateId() });
    }
    setConditions(newConds);
    setGroups(newGroups);
    setDataReady(true);
  };

  const moveToAnomalyPage = () => {
    setSelectedtrapId(trapId);
    history.push("/anomalies-settings");
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Alert Anomaly`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <Formik
        initialValues={!dataReady && !paramsReady ? { ruleName: "", selectedBrand: "", ruleType: "", conditions1: [], groups: [] } : { ruleName, selectedBrand, ruleType, conditions1: conditions, ...{ groups } }}
        enableReinitialize={true}
        onSubmit={() => { }}
        render={({ values, setFieldValue, errors, touched
        }) => {
          const isFirstConditionFake = values.conditions1[0]?.unitType === "iduNum" || values.conditions1[0]?.unitType === "oduNum";

          return (
            // @ts-ignore
            <Form translate="yes" className={classes.dialogContent}>
              <main
                className={clsx(classes.content)}
              >
                {(!dataReady && !paramsReady) && <div className={clsx(classes.opacityStyle, classes.flexStyle)}><CircularProgress /> </div>}
                <div className={classes.boxesHolder}>
                  <div id="basic-info" className={clsx(classes.basicInfoContainer)}>
                    <div style={{ marginRight: 15 }}>
                      <CustomedTextField
                        value={values.ruleName}
                        name="ruleName"
                        className={classes.removeTopMargin}
                        onChange={(event: any) => setFieldValue("ruleName", event.target.value)}
                        label={t`Rule Name`}
                        error={errors.ruleName && touched.ruleName}
                        maxLength={50}
                        disabled={true}
                      />
                      <div>
                        <CustomSelect className={classes.mainSelect} error={errors.selectedBrand && touched.selectedBrand} placeholder="Select Brand" name="selectedBrand" value={values.selectedBrand} options={brands} disabled={true} />
                        <CustomSelect label="name" optionValue="id" className={clsx(classes.mainSelect, classes.removeRightMargin)} disabled={true} placeholder="Rule Type" name="ruleType" value={values.ruleType} options={ruleTypes} />
                      </div>
                    </div>
                    <TextareaAutosize
                      disabled={true}
                      className={classes.readOnlyTextArea}
                      aria-label="minimum height"
                      minRows={4}
                      maxRows={4}
                      placeholder={t`Insert Description / Automatic Rule Description…`}
                      defaultValue={description}
                      ref={descriptionRef}
                    />
                    <CoolSwitch disabled={true} color={"default"} className={classes.switchButton} checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)}
                      classes={{
                        thumb: classes.thumb,
                        track: classes.track
                      }} />
                  </div>
                  <Box
                    title={t`Conditions`}
                    bLabel1={t`Add Condition`}
                    bLabel2={t`Group`}
                    bDisabled1={true}
                    bDisabled2={true}
                    // action1={() => addCondition(values.conditions1, values.groups, values.ruleName, values.selectedBrand)}
                    // action2={() => addGroup(values.conditions1, values.ruleName, values.selectedBrand)}
                    addBottomMargin
                  >
                    {!_.isEmpty(values.groups) && Object.keys(values.groups).map((key: any, index: number) => {
                      if (!groups[key] || !groups[key].id) {
                        return null;
                      }
                      return <FieldArray
                        name={`groups.${key}.conditions`}
                        key={`array-group-${groups[key].id}`}
                        render={() =>
                          <ConditionsGroup
                            isFirstConditionFake={isFirstConditionFake}
                            enumParams={enumParams}
                            serviceParamTypes={serviceParamTypes}
                            errors={errors}
                            touched={touched}
                            noSingleConditions={values.conditions1.length === 0}
                            groupsLastIndex={Object.keys(values.groups).length - 1}
                            groupIndex={index}
                            showWranning={() => { }}
                            isUnitsSelected={selectedUnits.length > 0}
                            disabled={true}
                            key={`group-${groups[key].id}`}
                            indexKey={key}
                            conditions={values.groups[key].conditions}
                            indoorParams={indoorParams}
                            outdoorParams={outdoorParams}
                            allParamsMap={{ ...indoorParamsMap, ...outdoorParamsMap }}
                            relation={values.groups[key].relation}
                            name={`groups.${key}.conditions`}
                            setFieldValue={setFieldValue}
                            unitTypes={types.unitTypes}
                          />
                        }
                      />;
                    })}
                    <FieldArray
                      name="conditions1"
                      render={() => (values.conditions1.map((condition: any, index: number) =>

                        <div key={index}>
                          <Condition
                            // hideIduNum={iduNumIn}
                            // hideOduNum={oduNumIn}
                            ignoreUnitType={selectedBrand === operationalTelemetryValue}
                            enumParams={enumParams}
                            serviceParamTypes={serviceParamTypes}
                            nextCondition={values.conditions1[index + 1]}
                            conditions={values.conditions1}
                            errors={errors}
                            touched={touched}
                            // showWranning={(value: any, id: any, name: any) => handleUnitsWarning({ conditions: values.conditions1, value, id: index, setFieldValue, name , item: "unit"})}
                            isUnitsSelected={selectedUnits.length > 0}
                            disabled={true}
                            key={condition.id}
                            index={index}
                            condition={condition}
                            setFieldValue={setFieldValue}
                            indoorParams={indoorParams}
                            outdoorParams={outdoorParams}
                            allParamsMap={{ ...indoorParamsMap, ...outdoorParamsMap }}
                            // deleteCondition={() => deleteCondition(values.conditions1, condition.id)}
                            // onSelect={onSelect}
                            name={"conditions1"}
                            lastCondition={index !== values.conditions1.length - 1}
                            // conditionsUnitsType={unitType}
                            unitTypes={types.unitTypes}
                          />
                        </div>
                      ))}
                    />
                  </Box>
                  <Box
                    small
                    title={t`Apply to`}
                    bLabel2={t`Add Units`}
                    bDisabled2={true}
                  // action2={() => openTreeDiag(values.conditions1, values.groups, values.selectedBrand === sensorBrandvalue ? values.conditions1[0]?.type : hasEmptyFields ? "systems" : unitType, values.ruleName, values.selectedBrand === sensorBrandvalue ? "sensor" : "unit")}
                  >
                    <div className={classes.unitsSystemsContainer}>
                      {(dataReady && paramsReady) && selectedSystems.map((id: any) => !allSystems[id] ? null : <SystemWrapper key={`selected-system-${id}`} systemName={allSystems[id]?.name} siteName={allSites[allDevices[allSystems[id]?.device]?.site]?.name} onRemove={() => setSelectedSystems(selectedSystems.filter((systemId: string) => systemId !== id))} disabled={true} />)}
                      {(dataReady && paramsReady) && selectedUnits.map((id: any) => !allUnits[id] ? null : <UnitWrapper key={`selected-unit-${id}`} unitName={allUnits[id]?.name} siteName={allSites[allDevices[allUnits[id]?.device]?.site]?.name} systemName={allSystems[allUnits[id]?.system as any]?.name} onRemove={() => setSelectedUnits(selectedUnits.filter((unitId: string) => unitId !== id))} disabled={true} />)}
                    </div>
                  </Box>
                </div>
                <div className={classes.actionsContainer}>
                  <Button onClick={moveToAnomalyPage} onMouseDown={(event: any) => event.preventDefault()} marginRight white width={150}> {t`Edit anomaly`}</Button>
                </div>

              </main>
            </Form>
          );
        }}
      />
    </Dialog>
  );
};

export default ReadOnlyAddRule;
