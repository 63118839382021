import {
  Button as MuiButton,
  Grid,
  IconButton,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import CircularSlider from "react-circular-slider-svg";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { t } from "ttag";
import { symbols } from "../../constants/sensorsUnitsSymbols";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import Switch from "../../cool_widgets/Switch/Switch";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  isEndStampLaterThanStartStamp,
  minsToTime,
  stringTimeToUTCMins
} from "../../services/timeService";
import { ArrowBack, ArrowDownControl, ArrowUp, CloseIcon } from "../../svgComponents";
import { Checkbox } from "../Checkbox";
import TimePicker from "../TimePicker/TimePicker";
import ErrorBox from "../WarningBox/ErrorBox";
import addEditScheduleStyles from "./addEditSchedule.style";
import { sensorMeasurementUnits, sensorTypes } from "@models/Sensors";

const hasValue = (value: any) => {
  return !!value || value === 0; //
};

const hasTime = (time: any) => {
  return time !== "" && time !== null && time >= 0;
};

const isEqual = (arr1: any, arr2: any) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  let equal = true;
  arr1.forEach((day: string, index: number) => {
    if (!equal) {
      return;
    }

    if (!arr2.includes(day)) {
      equal = false;
      return;
    }

    if (!arr1.includes(arr2[index])) {
      equal = false;
      return;
    }

  });
  return equal;
};

const Header = ({ title, handleConfirmDialog, checkIfCanGoBack }: any) => {
  const classes = addEditScheduleStyles();

  return (
    <div className={classes.headerStyle}>
      <IconButton onClick={() => checkIfCanGoBack("hide")} className={classes.bigIconBtnStyle}>
        <ArrowBack />
      </IconButton>
      <Typography style={{ color: "#fff", fontSize: 26 }}>{title}</Typography>
      <IconButton onClick={() => checkIfCanGoBack("close")} className={classes.bigIconBtnStyle}>
        <CloseIcon style={{ transform: "scale(1.3)" }} />
      </IconButton>
    </div>
  );
};

const AddEditSchedule: React.FC<any> = (props: any) => {
  const classes = addEditScheduleStyles();

  const user = useStoreState((state) => state.users.me);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay);
  const timeFormat = useStoreState((state) => state.users.timeFormat);

  const types = useStoreState((state) => state.types);
  const { temperatureScale, timeFormat: myTimeFormat } = user;
  const { scheduleCategories, weekDays = [], timeFormat: timeFormatTypes, sensorTypes : fullSensorTypes } = types;

  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const createScheduleAPI = useStoreActions((actions) => actions.sensors.createSensorSchedule);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [scheduleDisabled, setScheduleStatus] = useState<boolean>();
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOffTime] = useState<string>("");
  const [days, setDays] = useState<[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [schedule, setSchedule] = useState<any>({ name: "" });
  const [openPicker, setOpenPicker] = useState<string>("");
  const [openConfirmDialog, handleConfirmDialog] = useState<string>("");
  const [disableAdding, setDisableAdding] = useState<boolean>(false);
  const [scheduleName, setScheduleName] = useState<string>("");
  const [tab, setTab] = useState<number>(scheduleCategories.weekly);
  const today = new Date();
  const [dates, setDates] = useState<any>([]);

  const aYearFromToday = new Date();
  aYearFromToday.setFullYear(today.getFullYear() + 1);
  const [dirty, setDirty] = useState<boolean>(false);

  const { addScheduleLocaly, sensor, itemId, isGroup, scheduleData = {}, editMode, hideSchedulesPanel, closeAddEditSchedule, saveScheduleData } = props;
  const { enums, type, userMinMax, valueMinMax, sensorUnit, name } = sensor;
  const isAnalog = type === sensorTypes.ANALOG_OUTPUT_0_10V_SENSOR;
  const isOnOff = fullSensorTypes[type]?.measurementUnits === sensorMeasurementUnits.OpenClose ;

  const [value, setValue] = useState<number>(type === 130 ? userMinMax ? (userMinMax.userMin + userMinMax.userMax) / 2 : (valueMinMax.min + valueMinMax.max) / 2 : 0);
  const [mainValue, setMainValue] = useState<number>(type === 130 ? userMinMax ? (userMinMax.userMin + userMinMax.userMax) / 2 : (valueMinMax.min + valueMinMax.max) / 2 : 0);

  const title = editMode ? t`Edit Schedule` : t`Add Schedule`;
  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours" ? true : false;

  const passScheduleInfo = (schedule: any) => {
    const { name, dates = [], scheduleCategory, powerOnTime, powerOffTime, value, days = [], isDisabled } = schedule || {};
    setScheduleStatus(isDisabled);
    setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
    setPowerOffTime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");
    setValue(value);
    setMainValue(value);
    setDays(days as []);
    setScheduleName(name || "");
    setTab(scheduleCategory);
    const selectedDates = dates.map((date: any) => new Date(date));
    setDates(selectedDates);
  };

  useEffect(() => {
    if (!editMode) {
      return;
    }

    setSchedule(scheduleData);
    passScheduleInfo(scheduleData);
  }, [is12Hours]);

  const checkRequiredFields = () => {
    setErrorMsg("");

    if (!scheduleName) {
      setErrorMsg(t`Schedule Name is Required`);
      return false;
    }
    if (scheduleName.length < 3 || scheduleName.length > 25) {
      setErrorMsg(t`Schedule Name should be from 3 to 25 chars`);
      return false;
    }

    if (tab === scheduleCategories.calendar && dates.length === 0) {
      setErrorMsg(t`Pick one date at least`);
      return false;
    }
    if (tab === scheduleCategories.weekly && days.length === 0) {
      setErrorMsg(t`Pick one day at least`);
      return false;
    }

    if (!powerOnTime && !powerOffTime) {
      setErrorMsg(t`Add at least start or end time`);
      return false;
    }

    if (
      !(
        (powerOnTime) &&
        (powerOffTime)
      )
    ) {
      return true;
    }

    if (!isEndStampLaterThanStartStamp(powerOnTime, powerOffTime, is12Hours)) {
      setErrorMsg(t`End time must be later than start time`);
      return false;
    }

    return true;
  };

  const editSchedule = () => {
    const {
      powerOnTime: defaultPowerOn,
      powerOffTime: defaultPowerOff,
      setpoint: defaultSetpoint,
    } = schedule;

    const startHour =
      powerOnTime === ""
        ? defaultPowerOn === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOnTime, is12Hours);

    const endHour =
      powerOffTime === ""
        ? defaultPowerOff === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOffTime, is12Hours);

    const data: any = {
      isDisabled: scheduleDisabled,
      name: scheduleName,
      powerOnTime: startHour,
      powerOffTime: endHour,
      value,
      userMin: userMinMax ? userMinMax.userMin : hasValue(scheduleData.min) ? null : undefined,
      userMax: userMinMax ? userMinMax.userMax : hasValue(scheduleData.max) ? null : undefined,
      scheduleCategory: tab

    };
    if (tab === scheduleCategories.calendar) {
      data.dates = dates;
    }
    if (tab === scheduleCategories.weekly) {
      data.days = days;
    }

    updateSchedule({
      id: scheduleData.id as string,
      data
    })
      .then((data: any) => {
        saveScheduleData(scheduleData.id, data);
        closeAddEditSchedule();
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => {
        setDisableAdding(false);
      });
  };

  const createSchedule = () => {
    const startHour = powerOnTime ? stringTimeToUTCMins(powerOnTime, is12Hours) : undefined;
    const endHour = powerOffTime ? stringTimeToUTCMins(powerOffTime, is12Hours) : undefined;
    const data: any = {
      isDisabled: scheduleDisabled,
      powerOnTime: startHour,
      powerOffTime: endHour,
      value,
      name: scheduleName,
      userMin: userMinMax ? userMinMax.userMin : undefined,
      userMax: userMinMax ? userMinMax.userMax : undefined,
      scheduleCategory: tab
    };
    if (tab === scheduleCategories.calendar) {
      data.dates = dates;
    }
    if (tab === scheduleCategories.weekly) {
      data.days = days;
    }

    createScheduleAPI({
      sensorId: sensor.id,
      data
    })
      .then((schedule: any) => {
        addScheduleLocaly(schedule);
        closeAddEditSchedule();
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => {
        setDisableAdding(false);
      });
  };

  const save = () => {

    const allRequiredNotEmpty = checkRequiredFields();

    if (!allRequiredNotEmpty) {
      return;
    }
    setDisableAdding(true);

    if (!scheduleData.id) {
      createSchedule();
      return;
    }

    editSchedule();
  };

  const decreaseSetpoint = () => {
    setValue(value - 1);
  };

  const increaseSetpoint = () => {
    setValue(value + 1);
  };

  const cancel = () => {
    setOpenDialog(false);
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...[], ...days];

    currentDays.includes(selectedDay)
      ? (currentDays = days.filter((day) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setDays(currentDays);
    !dirty && setDirty(true);
  };

  const changeScheduleStatus = () => {
    setScheduleStatus(!scheduleDisabled);
    !dirty && setDirty(true);
  };
  const currentDays: string[] = days;

  const weekDaysArray = Object.keys(weekDays);

  const onClear = () => {
    setOpenPicker("");
    openPicker === "start" ? setPowerOnTime("") : setPowerOffTime("");
    !dirty && setDirty(true);
  };

  const onSetTime = (time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOffTime(time);
    setOpenPicker("");
    !dirty && setDirty(true);
  };

  const handleDayClick = (date: any, { selected }: any) => {
    const selectedDates: any = dates.concat();
    if (selected) {
      const selectedIndex = selectedDates.findIndex((selectedDate: any) =>
        DateUtils.isSameDay(selectedDate, date)
      );
      selectedDates.splice(selectedIndex, 1);
    } else {
      selectedDates.push(date);
    }
    setDates(selectedDates);
    !dirty && setDirty(true);
  };

  const checkIfCanGoBack = (goTo: any) => {
    if (editMode && schedule.name === "") {
      goTo === "close" ? hideSchedulesPanel() : closeAddEditSchedule();
      return;
    }
    if ((schedule.powerOnTime || schedule.powerOnTime === 0 ? schedule.powerOnTime : "") !== stringTimeToUTCMins(powerOnTime, is12Hours)) {
      handleConfirmDialog(goTo);
      return;
    }
    if ((schedule.powerOffTime || schedule.powerOffTime === 0 ? schedule.powerOffTime : "") !== stringTimeToUTCMins(powerOffTime, is12Hours)) {
      handleConfirmDialog(goTo);
      return;
    }
    if (schedule.isDisabled !== scheduleDisabled) {
      handleConfirmDialog(goTo);
      return;
    }
    if (editMode ? +value !== +schedule.value : +mainValue !== value) {
      handleConfirmDialog(goTo);
      return;
    }
    if (schedule.isDisabled !== scheduleDisabled) {
      handleConfirmDialog(goTo);
      return;
    }
    if (!isEqual(schedule.days || [], days)) {
      handleConfirmDialog(goTo);
      return;
    }
    if (schedule.name !== scheduleName) {
      handleConfirmDialog(goTo);
      return;
    }
    if (!isEqual((schedule?.dates || []).map((date: any) => moment(date).unix()), dates.map((date: any) => moment(date).unix()))) {
      handleConfirmDialog(goTo);
      return;
    }

    goTo === "close" ? hideSchedulesPanel() : closeAddEditSchedule();
  };
  return (
    <div className={classes.scheduleInfoContainer}>
      <Header title={title} handleConfirmDialog={handleConfirmDialog} checkIfCanGoBack={checkIfCanGoBack} />
      <div className={classes.backgroundContainer}>
        <div className={classes.pageContent}>
        <div className={classes.bodyRow}>
          <TextField
            variant={"outlined"}
            label={"Schedule Name"}
            placeholder={"Schedule Name"}
            value={scheduleName}
            className={classes.inputClass}
            onChange={(e: any) => {
              setScheduleName(e.target.value);
              !dirty && setDirty(true);
            }}
          />
          <div className={classes.controlSec}>
            <Switch
              checked={!scheduleDisabled}
              disableRipple={true}
              onChange={changeScheduleStatus}
              value={true}
            />
          </div>
        </div>
        <Tabs
          value={tab}
          onChange={(event: any, newValue: number) => setTab(newValue)}
          variant="fullWidth"
          aria-label="icon label tabs example"
          classes={{ root: classes.root }}
        >
          <Tab value={scheduleCategories.weekly} label={t`Weekly`} className={classes.tab} />
          <Tab value={scheduleCategories.calendar} label={t`Calendar`} className={classes.tab} />
        </Tabs>
        {tab === scheduleCategories.weekly && <Grid
          container
          className={clsx(classes.startEndTimeContainer, classes.tabContainer)}
        >
          <Typography
            className={classes.selectModeStyle}
          >{t`Choose Days`}</Typography>
          <Grid container className={classes.daysContainer} id="days">
            <DaysList
              days={weekDaysArray}
              activeDays={currentDays}
              action={addRemoveDay}
            />
          </Grid>
        </Grid>}
        {tab === scheduleCategories.calendar && <Grid
          container
          className={clsx(classes.startEndTimeContainer, classes.tabContainer)}
        >
          <Typography
            className={classes.selectModeStyle}
          >{t`Choose Dates`}</Typography>
          <Grid container className={classes.daysContainer} id="dates">
            <DayPicker
              selectedDays={dates}
              onDayClick={handleDayClick}
              className={classes.calendar}
              disabledDays={
                {
                  before: editMode ? undefined : today,
                  after: aYearFromToday
                }}
              fromMonth={editMode ? undefined : today}
              toMonth={aYearFromToday}
            />
          </Grid>
        </Grid>}
        <Grid
          container
          className={clsx(classes.startEndTimeContainer, classes.container)}
        >
          <Grid className={classes.startEndTimeContainer}>
            <MuiButton
              disableRipple
              variant="contained"
              className={clsx(classes.timeContainer, {
                [classes.timeSelected]: !!powerOnTime
              })}
              onClick={() => setOpenPicker("start")}
            >
              {powerOnTime
                ? powerOnTime
                : "Start Time"}
            </MuiButton>
            {!isAnalog && <MuiButton
              disableElevation
              disableRipple
              variant="contained"
              className={clsx(classes.timeContainer, {
                [classes.timeSelected]: !!powerOffTime
              })}
              onClick={() => setOpenPicker("end")}
            >
              {powerOffTime
                ? powerOffTime
                : "End Time"}
            </MuiButton>}
          </Grid>
        </Grid>
          {!isOnOff && <Grid
            container
            className={clsx(classes.startEndTimeContainer, classes.container)}
          >
            <Typography
              className={classes.selectModeStyle}
            >{t`Select Value`}</Typography>

            <div className={classes.selectModeContainer}>
              <Grid className={classes.setpointContainer}>
                {type === 130 ?
                  <div className={classes.sensorSliderContainer}>
                    <CircularSlider
                      size={275}
                      minValue={userMinMax ? userMinMax.userMin : valueMinMax.min}
                      maxValue={userMinMax ? userMinMax.userMax : valueMinMax.max}
                      startAngle={0}
                      endAngle={180}
                      coerceToInt={true}
                      angleType={{ direction: "cw", axis: "-x" }}
                      handle1={{
                        value, onChange: (value: any) => {
                          setValue(value);
                          !dirty && setDirty(true);
                        }
                      }}
                      arcColor={"#FFF"}
                      arcBackgroundColor={"#FFF"}
                    />
                    <div className={classes.hideHalfOfCircle}></div> {/*to fix controlling circular slider*/}
                    <div className={classes.sensorValuesContainer}>
                      <Typography className={classes.sensorValues}>{userMinMax ? userMinMax.userMin : valueMinMax.min}</Typography>
                      <Typography className={classes.sensorValues}>{userMinMax ? userMinMax.userMax : valueMinMax.max}</Typography>
                    </div>
                    <div className={classes.valueControl}>
                      <Typography className={classes.sensorValue}>{value}</Typography>
                      <Typography className={classes.measurementUnit}>{symbols[sensorUnit || ""] || ""}</Typography>
                    </div>
                  </div> : enums ?
                    <div className={classes.enumContainer}>
                      <Typography style={{ marginRight: 10 }}>{enums[0]}</Typography>
                      <Switch
                        checked={value}
                        disableRipple={true}
                        onChange={(event: any) => {
                          setValue(event.target.checked ? 1 : 0);
                          !dirty && setDirty(true);
                        }}
                        value={true}
                      />
                      <Typography style={{ marginLeft: 10 }}>{enums[1]}</Typography>
                    </div> : null
                }
              </Grid>
            </div>
          </Grid> }
          {errorMsg && (
            <InputLabel
              className={classes.errorLabelStyle}
            >{errorMsg}</InputLabel>
          )}

          <MuiButton disableRipple type="submit" variant="contained" onClick={save} disabled={disableAdding || !dirty} className={classes.redBtn}>
            {t`Save`}
          </MuiButton>
          {openConfirmDialog && <ErrorBox
            title={t`Discard Changes`}
            error={"Are you sure you want to discard changes made on this page?"}
            onAccept={() => closeAddEditSchedule()}
            onClose={() => handleConfirmDialog("")} />}
          <TimePicker
            show={!!openPicker}
            onSet={onSetTime}
            time={openPicker === "start" ? powerOnTime : powerOffTime}
            onDismiss={() => setOpenPicker("")}
            onClear={onClear}
            is12Hours={is12Hours}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditSchedule;
