import { Typography } from '@material-ui/core/';
import clsx from 'clsx';
import React from 'react';
import useStyle from './styles';

const BacnetUnitItem = ({ siteId, onSelect, groupId, selectedItem, entry }) => {
  const classes = useStyle();

  const { title = '', id } = entry;

  const isSelected = selectedItem.id === id && selectedItem.groupId === groupId;
  const isSelectable = !!entry.uiTemplate;

  const handleClick = () => {
    // if not UI template then it's not selectable
    if(!isSelectable) {
      return;
    }

    onSelect(siteId, {
      isGroup: false,
      id,
      groupId,
      type: 'bacnetUnit',
    });
  };

  return (
    <div
      className={clsx(classes.bacnetUnitItem__container, {
        [classes['bacnetUnitItem--selected']]: isSelected,
        [classes['bacnetUnitItem--selectable']]: isSelectable,
      })}
      onClick={handleClick}
    >
      <div className={classes.bacnetUnitItem__locationSection}>
        <Typography
          noWrap
          className={clsx(classes.bacnetUnitItem__name, {
            [classes['bacnetUnitItem__name--bold']]: isSelected,
          })}
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default BacnetUnitItem;
