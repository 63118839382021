import { Button, CircularProgress, IconButton, Typography } from "@material-ui/core/";
import { Services as sdkService } from "coolremote-sdk";

import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import DatePicker from "../../components/MobileDatePicker/MobileDatePicker";
import { Lookup } from "../../components/MobileLookup";
import { AlertBell, ArrowBack, ArrowDown, Close, MobileLogo } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AlertInfo from "./AlertInfo";
import useStyles from "./Alerts.style";

const Alerts: React.FC<any> = (props: any) => {
  const history = useHistory();
  const location: any = useLocation();
  const match: any = useRouteMatch();
  const emptyFilters = { code: {}, status: {}, type: {}, description: {} };
  const { state } = location;

  const selectedAlertObj = useStoreState((state) => state.selectedAlert);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const errorTypes = useStoreState((state) => state.serviceErrorTypes);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  const [descriptionArray, setDescriptionArray] = useState<any>({});
  const [selectedAlert, setSelectedAlert] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [] = useState<boolean>(true);
  const classes = useStyles();
  const types = useStoreState((state) => state.types);
  const getAlertsByFilters = useStoreActions((action) => action.alerts.getAlertsByFilters);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<any>(state?.filters || emptyFilters);
  const { dateRange } = useStoreState((s) => s.selections.selections);
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const [filteredAlerts, setFilteredAlerts] = useState<any>([]);
  const [errorCodeArray, setErrorCodesArray] = useState<any>({});
  const [alertTypeArray, setAlertTypeArray] = useState<any>({});
  const [statusesArray, setStatusesArray] = useState<any>({});
  const [rows, setRows] = useState<any>([]);
  const [opendatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openFilters, setOpenedFilters] = useState<boolean>(false);

  const setServiceErrorTypes = useStoreActions((action) => action.setServiceErrorTypes);
  const stillLoadingInitialAPIs = useStoreState((state) => state.stillLoadingInitialAPIs);
  const getNotificationAlerts = useStoreActions((action) => action.alerts.getNotificationAlerts);

  useEffect(() => {
    if (!dateRange?.endDate || !dateRange?.startDate) {
      updateSelections({
        type: "time",
        data: {
          startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
          endDate: new Date()
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!dateRange) {
      return;
    }

    if (match?.params?.notificaitonShortId) {
      getNotificationAlerts({ notificationShortId: match?.params?.notificaitonShortId })
        .then((resp: any) => {
          setRows(Object.values(resp));
        })
        .catch((err: any) => addMessage({ message: err.message }))
        .finally(() => setIsDisabled(false));
      return;
    }

    const startTime = Date.UTC(dateRange?.startDate.getFullYear(), dateRange?.startDate.getMonth(), dateRange?.startDate.getDate()) - 32400000;
    const endTime = Date.UTC(dateRange?.endDate.getFullYear(), dateRange?.endDate.getMonth(), dateRange?.endDate.getDate(), 23, 59, 59) + 32400000;

    (async () => {
      setIsDisabled(true);
      if (stillLoadingInitialAPIs) {
        return;
      }
      setRows([]);
      if (_.isEmpty(errorTypes)) {
        const errors = await sdkService.getServiceErrorTypes();
        setServiceErrorTypes(errors);
      }
      const allAlertsByTime = await getAlertsByFilters({ startTime, endTime });

      const sortedAlerts = _.orderBy(allAlertsByTime, ["eventTime"], ["desc"]);
      const errorCodesArray: any = {};
      const alertTypeArray: any = {};
      const descriptionArray: any = {};
      const statusesArray: any = {};

      setErrorCodesArray(errorCodesArray);
      setStatusesArray(statusesArray);
      setAlertTypeArray(alertTypeArray);
      setDescriptionArray(descriptionArray);
      setRows(sortedAlerts);
      setIsDisabled(false);
    })();
  }, [dateRange, stillLoadingInitialAPIs, match?.params?.notificaitonShortId]);

  useEffect(() => {
    if (!rows.length) {
      setFilteredAlerts([]);
      return;
    }

    const filteredAlerts = getFilteredAlerts(rows);
    setFilteredAlerts(filteredAlerts);
  }, [selections, selectedFilters, rows]);

  useEffect(() => {
    if (!_.isEmpty(selectedAlertObj) && !_.isEmpty(filteredAlerts)) {

      const alertIndex = _.findIndex(filteredAlerts, { id: selectedAlertObj.id });
      setSelectedAlert(filteredAlerts[alertIndex]);
    }
  }, [filteredAlerts]);

  const onRowSelect = (alert: any) => {
    setSelectedAlert(alert);
    setOpen(true);
  },
    setAcknowledged = (ackAlert: any) => {
      const changedAckAlerts = filteredAlerts.map((alert: any) => {
        let selectedAlert = null;
        if (alert.id === ackAlert.id) {
          alert.status = ackAlert.data ? "Acknowledged" : "Open";
          selectedAlert = alert;
        }
        return alert;
      });

      setFilteredAlerts(getFilteredAlerts(changedAckAlerts));
      setSelectedAlert(selectedAlert);
    };

  // Apply filters and selections to alerts
  const getFilteredAlerts = (alerts: any[]) => {
    function applyFilters(alerts: any[]) {
      return _(alerts)
        .filter((alert) => {
          return _.isEmpty(selectedFilters.code) ? true : selectedFilters.code[alert.errorCode];
        })
        .filter((alert) => {
          return _.isEmpty(selectedFilters.type) ? true : selectedFilters.type[alert.alertType] || selectedFilters.type[alert.severity?.name];
        })
        .filter((alert) => {
          return _.isEmpty(selectedFilters.status) ? true : selectedFilters.status[alert.status];
        })
        .filter((alert) => {
          return _.isEmpty(selectedFilters.description) ? true : selectedFilters.description[alert.description];
        })
        .value();
    }
    function applySelections(alerts: any[]) {
      return _(alerts)
        .filter((alert: any) =>
          selections.unitId ? alert.alertItemContainerIds.unitId ? alert.alertItemContainerIds.unitId === selections.unitId : (alert.alertItemContainerIds.unitIds.length > 1 && alert.alertItemContainerIds.unitIds.includes(selections.unitId)) : true
        )
        .filter((alert: any) =>
          selections.siteId ? alert.alertItemContainerIds.siteId === selections.siteId : true
        )
        .filter((alert: any) =>
          selections.systemId ? alert.alertItemContainerIds.systemId ? alert.alertItemContainerIds.systemId === selections.systemId :
            (alert.alertItemContainerIds.systemIds.length > 1 && alert.alertItemContainerIds.systemIds.includes(selections.systemId)) : true
        )
        .filter((alert: any) =>
          selections.systemId && _.includes(selections.systemId, "_") ?
            allUnits[alert.alertItemContainerIds.unitId].line.toString() === selections.systemId.split("_")[0] && allUnits[alert.alertItemContainerIds.unitId].device === selections.systemId.split("_")[1] :
            true

        )
        .filter((alert: any) =>
          selections.customerId
            ? alert.alertItemContainerIds.customerId === selections.customerId
            : true
        )
        .value();
    }

    const updateFiltersVals = (rows: any) => {
      const errorCodeArr: any = { ...errorCodeArray };
      const statusArr: any = { ...statusesArray };
      const alertTypesArr: any = { ...alertTypeArray };
      const descriptionArr: any = { ...descriptionArray };

      for (let rowIndex in rows) {
        const { status, errorCode, alertType, description } = rows[rowIndex];
        if (!!errorCode) { errorCodeArr[errorCode] = true; }
        if (!!status) { statusArr[status] = true; }
        if (!!alertType) { alertTypesArr[alertType] = true; }
        if (!!description) { descriptionArr[description] = true; }
      }
      setErrorCodesArray(errorCodeArr);
      setStatusesArray(statusArr);
      setAlertTypeArray(alertTypesArr);
      setDescriptionArray(descriptionArr);
    };

    const filteredRows = applyFilters(applySelections(alerts));
    updateFiltersVals(filteredRows);
    return filteredRows;
  };

  const filterValues: any = {
    status: statusesArray,
    code: errorCodeArray,
    type: alertTypeArray
  };

  const onApply = (selectedFilters: any) => {
    setSelectedFilters({ ...selectedFilters });
    setOpenedFilters(false);
  };

  return (
    <>
      <TopBar
        leftIconComponent={selectedAlert ? <ArrowBack /> : <MobileLogo />}
        leftAction={selectedAlert ? () => setSelectedAlert(null) : () => history.push("/dashboard")}
        screenTitle="alerts"
        applySiteTypeFiltering
      />
      <div className={classes.view}>
        <div className={classes.contentArea} style={{ height: "calc(100% - 10px)", background: "white" }}>
          <div id="title" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "53px",
            borderBottom: "solid 1px #e5e2e5",
            padding: "0 20px"
          }}>
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <AlertBell style={{ width: 25, height: 25 }} />
              <Typography style={{
                marginLeft: "15px",
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: 600,
                color: "#15111f"
              }}>{t`Alerts Log`}</Typography>
            </div>
            {match?.params?.notificaitonShortId && <Button variant="text" onClick={() => history.push("/alerts")}>{t`Back to all alerts`}</Button>}
          </div>
          <div style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", alignItems: "center", padding: "0 20px", minHeight: 50, borderBottom: "solid 1px #e5e2e5" }} onClick={() => setOpenDatePicker(true)}>
            <Typography style={{
              fontFamily: "Roboto",
              fontSize: 16,
              fontWeight: 600,
              color: "#29132e"
            }}
            >{`${moment(dateRange?.startDate || new Date()).format("MMM DD")} - ${moment(dateRange?.endDate || new Date()).format("MMM DD")}`}</Typography>
            <ArrowDown
              style={{ height: 8, width: 12 }}
            />
          </div>

          <div onClick={() => setOpenedFilters(true)} style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", alignItems: "center", padding: "0 20px", minHeight: 50, borderBottom: "solid 1px #e5e2e5" }}>
            <Typography style={{
              fontFamily: "Roboto",
              fontSize: 16,
              fontWeight: 600,
              color: "#29132e",
              marginRight: 18,
              whiteSpace: "nowrap"
            }}
            >{t`Filter By`}</Typography>
            <div className={classes.filterItemsContainer}>
              {Object.keys(selectedFilters.code).map((filterName: string) =>
                <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
                  <Typography className={classes.filterItemText}>{filterName}</Typography>
                  <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); delete selectedFilters.code[filterName]; setSelectedFilters({ ...selectedFilters }); }}>
                    <Close className={classes.filterItemCloseIcon} />
                  </IconButton>
                </div>
              )
              }
              {Object.keys(selectedFilters.description).map((filterName: string) =>
                <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
                  <Typography className={classes.filterItemText}>{filterName}</Typography>
                  <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); delete selectedFilters.description[filterName]; setSelectedFilters({ ...selectedFilters }); }}>
                    <Close className={classes.filterItemCloseIcon} />
                  </IconButton>
                </div>
              )
              }
              {Object.keys(selectedFilters.status).map((filterName: string) =>
                <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
                  <Typography className={classes.filterItemText}>{filterName}</Typography>
                  <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); delete selectedFilters.status[filterName]; setSelectedFilters({ ...selectedFilters }); }}>
                    <Close className={classes.filterItemCloseIcon} />
                  </IconButton>
                </div>
              )
              }
              {Object.keys(selectedFilters.type).map((filterName: string) =>
                <div key={`filter-item-name-${filterName}`} className={classes.filterItem}>
                  <Typography className={classes.filterItemText}>{filterName}</Typography>
                  <IconButton disableRipple className={classes.filterItemIconBtn} onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); delete selectedFilters.type[filterName]; setSelectedFilters({ ...selectedFilters }); }}>
                    <Close className={classes.filterItemCloseIcon} />
                  </IconButton>
                </div>
              )
              }
            </div>
            <FilterList />
          </div>
          <div id="content" style={{ overflow: "auto", height: "calc(100% - 260px)", maxHeight: "calc(100% - 260px)", display: "flex", justifyContent: "center", flexFlow: "row wrap" }}>
            {isDisabled ? <CircularProgress style={{ alignSelf: "center" }} /> : <div style={{ width: "100%" }}> {filteredAlerts.map((alert: any, index: number) =>
              <div id="topRow" key={index} style={{ width: "100%", display: "flex", flexFlow: "row nowrap", minHeight: "120px", borderBottom: "solid 1px #e5e2e5", paddingLeft: 15, paddingRight: 5, paddingTop: 20 }}
                onClick={() => onRowSelect(alert)}
              >
                <div /* column 1 */ style={{ height: 22, display: "flex", alignItems: "center" }}>
                  <div className={classes.typeKey} style={{ backgroundColor: alert.severity.color }} />
                </div>
                <div /*sec column */ style={{ flex: 1, paddingLeft: 10 }}>
                  <div style={{ display: "flex", flexFlow: "row nowrap", /*height: 19, */alignItems: "baseline" }}>
                    <Typography style={{
                      flex: 1,
                      fontFamily: "Roboto",
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#545964"
                    }}>{alert.alertType}</Typography>
                    <Typography style={{
                      width: "120px",
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#15111f"
                    }}>{alert.time}</Typography>
                    <IconButton className={classes.iconBtn} >
                      <ArrowDown style={{ height: 8, width: 12, transform: "rotate(-90deg)" }} />
                    </IconButton>
                  </div>
                  <div id="middleRows" style={{ display: "flex", flexFlow: "column nowrap", maxWidth: "calc(100vw - 60px)" }}>
                    <div style={{ display: "flex" }}>
                      <Typography className={classes.cutLongNames} style={{ maxWidth: "50%", fontFamily: "Roboto", fontSize: 14, color: "#545964" }}>
                        {!alert.unitName ? "-- " : alert.unitName}
                      </Typography>
                      <span style={{ marginRight: "2px" }}>,</span>
                      <Typography className={classes.cutLongNames} style={{ fontFamily: "Roboto", fontSize: 14, color: "#545964" }}>
                        {alert.siteName !== "-" ? alert.siteName : ""}
                      </Typography>
                    </div>
                    <Typography style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      color: "#545964"
                    }}>{alert.description}</Typography>
                  </div>
                  <div id="bottomRow" style={{ display: "flex", flexFlow: "row nowrap" }}>
                    <Typography className={clsx(classes.alertStatus, { [classes.openAlert]: alert.status === "Open", [classes.closedAlert]: alert.status === "Closed" })}>{alert.status}</Typography>
                    <Typography className={classes.clearTime}>{alert.clearTime}</Typography>
                  </div>
                </div>
              </div>
            )}
            </div>
            }
          </div>
          {opendatePicker && <DatePicker close={() => setOpenDatePicker(false)} />}
          {openFilters &&
            <Lookup
              filtersList={filterValues}
              appliedFilters={selectedFilters}
              onApply={onApply}
              onClose={() => onApply(selectedFilters)}
              title={t`Alert Log Filters`}
            />
          }
        </div>
        {selectedAlert && <AlertInfo
          alert={selectedAlert}
          setAcknowledged={setAcknowledged}
        />}
      </div>
    </>
  );
};
export default Alerts;
