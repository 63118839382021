import { alpha, createStyles } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: 56,
      height: 24,
      padding: "5px 7px",
      marginRight: -6
    },
    switchBase: {
      left: 6,
      padding: 1,
      color: "#D8D8D8",
      "&$checked": {
        left: 0,
        transform: "translateX(26px)",
        color: theme.palette.primary.main,
        "& + $track": {
          backgroundColor: alpha(theme.palette.primary.main, 0.5),
          border: "none",
          opacity: 1
        }
      },
      "&$focusVisible $thumb": {
        color: theme.palette.primary.main
      }
    },
    thumb: {
      width: 24,
      height: 23,
      boxShadow: "none"
    },
    track: {
      borderRadius: 50 / 2,
      backgroundColor: "rgba(213, 210, 213, 0.6)",
      opacity: 1,
      height: 15,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  });
