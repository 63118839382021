import { alpha, createStyles } from "@material-ui/core";

export const loaderStyles = (theme: any) =>
  createStyles({
    loaderWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: alpha("#000", 0.8),
      zIndex: 9999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    hide: {
      display: "none"
    }
  });
