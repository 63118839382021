import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  verticalLine: {
    position: 'absolute',
    top: '150px',
    left: '43%',
    width: '2px',
    height: '75vh',
    backgroundColor: theme.palette.divider,
    zIndex: 5,
    pointerEvents: 'none',
  },
  progressBarContainer: {
    height: '5px',
  },
  loaderContainer: {
    margin: 'auto',
  },
  view: {
    display: 'flex',
    height: '100%',
    overflowX: 'hidden',
  },
  contentArea: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'rgb(236, 235, 239)',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70px',
    padding: '20px 0 20px 0',
    width: '100%',
  },
  titleHeaderNames: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleHeaderAppName: {
    display: 'flex',
    width: '57%',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'left',
    lineHeight: '30px',
    color: '#545964',
    marginRight: '2%',
  },
  pageView: {
    paddingLeft: '24px',
    paddingRight: '24px',
    position: 'relative',
  },
  mainContainer: {
    background: '#FFF',
    padding: '16px',
  },
  topContainer: {
    padding: '16px',
  },
  bottomContainer: {
    height: '63vh',
    display: 'flex',
    flexDirection: 'column',
  },
  rightSideContainer: {
    padding: '16px 32px !important',
  },
  leftSideContainer: {
    padding: '16px !important',
    paddingLeft: '0 !important',
  },
  searchContainer: {
    padding: '16px 32px !important',
    paddingLeft: '0 !important',
  },
  actionContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '300px',
    height: '45px',
  },
  inputContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '45px',
  },
  inputSection: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'left',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
  },
  tableContainer: {
    overflowY: 'auto',
  },
  tableCell: {
    ...theme.tables.tableCell,
    padding: '0 15px !important',
    height: '52px',
    width: '250px',
  },
  tableSmallCell: {
    ...theme.tables.tableCell,
    padding: '0 15px !important',
    height: '52px',
    width: '52px',
  },
  tableHeadCell: {
    padding: '0 15px !important',
    ...theme.tables.headerCell,
    height: '52px',
    width: '180px',
  },
  tableSmallHeadCell: {
    padding: '0 15px !important',
    ...theme.tables.headerCell,
    height: '52px',
    width: '52px',
  },
  inputRoot: {
    padding: '0 !important',
    paddingLeft: '1px !important',
    height: '40px',
    width: '175px',
    margin: '0 8px 0 8px',
  },
  searchField: {
    '& svg': {
      transform: 'scale(0.8) translate(-10px,0)',
    },
  },
  inputSearchRoot: {
    padding: '0 !important',
    paddingLeft: '1px !important',
    height: '40px',
    margin: '0 8px 0 8px',
  },
  textSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    borderRight: '1px solid #FFFFFF',
    height: '36px',
    width: '50px',
  },
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '21px',
    height: '36px',
    paddingLeft: '7px',
  },
}));

export default useStyles;
