import { getHostname } from './utils';

class AssetsService {
	getResourcePath(resourceName) {
		return `${process.env.PUBLIC_URL}/assets/${getHostname()}/${resourceName}`;
	}
	getImagesPath(resourceName) {
		return `${process.env.PUBLIC_URL}/assets/common/images/${resourceName}`;
	}
	getImagesPathFromDomainFolder(resourceName) {
		return `${process.env.PUBLIC_URL}/assets/${getHostname()}/${resourceName}`;
	}
	getBrandLogo(resourceName) {
		return `${process.env.PUBLIC_URL}/assets/common/logos/${resourceName}`;
	}
	getExportPath() {
		return `${process.env.PUBLIC_URL}/assets/common/SiteManagement.xlsx`;
	}
}

export default new AssetsService();
