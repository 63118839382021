import { commonValidators } from './validators';

const getValidatorObject = (item, key) => {

	//CommonValidation:
	if(typeof item === 'string'){
		const commonValidator = commonValidators[item];
		if(commonValidator){
			return commonValidator;
		}else{
			console.error(`
            useMiniForm: Unknown common validation ${item} in ${key}!
            please provide a valid common validation name or provide a customValidator object.
            `);
			return null;
		}
	}

	//CommonValidation with custom message:
	if(Object.keys(item).length === 1){
		const commonValidationKey = Object.keys(item)[0];
		const commonValidator = getValidatorObject(commonValidationKey,key);
		return commonValidator ? {validator:commonValidator.validator, message: item[commonValidationKey]} : null;
	}

	//Custom Validator
	const {validator,message} = item;

	if(validator && message){
		return {validator,message};
	}else{
		console.error(`
            useMiniForm: incorrect customValidation format in ${key}!
            please provide a valid customValidation object:
            {
            validator:validator function that gets the value and all values and returns true if validation fails.
            message:message to show if validation fails.
            }
            `);

		return null;
	}


};

export const getValueFromNativeElement = (event) => {
	const {target:{value}} = event;
	return value;
};

export const getParsedValidation = (validations, key) => {
	if(!validations.length){
		return false;
	}

	return validations.map((item) => {
		return getValidatorObject(item,key);
	}).filter(item => !!item);


}; 