import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import OnGrey from '@icons/OnGrey';
import OnWhite from '@icons/OnWhite';

const useStyles = makeStyles(() => ({
  onOffWrap: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#6B4B68 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const OnOff = ({ value, onChange, isDisabled }) => {
  const classes = useStyles();
    
  return (
    <IconButton
      className={classes.onOffWrap}
      onClick={() => {
        onChange?.(value ? 0 : 1);
      }}
      disabled={isDisabled}
    >
      {value ? <OnGrey stroke={'#fff'} /> : <OnWhite />}
    </IconButton>
  );
};

export default OnOff;
