import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core';
import CoolButton from '@cool_widgets/Button';
import {t} from 'ttag';

import useMiniForm from '@hooks/useMiniForm/useMiniForm';

const AddUserToReportDialog = ({show,onCancelClick:closeDialog,classes,onSave}) => {

	const {connect,values,validateForm, init} = useMiniForm({validateOnChange:true});

	const clearDialog = ()=>{
		init({});
	};

	const handleSaveClick = async () => {

		const { userName, userEmail } = values;

		const nameToSave = userName ?? userEmail;

		if(validateForm()){
			clearDialog();
			onSave({ userName: nameToSave, userEmail });
		}

	};

	const handleCancelClick = ()=> {

		clearDialog();
		closeDialog();
	};

	if(!show){
		return null;
	}

	return (
		<Dialog
			open={show}
			onClose={closeDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="md"
			classes={{ paper: classes.dialog }}
		>
			<DialogContent>
				<Grid container={true} >
					<Grid item={true} xs={12} className={classes.newUserStyle}>
						<TextField id="outlined-basic"
							label="User Name"
							variant="outlined"
							className={classes.reportNameStyle}
							{...connect('userName',{
								customErrorPropName:'helperText',
								allowUndefinedValue:false,
							})}
						/>

					</Grid>

					<Grid item={true} xs={12} className={classes.newUserStyle}>
						<TextField id="outlined-basic"
							label="User Email"
							variant="outlined"
							className={classes.reportNameStyle}
							{...connect('userEmail',{
								customErrorPropName:'helperText',
								allowUndefinedValue:false,
								validations:[
									{required:t`missing email address`},
									{email:t`type in a valid Email`}
								]})}

						/>

					</Grid>

				</Grid>
			</DialogContent>
			<DialogActions className={classes.dialogActionsStyle}>
				<CoolButton
					width={150}
					white
					marginRight
					onClick={handleCancelClick}>
					{t`Cancel`}
				</CoolButton>
				<CoolButton
					width={150}
					onClick={handleSaveClick}>
					{t`Save`}
				</CoolButton>
			</DialogActions>
		</Dialog>
	);
};

AddUserToReportDialog.propTypes = {
	show:PropTypes.bool,
	onCancelClick:PropTypes.func.isRequired,
	onSave:PropTypes.func.isRequired,
	classes:PropTypes.object.isRequired
};

AddUserToReportDialog.defaultProps = {
	show:false
};

export default AddUserToReportDialog;
