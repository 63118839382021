import * as React from 'react';

function SvgDownload(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 21" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#AAA2AA"
          d="M13.357 8.258l-4.336 4.448-.007-10.809c0-.496-.392-.897-.875-.897s-.874.401-.874.897l.006 10.809-4.278-4.387a.86.86 0 00-1.237 0 .914.914 0 000 1.268l5.771 5.918c.02.02.042.04.065.06l.03.024.038.028.038.024c.012.007.023.014.036.02a.299.299 0 00.04.02l.037.019.04.014.04.015.04.01c.015.004.029.009.044.011l.046.008.038.005a.834.834 0 00.173 0l.038-.005c.016-.003.031-.004.046-.008l.043-.01.04-.011.042-.015a.384.384 0 00.04-.014l.037-.018.04-.02c.012-.007.023-.015.035-.021.013-.008.026-.015.038-.024.013-.009.025-.02.038-.028l.03-.024a.898.898 0 00.093-.092l.03-.027 5.771-5.919a.913.913 0 000-1.269.859.859 0 00-1.236 0z"
        />
        <path
          stroke="#AAA2AA"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.6}
          d="M14.5 16v3h-13v-3"
        />
      </g>
    </svg>
  );
}

export default SvgDownload;
