import React from "react";

function MobileProfile(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="#AAA2AA">
        <path d="M17 5c6.627 0 12 5.373 12 12s-5.373 12-12 12S5 23.627 5 17 10.373 5 17 5zm0 1.6C11.256 6.6 6.6 11.256 6.6 17c0 5.744 4.656 10.4 10.4 10.4 5.744 0 10.4-4.656 10.4-10.4 0-5.744-4.656-10.4-10.4-10.4z"></path>
        <path d="M17 10.042a3.194 3.194 0 100 6.39 3.194 3.194 0 000-6.39zm5.397 8.74a11.501 11.501 0 00-10.794 0 1.01 1.01 0 00-.536.891v2.234c0 .588.477 1.065 1.065 1.065h9.736c.588 0 1.065-.477 1.065-1.065v-2.234c0-.373-.206-.715-.536-.89z"></path>
      </g>
    </svg>
  );
}

export default MobileProfile;