import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '26px',
    height: '26px',
    '& svg': {
      width: '26px',
      height: '26px',
    },
  },
  customSelectField: {
    minWidth: '100px',
    '& .MuiInputBase-input': {
      fontSize: '16px',
      fontWeight: 400,
      backgroundColor: 'white',
      padding: '5px 35px 5px 8px',
      borderRadius: '4px',
      color: '#828282',
    },
  },
}));

export default useStyles;