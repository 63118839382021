import { MouseEventHandler } from "react";

/**
 * Enum representing different unit types.
 */
export enum UnitType {
  Outdoor = 2,
  Service = 3,
  Mixed = 4,
}

/**
 * Mapping of script system unit types to their respective enum values.
 */
export const scriptSystemUnitTypes = {
  service: UnitType.Service,
  outdoor: UnitType.Outdoor,
  mixed: UnitType.Mixed,
};

/**
 * Represents an option for a unit.
 */
export interface IUnitOption {
  /** The ID of the unit option. */
  id: string;
  /** The name of the unit option. */
  name: string;
  /** The brand associated with the unit option. */
  brand?: number;
  /** The ID of the system associated with the unit option. */
  system?: string;
  /** The type of the entity (unit). */
  entityType: string;
  /** The type of the unit. */
  type: number;
}

/**
 * Represents an option for a system.
 */
export interface ISystemOption {
  /** The ID of the system option. */
  id: string;
  /** The name of the system option. */
  name: string;
  /** The brand associated with the system option. */
  brand?: number;
  /** The type of the entity (system). */
  entityType: string;
  /** Indicates if the system option is disabled. */
  isDisabled?: boolean;
  /** An array of unit options associated with the system. */
  units?: IUnitOption[];
}

/**
 * Represents a system with associated units.
 */
export interface ISystem {
  /** The ID of the system option. */
  id: string;
  /** The name of the system option. */
  name: string;
  /** The brand associated with the system option. */
  brand?: number;
  /** The type of the entity (system). */
  entityType: string;
  /** An array of unit options associated with the system. */
  units: IUnitOption[];
}

/**
 * Represents an entity option, which can be either a unit or a system.
 */
export type EntityOption = IUnitOption | ISystemOption;

/**
 * Represents a mixed step.
 */
export interface IMixedStep {
  /** The index of the mixed step. */
  index?: number;
  /** A flag indicating if the Step is a group. */
  isGroup?: boolean;
  /** The ID of the mixed step. */
  id?: number;
  /** The operator of the mixed step. */
  operator: string;
  /** The command of the mixed step. */
  command?: string;
  /** The condition of the mixed step. */
  condition?: string;
  /** The type of the mixed step. */
  type: string;
  /** The value of the mixed step. */
  value?: number | string;
  /** Indicates if the mixed step should proceed on failure. */
  proceedOnFailure?: boolean;
  /** The scale of the mixed step. */
  scale?: number;
  /** The scale type of the mixed step. */
  scaleType?: number;
  /** An array of selected entity options. */
  selectedEntities?: EntityOption[];
  /** An array of unit IDs associated with the mixed step. */
  units?: string[];
  /** An array of system IDs associated with the mixed step. */
  systems?: string[];
  /** An array of formatted steps. */
  steps?: any[];
  /** The type of the unit associated with the mixed step. */
  unitType?: UnitType;
  /** The threshold parameter of the mixed step. */
  thresholdParameter?: string;
  /** The threshold operator of the mixed step. */
  thresholdOperator?: string;
  /** value sourve holds the enum that determine where command value source is coming from. */
  valueSource?: number;
  parameter?: number;
}

/**
 * Represents a formatted single step (backend format).
 */
export interface IFormattedSingleStep {
  /** The type of the single step. */
  type: string;
  /** The operator of the single step. */
  operator: string;
  /** The value of the single step. */
  value: number | string | undefined;
  /** The command of the single step. */
  command?: string;
  /** The condition of the single step. */
  condition?: string;
  /** Indicates if the single step should proceed on failure. */
  proceedOnFailure?: boolean;
  /** The scale of the single step. */
  scale?: number;
  /** The scale type of the single step. */
  scaleType?: number;
  /** An array of unit IDs associated with the single step. */
  units?: string[];
  /** An array of system IDs associated with the single step. */
  systems?: string[];
  /** The type of the unit associated with the single step. */
  unitType?: UnitType;
  /** The threshold parameter of the single step. */
  thresholdParameter?: string;
  /** The threshold operator of the single step. */
  thresholdOperator?: string;
  valueSource?: number;
  parameter?: number;
}

/**
 * Represents a formatted group step (backend format).
 */
export interface IFormattedGroupStep {
  /** An array of unit IDs associated with the group step. */
  units?: string[];
  /** An array of system IDs associated with the group step. */
  systems?: string[];
  /** An array of formatted single steps. */
  steps: IFormattedSingleStep[];
}

/**
 * Represents a single step.
 */
export interface ISingleStep {
  /** The ID of the single step. */
  id: number;
  /** The operator of the single step. */
  operator: string;
  /** The command of the single step. */
  command: string;
  /** The condition of the single step. */
  condition: string;
  /** The type of the single step. */
  type: string;
  /** The value of the single step. */
  value: number;
  /** Indicates if the single step should proceed on failure. */
  proceedOnFailure?: boolean;
  /** An array of selected entity options. */
  selectedEntities: EntityOption[];
  /** The type of the unit associated with the single step. */
  unitType?: UnitType;
  /** value sourve holds the enum that determine where command value source is coming from. */
  valueSource: number;
  parameter: number;
}

/**
 * Represents a group step.
 */
export interface IGroupStep {
  /** The ID of the group step. */
  id: number;
  /** A flag indicating if the step is a group. */
  isGroup: boolean;
  /** An array of selected entity options. */
  selectedEntities: EntityOption[];
  /** An array of single steps. */
  steps: ISingleStep[];
  /** The type of the unit associated with the group step. */
  unitType?: UnitType;
}

/**
 * Represents a step, which can be either a single step or a group step.
 */
export type Step = ISingleStep | IGroupStep;

/**
 * Represents a mapping of site entities.
 */
export type SiteEntitiesMap = {
  [key: string]: EntityOption;
};

/**
 * Represents the props for the AddEditScript component.
 */
export interface IProps {
  /** The ID of the site. */
  siteId: string;
  /** An array of system and unit options. */
  systemUnitsOptions: Record<UnitType, EntityOption[]>;
  /** A mapping of site entities. */
  siteEntitesMap: SiteEntitiesMap;
  /** A function to close the component. */
  close: () => void;
  /** A function to create a script. */
  createScript: (script: any, siteId: string) => Promise<any>;
  /** A script object representing the script to be edited. */
  editScript?: any;
  /** A function to update a script. */
  updateScript: (script: any, id: string) => Promise<any>;
  /** A flag indicating if editing is allowed. */
  canEdit?: boolean;
}

/**
 * Represents a site entity.
 */
export interface ISiteEntity {
  /** The ID of the site entity. */
  id: string;
  /** The name of the site entity. */
  name: string;
  /** The brand of the site entity. */
  brand: string;
  /** The type of the site entity. */
  entityType: string;
}

/**
 * Represents an option for a system unit.
 */
export interface ISystemUnitOption {
  /** The ID of the system unit option. */
  id: string;
  /** The name of the system unit option. */
  name: string;
  /** The brand of the system unit option. */
  brand: string;
  /** An array of site entities associated with the system unit option. */
  units?: ISiteEntity[];
  /** The type of the system unit option. */
  entityType: string;
}

/**
 * Represents the props for the Condition component.
 */
export interface IConditionProps {
  /** Errors object from Formik, used to display validation errors. */
  errors: Record<string, any>;
  /** Touched object from Formik, used to check if a field has been touched. */
  touched: Record<string, any>;
  /** The name of the form field. */
  name: string;
  /** The condition object containing the details of the condition. */
  condition: Record<string, any>;
  /** Function to delete the condition. */
  deleteCondition: MouseEventHandler<HTMLButtonElement>;
  /** Index of the condition in the conditions array. */
  index: number;
  /** Function to set the value of a form field. */
  setFieldValue: (field: string, value: any) => void;
  /** Boolean indicating if the condition is disabled. */
  disabled: boolean;
  /** Props for moving the condition, typically used for drag-and-drop functionality. */
  moveProps?: Record<string, any>;
  /** Available step types for the condition. */
  stepTypes: Record<string, any>;
  /** Available command types for the condition. */
  commandsTypes: Record<string, any>;
  /** Available mixed parameters for the condition. */
  params: Array<any>;
  /** Available indoor parameters for the step/condition command. */
  indoorParams: Array<any>;
  /** Options for system units. */
  systemUnitsOptions: any;
  /** Map of entities, used to display specific units. */
  entitiesMap: Record<string, any>;
  /** Boolean indicating if specific units are enabled. */
  specificUnitsEnabled: boolean;
  /** Available service parameter types for the condition. */
  serviceParamTypes: Record<string, any>;
  /** Map of mixed parameters. */
  paramsMap: Record<string, any>;
  /** Function to select conditions to be grouped later. */
  onSelect?: (conditionId: number, status: boolean) => void;
  /** Boolean indicating if the condition belongs to a grouped step. */
  inGroup?: boolean;
  /** Function to handle unit type change. */
  handleUnitTypeChange?: (unitType: UnitType | "", index: number) => void;
}

/**
 * Represents the props for the Group component.
 */
export interface IGroupProps {
  /** The unique key of the group. */
  key: string | number;
  /** Errors associated with the group. */
  errors: Record<string, any>;
  /** Touched fields within the group. */
  touched: Record<string, any>;
  /** The name of the group. */
  name: string;
  /** The data associated with the group. */
  group: Record<string, any>;
  /** A function to delete a condition from the group. */
  deleteCondition: (conditionIndex: number) => void;
  /** The index of the group. */
  index: number;
  /** A function to set field value within the group. */
  setFieldValue: (field: string, value: any) => void;
  /** Indicates if the group is disabled. */
  disabled: boolean;
  /** Props for moving the group. */
  moveProps: Record<string, any>;
  /** Available step types. */
  stepTypes: Record<string, any>;
  /** Available command types. */
  commandsTypes: Record<string, any>;
  /** Array of mixed parameters. */
  params: Array<any>;
  /** Available indoor parameters. */
  indoorParams: Array<any>;
  /** Array of system unit options. */
  systemUnitsOptions: Array<any>;
  /** Mapping of entities. */
  entitiesMap: Record<string, any>;
  /** Indicates if specific units are enabled. */
  specificUnitsEnabled: boolean;
  /** Types of service parameters. */
  serviceParamTypes: Record<string, any>;
  /** Mapping of mixed parameters. */
  paramsMap: Record<string, any>;
  /** A function to ungroup the group. */
  unGroup: () => void;
  /** Function to get the grouped steps unit type. */
  getGroupedStepsUnitType: (steps: ISingleStep[]) => UnitType;
}

export interface IBaseProcedure {
  name: string;
  description: string;
  steps: IMixedStep[];
  userSelections: {
    brand: number | string;
    [key: string]: any;
  };
  runningMode: number;
  unitType: number;
  conditions?: any[];
  type: number;
  stepsRunningMode: number;
}

export interface IProcedure extends IBaseProcedure {
  id: string;
}

export interface IParam {
  code: number | string;
  title: string;
}
