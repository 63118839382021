import { makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: any) => ({
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    paddingBottom: "10px",
    height: "83px",
    backgroundImage: theme.gradiants.button,
    paddingRight: "6px",
    paddingLeft: "10px",
  },
  appBarStyle: {
    height: "45px",
    boxShadow: `0px 5px 9px 0 ${alpha(
      theme.palette.colors.black || "#000",
      0.2
    )}`,
    background: "black",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iosPadding: {
    paddingTop: `env(safe-area-inset-top)`,
    height: "unset",
  },
  buttonText: {
    textDecoration: "underline",
    color: "gray",
    textTransform: "none",
    fontWeight: "bold",
  },
  titleStyle: {
    width: "100%",
    color: "white",
    fontSize: "18px",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftActionStyle: {
    display: "flex",
    justifyContent: "flex-start",
  },
  titleWrapper: {
    width: "300px",
    display: "flex",
    justifyContent: "center",
    flexFlow: "column nowrap",
    fontSize: 18,
    fontWeight: "bold",
    alignItems: "center",
    cursor: "pointer",
  },
  rightActionStyle: {
    display: "flex",
    justifyContent: "flex-end",
    width: 27,
  },
  rightButtonStyle: { padding: 0 },
  addPadding: { padding: "9px 10px" },
  paper: {
    height: "390px",
    padding: "20px 20px 44px 20px",
    backgroundImage: theme.gradiants.themeBackground,
    borderRadius: 0,
    borderBottomLeftRadius: "22px",
    borderBottomRightRadius: "22px",
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    justifyContent: "space-between",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.colors.white,
    marginBottom: "7px",
  },
  collapse: {
    position: "absolute",
    zIndex: 10,
    width: "100vw",
  },
  siteUnitName: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.colors.white,
    maxWidth: "26ch",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dropDownContainer: {
    marginBottom: 15,
  },
  submitBtn: {
    height: 50,
    minHeight: 50,
    marginTop: 15,
  },
  drawerStyle: {
    left: "auto !important",
    right: "auto !important",
  },
  //
  root: {
    width: "100%",
    left: "auto",
    right: "auto",
    margin: "auto",
    "@media (min-width: 769px)": {
      maxWidth: "375px",
      maxHeight: "812px",
      "& div:first-of-type:first-child": {
        maxWidth: "375px",
        maxHeight: "812px",
      },
    },
    "@media (min-height: 1025px)": {
      maxWidth: "375px",
      maxHeight: "812px",
      "& div:first-of-type:first-child": {
        maxWidth: "375px",
        maxHeight: "812px",
      },
    },
  },
  drawerPaper: {
    width: "85%",
    background: theme.gradiants.button,
    maxWidth: "500px",
    position: "absolute",
    flexDirection: "row",
    margin: "auto",
    top: "50%",
    bottom: "50%",
  },
  bigAvatar: {
    transform: "scale(0.8727)",
    marginTop: "14px",
    marginBottom: "5px",
  },
  menuHeader: {
    flexFlow: "column",
    alignItems: "center",
    paddingTop: "14px",
  },
  divider: {
    opacity: 0.2,
    background: theme.palette.colors.gray5,
  },
  listItemText: {
    opacity: 0.7,
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontSize: "16px",
    color: theme.palette.colors.gray5,
  },

  closeButton: { alignSelf: "flex-start" },
  menuBody: {
    paddingLeft: "27px",
    height: "94%",
    maxHeight: "850px",
    margin: 0,
    width: "100%",
  },
  usernameStyle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    textAlign: "right",
    color: theme.palette.colors.white,
    lineHeight: "normal",
  },
  roleStyle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    color: alpha(theme.palette.colors.white || "#fff", 0.7),
    lineHeight: "normal",
  },
  listItemStyle: {
    display: "flex",
    justifyContent: "space-between",
    justifyItems: "center",
    paddingLeft: "3px",
    paddingRight: "14px",
    height: "54px",
  },
  iconTextContainer: {
    display: "inline-flex",
    justifyItems: "center",
    justifyContent: "center",
  },
  menuIconsStyle: {
    minWidth: "17px",
    minHeight: "17px",
    alignSelf: "center",
    paddingRight: "11px",
    fontSize: "20px",
    fill: alpha(theme.palette.colors.gray5 || "#fdfdfe", 0.8),
  },
  arrowIconStyle: {
    fontSize: "16px",
    opacity: 0.6,
    color: theme.palette.colors.gray5,
  },
  alertIcon: {
    "& g": {
      "& mask": {
        fill: "white",
      },
      "& path": {
        fill: "white",
      },
    },
  },
  auditIcon: {
    "& g": {
      "& path": {
        stroke: "white",
      },
    },
  },
  iconHolder: {
    width: 28,
    paddingRight: 11,
    margin: "auto",
  },
  iconBtn: {
    width: 30,
    height: 30,
    padding: 0,
    borderRadius: 6,
    alignSelf: "flex-start",
  },
  smallPaper: {
    height: 217,
  },
  extraSmallPaper: {
    height: 124,
  },
  smallPaperWithSearchField: {
    height: 343,
  },
  extraSmallPaperWithSearchField: {
    height: 260,
  },
  titleText: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.colors.white,
  },
  menuIcon: {
    color: theme.palette.colors.gray4,
    fontSize: "25px",
  },
  menuIconBtn: {
    marginRight: "-7px",
    padding: "7px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  searchIcon: {
    color: theme.palette.colors.white,
  },
  autoCompletePoper: {
    backgroundColor: theme.palette.primary.dark,
  },
  autoCompleteItem: {
    backgroundColor: theme.palette.colors.black,
    paddingTop: 0,
    color: theme.palette.colors.white,
    "& li": {
      margin: "5px 10px",
    },
  },
  autoCompletePaper: {
    backgroundColor: theme.palette.colors.black,
  },
  autoCompleteGroup: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.colors.white,
    fontWeight: "bold",
    fontSize: "17px",
  },
  autoCompleteGroupUl: {
    backgroundColor: theme.palette.background.searchList,
  },
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: alpha(
      theme.palette.colors.gray1 || "rgb(170, 162, 170)",
      0.3
    ),
    marginRight: "40px",
  },
}));
export default useStyles;
