import { Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

interface ICountdownProps {
    time: number; // time in minutes
    isRunning: boolean;
}

const Counter: React.FC<ICountdownProps> = ({ time, isRunning }) => {
    const [timeLeft, setTimeLeft] = useState<number>(time * 60); // convert minutes to seconds

    useEffect(() => {
        setTimeLeft(time * 60)
    }, [time])

    const theme: any = useTheme();
    useEffect(() => {
        if (!isRunning || timeLeft <= 0) {
            return;
        }

        const intervalId = setInterval(() => {
            setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [isRunning, time]);

    // Formatting the time into hours, minutes, and seconds
    const formatTime = (seconds: number) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        return `${hrs > 0 ? `${hrs}h ` : ""}${mins > 0 ? `${mins}` : "0"}m`;
    };

    return (
        <Typography style={{
            color: theme.palette.colors.white,
            fontSize: "16px"
        }}>{formatTime(isRunning ? timeLeft : time * 60)}</Typography>
    );
};

export default Counter;
