import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	iconStyle: {
		fontSize: '17.5px',
	},
	modeIcon: {
		margin: '0 5px',
	},
	smallIcons: {
		transform: 'scale(0.5)',
		margin: 0,
	},
	inLetters: {
		fontSize: 20,
		fontWeight: 600,
		color: '#fff'
	}
}));

export default useStyles;