import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  modes: {
    display: "none",
    visibility: "hidden"
  },
  item: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "100%"
  },
  card: {
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    padding: "10px",
    boxSizing: "border-box",
    minWidth: "19rem",
    borderRadius: "10px",
    border: "solid 1px #ebebeb"
  },
  itemOn: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "100%",
    borderRight: "1px lightgray solid"
  },
  controlContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    height: "100%"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    marginBottom: "5px",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#f6f6f6"
    }
  },
  selectedButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    marginBottom: "5px",
    padding: "6px",
    width: "2.8rem",
    height: "2.8rem"
  },
  powerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f6f6f7",
    marginBottom: "5px",
    padding: "0"
  },

  modeText: {
    fontWeight: 100,
    fontSize: "1rem"
  },

  icon: {
    fontSize: "1.8rem"
  },

  modeButton: {
    display: "flex",
    flexDirection: "column"
  },

  snowflake: {
    color: "#35a8e0"
  },
  sun: {
    color: "#f05146"
  },
  tint: {
    color: "green"
  },
  fan: {
    color: "purple"
  },

  menu: {
    borderRadius: "50px",
    marginTop: "0.2rem"
  },
  "MuiPopover-paper": {
    borderRadius: "50px !important"
  }
});

export default useStyles;
