import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { filter, isEmpty, orderBy } from "lodash";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close, Search } from "../../icons";
import { DeleteIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./DisconnectedUnitsDialog.style";

const UnassignedUnitsDialog = (props: any) => {
  const { close, deviceId, deviceName, actions, type } = props;
  const classes = useStyles();

  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getDeviceUnits = useStoreActions((actions) => actions.devices.getDeviceUnitsAPI);
  const deleteUnits = useStoreActions((action) => action.units.deleteUnits);
  const types = useStoreState((state) => state.types);
  const { unitTypes } = types;

  const [units, setUnits] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedUnits, setSelectedUnits] = useState<Set<string>>(new Set());
  const [refresh, setRefresh] = useState<boolean>(true);

  useEffect(() => {
    if (!deviceId) {
      return;
    }
    getDeviceUnits({ id: deviceId }).then((unitsResp: any) => {
      const unitsTemp: any = {};
      Object.values(unitsResp).forEach((unit: any) => {
        if (unitTypes[type] === unit.type && !unit.isConnected) {
          unitsTemp[unit.id] = unit;
        }
      });

      setUnits(unitsTemp);
      setIsLoading(false);
    })
      .catch((err: any) => addMessage({ message: err.message }));
  }, [deviceId, refresh]);

  const getFilteredUnits = () => {
    const filtered = !searchValue
      ? units
      : filter(units, (unit) =>
          unit.name.toLowerCase().includes(searchValue.toLowerCase())
        );
    return orderBy(
      filtered,
      [(unit: any) => unit.name?.toLowerCase()],
      ["asc"]
    );
  };

  const filteredUnits = useMemo(getFilteredUnits, [units, searchValue]);

  const handleCheckboxChange = useCallback(
    (id: string) => {
      const newSelectedUnits = new Set(selectedUnits);
      if (newSelectedUnits.has(id)) {
        newSelectedUnits.delete(id);
      } else {
        newSelectedUnits.add(id);
      }
      setSelectedUnits(newSelectedUnits);
    },
    [selectedUnits]
  );

  const handleSelectAllChange = useCallback(
    (event: any) => {
      if (event.target.checked) {
        const allUnitIds = new Set<string>(
          filteredUnits.map((unit: any) => unit.id as string)
        );
        setSelectedUnits(allUnitIds);
      } else {
        setSelectedUnits(new Set<string>());
      }
    },
    [filteredUnits]
  );

  const isUnitSelected = (id: string) => selectedUnits.has(id);
  const isAllSelected = useMemo(
    () => selectedUnits.size === filteredUnits.length,
    [selectedUnits, filteredUnits]
  );
  const isIndeterminate = useMemo(
    () => selectedUnits.size > 0 && selectedUnits.size < filteredUnits.length,
    [selectedUnits, filteredUnits]
  );

  const bulkDelete = async () => {
    setIsLoading(true);
    try {
      await deleteUnits(Array.from(selectedUnits));
      setSelectedUnits(new Set<string>());
    } catch (error: any) {
      addMessage({ message: error.message });
    } finally {
      setIsLoading(false);
      setRefresh(!refresh);
    }
  };

  return (
    <Dialog
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="unassign-dialog"
      open={deviceId}
    >
      <div className={classes.dialogHeader}>
        <Typography
          className={classes.headerTitle}
        >{t`Disconnected Units`}</Typography>
        <div>
          <TextField
            placeholder={t`Search...`}
            value={searchValue}
            onChange={(event: any) => setSearchValue(event.target.value)}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.inputRoot },
              endAdornment: !searchValue ? (
                <Search />
              ) : (
                <IconButton
                  onClick={() => setSearchValue("")}
                  className={classes.closeIconStyle}
                >
                  <Close />
                </IconButton>
              ),
            }}
          />
          <IconButton
            disableRipple
            className={classes.iconBtnStyle}
            onClick={close}
          >
            <Close color="#7f7692" />
          </IconButton>
        </div>
      </div>
      <DialogContent className={classes.dialogContent}>
        {isLoading ? (
          <div className={classes.loaderHolder}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={classes.deleteSection}>
              <IconButton
                disabled={isEmpty(selectedUnits)}
                disableRipple
                onClick={() => {
                  bulkDelete();
                }}
                classes={{ root: classes.iconBtnStyle }}
              >
                <DeleteIcon width={25} height={25} />
              </IconButton>
              <Typography>
                {t`To delete units, select units and click the basket`}
              </Typography>
            </div>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="customized table"
              >
                <TableBody>
                  {!isEmpty(filteredUnits) && (
                    <TableRow
                      key={`unit-row-allunits`}
                      classes={{ root: classes.overWritePaddingRow }}
                    >
                      <TableCell
                        classes={{ root: classes.overWritePadding }}
                        align="center"
                        style={{ maxWidth: 20, padding: 0 }}
                      >
                        <Checkbox
                          indeterminate={isIndeterminate}
                          checked={isAllSelected}
                          onChange={handleSelectAllChange}
                        />
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.overWritePadding }}
                        align="left"
                      >
                        {t`Select all`}
                      </TableCell>
                    </TableRow>
                  )}
                  {filteredUnits.map(({ name, id }: any) => (
                    <TableRow
                      key={`unit-row-${id}`}
                      classes={{ root: classes.overWritePaddingRow }}
                    >
                      <TableCell
                        classes={{ root: classes.overWritePadding }}
                        align="center"
                        style={{ maxWidth: 20, padding: 0 }}
                      >
                        <Checkbox
                          checked={isUnitSelected(id)}
                          onChange={() => handleCheckboxChange(id)}
                        />
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.overWritePadding }}
                        align="left"
                      >
                        {name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actionsHolder}>
          <Button width={160} onMouseDown={close}>{t`Done`}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnassignedUnitsDialog;
