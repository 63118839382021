export const getState = (state) => {
	const allSites = state.sites?.allSites ?? {};
	const selectedSiteId = state.selections.selections?.siteId ?? '';
	const selectedSite = allSites[selectedSiteId] ?? {};
	const {canDeletePowerReportSchedules, canUpdatePowerReportSchedules,supportedReportTypes = []} = selectedSite.permissions ?? {};
	const types = state.types ?? [];
	const getSystemsBySiteId = state.sites.getSiteSystems;


	return {
		canDeletePowerReportSchedules,
		canUpdatePowerReportSchedules,
		supportedReportTypes,
		types,
		selectedSiteId,
		getSystemsBySiteId, //function in get state?!
		defDateFormat: state?.users?.me?.dateFormat ?? 0
	};
	

};

export const getActions = (actions) => {

	const {removeReport} = actions.scheduledReports;
	

	return {
		getZonesBySiteId:actions.zones.getZonesBySiteId,
		removeReport
	};
};