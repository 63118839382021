import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import { Typography, Grid } from '@material-ui/core';
import ImmediateReportRangePicker from './components/ImmediateReportRangePicker';
import TimeUnitSelector from './components/TimeUnitSelector';

import useStyles from '../../AddEditDialog.style';
import styles from './ReportFrequencySelector.module.css';
import DaySelector from './components/DaySelector';
import MonthOptionsSelector from './components/MonthOptionsSelector';


const validateImmediateReport = (value, values) => {
	const {timeUnit} = values;
	if(timeUnit === 'now'){
		return !value;
	}
	return false;
    
};

const validateDaySelector = (value, values) => {
	const {timeUnit} = values;
	if(timeUnit === 'week'){
		return !value;
	}
	return false;
    
};

const validateMonthOptionsSelector = (value, values) => {
	const {timeUnit} = values;
	if(timeUnit === 'month'){
		return !value;
	}
	return false;
    
};

const getTimeUnits = (showImmediateReport) => {
	if(showImmediateReport){
		return [{value:'now',text:'Once'},{value:'day',text:'Daily'},{value:'week',text:'Weekly'},{value:'month',text:'Monthly'}];
	}

	return [{value:'day',text:'Daily'},{value:'week',text:'Weekly'},{value:'month',text:'Monthly'}];

};

//todo: fix the issue where changing the timeUnit reset the initial value of specificDay
const resetSubTimeUnit = ({change,updateErrorByKey}) => {
	change('subTimeUnit','');
	change('specificDay','');
	updateErrorByKey('subTimeUnit');
	updateErrorByKey('specificDay');
};

const ReportFrequencySelector = ({disabled,showImmediateReport,connect,values,errors}) => {

	const classes = useStyles();

	const {timeUnit} = values;
	const {timeUnit:timeUnitError} = errors;


	const renderReportFrequency = () => {
		if(timeUnit === 'now'){
			return (
				<ImmediateReportRangePicker 
					disabled={disabled}
					{...connect('immediateReportDateRange',{
						customValuePropName:'dateRange',
						validations:[
							{validator:validateImmediateReport,message:t`When creating immediate report a date range is required`}
						]
					})}
				/>
			);
		}

		if(timeUnit === 'day'){
			return null;
		}

		if(timeUnit === 'week'){
			return (
				<DaySelector 
					disabled={disabled}
					{...connect('subTimeUnit',{
						validations:[
							{validator:validateDaySelector,message:t`Please Select a day`}
						]
					})}
				/>
			);
		}

		if(timeUnit === 'month'){
			return (
				<MonthOptionsSelector
					connect={connect}
					{...connect('subTimeUnit',{
						validations:[
							{validator:validateMonthOptionsSelector,message:t`Please select one of the options.`}
						]
					})}
				/>
			);
		}

	};

    
	return (
		<div className={styles['report-frequency-selector']}>
			<Typography className={classes.title}>{t`Report Frequency`}</Typography>
	
			<TimeUnitSelector
				options={getTimeUnits(showImmediateReport)}
				{...connect('timeUnit',{
					customOnChange:resetSubTimeUnit,
					validations:[{required:t`Please select report frequency.`}]
				})}
			/>

			<Grid item={true} xs={12} className={classes.selectTime}>
				{renderReportFrequency()}
			</Grid>

			<Typography className={classes.error}>{timeUnitError}</Typography>
		</div>
	);
};

ReportFrequencySelector.propTypes = {
	connect:PropTypes.func.isRequired,
	values:PropTypes.object.isRequired,
	errors:PropTypes.object.isRequired,
	disabled:PropTypes.bool,
	showImmediateReport:PropTypes.bool,
};

ReportFrequencySelector.defaultProps = {
	disabled:false,
	showImmediateReport:false
};

export default ReportFrequencySelector;