import { alpha, makeStyles } from '@material-ui/core';

// todo: remove unused classes.
// todo: remove the classes that are used here in OneTenant.style.ts file.
const useStyles = makeStyles((theme) => ({
	view: {
		display: 'flex',
		height: '-webkit-fill-available',
		width: '100%',
		background: 'rgb(236, 235, 239)',
		overflowY: 'auto',
		overflowX: 'auto',
	},

	nameContainer: {
		paddingRight: '30px',
		paddingTop:'5px'
	},
	headerStyle: {
		fontWeight: 'bold',
		fontSize: '20px',
		color: '#4b1c46',
		marginBottom: '20px',
	},
	fullLineHeader:{
		width:'100%'
	},

	title: {
		margin: '10px 0',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 500,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: theme.palette.primary.light,
	},
	subHeaderStyle: {
		color: '#545964',
		fontSize: '20px',
	},
	subSubHeaderStyle: {
		color: '#4b1c46',
		fontSize: '15px',
	},

	textFieldValue: {
		color: '#aaa2aa',
		width: '100%',
		height: 50,
		'& div': {
			height: 50,
		},
	},

	rightCard: {
		paddingLeft: '20px',
		borderLeft: '1px solid #d5d2d5',
		width: '100%',
	},

	unitListContainer: {
		width: '100%',
	},

	oneTenantContainer: {
		height: '75%',
		overflow: 'auto',
		paddingBottom: '1rem',
		padding: '0 20px',
		width: '97.5%',
		marginLeft: '1.2rem',
		marginTop: '30px',
		paddingTop: '15px',
		backgroundColor: 'white',
	},

	tenantGridContainer: {
		padding: '0 20px',
		display: 'flex',
		overflow: 'auto',
		width: '97.5%',
		marginLeft: '1.2rem',
		marginTop: '30px',
		paddingTop: '15px',
	},

	oneTenantBtn: {
		width: '5rem',
		marginLeft: '12px',
	},

	tenantUnitLists: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
		height: '50vh',
		width: '100%',
	},
	/// *************** no filter screen
	noContentContainer: {
		boxShadow: '0 2px 5px -5px rgba(180, 175, 203, 0.63)',
		backgroundColor: '#fefefe',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 0,
		width: 'calc(100% - 60px)',
		height: 'calc(100% - 130px)',
		alignSelf: 'center',
		marginTop: '30px',
	},

	grayOval: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '136px',
		height: '136px',
		backgroundColor: 'rgba(170, 162, 170, 0.1)',
		borderRadius: '50%',
	},
	arrowIcon: { fontSize: '91px', transform: 'rotate(90deg)' },
	noUnitText: {
		fontSize: '26px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		textAlign: 'center',
		color: '#545964',
		marginTop: '43px',
	},
	error: {
		color: 'red',
	},
	dialog: {
		width: '100%',
		borderRadius: '8px',
	},

	// **************************************

	contentArea: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		background: 'rgb(236, 235, 239)',
		maxHeight: '-webkit-fill-available',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: '0 16px',
		minHeight: '70px',
	},

	// ************************
	usersContainer: {
		height: '100%',
		display: 'flex',
		paddingBottom: '1rem',
	},

	tableContainer: {
		padding: '20px',
		display: 'flex',
		flexFlow: 'row nowrap',
		overflow: 'auto',
		flex: 1,
		maxWidth: 'calc(100vw - 265px)',
	},
	paperTableContainer: {
		width: 'inherit',
	},
	paperLeftContainer: {
		height: '100%',
		background: 'white',
		display: 'flex',
		flexFlow: 'row nowrap',
		width: '30%',
		padding: 20,
		paddingRight: 0,
		maxWidth: 400,
	},
	scrollContainer: {
		display: 'flex',
		overflow: 'auto',
		marginRight: 5,
		'&::-webkit-scrollbar': {
			width: '4px',
			backgroundColor: 'transparent',
			borderRadius: 2,
		},
		'&::-webkit-scrollbar-track': {
			backgroundColor: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#dfdde1',
		},
		maxHeight: '100%',
		height: '100%',
		flexFlow: 'column nowrap',
		paddingRight: '15px',
		flex: 1,
	},
	rightContainer: {
		height: '100%',
		maxHeight: '100%',
		background: 'white',
		display: 'flex',
		flexFlow: 'column nowrap',
		flex: 1,
		width: '70%',
	},

	table: {
		padding: '1rem 1rem',
		background: theme.palette.background.paper,
	},
	tableHeadRow: {
		height: 45,
		padding: 0,
	},
	tableHeadCell: {
		backgroundColor: theme.tables.headerCell.backgroundColor,
	},

	overWritePadding: {
		...theme.tables.tableCell,
	},

	editIcon: {
		fontSize: '1rem',
		cursor: 'pointer',
		color: 'initial',
		'&:hover': {
			color: 'green',
		},
	},
	deleteIcon: {
		fontSize: '1rem',
		cursor: 'pointer',
		color: 'initial',
		'&:hover': {
			color: 'red',
		},
	},

	overWriteIcon: {
		width: 30,
		height: 30,
		borderRadius: 6,
		padding: 0,
	},

	// ************************ reports
	reportContainer: {
		marginTop: '2%',
		marginLeft: '.5%',
		marginRight: '.5%',
		width: '100%',
		height: '77vh',
	},

	cardcontent: {
		height: '100%',
	},

	subSectionReports: {
		marginTop: '10px',
		marginLeft: '38px',
	},

	datePickerContanier: {
		padding: '0 20px',
		display: 'flex',
		overflow: 'auto',
	},
	datePickerCardContainer: {
		marginTop: '1%',
		marginLeft: '2%',
		marginRight: '2%',
		width: '100%',
	},
	selectDateRangeButton: {
		width: '30ch',
		height: 36,
		justifyContent: 'space-between',
		margin: '1rem .5rem',
		padding: '0 .4rem 0 .5rem',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.common.white,
		},
	},
	selectDateRangeButton__text: {
		fontSize: '0.8125rem',
		fontWeight: 'normal',
	},
	selectDateRangeButton__icon: {
		'&>svg': {
			fontSize: '1.5rem',
		},
	},
	rangePickerContainer: {
		backgroundColor: '#fff',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		boxShadow: '5px 5px 10px 0px rgba(138,138,138,1)',
	},

	calendarButtons: {
		display: 'flex',
		flexFlow: 'row nowrap',
		width: '100%',
		justifyContent: 'flex-end',
		paddingRight: '20px',
		alignItems: 'center',
		marginTop: '-10px',
		paddingBottom: '10px',
	},

	// ************************* reports
	chipContainer: {
		width: '100%',
		display: 'flex',
		overflowY: 'auto',
		overflowX: 'hidden',
		maxWidth: '100%',
		minHeight: 106,
		maxHeight: 106,
		flexFlow: 'row nowrap',
		padding: '0 20px',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	powerBtns: {
		display: 'flex',
		flexFlow: 'row wrap',
		overflow: 'auto',
	},

	tenantChips: {},

	btnUsage: {
		width: 'fit-content',
		minWidth: 155,
		height: 40,
		marginRight: 16,
		borderRadius: 28,
		backgroundColor: '#efeff2',
		marginBottom: 10,
		textTransform: 'none',
	},

	btnUsageTextState: {
		fontSize: '18px',
		marginRight: '2px',
		fontFamily: 'Roboto',
		position: 'relative',
		bottom: 0,
	},
	btnUsageTextStateSmaller: {
		position: 'relative',
		top: '-1px',
	},

	btnUsageTextDate: {
		marginRight: '4px',
		marginLeft: '4px',
		fontSize: '22px',
		fontWeight: '500',
		fontFamily: 'RobotoMedium',
		position: 'relative',
		bottom: 0,
	},

	btnUsageTextType: {
		fontSize: '15px',
		position: 'relative',
		bottom: 0,
	},

	coolUsage: {
		color: '#35a8e0',
		'&:hover': {
			backgroundColor: 'white',
			boxShadow: 'none',
			color: ' #35a8e0',
		},
		'&:focus': {
			//check focus effects
			backgroundColor: '#35a8e0',
			boxShadow: 'none',
			color: ' white',
		},
	},

	heatUsage: {
		color: '#f05146',
		'&:hover': {
			backgroundColor: 'white',
			boxShadow: 'none',
			color: '#f05146',
		},
		'&:focus': {
			backgroundColor: '#f05146',
			boxShadow: 'none',
			color: ' white',
		},
	},

	otherUsageSelected: {
		backgroundColor: '#dfdde1',
		boxShadow: 'none',
		color: '#545964',
		'&:focus': {
			backgroundColor: '#dfdde1',
			boxShadow:
        ' 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
			color: '#545964',
		},
	},

	missingUsage: {
		color: '#7a6095',
		'&:hover': {
			backgroundColor: 'white',
			boxShadow: 'none',
			color: '#7a6095',
		},
		'&:focus': {
			backgroundColor: '#7a6095',
			boxShadow: 'none',
			color: ' white',
		},
	},

	activeCoolUsage: {
		boxShadow: 'none',
		backgroundColor: '#35a8e0',
		color: ' white',
	},

	activeHeatUsage: {
		boxShadow: 'none',
		backgroundColor: '#f05146',
		color: ' white',
	},
	activeMissingUsage: {
		boxShadow: 'none',
		backgroundColor: '#7a6095',
		color: 'white',
	},

	activeBtnUsage: {
		boxShadow: 'none',
		backgroundColor: '#dfdde1',
	},

	reportSystemTitle: {
		color: '#4b1c46',
		fontSize: '20px',
		marginTop: '10px',
		marginBottom: '20px',
		fontFamily: 'RobotoBold',
		fontWeight: '500',
	},

	listSeperator: {
		width: '100%',
		height: '2px',
		border: 'solid 1px #e1e2ea',
		marginTop: '40px',
	},

	reportCounterStyle: {
		color: '#545964',
		fontSize: '20px',
		fontFamily: 'RobotoLight',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		marginLeft: 15,
		fontWeight: 300,
	},

	reportTitle: {
		fontFamily: 'RobotoMedium',
		fontSize: 20,
		fontWeight: 500,
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#4b1c46',
	},

	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '100%',
		minHeight: '65px',
		alignItems: 'center',
		padding: '0 20px',
	},
	divider: {
		borderBottom: 'solid 1px #e1e2ea',
		height: 1,
		width: 'calc(100% - 40px)',
		alignSelf: 'center',
	},

	listStyle: {
		width: '100%',
		minHeight: 50,
		marginTop: 10,
		boxShadow: '0 2px 0 -1px rgba(205, 205, 205, 0.5)', // try none
		border: 'solid 1px rgba(183, 185, 203, 0.37)',
		backgroundColor: '#ffffff',
		fontFamily: 'Roboto',
		fontSize: 16,
		fontWeight: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#29132e',
		textTransform: 'none',
		'&:hover': {
			boxShadow: '0 2px 0 -1px rgba(205, 205, 205, 0.5)',
			border: 'solid 1px rgba(183, 185, 203, 0.37)',
			backgroundColor: alpha('#edebed', 0.4),
		},
		'&:active': {
			boxShadow: '0 2px 0 -1px rgba(205, 205, 205, 0.5)',
			border: 'solid 1px rgba(183, 185, 203, 0.37)',
			backgroundColor: alpha('#edebed', 0.4),
		},
	},

	spinner: {
		zIndex: '999',
		position: 'absolute',
		top: '50vh',
		left: '55vw',
	},

	graphDropDown: {
		width: 140,
		boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
		backgroundColor: '##fff',
		minWidth: 140,
		height: 40,
	},

	graphDropDownStyle: {
		marginTop: '2px',
		height: 40,
		'&>svg': {
			fontSize: '2.5rem !important',
			top: '0',
			height: 40,
			width: '37px',
		},
	},

	graphContainer: {
		height: '100%',
		maxHeight: '100%',
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	graphSection: {
		display: 'flex',
		flex: 1,
		flexFlow: 'column nowrap',
		height: 'calc(100% - 170px)',
		maxHeight: 'calc(100% - 170px)',
	},

	legendContainer: {
		width: 'calc(100% - 90px)',
		height: '30px',
		marginLeft: '68px',
		marginTop: '20px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	graphLabelText: {
		fontFamily: 'Roboto',
		fontSize: '12px',
		marginRight: '15px',
		display: 'inline-block',
	},
	graphLabelIcon: {
		height: '12px',
		width: '12px',
		marginRight: '4px',
		backgroundColor: '#aaa2aa',
		display: 'inline-block',
	},
	graphLabelIconHeat: {
		backgroundColor: '#f05347',
	},
	graphLabelIconCool: {
		backgroundColor: '#35a8e0',
	},
	graphLabelIconMissing: {
		backgroundColor: '#7a6095',
	},
	graphLabelIconExcess: {
		backgroundColor: '#4b1c46',
	},
	graphLabelIconTime: {
		backgroundColor: '#5e5764',
	},
	graphLabelIconPower: {
		backgroundColor: '#666',
	},
	subSectionReportsGraph: {
		position: 'relative',
		bottom: '-4.5%',
		height: '80%',
		display: 'flex',
		width: ' 100%',
	},
	subSectionReportsGraphAfterFetch: {
		bottom: '1%',
	},

	summeryContainer: {
		minHeight: '5rem',
		maxHight: '125px',
	},
	selectedItem: {
		backgroundColor: alpha('#dfdde1', 0.5),
	},
	capacityFlagged: {
		color: 'red',
	},
	capacityFlaggedTitle: {
		color: 'red',
		fontSize: '20px',
		display: 'inline',
		fontFamily: 'RobotoBold',
	},

	reportDropDown: {
		width: '9rem',
	},

	selectTime: {
		marginTop: '15px',
	},

	// ______________________________________scheduelr __________________________________
	oneReportContainer: {
		height: '100%',
		overflow: 'auto',
		width: '100%',
		backgroundColor: 'white',
		maxHeight: '100%',
	},
	oneReportContent: {
		overflow: 'unset',
	},

	headerColumnTitle: {
		fontFamily: 'RobotoMedium',
		fontSize: '20px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#4b1c46',
		borderBottom: '1px solid #ebebeb',
		paddingBottom: 20,
		height: 46,
		marginBottom: 30,
	},
	oneSchedulerLeftHeader: {
		borderBottom: 'none',
		marginBottom: '0',
		paddingBottom: '0',
	},
	oneSchedulerLeftHeaderSystem: {
		marginTop: '1rem',
		marginBottom: '15px',
		borderBottom: '1px solid #ebebeb',
		height: '35px',
	},
	btnAdd: {
		float: 'right',
		marginTop: '5px',
	},
	addRecipients: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: -3,
		paddingBottom: 17,
	},
	reportNameStyle: {
		width: '100%',
		height: '56px',
		borderRadius: '5px',
		backgroundColor: '#ffffff',
		marginBottom:30
	},
	leftSideRecipients: {
		paddingLeft: '20px',
		flexDirection: 'column',
		justifyContent: 'start',
		flexWrap: 'no-wrap',
	},
	middleSideRecipients: {
		paddingLeft: '20px',
		paddingRight: '20px',
		borderRight: '1px solid #ebebeb',
		borderLeft: '1px solid #ebebeb',
		flexFlow: 'column nowrap',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	middleSideSection: {
		width: '100%',
		height: '40%',
		flex:1,
	},
	middleSideSectionScrollable: {
		overflowY: 'auto',
		width: '100%',
		height: 'calc(100% - 50px)'
	},
	searchUserScheduler: {
		width: '100%',
		height: '52px',
		borderRadius: '4px',
		backgroundColor: 'rgba(239, 239, 242, 0.48)',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#4b4850',
		border: 0,
		'& *': {
			border: 0,
		},
		'& div': {
			height: 52,
		},
	},

	reportsFormatWrtapper: {
		// marginTop: "15px"
		// float: 'left'
	},

	selectSystems:{
		width:'100%',
		overflow:'hidden'
	},

	systemSelectorWrapper:{
		width:'100%',
		paddingBottom:'20px'
	},
	multiSelect:{
		width:'100%',
		height:'56px',
		borderRadius: '5px',
		backgroundColor: '#ffffff',
	},
	formatSelect: {
		width: '100%',
		height: '40px',
		borderRadius: '4px',
		boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
		border: 'solid 1px #d5d2d5',
		backgroundColor: '#ffffff',
		// For the text inside
		color: '#545964',
		fontSize: '13px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		marginTop: 7,
		paddingLeft: 10,
		'&>svg': {
			fontSize: '2.5rem !important',
			top: '0',
			height: 40,
			width: '37px',
		},
	},

	reportFormatWrapper:{
		width:'45%',
	},

	reportFormatSelect: {
		width: '100%',
		height: '40px',
		borderRadius: '4px',
		boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
		border: 'solid 1px #d5d2d5',
		position:'relative',
		padding:'0 5px',
		fontSize: '13px',
		lineHeight: 'normal',
		'&>svg': {
			fontSize:40,
			height:'100%',
			position:'absolute',
			top:0

		},
	},

	formatLabelSelect: {
		width: '66px',
		height: '15px',
		fontFamily: 'Roboto',
		fontSize: '13px',
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#7f7182',
	},

	btnGroupTimeunits: {
		height: '50px',
		width: '100%',
		borderRadius: '8px',
		boxShadow: '0 3px 6px -6px #aaa2aa',
		border: 0,
		'& button': {
			flex: 1,
		},
	},

	btnGroupErr: {
		color: 'red',
	},

	reportDialog: {
		width: '100%',
		borderRadius: '8px',
		height: '800px',
		overflow: 'unset',
	},

	selectedBtn: {
		backgroundColor: '#edebed',
		color: '#736973',
	},

	newUserStyle: {
		marginBottom: '30px',
	},

	cancelBtnGlobalStyle: {
		height: '40px',
		minWidth: '169px',
		fontSize: '16px',
		fontWeight: 'normal',
		lineHeight: 'normal',
		color: '#4b1c46',
		borderRadius: '4px',
		background: 'white',
		border: 'solid 1px #413044',
		marginRight: '30px',
		'&:hover': {
			background: 'rgba(75, 28, 70, 0.1)',
		},
		'&:focus': {
			background: 'rgba(75, 28, 70, 0.1)',
		},
		'&:disabled': {
			background: '#fff',
			border: 'solid 1px #aaa2aa',
			color: '#4b1c46',
		},
	},

	saveBtnGlobalStyle: {
		height: '40px',
		minWidth: '169px',
		fontSize: '16px',
		fontWeight: 'normal',
		lineHeight: 'normal',
		color: '#fff',
		borderRadius: '4px',
		background: 'linear-gradient(to left, #421a40 100%, #29132e 0%)',
		'&:hover': {
			background: 'linear-gradient(to left, #421a40 100%, #29132e 0%)',
		},
		'&:focus': {
			background: 'linear-gradient(to left, #421a40 100%, #29132e 0%)',
		},
		'&:disabled': {
			background: 'linear-gradient(to left, #776a7b 99%, #876e86 0%)',
			color: '#fff',
		},
	},

	scheduledUserList: {
		// height: "100%",
		width: '100%',
		display: 'flex',
		overflow: 'unset',
		marginTop: '30px',
	},

	schedulerTableContainer: {
		display: 'flex',
		overflow: 'auto',
		height: '340px',
	},

	schedulerTable: {
		backgroundColor: 'rgba(239, 239, 242, 0.48)',
	},
	schedulerTableHeadRow: {
		...theme.tables.headerCell,
	},
	userNameCell: {
		fontFamily: 'Roboto',
		fontSize: 15,
		fontWeight: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#545964',
	},
	userIcon: {
		marginRight: '12px',
		transform: 'scale(1.3)',
	},
	delCell: {
		textAlign: 'right',
	},
	delIconEditReport: {
		display: 'flex',
		height:'100%',
		alignItems:'center'

	},
	middleTitle: {
		marginBottom: '15px',
		borderBottom: '1px solid #ebebeb',
		height: '35px',
	},
	field: { height: '25px' },
	HeaderHolder: {
		display: 'flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
		marginBottom: 11,
	},
	unitsSection: {
		display: 'flex',
		flexFlow: 'column nowrap',
		marginBottom: '20px',
	},
	unitBtnsContainer: {
		display: 'flex',
		flexFlow: 'column nowrap',
	},
	borderLine: {
		borderRight: 'solid 1px #e1e2ea',
		height: '100%',
		width: 1,
	},
	addTopMargin: {
		marginTop: 50,
	},
	btnStyle: {
		width: 44,
		height: 40,
		marginRight: 10,
		borderRadius: 8,
		backgroundColor: '#F6F5F6',
		textAlign: 'center',
		padding: '11px 0',
		marginBottom: 10,
		color: '#545964',
		fontSize: 16,
		fontFamily: 'Roboto',
		fontWeight: 'normal',
		lineHeight: 'normal',
		letterSpacing: '-0.39px',
	},
	selectedBtnDays: {
		backgroundColor: '#D5D2D5',
	},
	btnsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	monthlyBtnsStyle: {
		padding: 11,
		width: 'unset',
	},
	rowContainer: {
		display: 'flex',
		flex: 1,
		marginTop: 30,
		justifyContent: 'space-between',
	},
	inputLabelStyle: {
		flex: 0.47,
		display: 'flex',
		flexFlow: 'column',
	},
	timeSelectionsContainer: {
		marginTop: 30,
	},
	iconBtnStyle: {
		width: 30,
		height: 30,
		borderRadius: 6,
		padding: 0,
	},
	dialogHeader: {
		paddingLeft: 20,
		paddingRight: 13,
		...theme.dialogs.header,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 60,
		minHeight: 60,
	},
	headerTitle: {
		...theme.dialogs.title,
	},
	dialogContent: {
		flex: '1 1 auto',
		padding: 20,
		height: 'calc(100% - 140px)',
		paddingBottom: 0,
	},
	dialogActionsStyle: {
		padding: '8px 24px 20px',
	},
	actionsHolder: {
		padding: 20,
		display: 'flex',
		justifyContent: 'space-between',
	},
	buttonsHolder:{
		display:'flex',
		justifyContent:'flex-end',
		flex:1
	},
	dialogContentZone: {
		flex: '1 1 auto',
		padding: 20,
		overflowY: 'auto',
		paddingBottom: 0,
		display: 'flex',
		flexFlow: 'row nowrap',
	},
	addIcon: {
		backgroundColor: '#ecebef',
	},
	headerTitleNoBorder: {
		fontFamily: 'RobotoMedium',
		fontSize: '20px',
		fontWeight: '500',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#4b1c46',
	},
	itemName: {
		fontFamily: 'Roboto',
		fontSize: 15,
		fontweight: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#545964',
		marginBottom: 13,
		display: 'flex',
		alignItems: 'center',
	},
	itemNameSelected: {
		fontFamily: 'RobotoMedium',
		fontWeight: 500,
		color: '#4b1c46',
		display: 'flex',
		alignItems: 'center',
	},
	itemNameDisabled: {
		opacity: 0.5,
	},
	itemsPart: {
		marginBottom: 19,
	},
	smallCheckbox: {
		marginRight: '15px',
		padding: 0,
		'& span': {
			width: '24px',
			height: '24px',
			borderRadius: '3px',
			backgroundColor: '#fff',
		},
	},
	cellStyle: {
		padding: 10,
	},
	rowStyle: {
		background: '#fff',
	},
	userHolder: {
		display: 'flex',
		alignItems: 'center',
	},
	btnText: {
		fontFamily: 'Roboto',
		fontSize: 14,
		fontWeight: 500,
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#79797d',
		border: 'solid 1px #d5d2d5 !important',
		textTransform: 'unset',
	},
	loaderContainer: {
		backgroundColor: alpha('#000', 0.1),
		position: 'absolute',
		height: '100%',
		width: '100%',
		zIndex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	graphTooltip: {
		backgroundColor: '#fff',
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid #999',
		borderRadius: '6px',
		'& *': {
			fontSize: '13px',
		},
	},
	tooltipHeader: {
		padding: '5px 10px',
		backgroundColor: '#efeff2',
		width: '100%',
		height: '40px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: '6px',
	},
	tooltipInfoContainer: {
		display: 'flex',
		flex: 1,
	},
	tooltipSection: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		padding: '5px 20px',
		width: '150px',
		'&:first-child': {
			borderRight: '1px solid #f0f0f0',
		},
	},
	exportContent: {
		width: '900px',
		padding: '20px',
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	exportOptions: {
		display: 'flex',
		flex: 1,
		width: '100%',
		marginBottom: '20px',
		overflowY: 'auto',
	},
	exportOptionsSection: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '0 5%',
		overflowY: 'auto',
	},
	exportNameFieldTitle: {
		fontSize: '14px',
		fontWeight: 'bold',
	},
	exportNameField: {
		width: '60%',
	},
	exportNameFieldRoot: {
		height: '45px',
	},
	exportSelectField: {
		height: '45px',
		width: '100%',
	},
	sectionTitle: {
		color: theme.overrides.MuiButton.outlinedPrimary.color,
		fontFamily: 'Roboto',
		fontSize: '20px',
		fontWeight: 500,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		borderBottom: theme.overrides.MuiButton.outlined.border,
		paddingBottom: '10px',
		marginBottom: '10px',
		height:41
	},
	sectionTitleTextOnly:{
		color: theme.overrides.MuiButton.outlinedPrimary.color,
		fontFamily: 'Roboto',
		fontSize: '20px',
		fontWeight: 500,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
	},
	sectionTitleContainerOnly:{
		borderBottom: theme.overrides.MuiButton.outlined.border,
		paddingBottom: '10px',
		marginBottom: '10px',
		display:'flex',
		justifyContent:'space-between',
		alignItems:'center',
		height:41
	},
	exportField: {
		marginBottom: '15px',
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	hasError: {
		color: 'red',
	},
	sectionHasError: {
		border: '1px solid red',
	},
	exportBtns: {
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		width: '100%',
		'& *': {
			width: '30%',
		},
	},
	tenantsContainer: {
		height: '100%',
		display: 'flex',
		paddingBottom: '1rem',
		overflow: 'hidden',
	},
	tenantsTableContainer: {
		display: 'flex',
	},
	tenantsPaper: {
		boxSizing: 'border-box',
		margin: '1rem',
		marginTop: 0,
		overflow: 'auto',
		height: '100%',
		padding: '20px',
		maxHeight: 'calc(100% - 10px)',
		display: 'flex',
		flexFlow: 'column nowrap',
		minHeight: '250px',
		width: '100%',
	},
	tenantsTable: {
		background: theme.palette.background.paper,
	},
	radio: {
		color: theme.palette.primary.light,
		'&.Mui-checked': {
			color: theme.palette.primary.light,
		},
	},
	// ************************* TENANT SEARCH INPUT
	inputRoot: {
		color: theme.palette.colors.menuFont,
		fontFamily: 'Roboto',
		fontSize: '13px',
		width: '300px',
		display: 'flex',
		flexDirection: 'row',
		height: '36px',
		borderRadius: '4px',
		padding: '0 15px',
		backgroundColor: theme.palette.colors.mainSearchBackground,
		maxWidth: '70%',
		marginRight: '40px',
	},
	inputBase: {
		width: '190px !important',
	},
	closeIconStyle: {
		color: 'white',
		padding: '7px',
		'& svg': {
			fontSize: '24px',
		},
	},

	add20TopMargin: {
		marginTop: '20px',
	},
	graphCheckbox: {
		display: 'flex',
		alignItems: 'cenetr',
	},
	excessLabel: {
		marginLeft: '70px',
		marginTop: '15px',
	},
	excessUsage: {
		backgroundColor: '#efeff2',
		boxShadow: 'none',
		color: '#4b1c46',
		'&:focus': {
			boxShadow: 'none',
		},
		'&:hover': {
			boxShadow: 'none',
		},
	},
	activeExcessUsage: {
		backgroundColor: '#4b1c46',
		boxShadow: 'none',
		color: '#fff',
		'&:focus': {
			backgroundColor: '#4b1c46',
			boxShadow: 'none',
			color: '#fff',
		},
	},
	excessCheckbox: {
		marginTop: '-4px',
	},
}));

export default useStyles;
