import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  Grid,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import { t } from 'ttag';
import useStyles from './styles';
import Close from '@icons/Close';
import Button from '@cool_widgets/Button';
import { useStoreState } from '@models/RootStore';
import ErrorBox from '@components/WarnningBox/ErrorBox';
import useBacNet from '@hooks/useBacNet';
import useTypes from '@hooks/useTypes';
import { smartConvertToStringFormats } from '@utils/StringUtils';

const OBJECT_TYPES = {
  binary:1,
  'reverse_Binary':2,
  enumeration:4,
  value:5
};

const defaultValueTypeEnums = {
  min: 0,
  max: 0,
  step: 0,
};

/**A function that gets a binary value and return 'Yes' or 'No'. */
const getBinaryParamDisplayValue = (value) => value ? t`Yes`: t`No`;

/**
 * Determines the state of a COV field based on whether COV is supported.
 * - If `isCovSupported` is `false`, the field is disabled and set to a default value of `'2'`(No).
 * - For `true` or `undefined` values, the field is enabled with an empty string as its value.
 *
 * @param {boolean|undefined} isCovSupported - Flag indicating COV support status.
 * @returns {{ disabled: boolean, value: string }} - Object with `disabled` status and `value` for the COV field.
 */
const getCovFieldState = (isCovSupported) => {

  if(isCovSupported === false){
    return {
      disabled:true,
      value:2,
    };
  }

  return {
    disabled:false,
    value:'',
  };


};

/**
 * ParamMappingDialog component for configuring and mapping BACnet object parameters to a template.
 *
 * @param {Object} props
 * @param {Object} props.bacNetObject - The BACnet object containing parameter data to be mapped.
 * @param {Object} props.template - The template containing existing mappings and data.
 * @param {function} props.handleSave - Callback to handle saving the changes made in the dialog.
 * @param {function} props.handleClose - Callback to handle closing the dialog.
 */

const ParamMappingDialog = ({
  bacNetObject,
  template,
  handleSave,
  handleClose,
}) => {
  const classes = useStyles();

  const {
    name,
    objectId,
    enumeration,
    objectType,
    isCovSupported,
    bacnetProps,
  } = bacNetObject;

  const { UNITS } = bacnetProps;

  const {
    bacnetObjectTypes,
    bacnetMeasurementUnits,
    bacnetUnitTypes,
    bacnetPriorities,
    defaultBinaryEnums,
  } = useTypes();

  const {
    defaultReverseBinaryEnums,
    bacnetCovOptionsTypes,
  } = useStoreState((state) => state.types);

  //This will be restored in the future. do not delete.
  /*  const bacnetUnitOptions = useMemo(
    () => Object.keys(bacnetUnitTypes),
    [bacnetUnitTypes]
  ); */

  const typeNameByTypeId = useMemo(()=>{
    if(!bacnetUnitTypes){
      return {};
    }

    return Object.values(bacnetUnitTypes).reduce((map,{value,label})=>{
      map[value] = label;
      return map;
    },{});
  },[bacnetUnitTypes]);

  const reverseBinaryOptions = useMemo(
    () => Object.values(defaultReverseBinaryEnums),
    [defaultReverseBinaryEnums]
  );

  const bacnetEnumerationsTemplate = useMemo(()=>{
    if(!enumeration){
      return [];
    }
    return Object.entries(enumeration).map(([key,value])=>({value,key}));
  },[enumeration]);

  const binaryOptions = useMemo(
    () => Object.values(defaultBinaryEnums),
    [defaultBinaryEnums]
  );

  const unitsFieldOptions = useMemo(( ) => {
    return Object.entries(bacnetMeasurementUnits).map(([key, value]) => ({
      value: key,
      label: smartConvertToStringFormats(value).capitalized,
    }));
  },[bacnetMeasurementUnits]);

  const bacnetCovOptions = useMemo(
    () => Object.keys(bacnetCovOptionsTypes),
    [bacnetCovOptionsTypes]
  );

  const priorityFieldsOptions = useMemo(()=>{
    return Object.entries(bacnetPriorities).map(([value,label])=>({value,label}));
  },[bacnetPriorities]);

  const defaultValueTypeOption = useMemo(
    () => Object.keys(defaultValueTypeEnums),
    [defaultValueTypeEnums]
  );

  const covFieldsProps = getCovFieldState(isCovSupported);

  const { updateBacNetParameterTemplate } = useBacNet();
  const [mappedObject, setMappedObject] = useState({
    name: name,
    cov: covFieldsProps.value,
    priority: '16',
    type: objectType,
    customEnum: {}
  });

  const [dirty, setDirty] = useState(false);
  const [showDiscardChangesDialog, setShowDiscardChangesDialog] =
    useState(false);


  const initFromBacnetObject = () => {

    const derivedMappedObject = {
      name: name,
      cov: covFieldsProps.value,
      priority: '16',
      type: objectType,
      customEnum:{}
    };

    if (objectType === OBJECT_TYPES.value) {
      const { minimumValue, maximumValue, valueStep } = bacNetObject;
      derivedMappedObject.units = UNITS;

      derivedMappedObject.customEnum = {
        min: minimumValue ?? 0,
        max: maximumValue ?? 0,
        step: valueStep ?? 0,
      };
    }

    if(objectType === OBJECT_TYPES.binary || objectType === OBJECT_TYPES.reverse_Binary){

      const keyForOff = objectType === OBJECT_TYPES.binary ? 'off' : 'on';
      const keyForOn = objectType === OBJECT_TYPES.binary ? 'on' : 'off';

      derivedMappedObject.customEnum = {
        0: enumeration?.[0] ?? defaultBinaryEnums[keyForOff].label,
        1: enumeration?.[1] ?? defaultBinaryEnums[keyForOn].label,
      };
    }

    if(objectType === OBJECT_TYPES.enumeration){
      derivedMappedObject.customEnum = enumeration;
    }

    setMappedObject(derivedMappedObject);
    setDirty(true);
  };


  useEffect(()=>{
    initFromBacnetObject();
  },[bacNetObject]);


  /**
   * Effect to initialize mappedObject if it exists in the template data.
   */
  useEffect(() => {
    if (objectId in template.data) {
      const newMappedObject = {...mappedObject,...template.data[objectId]};
      setMappedObject(newMappedObject);
    }

  }, [template,bacNetObject]);

  /**
   * Handles changes to a field in the mappedObject.
   *
   * @param {string} field - The name of the field being changed.
   * @returns {function} - A function that takes an event and updates the corresponding field in mappedObject.
   */
  const handleChange = useCallback(
    (field) => (event) => {
      setDirty(true);
      const { type, checked, value } = event.target;
      setMappedObject((prevParam) => ({
        ...prevParam,
        [field]: type === 'checkbox' ? checked : value,
        customEnum:field === 'type' ? {} : prevParam.customEnum
      }));
    },
    []
  );

  /**
   * Handles changes to enum fields in the mappedObject.
   *
   * @param {string} field - The name of the enum field being changed.
   * @returns {function} - A function that takes an event and updates the enum field in mappedObject.
   */
  const handleEnumChange = useCallback(
    (field) => (event) => {
      setDirty(true);
      const { value } = event.target;
      setMappedObject((prevParam) => ({
        ...prevParam,
        customEnum: {
          ...prevParam.customEnum,
          [field]: value,
        },
      }));
    },
    []
  );

  /**
   * Handles saving the changes made to the mappedObject.
   */
  const handleSaveChanges = async () => {
    const updatedTemp = await updateBacNetParameterTemplate({
      id: template.id,
      data: {
        data: {
          ...template.data,
          [objectId]: mappedObject,
        },
      },
    });
    handleSave(updatedTemp);
  };

  /**
   * Renders input fields for enum values.
   *
   * @param {Object} enums - The enum options to render input fields for.
   * @returns {JSX.Element} The JSX elements containing input fields for enum values.
   */
  const renderEnumInputs = (options) => {

    return options.map(({ value }) => (
      <TableRow key={`row-enum-${value}`}>
        <TableCell classes={{ root: classes.tableCell }} align="left">
          {value}
        </TableCell>
        <TableCell classes={{ root: classes.tableCell }} align="left">
          <TextField
            fullWidth
            variant="outlined"
            value={mappedObject?.customEnum[value]}
            onChange={handleEnumChange(value)}
            InputProps={{ classes: { root: classes.inputRoot } }}
          />
        </TableCell>
      </TableRow>
    ));
  };

  const renderUnitsSelectOptions = () => {
    return unitsFieldOptions.map(({value,label}) => {
      return (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      );
    });
  };

  const renderValueTypeExtraOptions = () => {
    return (
      <>
        <TableRow key={'row-units'}>
          <TableCell classes={{ root: classes.tableCell }} align="left">
            {t`Units`}
          </TableCell>
          <TableCell classes={{ root: classes.tableCell }} align="left">
            <Select
              displayEmpty
              fullWidth
              variant="outlined"
              value={mappedObject.units ?? UNITS}
              disabled={!!UNITS}
              onChange={handleChange('units')}
              classes={{ select: classes.inputRoot }}
            >
              {renderUnitsSelectOptions()}
            </Select>
          </TableCell>
        </TableRow>
        {defaultValueTypeOption.map((key) => (
          <TableRow key={`row-enum-${key}`}>
            <TableCell classes={{ root: classes.tableCell }} align="left">
              {key?.toUpperCase()}
            </TableCell>
            <TableCell classes={{ root: classes.tableCell }} align="left">
              <TextField
                fullWidth
                disabled={mappedObject.readOnly}
                variant="outlined"
                value={mappedObject.customEnum[key]}
                onChange={handleEnumChange(key)}
                InputProps={{ classes: { root: classes.inputRoot } }}
              />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const renderEnumerationTypeExtraOption = () => {

    return (
      <div className={classes.enumerationsFormWrapper}>
        {bacnetEnumerationsTemplate.map(({key}) => {
          return (
            <TableRow key={`row-enum-${key}`}>
              <TableCell classes={{ root: classes.tableCell }} align="left">
                {key.toUpperCase()}
              </TableCell>
              <TableCell classes={{ root: classes.tableCell }} align="left">
                <TextField
                  fullWidth
                  disabled={mappedObject.readOnly}
                  variant="outlined"
                  value={mappedObject.customEnum[key]}
                  onChange={handleEnumChange(key)}
                  InputProps={{ classes: { root: classes.inputRoot } }}
                />
              </TableCell>
            </TableRow>);
        })}
      </div>
    );
  };

  /**
   * Renders the advanced settings input fields based on the object type.
   *
   * @returns {JSX.Element|null} The JSX elements containing advanced settings or null if not applicable.
   */
  const renderAdvancedSettings = () => {
    const { binary, reverseBinary,enumeration,value } = bacnetUnitTypes || {};
    switch (mappedObject.type) {
    case binary?.value:
      return renderEnumInputs(binaryOptions);
    case reverseBinary?.value:
      return renderEnumInputs(reverseBinaryOptions);
    case value?.value:
      return renderValueTypeExtraOptions();
    case enumeration?.value:
      return renderEnumerationTypeExtraOption();
    default:
      return null;
    }
  };


  const renderTypeDependentSettings = () => {

    if(!mappedObject.type){
      return null;
    }

    return (
      <TableRow key={'row-advanced-settings'}>
        <TableCell
          classes={{ root: classes.tableCell }}
          align="left"
        />
        <TableCell
          classes={{ root: classes.tableCell }}
          align="left"
        >
          {renderAdvancedSettings()}
        </TableCell>
      </TableRow>
    );
  };

  const enumerationsList = useMemo(()=>{
    if(!enumeration || typeof enumeration !== 'object'){
      return [];
    }

    return Object.entries(enumeration).map(([key,value])=>({key,value}));
  },[enumeration]);

  const renderEnumerationsList = () => {

    if(!enumeration){
      return null;
    }

    return(
      <div className={classes.enumerationsListWrapper}>
        {enumerationsList.map(({key,value})=>{
          return (
            <div key={key}>{key} - {value}</div>
          );
        })}
      </div>
    );

  };

  const getObjectTypeDisplayValue = (bacnetType) => {
    const name = bacnetObjectTypes[bacnetType];
    if(!name){
      return bacnetType;
    }
    return `${name} (${bacnetType})`;
  };


  const bacnetParamsList = useMemo(()=>{
    if(!bacNetObject || !bacnetObjectTypes){
      return [];
    }

    const {
      name,
      objectId,
      bacnetType,
      isCovSupported,
      isReadOnly,
      maximumValue,
      minimumValue,
      valueStep,
    } = bacNetObject;

    const type = getObjectTypeDisplayValue(bacnetType);

    return [
      {name:t`Name`,value:name},
      {name:t`Object ID`,value:objectId},
      {name:t`Type`,value:type},
      {name:t`COV support`,value:getBinaryParamDisplayValue(isCovSupported)},
      {name:t`Read only`,value:getBinaryParamDisplayValue(isReadOnly)},
      {name:t`Max value`,value:maximumValue},
      {name:t`Min value`,value:minimumValue},
      {name:t`Value step`,value:valueStep},
      //todo: In the future we should consider replacing `isEnumeration` with `customCellClassName` for more flexibility.
      {name:t`Enumerations`,value:renderEnumerationsList(),isEnumeration:true}
    ];
  },[bacNetObject,bacnetObjectTypes]);

  //This will be restored in the future. do not delete.
  /*  const renderTypeSelectOptions = () => {
    return bacnetUnitOptions.map((key) => {
      const isDisabled = key === 'enumeration' && !bacnetEnumerationsTemplate.length;
      return (
        <MenuItem
          key={key}
          value={bacnetUnitTypes[key]?.value}
          disabled={isDisabled}
        >
          {bacnetUnitTypes[key]?.label}
        </MenuItem>
      );
    });
  }; */

  /**
   * Renders the content of the dialog, including BACnet object parameters and their mappings.
   *
   * @returns {JSX.Element} The JSX elements representing the dialog content.
   */
  const renderContent = () => {

    if(!mappedObject){
      return null;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.leftSideContainer}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >
                    {t`BACnet Param Info`}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >
                    {t`Value`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bacnetParamsList.map((param) => {
                  const {name,value,isEnumeration } = param;
                  const className = isEnumeration ? classes.enumerationCellStyles : '';
                  return (
                    <TableRow key={`param-${name}`}>
                      <TableCell
                        classes={{ root: classes.tableCell }}
                        align="left"
                        className={className}
                      >
                        {name}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableCell }}
                        align="left"
                        className={className}
                      >
                        {value}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6} className={classes.rightSideContainer}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="a dense table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >
                    {t`Mapped Param`}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={'row-name'}>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    {t`Name`}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={t`Name`}
                      value={mappedObject.name}
                      onChange={handleChange('name')}
                      InputProps={{ classes: { root: classes.inputRoot } }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow key={'row-type'}>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    {t`Type`}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    {typeNameByTypeId[mappedObject.type] ?? t`Unknown`}
                    {/* This will be restored in the future. do not delete */}
                    {/* <Select
                      fullWidth
                      variant="outlined"
                      value={mappedObject.type}
                      onChange={handleChange('type')}
                      displayEmpty
                      classes={{ select: classes.inputRoot }}
                      disabled={!!objectType}
                    >
                      {renderTypeSelectOptions()}
                    </Select> */}
                  </TableCell>
                </TableRow>
                {renderTypeDependentSettings()}

                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`Settings`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableRow key={'row-priority'}>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    {t`Priority`}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    <Select
                      variant="outlined"
                      value={mappedObject?.priority}
                      onChange={handleChange('priority')}
                      displayEmpty
                      classes={{ select: classes.inputRoot }}
                    >
                      {priorityFieldsOptions.map(({value,label}) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {`${value} - ${label}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow key={'row-COV'}>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    {t`COV`}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }} align="left">
                    <Select
                      variant="outlined"
                      value={mappedObject.cov}
                      onChange={handleChange('cov')}
                      displayEmpty
                      classes={{ select: classes.inputRoot }}
                      disabled={covFieldsProps.disabled}
                    >
                      {bacnetCovOptions.map((key) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {bacnetCovOptionsTypes[key]?.toUpperCase()}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  const handleDiscardChangesDialogClose = () => {
    setShowDiscardChangesDialog(false);
  };

  const handleCloseMappingDialog = () => {
    dirty ? setShowDiscardChangesDialog(true) : handleClose();
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>
          {name}
        </Typography>
        <IconButton
          disableRipple
          className={classes.iconBtnStyle}
          onClick={handleCloseMappingDialog}
        >
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div id="dialogContent" className={classes.dialogContent}>
        {renderContent()}
      </div>
      <div className={classes.actionsContainer}>
        <Button
          onClick={handleCloseMappingDialog}
          marginRight
          white
          width={150}
        >
          {t`Cancel`}
        </Button>
        <Button disabled={!dirty} onClick={handleSaveChanges} width={150}>
          {t`save`}
        </Button>
      </div>
      {showDiscardChangesDialog && (
        <ErrorBox
          error={t`Do you want to discard changes?`}
          AcceptBtnTitle={t`Yes`}
          onAccept={handleClose}
          onClose={handleDiscardChangesDialogClose}
        />
      )}
    </Dialog>
  );
};

export default ParamMappingDialog;
