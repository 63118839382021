import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
                                                                        
import { t } from 'ttag';
import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as queryString from 'query-string';

import { goToControlApp, getLoginState, getLoginActions } from './utils/login';
import LoginForm from './components/LoginForm';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import { useStoreActions, useStoreState } from '../../models/RootStore';
import assetsService from '../../services/assetsService';
import CustomConfirmationDialog from '../../components/CustomConfirmationDialog/CustomConfirmationDialog';
import LoginReportProblem from './components/LoginReportProblem';

/**
 * The Login component renders the login form and handles login logic.
 *
 * @param {function} useStyles - A function that returns the styled classes for the component.
 * @param {boolean} isMobile - Whether the current device is a mobile device or not.
 * @param {function} inputField - The custom input field component to use for the login form.
 *
 * @returns {ReactElement} - The login component.
 */
const Login = ({useStyles,isMobile, inputField}) => {
	// React hooks for accessing the current location, styles, and history
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();

	// React hooks for accessing store state and actions
	const { configFile, isLoggedIn } = useStoreState(getLoginState);
	const { doLogin, verify2FA } = useStoreActions(getLoginActions);

	// State variables for managing 2FA dialog and errors
	const [open2FADialog, setOpen2FADialog] = useState(false);
	const [error, setError] = useState(null);
	const [error2FA, setError2FA] = useState(null);

	// State variable for holding login form input values
	const [loginState, setLoginState] = useState({
		username: '',
		password: ''
	});
	
	// Destructure configFile properties
	const { urls, enableOpenTicket } = configFile;

	//get the logoSrc by device type from the assetsService
	const logoSrc = isMobile ? assetsService.getResourcePath('logo_emblem.png') : assetsService.getResourcePath('logo.png');

	/**
     * Renders the logo component.
     *
     * @returns The logo component
     */
	const Logo = () => {
		return (
			<div className={classes.logos}>
				<img
					alt="logo"
					src={logoSrc}
					className={classes.logo}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.style.visibility = 'hidden';
					}}
				/>
			</div>);
	};

	/**
    * The MobileLogoTitle component renders a logo and a professional title for mobile devices.
    * It hides the logo if it fails to load.
    *
    * @returns {ReactElement} - The mobile logo and title component.
    */
	const MobileLogoTitle = () => {
		if(!isMobile){
			return null;
		}
		return (
			<>
				<img
					alt="logo"
					src={assetsService.getResourcePath('BlackLogoTitle.png')}
					className={classes.logoTitle}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.style.visibility = 'hidden';
					}}
				/> 
				<Typography className={classes.ProfessionalTitle}>{t`Professional`}</Typography>
			</>
		);
	};

	/**
     * Renders the "Forgot Password?" link.
     *
     * @returns A link component with "Forgot Password?" text
     */
	const ForgotPassword = () => {
		return (
			<Link component="button" variant="body2" onClick={handleForgotPassword} className={classes.forgotPassBtn} >
				{t`Forgot Password?`}
			</Link>
		);
	};

	/**
     * Renders the login error box if an error has occurred.
     *
     * @returns The login error box component or null if no error exists
     */
	const LoginErrorBox = () => {
		if (!error) {
			return null;
		}

		const handleOnClose = () => {
			setError(null);
		};

		return (
			<ErrorBox error={error} onClose={handleOnClose} />
		);
	};
	
	/* Handles canceling the 2FA dialog.
	*
	* @sets Open2FADialog to false
	* @sets Error2FA to null
	*/
	const cancel = () => {
		setOpen2FADialog(false);
		setError2FA(null);
	};

	/**
     * Handles submitting the 2FA code.
     *
     * @param {string} code The 2FA code entered by the user
     *
     * @sets Error2FA to an empty string if the code is valid
     *
     * @calls verify2FA with the provided code
     *
     * @handles error responses and redirects to the control app if necessary
     */
	const handleVerify = (code) => {
		setError2FA('');
		verify2FA({ code }).catch((response) => {
			if (response?.hasNoPermission && window.location.href.includes('airconnectpro')) {
				goToControlApp(urls);
			}
			if (response) {
				setError2FA(response.message ? t`The code you entered is invalid or expired. Please check again you entered the right code, or try logging in again to receive a new code.` : null);
			}
		});
	};

	/**
 	* Resets the error message for two-factor authentication (2FA).
 	*
 	* This function sets the error message to an empty string, effectively clearing any existing error message.
 	*/
	const resetVerifyError = () => {
		setError2FA('');
	};

	/**
     * Handles submitting the login form.
     *
     * @param {object} values The login form values
     *
     * @calls doLogin with the provided username and password
     *
     * @sets LoginState.error to the response error message if login fails
     *
     * @sets Open2FADialog to true if 2FA is required
     */
	const handleLogin = (values) => {
		doLogin({
			username: values.username,
			password: values.password
		})
			.then((res) => {
				setOpen2FADialog(res?.is2FA);
			})
			.catch((response) => {
				if (response?.hasNoPermission && window.location.href.includes('airconnectpro')) {
					goToControlApp();
				}
				if (response) {
					setLoginState({ ...loginState, error: response.message });
				}
			});
	};

	/**
 	* Handles the "Forgot Password?" button click.
 	*
 	* @returns A redirect to the '/forgot-password' route
 	*/
	const handleForgotPassword = () => {
		return history.push('/forgot-password');
	};

	//Check if the user is already logged in
	if (isLoggedIn) {
		// Get the params from the query string.
		const params = queryString.parse(location.search);

		// Redirect to the specified route
		return (
			<div>
				<p>{t`user already logged in`}</p>
				<Redirect to={params.redirect || '/dashboard'} />
			</div>
		);
	}

	//Render the page.
	return (
		<div className={classes.Login}>
			<div className={classes.main}>
				<Logo />
				{configFile ? (
					<div className={classes.interactionSection}>
						<MobileLogoTitle />
						<Typography className={classes.title}>{t`Welcome! Please login`}</Typography>
						<LoginErrorBox />
						<LoginForm loginState={loginState} onSubmit={handleLogin} classes={classes} inputField={inputField} />
						<ForgotPassword />
					</div>
				) : null}
			</div>
			{enableOpenTicket && <LoginReportProblem classes={classes} configFile={configFile} />}

			<CustomConfirmationDialog
				onCancel={cancel}
				onConfirm={handleVerify}
				onClose={cancel}
				title={t`Additional authentication required`}
				text={t`You account requires an additional authentication code that was sent to your email address. Please enter the code below`}
				confirmLabel={t`Submit`}
				cancelLabel={t`Cancel`}
				openDialog={open2FADialog}
				errorText={error2FA}
				onInputChange={resetVerifyError}
			/>
		</div>
	);
};


Login.propTypes = {
	useStyles:PropTypes.func.isRequired,
	isMobile:PropTypes.bool,
	inputField:PropTypes.node
};

Login.defaultProps = {
	isMobile:false,
	inputField:'input'
};

export default Login;





