import React from "react";

const Maximize = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.53 13.809a.793.793 0 0 1 1.032.077l4.851 4.852.001-4.132a.793.793 0 0 1 1.586 0V21.4h-6.772a.793.793 0 0 1-.793-.793l.006-.1a.793.793 0 0 1 .787-.693h4.02l-4.807-4.808-.077-.09a.793.793 0 0 1 .077-1.031zm-5.752.077a.793.793 0 1 1 1.121 1.12l-4.808 4.81h3.681l.109.007c.354.048.633.33.678.685l.006.1-.006.1a.793.793 0 0 1-.787.693H2v-6.795l.006-.1a.793.793 0 0 1 .787-.693l.1.006c.39.049.693.382.693.787l-.001 4.473zM22 2v6.795a.793.793 0 0 1-1.586 0l-.001-4.133-4.851 4.853a.793.793 0 0 1-1.033.077l-.088-.077a.793.793 0 0 1 0-1.121l4.807-4.809h-4.02a.793.793 0 0 1-.787-.693l-.006-.1c0-.437.355-.792.793-.792H22zM8.772 2c.438 0 .793.355.793.793l-.006.1a.793.793 0 0 1-.787.692H5.091l4.808 4.809a.793.793 0 0 1-1.121 1.121L3.585 4.322v4.473a.793.793 0 0 1-.693.787l-.1.006A.793.793 0 0 1 2 8.795V2z" fill="#AAA2AA" fillRule="nonzero" />
  </svg>

);

export default Maximize;
