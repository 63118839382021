import { TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import SvgArrow from "../../icons/Arrow";
import useStyles from "./MenuDropDown.style";

const MenuDropDown = (props: any) => {
  const classes = useStyles();
  const { className, value, options, unitMenu = false, onChange, controlOptions, indoorOptions, outdoorOptions, bsBoxOptions, otherOptions, setStyle = false, generalNameEmpty = "", generalNameUnselected = "" } = props;

  const [searchTerm, setSearchTerm] = useState<any>("");
  const [option, setOption] = useState<any>("");

  useEffect(() => {
    if (!value) {
      setOption(options[0] || "");
      return;
    }
    for (let i in options) {
      const option = options[i];
      if (option?.value === value) {
        setOption(option);
        break;
      }
    }
  }, [value, options]);

  return (
    <Autocomplete
      options={options || []}
      getOptionLabel={(option: any) => option?.label || ""}
      groupBy={(option: any) => option?.type}
      value={option}
      inputValue={searchTerm}
      getOptionSelected={(option: any) => option.value === (value || "")}
      disablePortal
      classes={{
        clearIndicator: classes.hide,
        popper: classes.popperStyle,
        paper: classes.paperStyle,
        groupLabel: classes.groupLabel,
        groupUl: classes.groupUI,
        input: classes.optionText,
        root: classes.selectStyle,
        endAdornment: classes.endAdornment

      }}
      clearOnEscape
      onOpen={() => setSearchTerm("")}
      onInputChange={(e: any, val: any) => {
        setSearchTerm(val);
      }}
      renderInput={(params) => <TextField
        {...params}
        placeholder={t`Search...`}
        InputProps={{ disableUnderline: true, ...params.InputProps }}
      />}
      popupIcon={<SvgArrow className={classes.arrowIcon} />}
      renderOption={(option: any, { selected }: any) => (
        <Typography className={clsx(classes.optionStyle, { [classes.selected]: selected })}>
          {option.label}
        </Typography>
      )}
      onChange={(event: any, value: any) => {
        if (!value) {
          return;
        }
        setSearchTerm("");
        onChange(value.value);
      }}
    />
  );
};

export default MenuDropDown;
