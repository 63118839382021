import {
  Card,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { manuallySetSystemViewStyle } from "./Device/ManuallySetSystems.style";
import { DeleteIcon } from "../../logos";

interface IProps {
  deviceId: string;
  closeModal: any;
  classes?: any;
  actions: any;
}
export default withStyles(manuallySetSystemViewStyle)(function ManuallySetPPDs(props: IProps) {
  const { classes, deviceId, actions } = props;
  const { modalActions, systemAttributes, disconnectedRow } = classes;
  const getPPDs = useStoreActions((action) => action.devices.getPPDsAPI);
  const { updatePowerMeter, deletePowerMeter } = useStoreActions((action) => action.powerMeters);
  const [ppdData, setPpdData] = useState<any>({});
  const [device, setDevice] = useState<any>({});
  const [error, setError] = useState<string | null>(null);
  const { powerMeterTypes } = useStoreState((state: any) => state.types);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  const errorBox = error ? <ErrorBox error={error} onClose={() => setError(null)} /> : null;
  useEffect(() => {
    if (!deviceId) {
      return;
    }

    setDevice(allDevices[deviceId])
    getPPDs({ id: deviceId, type: powerMeterTypes.normal })
      .then((ppdsRes: any) => {
        setPpdData(ppdsRes);
      });

  }, [deviceId]);

  const handleSave = async () => {
    _.forEach(ppdData, (pm) => {
      const {
        name,
        model,
        sn
      } = pm;
      updatePowerMeter({
        id: pm.id, data: {
          name,
          model,
          sn
        }
      });
    });
    props.closeModal();
  };

  const getColumnHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            classes={{ root: classes.tableHeadCell }}
            className={classes.headCellName}
            align="left"
            style={{ width: "37ch", minWidth: "37px" }}
          >
            {t`Unit name`}
          </TableCell>

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            className={classes.headCellType}
            align="left"
            style={{ width: "9ch", minWidth: "9ch" }}
          >
            {t`Type`}
          </TableCell>

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            className={classes.headCellType}
            align="left"
            style={{ width: "10ch", minWidth: "10ch" }}
          >
            {t`Model`}
          </TableCell>

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            className={classes.headCellType}
            align="left"
            style={{ width: "10ch", minWidth: "10ch" }}
          >
            {t`CA Line`}
          </TableCell>

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            className={classes.headCellType}
            align="left"
            style={{ width: "18ch", minWidth: "18ch" }}
          >
            {t`SN`}
          </TableCell>

          <TableCell
            classes={{ root: classes.tableHeadCell }}
            className={classes.headCellType}
            align="left"
            style={{ width: "16ch", minWidth: "16ch" }}
          >
            {t`ID`}
          </TableCell>
          <TableCell style={{ width: 64 }}></TableCell>
        </TableRow>
      </TableHead>

    );
  };

  const getInput = ({ value = "", lebel = "", isReadyOnly = false, onChange = _.noop, type = "", id = "", disabled = false }) => {

    return (
      <FormControl
        className={clsx(classes.inputFormWrapper, classes.textField)}
        variant="filled"
      >
        {lebel && <InputLabel htmlFor="my-input">{lebel}</InputLabel>}
        <OutlinedInput
          disabled={disabled}
          onChange={((event) => {
            onChange(id, event.target.value, type);

          })}
          value={value}
          margin="dense"
          className={classes.inputFieldStyle}
          readOnly={isReadyOnly}
        />

      </FormControl>
    );

  };

  const deleteItem = (id: string) => {
    actions.fetchData()
    const { [id]: deletedItem, ...restItems } = ppdData
    deletePowerMeter(id).then(() => {
      setPpdData(restItems)
    })
  };

  const generateRow = (oneRow: any) => {

    const onChange = (id: string, data: any, type: string) => {
      setPpdData({ ...ppdData, [id]: { ...ppdData[id], [type]: data } });
    };

    const { name, sn, identifier, model, permissions = {}, isConnected, id } = oneRow;
    const { canDelete, canUpdate } = permissions

    const ca = `L${identifier[1]}` || "unknown";

    return (

      <TableRow key={oneRow.id} className={(!isConnected || !device.isConnected) && disconnectedRow} >
        <TableCell align="left" className={classes.wideCell}>
          {getInput({ value: name, isReadyOnly: false, onChange, type: "name", id: oneRow.id, disabled: !canUpdate })}

        </TableCell>

        <TableCell align="left" className={classes.wideCell}>
          <Typography>
            {"Power Meter"}
          </Typography>

        </TableCell>

        <TableCell align="left" className={classes.wideCell}>
          {getInput({ value: model, isReadyOnly: false, onChange, type: "model", id: oneRow.id, disabled: !canUpdate })}
        </TableCell>

        <TableCell align="left" className={classes.wideCell}>
          <Typography className={classes.caRow}>
            {ca}
          </Typography>

        </TableCell>

        <TableCell align="left" className={classes.wideCell}>
          {getInput({ value: sn, isReadyOnly: false, onChange, type: "sn", id: oneRow.id, disabled: !canUpdate })}
        </TableCell>

        <TableCell align="left" className={classes.wideCell}>
          {identifier}
        </TableCell>
        <TableCell align="center" className={classes.wideCell}>
          {(!isConnected || !device.isConnected) &&
            <IconButton disabled={!canDelete} onClick={() => deleteItem(id)}>
              <DeleteIcon />
            </IconButton>}
        </TableCell>
      </TableRow>
    );
  };

  const disableEdit = Object.values(ppdData).every(({ canUpdate = true }: any) => !canUpdate);

  return (
    <Dialog classes={{ paperWidthLg: classes.smallDialogStyle }} onClose={() => props.closeModal()} aria-labelledby="simple-dialog-title" open={true} maxWidth="lg" fullWidth>
      <div className={classes.dialogTitle}>
        <Typography className={classes.dialogText}>{t`Edit Power Meters`}</Typography>
        <IconButton disableRipple onClick={() => props.closeModal()} className={classes.iconBtnStyle}>
          <Close />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        {errorBox}
        <Paper className={systemAttributes}>
          <Card key="1" className={classes.otherFormWarpper}>
            <Typography className={classes.otherFormWarpperTitle}>
              {t`Auxiliary devices`}
            </Typography>
            <div className={classes.scrollableTable}>
              <form onSubmit={() => {
              }} style={{
                display: "flex",
                flexFlow: "column",
                flex: 1,
                height: "100%"
              }}>

                <div className={classes.tableWrapper}>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    {getColumnHeader()}
                    <TableBody>
                      {ppdData && _.map(ppdData, (ppd) => generateRow(ppd))}
                    </TableBody>
                  </Table>
                </div>

              </form>

            </div>
          </Card>
          <div className={modalActions}>

            <Button
              disabled={disableEdit}
              title={t`close edit`}
              variant="contained"
              onClick={props.closeModal}
              width={150}
              white="white"
              marginRight={true}
            >
              {t`Cancel`}
            </Button>

            <Button
              disabled={disableEdit}
              title={t`save system`}
              type="submit"
              width={150}
              onClick={handleSave}
            >
              {t`Save`}
            </Button>
          </div>
        </Paper>
      </div>
    </Dialog>
  );

});
