import React from "react";

function MobileDiagnostics(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="#AAA2AA">
        <path d="M13.151 16.849c0 .876-.197 1.552-.59 2.027-.394.475-.949.713-1.664.713-.722 0-1.28-.239-1.676-.717-.395-.478-.592-1.153-.592-2.023v-1.185c0-.877.197-1.552.59-2.028.394-.475.95-.712 1.669-.712.719 0 1.275.239 1.67.717.396.478.593 1.154.593 2.027v1.18zm-1.501-1.377c0-.466-.061-.814-.183-1.045a.61.61 0 00-.58-.345c-.258 0-.446.107-.563.32-.117.215-.18.538-.19.972v1.658c0 .484.062.838.183 1.062a.613.613 0 00.58.337c.255 0 .444-.11.565-.328.122-.218.185-.56.188-1.027v-1.604zM21.225 17h-1.506v-4.794l-1.48.432v-1.14l2.848-.985h.138zm-2.569.565l-2.424 5.685h-1.586l2.428-5.329h-3.092v-1.158h4.674z"></path>
        <path d="M15.732 4.535c5.928 0 10.733 4.805 10.733 10.733 0 2.54-.882 4.872-2.357 6.71l4.958 4.956a.8.8 0 01-1.042 1.21l-.09-.078-4.917-4.917A10.694 10.694 0 0115.732 26C9.805 26 5 21.195 5 15.268 5 9.34 9.805 4.535 15.732 4.535zm0 1.6a9.132 9.132 0 100 18.265 9.132 9.132 0 000-18.265z"></path>
      </g>
    </svg>
  );
}

export default MobileDiagnostics;