import PropTypes from 'prop-types';

export function truncateText(text, maxLength) {
	return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}

const TruncatedText = ({ text, maxLength }) => {
	// Check if the text length exceeds the maxLength
	return truncateText(text, maxLength);
};

// Define prop types for TruncatedText component
TruncatedText.propTypes = {
	text: PropTypes.string.isRequired,
	maxLength: PropTypes.number.isRequired,
};

export default TruncatedText;
