import { makeStyles } from "@material-ui/core";

export const systemOverviewStyle = makeStyles({
  elevation: {
    boxShadow: "none",
  },

  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  title: {
    fontSize: "1.25rem",
    fontFamily: "RobotoMedium",
    color: "#4b1c46",
  },

  compressorsContainer: {
    overflow: "auto",
    flexWrap: "nowrap",
  },
  bsContainer: {
    flexWrap: "wrap",
    flexDirection: "column",
    height: "calc(100% - 19px)",
    justifyContent: "space-between",
  },
  bsGrid: {
    // maxWidth: "fit-content",
    padding: "0 20px",
    boxSizing: "border-box",
  },
  bsGridTitle: {
    fontWeight: 400,
  },
  bsBox: {
    marginLeft: "1rem",
    padding: "1rem",
    borderRadius: 4,
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    height: "45%",
    minWidth: "172px",
  },
  bsSelected: {
    border: "2px solid black",
  },
  infoLabel: {
    fontSize: "13px",
    color: "rgb(84, 89, 100)",
  },

  unitPanel: {
    // minWidth: '10rem',
    marginLeft: "1rem",
    padding: "1rem",
    borderRadius: 4,
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    height: "calc(100% - 19px)",
    ///
    minWidth: "172px",
  },

  unitPanelDivider: {
    margin: "5px 0",
  },

  unitName: {
    fontWeight: "bold",
    overflow: "hidden",
    maxWidth: "15ch",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    alignSelf: "flex-start",
  },
});
