import {
  CircularProgress,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { CircularSlider } from "../../../../components/CirclularSlider";
import { useHistory, useRouteMatch } from "react-router-dom";
import tinycolor2 from "tinycolor2";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import {
  ArrowDownControl,
  ArrowUp,
  AutoMode,
  AutoVRMode,
  ColdMode,
  ControlWrapper,
  DryIcon,
  HotMode,
  ModeFanIcon,
  ScheduleOffIcon,
  SwitcherOffIcon,
  SwitcherOnIcon
} from "../../../../svgComponents";
import groupPanelStyles from "./GroupPanel.style";

interface IObject {
  [key: string]: any;
}

const colorRanges: any = {
  COOL: ["#35a8e0", "#2d2e82"],
  HEAT: ["#f8b133", "#f05146"],
  AUTO: ["#35a8e0", "#f05146"],
  DRY: ["#266101", "#266101"],
  FAN: ["#4f00ed", "#4f00ed"],
  default: ["#ffffff", "#000000"],
  HH: ["#f8b133", "#f05146"],
  HAUX: ["#f8b133", "#f05146"]
};

const modeLimits: any = {
  1: [16, 32],
  2: [60, 90]
};

const GroupPanel: React.FC<any> = (props) => {
  const {
    group,
    navigateToSchedules,
    selectedSiteId,
    types,
    temperatureScale: userTempScale,
    addMessage,
    operationStatusesMirror,
    isCelsius,
    siteTemp,
    tempSymbol,
    changeSitePower,
    siteId,
    canControlSiteOperationStatus = true
  } = props;

  const useStyles = makeStyles(groupPanelStyles);
  const classes = useStyles();
  const history = useHistory();
  const changeGroupPower = useStoreActions((state) => state.groups.changePowerState);
  const changeSiteSetpoints = useStoreActions((state) => state.sites.changeSiteSetpoints);
  const changeGroupSetPoint = useStoreActions(
    (state) => state.groups.changeSetPoint
  );
  const allUnits = useStoreState(
    (state) => state.units.allUnits
  );
  const { id: groupId = "", units: groupUnits = [], name: groupName = "", sensors: groupSensors, schedules = [], permissions = {} } = group || {};
  const hasSchedules = schedules.length > 0;
  const { canControlSetpoint, canControlOperationStatus } = permissions;

  const tempLimits: any = modeLimits[userTempScale];

  const [currentColor, setCurrentColor] = useState<any>("#888");
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [updateObj, setUpdateObj] = useState<any>(null);
  const [disablePowerBtn, setDisablePowerBtn] = useState<boolean>(false);
  const [groupStatus, setGroupStatus] = useState<any>({
    power: false,
    setPoint: null,
    mode: null
  });
  const devices = useStoreState((state) => state.devices.allDevices);
  const unitSubTypesMirror = useStoreState((state) => state.unitSubTypesMirror);
  const allSensors = useStoreState((state) => state.sensors.allSensors);

  const [timer, setTimer] = useState<any>();
  const [groupTimer, setGroupTimer] = useState<any>();
  const [localActiveSetpoint, setLocalActiveSetPoint] = useState<any>(0);
  const [startTemp, setStart] = useState<number>(0);
  const [endTemp, setEnd] = useState<number>(48);
  const [startAngle, setStartAngle] = useState<number>(0);
  const [endAngle, setEndAngle] = useState<number>(360);
  const [enableSetpointHandlers, setEnableSetpointHandlers] = useState<boolean>(false);

  const modeIcons: IObject = {
    COOL: <ColdMode className={clsx(classes.iconStyle, classes.modeIcon)} />,
    HEAT: <HotMode className={clsx(classes.iconStyle, classes.modeIcon)} />,
    AUTO: (
      <AutoMode
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    "Virtual Auto": (
      <AutoVRMode
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    DRY: (
      <DryIcon
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    FAN: (
      <ModeFanIcon
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    4: <span style={{ color: "rgb(240, 81, 70)" }}>HAUX</span>,
    6: <span style={{ color: "rgb(240, 81, 70)" }}>HH</span>
  };

  const { operationModesExtended, temperatureScale } = types;
  const powerOnVal = operationStatusesMirror && +operationStatusesMirror.on;

  const isTempInC = temperatureScale[userTempScale] === "celsius";

  useEffect(() => {
    if (!operationStatusesMirror || _.isEmpty(operationStatusesMirror)) {
      return;
    }
    //check here later
    let power: boolean = false,
      setPoint: any = null,
      mode: any = null,
      first: boolean = true;
    const units = groupUnits;
    let allUnitsHasSetpointControl: boolean = false;
    let checkForNum: boolean = false;
    let checkForNumMode: boolean = false;

    if (_.isEmpty(units)) {
      return;
    }

    let oneAtleastIsConnected: boolean = false;
    for (let x in units) {
      const id = units[x];
      const unit = allUnits[id] || {};
      if (!unit || unit?.subType === +unitSubTypesMirror.waterHeater || !unit?.isVisible || !unit?.isConnected || !devices[unit?.device || ""]?.isConnected) {
        continue;
      }
      const { capabilityFlags = {} } = unit;
      const { enableOperationModeSelection = false, enableSetpointControl = false } = capabilityFlags;

      oneAtleastIsConnected = true;
      if (first) {
        power = unit.activeOperationStatus === powerOnVal;
        setPoint = enableSetpointControl ? unit.activeSetpoint : null;
        mode = enableOperationModeSelection ? unit.activeOperationMode : null;
        first = false;
        allUnitsHasSetpointControl = enableSetpointControl;
        if (!checkForNum && enableSetpointControl) {
          checkForNum = true;
        }
        if (!checkForNumMode && enableOperationModeSelection) {
          checkForNumMode = true;
        }
        continue;
      }

      if (!checkForNum && enableSetpointControl) {
        checkForNum = true;
        setPoint = unit.activeSetpoint;
      }
      if (!checkForNumMode && enableOperationModeSelection) {
        checkForNumMode = true;
        mode = unit.activeOperationMode;
      }

      if (!power && unit.activeOperationStatus === powerOnVal) {
        power = true;
      }
      if (enableSetpointControl && !isNaN(setPoint) && setPoint !== unit.activeSetpoint) {
        setPoint = null;
      }
      if (enableOperationModeSelection && !isNaN(mode) && mode !== unit.activeOperationMode && unit.subType !== +unitSubTypesMirror.waterHeater) {
        mode = null;
      }
      if (allUnitsHasSetpointControl) {
        continue;
      }
      allUnitsHasSetpointControl = enableSetpointControl;
    }
    if (!oneAtleastIsConnected || !power) {
      for (let x in groupSensors) {
        const id: any = groupSensors[x];
        const sensor: any = allSensors[id];

        if (!sensor || !devices[sensor?.device || ""]?.isConnected) {
          continue;
        }
        if (sensor?.type === 129) {
          oneAtleastIsConnected = true;
          if (sensor?.readingValue === 1) {
            power = true;
          }
        }
      }
    }

    setUpdateObj({ power, setPoint, mode });
    setDisablePowerBtn(!oneAtleastIsConnected);
    setEnableSetpointHandlers(allUnitsHasSetpointControl);
  }, [groupUnits, operationStatusesMirror, powerOnVal, allUnits]);

  useEffect(() => {
    if (!updateObj) {
      return;
    }
    if (isFirstLoad || !groupTimer) {
      setLocalActiveSetPoint(Math.round(updateObj.setPoint) || "--");
      setGroupStatus(updateObj);
      setUpdateObj(null);
      setIsFirstLoad(false);
      return;
    }
  }, [isFirstLoad, groupTimer, updateObj]);

  useEffect(() => {
    if (
      !localActiveSetpoint ||
      groupStatus.setPoint === localActiveSetpoint ||
      isNaN(localActiveSetpoint)
    ) {
      return;
    }
    if (timer) {
      clearTimeout(timer);
    }
    let newTimer: NodeJS.Timeout = setTimeout(() => {

      groupId === "allUnits" ?
        changeSiteSetpoints({ siteId, setpoint: localActiveSetpoint })
          .catch((err: any) => addMessage({ message: err.message }))
        : changeGroupSetPoint({ groupId, setPoint: localActiveSetpoint })
          .catch((err: any) => addMessage({ message: err.message }));
    }, 1000);
    setTimer(newTimer);
  }, [
    localActiveSetpoint,
    addMessage,
    groupId,
    groupStatus
  ]);

  const changeTemp = (isAdd: boolean) => {
    if (
      (isAdd && localActiveSetpoint === tempLimits[1]) ||
      (!isAdd && localActiveSetpoint === tempLimits[0])
    ) {
      return;
    }

    const emptyTemp = !localActiveSetpoint || isNaN(localActiveSetpoint);

    const startTemp = !emptyTemp ? localActiveSetpoint : isTempInC ? 24 : 75;
    const updatedSetPoint = emptyTemp
      ? startTemp
      : isAdd
        ? startTemp + 1
        : startTemp - 1;
    setLocalActiveSetPoint(updatedSetPoint);

    if (!isFirstLoad && !groupTimer) {
      let newTimer: NodeJS.Timeout = setTimeout(() => {
        clearTimeout(groupTimer);
        setGroupTimer(null);
      }, 10000);
      setGroupTimer(newTimer);
    }
  };

  useEffect(() => {
    const mode = groupStatus.mode;
    if (
      _.isEmpty(groupUnits) || (!mode && mode !== 0) || mode > 2 || (!localActiveSetpoint && localActiveSetpoint !== 0)
    ) {
      return;
    }

    const minTempPerType = isCelsius ? 0 : 32;
    const maxTempPerType = isCelsius ? 48 : 118.4;
    const midByType = isCelsius ? 24 : 75;
    const units: any = groupUnits;

    const groupLimits = modeLimits[userTempScale];

    const minLimit = Math.min((groupLimits[0] || minTempPerType), localActiveSetpoint);
    const maxLimit = Math.max((groupLimits[1] || maxTempPerType), localActiveSetpoint);
    if (minLimit === startTemp && maxLimit === endTemp) {
      return;
    }

    setStart(minLimit);
    setEnd(maxLimit);

    const startAngle = (180 * +minLimit) / midByType;
    const endAngle = (180 * +maxLimit) / midByType;

    const issue = (startAngle >= endAngle) || (endAngle > 360);

    setStartAngle(!issue ? startAngle : 5);
    setEndAngle(!issue ? endAngle : 355);
  }, [
    groupStatus,
    groupUnits,
    endTemp,
    startTemp,
    localActiveSetpoint,
    isCelsius,
    allUnits
  ]);

  useEffect(() => {
    const mode =
      groupStatus.mode === null ? "default" : operationModesExtended[groupStatus.mode];

    const range = colorRanges[mode]
      ? colorRanges[mode]
      : colorRanges["default"];
    const c1 = range[0];
    const c2 = range[1];
    const absoluteVal =
      ((localActiveSetpoint - tempLimits[0]) /
        (tempLimits[1] - tempLimits[0])) *
      100;
    const c3 = tinycolor2.mix(c1, c2, absoluteVal);
    setCurrentColor(c3.toHexString());
  }, [localActiveSetpoint, operationModesExtended, groupStatus, tempLimits]);

  const togglePower = () => {
    const state = !groupStatus.power ? 1 : 2;
    groupId === "allUnits" ? changeSitePower({ state, siteId }) :
      changeGroupPower({ groupId, state })
        .catch((error: any) => {
          addMessage({ message: error.message });
        });
  };

  const currentMode =
    groupStatus.mode === null ? null : operationModesExtended[groupStatus.mode];
  const noControl = currentMode === "DRY" || currentMode === "FAN" || groupId === "allUnits" || !enableSetpointHandlers;
  const isPowerOn = groupStatus.power;
  if (groupId === "sensors" || _.isEmpty(groupUnits)) {
    return (<> </>);
  }
  return (
    <div className={classes.groupView}>
      <div className={classes.firstRowContainer}>
        <Typography className={classes.siteTemp}>{(isCelsius ? siteTemp.C || "" : siteTemp.F || "") + " "}<span className={classes.scaleStyle}>{siteTemp.C && tempSymbol || ""}</span></Typography>
        <div className={classes.nameSection}>
          <Typography>{groupName}</Typography>
        </div>

        <div className={clsx(classes.powerContainer, { [classes.disabled]: disablePowerBtn })}>
          <IconButton
            className={clsx(classes.powerIconStyle, { [classes.disabled]: disablePowerBtn })}
            onClick={() => togglePower()}
            disabled={disablePowerBtn || (groupId === "allUnits" ? !canControlSiteOperationStatus : !canControlOperationStatus)}
          >
            {isPowerOn && (
              <SwitcherOnIcon className={classes.powerOffButtonClick} />
            )}
            {!isPowerOn && (
              <SwitcherOffIcon className={classes.powerOnButtonClick} />
            )}
          </IconButton>
          <Typography className={classes.powerTextStyle}>
            {isPowerOn ? t`POWER OFF` : t`POWER ON`}
          </Typography>
        </div>
      </div>

      <div className={classes.secondRowContainer}>
        <div className={classes.controlDiv} >
          <ControlWrapper
            className={clsx(classes.controlWrapperStyle)}
            fill={isPowerOn ? currentColor : "rgba(253,253,254, 0.5)"}
            width={336}
            height={326}
          />
          {!noControl ? (
            <Fragment>
              <div
                className={clsx(classes.sliderContainer)}
              >
                {(!isNaN(startTemp) && !isNaN(endTemp)) && <CircularSlider
                  size={280}
                  minValue={startTemp}
                  maxValue={endTemp}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  coerceToInt={true}
                  angleType={{
                    direction: "cw",
                    axis: "-y"
                  }}
                  handle1={{
                    value:
                      localActiveSetpoint === null || isNaN(localActiveSetpoint)
                        ? 0
                        : localActiveSetpoint,
                    onChange: () => { }
                  }}
                  arcColor={"#48314A"}
                  arcBackgroundColor={"#48314A"}
                  disabled={disablePowerBtn || !canControlSetpoint}
                />}
              </div>
              <div
                className={clsx(classes.innerControls)}
              >
                {(canControlSetpoint && groupId !== "allUnits") &&
                  <IconButton
                    onClick={() => changeTemp(true)}
                    className={clsx(classes.controlArrow, { [classes.disabled]: disablePowerBtn })}
                    disabled={disablePowerBtn}
                  >
                    <ArrowUp />
                  </IconButton>}
                <div className={classes.tempHolder}>
                  <Typography
                    className={clsx(classes.setPointStyle)}
                  >
                    {!isNaN(localActiveSetpoint) ? localActiveSetpoint : "--"}
                  </Typography>
                  <span>{isCelsius ? t`°C` : t`°F`}</span>
                </div>
                <div className={classes.ampTempContainer}>
                  {modeIcons[operationModesExtended[groupStatus.mode]] || (operationModesExtended[groupStatus.mode] || "")}
                </div>
                {(canControlSetpoint && groupId !== "allUnits") &&
                  <IconButton
                    onClick={() => changeTemp(false)}
                    className={clsx(classes.controlArrow, { [classes.disabled]: disablePowerBtn })}
                    disabled={disablePowerBtn}
                  >
                    <ArrowDownControl />
                  </IconButton>}
              </div>
            </Fragment>
          ) : (
            <Typography className={classes.modeTitle}>{currentMode || "--"}</Typography>
          )}
        </div>
      </div>

      <div className={classes.lastRowContainer}>
        {groupId !== "allUnits" &&
          <div className={classes.iconContainer}>
            <IconButton
              disableFocusRipple
              disableRipple
              className={classes.mainIconsStyle}
              name="schedule"
              onClick={() => history.push(`/group/${groupId}/schedules`)}
            >
              <ScheduleOffIcon />
              {hasSchedules && <span className={classes.badge}>{schedules.length}</span>}
            </IconButton>
          </div>
        }
      </div>
    </div>
  );
};

export default GroupPanel;
