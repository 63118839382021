import {
  Dialog,
  Drawer,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import Button from "../../cool_widgets/Button";
import { Close, Download } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { downloadAs } from "../../services/download";
import useStyles from "./FilesTableDialog.style";
import SiteTreeView from "./SiteTree";

export default (function FilesTableDialog(props: any) {
  const { openFoldersDialog, siteId, site } = props;
  const classes = useStyles();
  const [files, setFiles] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [siteTree, setSiteTree] = useState<any>({});

  const getSiteFiles = useStoreActions((action) => action.files.getSiteFiles);
  const uploadFile = useStoreActions((action) => action.files.uploadFile);
  const updateFilesInfo = useStoreActions((action) => action.files.updateFilesInfo);
  const updateFile = useStoreActions((action) => action.files.updateFile);
  const deleteFile = useStoreActions((action) => action.files.deleteFile);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const allUsers = useStoreState((state) => state.users.users);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);

  const { canCreateUserFiles = true, canDeleteUserFiles = true, canUpdateUserFiles = true } = site?.permissions || {};

  useEffect(() => {
    if (!siteId) {
      setSiteTree({});
      return;
    }

    const systemsToUnitsMap: any = {};
    //what about units not in systems?
    Object.values(allSystems).forEach((system: any) => {
      if (system.site === siteId) {
        const units: any = {};
        system.units.forEach((unitId: string) => {
          const unit = allUnits[unitId];
          if (!unit) {
            return;
          }
          units[unitId] = unit;
        });
        systemsToUnitsMap[system.id] = { ...system, units };
      }
    });

    setSiteTree(systemsToUnitsMap);

  }, [siteId]);

  useEffect(() => {

    getSiteFiles(siteId)
      .then((res: any) => setFiles(res))
      .catch((err: any) => addMessage({ message: err.message }));
  }, [siteId]);

  const addNewFile = (file: any) => {
    files.push(file);
    setFiles(files);
  };

  const deleteCurrentFile = (fileId: string) => {
    return deleteFile(fileId)
      .then(() => setFiles(files.filter((file: any) => {
        const id = file._id || file.id;
        return id !== fileId;
      })))
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const updateFileRow = (index: number, file: any) => {
    files[index] = { ...file };
    setFiles(files);
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`File Library`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => openFoldersDialog("")}><Close color="#7f7692" /></IconButton>
      </div>
      <div className={classes.dialogContent}>
        <main
          style={{ display: "flex", flexFlow: "column nowrap", padding: "20px", overflow: "hidden" }}
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}>
          {open && <div className={classes.opacityStyle} />}
          <Button disabled={!canCreateUserFiles} width={160} style={{ alignSelf: "flex-end" }}
            onClick={() => setOpen(!open)}
          >{t`add new file`}</Button>
          <Paper elevation={0} className={classes.paperTableContainer}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      style={{ minWidth: 250 }}
                      align="left"
                    >{t`FILE NAME`}</TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      style={{ minWidth: 122 }}
                      align="left"
                    >{t`UPLOAD DATE`}</TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      style={{ minWidth: 175 }}
                      align="left"
                    >{t`UPLOADING USERNAME`}</TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      style={{ minWidth: 90 }}
                      align="left"
                    >{t`FILE SIZE`}</TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      className={classes.iconsColumnWidth}
                      align="left"
                    >{t`DOWNLOAD`}</TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      className={classes.iconsColumnWidth}
                      align="left"
                    >{t`DELETE`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file: any, index: number) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        classes={{ root: classes.overWritePaddingRow }}
                        onDoubleClick={() => { setSelectedFile({ ...file, index }); setOpen(true); }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          classes={{ root: classes.overWritePadding }}
                          align="left"
                        >
                          {file.name}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.overWritePadding }}
                          align="left"
                        >
                          {moment(file.date).tz(site?.timezone || "").format(`${dateFormat} ${timeFormat}`)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          classes={{ root: classes.overWritePadding }}
                          align="left"
                        >
                          {allUsers[file.user]?.username || ""}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.overWritePadding }}
                          align="left"
                        >
                          {file.size}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.overWritePadding }}
                          align="center"
                        >
                          <IconButton onClick={() => downloadAs(file.url, file.name)}>
                            <Download style={{ fontSize: 21 }} />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.overWritePadding }}
                          align="center"
                        >
                          <Delete
                            disabled={!canDeleteUserFiles}
                            type={t`File`}
                            object={{ name: file.name }}
                            detach={() => deleteCurrentFile(file._id || file.id)}
                          ></Delete>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {open && <SiteTreeView canEdit={canUpdateUserFiles} close={() => { setOpen(false); setSelectedFile(undefined); }} updateFileRow={updateFileRow} updateFile={updateFile} file={selectedFile} addMessage={addMessage} addNewFile={addNewFile} site={site} siteTree={siteTree} updateFilesInfo={updateFilesInfo} uploadFile={uploadFile} allUnits={allUnits} />
          }      </Drawer>
      </div>
    </Dialog>
  );
});
