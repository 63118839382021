import { makeStyles } from "@material-ui/core";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100%",
  },
  content: {
    // margin: '1rem',
    height: "100%",
    marginTop: 0,
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
    height: "100vh",
    width: "calc(100% - 240px)",
  },
  container: {
    padding: "3rem",
  },
  tableContainer: {
    height: "calc(100% - 53px)",
    // padding: "1rem",
    width: "auto",
  },
  processingAnimationContainer: {
    display: "flex",
    flexDirection: "column",
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  progress: {
    height: "calc(100vh - 125px)",
    padding: "1rem",
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  hidden: {
    display: "none",
  },

  alertInfoContainer: {
    height: "100%",
  },
  selected: {
    backgroundColor: "#f4f4f4",
  },
  wideColumn: {
    width: "36ch",
    minWidth: "36ch",
    maxWidth: "36ch",
  },
  headContainer: { display: "flex", alignItems: "center", maxWidth: "200px" },
  blueFilter: { fill: "#1976d2" },
  filterStyle: { marginLeft: "10px" },
  dateColumn: { minWidth: "135px" },
  tablePaper: {
    background: "#fefefe",
    padding: "20px",
    paddingTop: 0,
    borderRadius: "4px",
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    border: "solid 1px #ebebeb",
    height: "100%",
  },
  tablePagesHeader: { display: "flex", justifyContent: "flex-end", position: 'relative' },
  columnsTitles: {
    padding: "0",
    ...theme.tables.headerCell,
  },
  pagingStyle: { border: "none" },
  cellStyle: {
    ...theme.tables.tableCell,
    height: "35px",
    padding: "0 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1rem",
    minHeight: "70px",
    background: theme.palette.background.default,
  },
  contentHeaderContainer: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    padding: "16px",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80px",
    marginBottom: "16px"
  },
  contentContainer: {
    height: "calc(100% - 160px)",
    margin: "unset",
    padding: "1rem",
    paddingTop: 0
  },

  hiddenComponent: {
    visibility: "hidden"
  },

  shareButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "6px",
    background: theme.gradiants.button,
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  showWhiteSpace: {
    whiteSpace: "pre",
  },
  limitWidth: {
    minWidth: "23ch",
    maxWidth: "23ch",
    width: "23ch",
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  searchIcon: {
    color: "#fff",
  },
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    margin: "auto",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%",
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px",
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px",
    },
  },

  buttonsContainer: {
    marginLeft: 'auto'
  },

  reloadButton: {
    display: 'flex',
    alignItems: 'center',
    position: "absolute",
    left: 0,
    top: 8
  },

  reloadLabel: {
    color: '#AAA2AA',
    fontSize: '13px',
    fontFamily: 'Roboto',
    fontWeight: 500,
  },

  reloadIcon: {
    color: '#AAA2AA',
    "& svg": {
      fontSize: "24px",
    },
  },

  newAlertsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  alertIcon: {
    color: "#f05347",
    height: "18px",
    width: "19px"
  },

  alertsMessagesContainer:{
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
  },

  alertMessage: {
    fontSize: "13px",
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#79797D",
    marginLeft: "5px"
  },

  IconButton: {
    margin: "7px",
  },

  listContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 10px",
  },

  title: {
    padding: "10px 10px 0px 10px",
    color: theme.palette.colors.purple1,
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  allOpenAlertsButton: {
    width: "225px",
    position: "fixed",
    right: "25%",
    height: 36,
    justifyContent: "center",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  datePickerButton: {
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  selectUnitButton: {
    width: "270px",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    ...placeBackgroundColor(theme.palette.background.topDropDownSelect),
    color: theme.palette.colors.menuFont,
    "&:hover": {
      ...placeBackgroundColor(theme.palette.background.topDropDownSelect),
    }
  },
  selectUnitButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectUnitButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  allOpenAlertsButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  Arrow: {
    marginLeft: "0px",
    "&>svg": {
      fontSize: "36px !important"
    }
  },
  datePickerDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '14px'
  },
  datePickerDialogHeader: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `2px solid #d9d9d9`,
    marginBottom: '15px'
  },
  datePickerDialogPresetCont: {
    display: 'flex',
    flexDirection: 'column',
    width: "200px",
    marginBottom: "45px",
    gap: '15px'
  },
  datePickerDialogPresetBtn: {
    textTransform: 'unset',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.colors.gray4,
    "&:hover": {
      backgroundColor: theme.palette.colors.gray4
    }
  },
  datePickerDialogPresetBtnActive: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.colors.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.colors.white
    }
  },
  datePickerTitle: {
    fontSize: '18px',
    color: theme.palette.primary.dark,
    fontWeight: 500
  },
  datePickerSubtitle: {
    fontSize: '14px',
    color: theme.palette.primary.dark,
  }

}));
export default useStyles;
