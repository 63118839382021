import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  dialogStyle: { width: 550, height: 220, overflow: "hidden" },
  agreeBtn: {
    backgroundColor: "rgba(41, 19, 46, 0.95)",
    color: "white",
    padding: "5px 50px",
    fontSize: "16px",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: "rgba(41, 19, 46, 0.95)",
      color: "white"
    }
  },
  view: {
    // minWidth: "400px",
    minHeight: "100px",
    "& p": {
      fontSize: "18px"
    }
  },
  errorBox: {
    fontSize: "20px",
    fontFamily: "Helvetica Neue Helvetica Arial sans-serif",
    background: "rgba(1, 13, 19, 0.9)",
    height: "100%",
    width: "100%",
    zIndex: 100
  },
  modalScreen: {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  header: {
    display: "block",
    minHeight: "50px",
    width: "100%",
    position: "relative",
    color: "white"
  },

  title: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  main: {
    minHeight: "50px",
    backgroundColor: "white"
  },

  content: {
    lineHeight: "30px",
    verticalAlign: "middle"
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
    padding: "10px 5px 0px 0"
  },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"

  },
  overWriteIcon: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  }
}));
export default useStyles;
