import {
  CircularProgress,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { tz } from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import useStyles from "./addEditSite.style";
import { countries } from "./countryList";
import { stateList } from "./stateList";
import useMiniForm from "@hooks/useMiniForm/useMiniForm";
import { Checkbox } from "@components/Checkbox";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const urlValidatorPattern = new RegExp(
  '^(' + // Start of group for protocol (optional)
    '(https?:\\/\\/)?' + // Optional protocol (http or https)
  ')?' + // Optional '?' to allow empty protocol group
  '(' + // Start of group for IP address or domain
    '[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}' + // IP address format
  '|' + // OR separator
    '((www\\.)?([a-z\\d-]+)\\.)*([a-z\\d-]+)\\.[a-z]{2,}' +  // Main domain name (with subdomain)
  ')' + // End of group for IP address or domain
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+:]*)*' + // Optional port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // Optional query string
  '(\\#[-a-z\\d_]*)?$', 'i' // Optional fragment locator
);



const removeProtocol = (url: string) => {
  return url.replace(/^https?:\/\//i, '');
}


// const validateURLAvailability = async (url: string) => {
//   try {
//     const response = await fetch(url, { method: 'HEAD' });
//     return response.ok; // Returns true if status code is within 200-299
//   } catch (error) {
//     return false;
//   }
// }


export default function Site(props: { siteId: null | true | string; close: any; actions: any; item: any }) {
  const [saving, setSaving] = React.useState<boolean>(false);
  const [isLoadingSite, setIsLoadingSite] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const allCustomers = useStoreState((state) => state.customers.allCustomers);
  const { customerId } = useStoreState((state) => state.selections.selections);
  const getSite = useStoreActions((actions) => actions.sites.getSiteById);
  const createSite = useStoreActions((action) => action.createSite);
  const updateSite = useStoreActions((action) => action.updateSite);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getSiteFlags = useStoreActions((actions) => actions.sites.getSiteFlags);

  const { connect, values, errors, init, validateForm, change }: any = useMiniForm({ validateOnChange: true });

  const countriesOptions = useMemo(() => {
    const parsedData = countries.map(country => ({
      label: country.name,
      value: country.code
    }));
    return parsedData;
  }, [countries]);

  const stateOptions = useMemo(() => {
    const parsedData = stateList.map((state: any) => ({
      value: state.value,
      label: state.label
    }));
    return parsedData;
  }, [stateList]);

  const tzOptions = useMemo(() => {
    const data = tz.names().map((timezone: any) => {
      const label = `${timezone}  ${tz(timezone).format("Z")}`;
      return { value: timezone, label };
    });
    return data;
  }, [])


  const { siteId, close, actions, item } = props;
  const { canUpdate = true } = item || {};
  const isNew = siteId === true;

  useEffect(() => {
    setIsLoadingSite(true);
    setShowPassword(false);
    //siteId === true means it's a new site not edit
    if (!siteId || siteId === true) {
      const initVals: any = {
        name: "",
        customer: customerId || null,
        customerName: customerId && allCustomers[customerId]?.name,
        city: "",
        address: "",
        postalCode: "",
        country: "",
        state: "",
        timezone: null,
        enableURL: false,
        URL: "",
        urlUsername: "",
        urlPassword: ""
      }
      init(initVals);
      setIsLoadingSite(false);
    }
    else {
      getSite(siteId)
        .then((siteData: any) => {
          const siteCustomerId = siteData?.customer || customerId;

          const initVals = {
            name: siteData?.name || "",
            customer: siteCustomerId || "",
            customerName: siteCustomerId && allCustomers[siteCustomerId]?.name,
            city: siteData?.city || "",
            address: siteData?.address || "",
            postalCode: siteData?.postalCode || "",
            country: siteData?.country ? countriesOptions.filter((country: any) => country.value === siteData?.country)[0] : "",
            state: siteData?.state ? stateOptions.filter((state: any) => state.value === siteData.state)[0] : "",
            timezone: siteData?.timezone ? tzOptions.filter((tz: any) => tz.value === siteData.timezone)[0] : "",
            enableURL: siteData?.enableURL || false,
            URL: siteData?.URL || "",
            urlUsername: siteData?.urlUsername || "",
            urlPassword: siteData?.urlPassword || ""
          }
          init(initVals);
          setIsLoadingSite(false);
        });
    }

  }, [siteId]);



  const classes = useStyles();

  const errorBox = error ? (
    <ErrorBox error={error} onClose={() => setError(null)} />
  ) : null;

  const saveSite = async (isNew: boolean, site: any, siteId: any) => {
    if (!site) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    setSaving(true);
    const data = {
      name: site.name,
      country: site.country.value,
      city: site.city,
      state: site.state?.value || "",
      address: site.address,
      postalCode: site.postalCode,
      timezone: site?.timezone?.value || "",
      enableURL: site.enableURL || false,
      URL: site.URL || "",
      urlUsername: site.urlUsername || "",
      urlPassword: site.urlPassword || ""
    };
    const method = isNew ? createSite({ customer: site.customer, ...data }) :
      updateSite({ siteId, updatedData: data });

    method.then(() => {
      close();
      actions.fetchData();
      isNew && getSiteFlags();
    })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setSaving(false));
  };

  const handleShowPassword = () => {
    setShowPassword(prev => !prev)
  }

  const toggleUrlState = () => {
    change('enableURL', !values.enableURL)
  }

  const validateUrlPassword = (newValue: any, values: any) => {
    if (values.urlUsername?.length > 0 && !newValue.length) {
      return true;
    }
    return false;
  }

  const handleUrlUsernameChange = ({ newValue, change }: any) => {
    if (!newValue.length) {
      change('urlPassword', '');
    }
  }
  const handleCountryChange = (e: any, value: any) => {
    change('country', value);
    if (value.value !== "US") {
      change('state', "");
    }
  }

  const handleStateChange = (e: any, value: any) => {
    change('state', value);
  }

  const handleTZChange = (e: any, value: any) => {
    change('timezone', value);
  }

  const validateURLSchema = (url: string) => {
    if (!url) {
      return true;
    }

    const isValid = urlValidatorPattern.test(url)
    return !isValid;
  }

  return (
    <Dialog
      open={!!siteId}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      {saving && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 999 }}>
        <CircularProgress />
      </div>}

      <div className={classes.titleContent}>
        <Typography className={classes.headerTitle}>{!isNew ? t`Edit site` : t`Add New Site`}</Typography>
        <IconButton disableRipple onClick={() => close()} className={classes.iconBtnStyle}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        {errorBox}
        {
          isLoadingSite ? (
            <div className={classes.loaderWrapper}><CircularProgress /></div>
          ) : (
            <Grid container spacing={3} direction="row">
            <Grid item xs={6}>
              <div className={classes.imgContainer}>
                <img
                  className={classes.deviceImg}
                  src={assetsService.getImagesPathFromDomainFolder("cmnet.png")}
                />
              </div>

              <div className={classes.urlContainer}>
                <div className={classes.chkboxContainer}>
                  <Checkbox
                    onChange={toggleUrlState}
                    checked={values.enableURL}
                  />
                  <Typography>{t`Embed 3rd Party URL`}</Typography>
                </div>
                {
                  values.enableURL && <>
                    <div className={classes.thirdPartyInputs}>
                      <Typography>{t`URL`}</Typography>
                      <TextField
                        id="site_url"
                        disabled={!canUpdate}
                        variant="outlined"
                        fullWidth
                        {...connect('URL', {
                          allowUndefinedValue: true,
                          validations: [{ validator: validateURLSchema, message: t`invalid URL` }]
                        })} />
                    </div>
                    <div className={classes.thirdPartyInputs}>
                      <Typography>{t`Username`}</Typography>
                      <TextField
                        id="site_url_username"
                        disabled={!canUpdate}
                        variant="outlined"
                        fullWidth
                        {...connect('urlUsername', {
                          allowUndefinedValue: true,
                          customOnChange: handleUrlUsernameChange
                        })} />
                    </div>
                    <div className={classes.thirdPartyInputs}>
                      <Typography>{t`Password`}</Typography>
                      <TextField
                        id="site_url_password"
                        disabled={!canUpdate || !values.urlUsername?.length}
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} disabled={!canUpdate || !values.urlUsername?.length}>
                                {showPassword ? <BsEye /> : <BsEyeSlash />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...connect('urlPassword', {
                          allowUndefinedValue: true,
                          customErrorPropName: 'helperText',
                          validations: [{ validator: validateUrlPassword, message: t`The password is required when you have a username` }]
                        })} />
                    </div>
                  </>
                }
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.formContainer}>
                <div className={classes.inputFieldWithLabel}>
                  <Typography>{t`Customer`}</Typography>
                  <TextField
                    id="customer"
                    disabled={true}
                    variant="outlined"
                    fullWidth
                    {...connect('customerName', {
                      allowUndefinedValue: false,
                    })} />
                </div>

                <div className={classes.inputFieldWithLabel}>
                  <Typography>{t`Site Name`}</Typography>
                  <TextField
                    id="site_name"
                    disabled={!canUpdate}
                    variant="outlined"
                    fullWidth
                    {...connect('name', {
                      allowUndefinedValue: false,
                      customErrorPropName: 'helperText',
                      validations: [{ required: t`Missing site name` }]
                    })} />
                </div>
                <div className={classes.inputFieldWithLabel}>
                  <Typography>{t`Street Address`}</Typography>
                  <TextField
                    id="site_address"
                    disabled={!canUpdate}
                    variant="outlined"
                    fullWidth
                    {...connect('address', {
                      allowUndefinedValue: false,
                      customErrorPropName: 'helperText',
                      validations: [{ required: t`Missing site address` }]
                    })} />
                </div>
                <div className={classes.inputFieldWithLabel}>
                  <Typography>{t`City`}</Typography>
                  <TextField
                    id="site_city"
                    disabled={!canUpdate}
                    variant="outlined"
                    fullWidth
                    {...connect('city', {
                      allowUndefinedValue: false,
                      customErrorPropName: 'helperText',
                      validations: [{ required: t`Missing site city` }]
                    })} />
                </div>
                <div className={classes.inputFieldWithLabel}>
                  <Typography>{t`Zip Code`}</Typography>
                  <TextField
                    id="site_zip"
                    disabled={!canUpdate}
                    variant="outlined"
                    fullWidth
                    {...connect('postalCode', {
                      allowUndefinedValue: false,
                      customErrorPropName: 'helperText',
                      validations: [{ required: t`Missing site city` }]
                    })} />
                </div>
                <div className={classes.inputFieldWithLabel}>
                  <Typography>{t`Country`}</Typography>
                  <Autocomplete
                    options={countriesOptions}
                    getOptionLabel={(option: any) => option.label}
                    fullWidth
                    disableClearable
                    renderOption={(option: any) => (
                      <li {...props}>
                        <span className={`fi fi-${option.value.toLowerCase()}`} />
                        {`  ${option.label}`}
                      </li>
                    )}
                    {...connect('country', {
                      allowUndefinedValue: false,
                      customErrorPropName: 'helperText',
                      validations: [{ required: t`Missing site country` }],
                    })}
                    onChange={handleCountryChange}
                    renderInput={(params) => <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: values.country ? (
                          <span className={`fi fi-${values.country.value.toLowerCase()}`} />)
                          : null
                      }}
                    />}
                  />
                </div>
                {values.country?.value === "US" && <div className={classes.inputFieldWithLabel}>
                  <Typography>{t`State`}</Typography>
                  <Autocomplete
                    options={stateOptions}
                    fullWidth
                    disableClearable
                    getOptionLabel={(option: any) => option.label}
                    {...connect('state', {
                      allowUndefinedValue: false,
                      customErrorPropName: 'helperText',
                      validations: [{ required: t`Missing site state` }]
                    })}
                    onChange={handleStateChange}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        variant="outlined"
                      />}
                  />
                </div>}

                <div className={classes.inputFieldWithLabel}>
                  <Typography>{t`Time Zone`}</Typography>

                  <Autocomplete
                    options={tzOptions}
                    fullWidth
                    disableClearable
                    getOptionLabel={(option: any) => option.label}
                    value={values.timezone || ""}
                    // {...connect('timezone', {
                    //   allowUndefinedValue: true
                    // })}
                    onChange={handleTZChange}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        variant="outlined"
                      />}
                  />


                  {/* <Autocomplete
                    options={tzOptions}
                    disabled={!canUpdate}
                    fullWidth
                    disableClearable
                    getOptionLabel={(option: any) => option.label}
                    // {...connect('timezone', {
                    //   allowUndefinedValue: true,
                    // })}
                    value={values?.timezone}
                    onChange={handleTZChange}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        variant="outlined"
                      />}
                  /> */}
                </div>

                <div className={classes.modalActions}>
                  <Button
                    onClick={close}
                    onMouseUp={close}
                    white
                    width={150}
                    marginRight
                    disabled={!canUpdate}
                  >
                    {t`Cancel`}
                  </Button>

                  <Button
                    width={150}
                    onClick={() => saveSite(isNew, values, siteId)}
                    onMouseUp={() => saveSite(isNew, values, siteId)}
                    disabled={saving || !canUpdate}
                  >
                    {t`Save`}
                  </Button>
                </div>

              </div>
            </Grid>
          </Grid>
          )
        }
      </div>
    </Dialog >
  );
}
