import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import {t} from 'ttag';

import useStyles from '../../../../AddEditDialog.style';
import styles from './MonthOptionsSelector.module.css';
import SpecificDaySelector from './components/SpecificDaySelector';

//todo: add jsdoc
const MonthOptionsSelector = ({value,onChange, disabled, connect,error}) => {

	const classes = useStyles();

	const renderSpecificDaySelector = ()=> {

		if(value !== 'SpecificDay'){
			return null;
		}

		return (
			<SpecificDaySelector 
				disabled={disabled}
				placeholder={t`Select a day in the month`}
				{...connect('specificDay',{
					initialValue:2
				})}
			/>
		);


	};

	return (	
		<div className={styles['month-options-selector']}>			
			<FormControl component="fieldset">
				<RadioGroup
					aria-label="monthSubUnit"
					value={value}
					onChange={onChange}
				>
					<FormControlLabel value="End" control={<Radio disabled={disabled} className={classes.radio} />} label="End Of Month" />
					<FormControlLabel value="SpecificDay" control={<Radio disabled={disabled} className={classes.radio} />} label="Specific Day" />
				</RadioGroup>
			</FormControl>
			{renderSpecificDaySelector()}
			<Typography className={classes.error}>{error}</Typography>
		</div>	
	);
};

MonthOptionsSelector.propTypes = {
	onChange:PropTypes.func.isRequired,
	connect:PropTypes.func.isRequired,
	value:PropTypes.string,
	disabled:PropTypes.bool,
	error:PropTypes.string
};

MonthOptionsSelector.defaultProps = {
	value:'',
	disabled:false,
	error:''
};

export default MonthOptionsSelector;