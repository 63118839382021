import React from 'react';

const Timer = (props) => (
	<svg
		width={props.width || '24'}
		height={props.height || '24'}
		viewBox="3 3 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			strokeWidth={1.5}
			d="M12.39 20.89C16.4714 20.89 19.78 17.5814 19.78 13.5C19.78 9.41862 16.4714 6.11 12.39 6.11C8.30862 6.11 5 9.41862 5 13.5C5 17.5814 8.30862 20.89 12.39 20.89Z"
			stroke="white"
			strokeLinecap="round"
		/>
		<path
			strokeWidth={1.5}
			d="M15.435 10.49L12.45 13.69"
			stroke="white"
			strokeLinecap="round"
		/>
		<path
			strokeWidth={1.5}
			d="M12.39 14.85C13.1356 14.85 13.74 14.2456 13.74 13.5C13.74 12.7544 13.1356 12.15 12.39 12.15C11.6444 12.15 11.04 12.7544 11.04 13.5C11.04 14.2456 11.6444 14.85 12.39 14.85Z"
			fill="white"
		/>
		<path
			strokeWidth={1.5}
			d="M12.165 6.035V4.05"
			stroke="white"
			strokeLinecap="round"
		/>
		<path
			strokeWidth={1.5}
			d="M10.6 4H13.73"
			stroke="white"
			strokeLinecap="round"
		/>
		<path
			strokeWidth={1.5}
			d="M17.47 8.025L18.42 7.08"
			stroke="white"
			strokeLinecap="round"
		/>
	</svg>
);

export default Timer;
