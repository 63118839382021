import React from 'react';
import PropTypes from 'prop-types';

const PM4Sensor = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12.0546" cy="5.64364" r="0.736411" fill="white" />
		<circle cx="12.0546" cy="8.87252" r="0.736411" fill="white" />
		<circle cx="12.0546" cy="12.3192" r="0.736411" fill="white" />
		<circle cx="10.0755" cy="4.03292" r="0.736411" fill="white" />
		<circle cx="8.10793" cy="5.64364" r="0.736411" fill="white" />
		<circle cx="8.32571" cy="8.87252" r="0.736411" fill="white" />
		<circle cx="15.8288" cy="5.64364" r="0.736411" fill="white" />
		<circle cx="15.611" cy="8.87252" r="0.736411" fill="white" />
		<circle cx="10.0755" cy="13.884" r="0.736411" fill="white" />
		<circle cx="8.10793" cy="12.3192" r="0.736411" fill="white" />
		<circle cx="15.8288" cy="12.3192" r="0.736411" fill="white" />
		<circle cx="5.97842" cy="8.90096" r="0.736411" fill="white" />
		<circle cx="18.0216" cy="8.90096" r="0.736411" fill="white" />
		<circle cx="13.8957" cy="4.03292" r="0.736411" fill="white" />
		<circle cx="13.8957" cy="6.79448" r="0.678879" fill="white" />
		<circle cx="10.1216" cy="6.79448" r="0.678879" fill="white" />
		<circle cx="5.7606" cy="6.79448" r="0.678879" fill="white" />
		<circle cx="18.2394" cy="6.79448" r="0.678879" fill="white" />
		<circle cx="5.7606" cy="11.0074" r="0.678879" fill="white" />
		<circle cx="10.0755" cy="11.0074" r="0.678879" fill="white" />
		<circle cx="13.9532" cy="11.0074" r="0.678879" fill="white" />
		<circle cx="18.2394" cy="11.0074" r="0.678879" fill="white" />
		<circle cx="13.8957" cy="13.884" r="0.736411" fill="white" />
		<path d="M6.3125 19.6688H4.98291V19.1322H6.3125C6.56999 19.1322 6.77848 19.0912 6.93799 19.0092C7.09749 18.9271 7.2137 18.8132 7.28662 18.6674C7.36182 18.5215 7.39941 18.3552 7.39941 18.1683C7.39941 17.9974 7.36182 17.8368 7.28662 17.6864C7.2137 17.536 7.09749 17.4152 6.93799 17.3241C6.77848 17.2307 6.56999 17.184 6.3125 17.184H5.13672V21.6205H4.47705V16.6439H6.3125C6.68848 16.6439 7.00635 16.7089 7.26611 16.8387C7.52588 16.9686 7.72298 17.1486 7.85742 17.3788C7.99186 17.6066 8.05908 17.8676 8.05908 18.1615C8.05908 18.4805 7.99186 18.7528 7.85742 18.9784C7.72298 19.204 7.52588 19.376 7.26611 19.4945C7.00635 19.6107 6.68848 19.6688 6.3125 19.6688ZM9.10156 16.6439H9.74072L11.3711 20.701L12.998 16.6439H13.6406L11.6172 21.6205H11.1182L9.10156 16.6439ZM8.89307 16.6439H9.45703L9.54932 19.6791V21.6205H8.89307V16.6439ZM13.2817 16.6439H13.8457V21.6205H13.1895V19.6791L13.2817 16.6439ZM19.9399 19.9457V20.4652H16.3442V20.0927L18.5728 16.6439H19.0889L18.5352 17.642L17.062 19.9457H19.9399ZM19.2461 16.6439V21.6205H18.6138V16.6439H19.2461Z" fill="white" />
	</svg>

);

PM4Sensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

PM4Sensor.defaultProps = {
	width: 24,
	height: 25
};

export default PM4Sensor;