import React from "react";

const UnitIcon = (props) => (
  <svg width={props.size || 33} height={props.size || 33} viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.5 8.75a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-24a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h24zm0 .8h-24a1.2 1.2 0 0 0-1.194 1.077l-.006.123v8a1.2 1.2 0 0 0 1.077 1.194l.123.006h24a1.2 1.2 0 0 0 1.194-1.077l.006-.123v-8a1.2 1.2 0 0 0-1.077-1.194L28.5 9.55zM27 17.918a.4.4 0 0 1 .08.792l-.08.008H6a.4.4 0 0 1-.08-.792l.08-.008h21zm0-1.966a.4.4 0 0 1 .08.791l-.08.009H6a.4.4 0 0 1-.08-.792l.08-.008h21zm-.184-4.565a.957.957 0 1 1 0 1.914h-4.11a.957.957 0 0 1 0-1.914h4.11zm-16.924.457a.5.5 0 0 1 .09.992l-.09.008H6a.5.5 0 0 1-.09-.992l.09-.008h3.892zm.212 10.046a.408.408 0 0 0-.42.397c0 .011-.013 1.186-1.176 1.968a.383.383 0 0 0-.1.552.434.434 0 0 0 .585.093c1.513-1.015 1.53-2.549 1.53-2.614a.408.408 0 0 0-.419-.396zm3.003 0a.408.408 0 0 0-.42.397v2.29c0 .22.188.397.42.397.232 0 .42-.177.42-.396v-2.291a.408.408 0 0 0-.42-.397zm3.51 0a.408.408 0 0 0-.418.397v2.29c0 .22.187.397.419.397.232 0 .42-.177.42-.396v-2.291a.408.408 0 0 0-.42-.397zm7.875 2.365c-1.147-.771-1.176-1.918-1.177-1.97a.408.408 0 0 0-.419-.395.408.408 0 0 0-.42.397c0 .064.018 1.598 1.53 2.614a.434.434 0 0 0 .585-.093.383.383 0 0 0-.099-.553zm-4.6-2.365a.408.408 0 0 0-.419.397v2.29c0 .22.188.397.42.397.232 0 .42-.177.42-.396v-2.291a.408.408 0 0 0-.42-.397z"
      fill={props.color || "#4B1C46"}
      fillRule="nonzero" />
  </svg>

);

export default UnitIcon;
