import React from 'react';
import PropTypes from 'prop-types';
import { Button,ButtonGroup } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from '../../../../AddEditDialog.style';
import styles from './TimeUnitSelector.module.css';

const TimeUnitSelector = ({disabled, value, onChange, options, error}) => {

	const classes = useStyles();

	const handleClick = (newValue) => () => {
		onChange(newValue);
	};

	const getClassNameByKey = (key) => {
		return clsx(classes.btnText,{[classes.selectedBtn]:value === key});
	};

	return (
		<ButtonGroup color="primary" aria-label="outlined primary button group" className={clsx(classes.btnGroupTimeunits,{[styles.error]:error})}>
			{options.map(({value,text})=>(
				<Button key={value} disabled={disabled} disableRipple disableElevation onClick={handleClick(value)} className={getClassNameByKey(value)}>{text}</Button>
			))}
		</ButtonGroup>
	);
};

TimeUnitSelector.propTypes = {
	options:PropTypes.array.isRequired,
	onChange:PropTypes.func.isRequired,
	value:PropTypes.string,
	disabled:PropTypes.bool,
	error:PropTypes.string
};

TimeUnitSelector.defaultProps = {
	disabled:false,
	error:'',
	value:''
};

export default TimeUnitSelector;