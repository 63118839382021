import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

import styles from './SelectFormControl.module.css';
import clsx from 'clsx';

const randomId = `select-${parseInt(Math.random() * 100000)}`;

const SelectFormControl = ({options,error,value,onChange,disabled,className ,label,id,labelClassName,fullWidth,showLargeLabels,wrapperClassName, ...props}) => {

	const menuItems = useMemo(()=>{
		return options.map(({text,value}) =>(
			<MenuItem key={value} value={value}>{text}</MenuItem>
		) );
	});

	const labelClass = clsx(styles.label,labelClassName,{[styles.error]:error,[styles['label-large']]:value && showLargeLabels});

	return (
		<FormControl error={!!error} disabled={disabled} className={wrapperClassName} fullWidth={fullWidth}>
			<InputLabel id={`${id}-label`} className={labelClass}>{label}</InputLabel>
			<Select
				disabled={disabled}
				id={id}
				labelId={`${id}-label`}
				label={label}
				value={value}
				onChange={onChange}
				className={clsx(className,{[styles['error-field']]:error})}
				{...props}
			>
				{menuItems}
			</Select>
			<FormHelperText className={styles.error}>{error}</FormHelperText>
		</FormControl>
	);
};

SelectFormControl.propTypes = {
	onChange:PropTypes.func.isRequired,
	options:PropTypes.arrayOf(PropTypes.shape({
		value:PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
		text:PropTypes.string
	})),
	value:PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
	error:PropTypes.string,
	label:PropTypes.string,
	id:PropTypes.string,
	disabled:PropTypes.bool,
	fullWidth:PropTypes.bool,
	labelClassName:PropTypes.string,
	showLargeLabels:PropTypes.bool,
	wrapperClassName:PropTypes.string,
	className:PropTypes.string,
};

SelectFormControl.defaultProps = {
	options:[],
	value:'',
	error:'',
	label:'',
	labelClassName:'',
	id:randomId,
	disabled:false,
	fullWidth:true,
	showLargeLabels:false,
	wrapperClassName:'',
	className:''
};

export default SelectFormControl;