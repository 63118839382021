import {
  CircularProgress,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip
} from "@material-ui/core/";
import { FilterList, Refresh } from "@material-ui/icons";
import clsx from "clsx";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./Alerts.style";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Settings } from "@material-ui/icons";
import DisconnectedResourcesCount from "./components/DisconnectedResourcesCount";

const intialAlertLogColumnPref = [
  { label: t`DATE/TIME`, columnId: "time", visible: true },
  { label: t`UNIT`, columnId: "unit", visible: true },
  { label: t`SITE`, columnId: "site", visible: true },
  { label: t`SYSTEM`, columnId: "system", visible: true },
  { label: t`DESCRIPTION`, columnId: "description", visible: true },
  { label: t`STATUS`, columnId: "status", visible: true },
  { label: t`CODE`, columnId: "code", visible: true },
  { label: t`TYPE`, columnId: "type", visible: true },
  { label: t`CATEGORY`, columnId: "category", visible: true },
  { label: t`PRIORITY`, columnId: "priority", visible: true }
];



const AlertsTable: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const { appliedFilters, page, rowsPerPage, rows, selectedAlert, setSelectedAlert, clearAllFilters, hideFilters = {}, refreshAlerts = () => { } } = props;
  const [clickedHead, setClickedHead] = useState("");
  const [lookupAnchor, setAnchor] = useState(null);
  const [alertsPerPage, setAlertsPerPage] = useState([]);
  const [preSelectedPage, setPreSelectedPage] = useState<number>(0);
  const { getUserPreferences, updateUserPreferences } = useStoreActions((action) => action.users);
  const userPreferences = useStoreState((state) => state.users.userPreferences);
  const tableHasFilters = !!appliedFilters["status"].length || !!appliedFilters["code"].length || !!appliedFilters["type"].length || !!appliedFilters["description"].length || !!appliedFilters["priority"].length || !!appliedFilters["category"]?.length;
  const [columnOrder, setColumnOrder] = useState<{
    label: string;
    columnId: string;
    visible: boolean;
  }[]>(intialAlertLogColumnPref);


  const [openSettingsMenu, setOpenSettingsMenu] = useState<boolean>(false);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    props.setPage(0);
    props.setRowsPerPage(event.target.value);
  };
  const updateClickedHead = (event: any, columnHead: string) => {
    setClickedHead(columnHead);
    setAnchor(event.currentTarget);
  };
  const onApply = (selectedFilters: any) => {
    const selectedFiltersObject = { [clickedHead]: selectedFilters };
    props.getSelectedFilters(selectedFiltersObject);
    setClickedHead("");
  }; // Get one page out of alerts array
  const _getAlertsPerPage = ({ page, rowsPerPage, rows }: any) => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  const types = useStoreState((state) => state.types);
  const { eventTypes, trapPriorityTypes } = types;

  useEffect(() => {
    // don't call the API if we alredy have the userPreferences
    if (isEmpty(userPreferences)) {
      getUserPreferences()
        .then((res: any) => {
          setColumnOrder(res.AlertLogColumnPref || intialAlertLogColumnPref);
        })
    }
    else {
      setColumnOrder(userPreferences?.AlertLogColumnPref || intialAlertLogColumnPref);
    }

    const alertsPerPage = _getAlertsPerPage({ page, rowsPerPage, rows });
    setPreSelectedPage(page);
    setAlertsPerPage(alertsPerPage);
    if (!selectedAlert || page !== preSelectedPage) {
      setSelectedAlert(alertsPerPage[0]);
    }
  }, [page, rowsPerPage, rows]);



  const handleColumnReorder = (result: any) => {
    if (!result.destination) return; // Return if the column was not dropped in a valid location
    const newOrder = Array.from(columnOrder);
    const [removed] = newOrder.splice(result.source.index, 1); // Remove the dragged column
    newOrder.splice(result.destination.index, 0, removed); // Insert the column in the new position
    setColumnOrder(newOrder);
    updateUserPreferences({ AlertLogColumnPref: newOrder });
  };

  const handleColumnDisplay = (event: any) => {
    const newOrder = Array.from(columnOrder);
    const index = newOrder.findIndex((element: any) => element.columnId === event.target.name)
    if (index > -1) newOrder[index].visible = event.target.checked;
    setColumnOrder(newOrder);
    updateUserPreferences({ AlertLogColumnPref: newOrder });
  };

  return (
    <div style={{ height: "100%" }}>
      {props.isDisabled ? (
        <div className={classes.progress}>
          <div className={classes.progressContainer}>
            <div className={clsx(classes.processingAnimationContainer)}>
              <CircularProgress />
            </div>
            <Typography variant="h5">{t`Loading Alerts`}</Typography>
          </div>
        </div>
      ) : (
        <div className={classes.tablePaper}>
          <div className={classes.tablePagesHeader}>
            <div className={classes.reloadButton}>
              <IconButton
                onClick={refreshAlerts}
                classes={{ root: classes.reloadIcon }}
              >
                <Refresh />
              </IconButton>
              <Typography className={classes.reloadLabel}>{t`Refresh Data`}</Typography>
            </div>
            <TablePagination
              backIconButtonProps={{ disableRipple: true, classes: { root: classes.iconBtnStyle } }}
              nextIconButtonProps={{ disableRipple: true, classes: { root: classes.iconBtnStyle } }}
              colSpan={7}
              component="div"
              count={rows.length}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: false
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes.pagingStyle}
            />
            {props.isDisabled && (
              <TablePagination
                colSpan={7}
                count={0}
                component="div"
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: false
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className={classes.pagingStyle}
              />
            )}
            <IconButton className={classes.IconButton}
              onClick={(event: any) => {
                setSettingsMenuAnchor(event.currentTarget);
                setOpenSettingsMenu(true);
              }}
            >
              <Settings />
            </IconButton>
          </div>
          <TableContainer className={classes.tableContainer}>
            <DragDropContext onDragEnd={handleColumnReorder}>
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <Droppable droppableId="columns" direction="horizontal">
                    {(provided: any) => (
                      <TableRow ref={provided.innerRef} {...provided.droppableProps}>
                        {columnOrder.map(({ label, columnId, visible }, index) => {
                          if (!visible) return null;
                          let cellData: any;
                          switch (columnId) {
                            case 'time':
                              cellData = <TableCell
                                className={clsx(classes.columnsTitles, classes.dateColumn)}
                                align="left"
                                style={{ minWidth: "132px", maxWidth: "132px", width: "132px" }}
                              >{t`DATE/TIME`}</TableCell>
                              break;
                            case 'unit':
                            case 'site':
                            case 'system':
                              cellData = <TableCell
                                className={classes.columnsTitles}
                                align="left"
                              >{label}</TableCell>;
                              break;
                            case 'description':
                            case 'status':
                            case 'code':
                            case 'type':
                            case 'category':
                            case 'priority':
                              cellData = <TableCell
                                className={classes.columnsTitles}
                                align="left"
                                onClick={(e: any) => {
                                  if (hideFilters[columnId]) {
                                    return;
                                  }
                                  updateClickedHead(e, columnId)
                                }}
                              >
                                <div className={classes.headContainer}>
                                  {label}
                                  {
                                    !hideFilters[columnId] &&
                                    <FilterList
                                      className={clsx(classes.filterStyle, {
                                        [classes.blueFilter]: !isEmpty(appliedFilters[columnId])
                                      })}
                                    />
                                  }
                                </div>
                              </TableCell>
                              break;
                            default:
                              cellData = null;
                          }
                          return <Draggable key={columnId} draggableId={columnId} index={index}>
                            {(provided: any) => (
                              <TableCell
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {cellData}
                              </TableCell>
                            )}
                          </Draggable>
                        })}
                        {provided.placeholder}
                      </TableRow>
                    )}
                  </Droppable>
                </TableHead>
                <TableBody>
                  {alertsPerPage.map((alert: any, index: number) => {
                    const { id, time, type, status, deviceName, unitName, siteName, systemName, description, errorDescription, errorCode, alertType, severity, priority, resources } = alert;
                    const unit = type === eventTypes.deviceDisconnected && status === "Closed" ? deviceName : unitName || "-"
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        onClick={() => props.onRowSelect(alert)}
                        onDoubleClick={() => props.getMoreInfo(alert)}
                        className={id === props.selectedAlert ? classes.selected : ""}
                      >
                        {columnOrder.map(({ columnId, visible }) => {
                          if (!visible) return null;
                          switch (columnId) {
                            case 'time':
                              return <TableCell className={clsx(classes.cellStyle, classes.showWhiteSpace)} align="left" >
                                {time}
                              </TableCell>;
                            case 'unit':
                              return (
                                <Tooltip title={unit}>
                                  <TableCell
                                    className={clsx(
                                      classes.cellStyle,
                                      classes.limitWidth
                                    )}
                                    component="th"
                                    scope="row"
                                    align="left"
                                  >
                                    {unit} <DisconnectedResourcesCount type={type} resources={resources} />
                                  </TableCell>
                                </Tooltip>
                              );

                            case 'site':
                              return <TableCell
                                className={clsx(classes.cellStyle, classes.limitWidth)}
                                align="left"
                              >
                                {siteName || "-"}
                              </TableCell>
                            case 'system':
                              return (
                                <Tooltip title={systemName}>
                                  <TableCell
                                    className={clsx(classes.cellStyle, classes.limitWidth)}
                                    align="left"
                                  >
                                    {systemName || "-"}
                                  </TableCell>
                                </Tooltip>
                              )
                            case 'description':
                              return (
                                <Tooltip title={errorDescription || description}>
                                  <TableCell className={classes.cellStyle} align="left"
                                    style={{ minWidth: "25ch", maxWidth: "25ch", width: "100%" }}>
                                    {errorDescription || description}
                                  </TableCell>
                                </Tooltip>
                              )
                            case 'status':
                              return <TableCell className={classes.cellStyle} align="left">
                                {status}
                              </TableCell>;
                            case 'code':
                              return <TableCell className={classes.cellStyle} align="left">
                                <LightTooltip
                                  title={errorDescription}
                                >
                                  <Typography>
                                    {errorCode || ""}</Typography>
                                </LightTooltip>
                              </TableCell>
                            case 'type':
                              return <TableCell className={classes.cellStyle} align="left">
                                {alertType}
                              </TableCell>;
                            case 'category':
                              return <TableCell className={classes.cellStyle} align="left">
                                {severity.name}
                              </TableCell>;
                            case 'priority':
                              return <TableCell
                                className={classes.cellStyle}
                                align="left"
                              >
                                {trapPriorityTypes[priority] || "-"}
                              </TableCell>
                            default:
                              return null;
                          }
                        })}
                      </TableRow>
                    );
                  }
                  )}
                </TableBody>
              </Table>
            </DragDropContext>
          </TableContainer>
          <Popover
            id={"popup-menu"}
            open={openSettingsMenu}
            style={{ overflow: "unset" }}
            anchorEl={settingsMenuAnchor}
            onClose={() => { setOpenSettingsMenu(false); setSettingsMenuAnchor(null) }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <Typography className={classes.title}>{t`Show/Hide columns`}</Typography>
            <FormGroup className={classes.listContainer} >
              {columnOrder.map(({ label, columnId, visible }, index) => {
                return <FormControlLabel
                  key={index}
                  control={<Checkbox checked={visible} onChange={handleColumnDisplay} name={columnId} />}
                  label={label}
                />
              })}
            </FormGroup>
          </Popover>
        </div>
      )}
      {clickedHead && (
        <Lookup
          filtersList={props.filterValues[clickedHead]}
          appliedFilters={props.appliedFilters[clickedHead]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedHead("")}
          tableHasFilters={tableHasFilters}
          clearAllFilters={clearAllFilters}
          extraWidth={clickedHead === 'description'}
        />
      )}
    </div>
  );
};
export default AlertsTable;
