import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './DynamicBattery.styles';

/**
 * @typedef Props
 * @property {number} percentage - the percentage in the icon
 * @property {string} [color] - The main color of the icon
 * @property {number} [width] - The width of the battery 
 * @property {number} [height] - the height of the icon
 */

/**
 * A component that renders an iPhone-like battery icon with percentage at the bottom.
 * @param {Props} props 
 * @returns {import('react').ReactComponentElement}
 */
const DynamicBattery = ({percentage,color,width,height}) => {

	const classes = useStyles();

	const fillerStyle = {
		backgroundColor:color,
		width:`${percentage}%`
	};

	const batteryWrapperStyle = {
		height,
	};

	const batteryStyle = {
		width
	};

	return (
		<div className={classes.iconWrapper}>
			<div className={classes.batteryWrapper} style={batteryWrapperStyle}>
				<div className={classes.battery} style={batteryStyle}>
					<div className={classes.batteryFiller} style={fillerStyle}></div>
				</div>
				<div className={classes.extraTip}></div>
			</div>
		</div>
	);
};

DynamicBattery.propTypes = {
	percentage:PropTypes.number.isRequired,
	color:PropTypes.string,
	height:PropTypes.number,
	width:PropTypes.number
};

DynamicBattery.defaultProps = {
	color:'#fff',
	height:30,
	width:50
};

export default DynamicBattery;