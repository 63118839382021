import React from 'react';

const SvgEdit = props => (
	<svg
		width="19px"
		height="20px"
		viewBox="0 0 19 20"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<defs>
			<polygon id="path-1" points="0 0.995 18.05437 0.995 18.05437 20 0 20" />
			<polygon
				id="path-3"
				points="0.09595 0.18725 11.41615 0.18725 11.41615 12.1035 0.09595 12.1035"
			/>
		</defs>
		<g id="App03" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
			<g
				id="Dashboard-UI-Elements"
				transform="translate(-209.000000, -770.000000)"
			>
				<g id="Icon/edit" transform="translate(209.000000, 770.000000)">
					<g id="Group-7" transform="translate(0.000000, -1.000000)">
						<g id="Group-3" transform="translate(0.000000, 0.813200)">
							<mask id="mask-2" fill="white">
								<use xlinkHref="#path-1" />
							</mask>
							<g id="Clip-2" />
							<path
								d="M14.77877,20 L3.27522,20 C1.46927,20 -0.00038,18.453 -0.00038,16.552 L-0.00038,4.443 C-0.00038,2.542 1.46927,0.995 3.27522,0.995 L12.38097,0.995 C12.81132,0.995 13.15902,1.362 13.15902,1.814 C13.15902,2.267 12.81132,2.634 12.38097,2.634 L3.27522,2.634 C2.32807,2.634 1.55667,3.445 1.55667,4.443 L1.55667,16.552 C1.55667,17.55 2.32807,18.361 3.27522,18.361 L14.77877,18.361 C15.72687,18.361 16.49732,17.55 16.49732,16.552 L16.49732,7.256 C16.49732,6.804 16.84597,6.437 17.27537,6.437 C17.70572,6.437 18.05437,6.804 18.05437,7.256 L18.05437,16.552 C18.05437,18.453 16.58472,20 14.77877,20"
								id="Fill-1"
								fill="#AAA2AA"
								mask="url(#mask-2)"
							/>
						</g>
						<g id="Group-6" transform="translate(7.600000, 0.813200)">
							<mask id="mask-4" fill="white">
								<use xlinkHref="#path-3" />
							</mask>
							<g id="Clip-5" />
							<path
								d="M1.54185,12.1035 L0.1539,12.0415 L0.09595,10.5815 L9.67005,0.5015 C10.07,0.0825 10.7179,0.0825 11.1169,0.5015 C11.51685,0.9225 11.51685,1.6045 11.1169,2.0245 L1.54185,12.1035 Z"
								id="Fill-4"
								fill="#AAA2AA"
								mask="url(#mask-4)"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default SvgEdit;
