import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { Site as sdkSite } from "coolremote-sdk";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import FloorPlanItem from "../../cool_widgets/FloorPlanItem/FloorPlanItem";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./Control.style";
import { GroupPanel, UnitPanel } from "./ControlComponents";
import { SensorPanel } from "./ControlComponents/SensorPanel";

const FloorPlansControl = ({ planId, siteId, fullSiteGroups, selectFloorPlan }: any) => {
  const classes = useStyles();
  const [plan, setPlan] = useState<any>(null);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [floorPlans, setFloorPlans] = useState<any>({});
  const [siteOffset, setSiteOffset] = useState<Number>(0);

  const types = useStoreState((state) => state.types);
  const {
    operationStatuses,
    fanModes,
    operationModesExtended,
    swingModes,
    unitSubTypes,
    unitTypes,
    sensorTypes,
    sensorMeasurementUnits
  } = types;
  const { getTemperatureScaleDisplay } = useStoreState((s) => s.users);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allSensors = useStoreState((s) => s.sensors.allSensors);
  const changeSitePower = useStoreActions((actions) => actions.sites.changeSitePower);
  const setActiveSetpoint = useStoreActions((actions) => actions.units.setActiveSetpoint);

  const setActiveFanMode = useStoreActions(
    (state) => state.units.setUnitActiveFanMode
  );
  const setActiveSwingMode = useStoreActions(
    (state) => state.units.setUnitActiveSwingMode
  );
  const setActiveOperationMode = useStoreActions(
    (state) => state.units.setActiveOperationMode
  );
  const setActiveOperationStatus = useStoreActions(
    (state) => state.units.changePowerState
  );
  const { addMessage } = useStoreActions(
    (action) => action.errorMessage
  );
  const serviceParams = useStoreState((s) => s.serviceParams);
  const allSites = useStoreState((s) => s.sites.allSites);

  const user = useStoreState((state) => state.users.me);

  const {
    fanModesMirror,
    operationStatusesMirror,
    operationModesMirror,
    swingModesMirror,
    temperatureScaleMirror
  } = useStoreState((state) => state);

  useEffect(() => {
    if (!siteId) {
      return;
    }
    sdkSite.getFloorPlans(siteId)
      .then((resp: any) => {
        const arr: any = Object.values(resp);
        const obj: any = {};
        for (let fp of arr) {
          obj[fp.id] = fp;
        }
        setFloorPlans(obj);
      });

    const site = allSites[siteId];
    const timezone = site?.timezone || "";
    const offset = moment().tz(timezone).utcOffset();
    setSiteOffset(offset);

  }, [siteId]);

  useEffect(() => {
    if (!planId) {
      setPlan(null);
      return;
    }
    sdkSite.getFloorPlanById(planId)
      .then((res: any) => setPlan(res));
  }, [planId]);

  const floorPlanItemClick = (isDisconnected: boolean, unit: any) => {
    if (unit?.type === "link") {
      selectFloorPlan(unit.unitId);
      return;
    }
    setSelectedUnit(isDisconnected ? null : unit);
  };

  const { temperatureScale = 1 } = user;
  const isCelsius = +temperatureScaleMirror?.celsius === +temperatureScale;
  const { stretch = false } = plan || {};
  const isUnitSelected = selectedUnit && !!allUnits[selectedUnit?.unitId];
  const isSensorSelected = selectedUnit && !!allSensors[selectedUnit?.unitId];

  return (
    <>
      {!planId ?
        <FilterRequire type={t`Floor Plan`} /> :
        <div className={classes.floorPlanPage}>
          <div
            className={classes.floorPlansContainer}
          >

            <div style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              height: `99%`,
              width: `calc(calc(100vh - 105px) * 1.4)`,
              border: "1px solid #c8c8c8"
            }}>
              <div
                id={"floorImgCont"}
                style={{
                  backgroundImage: `url('${plan?.url}')`,
                  backgroundRepeat: "no-repeat",
                  position: "relative",
                  backgroundSize: stretch ? "100% 100%" : "contain",
                  backgroundPosition: "50%",
                  width: stretch ? "100%" : "unset",
                  height: stretch ? "100%" : "unset",
                  cursor: 'pointer'//!!newUnit && newUnit.reposition && !newUnit.isMove ? "grab" : (!!newUnit && newUnit.reposition && newUnit.isMove ? "grabbing" : " default")
                }}>
                <img src={plan?.url} style={{ visibility: "hidden", maxWidth: "100%", maxHeight: "100%" }} />


                {
                  plan?.connectedUnits?.map((u: any, i: any) => (
                    <FloorPlanItem
                      key={`unit--${i}`}
                      unitId={u.unitId}
                      x={u.x}
                      y={u.y}
                      width={u.width}
                      height={u.height}
                      onClick={(e: any, isDisconnected: boolean) => floorPlanItemClick(isDisconnected, u)}
                      isSelected={u?.unitId === selectedUnit?.unitId}
                      floorPlans={floorPlans}
                    />
                  ))
                }


              </div>

            </div>

            {/* <div
              style={{
                backgroundImage: `url('${plan?.url}')`,
                backgroundRepeat: "no-repeat",
                position: "relative",
                backgroundSize: stretch ? "100% 100%" : "contain",
                backgroundPosition: "50%",
                width: stretch ? "100%" : "inherit",
                height: stretch ? "100%" : "inherit",
                minWidth: "500px",
                minHeight: "300px",
                margin: "auto"
              }}>
              {!stretch && <img src={plan?.url} style={{ visibility: "hidden", maxWidth: "100%", height: "100%" }} />}
              {
                plan?.connectedUnits?.map((u: any, i: any) => (
                  <FloorPlanItem
                    key={`unit--${i}`}
                    unitId={u.unitId}
                    x={u.x}
                    y={u.y}
                    width={u.width}
                    height={u.height}
                    onClick={(e: any, isDisconnected: boolean) => floorPlanItemClick(isDisconnected, u)}
                    isSelected={u?.unitId === selectedUnit?.unitId}
                    floorPlans={floorPlans}
                  />
                ))
              }
            </div> */}

          </div>
          <div className={clsx(classes.floorPlanControlContainer, !selectedUnit && classes.closedControl)}>
            {selectedUnit &&
              <IconButton
                className={classes.collapseBtn}
                onClick={() => setSelectedUnit(null)}
              >
                <Close style={{ fontSize: "30px", color: "#fff" }} />
              </IconButton>}
            {selectedUnit &&
              (isUnitSelected ?
                <UnitPanel
                  tempSymbol={getTemperatureScaleDisplay()}
                  siteTemp={{ C: null, F: null }}
                  types={{
                    operationStatuses,
                    fanModes,
                    operationModesExtended,
                    swingModes,
                    unitSubTypes,
                    unitTypes
                  }}
                  unit={allUnits[selectedUnit.unitId]}
                  setActiveSetpoint={setActiveSetpoint}
                  setActiveOperationMode={setActiveOperationMode}
                  setActiveFanMode={setActiveFanMode}
                  setActiveSwingMode={setActiveSwingMode}
                  setActiveOperationStatus={setActiveOperationStatus}
                  operationModesMirror={operationModesMirror}
                  fanModesMirror={fanModesMirror}
                  swingModesMirror={swingModesMirror}
                  operationStatusesMirror={operationStatusesMirror}
                  temperatureScale={temperatureScale}
                  isCelsius={isCelsius}
                  navigateToSchedules={() => { }}
                  addMessage={addMessage}
                  enableAirQuality={allSites[siteId]?.enableAirQuality}
                  airQualityParams={allSites[siteId]?.airQualityParams}
                  serviceParams={serviceParams}
                /> :
                (isSensorSelected ?
                  <SensorPanel
                    timezoneOffset={siteOffset}
                    key={selectedUnit.unitId}
                    tempSymbol={getTemperatureScaleDisplay()}
                    siteTemp={{ C: null, F: null }}
                    types={{ unitSubTypes, sensorTypes, sensorMeasurementUnits }}
                    sensor={allSensors[selectedUnit.unitId]}
                    isCelsius={isCelsius}
                    navigateToSchedules={() => { }}
                  /> :
                  <GroupPanel
                    group={fullSiteGroups[selectedUnit.unitId]}
                    key={selectedUnit.unitId}
                    user={user}
                    types={types}
                    navigateToSchedules={() => { }}
                    tempSymbol={getTemperatureScaleDisplay()}
                    temperatureScale={temperatureScale}
                    operationStatusesMirror={operationStatusesMirror}
                    isCelsius={isCelsius}
                    addMessage={addMessage}
                    siteTemp={{ C: null, F: null }}
                    siteId={siteId}
                    changeSitePower={changeSitePower}
                    canChangeOperationStatus={true}
                    hideSchedules={true}
                  />)
              )}

          </div>
        </div>}
    </>
  );
};

export default FloorPlansControl;
