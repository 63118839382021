import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "space-between"
    },
    dateStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "6px",
      margin: "4px",
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      height: "41px",
      fontSize: "14px",
      width: "165px",
      padding: "0 10px"
    },
    notWhiteDate: {
      backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color
    },
    textStyle: {
      fontSize: "16px",
      letterSpacing: "-0.39px",
      fontWeight: 300
    },
    purpleBg: {
      backgroundColor: "#4b1c46"
    }
  });
