import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: 'fit-content',
    maxWidth: '95% !important',
    minWidth: '600px',
    height: 'fit-content',
    maxHeight: 'calc(100vh - 140px)',
    minHeight: '300px',
    borderRadius: '8px',
    boxShadow: '0 2px 11px 0 rgba(0, 0, 0, 0.15)',
    border: 'solid 1px #d5d2d5',
    backgroundColor: '#ffffff',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  dialogContent: {
    padding: '15px 20px 0px 20px',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: 1,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    width: '100%',
  },
  actionsContainer: {
    borderTop: '1px solid #d5d2d5',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '20px 10px',
    width: '100%',
    bottom: 0,
    left: 0,
    position: 'sticky',
    zIndex: 3,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
    marginLeft: 10,
  },
  label: {
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'left',
    paddingLeft: '10px',
  },
  inputRoot: {
    padding: '0 !important',
    paddingLeft: '1px !important',
    height: '40px',
    width: '175px',
    margin: '0 8px 0 8px',
  },
  selectRoot: {
    width: '175px',
  },
}));

export default useStyles;
