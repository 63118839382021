import { Button, Card, IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { Note, Settings as SvgSettings } from "../../icons/";
import { IDevice } from "../../models/Devices";
import { useStoreState } from "../../models/RootStore";
import { ISite } from "../../models/Sites";
import { ISystem } from "../../models/Systems";
import { IUnit } from "../../models/Units";
import { BrandNamesIDsMap as brandNumbers } from "@utils/BrandUtils";
import Tooltip from "../../components/Tooltip/LightTooltip";

import useStyles from "./UnitInfo.style";

interface IProps {
  unit: IUnit;
  site: ISite;
  device: IDevice;
  system: ISystem | null | undefined;
  setNotesAnch: any;
  selectedSysId: string | null;
  unitNotes: any;
  setOpenAlthermaSettings: (open: boolean) => void;
  brandNum: number;
}

//key is brand number, values is min req. version
const unitSettingsOptions: any = {
  // [brandNumbers.SAMSUNG]: 9, // Temporary hidden for SAMSUNG
  [brandNumbers.ALTHERMA]: 5,
  [brandNumbers.WELLEA]: 5,
  [brandNumbers.HITACHI]: 9

};

export default function UnitInfo(props: IProps) {
  const classes = useStyles();
  const {
    setNotesAnch,
    selectedSysId,
    setOpenAlthermaSettings,
    unitNotes = {},
    brandNum = -1,
    unit
  } = props;
  const {
    id,
    serialNumber,
    privateId,
    capacity,
    capacityMeasurementUnits,
    airnet,
    model,
    type,
    permissions
  } = unit;

  const { canReadUnitConfigParams } = permissions;

  const siteName = props.site.name;
  const hvacBrands = useStoreState((state) => state.types.hvacBrands);
  const brandName =
    props.system && !_.isNil(_.find(hvacBrands, { value: props.system.brandNum }))
      ? _.find(hvacBrands, { value: props.system.brandNum }).name
      : null;
  const brand = brandName ? brandName : "--";
  const capacityMeasurementUnitTypes = useStoreState(
    (state) => state.types.capacityMeasurementUnitTypes
  );
  const unitTypesMirrror = useStoreState((state) => state.unitTypesMirrror);
  const { protocolVersion: deviceVersion } = props.device;
  const getUnitName = useStoreState((s) => s.units.getUnitName);
  const unitName = getUnitName(id, true);
  const { samsungFieldSettingsEnabled = false } = useStoreState((s) => s.users.me);
  const isOutdoorUnit = unitTypesMirrror[type]?.toUpperCase() === 'OUTDOOR';
  const showSettingsButton = !isOutdoorUnit && (brandNum === brandNumbers.SAMSUNG ? samsungFieldSettingsEnabled   : Boolean(unitSettingsOptions[brandNum]));

  return (
    <Card className={classes.card}>
      <div className={classes.unitInfoHeader}>
        <Typography className={classes.unitInfoTitle}>{`${unitTypesMirrror[type]?.toUpperCase() === "SERVICE" ? "Indoor" : (unitTypesMirrror[type]?.toUpperCase() === "BSBOX" ? "Bs Box" : unitTypesMirrror[type])}` + t` Unit Information`}</Typography>
        <Tooltip title={t`Add a note for future use - notes are designated for better teamwork or personal documentation associated with the desired Unit/System.`}>
            <IconButton className={classes.attButton} onClick={(e: any) => setNotesAnch(e.currentTarget)}>
              <Note />
              {!!selectedSysId && !!unitNotes && !!Object.values(unitNotes).length && <span className={classes.badge}>{Object.values(unitNotes).length}</span>}
            </IconButton>      
        </Tooltip>
        {!props.device.isConnected &&
          <Typography className={classes.unitInfoDisconnectedText}>{t`- Unit device is disconnected`}</Typography>
        }
      </div>
      <div className={classes.itemsWrapper}>
        <div className={clsx(classes.item, classes.firstItem)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Unit Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{unitName}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ minWidth: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Unit SN`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ minWidth: "12ch" }}>{serialNumber}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Brand`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "17ch" }}>{brand}</Typography>
        </div>
        <div className={classes.item} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Site Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{siteName}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`ID`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "7ch" }}> {privateId}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ minWidth: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Model`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ minWidth: "12ch" }}> {model}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "11%" }}>
          <Typography className={classes.infoTitle}>{t`Capacity`}</Typography>
          {capacity !== 0 && (
            <div>
              <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>
                {`${capacity} ` || ""}
                {capacityMeasurementUnits &&
                  _.invert(capacityMeasurementUnitTypes)[capacityMeasurementUnits]}
              </Typography>
            </div>
          )}
        </div>
        {showSettingsButton &&
          <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ borderLeft: "none" }}>
            <Tooltip title={deviceVersion < unitSettingsOptions[brandNum] ? t`the device version does not support this feature` : ""} arrow>
              <Button
                className={classes.althermaSettings}
                onClick={() => setOpenAlthermaSettings(true)}
                disabled={(deviceVersion < unitSettingsOptions[brandNum]) || !canReadUnitConfigParams}
              >
                <div className={classes.settingsIcon}>
                  <SvgSettings />
                </div>
                {t`Settings`}
              </Button>
            </Tooltip>
          </div>
        }
        {airnet && airnet !== 0 ? (
          <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "11%" }}>
            <Typography className={classes.infoTitle}>{t`Airnet: `}</Typography>
            <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>{airnet}</Typography>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Card>
  );
}
