import React from "react";
import { getHostname } from "../services/utils";

const Svg30 = (props: any) => (
    <img
        width={"35"}
        src={`${window.location.origin}/assets/${getHostname()}/LogoMobile.png`}
        onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${window.location.origin}/assets/localhost/LogoMobile.png`;
        }}

    />
);

export default Svg30;
