import { AppBar, Box, Container, Grid, IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import urlFlagMap from "../../constants/urlToFlagMapping";
import { ArrowDown } from "../../icons";
import { Close } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuIcon } from "../../svgComponents";
import MobileUserMenu from "../MobileUserMenu/MobileUserMenu";
import useStyles from "./TopBar.style";
import TopSelectionsMenu from "./TopFiltersMenu";

export default function ErrorBox(props: any) {
  const {
    title,
    leftAction,
    hideRightComponent = false,
    rightIconComponent,
    leftIconComponent,
    addRightPadding,
    hideUnitsFilter,
    rightAction,
    screenTitle,
    applySiteTypeFiltering = false,
    hideBsBox = false,
    ...restProps
  } = props;

  const classes = useStyles();
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const getUnitName = useStoreState((s) => s.units.getUnitName);
  const allSites = useStoreState((s) => s.sites.allSites);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const [openTopFilters, setOpenTopFilters] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<any>(false);
  const selectedUnitName = getUnitName(selections.unitId, true);
  const selectedCustomerName = allCustomers[selections?.customerId || ""]?.name;
  const selectedSiteName = allSites[selections?.siteId || ""]?.name || t`All Sites`;
  const updateSelections = useStoreActions((a) => a.selections.updateMobileSelections);
  const user = useStoreState((state) => state.users.me);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const getUnit = useStoreState((s) => s.units.getUnit);
  const { unitTypes } = useStoreState((s) => s.types);
  useEffect(() => {
    if (_.isEmpty(allCustomers) || selections.customerId) {
      return;
    }

    const customerId = Object.keys(allCustomers)[0];
    updateSelections({ customerId, siteId: null, unitId: null });

  }, [allCustomers]);

  useEffect(() => {
    const { siteId, unitId, customerId } = selections;
    let unitWasCleared = false;
    if (siteId) {
      if (siteId === "groups") {
        updateSelections({ customerId, siteId: null, unitId: null });
        return;
      }
      const pageFlag = urlFlagMap[screenTitle];

      if (pageFlag) {
        if (sitesFlags[siteId] && !sitesFlags[siteId][pageFlag]) {
          updateSelections({ customerId, siteId: null, unitId: null });
          unitWasCleared = true;
          return;
        }
      }
    }

    if (unitId && !unitWasCleared) {
      const unit = getUnit(unitId);
      const { isServiceSite } = sitesFlags[siteId];

      if (!unit?.isVisible) {
        updateSelections({ customerId, siteId, unitId: null });
        return;
      }

      if (applySiteTypeFiltering) {
        if ((isServiceSite && unit?.type === unitTypes?.indoor) || (!isServiceSite && unit?.type !== unitTypes?.indoor)) {
          updateSelections({ customerId, siteId, unitId: null });
        }
      }
      else if (unit?.type !== unitTypes?.indoor) {
        updateSelections({ customerId, siteId, unitId: null });
      }
      if (unit?.type === 2 || (unit?.type === 3 && restProps.hideIndoor) || (unit?.type === 4 && hideBsBox) || (unit?.type === 5 && restProps.hideOther)) {
        updateSelections({ customerId, siteId, unitId: null });
        return;
      }
    }
  }, []);

  const closeFilters = () => {
    setOpenTopFilters(false);
  };

  return (
    <>
      <AppBar position="static" className={clsx(classes.header, { [classes.iosPadding]: isIOS })}>
        <Container>
          <div className={classes.headerRow}>
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={leftAction}
              className={classes.leftActionStyle}
            >
              {leftIconComponent}
            </IconButton>

            {
              title ? <Typography className={classes.titleText}>{title}</Typography> :
                <Box className={classes.titleWrapper} onClick={() => setOpenTopFilters(!openTopFilters)}>
                  <Typography className={classes.titleStyle}>
                    {selectedCustomerName} <ArrowDown color={"#f4f4f6"} style={{ height: 8, width: 12 }} />
                  </Typography>
                  <Typography
                    className={classes.siteUnitName}
                  >{selectedSiteName}{selectedUnitName === "-" || hideUnitsFilter ? "" : ", " + selectedUnitName}</Typography>
                </Box>
            }

            <div className={classes.rightActionStyle}>
              {!hideRightComponent && <div style={{ display: 'flex' }}>
                {(rightIconComponent || openTopFilters) ?
                  <IconButton
                    edge="start"
                    aria-label="menu"
                    className={clsx(classes.rightButtonStyle, {
                      [classes.addPadding]: addRightPadding
                    })}
                    onClick={openTopFilters ? closeFilters : rightAction}
                  >
                    {openTopFilters ? <Close /> : rightIconComponent}
                  </IconButton>
                  :
                  <IconButton disableRipple onClick={() => setOpenDrawer(true)} className={classes.menuIconBtn}>
                    <MenuIcon className={classes.menuIcon} />
                  </IconButton>
                }

              </div>}
            </div>
          </div>
        </Container>
        {openDrawer && <MobileUserMenu
          open={openDrawer}
          userInfo={user}
          close={() => setOpenDrawer(false)}
        />}
      </AppBar>
      {openTopFilters && <TopSelectionsMenu
        close={closeFilters}
        applySiteTypeFiltering={applySiteTypeFiltering}
        screenTitle={screenTitle}
        hideUnitsFilter={hideUnitsFilter}
        {...restProps}
      />}
    </>
  );
}
