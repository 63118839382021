import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import Delete from '@components/Delete/Delete';
import {t} from 'ttag';
import _ from 'lodash';
import SvgUser from '@icons/User1';
import { useStoreState } from '@models/RootStore';


const getState = (state) => {
	//calculate state:
	const allSites = state.sites?.allSites ?? {};
	const selectedSiteId = state.selections.selections?.siteId ?? '';
	const selectedSite = allSites[selectedSiteId] ?? {};
	const {canUpdatePowerReportSchedules,} = selectedSite.permissions ?? {};
		
	return {
		canUpdatePowerReportSchedules
	};
	

};

//todo: refactor and clean code...
//todo: add jsdoc, comments.
//todo: remove lodash dependency.
/** This component renders the list of all users that will get the report */ 
const RecipientsList = ({selectedReportUsers,classes,searchVal,onUserDelete}) => {

	const {canUpdatePowerReportSchedules} = useStoreState(getState);

	const usersByFilter = !searchVal
		? selectedReportUsers
		: _.filter(selectedReportUsers, (oneUser) => {
			return _.startsWith(oneUser.userName.toUpperCase(), searchVal.toUpperCase());
			// return oneUser.userName.includes(searchVal);
		});

	if(!selectedReportUsers){
		return null;
	}

	return (
		<Grid container={true} className={classes.scheduledUserList}>
			<TableContainer className={classes.schedulerTableContainer}>
				<Paper elevation={0} className={classes.paperTableContainer}>
					<Table stickyHeader={true} className={classes.schedulerTable} aria-label="customized table">
						<TableHead>
							<TableRow className={classes.tableHeadRow}>
								<TableCell
									classes={{ root: classes.schedulerTableHeadRow }}
									align="left"
								>{t`RECIPIENTS`}</TableCell>

								<TableCell
									classes={{ root: classes.schedulerTableHeadRow }}
									align="right"
								>{t`DEL`}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{_.map(usersByFilter, (oneUser, i) => {
								return (
									<Tooltip
										title={oneUser.userEmail}
										key={i}
										enterDelay={500}>
										<TableRow role="checkbox" tabIndex={-1} key={i} className={classes.rowStyle}>
											<TableCell align={'left'} size="small" className={classes.cellStyle}>
												<div className={classes.userHolder}>
													<SvgUser className={classes.userIcon} />
													<Typography variant="body2" className={classes.userNameCell}>
														{oneUser.userName}
													</Typography>
												</div>
											</TableCell>
											<TableCell align={'left'} size="small">
												<Typography variant="body2" className={classes.delCell}>
													<Delete
														disabled={!canUpdatePowerReportSchedules}
														type={t`Reports Users`}
														object={{ name: oneUser.userName, id: oneUser.userEmail }}
														detach={onUserDelete}
														buttonClass={classes.deleteIcon}
													/>
												</Typography>
											</TableCell>
										</TableRow>
									</Tooltip>
								);
							})}
						</TableBody>
					</Table>
				</Paper>
			</TableContainer>
		</Grid>
	);
};

RecipientsList.propTypes = {
	classes:PropTypes.object.isRequired,
	onUserDelete:PropTypes.func.isRequired,
	selectedReportUsers:PropTypes.array,
	searchVal:PropTypes.string
};

RecipientsList.defaultProps = {
	selectedReportUsers:[],
	searchVal:''
};

export default RecipientsList;