import React from 'react';
import PropTypes from 'prop-types';

const LightLevelSensor = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.0162 1.45837V3.92324" stroke="white" strokeLinecap="round" />
		<path d="M3.46484 13.4746H0.999979" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M3.91609 4.99065L5.65901 6.73358" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M3.91609 21.3424L5.65901 19.5994" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M21.9513 13.4746H24.4162" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M21.5002 4.99065L19.7573 6.73358" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M21.5002 21.3424L19.7573 19.5994" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path fillRule="evenodd" clipRule="evenodd" d="M17.1283 7.8816C15.63 6.6295 13.6575 6.13126 11.7176 6.51423C9.10611 7.02971 7.00868 9.2651 6.61709 11.9504C6.29769 14.1417 7.06541 16.3301 8.67082 17.8046C9.39417 18.469 9.83057 19.4258 9.89988 20.4996C9.9012 20.5188 9.90495 20.5376 9.90915 20.5562V23.9226C9.90915 24.1077 10.0573 24.2583 10.2403 24.2583H15.7902C15.973 24.2583 16.1213 24.1077 16.1213 23.9226V20.5025C16.1213 20.5018 16.1215 20.5011 16.1215 20.5002C16.1215 19.5017 16.5688 18.537 17.3813 17.7845C18.7192 16.5449 19.4864 14.7818 19.4864 12.9475C19.4867 10.9805 18.6271 9.13393 17.1283 7.8816Z" stroke="white" />
		<path d="M9.68408 22.0567H16.3584" stroke="white" />
	</svg>
);

LightLevelSensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

LightLevelSensor.defaultProps = {
	width: 25,
	height: 26
};

export default LightLevelSensor;