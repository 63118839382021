import { Button, Card, CardContent, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { t } from "ttag";
import { TopBar } from "../../components";
import { Checkbox } from "../../components/Checkbox";
import CButton from "../../cool_widgets/Button";
import { ArrowBack, Delete as DeleteIcon, Search } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./GroupAddEdit.style";

const GroupAddEdit: React.FC<RouteComponentProps & any> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params: any = useParams();
  const [group, setGroup] = useState<any>({
    name: "",
    units: []
  });
  const [siteUnits, setSiteUnits] = useState<any>([]);
  const [searchVal, setSearchVal] = useState<string>("");
  const [showUnits, setShowUnits] = useState<string>("all");
  const [toDelete, setToDelete] = useState<boolean>(false);
  const [disableSaving, setDisableSaving] = useState<boolean>(true);

  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSites = useStoreState((state) => state.sites.allSites);
  const allCustomers = useStoreState((state) => state.customers.allCustomers);
  const createGroup = useStoreActions((action) => action.groups.createGroup);
  const updateGroup = useStoreActions((action) => action.groups.updateGroup);
  const deleteGroupById = useStoreActions((action) => action.groups.deleteGroup);
  const addMessage = useStoreActions((action) => action.errorMessage.addMessage);
  const getGroup = useStoreActions((action) => action.groups.getGroup);
  const { unitTypes } = useStoreState((state) => state.types);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const { customerId, siteId = "" } = useStoreState((s) => s.selections.mobileSelections);
  const updateSelections = useStoreActions((a) => a.selections.updateMobileSelectionsAll);

  const { canAddGroup = true } = allCustomers[customerId] || {};
  const { permissions = { canUpdate: true } } = group;
  const { canDelete, canUpdate } = permissions;
  const site = allSites[siteId];

  useEffect(() => {
    if (params.siteId && allSites[params.siteId]) {
      const exists = updateSelections({ siteId: params.siteId });
      !exists && history.push("/settings/groups");
    } else if (params.siteId) {
      history.push("/settings/groups");
    }
    if (!isEdit) {
      setDisableSaving(false);
    }
  }, []);

  useEffect(() => {
    if (!params.groupId) {
      return;
    }
    getGroup(params.groupId)
      .then((resp: any) => {
        setGroup({ name: resp.name, units: resp.units, sensors: resp.sensors });
      }).catch(() => goBack());

  }, []);

  useEffect(() => {
    if (_.isEmpty(allUnits)) {
      return;
    }

    const units = Object.values(allUnits).filter((unit: any) => {
      if (unit.type !== unitTypes.indoor || !unit.site || !unit.isVisible) {
        return false;
      }

      if (unit.site !== siteId || unit.subType === 1) {
        return false;
      }
      return true;
    });

    setSiteUnits(units);
  }, []);

  const goBack = () => {
    history.push("/settings/groups");
  };

  const saveGroup = () => {
    let method = null;
    const data: any = { ...group };
    if (params.groupId) {
      method = updateGroup({ id: params.groupId, data });
    }
    else {
      data.site = siteId;
      method = createGroup(data);
    }
    method
      .then(() => {
        goBack();
      });
  };

  const checkUnit = (unitId: any) => {
    const units = [...group.units];
    const index = units.indexOf(unitId);
    if (index > -1) {
      units.splice(index, 1);
    }
    else {
      units.push(unitId);
    }
    setGroup({ ...group, units });
    setDisableSaving(false);
  };

  const cancelDelete = () => {
    setToDelete(false);
  };

  const deleteGroup = () => {
    deleteGroupById(params?.groupId)
      .then(() => {
        goBack();
      })
      .catch((error: any) => {
        cancelDelete();
        addMessage({ message: error.message });

      });
  };

  const selectAllSite = (checked: boolean) => {
    if (checked) {
      const unitSet = siteUnits.map(({ id }: any) => id);
      setGroup({ ...group, units: unitSet });
    }
    else {
      setGroup({ ...group, units: [] });
    }
    setDisableSaving(false);
  };

  const isEdit = !!params?.groupId;
  const disabled = !canUpdate || (!canAddGroup && !isEdit);

  return (
    <>
      <TopBar
        hideUnitsFilter
        leftIconComponent={<ArrowBack />}
        leftAction={() => goBack()}
        rightAction={() => setToDelete(true)}
        rightIconComponent={<DeleteIcon />}
        hideRightComponent={!isEdit || !canDelete}
        title={isEdit ? t`Edit Group` : t`Add Group`}
      />
      <div className={classes.view}>
        <div className={classes.contentArea}>
          <div className={classes.nameContainer}>

            <TextField
              disabled={disabled}
              value={group.name}
              onChange={(e: any) => { setGroup({ ...group, name: e.target.value }); setDisableSaving(false); }}
              label={t`Group Name`}
              variant={"outlined"}
              className={classes.nameField}
            />
          </div>
          <div className={classes.contentContainer}>

            <div className={classes.containerHeader}>
              <Typography className={classes.containerTitle}>
                {t`Units`}
              </Typography>
              <ToggleButtonGroup
                className={classes.toggleButtons}
                value={showUnits}
                exclusive
                onChange={(e: any, val: any) => setShowUnits(val)}
              >
                <ToggleButton className={classes.tabContainer} value="all" aria-label="left aligned">
                  <Typography className={classes.tabText}>{t`All`}</Typography>
                </ToggleButton>
                <ToggleButton className={classes.tabContainer} value="selected" aria-label="centered">
                  <Typography className={classes.tabText} >{t`Selected`}</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <TextField
              value={searchVal}
              onChange={(e: any) => setSearchVal(e.target.value)}
              InputProps={{
                startAdornment: <Search />
              }}
              label={t`Search Units`}
              variant={"outlined"}
              className={classes.searchField}
            />
            <div className={classes.sitesCont}>
              {
                <Card key={`site-${siteId}`} className={classes.siteCont}>
                  <div className={clsx(classes.siteHeader, classes.expandedHeader)}>
                    <div className={classes.siteNameContainer}>
                      <Typography className={classes.siteName}>
                        {site.name}
                      </Typography>
                      <Typography className={classes.siteName}>
                        {siteUnits.filter((unit: any) => group.units.indexOf(unit.id) > -1).length + "/" + siteUnits.length}
                      </Typography>
                    </div>
                  </div>

                  <Collapse in={true} timeout="auto" unmountOnExit style={{ width: "100%", overflowY: "auto" }}>
                    <CardContent className={classes.unitsContainer}>
                      {siteUnits?.length > 1 &&
                        <div
                          key={`all-${siteId}`}
                          className={classes.unitRow}
                          style={{
                            justifyContent: "space-around",
                            textDecoration: "underline"
                          }}
                        >
                          <Typography onClick={(e: any) => disabled ? {} : selectAllSite(true)}>
                            {t`Select All`}
                          </Typography>
                          <Typography onClick={(e: any) => disabled ? {} : selectAllSite(false)}>
                            {t`Unselect All`}
                          </Typography>
                        </div>
                      }
                      {
                        siteUnits.map((unit: any) => {
                          const isChecked = group.units.indexOf(unit.id) > -1;
                          const showSearch = !searchVal?.length || (searchVal?.length && unit.name.toLowerCase()?.indexOf(searchVal.toLowerCase()) > -1);
                          if (!showSearch) {
                            return null;
                          }

                          const showFilter = showUnits === "all" || (showUnits === "selected" && isChecked);

                          if (!showFilter) {
                            return;
                          }

                          return (
                            <div
                              key={`unit-${unit.id}`}
                              className={classes.unitRow}
                              onClick={(e: any) => disabled ? {} : checkUnit(unit.id)}
                            >
                              <Checkbox
                                checked={isChecked}
                                className={classes.checkbox}
                                onChange={(e: any) => {
                                  return;
                                }}
                              />
                              <Typography className={classes.unitName}>
                                {unit.name}
                              </Typography>
                            </div>
                          );
                        })
                      }
                    </CardContent>
                  </Collapse>

                </Card>
              }
            </div>
            <CButton
              className={classes.saveBtn}
              color={"primary"}
              variant="contained"
              onClick={saveGroup}
              disabled={disableSaving || disabled}
            >
              {t`Save`}
            </CButton>
          </div>

        </div>

        <Dialog
          open={toDelete}
          onClose={cancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Group?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t`Are you sure you would like to delete this group?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteGroup} variant="contained">{t`Delete`}</Button>
            <Button onClick={cancelDelete} autoFocus color={"primary"} variant="contained">
              {t`Cancel`}
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    </>
  );
};

export default GroupAddEdit;
