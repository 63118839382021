import React, { useState } from "react";

import {
  Checkbox,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { t } from "ttag";
import { saveAs } from "file-saver";
import { keyBy, pick, omit } from "lodash";
import { Close } from "../../icons";
import Button from "../../cool_widgets/Button";
import { IProcedure, IMixedStep } from '../../hooks/useScripts/types';

import useStyles from "./ExportProceduresDialog.style";

interface IProps {
  procedures: IProcedure[];
  onClose: () => void;
  getBrandName: (brand: number | string) => string;
}

const removeUnitsAndSystems = (steps: IMixedStep[]): IMixedStep[] => {
  return steps.map((step: IMixedStep) => {
    const newStep = omit(step, ['units', 'systems']);

    newStep.steps = newStep.steps ? removeUnitsAndSystems(newStep.steps) : undefined;

    return newStep;
  });
};

const ExportProcedures = ({ procedures, onClose, getBrandName }: IProps) => {
  const classes = useStyles();

  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());

  const handleCheckboxChange = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    const selected = new Set(selectedIds);
    if (checked) {
      selected.add(id);
    } else {
      selected.delete(id);
    }

    setSelectedIds(selected);
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedIds(new Set(procedures.map((procedure: IProcedure) => procedure.id)));
    } else {
      setSelectedIds(new Set());
    }
  };

  const exportSelected = () => {
    const proceduresMap = keyBy(procedures, 'id');
    const selectedProcedures: any[] = [];

    Array.from(selectedIds).forEach((id: string) => {
      const procedure = proceduresMap[id];
      if (!procedure) return;

      const procedureCopy = pick(procedure, [
        'name', 'description', 'steps', 'userSelections',
        'runningMode', 'unitType', 'type', 'stepsRunningMode'
      ]);

      // remove units/systems applied to each procedure step.
      procedureCopy.steps = removeUnitsAndSystems(procedureCopy.steps);

      selectedProcedures.push(procedureCopy);
    });

    const dataStr = JSON.stringify(selectedProcedures, null, 2);
    const blob = new Blob([dataStr], { type: "application/json;charset=utf-8" });
    saveAs(blob, `export-procedure-${new Date()}.json`);
    onClose();
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Export Procedures`}</Typography>
        <IconButton disableRipple onClick={onClose} className={classes.iconBtnStyle}><Close color="#7f7692" /></IconButton>
      </div>
      <Paper elevation={0} className={classes.paperTableContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" hidden>
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableHeadCell }}
                  align="left"
                >
                  {t`Rule Name`}
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableHeadCell }}
                  align="left"
                >{t`Brand`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {procedures.length &&
                <TableRow
                  hover
                  tabIndex={-1}
                  role="checkbox"
                >
                  <TableCell padding="checkbox" hidden>
                    <Checkbox
                      color="default"
                      checked={selectedIds.size === procedures.length}
                      onChange={handleSelectAllChange}
                      inputProps={{ "aria-labelledby": `toggle all` }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                    align="left"
                  >
                    {t`All`}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                    align="left"
                  >
                    {""}
                  </TableCell>
                </TableRow>
              }
              {procedures.map((procedure: IProcedure) => {
                const { id = "", name, userSelections: { brand = -99 } = {} } = procedure;
                const brandName = getBrandName(brand) || "All";

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={id}
                    role="checkbox"
                  >
                    <TableCell padding="checkbox" hidden>
                      <Checkbox
                        color="default"
                        onChange={handleCheckboxChange(id)}
                        checked={selectedIds.has(id)}
                        inputProps={{ "aria-labelledby": name }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                      align="left"
                    >
                      {name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                      align="left"
                    >
                      {brandName}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.actionsContainer}>
        <Button onClick={onClose} marginRight white> {t`Cancel`}</Button>
        <Button onClick={exportSelected} disabled={!selectedIds.size}> {t`Export`}</Button>
      </div>
    </Dialog>
  );
};

export default ExportProcedures;
