import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      height: "100%"
    },
    pageContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      maxWidth: "768px"
    },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "10px 5px",
      margin: "20px 0",
      overflowY: "auto"
    },
    lineContainer: {
      border: theme.overrides.MuiButton.outlined.border,
      color: theme.palette.primary.dark,
      padding: "15px",
      margin: "10px 0",
      borderRadius: "4px"
    },
    collapseHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    normalText: {
      color: theme.palette.primary.dark,

      fontSize: "16px"
    },
    labelText: {
      color: theme.palette.primary.dark,

      fontSize: "13px",
      width: "25%"
    },
    unitContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "30px 0",
      padding: "10px",
      border: theme.overrides.MuiButton.outlined.border,
      borderRadius: "4px"
    },
    detailsRow: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "10px 0"
    },
    nextBtn: {
      minHeight: "53px",
      whdth: "95%"
    },
    iconStyle: {
      fontSize: "20px"
    },
    cancelIcon: {
      fill: "#D32F2F",
      marginLeft: "10px"
    },
    arrow: {
      color: "#7f7692"
    },
    openArrow: {
      transform: "rotate(90deg)"
    },
    selectStyle: {
      flexGrow: 1
    },
    menuItemsContainer: {
      maxHeight: "200px",
      transitionDuration: "0s !important"
    }
  });
