import { action, Action } from "easy-peasy";



export interface IMessagesModel {
  message: any;
  /**An object to show the reportProblem on connection error */
  globalErrorReport: any;
  setGlobalErrorReport: Action<IMessagesModel, any>;
  addMessage: Action<IMessagesModel, any>;
  hideMessage: Action<IMessagesModel, any>;
}

export const messageModel: IMessagesModel = {
  message: {
    message: ""
  },
  /**An object to show the reportProblem on connection error */
  globalErrorReport:{
    showGlobalReport:false,
    showOpenButton:false,
    messageDescription:'',
  },
  setGlobalErrorReport:action((state,payload)=>{
    state.globalErrorReport = payload
  }),

  addMessage: action((state, payload) => {
    state.message = { ...payload };
  }),
  hideMessage: action((state, cb) => {
    state.message.message = "";
    if (cb) {
      cb();
    }
  })
};
