import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
rowContainer: {
  height: 60,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: 492,
  borderBottom: "solid 1px #e1e2ea",
  paddingRight: 15
},
nameStyle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%",
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    padding: "0 15px"
},
grayCheckbox: {
  padding: 0,
  height: 16,
  width: 16,
  margin: 0

},
hideLine: {
  border: "none"
}
}));
export default useStyles;
