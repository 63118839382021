import React from "react";

const SvgEditUser = (props) => (
  <svg
    width={34}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.8417 26.3287L22.978 26.1827L22.8658 26.0175C21.4771 23.974 19.1597 22.608 16.5 22.608H13.5C10.8419 22.608 8.52288 23.974 7.13422 26.0175L7.02195 26.1827L7.15832 26.3287C9.12006 28.4286 11.9046 29.75 15 29.75C18.0954 29.75 20.8799 28.4286 22.8417 26.3287ZM0.25 19C0.25 11.0066 7.00657 4.25 15 4.25C22.9934 4.25 29.75 11.0066 29.75 19C29.75 26.9934 22.9934 33.75 15 33.75C7.00657 33.75 0.25 26.9934 0.25 19ZM19.75 16C19.75 13.2699 17.7285 11.25 15 11.25C12.273 11.25 10.25 13.2698 10.25 16C10.25 18.7302 12.273 20.75 15 20.75C17.7285 20.75 19.75 18.7301 19.75 16Z"
      fill="#E4E4E6"
      stroke="#CECECE"
      strokeWidth="0.5"
    />
    <circle cx="26.5" cy="7.5" r="7.5" fill="#4B1C46" />
    <path
      d="M29.1849 6.55069L27.4319 4.81581L28.0094 4.23751C28.1675 4.07917 28.3618 4 28.5922 4C28.8224 4 29.0165 4.07917 29.1746 4.23751L29.7521 4.81581C29.9102 4.97415 29.9927 5.16526 29.9996 5.38914C30.0064 5.61275 29.9308 5.80372 29.7727 5.96207L29.1849 6.55069ZM28.5868 7.15996L24.753 11H23V9.24447L26.8338 5.40443L28.5868 7.15996Z"
      fill="white"
    />
  </svg>
);

export default SvgEditUser;
