import { TableContainer, TableFooter, TablePagination } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Alert as SvgAlert } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISystem } from "../../models/Systems";
import assetsService from "../../services/assetsService";
import { systemTableStyle } from "./SystemTable.style";
import { truncateText } from "@components/TruncatedText";

interface IProps {
  onSystemDoubleClick: (systemId: string) => void;
  classes?: any;
  counts?: { indoors?: number, outdoors?: number, systems?: number, allUnits?: number, sites?: number };
  setCounts: (counts: { indoors?: number, outdoors?: number, systems?: number, allUnits?: number, sites?: number }) => void;
  isSelectedSite?: boolean;
}
const SystemTable: React.FC<IProps> = (props: IProps) => {

  const columns = [
    { id: "name", label: t`SYSTEM`, lessWidth: true },
    { id: "hasAlerts", label: t`ALERTS`, removePadding: true },
    { id: "brand", label: t`BRAND`, minWidth: true },
    { id: "type", label: t`TYPE` },
    { id: "allUnits", label: t`ODU/IDU` },
    { id: "capacity", label: t`CAPACITY` }
  ];
  const columnsSites = [
    { id: "siteName", label: t`SITE` },
    { id: "systems", label: t`SYSTEMS` },
    { id: "outdoor", label: t`ODU` },
    { id: "indoor", label: t`IDU` }
  ];

  const [rows, setRows] = useState<any>([]);

  const { classes, counts, setCounts, isSelectedSite } = props,
    { alertIcon, tableWrapper, tableCell, tableRow, brandLogo } = classes,
    selectedSystems = useStoreState((s) => s.selections.getSystemsBySelection),
    allSites = useStoreState((state) => state.sites.allSites);
  const getAlertsByFilter = useStoreState((state) => state.alerts.getAlertsByFilter);
  const setSelections = useStoreActions((s) => s.selections.setSelections);
  const capacityMeasurementUnitTypes = _.invertBy(useStoreState((state) => state.types.capacityMeasurementUnitTypes));
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const allSystemCustomized = useStoreState((state) => state.systems.allSystemCustomized);

  useEffect(() => {
    const tempRows: any = [];
    let tempIndoor = 0;
    let tempOutdoor = 0;
    selectedSystems.forEach((system: ISystem) => {
      const { id } = system,
        outdoor = allSystemCustomized[id]?.ODU || 0,
        indoor = allSystemCustomized[id]?.IDU || 0,
        name = system.name ? system.name : "",
        brand = system.brandNum ? system.brandNum : 999,
        type = system.type ? system.type : "",
        series = system.series ? system.series : "",
        siteId = system.site,
        siteName = allSites[siteId]?.name;
      const systemAlerts = getAlertsByFilter({ systemId: system.id });
      const hasAlerts = systemAlerts.some((alert: any) => alert?.severity?.id !== "MAINTENANCE");

      const capacityMeasurementUnits = (system.capacity && system.capacityMeasurementUnits) ? capacityMeasurementUnitTypes[system.capacityMeasurementUnits] : "",
        capacity = system.capacity ? system.capacity : "";
      tempRows.push({ id, name, siteName, siteId, type, indoor, outdoor, brand, series, hasAlerts, capacityMeasurementUnits, capacity });
      tempIndoor += indoor || 0;
      tempOutdoor += outdoor || 0;
    });
    setRows(tempRows);
    setCounts({ ...counts, outdoors: tempOutdoor, indoors: tempIndoor, systems: selectedSystems?.length });
  }, [selectedSystems]);

  const getRows = () => {
    const tempRowsObj: any = {};
    rows.forEach((row: any) => {
      if (sitesFlags[row.siteId] && !sitesFlags[row.siteId]?.hasDashboard) {
        return;
      }
      if (!tempRowsObj[row.siteId])
        tempRowsObj[row.siteId] = {} as any;
      tempRowsObj[row.siteId].siteName = row.siteName;
      tempRowsObj[row.siteId].siteId = row.siteId;
      tempRowsObj[row.siteId].id = row.siteId;
      tempRowsObj[row.siteId].indoor = (tempRowsObj[row.siteId].indoor || 0) + row.indoor;
      tempRowsObj[row.siteId].outdoor = (tempRowsObj[row.siteId].outdoor || 0) + row.outdoor;
      tempRowsObj[row.siteId].systems = (tempRowsObj[row.siteId].systems || 0) + 1;
    });
    if (isSelectedSite)
      return rows;
    return Object.values(tempRowsObj);
  };

  const getColumns = () => isSelectedSite ? columns : columnsSites;
  const [page, setPage] = React.useState(0),
    [rowsPerPage, setRowsPerPage] = React.useState(10),
    handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
      setPage(newPage);
    },
    handleChangeRowsPerPage = (event: { target: { value: React.ReactText } }) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  columns.forEach((col) => {
    if (col.id === "allUnits")
      col.label = t`ODU` + `(${counts?.outdoors})` + ` / ` + t`IDU` + `(${counts?.indoors})`;
  });
  return (
    <div className={classes.pageContainer}>
      {false && rows.length > 10 /* rowsPerPage*/ && (
        <TableFooter className={classes.systemTableFooter}>
          <TableRow className={classes.removeBottomBorder}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              colSpan={7}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page"
              }}
              nextIconButtonProps={{
                "aria-label": "next page"
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      )}
      <TableContainer className={tableWrapper}>
        <Table stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              {getColumns().map((column: any, index) => (
                <TableCell
                  key={index}
                  align={column.id === "name" || "siteName" ? "left" : "center"}
                  classes={{ root: classes.tableHeadCell }}
                >
                  {column.id === "hasAlerts" ? "" : column.label}
                  {column.id === "name" ? ` (${rows.length})` : ""}
                  {column.id === "outdoor" ? ` (${counts?.outdoors})` : ""}
                  {column.id === "indoor" ? ` (${counts?.indoors})` : ""}
                  {column.id === "systems" ? ` (${counts?.systems || 0})` : ""}
                  {column.id === "siteName" ? ` (${counts?.sites || 0})` : ""}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getRows().map((row: any) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  onDoubleClick={() => {
                    if (!isSelectedSite)
                      setSelections({ siteId: row.siteId });
                    else
                      props.onSystemDoubleClick(row.id);
                  }}
                  className={tableRow}
                >
                  {getColumns().map((column: any, index) => {
                    const rowVal = row[column.id],
                      value =
                        column.id === "brand" ? (
                          <img
                            width={60}
                            height={25}
                            src={assetsService.getBrandLogo(`${rowVal}-grey.svg`)}
                            onError={(event: any) =>
                              (event.target.src = assetsService.getBrandLogo("empty.png"))
                            }
                            alt={rowVal}
                            className={brandLogo}
                          />
                        )
                          : column.id === "hasAlerts" && row.hasAlerts ? (<SvgAlert className={alertIcon} />)
                            : column.id === "allUnits" ? `${row.outdoor} / ${row.indoor}`
                              : column.id === "capacity" ? `${rowVal} ${row.capacityMeasurementUnits}`
                                : rowVal;

                    return (
                      <TableCell
                        size="small"
                        key={index}
                        className={clsx(tableCell, { [classes.minWidthStyle]: column.minWidth, [classes.lessWidth]: column.lessWidth, [classes.removePadding]: column.removePadding })}
                        align={column.id === "name" || column.id === "siteName" ? "left" : "center"}
                      >
                        {column.format && typeof value === "number" ? column.format(value) :
                          (column.minWidth && value && value.length > 30 ? value.substr(0, 30) + "\u2026"
                            : truncateText(value, 20))}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default withStyles(systemTableStyle)(SystemTable);
