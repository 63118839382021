import * as Yup from 'yup';
import {t} from 'ttag';

/**
 * Cleans a given input string by removing all non-digit characters.
 *
 * @param {string} input - The input string to be cleaned.
 * @returns {string} The cleaned string containing only digits.
 */
export const getCleanNumber = (input) => {
	return input.replace(/\D/g, '');
};


/**
 * A function to get a validation schema for a phone number based on Yup
 * @param {number} [phoneNumberMinLength] The minimum length required for the phone number (optional).
 * @return {RequiredStringSchema<string | undefined, AnyObject>} a Schema for validating a phone number  */
export const getPhoneValidation = (phoneNumberMinLength) => {

	const message = phoneNumberMinLength ? t`Phone number should be at least ${phoneNumberMinLength} digits` : t`Phone number is not valid`;

	return Yup.string()
		.test('phone-number', message, (value) => {
			//If there is no value - don't check it.
			if (!value) return true;
			if(value === '+') return true;

			const cleanedValue = getCleanNumber(value);

			if(phoneNumberMinLength && phoneNumberMinLength > cleanedValue.length){
				return false;
			}

			// Validate cleaned number
			const regex = /^[0-9]+$/;
			return regex.test(cleanedValue); // Return true if the cleaned value matches the regex, otherwise false
		});

};

