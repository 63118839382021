import React from "react";

const SvgScheduleOff = props => {
  const { color = "#FFF" } = props
  return (
    <svg width={40} height={40} {...props}>
      <g fill="none" fillRule="evenodd" opacity={0.4}>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeWidth={2.183}
          d="M34.952 20.024c0 8.245-6.683 14.929-14.927 14.929-8.245 0-14.928-6.684-14.928-14.929 0-8.243 6.683-14.927 14.928-14.927 8.244 0 14.927 6.684 14.927 14.927z"
        />
        <path
          fill={color}
          fillRule="nonzero"
          d="M20 10.909a1.09 1.09 0 0 1 1.085.972l.006.119v7.908l4.81 3.672c.446.34.557.954.278 1.424l-.072.105a1.09 1.09 0 0 1-1.424.277l-.105-.07-5.67-4.327V12c0-.603.49-1.091 1.092-1.091z"
        />
      </g>
    </svg>
  )
};

export default SvgScheduleOff;
