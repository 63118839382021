import React from 'react';
import PropTypes from 'prop-types';

const HumiditySensor = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M15.1414 7.79457C14.2167 6.68247 12.5696 5.03708 10.2 2.8584C5.00002 7.2584 2.40002 11.0584 2.40002 14.2584C2.40002 19.0584 5.8922 22.0584 10.2 22.0584C13.076 22.0584 15.5884 20.7213 16.9408 18.4041" stroke="white" strokeLinecap="round" />
		<path fillRule="evenodd" clipRule="evenodd" d="M17.1002 6.57068C20.0864 9.17098 21.66 11.4125 21.66 13.3396C21.66 14.71 21.1857 15.8357 20.3976 16.6266C19.566 17.4613 18.3937 17.9184 17.1 17.9184C15.8064 17.9184 14.6341 17.4613 13.8025 16.6266C13.0144 15.8357 12.54 14.71 12.54 13.3396C12.54 11.4124 14.1139 9.1707 17.1002 6.57068Z" stroke="#FDFDFE" />
	</svg>
);

HumiditySensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

HumiditySensor.defaultProps = {
	width: 24,
	height: 25
};

export default HumiditySensor;