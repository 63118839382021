import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { t } from "ttag";
import AlertsMap from "../../components/AlertsMap/AlertsMap";
import ErrorLogs from "../../components/ErrorLogs/ErrorLogs";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import SystemTable from "../../components/SystemTable/SystemTable";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AlertInfoPopup from "../Alerts/AlertInfoPopup";
import AlertsBand from "./AlertsBand";
import useStyles from "./Dashboard.style";
import OperationalOverview from "./OperationalOverview";
import SystemOverviewPopUp from "./SystemOverviewPopUp";
import TOU from "./TOU";

const Dashboard: React.FC<RouteComponentProps & any> = () => {
  const classes = useStyles();
  const { errorLogsCard, HVACCard, googleCard, listItem, ...otherProps } = classes;
  const history = useHistory();
  const [error, setError] = useState<any>(null);
  const getSystemFirstUnitId = useStoreState((s) => s.systems.getSystemFirstUnitId);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const me = useStoreState((s) => s.users.me);
  const acceptTOU = useStoreActions((a) => a.users.acceptTOU);
  const [open, setOpen] = useState(false);
  const [systemId, setSystemId] = useState<string>("");
  const [unitId, setUnitId] = useState<string | null>("");
  const [alertFilterType, setAlertFilterType] = useState<any>(null);
  const [clickedAlert, setClickedAlert] = useState<any>("");
  const { showClearFilterAlerts = true } = useStoreState((s) => s.users.userPreferences);
  const { customerId, siteId } = useStoreState((state) => state.selections.selections);
  const sites = useStoreState((s) => s.selections.getSitesBySelection);
  const selectedSystems = useStoreState((s) => s.selections.getSystemsBySelection);
  const [counts, setCounts] = useState<{ indoors?: number, outdoors?: number, systems?: number, allUnits?: number, sites?: number }>({ outdoors: 0, indoors: 0, systems: 0, allUnits: Object.values(selectedSystems)?.length || 0, sites: sites.length || 0 });
  const accept = () => {
    acceptTOU().then(() => setError(null)).catch((err: any) => setError(err.message));
  };
  const onSystemSelect = (systemId: string) => {
    updateSelections({ type: "system", data: systemId });
    history.push(`/system-diagnostics/${systemId}`);
  };
  const onSystemDoubleClick = (systemId: string) => {
    let firstUnitId: string | null = null;

    // Get the first outdoor unit of the selected system.
    firstUnitId = getSystemFirstUnitId(systemId, 2);

    // If no outdoor units on the selected system, get the first indoor.
    if (!firstUnitId) {
      firstUnitId = getSystemFirstUnitId(systemId, 1);
    }
    setUnitId(firstUnitId);
    setSystemId(systemId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClickedAlert("");
  };
  const handleAlertInfo = (alert: any) => {
    setClickedAlert(alert);
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...otherProps} />
      <div className={classes.contentArea}>
        <Header
          screenTitle="dashboard"
          path={[t`Dashboard`]}
          hideSystemSelection
          hideUnitSelection
          countControlUnits
          textAllCustomers={true}
        />
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={7} className={classes.tablesArea}>
            <Grid container direction="column" className={classes.tablesContainer}>
              {customerId &&
                <div className={classes.HVACContainer}>
                  <div className={classes.operationalView}>
                    <Card raised={false} className={classes.HVACCard}>
                      <div className={classes.HVAC}>
                        <Typography
                          className={classes.detailsSectionSecondTitle}
                        >{t`Operational Overview`}</Typography>
                        <OperationalOverview />
                      </div>
                    </Card>
                  </div>
                  <div className={classes.fullHeight}>
                    <Card raised={false} className={classes.HVACCard}>
                      <div className={classes.HVAC}>
                        <Typography
                          className={classes.detailsSectionSecondTitle}
                        >{t`HVAC Systems`}</Typography>
                        <SystemTable isSelectedSite={!!siteId} counts={counts} setCounts={setCounts} onSystemDoubleClick={onSystemDoubleClick} />
                      </div>
                    </Card>

                  </div>
                </div>
              }
              <Grid item className={clsx(classes.alertsContainer, !customerId && classes.maxAlerts)}>
                <AlertsBand
                  setAlertFilterType={setAlertFilterType}
                  filterType={alertFilterType}
                  showClearFilterAlerts={showClearFilterAlerts}
                />
                <Card className={classes.errorLogsCard}>
                  <ErrorLogs
                    showPointer
                    usePadding={false}
                    hideColumns={["status", "customerName", "alertType1"]}
                    onAlertClick={handleAlertInfo}
                    inDashboard={false}
                    filterType={alertFilterType}
                    hideEntity={true}
                    showClearFilterAlerts={showClearFilterAlerts}
                  />
                </Card>
              </Grid>
            </Grid>
            {/* ================== NEW with grid ================== */}
          </Grid>
          <Grid item xs={5} className={classes.mapArea}>
            <AlertsMap filterType={alertFilterType} />
          </Grid>

        </Grid>
        <SystemOverviewPopUp
          open={open}
          onSystemSelect={onSystemSelect}
          systemId={systemId}
          isUnit={!unitId}
          handleClose={handleClose}
        />
        <AlertInfoPopup
          alert={clickedAlert && clickedAlert}
          open={!!clickedAlert}
          handleClose={handleClose}
          updateSelections={updateSelections}
          setError={setError}
        />
      </div>
      <Dialog
        onClose={_.noop}
        aria-labelledby="simple-dialog-title"
        open={!me.isAcceptedTOU && !me?.isImpersonation}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <TOU />
        </DialogContent>

        <DialogActions
        // className={classes.modalActions}
        >
          <Button
            onClick={accept}
            width={150}
          >
            {t`Accept`}
          </Button>
        </DialogActions>
      </Dialog>
      {
        error &&
        <Dialog onClose={() => setError(null)} aria-labelledby="customized-dialog-title" open={error}>
          <DialogTitle id="customized-dialog-title">
            {t`Error`}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              {error}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setError(null)} color="primary">
              {t`Close`}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

export default Dashboard;
