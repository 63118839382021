import { createStyles } from "@material-ui/core";
const unitPanelStyle = (theme: any) =>
  createStyles({
    root: {
      height: 3,
      marginBottom: 0,
    },
    mark: {
      width: 5,
      height: 3,
    },
    markLabel: {
      top: 35
    },
    thumb: {
      pointerEvents: 'none',
      height: 27,
      width: "27px !important",
      backgroundColor: '#fff',
      marginTop: -13,
      marginLeft: -13,
      boxShadow: '#unset',
      '&:focus, &:hover, &$active': {
        boxShadow: 'unset',
      },
    },
    active: {},
    track: {
      height: 3,
    },
    rail: {
      opacity: 0.2,
      height: 3,
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    thumbComponent: {
      paddingLeft: "1px",
      paddingTop: "2px",
      fontSize: "13px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "27px",
      height: "27px",
      borderRadius: "50%",
      fontWeight: "bold",
      color: "#fff"
    }
  });
export default unitPanelStyle;