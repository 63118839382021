import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: "1rem",
      height: "100%",
      // paddingTop: "1%"
      paddingTop: 0,
    },

    header: {
      display: "flex",
      alignItems: "center",
      paddingBottom: "4.41px",
      justifyContent: "space-between",
    },

    highlightedItem: {
      backgroundColor: theme.palette.primary.light,
      "& td":{
        backgroundColor: '#EFEBEF !important'
      }
    },

    table: {},

    tableWrapper: {
      minHeight: "470px",
      overflowX: "auto",
      maxHeight: "calc(100% - 70px)",
    },
    largeWrapper: {
      minHeight: "calc(62% - 45px) !important",
      maxHeight: "calc(62% - 45px) !important",
      height: "calc(62% - 45px) !important",
      overflowX: "auto",
    },
    smallWrapper: {
      minHeight: "calc(38% - 45px)",
      maxHeight: "calc(38% - 45px)",
      height: "calc(38% - 45px)",
      overflowX: "auto",
    },
    "@media (max-width: 1600px)": {
      tableWrapper: {
        minHeight: "470px",
      },
      halfWrappaer: {
        minHeight: "calc(50% - 45px)",
      },
    },

    tableHeadStyle: {
      borderBottom: "none",
      // padding: "16px 15px !important",
      fontSize: "13px",
      padding: 0,
      color: "#79797d",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      backgroundColor: "#efeff2",
      // height: 56
      height: "45px",
    },

    tableCell: {
      fontSize: "0.8125rem !important",
      // padding: "0.2rem 0.2rem 0.2rem 0",
      padding: 0,
      textAlign: "center",
      // height: "3rem",
      height: "45px",
      "&:last-child": {
        paddingRight: "20px",
      },
    },

    tableCellAlignLeft: {
      fontSize: "0.8125rem !important",
      padding: "0 10px",
      paddingRight: "15px",
      textAlign: "left",
      width: "1%",
      whiteSpace: "nowrap",
    },
    nameCell: {
      fontSize: "0.8125rem !important",
      padding: "0 10px",
      paddingRight: "15px",
      textAlign: "left",
      width: "20ch",
      maxWidth: "30ch",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    rangeCell: {
      width: "20ch",
      maxWidth: "30ch",
      paddingLeft: "auto",
    },

    "@media (min-width: 1921px)": {
      nameCell: {
        width: "45ch",
        minWidth: "45ch",
      },
      rangeCell: {
        width: "45ch",
        minWidth: "45ch",
      },
    },

    "tableCell:last-child": {
      paddingRight: "1rem",
    },

    "tableCell:nth-last-child(2)": {
      paddingRight: "2rem",
    },

    tableRowUnplottable: {
      backgroundColor: "rgb(249, 249, 249)",
    },

    title: {
      padding: "0.5rem 0",
      color: "#4b1c46",
    },

    lastUpdatedTitle: {
      fontSize: "0.7rem",
      color: "#aaa2aa",
      marginRight: "5px",
    },

    thumbValue: {
      color: "rgb(75, 28, 70)",
      marginTop: "-5px",
      fontSize: "1.3rem",
    },

    thumbSelection: {
      color: "rgb(240, 81, 70)",
      marginTop: "-2px",
      fontSize: "1.7rem",
      opacity: "0.7",
    },

    autoRefreshLabel: {
      fontSize: "0.85rem",
    },
    autoUpdate: {
      minWidth: "8rem",
    },
    removePaddingRight: {
      paddingRight: "5px",
    },
    grayCheckbox: {
      "&$checked": {
        color: "#736973",
      },
    },
    popper: {
      marginBottom: "-20px",
    },
    dialogTitle: {
      borderBottom: "solid 1px #d5d2d5",

      display: "flex",
      justifyContent: "space-between",
      padding: "12px",
      paddingRight: "5px",
      height: "60px",
      backgroundColor: "#f6f6f7",
      alignItems: "center",
      width: "350px",
    },
    addIcon: {
      color: "#aaa2aa",
      marginLeft: "3px",
    },
    pushedAddIcon: {
      background: "#EFEFF2",
    },
    paramsPopupButton: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "15px",
    },
    paramsPopupList: {
      width: "100%",
      overflow: "auto",
      paddingRight: 20,
      paddingLeft: 10,
    },
    parametersHeader: {
      width: "50%",
      display: "flex",
      alignItems: "center",
    },
    autoUpdateContainer: {
      display: "flex",
      alignItems: "center",
    },
    smallCell: {
      maxWidth: "80px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    moveCursor: { cursor: "move !important" },
    yesBtn: {
      marginRight: "17px",
    },
    dialogActions: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "1rem",
    },
    tableCellValue: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "4rem",
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0,
    },
    dialogHeader: {
      paddingLeft: 20,
      paddingRight: 13,
      ...theme.dialogs.header,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60,
    },
    headerTitle: {
      ...theme.dialogs.title,
    },
    dialogContent: {
      flex: "1 1 auto",
      padding: 20,
      overflowY: "auto",
      paddingBottom: 0,
    },
    limitWidth: {
      minWidth: "10px",
      width: "10px",
      maxWidth: "10px",
    },
    resetParamsButton: {
      minWidth: "fit-content",
      marginRight: 5,
      height: 30,
    },
    subTitle: {
      ...theme.dialogs.title,
      fontSize: "15px",
      margin: "10px 0 0 20px",
    },
    editContainer: {
      width: "32px",
    },
    valueStyle: {
      fontSize: "15px",
    },
    paramsWithNoSensors: {
      height: "400px",
    },
    tab: {
      height: "60px",
      // width: "50%"
    },
    indicatorColor: {
      backgroundColor: theme.palette.primary.main,
      width: "50% !important",
    },
    customLabel: {
      color: "white",
      borderRadius: "3px",
      padding: "4px 7px",
      fontSize: "11px",
      lineHeight: "normal",
      fontWeight: "normal",
      letterSpacing: "normal",
      width: "fit-content",
      maxHeight: "19px",
      background: "#d7d7df",
    },
    dialogStyle: {
      minWidth: "495px",
    },
    overWriteIcon: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0,
    },
    tabRoot: {
      width: "100%",
      padding: 0,
      height: "60px",
    },
    dialog: {
      borderRadius: "0.5rem",
      boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
      border: " 1px solid #d5d2d5",
      minWidth: "350px",
    },
    titleContent: {
      paddingLeft: 20,
      paddingRight: 13,
      ...theme.dialogs.header,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60,
    },
    DialogContentText: {
      fontFamily: "Roboto",
      color: "#545964",
    },
    deleteDialogActions: {
      padding: 20,
      display: "flex",
      justifyContent: "center",
    },
    customParamToolTipChild: {
      display: "flex",
      flex: 1,
      alignItems: "center",
    },
  })
);

export default useStyles;
