import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  dialogPaper: {
    height: "100%",
    width: "100%",
  },

  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964",
  },

  boldText: {
    fontWeight: "bold",
  },

  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    paddingBottom: 0,
    display: "flex",
    flexFlow: "row nowrap",
    flexDirection: "column",
    height: "40%",
  },

  overWritePadding: {
    ...theme.tables.tableCell,
    padding: "8px",
  },

  paperTableContainer: {
    width: "inherit",
  },

  tableHeadRow: {
    height: 45,
    padding: 0,
  },

  tableHeadCell: {
    backgroundColor: theme.tables.headerCell.backgroundColor,
    padding: "8px",
  },

  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },

  tableContainer: {
    display: "flex",
    height: "100%",
    overflow: "auto",
  },

  subscriptionsTable: {
    background: theme.palette.background.paper,
  },

  loaderContainer: {
    width: "100px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default useStyles;
