import * as React from 'react';

function SvgAppUser(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 28" {...props}>
      <defs>
        <path id="appUser_svg__a" d="M0 .106h16.286v27.19H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 .544)">
          <mask id="appUser_svg__b" fill="#fff">
            <use xlinkHref="#appUser_svg__a" />
          </mask>
          <path
            d="M13.844 25.598H2.443c-.45 0-.814-.381-.814-.85v-2.55h13.03v2.55c0 .469-.366.85-.815.85M2.443 1.805h11.4c.45 0 .815.381.815.85v17.844H1.628V2.655c0-.469.366-.85.815-.85m11.4-1.7h-11.4C1.096.106 0 1.25 0 2.656v22.093c0 1.406 1.096 2.549 2.443 2.549h11.4c1.348 0 2.443-1.143 2.443-2.55V2.656c0-1.406-1.095-2.55-2.442-2.55"
            fill="#AAA2AA"
            mask="url(#appUser_svg__b)"
          />
        </g>
        <path
          d="M8.72 23.841a.876.876 0 010 1.203.791.791 0 01-1.153 0 .878.878 0 010-1.203.791.791 0 011.152 0"
          fill="#AAA2AA"
        />
      </g>
    </svg>
  );
}

export default SvgAppUser;
