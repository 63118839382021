import { alpha, createStyles } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    disabledStyle: {
      color: `${alpha("#fff", 0.1)} !important`
    },
    root: {
      padding: "6px",
      maxWidth: "24px",
      maxHeight: "24px",
      "& svg": {
        fontSize: "24px"
      },
      "&:hover": {
        backgroundColor: "transparent"
      },
      color: "#AAA2AA"
    }
  });
