import {
  Button as MUIButton,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  makeStyles,
  Typography,
  SvgIconProps
} from "@material-ui/core";
import { ChevronRight, ErrorOutline, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { TopBar } from "../../components";
import Button from "../../cool_widgets/Button";
import HVACImg from "../../images/coolmaster.png";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ArrowBack } from "../../svgComponents";
import { registerNewDeviceStyle } from "./registerNewDevice.style";
import { TreeItem, TreeItemProps, TreeView } from "@material-ui/lab";

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  node?: any;
  className?: any;
};

const RegisterNewDeviceQR: React.FC<any> = (props) => {

  const allCustomers = useStoreState((states) => states.customers.allCustomers);
  const allSites = useStoreState((state) => state.sites.allSites);
  const { deviceRegistrationProgress = {} } = useStoreState((states) => states.devices);
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [serial, setSerial] = useState<string>(deviceRegistrationProgress.serial || null);
  const [pin, setPin] = useState<string>(deviceRegistrationProgress.pin || null);
  const [confirmCheck, setConfirmCheck] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const startLoader = useStoreActions((actions) => actions.loader.startLoader);
  const finishLoader = useStoreActions((actions) => actions.loader.finishLoader);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const addNewDevice = useStoreActions((action) => action.createDevice);
  const getAccountByDeviceSerial = useStoreActions((actions) => actions.devices.getAccountByDeviceSerial);
  const fetchAllUnits = useStoreActions((state) => state.units.getUnits);
  const [expanded, setExpanded] = useState<any>([]);

  const {
    history,
    history: { location: { search = "" } = {} } = {}
  } = props;

  const isLoggedIn = useStoreState((s) => s.isLoggedIn);

  const useStyles = makeStyles(registerNewDeviceStyle);
  const classes = useStyles();

  useEffect(() => {
    const searchParams = new URLSearchParams(search || "");
    const serial = searchParams.get("serial");
    const pin = searchParams.get("pin");

    if (!search || !serial || !pin) {
      history.push(`/dashboard`)
      return;
    }

    setSerial(serial);
    setPin(pin);

  }, [search]);

  const addDevice = (values: any) => {
    startLoader();
    const { serial, pin, site } = values;
    addNewDevice({
      site,
      serial: serial.replace(/\s+/g, " ").trim(),
      pin: pin.replace(/\s+/g, " ").trim(),
      shortRegistration: true
    })
      .then(() => {
        fetchAllUnits();
        history.push(`/dashboard`);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const handleSubmit = async (values: any) => {
    const { pin, serial } = values;

    const deviceInfo: any = await getAccountByDeviceSerial({
      serial: serial.replace(/\s+/g, " ").trim(),
      pin: pin.replace(/\s+/g, " ").trim()
    });

    if (!deviceInfo || !deviceInfo?.isFound) {
      setError(`The device ID you entered was not found. Please verify you entered it correctly`);
      return;
    }

    if (!deviceInfo?.isAuthenticated) {
      setError(t`The device ID and pin do not match. Please verify you entered it correctly`);
      return;
    }

    if (!deviceInfo?.isConnected) {
      setError(t`The device is not connected. It must be connected to be registered. Please connect it and try again`);
      return;
    }

    if (deviceInfo?.isRegistered) {
      setError(t`The device is already registered to another account, and can not be registered again`);
      return;
    }

    if (isLoggedIn) {
      addDevice(values);
      return;
    }
    else {
      history.push("/");
    }
  };

  const goToDashboard = () => {
    history.push(`/dashboard`);
  };

  function StyledTreeItem(props: StyledTreeItemProps) {
    const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;
    return (
      <TreeItem
        label={
          <div className={clsx(classes.labelRoot, { [className]: className })}>
            {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
            {node && node}
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
          background: bgColor
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
          iconContainer: classes.iconContainer
        } as any}
        {...other}
      />
    );
  }

  const sitesNodes = (id: any) => {
    const sites = Object.values(allSites || {}).filter((site: any) => site?.customer === id);
    const items = [];
    for (const i in sites) {
      const site: any = sites[i];
      if (site) {
        const { id, name, predictiveMaintenance, hvacAdvancedOperations } = site;
        if (!predictiveMaintenance && !hvacAdvancedOperations) {
          continue;
        }
        id && items.push(
          <StyledTreeItem
            onClick={() => setSelectedSite(id)}
            key={`site-${id}`}
            nodeId={`site-${id}`}
            labelText={name}
            color="#545964"
            bgColor={id === selectedSite ? "#f0f0f0" : "#fff"}
          >
          </StyledTreeItem>);
      }
    }
    return items;
  };

  const customersNodes = () => {

    const items = [];
    for (const customer in allCustomers) {
      const customerObject = allCustomers[customer];
      const { id, name } = customerObject;
      const sites = sitesNodes(id);

      customerObject && items.push(
        <StyledTreeItem
          key={`customer-${id}`}
          nodeId={`customer-${id}`}
          labelText={name || ""}
          color="#545964"
          bgColor="#fff"
        >{sites}</StyledTreeItem>);
    }

    return items;
  };



  return (
    <div className={classes.screenContainer}>
      <TopBar
        title={t`Connect Device`}
        leftIconComponent={isLoggedIn && <ArrowBack />}
        leftAction={isLoggedIn && goToDashboard}
        hideRightComponent={true}
      />

      <div className={classes.container}>
        <div className={classes.pageContent}>
          <div className={clsx(classes.header)}>
            <Typography className={classes.pageTitle}>
              {t`Lets connect your HVAC Device`}
            </Typography>
            <div className={classes.deviceImageContainer}>
              <div />
              <img src={HVACImg} alt={"HVAC"} className={classes.deviceImgStyle} />
            </div>
          </div>

          {!isLoggedIn ? <div>
            <Typography className={classes.textStyle}>{t`Please login to continue`}</Typography>
            <Button variant="contained" className={classes.nextButton} onClick={() => {
              localStorage.setItem("redirectUrl", history.location.pathname + history.location.search);
              history.push("/login")
            }}>
              {t`Go to login page`}
            </Button>
          </div>
            : <div className={classes.formsWrapper}>
              <div className={classes.manualFormWrapper}>
                <Formik
                  initialValues={{ serial, pin, site: selectedSite }}
                  onSubmit={(values) => handleSubmit(values)}
                  enableReinitialize={true}
                >
                  {() => (
                    // @ts-ignore
                    <Form translate="yes">
                      <div className={classes.addDeviceContainer}>
                        <Typography className={classes.textStyle}>
                          {t`Select Site to Add Device into it`}
                        </Typography>

                        <TreeView
                          defaultCollapseIcon={<ExpandMore />}
                          expanded={expanded}
                          defaultExpandIcon={<ChevronRight />}
                          onNodeToggle={(e: any, ids: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setExpanded(ids.length > 0 ? [ids[0]] : []);
                          }}
                        >
                          {customersNodes()}
                        </TreeView>
                      </div>

                      <FormControlLabel
                        control={
                          <Checkbox
                            color={"default"}
                            checked={confirmCheck}
                            onChange={() => setConfirmCheck(!confirmCheck)}
                          />
                        }
                        style={{
                          marginBottom: 10,
                          marginTop: 10,
                          marginRight: 0
                        }}
                        classes={{
                          label: classes.lableStyle,
                          root: confirmCheck ? "" : classes.checkBoxRed
                        }}
                        label={t`I confirm I have the official approval to connect to the HVAC system using this device and start pulling all technical and control data using this solution.`}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.nextButton}
                        disabled={!confirmCheck || !selectedSite?.length}
                      >
                        {t`Next`}
                      </Button>
                    </Form>
                  )
                  }
                </Formik>
              </div>
            </div>
          }
        </div>
      </div>

      {
        error &&
        <Dialog onClose={() => { }} open={error}>
          <div className={classes.errDialogHeader}>
            <Typography className={classes.errTitle}><ErrorOutline />{t`Device registration error`}</Typography>
          </div>
          <DialogContent className={classes.errContent}>
            <Typography>{error}</Typography>
            <MUIButton
              className={classes.errBtn}
              onClick={goToDashboard}
              variant="contained"
              color="primary"
            >
              {t`Close`}
            </MUIButton>
          </DialogContent>
        </Dialog>
      }
    </div>
  );
};

export default RegisterNewDeviceQR;
