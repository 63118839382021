import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {t} from 'ttag';
import _ from 'lodash';

import useStyles from '../../AddEditDialog.style';
import CheckboxesList from '@components/CheckboxesList';

//todo: add jsdoc
const TenantsSelector = ({disabled, tenants, value, onChange}) => {

	const classes = useStyles();

	const options = useMemo(()=>{
		const sortedTenants = _.orderBy(tenants, [(item) => item?.name?.toUpperCase()],['asc']).filter((system)=> !!system);
		return sortedTenants.map(system => ({...system, text:system.name,value:system.id}));
	},[tenants]);



	return (
		<div className={classes.middleSideSection}>
			<Typography className={classes.sectionTitle}>{t`Select Tenants`}</Typography>
			<div className={classes.middleSideSectionScrollable}>
				{options.length ? (	
					<CheckboxesList
						options={options}
						onChange={onChange}
						value={value}
						disabled={disabled}
						selectAllLabel={t`All Tenants`}
					/>
				): (
					<Typography className={classes.itemName}>{t`No tenants`}</Typography>
				)}
			</div>
		</div>
	);
};

TenantsSelector.propTypes = {
	onChange:PropTypes.func.isRequired,
	value:PropTypes.array,
	tenants:PropTypes.array,
	disabled:PropTypes.bool,
	error:PropTypes.string
};

TenantsSelector.defaultProps = {
	value:[],
	tenants:[],
	disabled:false,
	error:''
};

export default TenantsSelector;