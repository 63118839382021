import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	filterHeaderContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '0.5rem',
		borderBottom: '2px solid rgba(60, 60, 60, 0.2)',
	},
	filterHeader: {
		paddingLeft: '6px',
		color: '#4b1c46',
		whiteSpace: 'nowrap',
	},
	filterList: {
		overflow: 'auto',
		height: 'calc(100% - 75px)',
	},
	paper: { height: ' 100%' },
	limitShownText: {
		'& span': {
			width: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
	highlightedItem: {
		backgroundColor: theme.palette.primary.light,
	},
	grayCheckbox: {
		marginRight: '10px',
		'&$checked': {
			color: '#736973',
		},
	},
	addIcon: {
		color: '#aaa2aa',
		marginLeft: '3px',
		width: 30,
		height: 30,
		borderRadius: 6,
		padding: 0,
	},
	pushedAddIcon: {
		background: '#EFEFF2',
	},
	paramsPopupButton: {
		padding: 20,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	filterPopupHeader: {
		paddingLeft: 20,
		paddingRight: 13,
		...theme.dialogs.header,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 60,
		minHeight: 60,
	},
	filterPopupList: {
		width: '300px',
		height: 'calc(100% - 60px)',
		overflowY: 'auto',
		paddingLeft: 10,
		paddingRight: 20,
	},
	clearIcon: {
		width: '28px',
		height: '28px',
	},
	outdoorPaper: {
		height: '80%',
		maxHeight: '80%',
		overflow: 'hidden',
	},
	indoorPaper: {
		height: '47%',
	},
	moveCursor: { cursor: 'move !important' },
	iconBtnStyle: {
		width: 30,
		height: 30,
		borderRadius: 6,
		padding: 0,
	},
	headerTitle: {
		...theme.dialogs.title,
	},
	customLabel: {
		color: 'white',
		borderRadius: '3px',
		padding: '4px 7px',
		fontSize: '11px',
		lineHeight: 'normal',
		fontWeight: 'normal',
		letterSpacing: 'normal',
		width: 'fit-content',
		maxHeight: '19px',
		background: '#d7d7df',
		display:'flex',
		alignItems:'center'
	},
}));
