import React from "react";

function MobileDashboard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="#AAA2AA" fillRule="evenodd">
        <path d="M16.985 19.415a2.415 2.415 0 002.34-3.041l2.789-2.789a1.2 1.2 0 10-1.7-1.698l-2.788 2.788a2.414 2.414 0 00-2.76 3.454 2.397 2.397 0 002.119 1.286"></path>
        <path
          fillRule="nonzero"
          d="M17 5c6.627 0 12 5.373 12 12 0 6.628-5.373 12-12 12S5 23.628 5 17c0-6.627 5.373-12 12-12zm-.447 17.722c-2.482 0-4.798.734-6.522 1.997A10.36 10.36 0 0017 27.4a10.36 10.36 0 006.542-2.314c-1.75-1.479-4.266-2.364-6.989-2.364zM17 6.6C11.256 6.6 6.6 11.257 6.6 17c0 2.448.846 4.698 2.261 6.475l.062.074c2.023-1.544 4.739-2.428 7.63-2.428 3.16 0 6.102 1.057 8.157 2.857A10.355 10.355 0 0027.4 17c0-5.743-4.656-10.4-10.4-10.4z"
        ></path>
      </g>
    </svg>
  );
}

export default MobileDashboard;
