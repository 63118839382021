import { Services as sdkServices } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { TopBar } from "../../components";
import Loading from "../../components/Loading/Loading";
import { MobileLogo } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AlertsBand from "./AlertsBand";
import useStyles from "./Dashboard.style";
import SiteCountCard from "./SiteCountCard";
import WorkingUnits from "./WorkingUnits";

const Dashboard: React.FC<RouteComponentProps & any> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const selections = useStoreState((s) => s.selections.mobileSelections);
  const getAllOnUnits = useStoreActions((actions) => actions.units.getAllOnUnits);
  const isInitialized = useStoreState((s) => s.isInitialized);

  const { customerId, siteId } = selections;
  const [openWorkingUnits, setOpenWorkingUnits] = useState<boolean>(false);
  const [workingUnits, setWorkingUnits] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [allWorkingUnits, setAllWorkingUnits] = useState<any>(null);
  const [mode, setMode] = useState<any>("");
  const [data, setData] = useState<any>({ sites: [], openAlerts: {}, firstCall: true });
  const [alertsBandData, setAlertsBandData] = useState<any>({});
  const [dataChanged, setDataChanged] = useState<number>(0);

  const handleWorkingUnits = (unitsCount: number, mode: any, siteId: string) => {
    if (unitsCount > 0) {
      setOpenWorkingUnits(mode);
      setLoading(true);
      setMode(mode);
      mode === "ALL" ? setAllWorkingUnits("open") : setAllWorkingUnits("");
      getAllOnUnits({ customerId, siteId })
        .then((res: any) => {
          setWorkingUnits(res);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const redirectUrl = localStorage.getItem("redirectUrl");
    if (redirectUrl) {
      localStorage.removeItem("redirectUrl");
      history.push(redirectUrl);
    }
  }, []);

  useEffect(() => {
    sdkServices.getProfessionalDashboard()
      .then((res: any) => {
        setData(res);
      })
      .catch(() => { });
  }, [dataChanged]);

  useEffect(() => {
    if (siteId) {
      const sites = data?.sites || [];
      let siteData: any = { alertCount: {} };
      for (let index in sites) {
        const site = sites[index] || {};
        if (site?.id === siteId) {
          siteData = site?.alertCount || {};
          break;
        }
      }
      setAlertsBandData(siteData);
    } else {
      setAlertsBandData(data?.openAlerts);
    }
  }, [siteId, data]);

  //this route can be accessed before tree API call is done, so we need to keep this loader here
  if (!isInitialized) {
    return <Loading mobile={true} />;
  }

  return (
    <>
      <TopBar screenTitle="dashboard" hideUnitsFilter leftIconComponent={<MobileLogo />} />
      <div className={classes.view}>
        <div className={classes.contentArea} style={{ background: "white" }}>
          {!data.firstCall && <AlertsBand alertsCount={alertsBandData} />}

          <div id="sitesHolder" style={{
            display: "flex", flexFlow: "column nowrap", padding: "0 20px", overflow: "auto"
          }}>
            {Object.values(data.sites).map((site: any, index: number) => {
              if (siteId && siteId !== site.id) {
                return null;
              }
              const { system, indoor, communication, anomalies } = site.alertCount;
              const systemIndoor = system + indoor;

              return <SiteCountCard
                key={`site-${site.id}`}
                systemIndoor={systemIndoor}
                anomalies={anomalies}
                communication={communication}
                name={site.name}
                indoorUnits={site.numOfUnits}
                onIndoorUnits={site.numOfOnUnits}
                coolUnits={site.numOfCoolOnUnits}
                hotUnits={site.numOfHeatOnUnits}
                siteId={site.id}
                handleWorkingUnits={handleWorkingUnits}
              />;
            })}
          </div>
        </div>
        {openWorkingUnits &&
          <WorkingUnits
            loading={loading}
            workingUnits={workingUnits}
            openWorkingUnits={openWorkingUnits}
            setOpenWorkingUnits={setOpenWorkingUnits}
            setDataChanged={setDataChanged}
            dataChanged={dataChanged}
            allWorkingUnits={allWorkingUnits}
            mode={mode}
          />}
      </div>
    </>
  );
};

export default Dashboard;
