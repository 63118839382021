import React from 'react';
import PropTypes from 'prop-types';

const ErrorSensor = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M1.29255 15.5653L7.89371 2.93975C9.31537 0.222348 11.8336 0.222348 13.267 2.93905L19.9006 15.5654C21.3207 18.2928 19.8807 20.6223 16.8015 20.5985H4.39163C1.34407 20.5985 -0.112047 18.2621 1.29255 15.5653ZM19.0951 15.9867L12.4626 3.36258C11.3709 1.29353 9.78095 1.29353 8.69928 3.36107L2.09883 15.9853C1.01223 18.0715 2.02056 19.6894 4.39163 19.6894L16.805 19.6894C19.2045 19.708 20.1968 18.1028 19.0951 15.9867Z" fill="#FDFDFE" />
		<mask id="mask0_1252_991" maskUnits="userSpaceOnUse" x="9" y="6" width="3" height="9">
			<path fillRule="evenodd" clipRule="evenodd" d="M9.69313 6.93469H11.9837V14.5621H9.69313V6.93469Z" fill="white" />
		</mask>
		<g mask="url(#mask0_1252_991)">
			<path fillRule="evenodd" clipRule="evenodd" d="M10.3335 14.1181L9.69918 8.16571C9.63454 7.55314 10.0911 7.00359 10.7204 6.94056C11.3497 6.87655 11.9123 7.32268 11.978 7.93624C11.9861 8.01208 11.985 8.09283 11.978 8.16571L11.3436 14.1181C11.3153 14.39 11.0659 14.5879 10.7861 14.5593C10.5426 14.5347 10.3578 14.3456 10.3335 14.1181Z" fill="#FDFDFE" />
		</g>
		<mask id="mask1_1252_991" maskUnits="userSpaceOnUse" x="9" y="15" width="3" height="3">
			<path fillRule="evenodd" clipRule="evenodd" d="M9.75081 15.545H11.9245V17.7197H9.75081V15.545Z" fill="white" />
		</mask>
		<g mask="url(#mask1_1252_991)">
			<path fillRule="evenodd" clipRule="evenodd" d="M10.8377 17.7198C10.2367 17.7198 9.75081 17.2329 9.75081 16.6329C9.75081 16.0319 10.2367 15.545 10.8377 15.545C11.4387 15.545 11.9245 16.0319 11.9245 16.6329C11.9245 17.2329 11.4387 17.7198 10.8377 17.7198Z" fill="#FDFDFE" />
		</g>
	</svg>
);

ErrorSensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

ErrorSensor.defaultProps = {
	width: 21,
	height: 21
};

export default ErrorSensor;