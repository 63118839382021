import React from "react";

const SvgFixedSpeed = props => (
  <svg width="40" height="50" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(10 0)" opacity=".5" fill="#FDFDFE">
        <rect y="12.325" width="2.175" height="7.975" rx="1.087" />
        <rect x="5.075" y="8.7" width="2.175" height="11.6" rx="1.087" />
        <rect x="10.875" y="3.625" width="2.175" height="16.675" rx="1.087" />
        <rect fillOpacity=".5" x="16.675" width="2.175" height="20.3" rx="1.087" />
      </g>
    </g>
    <g fill="none" transform="translate(12 2)" fillRule="evenodd">
      <text fill="#FDFDFE" fontFamily="Roboto" fontSize="11" fontWeight="500" opacity=".5" color="rgba(253, 253, 254, 0.4)">
        <tspan x="7" y="29" text-anchor="middle">Fixed</tspan>
        <tspan x="7" y="39" text-anchor="middle">Speed</tspan>
      </text>
    </g>
  </svg>
);

export default SvgFixedSpeed;
