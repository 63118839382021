import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    borderRadius: "10px",
    border: "solid 1px #ebebeb",
  },
  currentSetpointTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
    padding: "0 5px",
  },
  content: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
    padding: "4%",
  },
  ambTemp: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "5px",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  ambientTempTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px lightgray solid",
    width: "100%",
    height: "100%",
  },
  externalTempTitle: {
    display: "flex",
    height: "100%",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  currentSetpointContent: {
    marginTop: "1rem",
  },
  temp: {
    marginRight: "0.5rem",
  },

  tempStyle: {
    fontSize: "4.0625rem",
    fontFamily: "Roboto",
    letterSpacing: "0.13rem",
    color: "#4b1c46",
  },
  tempIconStyle: {
    color: "#4b1c46",
    fontSize: "1.875rem",
    letterSpacing: "0.06rem",
    fontFamily: "Roboto",
  },
  tempTitle: {
    color: "#6f6f6f",
    fontSize: "1rem",
    fontFamily: "RobotoMedium",
    textAlign: "center",
  },

  infoContainer: {
    backgroundColor: "#f6f5f6",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    width: "48%",
    textAlign: "center",
  },
  minorTempStyle: {
    color: "#4b1c46",
    fontSize: "1.75rem",
    letterSpacing: "0.04625rem",
    fontFamily: "Roboto",
  },
  minorTempIconStyle: {
    color: "#413044",
    fontSize: "0.8125rem",
    letterSpacing: "0.03375rem",
    fontFamily: "RobotoLight",
  },

  minorTempTitleStyle: {
    color: "#545964",
    fontSize: "0.8125rem",
    fontFamily: "Roboto",
    boxSizing: "border-box",
  },
});

export default useStyles;
