import { IconButton, Slider, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import Switch from "../../components/SmallSwitch/Switch";
import scheduletypes from "../../constants/scheduletypes";
import Button from "../../cool_widgets/Button";
import DatesList from "../../cool_widgets/DatesList/DatesList";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { Delete, MobileLogo, SchedulesCalendar, SettingsAlert } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { minsToTime } from "../../services/timeService";
import {
  AddIcon,
  ColdBlue,
  Edit,
  HotRed,
} from "../../svgComponents";
import FilterRequire from "../MobileFilterRequire/FilterRequire";
import AddEditSchedule from "./AddEditSchedulePopup/AddEditSchedulePopup";
import unitManagementStyles from "./unitManagement.style";

const UnitManagement = () => {
  const useStyles = makeStyles(unitManagementStyles);
  const classes: any = useStyles();
  const history = useHistory();
  const theme: any = useTheme();
  const types: any = useStoreState((states) => states.types);
  const addMessage = useStoreActions((actions) => actions.errorMessage.addMessage);
  const setEWrcLock = useStoreActions((a) => a.units.setEWrcLock);
  const toggleEWrcLock = useStoreActions((a) => a.units.toggleEWrcLock);
  const updateStoreUnit = useStoreActions((a) => a.units._storeUpdateUnit);
  // const getUnit = useStoreState((state) => state.units.getUnit);
  const getUnit = useStoreActions((actions) => actions.units.getUnitByIdSDK);
  const getDeviceById = useStoreState((state) => state.devices.getDeviceById);
  const { unitId, customerId } = useStoreState((state) => state.selections.mobileSelections);
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const me = useStoreState((state) => state.users.me);
  const { temperatureScale } = me;
  const getCustomerSchedules = useStoreActions((a) => a.schedules.getCustomerSchedules);
  const { weekDays = [""], scheduleCategories } = types;

  const deleteSchedule = useStoreActions((actions) => actions.schedules.deleteSchedule);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);

  const [weekDaysOptions] = useState<any>(Object.keys(weekDays));
  const [unit, setUnit] = useState<any>({});
  const [schedules, setSchedules] = useState<any>({});
  const [unitSchedules, setUnitSchedules] = useState<any>({});
  const [schedulesFetched, setSchedulesFetched] = useState<boolean>(false);
  const [selectedScheduleId, openAddEditSchedulePopup] = useState<string>("");
  const [disableMessage, setDisableMessage] = useState<string>("");
  const [dirty, setDirty] = useState<boolean>(false);
  const [toDeleteItem, setToDeleteItem] = useState<any>(null);
  const [isEWrcDisabled, setIsEWrcDisabled] = useState<boolean>(false);

  const {
    eWrcEnableOnoff,
    eWrcEnableMode,
    eWrcEnableSetpoint,
    eWrcEnableCoolMode,
    eWrcEnableHeatMode,
    // eWrcEnableAutoMode,
    eWrcTemperatureLimits,
    temperatureLimits,
    capabilityFlags = {},
    permissions = {}
  } = unit;

  const { canUpdate, canUpdateSchedules, canCreateSchedules, canDeleteSchedules } = permissions;

  const {
    enableWrcSettingsOnOffLock = false, enableWrcSettingsSetpointChangeLock = false,
    enableWrcSettingsModeLock = false, enableWrcSettingsLimitCoolingSetpoint = false,
    enableWrcSettingsLimitHeatingSetpoint = false, enableWrcSettingsRestrictionsLock = false
  } = capabilityFlags;
  const tempLimits = eWrcTemperatureLimits ? eWrcTemperatureLimits : temperatureLimits;

  const slidersRef: any = useRef([]);

  useEffect(() => {
    if (slidersRef.current.length) {
      slidersRef.current.forEach((slider: any) => {
        slider?.addEventListener(
          "touchstart",
          (e: any) => {
            const isThumb = e.target?.dataset.index;
            if (!isThumb) {
              e.stopPropagation();
            }
          },
          { capture: true }
        );
      });
    }
  }, [slidersRef.current.length]);

  useEffect(() => {
    if (!customerId) {
      return;
    }
    schedulesFetched && setSchedulesFetched(false);
    getCustomerSchedules({ id: customerId, type: scheduletypes.eDeviceLimits }).then((res: any) => {
      setSchedules(res);
      setSchedulesFetched(true);
    });
  }, [customerId]);

  useEffect(() => {
    dirty && setDirty(false);
    if (!unitId) {
      return;
    }

    getUnit(unitId)
      .then((resp: any) => {

        setUnit(resp);
        setIsEWrcDisabled(resp?.eWrcDisable);
        const device: any = getDeviceById(resp?.device || "");
        const { isConnected, protocolVersion } = device;
        if (protocolVersion < 4) {
          setDisableMessage(t`You need to upgrade your device firmware to use this feature`);
          return;
        }

        if (!isConnected) {
          setDisableMessage(t`Device is disconnected`);
          return;
        }
        setDisableMessage("");
      })
  }, [unitId]);

  useEffect(() => {
    if (!unitId || !schedulesFetched) {
      return;
    }

    const filteredSchedules: any = {};
    Object.values(schedules).forEach((schedule: any) => {
      const { id, unit } = schedule;
      if (unit === unitId) {
        filteredSchedules[id] = schedule;
      }
    });
    setUnitSchedules(filteredSchedules);

  }, [unitId, schedulesFetched]);

  const saveUnit = () => {
    const data: any = {
      unitIds: [unitId],
      eWrcTemperatureLimits: enableWrcSettingsLimitCoolingSetpoint || enableWrcSettingsLimitHeatingSetpoint ? eWrcTemperatureLimits : undefined,
      eWrcEnableOnoff: enableWrcSettingsOnOffLock ? eWrcEnableOnoff : undefined,
      eWrcEnableMode: enableWrcSettingsModeLock ? eWrcEnableMode : undefined,
      eWrcEnableSetpoint: enableWrcSettingsSetpointChangeLock ? eWrcEnableSetpoint : undefined,
      eWrcEnableCoolMode: enableWrcSettingsLimitCoolingSetpoint ? eWrcEnableCoolMode : undefined,
      eWrcEnableHeatMode: enableWrcSettingsLimitHeatingSetpoint ? eWrcEnableHeatMode : undefined
      // eWrcEnableAutoMode   // not allowed
    };
    setDirty(false);
    setEWrcLock({ id: unitId, data })
      .then((unitA: any) => updateStoreUnit({ id: unitId, data }))
      .catch((error: any) => {
        setDirty(true);
        addMessage({ message: error.message });
      });
  };

  const valueLabelFormat = (value: number) => {
    const valueToShow = value + (temperatureScale === 1 ? " °C" : " °F");
    return `${valueToShow}`;
  };

  const handleCoolRange = (event: any, newValue: number | number[]) => {
    setUnit({ ...unit, eWrcTemperatureLimits: { ...tempLimits, 0: newValue } });
    !dirty && setDirty(true);
  };

  const handleHeatRange = (event: any, newValue: number | number[]) => {
    setUnit({ ...unit, eWrcTemperatureLimits: { ...tempLimits, 1: newValue } });
    !dirty && setDirty(true);
  };

  const updateLocalSchedules = (schedule: any) => {
    const { id } = schedule;
    unitSchedules[id] = schedule;
    setUnitSchedules({ ...unitSchedules });
    schedules[id] = schedule;
    setSchedules({ ...schedules });
  };

  const handleScheduleEnable = (schedule: any) => {
    const { id, isDisabled } = schedule;
    updateSchedule({
      id,
      data: {
        isDisabled: !isDisabled
      }
    })
      .then((updatedSchedule: any) => {
        updateLocalSchedules({ ...schedule, isDisabled: updatedSchedule.isDisabled });
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      });
  };

  const deleteSelectedSchedule = (scheduleId: string) => {
    deleteSchedule(scheduleId)
      .then(() => {
        delete unitSchedules[scheduleId];
        setUnitSchedules({ ...unitSchedules });
        delete schedules[scheduleId];
        setSchedules({ ...schedules });
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      ).finally(() => {
        setToDeleteItem(null);
      });
  };

  const handleDeleteClose = () => {
    setToDeleteItem(null);
  };

  const disabled = !canUpdate;
  const onToggleAllLocks = () => {
    toggleEWrcLock({
      units: [unit.id],
      isEWrcDisable: !isEWrcDisabled
    });

    setIsEWrcDisabled(!isEWrcDisabled);
  };
  return (
    <>
      <TopBar leftAction={() => history.push("/dashboard")}
        leftIconComponent={<MobileLogo />}
        screenTitle="wrc"
        hideOutdoor
        hideBsBox
        hideIndoor
        hideOther
      />
      {!unitId || _.isEmpty(unit) ? <FilterRequire type={t`unit`} /> : (

        <div className={classes.root}>
          <div>
            <Typography className={classes.mainSectionTitle}><SettingsAlert className={classes.alertIcon} />{t`Restrict Local Controller`}</Typography>
            {disableMessage && <Typography className={classes.errorLabelStyle}>{disableMessage}</Typography>}
            <div style={{ padding: "0 10px", display: "flex", flexFlow: "column nowrap", flex: 1, minWidth: "100%", alignItems: "center" }}>
              {enableWrcSettingsRestrictionsLock &&
                <Typography style={{ borderBottom: "ridge" }} className={classes.rowSectionTitle}>{t`Enable Restrictions`}
                  <Switch
                    checked={!isEWrcDisabled}
                    disableRipple={true}
                    switchChange={onToggleAllLocks}
                    value="checkedA"
                    disabled={disableMessage || disabled}
                  />
                </Typography>}
              {isEWrcDisabled ? <Typography className={classes.title}>
                {t`Restrictions Are Disabled`}</Typography>
                : <>
                  {enableWrcSettingsOnOffLock &&
                    <Typography className={classes.rowSectionTitle}>{t`Restrict On/OFF`}
                      <Switch
                        checked={eWrcEnableOnoff}
                        disableRipple={true}
                        switchChange={() => {
                          setUnit({ ...unit, eWrcEnableOnoff: !eWrcEnableOnoff });
                          !dirty && setDirty(true);
                        }}
                        value="checkedA"
                        disabled={disableMessage || disabled}
                      />
                    </Typography>}
                  {enableWrcSettingsModeLock &&
                    <Typography className={classes.rowSectionTitle}>{t`Restrict Mode setting`}
                      <Switch
                        checked={eWrcEnableMode}
                        disableRipple={true}
                        switchChange={() => {
                          setUnit({ ...unit, eWrcEnableMode: !eWrcEnableMode });
                          !dirty && setDirty(true);
                        }}
                        value="checkedA"
                        disabled={disableMessage || disabled}
                      />
                    </Typography>}
                  {enableWrcSettingsSetpointChangeLock &&
                    <Typography className={classes.rowSectionTitle}>{t`Restrict Setpoint Change`}
                      <Switch
                        checked={eWrcEnableSetpoint}
                        disableRipple={true}
                        switchChange={() => {
                          setUnit({ ...unit, eWrcEnableSetpoint: !eWrcEnableSetpoint });
                          !dirty && setDirty(true);
                        }}
                        value="checkedA"
                        disabled={disableMessage || disabled}
                      />
                    </Typography>}

                  {enableWrcSettingsLimitCoolingSetpoint &&
                    <div className={classes.controlSectionContainer}>
                      <div className={classes.titleContainer}>
                        <Typography className={classes.controlSectionTitle}>
                          <ColdBlue className={classes.coldIcon} />
                          {t`Min/max Setpoint cooling`}
                        </Typography>
                        <Switch
                          checked={eWrcEnableCoolMode}
                          disableRipple={true}
                          switchChange={() => {
                            setUnit({ ...unit, eWrcEnableCoolMode: !eWrcEnableCoolMode });
                            !dirty && setDirty(true);
                          }}
                          value="checkedA"
                          disabled={disableMessage || disabled}
                        />
                      </div>
                      <div className={classes.sliderHolder}>
                        <Slider
                          ref={(el) => slidersRef.current[0] = el}
                          classes={{
                            root: classes.sliderRoot,
                            valueLabel: classes.sliderValueLabel,
                            rail: classes.sliderRail
                          }}
                          className={classes.coolSlider}
                          onChange={handleCoolRange}
                          aria-labelledby="discrete-slider-always"
                          min={temperatureScale === 1 ? 0 : 32}
                          max={temperatureScale === 1 ? 50 : 122}
                          value={tempLimits[0]}
                          valueLabelDisplay="on"
                          valueLabelFormat={valueLabelFormat}
                          step={1}
                          disabled={!eWrcEnableCoolMode || !!disableMessage || disabled}
                        />
                      </div>
                    </div>}
                  {enableWrcSettingsLimitHeatingSetpoint &&
                    <div className={classes.controlSectionContainer}>
                      <div className={classes.titleContainer}>
                        <Typography className={classes.controlSectionTitle}>
                          <HotRed className={classes.coldIcon} style={{ transform: "scale(1.778)" }} />
                          {t`Min/max Setpoint heating`}
                        </Typography>
                        <Switch
                          checked={eWrcEnableHeatMode}
                          disableRipple={true}
                          switchChange={() => {
                            setUnit({ ...unit, eWrcEnableHeatMode: !eWrcEnableHeatMode });
                            !dirty && setDirty(true);
                          }}
                          value="checkedA"
                          disabled={disableMessage || disabled}
                        />
                      </div>
                      <div className={classes.sliderHolder}>
                        <Slider
                          ref={(el) => slidersRef.current[1] = el}
                          classes={{
                            root: classes.heatSliderRoot,
                            valueLabel: classes.sliderValueLabel,
                            rail: classes.sliderRail
                          }}
                          className={classes.heatSlider}
                          onChange={handleHeatRange}
                          aria-labelledby="discrete-slider-always"
                          min={temperatureScale === 1 ? 0 : 32}
                          max={temperatureScale === 1 ? 50 : 122}
                          value={tempLimits[1]}
                          valueLabelDisplay="on"
                          valueLabelFormat={valueLabelFormat}
                          step={1}
                          disabled={!eWrcEnableHeatMode || !!disableMessage || disabled}
                        />
                      </div>
                    </div>}
                  <Button style={{ minHeight: 50, margin: "20px 0px", width: "calc(100% - 20px)" }} disabled={disableMessage || !dirty || disabled} onClick={saveUnit}>{t`Save`}</Button>
                </>}
            </div>
          </div>
          {!isEWrcDisabled && <div className={classes.schedulesSection}>
            <Typography className={classes.mainSectionTitle}>
              <SchedulesCalendar className={classes.alertIcon} />
              {t`Schedules`}
              <IconButton className={classes.btnIcon} disableRipple disabled={!!disableMessage || !canCreateSchedules} onClick={() => openAddEditSchedulePopup("new")}>
                <AddIcon className={classes.addIcon} color={theme.overrides.MuiButton.outlinedPrimary.color} />
              </IconButton>
            </Typography>
            {Object.values(unitSchedules).map((schedule: any) => {
              const {
                id,
                name,
                days,
                powerOnTime,
                powerOffTime,
                eWrcEnableMode,
                eWrcEnableOnoff,
                eWrcEnableSetpoint,
                eWrcEnableCoolMode,
                eWrcEnableHeatMode,
                eWrcTemperatureLimits,
                isDisabled,
                scheduleCategory,
                dates
              } = schedule;

              return (
                <div key={t`schedule-${id}`} className={classes.scheduleContainer}>
                  <div className={classes.scheduleTitleContainer}>
                    <Typography className={classes.scheduleName}>{name}</Typography>
                    <div className={classes.actionsContainer}>
                      <Edit style={{ marginRight: 30 }} onClick={() => openAddEditSchedulePopup(id)} />
                      <Delete style={{ marginRight: 25 }} onClick={() => !canDeleteSchedules ? {} : setToDeleteItem(schedule)} />
                      <Switch
                        checked={!isDisabled || !canUpdateSchedules}
                        disableRipple={true}
                        switchChange={() => handleScheduleEnable(schedule)}
                        value="checkedA"
                      />
                    </div>
                  </div>
                  <Typography className={classes.timeLabel}>
                    {minsToTime(powerOnTime, timeFormat).replace(/am/g, "AM").replace(/pm/g, "PM")}
                    {" "}-{" "}
                    {minsToTime(powerOffTime, timeFormat).replace(/am/g, "AM").replace(/pm/g, "PM")}</Typography>
                  {scheduleCategory === scheduleCategories.weekly ? <DaysList
                    days={weekDaysOptions}
                    activeDays={days}
                    action={() => { }}
                    isMobile
                    disablePointer
                    white={false}
                  />
                    : <DatesList dates={dates} />
                  }
                  <div style={{ marginBottom: 11 }}></div>
                  <div className={classes.enablesContainer}>
                    {(!eWrcEnableOnoff && enableWrcSettingsOnOffLock) &&
                      <Typography className={classes.enablesText}>{t`Restrict On/OFF`}</Typography>
                    }
                    {(!eWrcEnableMode && enableWrcSettingsModeLock) &&
                      <Typography className={classes.enablesText}>{t`Restrict Mode setting`}</Typography>
                    }
                    {(!eWrcEnableSetpoint && enableWrcSettingsSetpointChangeLock) &&
                      <Typography className={classes.enablesText}>{t`Restrict Setpoint Change`}</Typography>
                    }
                  </div>

                  {(eWrcEnableCoolMode && enableWrcSettingsLimitCoolingSetpoint) &&
                    <div className={classes.modesContainer}>
                      <div className={classes.titleContainer} style={{ margin: 0 }}>
                        <Typography className={classes.controlSectionTitle}>
                          <ColdBlue className={classes.coldIcon} />
                          {t`Min/max Setpoint cooling`}
                        </Typography>
                      </div>
                      <Typography className={classes.degreeLabel}>
                        {valueLabelFormat(eWrcTemperatureLimits[0][0])}
                        {" "}-{" "}
                        {valueLabelFormat(eWrcTemperatureLimits[0][1])}</Typography>
                    </div>
                  }

                  {(eWrcEnableHeatMode && enableWrcSettingsLimitHeatingSetpoint) &&
                    <div className={classes.modesContainer}>
                      <div className={classes.titleContainer} style={{ margin: 0 }}>
                        <Typography className={classes.controlSectionTitle}>
                          <HotRed className={classes.coldIcon} style={{ transform: "scale(1.778)" }} />
                          {t`Min/max Setpoint heating`}
                        </Typography>
                      </div>
                      <Typography className={classes.degreeLabel}>
                        {valueLabelFormat(eWrcTemperatureLimits[1][0])}
                        {" "}-{" "}
                        {valueLabelFormat(eWrcTemperatureLimits[1][1])}</Typography>
                    </div>
                  }

                </div>
              );
            })
            }
          </div>}
        </div>)}
      {(selectedScheduleId === "new" || (selectedScheduleId && unitSchedules[selectedScheduleId])) &&
        <AddEditSchedule
          capabilityFlags={capabilityFlags}
          onClose={() => openAddEditSchedulePopup("")}
          schedule={unitSchedules[selectedScheduleId]}
          scheduleId={selectedScheduleId}
          itemType={"unit"}
          itemId={unitId}
          updateLocalSchedules={updateLocalSchedules}
          item={unit}
          canEdit={canUpdateSchedules}
        />}

      {toDeleteItem && (
        <DeleteDialog
          open={!!toDeleteItem}
          type={t`Schedule`}
          object={{ id: toDeleteItem.id, name: toDeleteItem.name + t` Schedule` }}
          detach={deleteSelectedSchedule}
          onClose={handleDeleteClose}
        />)}

    </>);
};
export default UnitManagement;
