import { Reporting as sdkReporting } from "coolremote-sdk";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import GraphPlaceHolder from "../Reports/GraphPlaceHolder";
import useStyles from "./heatmap.style";
import { getDayStartEndTimeStampOfUTCDate } from "../../services/timeService";

const weekArr: any = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thur",
  "Fri",
  "Sat"
];

const graphOptions: any = {
  tooltip: {
    enabled: false
  },
  chart: {
    height: 350,
    width: "80%",
    type: "heatmap",
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ["#008FFB"],
  xaxis: {
    labels: {
      show: true
    },
    categories: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]

  }
};

const HeatMap = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { unitId: unitUrl }: any = useParams();
  const { unitId } = useStoreState((state) => state.selections.selections);
  const { updateSelections } = useStoreActions((actions) => actions.selections);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (unitUrl) {
      updateSelections({ type: "unit", data: unitUrl });
      return;
    }

  }, [unitUrl]);

  const selectUnit = (unitId: any) => {
    history.push(`/unit-usage/${unitId || ""}`);
  };

  useEffect(() => {
    if (!unitId) {
      setData([]);
      return;
    }
    setIsLoading(true);

    const startDate = moment().subtract(3, "month").toDate();
    const endDate = moment().toDate();

    const startTimeStamp = getDayStartEndTimeStampOfUTCDate(startDate);
    const endTimeStamp = getDayStartEndTimeStampOfUTCDate(endDate, "end");

    sdkReporting.getHeatMap(unitId, startTimeStamp, endTimeStamp)
      .then((resp: any) => {
        const dataArr = [];
        for (let d in resp) {
          const obj: any = {};
          obj.name = weekArr[d];
          obj.data = [];

          for (let h in resp[d]) {
            const item = resp[d][h];
            obj.data.push((item.numOfSamplesOn / item.numOfSamples) * 100);
          }
          dataArr.push(obj);
        }
        setData(dataArr);
      })
      .finally(() => setIsLoading(false));

  }, [unitId]);

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          screenTitle="unitSettings"
          hideOutdoor
          hideBsBox
          hideIndoor
          hideOther
          countControlUnits
          onUnitSelect={selectUnit}
          onSystemSelect={() => selectUnit("")}
          onSiteSelect={() => selectUnit("")}

        />
        <div
          className={classes.content}
        >
          {isLoading && <div className={classes.spinnerContainer}>
            <div id="loader" className="redMainLoader" />
          </div>
          }

          <div className={classes.graphContainer}>
            {!data?.length ?
              <GraphPlaceHolder type={"unit"} /> :
              <ReactApexChart options={graphOptions} series={data} type="heatmap" height={350} />}
          </div>

        </div>

      </div>

    </div>
  );
};
export default HeatMap;
