import React from 'react';

function SvgQuestionMark(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <defs>
        <path id="Question_Mark_svg__a" d="M0 0h20v20H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="Question_Mark_svg__b" fill="currentColor">
          <use xlinkHref="#Question_Mark_svg__a" />
        </mask>
        <path
          d="M13.69 8.34c-.185.382-.483.773-.893 1.172l-.969.901c-.275.262-.466.535-.573.812-.107.278-.167.63-.177 1.058h-2.41c0-.82.092-1.468.277-1.941.187-.474.487-.887.902-1.235.416-.349.733-.667.948-.957.217-.288.325-.608.325-.955 0-.848-.365-1.273-1.095-1.273-.337 0-.61.125-.818.37-.207.246-.317.581-.329 1.003H6.03c.012-1.123.367-1.998 1.062-2.625.696-.625 1.673-.94 2.933-.94 1.252 0 2.223.288 2.91.868.688.58 1.033 1.402 1.033 2.469 0 .468-.093.891-.278 1.273m-2.667 7.53c-.3.267-.675.4-1.125.4-.45 0-.825-.133-1.125-.4-.3-.27-.451-.61-.451-1.025 0-.415.151-.757.451-1.023.3-.269.675-.4 1.125-.4.45 0 .825.131 1.125.4.3.266.45.608.45 1.023 0 .415-.15.755-.45 1.025M10 0C4.475 0 0 4.475 0 9.998 0 15.523 4.475 20 10 20c5.523 0 10-4.477 10-10.002C20 4.475 15.523 0 10 0"
          fill="#AAA2AA"
          mask="url(#Question_Mark_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgQuestionMark;
