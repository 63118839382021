import { Dialog, DialogContentText, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { isEmail } from "react-multi-email";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { UserIdentifierChangeRequiredStyles } from "./UserIdentifierChangeRequired.style";

const UserIdentifierChangeRequired = () => {
  const currentUserEmail: string = useStoreState((s) => s.currentUserEmail);
  const isUsernameNotEmail = useStoreState((s) => s.isUsernameNotEmail);
  const checkUsernameAvailability = useStoreActions((actions) => actions.users.checkUsernameAvailability);
  const setIsUsernameNotEmail = useStoreActions((actions) => actions.setIsUsernameNotEmail);
  const updateMe = useStoreActions((action) => action.users.updateMe);

  const [newUserName, setNewUserName] = useState<string>(currentUserEmail || "");
  const [errMessage, setErrMessage] = useState<string | null>(null);

  const useStyles = makeStyles(UserIdentifierChangeRequiredStyles);
  const classes = useStyles();

  const onClick = async () => {
    const userNameExist = await checkUsernameAvailability(newUserName);
    if (!userNameExist?.available) {
      setErrMessage(t`E-mail address is already in use by another user.`);
      return;
    }

    updateMe({ username: newUserName })
      .then((re: any) => {
        setIsUsernameNotEmail(false);
      })
      .catch((e: any) => {
        setErrMessage(e.message);
      });
  };

  return (
    <Dialog
      open={isUsernameNotEmail}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.dialogStyle }}
    >
      <DialogContentText className={classes.contentStyle}>
        {t`New Username Policy`}
      </DialogContentText>
      <Typography>{t`A new user management policy has been introduced to our Apps, requiring our users to set their user name in a valid email format.`}</Typography>
      <Typography>{t`Please enter a valid unique email address to be used as your user name login credentials.`}</Typography>
      <div className={classes.form}>
        <TextField
          variant="outlined"
          placeholder={t`Enter E-mail`}
          label={t`E-mail`}
          value={newUserName}
          onChange={(e: any) => {
            setNewUserName(e.target.value);
            setErrMessage(null);
          }}
          error={!!newUserName && !isEmail(newUserName) || !!errMessage}
          helperText={errMessage}
        />
        <Button disabled={!isEmail(newUserName)} onClick={onClick} variant="contained" className={classes.submitButton}>
          {t`Submit`}
        </Button>
      </div>
    </Dialog>
  );
};

export default UserIdentifierChangeRequired;
