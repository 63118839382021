import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  row: {
    borderBottom: "1px solid lightgrey",
    display: "flex",
    alignItems: "center",
    height: 38
  },
  rowInfo: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 116px)"
  },
  rowTitle: {
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)",
    width: "5.8rem",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  infoContainer: {
    height: "100%",
    width: "auto",
    overflow: "auto",
    flexFlow: "column nowrap"
  },

  description: {
    backgroundColor: "#f4f4f4",
    marginTop: "2rem",
    height: "75px",
    color: "#545964",
    fontFamily: "Roboto",
    overflow: "auto",
    padding: "7px",
    marginBottom: 20
  },
  descriptionTitle: {
    fontWeight: 500,
    margin: "3px 0"
  },
  resolveDescription: {
    marginTop: "1rem",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  resolvedInfo: {
    border: "1px solid grey",
    borderRadius: "3px",
    marginBottom: "5px",
    width: "100%",
    padding: "5px"
  },
  saveBtn: { alignSelf: "flex-end", width: "40%", marginTop: "5px" },
  resolveTitle: {
    fontWeight: 500,
    alignSelf: "flex-start"
  },
  btn: {
    marginTop: 10,
    marginRight: 10
  },
  dialogTitle: {
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  clearButton: {
    width: "40px",
    height: "40px"
  },
  alertHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  emailDialogStyle: {
    padding: 0,
    minWidth: "400px",
    width: "400px",
    minHeight: "300px",
    height: "auto",
    display: "flex",
    flexFlow: "column nowrap",
    maxHeight: "400px"
  },
  emailDialogActions: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "30px"
  },
  emailDialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "20px",
    maxHeight: "300px",
    overflow: "auto",
    minHeight: "235px"
  },
  sendBtn: {
    width: "100px"
  },
  emailPart: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    justifyContent: "space-between !important",
    alignItems: "center"
  },
  deleteDialog: {
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },

  deleteDialogContent: {
    padding: "25px",
    paddingBottom: 0,
    height: "100%",
    color: theme.overrides.MuiButton.contained.color,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal"
  },
  deleteContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  deleteNoBtn: {
    width: "9rem",
    height: "2.625rem",
    border: "none"
  },
  deleteYesBtn: {
    color: "#4b1c46",
    width: "9rem",
    height: "2.625rem",
    border: "1px solid #421a40"
  },
  deleteDialogActions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "1rem"
  },
  title: {
    backgroundColor: "#f6f6f7",
    color: "#4b1c46",
    borderBottom: "1px solid #d5d2d5",
    padding: "4px 24px"
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  alertInfoDialogPaper: {
    width: "540px"
  },
  cancelFilter: { marginRight: "22px" },
  ackButtonContainer: {
    marginTop: "20px",
    marginBottom: "40px"
  },
  ackButton: {
    // marginLeft: "20px"
  },
  ackButtonLable: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center"
  },
  ackButtonText: {
    fontSize: "16px",
    color: "#545964",
    textTransform: "capitalize"
  },
  ackButtonIcon: {
    fontSize: "19px",
    marginRight: "7px",
    color: theme.palette.primary.dark
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  ackActionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "end"
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "center"
  },
  dialogContent: {
    flex: "1 1 auto",
    overflowY: "auto",
    paddingBottom: 0,
    fontSize: "18px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "25px",
    color: theme.overrides.MuiButton.contained.color
  },
  addTopMargin: {
    marginTop: "1rem"
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  smallDialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto"
  },
  troubleshoot: {
    color: "#545964",
    textDecoration: "underline",
    margin: "3px 0",
    fontWeight: 500,
    textAlign: "right",
    cursor: "pointer"
  },
  descriptionStyle: {
    minHeight: "38px",
    display: "flex",
    flexFlow: "column nowrap",
    borderBottom: "1px solid lightgrey",
    padding: "10px 0px"
  },
  descriptionText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: 1.57,
    letterSpacing: "normal",
    color: "#545964"
  },
  descriptionHeader: {
    fontFamily: "RobotoMedium",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "14px",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: 10
  },
  ackButton1: {
    height: "40px !important",
    borderRadius: "6px !important",
    border: "solid 1px #413044 !important",
    width: "140px",
    margin: "5px"
  },
  addMargin: {
    marginTop: 20
  },

  ////////////////////////////
  header: {
    width: "100%",
    height: "80px",
    padding: "15px 20px 14px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerActions: {
    alignSelf: "flex-start"
  },
  headerInfo: {
    display: "flex",
    flexDirection: "column"
  },
  alertTypeStyle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#fff",
    // maxWidth: "30ch",
    overflow: "hidden",
    display: "-webkit-box",
    wordBreak: "break-word",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  },
  alertIdStyle: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#fff"
  },
  content: {
    padding: "0 20px",
    position: "relative"
  },

  desc: {
    padding: "25px 0",
    color: "#545964"
  },
  descTitle: {
    fontSize: "15px",
    marginBottom: "5px",
    fontWeight: 500
  },
  descInfo: {
    fontWeight: "normal",
    fontSize: "16px",
    '& strong': {
      fontWeight: "bold"
    }
  },
  ruleName: {
    fontWeight: "bold",
    fontSize: "16px",
    cursor: "pointer",
    textDecoration: "underline"
  },
  detailsContainer: {
    marginTop: "2rem",
    marginBottom: "1rem"
  },

  infoRow: {
    justifyContent: "space-between",
    color: "#545964",
    fontSize: "14px",
    display: "flex"
  },
  line: {
    borderBottom: "1px solid #ebebeb",
    marginBottom: "15px",
    width: "48%"
  },
  infoGroup: {
    borderBottom: "1px solid #ebebeb",
    padding: "15px 0",
    width: "48%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  infoTitle: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#545964",
    fontSize: "14px",
    fontFamily: "RobotoBold"
  },
  infoVal: {
    fontWeight: "normal",
    fontFamily: "Roboto",
    lineHeight: "normal"
  },
  SYSTEM_ERROR: {
    backgroundColor: "#ef3b2f"
  },
  INDOOR_ERROR: {
    backgroundColor: "#ff78ff"

  },
  MAINTENANCE: {
    backgroundColor: "#fcbc00"

  },
  NOT_CONNECTED: {
    backgroundColor: "#35a8e0"

  },
  ANOMALIES: {
    backgroundColor: "#9f45e4"

  },
  openBtn: {
    background: "transparent",
    borderColor: "#fff",
    "&:hover": {
      background: "transparent"
    },
    "&:focus": {
      background: "transparent"
    }
  },
  resolveField: {
    borderColor: "#d5d2d5",
    color: "#545964",
    "&:hover": {
      borderColor: "#d5d2d5"
    }
  },
  ackResolveField: {
    margin: "15px 0",
    borderColor: "#d5d2d5",
    color: "#545964",
    "&:hover": {
      borderColor: "#d5d2d5"
    }
  },
  saveResolveBtn: {
    position: "absolute",
    right: "28px",
    bottom: "10px",
    background: "transparent",
    border: `1px solid #413044`,
    borderRadius: "4px",
    height: "36px",
    width: "94px",
    minWidth: "94px",
    color: "#413044",
    "&:hover": {
      background: "transparent"
    },
    "&:focus": {
      background: "transparent"
    }
  },
  cleanFilterBtn: {
    background: "transparent",
    border: `1px solid #413044`,
    borderRadius: "4px",
    height: "47px",
    width: "160px",
    minWidth: "94px",
    color: "#413044",
    "&:hover": {
      background: "#dadada",
      borderColor: "#d2d0d0",
      color: "#fff"
    },
    "&:active": {
      background: "#dadada",
      borderColor: "#d2d0d0",
      color: "#fff"
    },
    "&:focus": {
      background: "#dadada",
      borderColor: "#d2d0d0",
      color: "#fff"
    },
    "&:disabled": {
      background: "#dadada",
      borderColor: "rgba(0,0,0,0.2)"
    }
  },
  cleanFilterBtnPopup: {
    background: "transparent",
    color: "#413044",
    "&:hover": {
      background: "#d2d0d0",
      borderColor: "#d2d0d0",
      color: "#fff"
    },
    "&:focus": {
      background: "transparent"
    },
    "&:disabled": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  alertActions: {
    marginTop: "60px",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: "15px"
  },
  wrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowY: "auto"
  },
  statusBox: {
    fontSize: "20px",
    color: "#fff"
  },
  pdfDoc: {
    overflowY: "auto",
    height: "calc(100vh - 240px)",
  },
  pdfPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "column",
  },
  popupActions: {
    marginTop: "30px",
    justifyContent: "flex-end"
  },
  multiAction: {
    justifyContent: "space-between"
  },
  clearFiltersContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px"
  },
  longInfoRow: {
    width: "100%",
    border: 0
  },
  resolveDescExist: {
    "&>div": {
      paddingBottom: "55px"
    }
  },
  cutLongNames: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  ul: {
    marginLeft: "20px",
    listStyleType: "disc"
  },
  li: {
    paddingLeft: "10px"
  },
  ackConditionDetails: {
    fontWeight: "bold",
    color: theme.overrides.MuiButton.outlinedPrimary.color
  },
  longRow: {
    padding: "15px 0",
    color: "#545964",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column"
  },
  longRowTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "5px"
  },
  longRowVal: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    fontFamily: "Roboto",
    marginLeft: 10
  },
  timeInput: {
    color: theme.overrides.MuiButton.outlinedPrimary.color,
    border: `1px solid ${theme.overrides.MuiButton.outlinedPrimary.color}`,
    borderRadius: "6px",
    textAlign: "center",
    boxSizing: "border-box",
    height: "30px",
    width: "50px",
    fontWeight: "bold",
    marginLeft: "10px",
    "& input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      textAlign: "center",
      padding: 0
    }
  },
  ackTimeError: {
    display: "flex",
    color: "rgb(240, 83, 71)",
    fontSize: "14px",
    marginLeft: "30px",
    height: "20px"
  },
  shortBtn: {
    width: "100px !important",
    minWidth: "100px !important",
    margin: "5px"
  },
  cancelBtn: {
    width: "37%",
    minWidth: "100px",
    color: "#545964",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: 400,
    margin: "5px 0"
  },
  longBtn: {
    width: "135px",
    minWidth: "135px",
    margin: "5px"
  },
  has3Btns: {
    justifyContent: "space-around"
    // flexWrap: "wrap"
  },
  imagePreview: {
    maxWidth: "40%",
    maxHeight: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  },
  dialogStyle: {
    minHeight: "90%",
    minWidth: "90%",
    height: "100%"
  }

}));
export default useStyles;
