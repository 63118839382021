import {
  Dialog,
  IconButton,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import Button from "../../../cool_widgets/Button";
import { Close } from "../../../icons";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import { CustomSelect } from "../../../components/AddRule/CustomedComponents";
import { AlertGroup as sdkAlertGroup } from "coolremote-sdk";
import clsx from "clsx";

import styles from "./PriorityPopup.style";
import useChurnZero from "@hooks/useChurnZero";

const globalAlertsTypes: any = [
  "deviceDisconnectedEnable",
  "cleanFilterEnable",
  "indoorUnitErrorEnable",
  "outdoorUnitErrorEnable",
  "unitDisconnectedEnable",
  "sensorDisconnectedEnable",
  "powerMeterDisconnectedEnable",
  "lowLineQualityEnable",
  "scheduleFailuresEnable"
]

const globalAlertsTitles: any = {
  deviceDisconnectedEnable: t`Disconnect device alerts`,
  cleanFilterEnable: t`Clean Filter alerts (No SMS)`,
  indoorUnitErrorEnable: t`Unit indoor alerts`,
  outdoorUnitErrorEnable: t`Unit Outdoor alerts`,
  unitDisconnectedEnable: t`Unit Disconnected`,
  sensorDisconnectedEnable: t`Sensor Disconnected`,
  powerMeterDisconnectedEnable: t`Power Meter Disconnected`,
  lowLineQualityEnable: t`Line Quality alerts`,
  scheduleFailuresEnable: t`Schedule Failures`
}

//1 - high is default
const defaultSitePriorities: any = {
  deviceDisconnectedEnable: 1,
  cleanFilterEnable: 1,
  indoorUnitErrorEnable: 1,
  outdoorUnitErrorEnable: 1,
  unitDisconnectedEnable: 1,
  sensorDisconnectedEnable: 1,
  powerMeterDisconnectedEnable: 1,
  lowLineQualityEnable: 1,
  scheduleFailuresEnable: 1
}
const PriorityPopup = ({ canUpdateAlertGroups }: any) => {
  const classes = styles();

  const types = useStoreState((s) => s.types);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { siteId } = useStoreState((state) => state.selections.selections);

  const { trapPriorityTypes } = types;
  const trapPriorityOptions = Object.keys(trapPriorityTypes).reduce((obj: any, priority: any) => {
    obj.push({ name: trapPriorityTypes[priority], value: +priority });
    return obj;
  }, []);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>({});

  const {trackEvent} = useChurnZero()

  const originalTableData = useRef({})

  useEffect(() => {
    if (!siteId) {
      return;
    }
    setTableData({});
    sdkAlertGroup
      .getSiteGlobalAlertsPriorities(siteId)
      .then((result: any) => {
        const priorities: any = { ...defaultSitePriorities, ...result?.globalAlertsPriorities };
        setTableData(priorities)
        originalTableData.current = priorities
      })

  }, [siteId])

  const save = () => {
    trackEvent('NotificationsSettingsSetGlobalPriorities','Global setting update to notification group were set');
    sdkAlertGroup
      .updateSiteGlobalAlertsPriorities(siteId, { globalAlertsPriorities: tableData })
      .then(() => setOpenDialog(false))
      .catch((err: any) => addMessage({ message: err.message }))
  }


  /**
   * A function to handle the cancel button click event.
   * The function resets the Table data to it's original state and closes the popup
   */
  const handleCancelClick = () => {
    //reset all fields.
    setTableData(originalTableData.current)
    //close the dialog
    setOpenDialog(false)
  }

  const handleOnChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableData({ ...tableData, [fieldName]: event.target.value });
  };

  return (
    <>
      <Button
        disabled={!canUpdateAlertGroups}
        onClick={(_: any) => setOpenDialog(true)}
        width={150}
        white
         /*style={{ backgroundColor: "transparent" }}*/ >
        {t`Global alert priorities`}
      </Button>

      {openDialog &&
        <Dialog
          disableEnforceFocus
          fullScreen={true}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="simple-dialog-title"
          open={openDialog}
        >
          <div className={classes.dialogHeader}>
            <Typography className={classes.headerTitle}>{t`Global alert priorities`}</Typography>
            <IconButton
              disableRipple
              className={classes.iconBtnStyle}
              onClick={() => setOpenDialog(false)}
            >
              <Close color="#7f7692" />
            </IconButton>
          </div>
          <div style={{ flex: 1, padding: "20px" }}>

            <div className={classes.container}>
              <div className={clsx(classes.subContainer, classes.overWritecontainerPadding)}>
                <Typography>
                  {globalAlertsTitles.indoorUnitErrorEnable}
                </Typography>

                <CustomSelect
                  disableTooltip
                  value={tableData.indoorUnitErrorEnable}
                  options={trapPriorityOptions}
                  onChange={(event: any) => setTableData({ ...tableData, indoorUnitErrorEnable: event.target.value })}
                />
              </div>
            </div>

            <div className={classes.container}>
              <div className={clsx(classes.subContainer, classes.overWritecontainerPadding)}>
                <Typography>
                  {globalAlertsTitles.outdoorUnitErrorEnable}
                </Typography>
                <CustomSelect
                  disableTooltip
                  value={tableData.outdoorUnitErrorEnable}
                  options={trapPriorityOptions}
                  onChange={(event: any) => setTableData({ ...tableData, outdoorUnitErrorEnable: event.target.value })}
                />
              </div>
            </div>

            <div className={classes.container}>
              <Typography>
                {t`Communication`}
              </Typography>
              <div className={classes.subContainer}>
                <Typography>
                  {globalAlertsTitles.deviceDisconnectedEnable}
                </Typography>

                <CustomSelect
                  disableTooltip
                  value={tableData.deviceDisconnectedEnable}
                  options={trapPriorityOptions}
                  onChange={(event: any) => setTableData({ ...tableData, deviceDisconnectedEnable: event.target.value })}
                />
              </div>
              <div className={classes.subContainer}>
                <Typography>
                  {globalAlertsTitles.unitDisconnectedEnable}
                </Typography>

                <CustomSelect
                  disableTooltip
                  value={tableData.unitDisconnectedEnable}
                  options={trapPriorityOptions}
                  onChange={(event: any) => setTableData({ ...tableData, unitDisconnectedEnable: event.target.value })}
                />
              </div>
              <div className={classes.subContainer}>
                <Typography>
                  {globalAlertsTitles.sensorDisconnectedEnable}
                </Typography>
                <CustomSelect
                  disableTooltip
                  value={tableData.sensorDisconnectedEnable}
                  options={trapPriorityOptions}
                  onChange={handleOnChange('sensorDisconnectedEnable')}
                />
              </div>
              <div className={classes.subContainer}>
                <Typography>
                  {globalAlertsTitles.powerMeterDisconnectedEnable}
                </Typography>
                <CustomSelect
                  disableTooltip
                  value={tableData.powerMeterDisconnectedEnable}
                  options={trapPriorityOptions}
                  onChange={handleOnChange('powerMeterDisconnectedEnable')}
                />
              </div>
            </div>

            <div className={classes.container}>
              <Typography>
                {t`Maintenance`}
              </Typography>
              <div className={classes.subContainer}>
                <Typography>
                  {globalAlertsTitles.cleanFilterEnable}
                </Typography>

                <CustomSelect
                  disableTooltip
                  value={tableData.cleanFilterEnable}
                  options={trapPriorityOptions}
                  onChange={(event: any) => setTableData({ ...tableData, cleanFilterEnable: event.target.value })}
                />
              </div>
              <div className={classes.subContainer}>
                <Typography>
                  {globalAlertsTitles.lowLineQualityEnable}
                </Typography>

                <CustomSelect
                  disableTooltip
                  value={tableData.lowLineQualityEnable}
                  options={trapPriorityOptions}
                  onChange={(event: any) => setTableData({ ...tableData, lowLineQualityEnable: event.target.value })}
                />
              </div>
              <div className={classes.subContainer}>
                <Typography>
                  {globalAlertsTitles.scheduleFailuresEnable}
                </Typography>

                <CustomSelect
                  disableTooltip
                  value={tableData.scheduleFailuresEnable}
                  options={trapPriorityOptions}
                  onChange={(event: any) => setTableData({ ...tableData, scheduleFailuresEnable: event.target.value })}
                />
              </div>

            </div>
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={handleCancelClick}
              marginRight
              white
              width={150}
            >
              {t`cancel`}
            </Button>
            <Button disabled={!siteId} onClick={save} width={150}>
              {t`save`}
            </Button>
          </div>

        </Dialog>}
    </>
  );
};

export default PriorityPopup;
