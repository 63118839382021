export const symbols: any = {
  1: "°",
  2: " ppm",
  3: " rpm",
  4: "",
  5: "%",
  6: " 𝜇g/m³",
  7: " mm wc",
  8: " Inch wc",
  9: " Psi",
  10: " Bar",
  11: " Pa",
  12: " kPa",
  13: " CFM",
  14: "% RH",
  15: " GPM"
};

export default { symbols };
