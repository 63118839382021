import { t } from "ttag";

interface IAuditAction {
    [action: string]: string;
}

const AuditActions: IAuditAction = {
    rootCreateCustomer: t`Created Root Customer`,
    setAcknowledgedEvent: t`Acknowledge Alert`,
    setAutoAcknowledgedEvent: t`Auto Acknowledge Alert`,
    cancelAutoAcknowledgedEvent: t`Cancel Auto Acknowledge Alert`,
    refreshDevice: t`Refresh Device`,
    setUnAcknowledgedEvent: t`Cancel Acknowledge Alert`,
    createCustomer: t`Created Customer`,
    updateCustomer: t`Updated Customer`,
    deleteCustomer: t`Deleted Customer`,
    createCustomerUser: t`Created Customer User`,
    rootCreateCustomerUser: t`Created Root Customer User`,
    createSite: t`Created Site`,
    updateSite: t`Updated Site`,
    deleteSite: t`Deleted Site`,
    createSiteUser: t`Created Site User`,
    createDevice: t`Created Device`,
    updateDevice: t`Updated Device`,
    deleteDevice: t`Deleted Device`,
    createGroup: t`Created Group`,
    updateGroup: t`Updated Group`,
    deleteGroup: t`Deleted Group`,
    setGroupSetpoint: t`Set Group Setpoint`,
    setGroupOperationStatus: t`Set Group Operation Status`,
    setGroupOperationMode: t`Set Group Operation Mode`,
    setGroupFanMode: t`Set Group Fan Mode`,
    setGroupSwingMode: t`Set Group Swing Mode`,
    createAlertGroup: t`Created Alert Group`,
    updateAlertGroup: t`Updated Alert Group`,
    deleteAlertGroup: t`Deleted Alert Group`,
    createAlertGroupUser: t`Created Alert Group User`,
    updateAlertGroupUser: t`Updated Alert Group User`,
    deleteAlertGroupUser: t`Deleted Alert Group User`,
    updateUnit: t`Updated Unit`,
    deleteUnit: t`Deleted Unit`,
    clearUnitFilter: t`Clear unit filter`,
    setUnitSetpoint: t`Set Unit Setpoint`,
    setUnitOperationStatus: t`Set Unit Operation Status`,
    setUnitOperationMode: t`Set Unit Operation Mode`,
    setUnitFanMode: t`Set Unit Fan Mode`,
    setUnitSwingMode: t`Set Unit Swing Mode`,
    autoIndoorUnitMapping: t`Automatic indoor unit mapping`,
    setSystemOperationMode: t`Set System Operation Mode`,
    setSystemOperationStatus: t`Set System Operation Status`,
    createSchedule: t`Created Schedule`,
    updateSchedule: t`Updated Schedule`,
    deleteSchedule: t`Deleted Schedule`,
    createInvite: t`Created Invite`,
    acceptInvite: t`Accepted Invite`,
    deleteInvite: t`Deleted Invite`,
    updateUser: t`Updated User`,
    deleteUser: t`Deleted User`,
    updateUserPassword: t`Updated User Password`,
    createTrap: t`Created Anomaly Trigger`,
    updateTrap: t`Updated Anomaly Trigger`,
    deleteTrap: t`Deleted Anomaly Trigger`,
    createTrigger: t`Created Anomaly Trigger`,
    updateTrigger: t`Updated Anomaly Trigger`,
    deleteTrigger: t`Deleted Anomaly Trigger`,
    createTriggerTemplate: t`Created Trigger Template`,
    updateTriggerTemplate: t`Updated Trigger Template`,
    deleteTriggerTemplate: t`Deleted Trigger Template`,
    createSystem: t`Created System`,
    removeSystemUnit: t`Removed System Unit`,
    addSystemUnit: t`Added System Unit`,
    addPoliciesToInvite: t`Added Policies To Invite`,
    updateSystem: t`Updated System`,
    setUnitLock: t`Set Unit Lock`,
    deleteEvent: t`Deleted Alert`,
    setUnitServiceParam: t`Set Service Parameter`,
    eWrcTStatLocks: t`Set unit EWrc restrictions`,
    createProcedure: t`Create procedure`,
    updateProcedure: t`Update procedure`,
    runProcedure: t`Run procedure`,
    stopProcedure: t`Stop procedure`,
    rerunProcedure: t`Re-run procedure`,
    deleteProcedure: t`Delete procedure`,
    deleteProcedureReport: t`Delete procedure report`,
    unitLimitsScheduleOn: t`unit app restrictions schedule start`,
    unitLimitsScheduleOff: t`unit app restrictions schedule end`,
    eWrcLimitsScheduleOn: t`unit WRC restrictions schedule start`,
    eWrcLimitsScheduleOff: t`unit WRC restrictions schedule end`,
    unitResetWorkingHours: t`Reset unit working hours`,
    setWaterHeaterSetpoint: t`Set water Heater Setpoint`,
    bootDevice: t`Boot Device`,
    createReportSchedule: t`Create Report Schedule`,
    setSensorValue: t`Set sensor value`,
    setWaterHeaterOperationStatus: t`Set Heater Operation Status`,
    uploadFile: t`Upload file`,
    deleteZone: t`Deleted Zone`,
    createZone: t`Created Zone`,
    createProfile: t`Created Profile`,
    updateProfile: t`Updated Profile`,
    createUserGroup: t`Created User Group`,
    updateUserGroup: t`Updated User Group`,
    deleteUserGroup: t`Deleted User Group`,
    deleteProfile: t`Deleted Profile`,
    createUser: t`Created User`,
    createAutomationLogic: t`Created Automation Logic`,
    updateAutomationLogic: t`Updated Automation Logic`,
    deleteAutomationLogic: t`Deleted Automation Logic`,
    setSystemSetpoint: t`Set System Setpoint`,
    setSystemFanMode: t`Set System Fan Mode`,
    setSystemSwingMode: t`Set System Swing Mode`,
    addOperationalAlertGroupContact: t`Add Operational Alert Group Contact`,
    assignPowerMeterToSystem: t`Assign Power Meter To System`,
    createOperationalAlertGroup: t`Create Operational Alert Group`,
    createUnitLimitSchedule: t`Create Unit Limit Schedule`,
    createWrcLockSchedule: t`Create Wrc Lock Schedule`,
    deleteReportSchedule: t`Delete Report Schedule`,
    deleteUnitLimitSchedule: t`Delete Unit Limit Schedule`,
    disableEWrcTStatLocks: t`Disable EWrc TStat Locks`,
    enableEWrcTStatLocks: t`Enable EWrc Tstat Locks`,
    removeOperationalAlertGroupContact: t`Remove Operational Alert Group Contact`,
    setSystemSetPoint: t`Set System Setpoint`,
    updateOperationalAlertGroupContact: t`Update Operational Alert Group Contact`,
    createContact: t`Create Contact`,
    updateZone: t`Updated Zone`,
    updateUnitLimitSchedule: t`Updated Unit Limit Schedule`,
    updateWrcLockSchedule: t`Updated Wrc Lock Schedule`,
    deleteWrcLockSchedule: t`Deleted Wrc Lock Schedule`,
    updateEvent: t`Updated Event`,
    deleteContact: t`Delete Contact`,
    deleteSystem: t`Deleted System`,
    login: t`Login`,
    closeEvent: t`Close Event`,
    createPolicy: t`Created Policy`,
    deletePolicy: t`Deleted Policy`,
    deletePoliciesFromInvite: t`Deleted Policies From Invite`,
    createPowerMeter: t`Created Power Meter`,
    createSmartRule: t`Created Smart Rule`,
    createSystemsChangeover: t`Created Systems Changeover`,
    createTimer: t`Created Timer`,
    deleteSmartRule: t`Deleted Smart Rule`,
    deleteSystemsChangeover: t`Deleted Systems Changeover`,
    reportingSiteSystemsDiversity: t`Reporting Site Systems Diversity`,
    updateAlertGroupContacts: t`Updated Alert Group Contacts`,
    updatePowerMeter: t`Updated Power Meter`,
    updateReportSchedule: t`Updated Report Schedule`,
    updateSmartRule: t`Updated Smart Rule`,
    updateSystemsChangeover: t`Updated Systems Changeover`,
    setConfigParamValue: t`Set Config Param Value`,
    setUnitTempCurve: t`Set Unit Temp Curve`
};

export default AuditActions