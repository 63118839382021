import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, TextField } from '@material-ui/core';
import { Check, Cancel } from '@material-ui/icons';

/**
 * EditInPlaceInput component that displays an input field with save and reset buttons.
 * Buttons are only visible when the input field has been edited.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.value - The initial value of the input field.
 * @param {Function} props.onSave - Callback function to handle saving the new value.
 * @returns {JSX.Element} The rendered EditInPlaceInput component.
 */
const EditInPlaceInput = ({ value: initialValue, onSave }) => {
  const [value, setValue] = useState(initialValue);
  const [isEdited, setIsEdited] = useState(false);

  // Update local state if the `initialValue` prop changes
  useEffect(() => {
    setValue(initialValue);
    setIsEdited(false); // disable buttons after saving
  }, [initialValue]);

  /**
   * Handles input changes and updates the edited state.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  const handleInputChange = (event) => {
    setValue(event.target.value);
    setIsEdited(event.target.value !== initialValue); // enable buttons if edited
  };

  /**
   * Saves the current value by calling the onSave callback.
   */
  const handleSave = () => {
    onSave(value);
  };

  /**
   * Resets the input field to its initial value.
   */
  const handleReset = () => {
    setValue(initialValue);
    setIsEdited(false); // disable buttons
  };

  return (
    <Box display="flex" alignItems="center">
      <TextField
        value={value}
        onChange={handleInputChange}
        variant="outlined"
        size="small"
      />
      {isEdited && (
        <>
          <IconButton color="primary" onClick={handleSave}>
            <Check />
          </IconButton>
          <IconButton color="secondary" onClick={handleReset}>
            <Cancel />
          </IconButton>
        </>
      )}
    </Box>
  );
};

EditInPlaceInput.propTypes = {
  /** The initial value of the input field. */
  value: PropTypes.string,

  /** Callback function to handle saving the new value. */
  onSave: PropTypes.func,
};

EditInPlaceInput.defaultProps = {
  value: '', // Default value as an empty string
  onSave: () => {}, // Default onSave as a no-op function
};

export default EditInPlaceInput;
