import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  datePickerContainer: {
    // padding: 20,
    minWidth: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    top: 0,
    margin: 0,
    borderRadius: 0,
    // justifyContent: "space-between",
    background: "white",
    alignItems: "center",
    maxWidth: "100vw"
  },
  groupButtons: {
    height: 50,
    boxShadow: "0 3px 6px -6px #aaa2aa",
    "&:hover": {
      backgroundColor: "#edebed"
    }
  },
  groupsWidth: {
    width: "100%"
  },
  selectedBtn: {
      backgroundColor: "#edebed"
    },
    iosTopHeight: {
      paddingTop: "40px",
      minHeight: "100px"
    },
    header: {
      borderBottom: "solid 1px #d5d2d5",
      backgroundColor: "#f6f6f7",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      height: 65,
      alignItems: "center",
      paddingLeft: 20,
      paddingRight: 10
    }
});
export default useStyles;
