import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { isIOS } from 'react-device-detect';
import { t } from 'ttag';
import { Close } from '@icons';
import { useStoreState } from '../../../models/RootStore';
import useStyles from './AuditInfo.style';
import AuditActions from '../../../screens/Audits/AuditActions';

interface AuditInfoProps {
  audit: any; // Replace `any` with a specific type if possible
  close: () => void;
  auditActors: { [key: string]: { username: string } };
}

const dataPropertiesReplacement: { [key: string]: string } = {
  isRegistered: 'Connected',
  isExpired: 'Expired'
};

const notReadable = [
  'name', 'customer', 'isAcceptedTOU', '_id', '__v', 'user', 'userLocationsMetadata',
  'password', 'randId', 'id', 'device', 'admin', 'lat', 'lon', 'fileId', 'type', 'site'
];

const getState = (state: any) => {
  return {
    allUnits: state.units.allUnits,
    getSite: state.sites.getSite,
    allDevices: state.devices.allDevices,
    allCustomers: state.customers.allCustomers,
    timeFormat: state.users.timeFormat,
    types: state.types
  }
}

const AuditInfo: React.FC<AuditInfoProps> = ({ audit, close, auditActors }) => {
  const classes = useStyles();
  const {
    allUnits,
    getSite,
    allDevices,
    allCustomers,
    timeFormat,
    types
  } = useStoreState(getState);

  const { operationStatuses, operationModesExtended, fanModes, swingModes, temperatureScale, actionSources } = types;
  const { source, sourceType, customer, action: actionId, date, actor: user, site, device, unit, line, data } = audit;

  const getTimeFromMins = (mins: number): string => {
    const h = Math.floor(mins / 60);
    const m = mins % 60;
    return moment.utc().hours(h).minutes(m).format('hh:mm A');
  };

  const valueMapping = (value: number, action: string): any => {
    const lowerCaseAction = action.toLowerCase();
    if (lowerCaseAction.includes('setpoint')) return value;
    if (lowerCaseAction.includes('operationstatus')) return operationStatuses[value] || value;
    if (lowerCaseAction.includes('operationmode')) return operationModesExtended[value] || value;
    if (lowerCaseAction.includes('fanmode')) return fanModes[value] || value;
    if (lowerCaseAction.includes('swingmode')) return swingModes[value] || value;
    return value;
  };

  const valueTitleMapping = (action: string): string => {
    const lowerCaseAction = action.toLowerCase();
    if (lowerCaseAction.includes('setpoint')) return t`Setpoint`;
    if (lowerCaseAction.includes('operationstatus')) return t`Operation Status`;
    if (lowerCaseAction.includes('operationmode')) return t`Operation Mode`;
    if (lowerCaseAction.includes('fanmode')) return t`Fan Mode`;
    if (lowerCaseAction.includes('swingmode')) return t`Swing Mode`;
    return t`value`;
  };

  const replaceTitle = (property: string): string => {
    return dataPropertiesReplacement[property] || property.replace(/([A-Z])/g, ' $1').trim();
  };

  const auditSource = (source: number): string => {
    switch (source) {
      case +actionSources.user:
        return t`Application User`;
      case +actionSources.schedule:
        return t`Scheduled Operation`;
      case +actionSources.homeAutomation:
        return t`Home automation / BMS`;
      case +actionSources.hvac:
        return t`HVAC`;
      case +actionSources.applicationLogic:
        return t`Application Logic`;
      case +actionSources.ecobee:
        return t`Ecobee`;
      default:
        return t`Unknown source`;
    }
  };

  const DataRow: React.FC<{ property: string; content: any; action: string }> = ({ property, content, action }) => {
    const contentText = typeof content === 'boolean' ? (content ? 'yes' : 'no') : content;
    return (
      <div className={classes.row}>
        <Typography className={classes.title}>
          {property === 'value' ? valueTitleMapping(action) : replaceTitle(property)}
        </Typography>
        <Typography className={classes.data}>
          {property === 'value'
            ? `${valueMapping(contentText, action)}`
            : property === 'temperatureScale'
              ? `${temperatureScale[contentText]}`
              : `${contentText}`}
        </Typography>
      </div>
    );
  };

  const convertDate = (stringDate: string): string => {
    const timezone = getSite(site)?.timezone || '';
    return timezone ? moment(stringDate).tz(timezone).format(`ll ${timeFormat}`) : moment(stringDate).format(`ll ${timeFormat}`);
  };

  return (
    <div className={classes.infoDialogStyle}>
      <div id="title" className={clsx(classes.dataContainer, { [classes.iosTopHeight]: isIOS })}>
        <Typography className={classes.details}>{t`Audit Data`}</Typography>
        <IconButton disableRipple onClick={close}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.rowsContainer}>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Source`}</Typography>
          <Typography className={classes.data}>{auditSource(source)}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Source Type`}</Typography>
          <Typography className={classes.data}>{sourceType}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Customer`}</Typography>
          <Typography className={classes.data}>{allCustomers[customer]?.name}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Action`}</Typography>
          <Typography className={classes.data}>{AuditActions[actionId] || actionId}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Date`}</Typography>
          <Typography className={classes.data}>{convertDate(date)}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`User`}</Typography>
          <Typography className={classes.data}>{auditActors[user]?.username || '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Site`}</Typography>
          <Typography className={classes.data}>{getSite(site)?.name || '-'}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Device`}</Typography>
          <Typography className={classes.data}>{allDevices[device]?.name}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title}>{t`Unit`}</Typography>
          <Typography className={classes.data}>{allUnits[unit]?.name}</Typography>
        </div>
        {line && (
          <div className={classes.row}>
            <Typography className={classes.title}>{t`Line`}</Typography>
            <Typography className={classes.data}>{line}</Typography>
          </div>
        )}
        {data && Object.keys(data).map((key) => {
          let content = data[key];
          if (typeof content !== 'string' && typeof content !== 'number' && typeof content !== 'boolean') {
            return null;
          }
          if (notReadable.includes(key)) {
            return null;
          }
          if (typeof content === 'number' && (key === 'powerOffTime' || key === 'powerOnTime')) {
            content = getTimeFromMins(content);
          }
          return <DataRow key={`audit-data-${key}`} property={key} content={content} action={actionId} />;
        })}
      </div>
    </div>
  );
};

export default AuditInfo;
