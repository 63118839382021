import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import {t} from "ttag";
// import { Calendar } from "../../svgComponents";
import {  Calendar } from "../../../icons/";

import datePickerStyle from "./datePicker.style";

interface IPickerProps {
  date?: any;
  onSet?: any;
  disabled?: boolean;
}

const CustomDatePicker: React.FC<IPickerProps> = (props: any) => {
  const classes = datePickerStyle();
  const now = new Date();
  const { onSet, date, disabled } = props;
  const [selectedDate, handleDateChange] = useState<any>(null);

  useEffect(() => {
    if (!selectedDate && date) {
      handleDateChange(new Date(date * 1000 - now.getTimezoneOffset() * 60000));
    }
  }, [date, now, selectedDate]);

  const onAccept = (date: any) => {
    const midnightDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const localSeconds = midnightDate.getTime() / 1000;
    const utcSeconds = localSeconds + midnightDate.getTimezoneOffset() * 60;

    onSet && onSet(utcSeconds);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.maxWidth}>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          placeholder={t`Experation Date`}
          InputProps={{
            classes: {
              root: classes.rootField,
              disabled: classes.disabledInput,
              notchedOutline: classes.notchedOutline
            },
            startAdornment: <Calendar className={classes.inputAdornment} color="#aaa2aa"/>
          }}
          disabled={disabled}
          onAccept={onAccept}
          inputVariant="outlined"
          disablePast
          disableToolbar
          format="dd/MM/yyyy"
          error={false}
          helperText=""
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
