import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  dataContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "60px",
    borderBottom: "solid 1px #e5e2e5",
    padding: "0 20px",
    justifyContent: "space-between"
  },
  iosTopHeight: {
    paddingTop: "40px",
    minHeight: "100px"
  },
  row: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    padding: "0 20px",
    borderBottom: "1px solid #efeff2",
    height: 50
  },
  infoDialogStyle: {
    padding: 0,
    minWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    bottom: 0,
    margin: 0,
    borderRadius: 0,
    paddingBottom: 20,
    background: "white"
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 15,
    textTransform: "capitalize",
    fontWeight: 500,
    width: 170,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  data: {
    fontFamily: "Roboto",
    fontSize: 15,
    color: "#545964",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  details: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 600,
    color: "#4b1c46"
  },
  closeIcon: {
    "& mask": {
      fill: "#7f7692"
    }
  },
  rowsContainer: {
    overflow: "auto"
  }
}));
export default useStyles;
