import {
  Site as siteSdk,
  BacnetDevice as bacnetDeviceSdk,
} from 'coolremote-sdk';

import { File as sdkFile } from 'coolremote-sdk';

import useQuery from './useQuery';
import useMutation from './useMutation';
import { generateRandomString } from '@utils/CommonUtils';

/**
 * Hook to create a Bacnet UI template.
 *
 * @typedef {Object} CreateBacnetTemplateData
 * @property {Object} data - The Bacnet template data.
 * @property {string} name - The name of the Bacnet template.
 * @property {string} description - The description of the Bacnet template.
 * @property {string} site - The site to which the Bacnet template belongs.
 * @property {string[]} [bacnetUnits] - Optional list of Bacnet unit IDs.
 *
 * @returns {Object} mutation function with status and error
 */
export const useCreateBacnetUiTemplate = () => {
  return useMutation({
    mutationFn: async ({
      data,
      name,
      description,
      site,
      paramTemplate,
      fileId,
      bacnetUnits,
    }) => {
      return bacnetDeviceSdk.createBacnetUiTemplate({
        data,
        name,
        description,
        site,
        paramTemplate,
        fileId,
        bacnetUnits
      });
    },
  });
};

/**
 * Hook to get a Bacnet UI template by ID.
 *
 * @param {string} templateId - The ID of the Bacnet UI template.
 * @returns {Object} data, error, loading status, and refetch function
 */
export const useGetBacnetUiTemplate = (templateId) => {
  return useQuery({
    queryKey: ['bacnetUiTemplate', templateId],
    queryFn: async () => {
      return bacnetDeviceSdk.getBacnetUiTemplateById(templateId);
    },
    enabled: !!templateId,
  });
};

/**
 * Hook to update a Bacnet UI template.
 *
 * @typedef {Object} UpdateBacnetTemplateData
 * @property {string} templateId - The ID of the Bacnet UI template to update.
 * @property {Object} data - The new Bacnet template data.
 * @property {string} [name] - Optional new name for the template.
 * @property {string} [description] - Optional new description for the template.
 * @property {string[]} [bacnetUnits] - Optional list of Bacnet unit IDs.
 *
 * @returns {Object} mutation function with status and error
 */
export const useUpdateBacnetUiTemplate = () => {
  return useMutation({
    mutationFn: async ({
      templateId,
      payload,
    }) => {
      return bacnetDeviceSdk.updateBacnetUiTemplate(templateId, payload);
    },
  });
};

/**
 * Hook to delete a Bacnet UI template by ID.
 *
 * @param {string} templateId - The ID of the Bacnet UI template to delete.
 * @returns {Object} mutation function with status and error
 */
export const useDeleteBacnetUiTemplate = () => {
  return useMutation({
    mutationFn: async (templateId) => {
      return bacnetDeviceSdk.deleteBacnetUiTemplate(templateId);
    },
  });
};

/**
 * Hook to add a Bacnet unit to a Bacnet UI template.
 *
 * @typedef {Object} AddBacnetUnitData
 * @property {string} templateId - The ID of the Bacnet UI template.
 * @property {string} bacnetUnitId - The ID of the Bacnet unit to add.
 *
 * @returns {Object} mutation function with status and error
 */
export const useAddBacnetUnitToTemplate = () => {
  return useMutation({
    mutationFn: async ({ templateId, bacnetUnitId }) => {
      return bacnetDeviceSdk.addBacnetUnitToTemplate(templateId, bacnetUnitId);
    },
  });
};

/**
 * Hook to remove a Bacnet unit from a Bacnet UI template.
 *
 * @typedef {Object} RemoveBacnetUnitData
 * @property {string} templateId - The ID of the Bacnet UI template.
 * @property {string} bacnetUnitId - The ID of the Bacnet unit to remove.
 *
 * @returns {Object} mutation function with status and error
 */
export const useRemoveBacnetUnitFromTemplate = () => {
  return useMutation({
    mutationFn: async ({ templateId, bacnetUnitId }) => {
      return bacnetDeviceSdk.removeBacnetUnitFromTemplate(
        templateId,
        bacnetUnitId
      );
    },
  });
};

/**
 * Custom hook to upload a BACnet UI template image.
 *
 * @returns {Mutation} A mutation object from useMutation hook.
 */
export const useUploadBacnetUiTemplateImg = () => {
  return useMutation({
    mutationFn: async ({ filePayload }) => {
      const formData = new FormData();
      const uploadedFile = new File(
        [filePayload.file],
        generateRandomString() + '-' + filePayload.file.name,
        { type: filePayload.file.type }
      );
      formData.append('file', uploadedFile);
      const imgId = await sdkFile.uploadFile(formData);
      return imgId;
    },
  });
};

/**
 * Hook to get Bacnet UI templates for a specific site.
 *
 * @param {string} siteId - The ID of the site.
 * @returns {Object} data, error, loading status, and refetch function
 */
export const useGetSiteBacnetUiTemplates = (siteId) => {
  return useQuery({
    queryKey: ['siteBacnetUiTemplates', siteId],
    queryFn: async () => {
      return siteSdk.getSiteBacnetUiTemplates(siteId);
    },
    enabled: !!siteId,
  });
};
