import React from 'react';

const SvgSystemOff = props => (
	<svg width={43} height={43} {...props}>
		<defs>
			<circle id="systemOff_svg__b" cx={17.5} cy={17.5} r={17.5} />
			<filter
				id="systemOff_svg__a"
				width="137.1%"
				height="137.1%"
				x="-18.6%"
				y="-18.6%"
				filterUnits="objectBoundingBox"
			>
				<feMorphology
					in="SourceAlpha"
					operator="dilate"
					radius={0.5}
					result="shadowSpreadOuter1"
				/>
				<feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
				<feGaussianBlur
					in="shadowOffsetOuter1"
					result="shadowBlurOuter1"
					stdDeviation={2}
				/>
				<feComposite
					in="shadowBlurOuter1"
					in2="SourceAlpha"
					operator="out"
					result="shadowBlurOuter1"
				/>
				<feColorMatrix
					in="shadowBlurOuter1"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
				/>
			</filter>
		</defs>
		<g fill="none" fillRule="evenodd">
			<g transform="translate(4 4)">
				<use
					fill="#000"
					filter="url(#systemOff_svg__a)"
					xlinkHref="#systemOff_svg__b"
				/>
				<use fill="#FFF" stroke="#D5D2D5" xlinkHref="#systemOff_svg__b" />
			</g>
			<path
				d="M16.846 15.9c-1.916 1.389-3.166 3.625-3.166 6.156 0 4.217 3.456 7.636 7.719 7.636s7.718-3.419 7.718-7.636c0-2.53-1.25-4.767-3.165-6.157m-4.727-2.219v7.592"
				stroke="#AAA2AA"
				strokeLinecap="round"
				strokeWidth={1.5}
			/>
		</g>
	</svg>
);

export default SvgSystemOff;
