/* eslint-disable react/display-name */
import React, { memo, useMemo } from 'react';
import { t as translate } from 'ttag';
import { TextField, InputAdornment } from '@material-ui/core';
import { isEmpty, map } from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search as SearchIcon } from '@material-ui/icons';
import useConfirmationDialog from '@components/ConfirmationDialogV2';

import useStyles from './styles';

// Separate the data points list into its own component
const DataPointsList = memo(
  ({
    onClickDataPoint,
    paramTemplates,
    selectedParamTemplate,
    onSelectParamTemplate,
  }) => {
    const [ showConfirmation, ConfirmationDialog ] = useConfirmationDialog();
    const classes = useStyles();
    const paramTemplateData = useMemo(() => {
      return paramTemplates[selectedParamTemplate]?.data
        ? map(
          Object.keys(paramTemplates[selectedParamTemplate].data),
          (dataPoint) => {
            return {
              id: dataPoint,
              ...paramTemplates[selectedParamTemplate].data[dataPoint],
            };
          }
        )
        : [];
    }, [paramTemplates, selectedParamTemplate]);

    const handleSelectParamTemplate = async (newValue) => {
      const result = await showConfirmation({
        title: translate`Confirm changing param template?`,
        bodyMessage: translate`Changing param template will reset control mapping?`,
        confirmText: translate`Confirm`,
        cancelText: translate`Cancel`,
      });

      if(result) {
        onSelectParamTemplate(newValue.id);
      }
    };

    return (
      <div className={classes.dataPoints}>
        <div className={classes.dataPoints__section}>
          <div className={classes.dataPoints__section__instructions}>
            {translate`Select param template`}
          </div>
          <div
            className={classes.dataPoints__section__content}
            style={{
              padding: '0 37px 16px 37px',
            }}
          >
            {Object.keys(paramTemplates).length > 0 && (
              <Autocomplete
                className={classes.customAutocompleteField}
                fullWidth
                options={Object.values(paramTemplates)}
                getOptionLabel={(option) => option.name}
                onChange={(_, newValue) => {
                  handleSelectParamTemplate(newValue);
                }}
                value={paramTemplates[selectedParamTemplate]}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    placeholder={translate`Search...`}
                    {...params}
                    variant="outlined"
                    className={classes.customTextField}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ fill: '#aaa2aa' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
          </div>
        </div>
        {!isEmpty(paramTemplateData) && (
          <div className={classes.dataPoints__section}>
            <div className={classes.dataPoints__section__instructions}>
              {translate`Drag or Select data points`}
            </div>
            <div className={classes.dataPoints__section__content}>
              <div className={classes.dataPoints__list}>
                {paramTemplateData.map((dataPoint) => (
                  <div
                    key={dataPoint.id}
                    className={classes.dataPoints__listItem}
                    onClick={() => onClickDataPoint(dataPoint)}
                  >
                    {dataPoint.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <ConfirmationDialog />
      </div>
    );
  }
);

export default DataPointsList;
