let conditionUtils = {
  generateId: (() =>  {
    let idCounter = 0;

    const incrementCounter = function() {
      ++idCounter;
      return idCounter;
    };

    incrementCounter.reset = () => idCounter = 0;

    return incrementCounter;

  })()
};
export default conditionUtils;
