import { makeStyles, Popover } from "@material-ui/core";
import clsx from "clsx";
import {
  addDays,
  differenceInDays,
  endOfMonth,
  startOfMonth,
  sub
} from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker as ReactRangePicker } from "react-date-range";
import { t } from "ttag";
import { useStoreState } from "../../models/RootStore";
import CButton from "../Button";
import styles from "./dateRangePicker.style";

const DateRangePicker = ({ date, setDate, disabled = false, autoSelectMonth = false , placeholder, className }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { dateFormat } = useStoreState((state) => state.users);

  const [anchorEl, setAnchorEl] = useState(null);

  const computedPlaceholder = placeholder ?? "Select Period"

  return (
    <>
      <div className={clsx(className,classes.dateRangeLabel, disabled && classes.disabled)} onClick={(event: any) => {
        if (disabled) {
          return;
        }
        setAnchorEl(event.currentTarget);
      }}>
        {!date ? computedPlaceholder :
          `${moment(date.startDate).format(dateFormat)} > ${moment(date.endDate).format(dateFormat)}`}
      </div>
      <Popover
        id={"graph-date-picker"}
        className={classes.container}
        open={!!anchorEl}
        style={{ overflow: "unset" }}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Picker date={date} setDate={setDate} onClose={() => setAnchorEl(null)} autoSelectMonth={autoSelectMonth} />
      </Popover>
    </>
  );
};

const Picker = ({ date, setDate, onClose, autoSelectMonth }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const defaultDates = {
    start: startOfMonth(sub(new Date(), { months: 1 })),
    end: endOfMonth(sub(new Date(), { months: 1 }))
  };

  const [defaultPickerData, setDefaultPickerData] = useState<any>({
    maxDate: new Date(),
    shownDate: date ? date.startDate : new Date(new Date().setMonth(new Date().getMonth() - 1))
  });
  const [dateSelection, setDateSelection] = useState<any>({
    key: "selection",
    startDate: date ? date.startDate : defaultDates.start,
    endDate: date ? date.endDate : defaultDates.end
  });

  return (
    <div className={classes.rangePickerContainer}>
      <ReactRangePicker
        onChange={(item: any) => {
          const range = item.selection;
          let { startDate, endDate, key } = range;

          if (differenceInDays(endDate, startDate) > 31) {
            endDate = addDays(startDate, 31);
          }

          setDateSelection({ startDate, endDate, key });
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        preventSnapRefocus={true}
        months={2}
        direction={"horizontal"}
        ranges={[dateSelection]}
        maxDate={defaultPickerData.maxDate}
        staticRanges={[]}
        inputRanges={[]}
        shownDate={defaultPickerData.shownDate}
        onShownDateChange={(date: any, cal: any) => {
          if (!autoSelectMonth) {
            return;
          }
          const startDate = startOfMonth(date);
          const endDate = endOfMonth(date);

          setDateSelection({ startDate, endDate, key: "selection" });
        }}
      />
      <div className={classes.calendarButtons}>
        <CButton
          onMouseUp={onClose}
          white width={100} marginRight>
          {t`Cancel`}
        </CButton>
        <CButton
          width={100}
          onMouseUp={() => {
            setDate({ startDate: dateSelection.startDate, endDate: dateSelection.endDate });
            onClose();
          }}
        >
          {t`Done`}
        </CButton>
      </div>
    </div>
  );
};

export default DateRangePicker;
