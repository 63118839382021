import { Divider, IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Bar, Cell, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { t } from "ttag";
import DateRangePicker from "../../cool_widgets/DateRangePicker";
import { CoolSmall, HeatSmall, Maximize, ZoomOut } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import GraphPlaceHolder from "./GraphPlaceHolder";

const graphConfig: any = {
  "on_minutes_cool": { name: "cool", color: "#35a8e0" },
  "on_minutes_heat": { name: "heat", color: "#f05146" },
  "on_minutes_other": { name: "other", color: "#a6a6a6" }
};
const ModeReport = React.forwardRef((props: any, ref: any) => {
  const { classes, siteId, setIsLoading, id, maxed, maximize, updatedDate } = props;
  const firstUpdate = useRef(true);
  const [loadDataFailed, setLoadDataFailed] = useState<boolean>(false);
  const reportsData = useStoreState((state) => state.ReportsData.modeReportsData);
  const getData = useStoreActions((action) => action.ReportsData.getModeGraphData);
  const setInfo = useStoreActions((action) => action.ReportsData.setModeGraphData);
  const tempMirror = useStoreState((state) => state.temperatureScaleMirror);
  const user: any = useStoreState((state) => state.users.me);
  const isC = +user?.temperatureScale === +tempMirror?.celsius;
  const { date, info, data } = reportsData;
  const tempUnit = isC ? t`°C` : t`°F`;

  useEffect(() => {
    updatedDate && setInfo({ date: updatedDate });
  }, [updatedDate, siteId]);

  useEffect(() => {
    setLoadDataFailed(false);
    //prevent firing at first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!siteId || !date) {
      return;
    }
    setIsLoading(true);
    getData({ date, site: siteId })
      .catch(() => {
        setLoadDataFailed(true);
      })
      .finally(() => setIsLoading(false));
  }, [date, siteId]);

  const getHoursVal = (item: any, name: string) => {
    const value = Math.round((item[name].val / 60) * 10) / 10;
    return value;
  };

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active) {

      const data = payload?.reduce((result: any, item: any) => {
        result.total = Math.round((item.payload.on_minutes / 60) * 10) / 10;
        result.cool = Math.round((item.payload["on_minutes_cool"] / 60) * 10) / 10;
        result.heat = Math.round((item.payload["on_minutes_heat"] / 60) * 10) / 10;
        result.other = Math.round((item.payload["on_minutes_other"] / 60) * 10) / 10;
        result.max = Math.round(item.payload[isC ? "maxTemp" : "maxTempF"] * 10) / 10;
        result.min = Math.round(item.payload[isC ? "minTemp" : "minTempF"] * 10) / 10;
        return result;

      }, { cool: null, heat: null, other: null, total: null, min: null, max: null });
      return (
        <div className={classes.graphTooltip} >
          <Typography>
            {moment.utc(label).format("ddd MMM Do, YYYY")}
          </Typography>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              marginBottom: "5px"
            }}>
            <div
              style={{
                width: "26px",
                height: "20px",
                marginRight: "12px"
              }}
            >

              <CoolSmall />
            </div>
            <br />
            <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>{`${data.cool} Hrs`}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              marginBottom: "5px"
            }}>
            <div
              style={{
                width: "26px",
                height: "20px",
                marginRight: "12px"
              }}
            >
              <HeatSmall />
            </div>
            <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>{`${data.heat} Hrs`}</Typography>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              marginBottom: "5px"
            }}>
            <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>{`Other ${data.other} Hrs`}</Typography>
          </div>
          <Divider
            style={{
              width: "100%", height: "1px", backgroundColor: "#d5d2d5", margin: "6px 0"
            }} />

          <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>{`Max Temp ${data.max} ${tempUnit}`}</Typography>
          <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>{`Min Temp ${data.min} ${tempUnit}`}</Typography>
          <Divider
            style={{
              width: "100%", height: "1px", backgroundColor: "#d5d2d5", margin: "6px 0"
            }} />
          <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>{`Total: ${data.total} Hrs`}</Typography>
        </div >
      );
    }

    return null;
  };

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize="12px" fontWeight="bold">
          {moment.utc(payload.value).format("D")}
        </text>
      </g>
    );
  };
  const isMaxed = id === maxed;

  return (
    <div className={clsx(classes.reportContainer, isMaxed && classes.maxedContainer)} id={!data.length ? "modeXX" : "modeGraphId"}>
      <div className={classes.reportContainerHeader}>
        <Typography className={classes.reportTitle}>
          {t`Overall Cooling & Heating hours`}
        </Typography>

        <div className={classes.headerMid}>
          <DateRangePicker disabled={!siteId} date={date} setDate={(date: any) => setInfo({ date })} autoSelectMonth={true} />
        </div>
        <div className={classes.reportHeaderActions}>
          <IconButton onClick={() => maximize(id)} data-html2canvas-ignore="true">
            {isMaxed ? <ZoomOut /> : <Maximize />}
          </IconButton>
        </div>
      </div>
      <div className={classes.reportContainerBody} style={{ overflow: "hidden", justifyContent: "space-around" }}>
        {!!data.length ? (
          <>
            <ResponsiveContainer width="100%" height={"75%"}>
              <ComposedChart
                data={data}
                width={500}
                height={300}
                margin={{
                  top: 20,
                  right: 10,
                  left: 10,
                  bottom: 10
                }}
              >
                <XAxis
                  dataKey="datestamp"
                  tick={<CustomizedAxisTick />}
                  interval={0}
                />
                {data?.length && <YAxis
                  yAxisId="left"
                  dataKey={(item: any) => Math.round((item["on_minutes"] / 60) * 10) / 10}
                  unit={"Hr"}
                  tick={{ fontSize: "10px" }}
                />}
                {data?.length && <YAxis
                  yAxisId="right"
                  domain={["dataMin - 2", "dataMax + 2"]}
                  unit={isC ? "°C" : "°F"}
                  orientation="right"
                  tickFormatter={(value: any) => Math.round(value * 10) / 10}
                  tick={{ fontSize: "10px" }}
                />}
                {data?.length && <Tooltip content={<CustomTooltip />} cursor={{ fill: "rgba(128,128,128,0.1)" }} />}
                <Bar
                  dataKey={(item: any) => getHoursVal(item, "a")}
                  stackId="a"
                  yAxisId="left"

                >
                  {
                    data.map((entry: any) =>
                      <Cell key={`entry${entry.a.key}`} fill={graphConfig[entry.a.key].color} />
                    )
                  }
                </Bar>
                <Bar
                  dataKey={(item: any) => getHoursVal(item, "b")}
                  stackId="a"
                  yAxisId="left"
                >
                  {
                    data.map((entry: any) =>
                      <Cell key={`entry${entry.b.key}`} fill={graphConfig[entry.b.key].color} />
                    )
                  }
                </Bar>
                <Bar
                  dataKey={(item: any) => getHoursVal(item, "c")}
                  stackId="a"
                  radius={[6, 6, 0, 0]}
                  yAxisId="left"
                >
                  {
                    data.map((entry: any) =>
                      <Cell key={`entry${entry.c.key}`} fill={graphConfig[entry.c.key].color} />
                    )
                  }
                </Bar>
                <Line
                  dataKey={isC ? "maxTemp" : "maxTempF"}
                  type="monotone"
                  stroke="#fa6666"
                  dot={false}
                  yAxisId="right"
                  strokeWidth={1.5}
                />
                <Line
                  dataKey={isC ? "minTemp" : "minTempF"}
                  type="monotone"
                  stroke="#66fa8d"
                  dot={false}
                  yAxisId="right"
                  strokeWidth={1.5}
                />
              </ComposedChart>
            </ResponsiveContainer>
            <div className={classes.lineLegendContainer} >
              <div className={classes.lineLegendBlock}>
                <div className={classes.lineLegendColor} style={{ backgroundColor: "#f05146" }} />
                <Typography>{t`Max temp`}</Typography>
              </div>

              <div className={classes.lineLegendBlock}>
                <div className={classes.lineLegendColor} style={{ backgroundColor: "#4fff00" }} />
                <Typography>{t`Min temp`}</Typography>
              </div>

            </div>
            <div className={classes.customLegend}>
              {!!data.length &&
                <>
                  <div className={classes.legendBlock} style={{ flex: 1 }}>
                    <div className={classes.legendBlockInfo}>
                      <Typography style={{ width: "100%" }}>{t`Site Temperature`}</Typography>
                      <div className={classes.LegendTemp} >
                        <Typography><span>{t`Average`}&nbsp;</span><span className={classes.legendTempVal}>{Math.round((isC ? info.avg : info.avgF) * 10) / 10 + tempUnit}</span></Typography>
                        <Divider orientation="vertical" className={classes.legendTempDivider} />
                        <Typography><span>{t`Min`}&nbsp;</span><span className={classes.legendTempVal}>{Math.round((isC ? info.min : info.minF) * 10) / 10 + tempUnit}</span></Typography>
                        <Divider orientation="vertical" className={classes.legendTempDivider} />
                        <Typography><span>{t`Max`}&nbsp;</span><span className={classes.legendTempVal}>{Math.round((isC ? info.max : info.maxF) * 10) / 10 + tempUnit}</span></Typography>
                      </div>
                    </div>
                  </div>

                  <div className={classes.legendBlock} style={{ flex: 2 }}>
                    <div className={classes.legendBlockInfo}>
                      <Typography style={{ width: "100%" }}>{t`Running Time`}</Typography>
                      <div className={classes.LegendTemp}>
                        <Typography><CoolSmall width={12} height={13} /><span>&nbsp;{t`Cooling`}&nbsp;</span><span className={clsx(classes.purpleBold, classes.legendTempVal)}>{Math.round(info.totalCool * 10) / 10 + "Hr"}</span></Typography>
                        <Divider orientation="vertical" className={classes.legendTempDivider} />
                        <Typography><HeatSmall width={12} height={13} /><span>&nbsp;{t`Heating`}&nbsp;</span><span className={clsx(classes.purpleBold, classes.legendTempVal)}>{Math.round(info.totalHeat * 10) / 10 + "Hr"}</span></Typography>
                        <Divider orientation="vertical" className={classes.legendTempDivider} />
                        <Typography><span>{t`Other`}&nbsp;</span><span className={clsx(classes.purpleBold, classes.legendTempVal)}>{Math.round(info.totalOther * 10) / 10 + "Hr"}</span></Typography>
                        <Divider orientation="vertical" className={classes.legendTempDivider} />
                        <Typography><span>{t`Total`}&nbsp;</span><span className={clsx(classes.purpleBold, classes.legendTempVal)}>{Math.round((info.totalOther + info.totalCool + info.totalHeat) * 10) / 10 + "Hr"}</span></Typography>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </>
        ) :
          <GraphPlaceHolder loadDataFailed={loadDataFailed} />
        }
      </div>

    </div>
  );
});
export default ModeReport;
