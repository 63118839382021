import { Grid, Typography } from "@material-ui/core/";
import { AccessTime } from "@material-ui/icons/";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { symbols } from "../../../../constants/sensorsUnitsSymbols";
import { Switch } from "../../../../cool_widgets/Switch";
import { useStoreActions, useStoreState } from "../../../../models/RootStore";
import { Disconnect as DisconnectIcon } from "../../../../icons";
import useStyle from "../UnitItem/UnitItem.style";
import SensorIcon from "@icons/SensorIcon";

const hasValue = (value: any) => {
  return !!value || value === 0;
};

const SensorItem = (props: any) => {

  const types = useStoreState((state) => state.types);
  const allSensors = useStoreState((state) => state.sensors.allSensors);
  const { updateSensorValue, _storeUpdateSensor } = useStoreActions((actions) => actions.sensors);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { sensorId, siteId, selectItem, groupId, selectedItem, isCelsius } = props;
  const { sensorTypes, sensorMeasurementUnits } = types;
  const item = allSensors[sensorId] || {};
  const devices = useStoreState((state) => state.devices.allDevices);
  const classes = useStyle();

  const {
    name,
    type,
    readingValue = 0,
    userData = {},
    id,
    canControl = true,
    device,
    isConnected,
    schedules
  } = item;

  const sensorType = sensorTypes[type] || {};
  const { enableMinMaxSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin } = sensorType;
  const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
  const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
  const { rangeMax: userMax, rangeMin: userMin } = userData;
  const value = +readingValue;

  const displayValue = !sensorUnit || type === 130 || type === 7 ? (enableMinMaxSelection ? hasValue(userMax) && hasValue(userMin) ?
    (((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin : value : value) :
    sensorUnit === "Temperature" ? (isCelsius ? value : ((value * 9 / 5) + 32)) :
      sensorUnit === "Open/Close" ? type === 129 ? +value === 0 ? `OFF` : t`ON` : (+value === 1 ? t`OPEN` : t`CLOSE`) : value;

  const roundedValue = _.isNumber(displayValue) ? (Math.round(displayValue * 100) / 100) : displayValue;
  const selected = !selectedItem.isGroup && selectedItem.sensorId === sensorId && selectedItem.groupId === groupId;

  const deviceIsConnected = devices[device] && devices[device].isConnected;
  const isDisconnected = !deviceIsConnected || !isConnected;

  const handleOnOff = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const updateValueTo = +value === 0 ? 1 : 0;
    updateSensorValue({ id, data: { value: updateValueTo } })
      .then(() => {
        _storeUpdateSensor({ id, data: { readingValue: updateValueTo } });
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });

  };

  const switchClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };


  if (isDisconnected) {
    return (
      <div className={classes.unitStyle}>
        <div className={classes.locSec}>
          <Typography className={classes.nameStyle2Lines}>{name}</Typography>
        </div>
        <div className={classes.disconnectedContainer}>
          <DisconnectIcon className={classes.disconnectedIconStyle} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.unitStyle, { [classes.selected]: selected })}
      onClick={() =>
        selectItem(siteId, { isGroup: false, sensorId, groupId, type: "sensor" })
      }
    >
      <div className={classes.locSec}>
        <Typography className={classes.nameStyle2Lines}>{name}</Typography>
      </div>
      <div className={classes.tempSec}>
        <div className={classes.iconDiv}>
          <SensorIcon smallIcon={true} type={type} width={30} height={30} readingValue={+readingValue} />
          {schedules.length > 0 && <AccessTime className={classes.noteIcon} />}
        </div>
        <Typography className={clsx(classes.tempTextSensor, { [classes.offText]: `OFF` === displayValue })}>
          {roundedValue}<span className={clsx({ [classes.specialFont]: activeMeasurementUnit === 2 || activeMeasurementUnit === 3 || activeMeasurementUnit === 6 || activeMeasurementUnit === 14 })}>{" " + symbols[activeMeasurementUnit || ""] || ""}</span>
        </Typography>
      </div>
      <Grid className={classes.onOffSensor}>
        {type === 129 &&
          <Switch
            disabled={!canControl}
            checked={value === 1}
            disableRipple={true}
            onClick={(e: any) => switchClick(e)}
            switchChange={(e: any) => handleOnOff(e)}
            value="checkedA"
          />
        }
      </Grid>
    </div>
  );
};

export default SensorItem;
