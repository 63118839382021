import { Card, createStyles, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import clsx from "clsx";
import _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { t } from "ttag";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./ApplySettings.style";

import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { ArrowDownO, CheckboxChecked, Search } from "../../icons";
import Checkbox from "./Checkbox";

declare module "csstype" {
    interface Properties {
        "--tree-view-color"?: string;
        "--tree-view-bg-color"?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon?: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    node?: any;
    className?: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: "#545964",
            fontSize: "14px",
            width: "100%",
            minHeight: "40px",
            "&:hover > $content": {
                backgroundColor: "transparent"
            },
            "&:focus > $content, &$selected > $content": {
                backgroundColor: `var(--tree-view-bg-color, #fff)`,
                color: "var(--tree-view-color)"
            },
            "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
                backgroundColor: "transparent"
            }
        },
        content: {
            color: "#545964",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            paddingRight: "10px",
            fontWeight: 400,
            "$expanded > &": {
                fontWeight: 400
            }
        },
        group: {
            borderLeft: "1px solid #d5d2d5",
            marginLeft: "31px"
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: "inherit",
            color: "inherit",
            padding: 0,
            display: "flex"
        },
        labelRoot: {
            display: "flex",
            alignItems: "center",
            padding: "9px",
            paddingLeft: 0
        },
        customerIconContainer: {
            alignItems: "center",
            width: "unset",
            "& div": { display: "none" },
            marginLeft: "10px"
        },
        labelIcon: {
            marginRight: "10px"
        },
        labelText: {
            fontWeight: "inherit",
            flexGrow: 1,
            fontSize: 15
        },
        iconContainer: {
            alignItems: "center",
            width: "unset",
            marginRight: "5px"
        },
        customerGroup: {
            borderLeft: "1px solid #d5d2d5",
            marginLeft: "21px"
        },
        customerContent: {
            backgroundColor: "rgba(170, 162, 170, 0.1)"
        },
        inputRoot: {
            fontFamily: "Roboto",
            fontSize: "13px",
            width: "300px",
            display: "flex",
            flexDirection: "row",
            height: "36px",
            borderRadius: "4px",
            padding: "0 15px",
            border: "1px solid rgba(170, 162, 170, 0.3)",
            maxWidth: "70%",
            marginRight: "40px",
            marginBottom: "10px"
        }
    })
);

const StyledTreeItemWrapper = memo(function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { className, labelText, labelIcon: LabelIcon, node, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            label={
                <div
                    className={clsx(classes.labelRoot, { [className]: className })}
                >
                    {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
                    {node && node}
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                "--tree-view-color": color,
                "--tree-view-bg-color": bgColor
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
                iconContainer: classes.iconContainer
            } as any}
            {...other}
        />
    );
});

export default memo(function NewApplySettings(props: any) {
    const localClasses = useTreeItemStyles();
    const classes = useStyles();
    const { save, disabled = false, showSiteCheckbox = false } = props;
    const getCustomerSites = useStoreState((state) => state.customers.getCustomerSites);
    const getSiteSystems = useStoreState((state) => state.sites.getSiteSystems);
    const getUnits = useStoreState((state) => state.units.getFilteredUnits);
    const selections = useStoreState((s) => s.selections.selections);
    const allUnits = useStoreState((state) => state.units.allUnits);
    const allDevices = useStoreState((state) => state.devices.allDevices);
    const allSites = useStoreState((state) => state.sites.allSites);
    const [indoorUnits, setIndoorUnits] = useState<any>([]);
    const [currentSelectedUnits, setCurrentSelectedUnits] = useState<string[]>([]);
    const [searchVal, setSearchVal] = useState<string>("");

    useEffect(() => {
        const filterType = 1;
        const indoorUnitsOnly = Object.values(allUnits).filter((unit: any) => {
            const unitDevice = allDevices[unit.device];
            if (_.isUndefined(unitDevice)) { return false; }
            const unitSite = allSites[unitDevice.site];
            if (_.isUndefined(unitSite)) { return false; }
            const unitCustomerId = unitSite.customer;
            if (_.isUndefined(unitCustomerId)) { return false; }

            return (
                unit.type === filterType &&
                (selections.customerId ? unitCustomerId === selections.customerId : true) &&
                (selections.siteId ? unitSite.id === selections.siteId : true) &&
                unitDevice.isConnected
            );
        });
        setIndoorUnits(indoorUnitsOnly);
    }, [allUnits]);

    // Tenants that have units already
    const tenantUnitsIds = _.reduce(props.allTenants, (unitsIds: string[], tenant: any) => {
        if (props.tenantId === tenant.id) {
            return unitsIds;
        }

        unitsIds.push(...tenant.units);
        return unitsIds;
    }, []);

    const Checkbox1 = (props: any) => {
        return <Checkbox
            color="default"
            edge="end"
            variant="outlined"
            onChange={() => { }}
            onClick={(event: any) => event.stopPropagation()}
            checkedIcon={<CheckboxChecked />}
            className={clsx(classes.smallCheckbox, { [props.className]: props.className })}
            {...props}
        />;
    };

    useEffect(() => {
        // Set new UI's units
        setCurrentSelectedUnits(props.units);

    }, [props.units]);

    const siteNode = () => {
        const items: any = [];
        const site: any = allSites[props.oneSite];
        if (!site) {
            return null;
        }
        const type = props.useServiceUnits ? "service" : "indoor";
        const siteUnits = getUnits("site", site.id, { type }, true).sort((a: any, b: any) => {
            let nameA = a?.name?.toUpperCase();
            let nameB = b?.name?.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        const siteUnitsIds = siteUnits.map(unit => unit.id);

        site && items.push(
            <StyledTreeItemWrapper
                key={`site-1`}
                nodeId={`site-1`}
                labelText={site.name}
                color="#545964"
                bgColor="#fff"
                node={
                    showSiteCheckbox ? <Checkbox1
                        disabled={disabled}
                        checked={currentSelectedUnits.length == siteUnitsIds.length}
                        onChange={(event: any) => {
                            if (event.target.checked) {
                                const unitsToSave = [...siteUnitsIds];
                                setCurrentSelectedUnits(unitsToSave);
                                save(unitsToSave);
                            } else {
                                const unitsToSave: any[] = [];
                                setCurrentSelectedUnits(unitsToSave);
                                save(unitsToSave);
                            }
                        }}
                    /> : null}
                classes={{ iconContainer: localClasses.customerIconContainer, group: localClasses.customerGroup, label: localClasses.label, content: localClasses.customerContent }}
            >
                {unitsNodes(siteUnits)}
            </StyledTreeItemWrapper>
        );
        return items;
    };

    const unitsNodes = (units: any) => {
        const items = [];
        for (const i in units) {
            // Avoid outdoor units

            if (!!searchVal?.length && units[i]?.name?.toUpperCase().indexOf(searchVal?.toUpperCase()) === -1) {
                continue;
            }

            if (_.includes(units[i].internalId, "_outdoor")) {
                continue;
            }

            let unit = units[i];

            if (!props.showServiceUnits) {
                unit = !props.useServiceUnits
                    ? units[i]
                    : _.find(indoorUnits, (unit) => _.find(unit.serviceUnits, (serviceUnitsId) => {
                        return serviceUnitsId === units[i].id;
                    }));
            }

            // if showing service units that doesn't have control unit - return
            if ((props.useServiceUnits && !unit) || unit.id === selections.unitId || !unit.isVisible) {
                continue;
            }

            const { id, name, system } = unit;

            const disabledCheck = disabled ? true : props.noDisable ? false : _.includes(tenantUnitsIds, id);
            unit && items.push(
                <StyledTreeItemWrapper
                    key={`unit-${id}`}
                    nodeId={`unit-${id}`}
                    labelText={name}
                    style={{ opacity: disabled ? 0.5 : 1 }}
                    node={
                        <Checkbox1
                            disabled={disabledCheck}
                            checked={currentSelectedUnits.includes(id)}
                            onChange={(event: any) => {
                                // Save new units
                                if (event.target.checked) {
                                    const unitsToSave = [...currentSelectedUnits, id];
                                    setCurrentSelectedUnits(unitsToSave);
                                    save(unitsToSave);
                                } else {
                                    const unitsAfterRemoved = currentSelectedUnits.filter((index: any) => {
                                        return id !== index;
                                    });

                                    setCurrentSelectedUnits(unitsAfterRemoved);
                                    save(unitsAfterRemoved);
                                }
                            }}
                        />}
                    color="#545964"
                    bgColor="#fff"
                />);
        }
        return items;
    };

    return (
        <Grid>
            <TextField
                placeholder={t`Search...`}
                InputProps={{
                    disableUnderline: true,
                    classes: { root: localClasses.inputRoot },
                    endAdornment: <Search />
                }}
                value={searchVal}
                onChange={(e: any) => setSearchVal(e.target.value)}
            />
            <TreeView
                defaultExpanded={["site-1"]}
                defaultCollapseIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ marginLeft: "7px", marginRight: "2px" }} /> </>}
                defaultExpandIcon={<><div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} /> <ArrowDownO style={{ transform: "rotate(-90deg)", marginLeft: "7px", marginRight: "2px" }} /></>}
                defaultEndIcon={<div style={{ minWidth: 20, height: 1, borderBottom: "1px solid #d5d2d5", marginLeft: "-1px" }} />}
            >
                {siteNode()}
            </TreeView>

        </Grid>
    );

},
    (oldProps: any, newProps: any) => {
        const isSameUnits = _.intersection(oldProps.units, newProps.units).length !== oldProps.units.length;
        const isSameTenant = oldProps.tenantId !== newProps.tenantId;

        return isSameTenant && isSameUnits;
    }

);
