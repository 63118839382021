import { useState } from 'react';

const useMutation = ({ mutationFn, onSuccess, onError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const mutate = async (data) => {
    let result;
    setIsLoading(true);
    setError(null); // Clear previous error
    try {
      result = await mutationFn(data);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (err) {
      setError(err);
      if (onError) {
        onError(err);
      }
    } finally {
      setIsLoading(false);
    }

    return result;
  };

  return { mutate, isLoading, error };
};

export default useMutation;
