import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100%"
  },
  content: {
    height: "100%",
    margin: "unset",
    padding: "1rem",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
    height: "100vh",
    width: "calc(100% - 240px)"
  },
  spinnerContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 9999,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  graphContainer: {
    margin: "unset",
    padding: "1rem",
    position: "relative",
    width: "100%"

  }
}));
export default useStyles;
