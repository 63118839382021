import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  buttonLogin: {
    minHeight: "53px",
    width: "100%",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    margin: "0.5rem 0",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "& >div": {
      width: "100%",
    },
    "& >div>div": {
      width: "100%",
    },
  },
  Login: {
    MozFlexDirection: "column",
    flexDirection: "column",
    height: "100vh",
    display: "flex",
    fontSize: "1.5rem",
    overflowY: "auto",
  },
  form: {
    width: "90%",
  },
  logos: {
    justifyContent: "center",
    display: "flex",
    MozFlexDirection: "column",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    ...theme.typography.themeTitle,
    color: theme.palette.primary.dark,
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "90%",
  },
  logo: {
    paddingBottom: "2rem",
    paddingTop: "2rem",
  },
  interactionSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingBottom: "5rem",
  },

  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    MozFlex: "1",
    flex: "1",
  },

  forgotPassBtn: {
    ...theme.link.button,
  },

  hidden: {
    display: "none",
  },

  newPasswordForm: {
    display: "inline-flex",
    flexDirection: "column",
  },

  error: {
    color: theme.errorMessages.loginForm.color,
    margin: "0.5rem 0",
  },

  textFieldInputClass: {
    margin: "0.5rem 0",
    width: "100%",
    fontSize: `${theme.textField.loginForm.fontSize}px !important`,
    color: theme.palette.primary.dark,
    border: "none",

    "& input": {
      color: theme.palette.primary.dark,
      padding: "15px ",
      height: "30px",
    },
    "& input + fieldset": {
      border: "none",
    },
    "& input:disabled + fieldset": {
      border: "none",
    },
    "& div": {
      borderRadius: "4px 4px  0 0",
    },
    "& ::placeholder": {
      color: theme.palette.primary.dark,
      opacity: 0.5,
      fontSize: "16px",
      lineHieght: "normal",
      fontWeight: "normal",
    },
    "& label": {
      color: `${theme.palette.primary.dark} !important`,
    },
  },
  select: {
    textTransform: "capitalize",
    textAlign: "left",
    margin: "0.5rem 0",
    width: "100%",
    borderBottom: "none",
    color: theme.palette.primary.dark,
    "&>svg": {
      top: "10px",
      width: "40px",
      height: "40px",
    },
  },
  selectLabel: {
    color: theme.palette.primary.dark,
    lineHeight: "normal",
    paddingTop: "5px",
    "&.Mui-focused": {
      color: theme.palette.primary.dark,
    },
  },
  menuItemsContainer: {
    maxHeight: "200px",
    transitionDuration: "0s !important",
  },
  reportProblemButton: {
    backgroundColor: theme.specialButtons.reportProblemMain.backgroundColor,
    position: "absolute",
    zIndex: 99999,
    left: "15px",
    bottom: "40px",
    borderRadius: "2rem",
    height: "3.5rem",
    width: "3.5rem",
    minWidth: "3rem",
    "& span": {
      "& svg": {
        width: "3rem",
        height: "3rem",
      },
    },
  },
}));
export default useStyles;
