import React from 'react';

function SvgCoolModeOn(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 35" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#53B5E4" d="M17.5 0H40v35H17.5C7.835 35 0 27.165 0 17.5S7.835 0 17.5 0z" />
        <g stroke="currentColor" strokeLinecap="round">
          <path d="M23.035 7.439V27.25m2.913-17.576l-2.913 1.78-2.913-1.78m0 15.503l2.912-1.78 2.915 1.78m5.634-12.837L14.488 22.35m16.638-6.368l-3.008-1.614.063-3.414m-13.377 7.835l3.009 1.615-.064 3.413" />
          <path strokeWidth={1.026} d="M14.44 12.422l17.12 9.968" />
          <path d="M17.837 11.028l.072 3.414-3.005 1.622m13.398 7.801l-.073-3.413 3.006-1.623" />
        </g>
      </g>
    </svg>
  );
}

export default SvgCoolModeOn;
