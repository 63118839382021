import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Typography,
} from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { orderBy } from 'lodash';
import { t } from 'ttag';
import useStyles from './styles';
import FilterRequire from '@components/FilterRequire/FilterRequire';
import Header from '@components/Header/Header';
import ServiceNavigationBar from '@components/Menu/ServiceNavigationBar';
import ButtonV2 from '@cool_widgets/ButtonV2';
import BacnetDeviceTableRow from './DevicesTableRow';
import useBacNet from '@hooks/useBacNet';
import { useStoreState } from '@models/RootStore';
import AddEditBacnetUiTemplateDialog from '@components/AddEditBacnetUiTemplateDialog';
import { useGetSiteBacnetUiTemplates } from '@hooks/useBacnetUiTemplatesApi';

/**
 * BacnetDeviceList component renders a list of BACnet devices for a selected site.
 *
 */
const BacnetDeviceList = (props) => {
  const classes = useStyles();
  const { siteId } = useStoreState((state) => state.selections.selections);
  const allDevices = useStoreState((state) => state.devices.allDevices);

  const {
    isLoading,
    getSiteBacNetParameterTemplates,
    siteBacNetUnits,
    getSiteBacNetUnits,
    assignBacNetParameterTemplate,
    unassignBacNetParameterTemplate,
    updateBacNetUnit,
    assignBacNetUiTemplate,
    unassignBacNetUiTemplate,
  } = useBacNet();

  const fetchData = useCallback(async () => {
    if (!siteId) {
      return;
    }
    await getSiteBacNetUnits(siteId);
    await getSiteBacNetParameterTemplates(siteId);
  }, [siteId]);

  const handleConnectionChange = async (bacnetUnitId, isEngaged) => {
    await updateBacNetUnit({
      id: bacnetUnitId,
      data: {
        isEngaged: isEngaged,
      },
    });
  };

  const handleParamTemplateAssign = async (tempId, bacnetUnitId) => {
    await assignBacNetParameterTemplate(tempId, bacnetUnitId);
  };

  const handleParamTemplateUnassign = async (tempId, bacnetUnitId) => {
    await unassignBacNetParameterTemplate(tempId, bacnetUnitId);
  };

  const handleUiTemplateAssign = async (tempId, bacnetUnitId) => {
    await assignBacNetUiTemplate(tempId, bacnetUnitId);
  };

  const handleUiTemplateUnassign = async (tempId, bacnetUnitId) => {
    await unassignBacNetUiTemplate(tempId, bacnetUnitId);
  };

  const goToUiTemplateMapping = (defaultParamTemplate) => {
    setDefaultParamTemplateForUiTemplate(defaultParamTemplate);
    setIsBacnetUiTemplateDialogShown(true);
  };

  // Initial fetch on mount or when siteId changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // State to control the visibility of the AddEditBacnetUiTemplateDialog
  const [isBacnetUiTemplateDialogShown, setIsBacnetUiTemplateDialogShown] =
    useState(false);
  const [defaultParamTemplateForUiTemplate, setDefaultParamTemplateForUiTemplate] = useState(null);

  const { data: uiTemplates, refetch: refetchUiTemplates } =
    useGetSiteBacnetUiTemplates(siteId);

  // Sort devices by name asc
  const sortedBacnetDevices = useMemo(() => {
    return orderBy(Object.values(siteBacNetUnits), ['name'], ['asc']);
  }, [siteBacNetUnits]);

  const handleEditUiTemplateDialogClose = () => {
    setIsBacnetUiTemplateDialogShown(false);
    refetchUiTemplates();
  };

  const tableHeaders = [
    t`CA device`,
    t`Line`,
    t`Device ID`,
    t`Device Name`,
    t`Vendor ID`,
    t`Org Name`,
    t`MAC Addr`,
    t`Model`,
    t`Mapping`,
    t`Control UI`,
    t`Connected`,
    t` `,
  ];

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection
          hideUnitSelection
          screenTitle="siteBacNetUnits"
        />
        {!siteId ? (
          <FilterRequire type={t`site`} />
        ) : (
          <>
            <Paper elevation={0} className={classes.paperTableContainer}>
              {!isLoading.getSiteBacNetUnits &&
                sortedBacnetDevices?.length > 0 && (
                <div className={classes.pageHeader}>
                  <Typography>
                    {`${
                      sortedBacnetDevices?.length || 0
                    } ${t`Devices discovered`}`}
                  </Typography>
                  <ButtonV2 variant="contained" onClick={() => fetchData()}>
                    {t`Discover`}
                  </ButtonV2>
                </div>
              )}
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((entry, index) => {
                        return (
                          <TableCell
                            key={`tableHeaders-${index}`}
                            classes={{ root: classes.tableHeadCell }}
                            align="left"
                          >
                            {entry}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading.getSiteBacNetUnits ? (
                      <TableRow>
                        <TableCell colSpan={tableHeaders.length} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : sortedBacnetDevices?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={tableHeaders.length} align="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <InfoIcon style={{ fontSize: 50 }} />
                            <Typography variant="h6">{t`No data available`}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      sortedBacnetDevices.map((bacnetDevice) => {
                        const { canUpdate } = bacnetDevice?.permissions || {};
                        const rowProps = {
                          bacnetDevice: {
                            ...bacnetDevice,
                            serial: allDevices[bacnetDevice.device]?.serial,
                          },
                          canUpdate,
                          allDevices,
                          handleConnectionChange,
                          handleParamTemplateAssign,
                          handleParamTemplateUnassign,
                          handleUiTemplateAssign,
                          handleUiTemplateUnassign,
                          goToUiTemplateMapping,
                        };
                        return (
                          <BacnetDeviceTableRow
                            siteId={siteId}
                            key={bacnetDevice.id}
                            uiTemplates={uiTemplates}
                            {...rowProps}
                          />
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <AddEditBacnetUiTemplateDialog
              open={isBacnetUiTemplateDialogShown}
              onClose={handleEditUiTemplateDialogClose}
              defaultParamTemplate={defaultParamTemplateForUiTemplate}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BacnetDeviceList;
