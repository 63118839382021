import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog,IconButton,Typography,Grid,TextField } from '@material-ui/core';

import Delete from '../../../../../components/Delete/Delete';
import { t } from 'ttag';

import { Close } from '../../../../../icons/index';
import { useStoreActions, useStoreState } from '../../../../../models/RootStore';
import CoolButton from '../../../../../cool_widgets/Button';


import SystemSelector from './components/SystemSelector';
import { getDayStartEndTimeStampOfUTCDate, convertTimeStampToJSDate } from '../../../../../services/timeService';
import { ConfirmationDialog } from '../../../../../components/ConfirmationDialog';


import useMiniForm from '@hooks/useMiniForm/useMiniForm';
import ReportDataSelector from './components/ReportDataSelector';
import ReportFrequencySelector from './components/ReportFrequencySelector';
import TenantsSelector from './components/TenantsSelector';
import UsersSelector from './components/UsersSelector';
import ReportFormatSelector from './components/ReportFormatSelector/inedx';
import PPDExtraOptions from './components/PPDExtraOptions';

import { getState,getActions } from './Utils/storeGetters';

import useStyles from './AddEditDialog.style';
import useChurnZero from '@hooks/useChurnZero';


const validateTenants = (tenantsList) => (value) => {
	if(!tenantsList.length){
		return false;
	}

	return !value.length;
};

const validateSystems = (systemsList,generateMultipleReports) => (value) => {
	if(!systemsList.length){
		return false;
	}

	if(generateMultipleReports){
		return false;
	}

	return !value.length;
};

// todo: much much more code splitting and refactoring.
// todo: fix validations order and implementation.
// todo: split normal report and ppd report to two different views.
// todo: remove lodash where possible.
// todo: when creating new report, auto focus on report name.
// todo: split all "sections" into a reusable component.
const AddEditDialog = ({selectedReport, onClose:hideDialog,scheduledReportType,chosenSite,onSubmit:updateReportsList,enableImmediateReport}) => {

	const classes = useStyles();

	const {connect,values,errors, init,validateForm, change} = useMiniForm({validateOnChange:true});

	const [showDeleteReport, setShowDeleteReport] = useState(false);

	const [siteZones, setSiteZones] = useState([]);
	const [siteSystems, setSiteSystems] = useState([]);

	const [showImmediateReportConfirm, setShowImmediateReportConfirm] = useState(false);

	// todo: getSystemsBySiteId should be an action, not a function returning from the state...
	const {canDeletePowerReportSchedules, canUpdatePowerReportSchedules,types,getSystemsBySiteId,defDateFormat} = useStoreState(getState);
	const {removeReport,getZonesBySiteId} = useStoreActions(getActions);

	const {trackEvent} = useChurnZero();

	const fetchZones = async () => {
		const zones = await getZonesBySiteId(chosenSite);
		setSiteZones(Object.values(zones));
	};

	const fetchSystems = async () => {
		const systems = await getSystemsBySiteId(chosenSite);
		setSiteSystems(Object.values(systems)?.filter((sys) => !!sys));
		return systems;
	};

	useEffect(() => {
		if (chosenSite) {
			// Load tenant list when list is ready
			fetchZones();
			fetchSystems();
		}
	}, [chosenSite]);


	const initReportDialogFields = (report) => {

		if(!report){
			return;
		}

		const {
			isNew = false,
			name = '',
			dataReportTypes,
			timeUnit,
			subTimeUnit,
			specificDay,
			users=[],
			systems,
			dateFormat = defDateFormat,
			startDate,
			endDate
		} = report;

		if(isNew){
			return change('dateFormat',defDateFormat);

		}

		const reportFields = {
			reportName:name,
			reportData:dataReportTypes,
			dateFormat,
			timeUnit,
			subTimeUnit,
			specificDay,
			systems,
			users
		};

		if(timeUnit === 'now' && startDate && endDate){
			reportFields.immediateReportDateRange = {
				startDate:convertTimeStampToJSDate(startDate),
				endDate:convertTimeStampToJSDate(endDate)
			};
		}


		init(reportFields);

		setShowDeleteReport(true);


	};

	const initPPDReportDialogField = (report) => {

		if(!report){
			return;
		}

		const {
			isNew = false,
			name = '',
			format,
			granularity,
			reportFormatType = 1,
			timeUnit,
			subTimeUnit,
			specificDay,
			startDate,
			endDate,
			zones,
			systems,
			distributeExcessPower,
			reportPerTenant = false,
			users=[],
		} = report;

		if(isNew){
			return;
		}

		const reportFields = {
			reportName:name,
			format,
			granularity,
			reportFormatType,
			timeUnit,
			subTimeUnit,
			specificDay,
			distributeExcessPower,
			generateMultipleReports:reportPerTenant,
			tenants:zones,
			systems,
			users,
		};

		if(timeUnit === 'now' && startDate && endDate){
			reportFields.immediateReportDateRange = {
				startDate:convertTimeStampToJSDate(startDate),
				endDate:convertTimeStampToJSDate(endDate)
			};
		}

		init(reportFields);

		setShowDeleteReport(true);
	};

	useEffect(() => {
		if(isPPDReport){
			initPPDReportDialogField(selectedReport);
		}else{
			initReportDialogFields(selectedReport);
		}
	}, [selectedReport]);

	useEffect(()=>{
		change('reportFormat',types?.ppdReportFormatTypes['CoolAutomation']);
	},[types]);

	const handleDeleteClick = (reportId) => {
		// need to use 'then' as the <Delete> creates memory leak using async\await
		removeReport({ reportId: reportId.id }).then(() => {
			setShowDeleteReport(false);
			resetAllFields();
			hideDialog();
		});
	};

	const saveReport = async () => {

		if(!validateForm()){
			return;
		}

		const {
			reportName,
			reportData,
			systems,
			immediateReportDateRange,
			dateFormat,
			timeUnit,
			subTimeUnit,
			specificDay,
			users
		} = values;

		const sendImmediateReport = timeUnit === 'now';

		const data = {
			name: reportName,
			dataReportTypes:reportData,
			dateFormat,
			timeUnit,
			subTimeUnit,
			isImmediate:sendImmediateReport,
			systems,
			users
		};
		if(data.isImmediate && immediateReportDateRange){
			const {startDate,endDate} = immediateReportDateRange;
			data.startTime = getDayStartEndTimeStampOfUTCDate(startDate);
			data.endTime = getDayStartEndTimeStampOfUTCDate(endDate, 'end');
		}

		if (subTimeUnit === 'SpecificDay') {
			data.specificDay = specificDay;
		}

		const action = selectedReport.isNew  ? 'add' : 'update';
		const savedReport =  selectedReport.isNew ? { ...data, site: chosenSite, type: types.scheduledReportTypes[scheduledReportType] } : { ...data, reportId: selectedReport.id };

		if(sendImmediateReport && !showImmediateReportConfirm){
			return setShowImmediateReportConfirm(true);
		}

		updateReportsList(savedReport,action);
		resetAllFields();
		hideDialog();

	};

	const savePPDReport = async () => {

		if(!validateForm()){
			return;
		}

		const {
			reportName,
			format,
			granularity,
			reportFormat,
			timeUnit,
			subTimeUnit,
			specificDay,
			distributeExcessPower,
			generateMultipleReports,
			tenants,
			systems,
			users
		} = values;



		const data = {
			name: reportName,
			format,
			granularity,
			zones: tenants,
			distributeExcessPower,
			reportFormatType: reportFormat,
			reportPerTenant: generateMultipleReports,
			timeUnit,
			subTimeUnit,
			systems,
			users

		};


		if (subTimeUnit === 'SpecificDay') {
			data.specificDay = specificDay;
		}


		const action = selectedReport.isNew  ? 'add' : 'update';
		const savedReport =  selectedReport.isNew ? { ...data, site: chosenSite, type: types.scheduledReportTypes[scheduledReportType] } : { ...data, reportId: selectedReport.id };

		updateReportsList(savedReport,action);
		resetAllFields();
		hideDialog();

	};

	const handleSaveClick = () => {
		if(isPPDReport){
			trackEvent('PPDSaveReportSchedule','A report schedule was saved');
			savePPDReport();
		}else{
			saveReport();
		}

	};

	const handleCancelClick =()=> {
		resetAllFields();
		hideDialog();
	};


	const resetAllFields = () => {
		init({});
		setShowDeleteReport(false);
	};


	const hideImmediateReportConfirm = () => {
		setShowImmediateReportConfirm(false);
	};

	const handleGenerateMultipleReportsChange = ({newValue:checked,change,updateErrorByKey}) => {
		if (checked) {
			const ids = siteZones.map(({id}) => id);
			change('tenants',ids);
			change('systems',[]);

			updateErrorByKey('tenants');
			updateErrorByKey('systems');

		}
	};

	const {generateMultipleReports} = values;

	const isPPDReport = scheduledReportType === 'ppd';

	const showImmediateReport = !isPPDReport && enableImmediateReport;

	return (
		<Dialog
			open={!!selectedReport}
			onClose={handleCancelClick}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			fullWidth
			classes={{ paper: classes.reportDialog }}
		>
			<div className={classes.dialogHeader}>
				<Typography className={classes.headerTitle}>{showDeleteReport ? t`Edit Report` : t`Add New Report`}</Typography>
				<IconButton disableRipple className={classes.iconBtnStyle} onClick={handleCancelClick}><Close color="#7f7692" /></IconButton>
			</div>
			<div className={classes.dialogContent}>
				<Grid container={true} className={classes.oneReportContainer}>
					<Grid item={true} xs={isPPDReport ? 4 : 6} className={classes.nameContainer}>
						<TextField
							id="report-name"
							disabled={!canUpdatePowerReportSchedules}
							label="Report Name"
							variant="outlined"
							className={classes.reportNameStyle}
							{...connect('reportName',{
								allowUndefinedValue:false,
								customErrorPropName:'helperText',
								validations:[{required:t`Missing report name`}]
							})}/>

						{!isPPDReport ?(
							<ReportDataSelector
								disabled={!canUpdatePowerReportSchedules}
								{...connect('reportData',{validations:[{required:t`Please select at least one data report`}]})}
							/>
						)
							: (
								<ReportFormatSelector
									disabled={!canUpdatePowerReportSchedules}
									connect={connect}
								/>
							)}

						<Grid container={true} className={classes.timeSelectionsContainer}>

							<ReportFrequencySelector
								disabled={!canUpdatePowerReportSchedules}
								showImmediateReport={showImmediateReport}
								connect={connect}
								values={values}
								errors={errors}
							/>

							{isPPDReport && (
								<PPDExtraOptions
									disabled={!canUpdatePowerReportSchedules}
									connect={connect}
									onGenerateMultipleReportsChange={handleGenerateMultipleReportsChange}
								/>
							)
							}
						</Grid>

					</Grid>

					<Grid item={true} container={true} xs={isPPDReport ? 4 : 3} className={classes.middleSideRecipients}>
						{isPPDReport ? (
							<TenantsSelector
								disabled={!canUpdatePowerReportSchedules || generateMultipleReports}
								tenants={siteZones}
								{...connect('tenants',{
									validations:[
										{validator:validateTenants(siteZones),message:t`Please select at least one tenant`}
									]
								})}
							/>
						) : null}

						<SystemSelector
							classes={classes}
							disabled={!canUpdatePowerReportSchedules || generateMultipleReports}
							systems={siteSystems}
							{...connect('systems',{
								validations:[
									{validator:validateSystems(siteSystems,generateMultipleReports),message:t`Please select at least one system`}
								]
							})}
						/>
					</Grid>

					<Grid item={true} container={true} xs={isPPDReport ? 4 : 3} className={classes.leftSideRecipients} >
						<UsersSelector
							disabled={!canUpdatePowerReportSchedules}
							{...connect('users',{
								validations:[
									{required:t`Please Select at least one recipient`}
								]
							})}
						/>
					</Grid>

				</Grid>
			</div>
			<div className={classes.actionsHolder}>

				{showDeleteReport &&
		<div className={classes.delIconEditReport}>
			<Delete
				disabled={!canDeletePowerReportSchedules}
				type={t`Report Schedule`}
				object={selectedReport}
				detach={handleDeleteClick}
				buttonClass={classes.deleteIcon}
			/>
		</div>
				}

				<div className={classes.buttonsHolder}>
					<CoolButton disabled={!canUpdatePowerReportSchedules}
						white
						marginRight
						width={150}
						onClick={handleCancelClick}>
						{t`Cancel`}
					</CoolButton>
					<CoolButton disabled={!canUpdatePowerReportSchedules}
						width={150}
						onClick={handleSaveClick}>
						{t`Submit`}
					</CoolButton>
				</div>
			</div>

			<ConfirmationDialog
				onCancel={hideImmediateReportConfirm}
				onConfirm={handleSaveClick}
				onClose={hideImmediateReportConfirm}
				title={t`Send Immediate report?`}
				text={t`Your requested report will be prepared and sent to the email recipients within a few minutes.`}
				confirmLabel={t`Send`}
				cancelLabel={t`Cancel`}
				dialogType="normal"
				openDialog={showImmediateReportConfirm}
			/>

		</Dialog>
	);
};

AddEditDialog.propTypes = {
	selectedReport:PropTypes.object,
	onClose:PropTypes.func.isRequired,
	onSubmit:PropTypes.func.isRequired,
	scheduledReportType:PropTypes.string,
	chosenSite:PropTypes.string,
	enableImmediateReport:PropTypes.bool
};

AddEditDialog.defaultProps = {
	selectedReport:null,
	scheduledReportType:'',
	chosenSite:'',
	enableImmediateReport:false
};

export default AddEditDialog;
