import * as React from 'react';

function SvgTune(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(3 4)">
          <path fill="#AAA2AA" d="M9 2h9v1.4H9zM0 2h6v1.4H0z" />
          <circle stroke="#AAA2AA" strokeWidth="1.4" cx="7.5" cy="2.5" r="1.8" />
        </g>
        <g transform="translate(3 10)">
          <path fill="#AAA2AA" d="M14 2h4v1.4h-4zM0 2h11v1.4H0z" />
          <circle stroke="#AAA2AA" strokeWidth="1.4" cx="12.5" cy="2.5" r="1.8" />
        </g>
        <g transform="translate(3 16)">
          <path fill="#AAA2AA" d="M6 2h12v1.4H6zM0 2h3v1.4H0z" />
          <circle stroke="#AAA2AA" strokeWidth="1.4" cx="4.5" cy="2.5" r="1.8" />
        </g>
      </g>
    </svg>

  );
}

export default SvgTune;
