import { alpha } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
	createStyles({
		sensorName: {
			fontSize: '18px',
			color: '#fff',
			fontWeight: 'bold'
		},
		sensorWrapperStyle: {
			height: '140px',
			maxHeight: '140px',
			width: '45%',
			maxWidth: '45%',
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
			borderRadius: '8px',
			display: 'flex',
			alignItems: 'center',
			flexFlow: 'column nowrap',
			margin: '5px'
		},
		sensorIcon: {
			height: 40,
			width: 40,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		iconStyle: {
			fontSize: '17.5px'
		},
		modeIcon: {
			margin: '0 5px'
		},
		smallIcons: {
			transform: 'scale(0.5)',
			margin: 0
		},
		sensorSliderContainer: {
			marginTop: '11px',
			maxWidth: '320px',
			maxHeight: '152px',
			zIndex: 1,
			position: 'relative',
			marginBottom: 10
		},
		sensorValuesContainer: {
			position: 'absolute',
			top: '120px',
			display: 'flex',
			flexFlow: 'row nowrap',
			justifyContent: 'space-between',
			width: '200px',
			right: '38px'
		},
		sensorValues: {
			fontFamily: 'Roboto',
			fontSize: 18,
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: '#ffffff'
		},
		valueControl: {
			position: 'absolute',
			top: '135px',
			width: '270px',
			textAlign: 'center',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexFlow: 'row wrap'
		},
		sensorValue: {
			fontFamily: 'Roboto',
			fontSize: 60,
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: '#ffffff',
			paddingRight: 5
		},
		measurementUnit: {
			fontWeight: 'normal',
			letterSpacing: 'normal',
			textAlign: 'center',
			color: ' #ffffff',
			lineHeight: '11px',
			fontFamily: '-webkit-body',
			fontSize: '24px',
			alignSelf: 'flex-end'
		},
		percentStyle: {
			fontSize: '20px',
			fontFamily: 'Roboto',
			marginBottom: '24px'
		},
		tempUnit: {
			alignSelf: 'flex-start',
			fontFamily: 'Roboto',
			fontSize: '20px',
		},
		valueContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: 30,
			position: 'relative',
			flexFlow: 'row wrap',
			margin: '7px 0'
		},
		sensorValueText: {
			fontFamily: 'Roboto',
			fontSize: '20px',
			fontWeight: 'normal',
			// lineHeight: '80px',
			letterSpacing: 'normal',
			color: '#ffffff'
		},
		offText: {
			color: alpha('#fff', 0.4)
		},
		measurementUnitsStyle: {
			fontWeight: 'normal',
			letterSpacing: 'normal',
			textAlign: 'center',
			color: ' #ffffff',
			fontFamily: '-webkit-body',
			fontSize: '20px',
			alignSelf: 'flex-end',
			marginBottom: '18px'
		},
		setPointDiscription: {
			fontSize: '20px',
			color: '#fff',
			// height: 65,
			lineHeight: 'normal'
		},
		timeDateStyle: {
			display: 'flex',
			justifyContent: 'space-evenly',
			width: '100%',
		},
		addTopMargin: {
			marginTop: 75
		},
		dataTimeFont: {
			height: 20,
			fontFamily: 'Roboto',
			fontSize: 14,
			fontWeight: 'normal',
			lineHeight: 1.11,
			letterSpacing: 'normal',
			color: '#ffffff'
		}
	});
