import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(()=>({
	batteryWrapper:{
		height:40,
		width:75,
		display:'flex',
		alignItems:'center',
		justifyContent:'center'
	},
	battery:{
		padding:'4%',
		background:'transparent',
		borderRadius:5,
		border:'2px solid #fff',
		height:'100%',

	},
	batteryFiller:{
		height:'100%'
	},
	extraTip:{
		width:5,
		background:'#fff',
		height:'60%',
		borderTopRightRadius:5,
		borderBottomRightRadius:5
	}
}));

export default useStyles;