import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import { getState } from '../../Utils/storeGetters';
import useStyles from '../../AddEditDialog.style';
import { useStoreState } from '@models/RootStore';
import SelectFormControl from '@components/SelectFormControl';
import SvgArrow from '@icons/Arrow';

const ReportFormatSelector = ({disabled,connect}) => {

	const {types,supportedReportTypes} = useStoreState(getState);

	const classes = useStyles();

	const menuProps = {
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left'
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left'
		},
		getContentAnchorEl: null
	};

	const reportFormatSelectOptions = useMemo(()=>{
		return Object.keys(types.ppdReportFormatTypes).map((type) => ({
			value:types.ppdReportFormatTypes[type],
			text:type
		}));
	},[types]);

	const renderReportFormatSelect = () => {

		if(!supportedReportTypes.length){
			return null;
		}

		return (
			<div className={classes.rowContainer}>

				<SelectFormControl 
					disabled={disabled}
					options={reportFormatSelectOptions}
					label={t`Report Format`}
					labelClassName={classes.inputLabelStyle}
					disableUnderline
					className={classes.reportFormatSelect}
					IconComponent={SvgArrow}
					MenuProps={menuProps}
					showLargeLabels={true}
					{...connect('reportFormat', {validations:[
						{required:t`please select report format`}
					]} )}
				/>
					
			</div>
		);

	};

	return (
		<div>
			<div className={classes.rowContainer}>

				<SelectFormControl 
					disabled={disabled}
					options={[
						{value:'pdf',text:'PDF'},
						{value:'csv',text:'CSV'},
					]}
					label={t`File Format`}
					labelClassName={classes.inputLabelStyle}
					disableUnderline
					className={classes.reportFormatSelect}
					wrapperClassName={classes.reportFormatWrapper}
					IconComponent={SvgArrow}
					MenuProps={menuProps}
					fullWidth={false}
					showLargeLabels={true}					
					{...connect('format', {initialValue:'pdf'})}
				/>

				<SelectFormControl 
					disabled={disabled}
					options={[
						{value:'hour',text:t`1 hour`},
						{value:'day',text:t`1 day`},
					]}
					label={t`Granularity`}
					labelClassName={classes.inputLabelStyle}
					disableUnderline
					className={classes.reportFormatSelect}
					wrapperClassName={classes.reportFormatWrapper}
					IconComponent={SvgArrow}
					MenuProps={menuProps}
					fullWidth={false}
					showLargeLabels={true}
					error={'field is missing'}
					{...connect('granularity', {initialValue:'hour'} )}
				/>
			</div>

			{renderReportFormatSelect()}
		</div>
	);
};

ReportFormatSelector.propTypes = {
	disabled:PropTypes.bool,
	connect:PropTypes.func.isRequired
};

ReportFormatSelector.defaultProps = {
	disabled:false
};

export default ReportFormatSelector;