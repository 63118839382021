import { Typography } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import { Add, ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { isIOS } from "react-device-detect";
import { RouteComponentProps, useHistory } from "react-router";
import { t } from "ttag";
import { TopBar } from "../../components";
import { MobileLogo } from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./GroupMtmt.style";

const GroupMgmt: React.FC<RouteComponentProps & any> = (props) => {
  const classes = useStyles();
  const [groups, setGroups] = useState<any>([]);
  const getGroups = useStoreActions((actions) => actions.groups.getSiteGroups);
  const rippleRef: any = useRef([]);
  const history = useHistory();
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const {customerId, siteId = ""} = useStoreState((s) => s.selections.mobileSelections);
  const {canAddGroup = true} = getCustomer(customerId) || {};

  useEffect(() => {
    !_.isEmpty(groups) && setGroups([]);
    if(!siteId){
      return;
    }

    getGroups(siteId)
      .then((resp: any) => {
        setGroups(resp);
      });
  }, [siteId]);

  const onRippleStart = (e: any, index: any) => {
    rippleRef?.current[index]?.start(e);
  };
  const onRippleStop = (e: any, index: any, id = "") => {
    rippleRef.current[index].stop(e);
    setTimeout(() =>
      history.push(id ? `/settings/groups/addEdit/${id}` : `/sites/${siteId}/groups`),
      200);
  };

  return (
    <>
      <TopBar
        hideUnitsFilter
        leftIconComponent={<MobileLogo />}
        leftAction={() => history.push("/dashboard")}
      />
      <div className={classes.view} style={{background: "white"}}>
      {!siteId ? <Typography className={classes.title} style={{textAlign: "center", width: "100%", marginTop: "40px"}}>{t`Please select a site from top filters.`}</Typography> :
        <>
        <div className={clsx(classes.contentArea, { [classes.IOSContentArea]: isIOS })}>
          <div className={classes.titleRow}>
            <Typography className={classes.title}>
              {t`Groups`}
            </Typography>
          </div>

          <div
            className={classes.groupRow}
            onMouseDown={(e: any) => !canAddGroup ? {} : onRippleStart(e, 0)}
            onMouseUp={(e: any) => !canAddGroup ? {} : onRippleStop(e, 0)}
          >

            <TouchRipple ref={(e: any) => rippleRef.current[0] = e} center={false} />
            <Typography className={classes.groupName}>
              {t`Add New Group`}
            </Typography>
            <Add className={classes.arrow} />
          </div>

          {
            _.orderBy(groups, ["name"], ["asc"])
              .map((group: any, index: any) => {
                const { id, name } = group;
                return (
                  <div
                    key={`group-${id}`}
                    className={classes.groupRow}
                    onMouseDown={(e: any) => onRippleStart(e, index + 1)}
                    onMouseUp={(e: any) => onRippleStop(e, index + 1, id)}

                  >
                    <TouchRipple ref={(e: any) => rippleRef.current[index + 1] = e} center={false} />
                    <Typography className={classes.groupName}>
                      {name}
                    </Typography>
                    <ChevronRight className={classes.arrow} />
                  </div>
                );
              })
          }
        </div>
        </>
      }
      </div>
    </>
  );
};

export default GroupMgmt;
