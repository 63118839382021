import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  tablePaper: {
    background: '#fefefe',
    padding: '20px',
    paddingTop: 0,
    borderRadius: '4px',
    boxShadow: '0 2px 5px -5px rgba(180, 175, 203, 0.63)',
    border: 'solid 1px #ebebeb',
    height: '100%',
  },
  tableContainer: {
    height: 'calc(100% - 53px)',
    // padding: "1rem",
    width: 'auto',
  },
  tableCell: {
    ...theme.tables.tableCell,
    padding: '0 15px',
    height: '35px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  reloadIconStyle: {
    color: theme.palette.colors.purple1,
    padding: '7px',
    '& svg': {
      fontSize: '24px',
    },
  },
  tablePagesHeader: {
    height: '35px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    marginTop: '5px'
  },
  progressBarContainer: {
    height: '5px',
  },
  flexConitainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}));

export default useStyles;
