import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@cool_widgets/CoolCheckbox';
import { CheckboxChecked } from '@icons/index';
import clsx from 'clsx';
import { InputLabel } from '@material-ui/core';

import classes from './CheckBoxFormControl.module.css';

const defCheckedIcon = (<CheckboxChecked />);


//todo: add JSDOC.
const CheckBoxFormControl = ({checkedIcon,value,label, labelClassName, wrapperClassName, onChange, ...props}) => {

	const labelClass = clsx(labelClassName, { [classes['label-checked']]: value });

	const handleChange = ({target:{checked}}) => {
		onChange(checked);
	};

	return (
		<div className={clsx('checkbox-form-control',wrapperClassName)}>
			<InputLabel className={labelClass}>
				<Checkbox
					checked={value}
					checkedIcon={checkedIcon}
					color="default"
					variant="outlined"
					edge="end"
					onChange={handleChange}
					{...props}
				/>
				{label}
			</InputLabel>
		</div>
	);
};

CheckBoxFormControl.propTypes = {
	onChange:PropTypes.func.isRequired,
	checkedIcon:PropTypes.node,
	value:PropTypes.bool,
	wrapperClassName:PropTypes.string,
	labelClassName:PropTypes.string,
	label:PropTypes.string,
};

CheckBoxFormControl.defaultProps = {
	checkedIcon:defCheckedIcon,
	value:false,
	wrapperClassName:'',
	labelClassName:'',
	label:'',
};

export default CheckBoxFormControl;