import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { PlayArrow, Stop } from "@material-ui/icons";
import clsx from "clsx";
import { keyBy, mapValues, cloneDeep, orderBy } from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { ApplyToDialog } from "../../components/AddEditScript";
import Delete from "../../components/Delete/Delete";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { Download } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { toC, toF, paramValueToScale, hasValue } from "../../services/converter";
import { checkDateInRange } from "../../services/timeService";
import useStyles from "./CommissioningLogs.style";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

type StringMap = {
  [key: string]: string;
};

interface ScaleMatchEntry {
  scaleType: number;
  scale: number;
}

interface ScaleMatch {
  [measurementUnit: string]: ScaleMatchEntry;
}

interface IReport {
  id: string;
  procedureInstance: string;
}

const ScriptsList: React.FC = (props: any) => {
  const classes = useStyles();

  const isInitialized = useStoreState((s) => s.isInitialized);
  const allUnits = useStoreState((s) => s.units.allUnits);
  const allSites = useStoreState((s) => s.sites.allSites);
  const selections = useStoreState((state) => state.selections.selections);
  const addMessage = useStoreActions((action) => action.errorMessage.addMessage);
  const types = useStoreState((state) => state.types);
  const user = useStoreState((s) => s.users.me);
  const stepResultStatus = useStoreState((s) => s.stepResultStatus);
  const reportResults = useStoreState((s) => s.reportResults);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const procedureStatuses = useStoreState((state) => state.procedureStatuses);
  const setCallBacks = useStoreActions((action) => action.setCallBacks);
  const resetCallBacks = useStoreActions((action) => action.resetCallBacks);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);

  const {
    getProcedureInstance,
    stopProcedureInstance,
    deleteCommissioningReport,
    getProceduresInstances,
    getCommissioningReportsBySite,
    getSiteScripts,
    runProcedure
  } = useStoreActions((action) => action.scripts);
  const { stepsTypesMirror, commandsOptions, conditionsOptions, serviceParams, serviceParamTypes, operatorTypesMap }: any = useStoreState((s) => s);
  const { temperatureScale: userTempScale = 1, measurementUnits: userPressureScale = 2 } = user;
  const { actionParameterOperators, procedureStepTypes, procedureRunningStates, measurementUnitTypes, procedureStepTypesTitles, actionValueSources } = types;

  const [reports, setReports] = useState<any>([]);
  const [siteTimezone, setSiteTimezone] = useState<any>(moment.tz.guess());
  const [reportsObject, setReportsObject] = useState<any>({});
  const [instanceIdToReportIdMap, setInstanceIdToReportIdMap] = useState<StringMap>({});
  const [procedures, setProcedures] = useState<any>({});
  const [proceduresInstances, setProceduresInstances] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [scriptToFetch, setScriptToFetch] = useState<any>("");
  const [selectedReRun, setSelectedReRun] = useState<string>("");
  const { customerId, siteId } = selections;
  const { permissions = {} } = siteId ? allSites[siteId] : {};
  const { canRunProcedures, canDeleteProcedureReports } = permissions || {};

  useEffect(() => {
    resetCallBacks();
    setCallBacks({ messageName: "PROCEDURE_INSTANCE_UPDATED", func: setScriptToFetch });
  }, []);

  useEffect(() => {
    if (!siteId || !selections.dateRange || !scriptToFetch) {
      return;
    }

    if (siteId !== scriptToFetch.siteId) {
      return;
    }

    if (proceduresInstances[scriptToFetch.procedureInstanceId]) {
      const updatedScript = cloneDeep(proceduresInstances[scriptToFetch.procedureInstanceId]);
      updatedScript.runningState = scriptToFetch.state;
      proceduresInstances[updatedScript.id] = updatedScript;
      setProceduresInstances({ ...proceduresInstances });
    } else {
      getProcedureInstance({ id: scriptToFetch.procedureInstanceId, type: 1 })
        .then((instance: any) => {
          instance.timezone = siteTimezone;
          proceduresInstances[instance.id] = instance;
          setProceduresInstances({ ...proceduresInstances });
        });
    }

    const isToday = moment(selections?.dateRange?.endDate).isSame(moment(new Date()), "day");

    if (scriptToFetch.status !== 2 && isToday) {
      // const { [scriptToFetch.procedureInstanceId]: toDelete, ...newProceduresInstances } = proceduresInstances;
      // setProceduresInstances(newProceduresInstances);
      const { dateRange } = selections;
      const { startDate, endDate } = dateRange || {};

      const startTime = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) - 54000000;
      const endTime = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59) + 54000000;

      getCommissioningReportsBySite({ id: siteId, data: { startTime, endTime, type: 1 } })
        .then((response: any) => {
          Object.values(response).forEach((report: any) => {
            const { id, startTime } = report;

            if (!checkDateInRange(moment(startDate).format("MM/DD/YYYY"),
              moment(endDate).format("MM/DD/YYYY"),
              moment(startTime).tz(siteTimezone).format("MM/DD/YYYY"))
            ) {
              delete response[id];
            }
            report.timezone = siteTimezone;
          });

          const instanceIdToReportIdMap: StringMap = (Object.values(response) as IReport[]).reduce((acc: StringMap, report: IReport) => {
            acc[report.procedureInstance] = report.id;
            return acc;
          }, {} as StringMap);

          setInstanceIdToReportIdMap(instanceIdToReportIdMap);
          setReportsObject(response);
        });
    }

  }, [scriptToFetch]);

  useEffect(() => {
    if (selections.dateRange) {
      return;
    }
    updateSelections({
      type: "time",
      data: {
        startDate: new Date(new Date().setHours(0, 0, 0) - 7 * 24 * 60 * 60 * 1000),
        endDate: new Date()
      }
    });

  }, [selections.dateRange]);

  useEffect(() => {
    setReports(orderBy(reportsObject, ["startTime"], ["desc"]));
  }, [reportsObject]);

  useEffect(() => {
    if (!selections.dateRange) {
      return;
    }

    const { dateRange } = selections;
    const { startDate, endDate } = dateRange || {};

    if (!siteId) {
      setLoading(false);
      return;
    }

    const startTime = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) - 54000000;
    const endTime = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59) + 54000000;
    setLoading(true);
    const { timezone = "" } = allSites[siteId || ""];
    setSiteTimezone(timezone || moment.tz.guess());

    Promise.all([getSiteScripts({ siteId, type: 1 }),
    getCommissioningReportsBySite({ id: siteId, data: { startTime, endTime, type: 1 } }),
    getProceduresInstances({ siteId, type: 1 })])
      .then((response: any) => {
        Object.values(response[1]).forEach((report: any) => {
          const { id, startTime } = report;
          if (!checkDateInRange(moment(startDate).format("MM/DD/YYYY"),
            moment(endDate).format("MM/DD/YYYY"),
            moment(startTime).tz(siteTimezone).format("MM/DD/YYYY"))
          ) {
            delete response[1][id];
          }
          report.timezone = siteTimezone;
        });

        Object.values(response[2]).forEach((instance: any) => {
          instance.timezone = siteTimezone;
        });

        setProcedures(response[0]);
        setReportsObject(response[1]);
        setProceduresInstances(response[2]);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      })
      .finally(() => setLoading(false));
  }, [selections.dateRange, siteId]);

  const runNewProcedureInstance = (id: string, units: any, systems: any) => {

    runProcedure({ id, data: { units, systems } })
      .then(() => {
        setSelectedReRun("");
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
  };

  const handleRunProcedure = (report: any) => {
    const procedure = procedures[report.procedure] || {};
    if((procedure && procedure.stepsRunningMode === 2) || (!procedure && report.stepsRunningMode === 2))
      runNewProcedureInstance(report.procedure, [], [])
    else
      setSelectedReRun(report.procedure);
  }

  const deleteCurrentReport = (id: string) => {
    return deleteCommissioningReport(id)
      .then(() => {
        delete reportsObject[id];
        setReportsObject({ ...reportsObject });
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const stop = (id: string) => {
    return stopProcedureInstance(id)
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const download = async (report: any) => {
    serviceParams.procedureStatus = {
      code: "procedureStatus",
      data_unit_of_measurement: "",
      enum: "procedureStatus",
      hvac_param_name: "ProcedureStatus",
      title: "Procedure Status",
      unitType: "",
    };

    let timeFormatWithSeconds = timeFormat + ':ss:SSS';
    if (timeFormat.includes('a') || timeFormat.includes('A')) {
      timeFormatWithSeconds = timeFormat.replace(/a/i, ':ss:SSS$&');
    }

    const measuresMap: StringMap = {
      "°C": userTempScale === 2 ? " (°F)" : " (°C)",
      "kg/cm2": userPressureScale === 2 ? " (PSI)" : " (kg/cm2)",
      "MPa": userPressureScale === 2 ? " (PSI)" : " (MPa)",
      "PSI": userPressureScale === 1 ? " (kg/cm2)" : " (PSI)"
    };

    const scaleMatch: ScaleMatch = {
      "°C": {
        scaleType: measurementUnitTypes["temperature"],
        scale: userTempScale
      },
      "kg/cm2": {
        scaleType: measurementUnitTypes["pressure"],
        scale: userPressureScale
      },
      MPa: {
        scaleType: measurementUnitTypes["pressure"],
        scale: userPressureScale
      },
      PSI: {
        scaleType: measurementUnitTypes["pressure"],
        scale: userPressureScale
      }
    };

    const actionParamOperatorsMap =  mapValues(keyBy(actionParameterOperators, 'value'), 'label');

    const { results = {}, startTime, endTime, procedure, timezone } = report;

    const startDate = moment(startTime).tz(timezone).format(`${dateFormat} ${timeFormat}`);
    const endDate = moment(endTime).tz(timezone).format(`${dateFormat} ${timeFormat}`);
    const reportName = `${procedures[procedure]?.name || ""}.${startDate}-${endDate}`;
    const headers = ["Unit Name", "Type", "Action", "Operator", "Parameter 2", "Difference Operator", "Target Value", "Actual Value", "Result", "Date/Time"];

    const workbook = new ExcelJS.Workbook();
    const sheetName = procedures[procedure]?.name?.replace(/[/\\?%*:|"<>]/g, "-") || "reprot";
    const sheet = workbook.addWorksheet(sheetName);
    const columnWidths = [28, 17, 28, 20, 25, 17, 16, 16, 21, 20];
    columnWidths.forEach((width: number, index: number) => {
      sheet.getColumn(index + 1).width = width;
    });

    let index = 1;
    sheet.addRow(headers);
    sheet.getRow(index).font = { bold: true };
    Object.keys(results).forEach((unitId: string) => {
      const unit = unitId === 'null' ? { name: '' } : allUnits[unitId];

      if (!unit) {
        return;
      }

      report.results[unitId].forEach((step: any) => {
        let { currentValue: actualValue = "", calculatedValue = "", type, condition, command, value, result, timestamp = "", scale, scaleType, operator } = step;

        if (type === procedureStepTypes.wait) {
          value = value / 60000 + " (minutes)";
        } else {
          let scaleUnit = "";
          let enumValues: any;
          if(command) {
            value = +step.valueSource === actionValueSources.parameter ? calculatedValue : value
            if(scale) {
              scaleUnit = userTempScale === 2 ? " (°F)" : " (°C)";
              if(userTempScale !== scale)
                  value = (userTempScale === 1 ? toC(+value) : toF(+value))
            } else {
                const enumText: any = commandsOptions[command]?.enum || "";
                if(types[enumText])
                  enumValues = types[enumText];
            }
          } else if (condition) {
            let measurementUnit = serviceParams[condition]?.data_unit_of_measurement;
            scaleUnit = measuresMap[measurementUnit] || "";
            if(scale && scaleType) {
              value = paramValueToScale(value, scaleType, scale, userTempScale, userPressureScale, types);
              if (hasValue(actualValue) && scaleMatch[measurementUnit]) {
                actualValue = paramValueToScale(actualValue, scaleMatch[measurementUnit].scaleType, scaleMatch[measurementUnit].scale, userTempScale, userPressureScale, types);
              }
            } else {
              const enumText: any = serviceParams[condition]?.enum || "";
              if(serviceParamTypes[enumText])
                enumValues = serviceParamTypes[enumText];
            }
          }

          if(enumValues && enumValues[value]) {
            value = enumValues[value];
            actualValue = enumValues[actualValue] ? enumValues[actualValue] : actualValue;
          }

          value = value + scaleUnit;

          if (hasValue(actualValue)) {
            actualValue = actualValue + scaleUnit;
          }
        }

        let actionText = "-";
        let parameter2 = "";
        if(command) {
          actionText = conditionsOptions[command]?.name || "-";
          if (step.parameter) {
            parameter2 = serviceParams[step.parameter]?.title || "";
          }
        } else if(condition) {
          actionText = serviceParams[condition]?.title || "-";
          if (step.thresholdParameter) {
            parameter2 = serviceParams[step.thresholdParameter]?.title || "";
          }
        }

        const thresholdOperator = operatorTypesMap[step.thresholdOperator] || "";
        if (command && step.parameter) {
          operator = actionParamOperatorsMap[operator] || "=";
        } else {
          operator = operator === "threshold" ? "Difference" : (operatorTypesMap[operator] || "=");
        }

        const row: any = [
          unit.name,
          procedureStepTypesTitles[type] || "-",
          actionText,
          operator,
          parameter2,
          thresholdOperator,
          value,
          actualValue,
          stepResultStatus[result],
          timestamp ? moment(timestamp).tz(timezone).format(`${dateFormat}   ${timeFormatWithSeconds}`) : ""

        ];

        sheet.addRow(row)
        index++;
        if(result === types.procedureStepResultTypes.failure) {
          sheet.getRow(index).font = { color: { argb: "FF0000" } };
        }
      });

    });

    const buffer = await workbook["xlsx"].writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `${reportName}.xlsx`);
  };

  if (!isInitialized) { return <Loading />; }

  const selectedReport = reports.filter((report: any) => report.procedure === selectedReRun)[0];

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection
          hideUnitSelection
          showDateRangePicker
          screenTitle="commissioningLogs"
        />
        {!siteId ? <FilterRequire type={t`site`} /> : <>
          <div className={classes.headerButtons}>
          </div>
          {loading ?
            <Paper elevation={0} className={clsx(classes.paperTableContainer, classes.loaderPaper)}>
              <div className={classes.loaderContainer}>
                <CircularProgress />
                <Typography variant="h5">{t`Loading Reports`}</Typography>
              </div>
            </Paper>
            : <>
              <Paper elevation={0} className={clsx(classes.paperTableContainer, classes.halfTable)}>
                <Typography className={classes.title}>{t`Running Procedures`}</Typography>
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Name`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Status`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Date/Time`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`stop`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderBy(proceduresInstances, ["createdAt"], ["desc"]).map((script: any) => {

                        if (instanceIdToReportIdMap[script.id]) {
                          return null;
                        }

                        const isScriptStopped = script.runningState === procedureRunningStates["stopped"] || script.runningState === procedureRunningStates["completedSuccess"] || script.runningState === procedureRunningStates["completedPartialSuccess"] || script.runningState === procedureRunningStates["completedFailure"];
                        const createdAtFormatted = (script.timezone ? moment(script.createdAt).tz(script.timezone) : moment(script.createdAt))?.format(`${dateFormat}   ${timeFormat}`);

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={`script-${script.id}`}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                              align="left"
                            >
                              {procedures[script?.procedure]?.name || ""}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                              align="left"
                            >
                              {procedureStatuses[script?.runningState] || ""}
                            </TableCell>
                            <TableCell
                              classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                              align="left"

                            >
                              {createdAtFormatted}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                              align="left"
                            >
                              <IconButton disableRipple disabled={isScriptStopped || !canRunProcedures} onClick={() => stop(script.id)} className={classes.iconBtnStyle}>
                                <Stop className={clsx(classes.iconColor, { [classes.disabledIcon]: isScriptStopped })} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <Paper elevation={0} className={classes.paperTableContainer}>
                <Typography className={classes.title}>{t`Procedures Reports`}</Typography>
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Procedure`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Status`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`Start Date/Time`}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableHeadCell }}
                          align="left"
                        >{t`End Date/Time`}</TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.tableHeadCell, classes.iconsColumnWidth) }}
                          align="left"
                        >{t`DOWNLOAD`}</TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.tableHeadCell, classes.iconsColumnWidth) }}
                          align="left"
                        >{t`RERUN`}</TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.tableHeadCell, classes.iconsColumnWidth) }}
                          align="left"
                        >{t`REMOVE`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports.map((report: any) => {
                        const procedure = procedures[report.procedure] || {};
                        const { permissions: { canDelete = false } = {}, name = "" } = procedure;
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={`row-${report.id}`}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              classes={{ root: classes.overWritePadding }}
                              align="left"
                            >
                              {name}
                            </TableCell>
                            <TableCell classes={{ root: clsx(classes.overWritePadding) }} align="left">
                              {reportResults[report?.resultsSummary] || ""}
                            </TableCell>
                            <TableCell classes={{ root: clsx(classes.overWritePadding, classes.timeWidth) }} align="left">
                              {moment(report.startTime).tz(report.timezone).format(`${dateFormat}   ${timeFormat}`)}
                            </TableCell>
                            <TableCell classes={{ root: clsx(classes.overWritePadding, classes.timeWidth) }} align="left">
                              {moment(report.endTime).tz(report.timezone).format(`${dateFormat}   ${timeFormat}`)}
                            </TableCell>
                            <TableCell classes={{ root: classes.overWritePadding }} align="center">
                              <IconButton disableRipple onClick={() => download(report)} className={classes.iconBtnStyle}>
                                <Download style={{ fontSize: 21 }} />
                              </IconButton>
                            </TableCell>
                            <TableCell classes={{ root: classes.overWritePadding }} align="center">
                              <IconButton disabled={!canRunProcedures} disableRipple onClick={() => handleRunProcedure(report)} className={classes.iconBtnStyle}>
                                <PlayArrow className={classes.iconColor} />
                              </IconButton>
                            </TableCell>
                            <TableCell classes={{ root: classes.overWritePadding }} align="center" style={{ opacity: !canDelete ? 0.4 : 1 }}>
                              <Delete
                                disabled={!canDeleteProcedureReports || !canDelete}
                                type={t`commissioning report`}
                                object={{ id: report.id, name }}
                                detach={() => deleteCurrentReport(report.id)}
                              ></Delete>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              {
                selectedReRun && <ApplyToDialog
                  close={() => setSelectedReRun("")}
                  onSave={runNewProcedureInstance}
                  brand={procedures[selectedReRun]?.userSelections?.brand}
                  customerId={customerId}
                  siteId={siteId}
                  procedureId={procedures[selectedReRun]?.id}
                  selectedUnits={Object.keys(selectedReport?.results || {})}
                  steps={procedures[selectedReRun]?.steps}
                />
              }
            </>}
        </>}
      </div>
    </div>
  );
};

export default ScriptsList;
