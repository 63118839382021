import {
  BacnetDevice as bacnetDeviceSdk,
} from 'coolremote-sdk';

import useQuery from './useQuery';

export const useGetBacnetParamTemplate = (templateId) => {
  return useQuery({
    queryKey: ['bacnetParamTemplate', templateId],
    queryFn: async () => {
      return bacnetDeviceSdk.getBacnetParameterTemplateById(templateId);
    },
    enabled: !!templateId,
  });
};
