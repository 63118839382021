import * as React from 'react';

function ListIcon({ width = 16, height = 16, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={width} height={height} rx="8" fill="#6B4B68" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3753 5.5L13 6.19677L8.41765 11.3082C8.30571 11.4312 8.15591 11.5 8 11.5C7.84409 11.5 7.69429 11.4312 7.58235 11.3082L3 6.19677L3.62529 5.5L8 10.3794L12.3753 5.5Z"
        fill="white"
      />
    </svg>
  );
}

export default ListIcon;
