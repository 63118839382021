/**
 * Determines the current environment name based on the hostname.
 *
 * This function checks the hostname of the current window location
 * and returns a string indicating the environment. It distinguishes between
 * 'local', 'dev', 'staging', and 'production' environments.
 *
 * @returns {string} The name of the current environment. Possible values are 'local', 'dev', 'staging', or 'production'.
 */
export const getCurrentEnvName = () => {
	const host = window.location.hostname;

	if(host.includes('localhost') || host.includes('127.0.0.1')){
		return 'local';
	}

	if(host.includes('-dev')){
		return 'dev';
	}

	if(host.includes('-stg')){
		return 'staging';
	}

	return 'production';
};
