import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  leftSide: {
    width: "35%",
    paddingRight: 30,
    display: "flex",
    flexDirection: "column",
  },
  rightSide: {
    borderLeft: "solid 1px #e1e2ea",
    padding: "0 30px",
  },
  endRightSide: {
    borderLeft: "solid 1px #e1e2ea",
    width: "37%",
    padding: "0 22px",
  },
  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    overflow: "hidden",
    height: "100%",
    padding: "2rem",
    display: "flex",
    flexFlow: "row nowrap",
  },
  select: {
    "&>svg": {
      width: "25px",
      height: "25px",
    },
  },
  menuItemsContainer: {
    maxWidth: "26%",
  },
  menuItem: {
    textOverflow: "ellipsis",
    display: "block",
  },
  thresholdInput: {
    width: "86%",
  },
  tempInput: {
    width: "50%",
  },
  title: {
    margin: "10px 0",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.light,
  },
  noteTxt: {
    fontFamily: "Roboto",
    margin: "10px 0",
    fontSize: "15px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    color: theme.palette.primary.light,
  },
  modeIcon: {
    transform: "scale(1.5)",
  },
  modeThresholdRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0 30px 0",
    alignItems: "center",
  },
  temperatureScale: {
    fontFamily: "Roboto",
    fontSize: "1.125rem",
    letterSpacing: "0.03625rem",
    color: theme.overrides.MuiButton.outlinedPrimary.color,
  },
  modeAndTempContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  differentialText: {
    display: "flex",
    fontSize: "14px",
    marginTop: "15px",
    color: theme.palette.primary.light,
  },
  sectionTitle: {
    color: theme.overrides.MuiButton.outlinedPrimary.color,
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    borderBottom: theme.overrides.MuiButton.outlined.border,
    paddingBottom: "10px",
    marginBottom: "10px",
  },
  blockContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
  },
  thumb: {
    backgroundColor: theme.palette.primary.dark,
  },
  track: {
    backgroundColor: theme.palette.primary.light,
  },
  switchAndTitle: {
    display: "flex",
    alignItems: "center",
  },
  switchTitle: {
    fontSize: "14px",
  },

  errorMsg: {
    color: "red",
    fontSize: "15px",
    fontFamily: "Roboto",
    height: "20px",
    margin: "0 20px",
  },
  dialog: {
    borderRadius: "8px",
    maxWidth: "90%",
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: "25px",
    paddingBottom: "15px",
    margin: "5px",
    overflow: "auto",
    display: "flex",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    margin: "20px",
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  bold: {
    fontWeight: "bold",
  },
  siteTempNote: {
    width: "40%",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    color: theme.palette.primary.light,
  },
  siteTemp: {
    display: "flex",
    alignItems: "center",
  },
  checkboxStyle: { marginRight: "15px" },
  thresholdContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  thresholdBlock: {
    width: "47%",
    paddingRight: "23px",
  },
  thresholdGraph: {
    width: "50%",
  },
  systemError: {
    color: "red",
  },
  limitMinWidth: {
    minWidth: "467px",
  },
  thresholdSvg: {
    marginTop: "29px",
  },
  hideOption: {
    display: "none",
  },
}));

export default useStyles;
