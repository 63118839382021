import React from 'react';

const TimerActive = (props) => (
	<svg
		width={props.width || '24'}
		height={props.height || '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_345_1406)">
			<circle cx="12" cy="12" r="12" fill="#816B81" />
			<path
				strokeWidth={0.8}
				stroke="white"
				d="M12 4.12729C7.58172 4.12729 4 7.70901 4 12.1273C4 16.5456 7.58172 20.1273 12 20.1273C16.4183 20.1273 20 16.5456 20 12.1273C20 7.70901 16.4183 4.12729 12 4.12729ZM12 5.22622C15.8114 5.22622 18.9011 8.31593 18.9011 12.1273C18.9011 15.9386 15.8114 19.0284 12 19.0284C8.18864 19.0284 5.09893 15.9386 5.09893 12.1273C5.09893 8.31593 8.18864 5.22622 12 5.22622Z"
				fill="white"
			/>
			<path
				strokeWidth={0.8}
				stroke="white"
				d="M14.668 8.71783C14.875 8.49592 15.2227 8.48384 15.4446 8.69083C15.6506 8.88304 15.6758 9.19657 15.5126 9.41804L15.4716 9.46742L12.4621 12.6936C12.2551 12.9155 11.9074 12.9276 11.6855 12.7206C11.4795 12.5284 11.4543 12.2149 11.6175 11.9934L11.6585 11.944L14.668 8.71783Z"
				fill="white"
			/>
			<path
				strokeWidth={0.8}
				stroke="white"
				d="M12 13.4884C12.7517 13.4884 13.361 12.879 13.361 12.1273C13.361 11.3756 12.7517 10.7662 12 10.7662C11.2483 10.7662 10.6389 11.3756 10.6389 12.1273C10.6389 12.879 11.2483 13.4884 12 13.4884Z"
				fill="white"
			/>
			<path
				strokeWidth={0.8}
				stroke="white"
				d="M11.7732 2.05042C12.0564 2.05042 12.2896 2.26471 12.3195 2.54001L12.3227 2.59988V4.60114C12.3227 4.9046 12.0767 5.1506 11.7732 5.1506C11.49 5.1506 11.2568 4.93631 11.227 4.66101L11.2238 4.60114V2.59988C11.2238 2.29642 11.4698 2.05042 11.7732 2.05042Z"
				fill="white"
			/>
			<path
				strokeWidth={0.8}
				stroke="white"
				d="M13.3509 2C13.6544 2 13.9004 2.246 13.9004 2.54946C13.9004 2.83269 13.6861 3.06587 13.4108 3.0957L13.3509 3.09893H10.1953C9.89182 3.09893 9.64581 2.85293 9.64581 2.54946C9.64581 2.26623 9.86011 2.03306 10.1354 2.00322L10.1953 2H13.3509Z"
				fill="white"
			/>
			<path
				strokeWidth={0.8}
				stroke="white"
				d="M17.6918 5.26514C17.9069 5.05112 18.2548 5.05204 18.4688 5.26719C18.6675 5.46696 18.681 5.78121 18.5096 5.99643L18.4668 6.04424L17.509 6.99698C17.2938 7.211 16.9459 7.21008 16.7319 6.99493C16.5332 6.79516 16.5198 6.48091 16.6912 6.26569L16.734 6.21788L17.6918 5.26514Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_345_1406">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default TimerActive;
