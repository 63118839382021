import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import { Site as SDKSite, SmartRule as SDKSmartRule } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { Lookup } from "../../components/Lookup";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import useStyle from "./SmartRules.style";
import { useRouteMatch } from "react-router-dom";
import AddEditRule from "./AddEditRule/AddEditRule";
import useChurnZero from "@hooks/useChurnZero";

const SmartRules: React.FC = (props: any) => {
  const classes = useStyle();
  const match = useRouteMatch<{ ruleSiteId: string, ruleId: string }>();
  let { ruleSiteId = "", ruleId = "" } = match.params;
  const [smartRules, setSmartRules] = useState<any[]>([]);
  const [showOneSmartRule, setShowOneSmartRule] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [clickedHead, setClickedHead] = useState("");
  const [lookupAnchor, setAnchor] = useState(null);
  const [filtersList, setFiltersList] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const tableHasFilters = !!selectedFilters["typeName"]?.length;
  const isInitialized = useStoreState((s) => s.isInitialized);
  const updateSelections = useStoreActions((s) => s.selections.updateSelections);
  const selections = useStoreState((s) => s.selections.selections);
  const { siteId = "" } = selections;
  const allUnits = useStoreState((s) => s.units.allUnits);
  const [error, setError] = useState(null);
  const allSites = useStoreState((s) => s.sites.allSites);
  const { canCreateSmartRules } = allSites?.[siteId || ""]?.permissions || {};
  const [selectedRule, setSelectedRule] = useState<any>({});

  const {trackEvent} = useChurnZero()

  // will get the smartRuleTypes from types after add it from backend side
  // const smartRuleTypes: any = {
  //   "0": "abnormalWorkingHours",
  //   "1": "frequentSetpointChanges",
  //   "2": "setpointMaintenance",
  //   "3": "unitStandbyTime"
  // };
  const smartRuleTypesMirror: {[key:string]:string} = {
    "0": "Abnormal unit operation time",
    "1": "High rate of Setpoint Changes",
    "2": "SetPoint Maintenance",
    "3": "Unit on High Demand"
  };

  useEffect(() => {
    if (!siteId)
      return;
    setIsLoading(true);
    SDKSite.getSiteSmartRules(siteId)
      .then((res: any) => setSmartRules(Object.values(res)))
      .catch((e: any) => { setError(e.message); })
      .finally(() => { setIsLoading(false); });

  }, [refresh, siteId]);

  useEffect(() => {
    const filtersListVal: any = {};
    smartRules.forEach((rule) => {
      filtersListVal[smartRuleTypesMirror?.[rule.type]] = true;
    });
    setFiltersList(Object.keys(filtersListVal));
  }, [smartRules]);

  useEffect(() => {
    if (ruleSiteId && ruleSiteId !== siteId) updateSelections({ type: "site", data: ruleSiteId });
    const tempRule = smartRules.find((rule: any) => rule.id === ruleId);
    if (tempRule) {
      onEdit(tempRule)
    }
  }, [smartRules]);

  if (!isInitialized) { return <Loading />; }

  const onDelete = async (payload: any) => {
    if (!siteId)
      return;
    let message = null;
    setSmartRules(smartRules.filter((rule: any) => rule.id !== payload.id));
    await SDKSmartRule.delSmartRule(payload.id)
      .catch((e: any) => {
        setRefresh(true);
        message = e.message;
      });
    return message;
  };

  const onEdit = (smartRule: any) => {
    setSelectedRule(smartRule);
    setShowOneSmartRule(true)
  };

  const onClose = () => {
    setShowOneSmartRule(false);
    setSelectedRule({});
  };

  const updateClickedHead = (event: any, columnHead: string) => {
    setClickedHead(columnHead);
    setAnchor(event.currentTarget);
  };

  const onApply = (newSelectedFilters: any) => {
    const selectedFiltersObject = { [clickedHead]: newSelectedFilters };
    setSelectedFilters({ ...selectedFilters, ...selectedFiltersObject });
    setClickedHead("");
  };

  const setRuleStatus = (smartRule: any) => {
    const data = {
      name: smartRule.name,
      data: smartRule.data,
      type: smartRule.type,
      units: smartRule.units,
      isEnabled: !smartRule.isEnabled
    };
    SDKSmartRule.updateSmartRule(smartRule.id, data).then(() => {
      setSmartRules(smartRules.map((rule: any) => rule.id === smartRule.id ? ({ ...rule, ...data }) : rule));
    })
      .catch((e: any) => { setRefresh(true); setError(e.message); });
  };

  const smartRuleList = () => {
    return siteId && (!!smartRules?.length || isLoading) ? (
      <Grid container={true} className={classes.smartRulesContainer}>
        <Paper elevation={0} className={classes.smartRulesPaper}>
          <TableContainer className={classes.smartRulesTableContainer}>
            <Table stickyHeader={true} className={classes.smartRulesTable} aria-label="customized table">
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                  >
                    {t`RULE NAME`}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="left"
                    onClick={(e: any) => updateClickedHead(e, "typeName")}
                  >
                    <div
                      className={classes.headContainer}
                    >
                      {t`TYPE`}
                      <FilterList
                        className={clsx(classes.filterStyle, {
                          [classes.blueFilter]: !_.isEmpty(selectedFilters.typeName)
                        })}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="center"
                  >
                    {t`STATUS`}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableHeadCell }}
                    align="center"
                  >
                    {t`DELETE`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? <Paper elevation={0} className={clsx(classes.paperTableContainer, classes.loaderPaper)}>
                  <Paper className={classes.loaderContainer}>
                    <CircularProgress />
                    <Typography variant="h5">{t`Loading Smart Rules`}</Typography>
                  </Paper>
                </Paper>
                  : _.orderBy(searchTerm?.length ? smartRules?.filter((item: any) => (item?.name?.toUpperCase()?.includes(searchTerm?.toUpperCase()) || item?.units?.find((unit: any) => allUnits[unit]?.name?.toUpperCase()?.includes(searchTerm?.toUpperCase())))) : smartRules, [(item: any) => item?.name?.toUpperCase()],
                    ["asc"]).filter((rule) => !tableHasFilters || selectedFilters?.["typeName"]?.indexOf(smartRuleTypesMirror?.[rule.type]) !== -1).map((rule: any, index: number) => {
                      const { canDelete, canUpdate } = rule?.permissions || {};

                      return (
                        <TableRow
                          hover={true}
                          tabIndex={-1}
                          key={index}
                          classes={{ root: classes.overWritePadding }}
                          onDoubleClick={() => { onEdit(rule); }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            classes={{ root: classes.overWritePadding }}
                            align="left"
                          >
                            {rule.name}
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="left">
                            {smartRuleTypesMirror?.[rule.type]}
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="center">
                            <CoolSwitch
                              className={!canUpdate && classes.disabledStyle}
                              disabled={!canUpdate}
                              switchChange={() => {
                                setRuleStatus(rule);
                              }}
                              checked={rule.isEnabled}
                              name="is2FA"
                            />
                          </TableCell>
                          <TableCell classes={{ root: classes.overWritePadding }} align="center">
                            <Delete
                              disabled={!canDelete}
                              type={t`Rule`}
                              object={rule}
                              detach={onDelete}
                              buttonClass={classes.deleteIcon}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {clickedHead && (
          <Lookup
            filtersList={filtersList}
            appliedFilters={selectedFilters?.[clickedHead]}
            onApply={onApply}
            lookupAnchor={lookupAnchor}
            onClose={() => setClickedHead("")}
            tableHasFilters={tableHasFilters}
            clearAllFilters={() => setSelectedFilters({})}
          />
        )}
      </Grid>
    )
      : <Typography className={classes.noData}>{t`There are no smart rules for this site`}</Typography>;
  };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchTerm}
      onChange={(event: any) => setSearchTerm(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot, input: classes.inputBase },
        endAdornment:
          !searchTerm ? (<Search />) : (
            <IconButton
              onClick={() => setSearchTerm("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  const handleAddNewRuleClick = () => {
    trackEvent('SmartRuleAddRule','The user initiated a Smart Rule rule setup');
    setShowOneSmartRule(true);
    setRefresh(false);
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          path={[t`Automation`]}
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection={true}
          hideUnitSelection={true}
          countControlUnits
          hideIndoor
          hideOther
          hideBsBox
          hideOutdoor
          screenTitle="smartRuleReports"
          searchComponent={searchComponent}
        />
        {!!siteId &&
          <div className={classes.buttonContainer}>
            <Button
              disabled={!canCreateSmartRules}
              onClick={handleAddNewRuleClick}
            >
              {t`Add New Rule`}
            </Button>
          </div>}
        {siteId && smartRuleList()}

        {showOneSmartRule &&
          <AddEditRule
            rule={selectedRule}
            setRefresh={setRefresh}
            setError={setError}
            setSmartRules={setSmartRules}
            smartRules={smartRules}
            onClose={onClose}
          />}

        {error && <ErrorBox error={error} onClose={() => { setError(null); }} />}
        {!siteId && <FilterRequire type={t`site`} />}
      </div>
    </div>
  );
};

export default SmartRules;
