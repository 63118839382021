import { Dialog, Drawer, IconButton, Popover, TextareaAutosize, Tooltip, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import clsx from "clsx";
import { FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiDownload } from "react-icons/fi";
import { MoonLoader } from "react-spinners";
import { t } from "ttag";
import * as Yup from "yup";
import Button from "../../cool_widgets/Button";
import { Close, Delete, Download } from "../../icons";
import { ICustomerMap } from "../../models/Customers";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ISiteMap } from "../../models/Sites";
import { toC, toF, toKgPerCm2, toPSI } from "../../services/converter";
import { downloadAs } from "../../services/download";
import CommonUtils from "../../utils/CommonUtils";
import ErrorBox from "../WarnningBox/ErrorBox";
import styles from "./AddRule.style";
import Condition from "./Condition";
import ConditionsGroup from "./ConditionsGroup";
import conditionUtils from "./conditionUtils";
import { Box, CustomAutoComplete, CustomedTextField, CustomSelect, SystemWrapper, UnitWrapper } from "./CustomedComponents";
import ItemsTree from "./ItemsTree";
import SensorCondition from "./SensorCondition";
import SensorsTree from "./SensorsItemsTree";
import useChurnZero from "@hooks/useChurnZero";
import LightTooltip from "@components/Tooltip/LightTooltip";
const maxFileSizeAllowed = 50000000; //50MB

const hasValue = (value: any) => {
  return !!value || value === 0;
};
const ruleTypes = [{ id: "Custom Rule", name: "Custom Rule" }];
const newRule = { relation: "and", unitType: "", parameter: "", operator: "", thresholdOperator: "", value: "", duration: "", parameter2: "", indoorPerc: 1, outdoorPerc: 1, type: null };
const sensorBrandvalue = -88;
const operationalTelemetryValue = -77;
const sensorCode = 58;

const AddRule = (props: any) => {
  const classes = styles();
  const { disabled = false, trapTemplatesToBrands = {}, trapTemplatesMap = {}, template, close, createRule, editTrap, updateRule, siteId, customerId } = props;
  const templateBaseOption = editTrap ? [{ id: editTrap.userSelections?.trapTemplateId, name: "Library Rule" }] : [];

  const types = useStoreState((s) => s.types);
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  const allSites = useStoreState((state) => state.sites.allSites);
  const uploadFile = useStoreActions((action) => action.files.uploadFile);
  const sites: ISiteMap = {
    [siteId]: allSites[siteId]
  };
  const getServiceParams = useStoreActions((action) => action.traps.getServiceParams);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);

  const customers: ICustomerMap = {
    [customerId]: allCustomers[customerId]
  };

  const user = useStoreState((s) => s.users.me);
  const userPref = useStoreState((s) => s.users.userPreferences);
  const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
  const getCustomersSensorsData = useStoreState((s) => s.sensors.getCustomersSensorsData);
  const getCustomersPowerMetersData = useStoreState((s) => s.powerMeters.getCustomersPowerMetersData);
  const ruleParameters = useStoreState((s) => s.ruleParameters);
  const getAllUnits = useStoreActions((a) => a.units.getUnitsFromStore);
  const getSensorGroupNameBySensorId = useStoreState((s) => s.sensors.getSensorGroupNameBySensorId);
  const [priority, setPriority] = useState<number>();
  const [ruleName, setRuleName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<any>("");
  const [ruleType, setRuleType] = useState<any>("Custom Rule");
  const [selectedConditions, setSelectedConditions] = useState<any>([]);
  const [indoorParamsMap, setIndoorParams] = useState<any>([]);
  const [conditions, setConditions] = useState<any>([]);
  const [groups, setGroups] = useState<any>({});
  const [groupsList, setGroupsList] = useState<any>([]);
  const [outdoorParamsMap, setOutdoorParams] = useState<any>([]);
  const [brandSystems, setBrandSystems] = useState<any>({});
  const [openTree, setOpenTree] = useState<any>("");
  const [selectedUnits, setSelectedUnits] = useState<any>([]);
  const [selectedSystems, setSelectedSystems] = useState<any>([]);
  const [brandToBeChange, setBrandToBeChange] = useState<any>(null);
  const [showDeletingUnitsWarnning, handleUnitsWarning] = useState<any>({});
  const [systemsToSitesMap, setSystemsToSitesMap] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [enumParams, setEnumParams] = useState<any>([]);
  const [indoorParams, setFilteredIndoorParams] = useState<any>([]);
  const [outdoorParams, setFilteredOutdoorParams] = useState<any>([]);
  const [ruleTypeTobeChanged, setRuleTypeTobeChanged] = useState<any>(null);
  const [cancelClicked, setCancelClicked] = useState<boolean>(false);
  const [selectedBrandCustomers, setSelectedBrandCustomers] = useState<any>({});
  const [isTemplateBased, setIsTemplateBased] = useState<boolean>(false);
  const { temperatureScale: userTempScale = 1, measurementUnits: userPressureScale = 2 } = user;
  const { trapTypes, hvacBrands, unitTypes, measurementUnitTypes, temperatureScale, pressureScale, sensorTypes, sensorMeasurementUnits, trapPriorityTypes } = types;
  const [brands, setBrands] = useState<any>([]);
  const [sensorsOptions, setSensorsOptions] = useState<any>([]);
  const [sensorsPerSite, setSensorsPerSite] = useState<any>({});
  const [powerMetersPerSite, setPowerMetersPerSite] = useState<any>({});
  const [customerPowerMeters, setCustomerPowerMeters] = useState<any>({});
  const descriptionRef: any = useRef(null);
  const [customerSensors, setCustomerSensors] = useState<any>({});
  const [selectedSensors, setSelectedSensors] = useState<any>([]);
  const [selectedPowerMeters, setSelectedPowerMeters] = useState<any>([]);
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [brandsObject, setBrandsObject] = useState<any>({});
  const [allUnits, setAllUnits] = useState<any>({});
  const [attachmentPopup, setAttachmentPopup] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<any>(null);
  const [isODRule, setIsODRule] = useState<boolean>(true);

  const {trackEvent} = useChurnZero()

  const trapPriorityOptions = Object.keys(trapPriorityTypes).reduce((obj: any, priority: any) => {
    obj.push({ name: trapPriorityTypes[priority], value: +priority });
    return obj;
  }, []);

  useEffect(() => {
    setAllUnits(getAllUnits());
  }, []);

  useEffect(() => {
    setGroupsList((Object.values(groups)).sort((g: any, g2: any) => g.index - g2.index));
  }, [groups]);

  useEffect(() => {
    //check if customer has sensors
    let allBrands: any = [];
    const brands = (hvacBrands?.filter(({ value }: any) => value !== 20 && value !== 21) || []).sort((a, b) => {
      // Normalize names by trimming spaces
      const nameA = a.name.trim().toLowerCase();
      const nameB = b.name.trim().toLowerCase();

      // Place "Unknown" at the top
      if (nameA === "unknown") return -1;
      if (nameB === "unknown") return 1;

      // Otherwise, sort alphabetically, ignoring leading spaces
      return nameA.localeCompare(nameB);
    });

    if (!template) {
      if (editTrap && (editTrap.type !== trapTypes.operationalTelemetry && editTrap.userSelections?.brand !== sensorBrandvalue)) {
        allBrands = brands;
      } else {
        const { options, sensorsPerSite, customerSensors } = getCustomersSensorsData();
        const { powerMeterOption, powerMetersPerSite, customerPowerMeters } = getCustomersPowerMetersData();
        if (powerMeterOption) {
          options.push(powerMeterOption);
          setPowerMetersPerSite(powerMetersPerSite);
          setCustomerPowerMeters(customerPowerMeters);
        }
        allBrands.push({ name: t`Operational Controls`, value: operationalTelemetryValue });
        if (options.length) {
          setSensorsOptions(options);
          setSensorsPerSite(sensorsPerSite);
          setCustomerSensors(customerSensors);
          allBrands.push({ name: t`External I/O devices`, value: sensorBrandvalue });
        }

        let showAllBrands = false;
        Object.values(sites).forEach((site: any) => {
          const { predictiveMaintenance } = site;
          if (!showAllBrands && predictiveMaintenance) {
            showAllBrands = true;
          }
        });

        if (showAllBrands) {
          allBrands = [...allBrands, ...brands];
        }

      }
    }

    const brandsObject: any = {};
    allBrands.forEach((brand: any) => brandsObject[brand.value] = brand);
    setBrands(allBrands);
    setBrandsObject(brandsObject);
    setDataReady(true);

  }, []);

  const getAllParams = (brand: number) => {
    if (brand === sensorBrandvalue) {
      setIndoorParams({});
      setOutdoorParams({});
      setEnumParams({});
      setFilteredIndoorParams([]);
      setFilteredOutdoorParams([]);
      return;
    }

    if (brand === operationalTelemetryValue) {
      const objectData: any = {};
      ruleParameters.forEach((param: any) => {
        objectData[param.code] = param;
      });
      setIndoorParams(objectData);
      setOutdoorParams({});
      setEnumParams(ruleParameters.map((param: any) => param.code));
      setFilteredIndoorParams(ruleParameters);
      setFilteredOutdoorParams([]);
      return;
    }
    Promise.all([getServiceParams({ site: siteId, brand, unitType: unitTypes.service }), getServiceParams({ site: siteId, brand, unitType: unitTypes.outdoor })]).then((response: any) => {
      const enumParamsValues: any = [];

      Object.values({ ...response[0], ...response[1] }).forEach((param: any) => param.enum && enumParamsValues.push(param.code));
      let indoorParamsArray = Object.values(response[0]).filter((param: any) => (_.isUndefined(param.enabledInTriggers) || param.enabledInTriggers === true) && !param.isCustomParam);
      let outdoorParamsArray = Object.values(response[1]).filter((param: any) => (_.isUndefined(param.enabledInTriggers) || param.enabledInTriggers === true) && !param.isCustomParam);

      setIndoorParams(response[0]);
      setOutdoorParams(response[1]);
      setEnumParams(enumParamsValues);
      setFilteredIndoorParams(indoorParamsArray);
      setFilteredOutdoorParams(outdoorParamsArray);
    });
  };

  useEffect(() => {
    if (!selectedBrand) {
      return;
    }

    setIndoorParams({});
    setOutdoorParams({});
    setEnumParams([]);
    setFilteredIndoorParams([]);
    setFilteredOutdoorParams([]);
    getAllParams(selectedBrand);
    getBrandSystems(selectedBrand);
  }, [selectedBrand]);

  useEffect(() => { conditionUtils.generateId.reset(); }, []);

  const getBrandSystems = (brand: number) => {
    let systemsMappedToSites: any = {};
    let systems: any = {};
    let brandSites: any = {};
    let brandCustomers: any = {};
    const onlyControls: boolean = brand === operationalTelemetryValue;
    for (const system in allSystems) {
      const { brandNum, site } = allSystems[system];

      if (onlyControls || (!_.isUndefined(brandNum) && brandNum === brand)) {
        const siteObj = sites[site];
        if (!siteObj) {
          continue;
        }
        const { predictiveMaintenance, hvacAdvancedOperations } = siteObj;

        if (!onlyControls && !predictiveMaintenance) {
          continue;
        }

        if (onlyControls && !predictiveMaintenance && !hvacAdvancedOperations) {
          continue;
        }

        const fullSystem: any = { ...allSystems[system], indoorUnits: [], outdoorUnits: [] };

        if (systemsMappedToSites[site]) {
          systemsMappedToSites[site].push(system);

        } else {
          systemsMappedToSites[site] = [system];
        }

        brandSites[site] = sites[site];

        if (fullSystem.units.length === 0) {
          systems[system] = fullSystem;
          continue;
        }
        const { units } = fullSystem;
        for (const i in units) {
          if (!allUnits[units[i]]) {
            continue;
          }

          if (onlyControls) {
            if (allUnits[units[i]].type === unitTypes.indoor && allUnits[units[i]].isVisible && allUnits[units[i]].subType !== 2 && fullSystem.indoorUnits?.indexOf(units[i]) === -1) {
              fullSystem.indoorUnits.push(units[i]);
            }
          } else {
            if (allUnits[units[i]].type === unitTypes.service && allUnits[units[i]].isVisible && fullSystem.indoorUnits?.indexOf(units[i]) === -1) {
              fullSystem.indoorUnits.push(units[i]);
            } else if (allUnits[units[i]].type === unitTypes.outdoor && fullSystem.outdoorUnits?.indexOf(units[i]) === -1) {
              fullSystem.outdoorUnits.push(units[i]);
            }
          }
          systems[system] = fullSystem;
        }
      }
    }

    Object.values(brandSites).forEach((site: any) => {
      const { customer, id } = site;
      // if (!sitesFlags[id]?.hasUnitDiag) {
      //   return;
      // } do this from outside
      if (brandCustomers[customer]) {
        brandCustomers[customer].sites.push(id);
      } else {
        if (customers[customer]) {
          brandCustomers[customer] = customers[customer];
          brandCustomers[customer].sites = [id];
        }
      }
    });

    setBrandSystems(systems);
    setSystemsToSitesMap(systemsMappedToSites);
    setSelectedBrandCustomers(brandCustomers);
  };

  const handleBrandChange = (brand: number, name: string, setValue: any, ruleName: string, priority: number | undefined) => {
    if ((!brand || _.isEmpty(conditions) && _.isEmpty(groups))) {
      setRuleName(ruleName);
      setPriority(priority);
      setValue(name, brand);
      setValue("ruleType", "Custom Rule");
      setSelectedSystems([]);
      setSelectedUnits([]);
      setSelectedSensors([]);
      if (brand === sensorBrandvalue) {
        setSelectedBrand(brand);
        const id = conditionUtils.generateId();
        setConditions([{ ...newRule, id, unitType: 5, code: sensorCode }]);
        setSelectedBrand(brand);
        return;
      }
      setSelectedBrand(brand);
      getBrandSystems(brand);
      return;
    }
    setBrandToBeChange({ brand, ruleName });

  };

  const handleWraningBrandChanging = () => {
    setRuleType("Custom Rule");
    setRuleName(brandToBeChange.ruleName);
    setSelectedBrand(brandToBeChange.brand);
    setBrandToBeChange(null);
    if (brandToBeChange.brand === sensorBrandvalue) {
      const id = conditionUtils.generateId();
      setConditions([{ ...newRule, id, unitType: 5, code: sensorCode }]);
    } else {
      setConditions([]);
    }
    setGroups({});
    setSelectedSystems([]);
    setSelectedUnits([]);
    setSelectedSensors([]);

  };

  const handleRuleTypeChange = (newRuleType: string, name: string, setValue: any, currentrRuleType: string, ruleName: string, selectedBrand: string, priority: number | undefined) => {
    if (_.isEmpty(conditions) && _.isEmpty(groups)) {
      if (newRuleType !== "Custom Rule") {
        trapTemplatesMap[newRuleType] && extractConditions(trapTemplatesMap[newRuleType], true);
        setRuleType(newRuleType);
        setRuleName(ruleName);
        setPriority(priority);
        setSelectedBrand(selectedBrand);

        if (trapTemplatesMap[newRuleType].fileId)
          setFile({ fileId: trapTemplatesMap[newRuleType].fileId, fileURL: trapTemplatesMap[newRuleType].fileURL, name: trapTemplatesMap[newRuleType].fileName })
        else if (trapTemplatesMap[currentrRuleType] && file && file.fileId === trapTemplatesMap[currentrRuleType].fileId)
          setFile(null);

        setDescription(trapTemplatesMap[newRuleType].description);
      } else {
        setValue(name, newRuleType);
        if (trapTemplatesMap[currentrRuleType] && file && file.fileId === trapTemplatesMap[currentrRuleType].fileId)
          setFile(null)
      }
      return;
    }

    setRuleTypeTobeChanged({ ruleType: newRuleType, selectedBrand, ruleName });

  };

  const handleRuleTypeWarning = () => {
    setRuleType(ruleTypeTobeChanged.ruleType);
    setRuleName(ruleTypeTobeChanged.ruleName);
    setSelectedBrand(ruleTypeTobeChanged.selectedBrand);

    if (trapTemplatesMap[ruleType] && file && file.fileId === trapTemplatesMap[ruleType].fileId)
      setFile(null)

    if (ruleTypeTobeChanged.ruleType === "Custom Rule") {
      setDescription('');
      setConditions([]);
      setGroups({});
    } else {
      const newRuleType = ruleTypeTobeChanged.ruleType;
      if (trapTemplatesMap[newRuleType].fileId)
        setFile({ fileId: trapTemplatesMap[newRuleType].fileId, fileURL: trapTemplatesMap[newRuleType].fileURL, name: trapTemplatesMap[newRuleType].fileName })

      extractConditions(trapTemplatesMap[ruleTypeTobeChanged.ruleType], true);
      setDescription(trapTemplatesMap[newRuleType].description);
    }

    setRuleTypeTobeChanged(null);
  };

  const handleAcceptDeletingUnits = () => {
    const { id, conditions, value, name, inGroup, groupKey, groups, item = "unit" } = showDeletingUnitsWarnning;

    if (item === "sensor") {
      conditions[id] = { ...conditions[id], [name]: value, operator: "", value: "", parameter: "" };
      setConditions([...conditions]);
      setSelectedSensors([]);
      handleUnitsWarning({});
      return;
    }
    if (item === "powerMeter") {
      conditions[id] = { ...conditions[id], [name]: value, operator: "", value: "", parameter: "" };
      setConditions([...conditions]);
      setSelectedPowerMeters([]);
      handleUnitsWarning({});
      return;
    }

    if (inGroup) {
      groups[groupKey].conditions[id] = { ...groups[groupKey].conditions[id], [name]: value };
      setGroups({ ...groups });
    } else {
      conditions[id] = { ...conditions[id], [name]: value };
      setConditions([...conditions]);
    }
    setSelectedUnits([]);
    handleUnitsWarning({});
  };

  const handleDeclineDeletingUnits = () => {
    const { id, conditions } = showDeletingUnitsWarnning;
    conditions[id] = { ...conditions[id] };
    setConditions([...conditions]);
    handleUnitsWarning({});
  };

  const addCondition = (conditions: any, groups: any, ruleName: string, brand: number | null, priority: number | undefined) => {
    const id = conditionUtils.generateId();
    setConditions([...conditions, { id, ...newRule, unitType: brand === operationalTelemetryValue ? +unitTypes?.indoor : "" }]);
    setGroups({ ...groups });
    setPriority(priority);
    setRuleName(ruleName);
    setSelectedBrand(brand);
  };

  const onSelect = (conditionId: number, status: boolean) => {
    if (status) {
      selectedConditions.push(conditionId);
      setSelectedConditions([...selectedConditions]);
      return;
    }

    setSelectedConditions(selectedConditions.filter((id: number) => id !== conditionId));
  };

  const deleteCondition = (conditions: any, index: number) => {

    const condToDelete = conditions.filter((condition: any) => condition.id === index)[0];

    if (conditions.length === 1) {

      if (Object.values(groups).length < 1) {
        setSelectedUnits([]);
        setSelectedSystems([]);
      }

      setConditions([]);
      setSelectedConditions([]);
      return;
    }

    selectedConditions.includes(index) && setSelectedConditions(selectedConditions.filter((id: number) => id !== index));
    setConditions(conditions.filter((condition: any) => condition.id !== index));
  };

  const addGroup = (conditions: any, ruleName: string, brand: number | null, priority: number | undefined) => {
    const id = "group" + conditionUtils.generateId();
    const group: { id: string; relation: string; conditions: any } = { id, relation: "and", conditions: [] };

    conditions.forEach((condition: any) => selectedConditions.includes(condition.id) && group.conditions.push(condition));

    setConditions([...Object.values(conditions).filter((condition: any) => !selectedConditions.includes(condition.id))]);
    setSelectedConditions([]);
    setGroups({ ...groups, [id]: group });
    setRuleName(ruleName);
    setPriority(priority);
    setSelectedBrand(brand);
  };

  const parseNode = (condition: any) => {
    const { duration, operator, parameter, parameter2, value, thresholdOperator, unitType } = condition;

    let uType1: any, uType2: any;
    if (+selectedBrand === operationalTelemetryValue) {
      uType1 = unitTypes.indoor;
      uType2 = unitTypes.indoor;
    } else {
      uType1 = unitType === "mixed" ? +unitTypes["service"] : unitType ? unitType : null;
      if (operator && (operator.includes("threshold") || operator.includes("parameter"))) {
        uType2 = unitType === "mixed" ? +unitTypes["outdoor"] : unitType ? unitType : null;
      }
    }

    const paramObj = indoorParamsMap[parameter] || outdoorParamsMap[parameter];
    const paramUnit = paramObj?.data_unit_of_measurement?.toUpperCase() || "";

    const paramUnitType = paramUnit === "°C" ?
      measurementUnitTypes["temperature"] :
      ((paramUnit === "KG/CM2" || paramUnit === "PSI" || paramUnit === "MPA") ? measurementUnitTypes["pressure"] : measurementUnitTypes["unspecified"]);
    const scale = measurementUnitTypes["temperature"] === paramUnitType ? userTempScale : (measurementUnitTypes["pressure"] === paramUnitType ? userPressureScale : null);

    const valMeasureObj = {
      scaleType: paramUnitType,
      scale
    };

    const obj: any = operator.includes("threshold") ?
      {
        relation: thresholdOperator,
        thresholdValue: +value,
        ...valMeasureObj,
        "rules": [
          {
            code: parameter,
            operator: "self",
            unitType: uType1
          },
          {
            code: parameter2,
            operator: "self",
            unitType: uType2
          }
        ]
      }
      : operator.includes("parameter") ? {
        relation: "comparison",
        comparisonOperator: operator.charAt(0),
        "rules": [
          {
            code: parameter,
            operator: "self",
            unitType: uType1
          },
          {
            code: parameter2,
            operator: "self",
            unitType: uType2
          }
        ]
      } :
        {
          rules: [
            {
              ...valMeasureObj,
              code: parameter,
              operator,
              value: +value,
              unitType: uType1
            }
          ]
        };

    if (duration || duration === 0) {
      let durationTxt = `${duration}`;
      if (durationTxt.indexOf(":") === -1) {
        durationTxt = `${duration}:00`;
      }
      const timeArray = durationTxt.split(":");
      obj["triggerDelayTimeInMinutes"] = Number(timeArray[0]) * 60 + Number(timeArray[1]);
    }

    return { ...obj, isCondition: true };
  };

  const parseSensorNode = (condition: any) => {
    const { operator, value, type, code, duration } = condition;
    const unitType = 5;

    const scaleType = +type === 1 ? measurementUnitTypes["temperature"] : measurementUnitTypes["unspecified"];
    const scale = +type === 1 ? userTempScale : null; // type = 1 is temp sensor
    const sensorObjects: any = [];

    selectedSensors.forEach((id: string) => {
      const item: any = { id };
      const { type, userData = {} } = customerSensors[id];
      const sensorType = sensorTypes[type] || {};
      const { enableMinMaxSelection, measurementUnits, enableMeasurementUnitSelection } = sensorType;
      const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
      const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
      const { rangeMax: userMax, rangeMin: userMin } = userData;
      const analogSensor = !sensorUnit || type === 130 || type === 7;
      const hasUserMinMax = analogSensor && enableMinMaxSelection && hasValue(userMax) && hasValue(userMin);

      if (hasUserMinMax) {
        item.userMin = userMin;
        item.userMax = userMax;
      }

      sensorObjects.push(item);
    });
    const sensorRule: any = {
      scaleType,
      scale,
      operator,
      value: +value,
      unitType,
      code
    };

    const timeArray = duration.split(":");
    const ruleSets: any = {
      ruleSets: [{ rules: [sensorRule], triggerDelayTimeInMinutes: Number(timeArray[0]) * 60 + Number(timeArray[1]), isCondition: true }],
      systemIds: [],
      systemUnitType: 5,
      unitIds: [],
      sensorObjects
    };

    return { ruleSets, type };
  };

  const parsePowerMeterNode = (condition: any) => {
    const { operator, value, type, duration, parameter } = condition;
    const unitType = 6;
    const scaleType = measurementUnitTypes["unspecified"];
    const scale = null;

    const powerMeterRule: any = {
      scaleType,
      scale,
      operator,
      value: +value,
      unitType,
      code: parameter
    };

    const timeArray = duration.split(":");
    const ruleSets: any = {
      ruleSets: [{ rules: [powerMeterRule], triggerDelayTimeInMinutes: +Number(timeArray[0]) * 60 + Number(timeArray[1]), isCondition: true }],
      systemIds: [],
      systemUnitType: "",
      unitIds: [],
      sensorObjects: [],
      powerMeterIds: selectedPowerMeters
    };

    return { ruleSets, type };
  };

  const extractRelations = (nodes: any[]) => {
    if (!nodes.length || nodes.length === 1) {
      return [];
    }
    const relations = nodes.map((node: any) => node.relation);
    relations.pop();
    return relations;
  };

  const isBasicNode = (node: any) => {
    return "operator" in node;
  };

  const groupNodes = (index: number, nodes: any[], relations: string[]) => {

    const node1 = isBasicNode(nodes[index]) ? parseNode(nodes[index]) : nodes[index];
    const node2 = isBasicNode(nodes[index + 1]) ? parseNode(nodes[index + 1]) : nodes[index + 1];

    const newNode = {
      relation: relations[index],
      ruleSets: [
        node1,
        node2
      ]
    };

    relations.splice(index, 1);
    nodes.splice(index + 1, 1);
    nodes[index] = newNode;

    return { relations, nodes };
  };

  const groupByRelation = (relations: any[], nodes: any[], relation: string) => {

    let result: any = { relations, nodes };
    let firstAndIndex = result.relations.indexOf(relation);

    while (firstAndIndex !== -1) {
      result = groupNodes(firstAndIndex, nodes, result.relations);
      firstAndIndex = result.relations.indexOf(relation);
    }
    return result;
  };

  const arrayToTree = (conditionsArray: any[]) => {

    const relations = extractRelations(conditionsArray);
    const groupedAnds = groupByRelation(relations, conditionsArray, "and");
    const tree = groupByRelation(groupedAnds.relations, groupedAnds.nodes, "or");
    return tree.nodes;
  };

  const onSubmit = (values: any) => {

    trackEvent('AnomaliesSaveRule','The user created an Anomaly rule')

    setErrorMessage("");
    if (!customerId && !template) {
      setErrorMessage(t`Please select a customer from top filters before trying to add a new rule `);
      return;
    }

    const { groups, conditions1, selectedBrand, ruleName, priority } = values;
    let mainRuleSet: any = null;
    let trapBody: any = null;
    let sensorType: any = null;

    if (selectedBrand === sensorBrandvalue) {
      const conditionsArr: any = Object.values(conditions1);
      const firstCondition: any = conditionsArr[0] || {};
      const { ruleSets, type } = firstCondition.type === "powerMeter" ? parsePowerMeterNode(firstCondition) : parseSensorNode(firstCondition);
      mainRuleSet = ruleSets;
      sensorType = type;
    } else {
      let numOfIndoorUnits = "";
      let numOfOutdoorUnits = "";
      let indoorPerc = false;
      let outdoorPerc = false;
      const conditionsArr: any = Object.values(conditions1);

      conditionsArr.forEach((condition: any, index: number) => {
        if (condition.unitType === "iduNum") {
          numOfIndoorUnits = condition.value;
          indoorPerc = condition.indoorPerc === 2 ? true : false;
          const prevElem = conditionsArr[index - 1];
          const nextElem = conditionsArr[index + 1];
          if (prevElem) {
            conditionsArr[index - 1] = { ...prevElem, relation: "and" };
          }
          if (nextElem) {
            conditionsArr[index + 1] = { ...nextElem, relation: "and" };
          }
          return;
        }

        if (condition.unitType === "oduNum") {
          numOfOutdoorUnits = condition.value;
          outdoorPerc = condition.outdoorPerc === 2 ? true : false;
          const prevElem = conditionsArr[index - 1];
          const nextElem = conditionsArr[index + 1];
          if (prevElem) {
            conditionsArr[index - 1] = { ...prevElem, relation: "and" };
          }
          if (nextElem) {
            conditionsArr[index + 1] = { ...nextElem, relation: "and" };
          }
          return;
        }

      });

      const conditions = conditionsArr.filter((condition: any) => !(condition.unitType === "iduNum" || condition.unitType === "oduNum"));

      if (_.isEmpty(groups) && _.isEmpty(conditions)) {
        setErrorMessage("One condition at least is required");
        return;
      }

      const hasConditions = !!conditions.length;
      const pageNodes = [];
      const pageRelations: any = [];
      groupsList.forEach((group: any) => {
        pageRelations.push(groups[group.id].relation);
        const groupTree = arrayToTree(group.conditions)[0];
        groupTree.isGroup = true;
        groupTree.index = group.index;
        pageNodes.push(groupTree);
      });

      if (!hasConditions) {
        pageRelations.pop();
      }

      if (conditions.length) {
        const conditionsTree = conditions.length > 1 ? arrayToTree(conditions)[0] : parseNode(conditions[0]);
        pageNodes.push(conditionsTree);
      }

      const groupPageByAnds = groupByRelation(pageRelations, pageNodes, "and");
      const pageTree = groupByRelation(groupPageByAnds.relations, groupPageByAnds.nodes, "or").nodes;

      mainRuleSet = {
        ruleSets: pageTree
      };
      const {
        hasIndoor,
        hasOutdoor,
        hasMixed
      } = countTypes(values);
      if (selectedSystems) {
        mainRuleSet["systemIds"] = [...selectedSystems];
        mainRuleSet["systemUnitType"] = +selectedBrand === operationalTelemetryValue ? unitTypes?.indoor : (hasMixed || (hasIndoor && hasOutdoor)) ? 4 : (hasIndoor && !hasOutdoor ? +unitTypes["service"] : +unitTypes["outdoor"]);
      }

      if (selectedUnits) {
        mainRuleSet["unitIds"] = [...selectedUnits];
      }

      mainRuleSet.sensorObjects = [];
      mainRuleSet.powerMeterIds = [];

      if (numOfIndoorUnits && +numOfIndoorUnits > 0) {
        mainRuleSet["numberOfIndoorUnitsForEvent"] = +numOfIndoorUnits;
        mainRuleSet["numberOfIndoorUnitsForEventIsPerc"] = indoorPerc;
      }

      if (numOfOutdoorUnits && +numOfOutdoorUnits > 0) {
        mainRuleSet["numberOfOutdoorUnitsForEvent"] = +numOfOutdoorUnits;
        mainRuleSet["numberOfOutdoorUnitsForEventIsPerc"] = outdoorPerc;
      }

    }
    trapBody = {
      name: ruleName,
      priority,
      description: description || "",
      type: +selectedBrand === operationalTelemetryValue ? trapTypes.operationalTelemetry : trapTypes.customTelemetry,
      userSelections: {
        brand: +selectedBrand === operationalTelemetryValue ? editTrap ? editTrap?.userSelections?.brand || "all" : "all" : selectedBrand,
        isTemplateBased: ruleType !== "Custom Rule" ? true : false,
        trapTemplateId: ruleType !== "Custom Rule" ? ruleType : undefined,
        sensorType
      },
      trapSets: [
        {
          ruleSets: [
            mainRuleSet
          ]
        }
      ]
    };

    if (!template) {
      if (file)
        trapBody.fileId = file.fileId;

      if (editTrap) {
        if (!file && editTrap.fileId)
          trapBody.fileId = null;

        delete trapBody.type;
        updateRule(trapBody, editTrap.id)
          .finally(() => close());
      }
      else {
        trapBody.isEnabled = true;
        createRule(trapBody, customerId)
          .finally(() => close());
      }
      return;
    }

    trapBody.userSelections.isTemplateBased = undefined;
    if (editTrap) {
      delete trapBody.type;
      updateRule(trapBody, editTrap.id)
        .finally(() => close());
    }
    else {
      createRule(trapBody)
        .finally(() => close());
    }
  };

  const saveSelectedItems = (systems: any, units: any) => {
    trackEvent('AnomaliesAddUnits','The user added units to the Anomaly rule');
    setSelectedUnits(units);
    setSelectedSystems(systems);
    setOpenTree("");
  };

  const saveSensorSelectedItems = (sensors: any) => {
    setSelectedSensors(sensors);
    setOpenTree("");
  };

  const savePowerMetersSelectedItems = (items: any) => {
    setSelectedPowerMeters(items);
    setOpenTree("");
  };

  const checkIsODOnly = (conditions: any) => {
    for (let condition of conditions) {
      if (+condition.unitType !== 2) {
        return false;
      }
    }
    return true;
  }

  const openTreeDiag = (conditions: any, groups: any, mode: string, name: string, itemType: string) => {
    const isODOnly = checkIsODOnly(conditions);
    setIsODRule(isODOnly);

    setConditions([...conditions]);
    setGroups({ ...groups });

    setOpenTree({ type: selectedBrand === operationalTelemetryValue ? "indoorUnits" : mode, itemType });
    setRuleName(name);
  };

  const unGroup = (key: any, conditions: any, groups: any) => {
    setConditions([...conditions, ...groups[key].conditions]);
    delete groups[key];
    setGroups({ ...groups });
  };

  const deleteGroupCondition = (conditions: any, groups: any, key: any, conditionIndex: any) => {
    if (groups[key].conditions.length === 2) {
      setConditions([groups[key].conditions[conditionIndex === 0 ? 1 : 0], ...conditions]);
      delete groups[key];
      setGroups({ ...groups });
      return;
    }

    const newConditions = groups[key].conditions.filter((condition: any, index: number) => index !== conditionIndex);
    setGroups({ ...groups, [key]: { ...groups[key], conditions: newConditions } });
  };
  const getObjectSchema = () => {
    let objectGroups: any = {};

    Object.keys(groups).forEach((key: string) => objectGroups[key] = Yup.object().shape({
      conditions: Yup.array()
        .of(
          Yup.object().shape({
            unitType: Yup.string()
              .required(t`Required`),
            parameter: Yup.string().when(["unitType"], {
              is: (unitType) => unitType && !["iduNum", "oduNum"].includes(unitType),
              then: Yup.string().required(t`Required`),
              otherwise: Yup.string()
            }),
            operator: Yup.string().when(["unitType"], {
              is: (unitType) => unitType && !["iduNum", "oduNum"].includes(unitType),
              then: Yup.string().required(t`Required`),
              otherwise: Yup.string()
            }),
            thresholdOperator: Yup.string().when(["operator"], {
              is: (operator) => operator && operator === "threshold",
              then: Yup.string().required(t`Required`),
              otherwise: Yup.string()
            }),
            value: Yup.string().when(["operator"], {
              is: (operator) => !operator || (operator && !operator.includes("parameter")),
              then: Yup.string().required(t`Required`),
              otherwise: Yup.string()
            }),
            duration: Yup.string().when(["unitType"], {
              is: (unitType) => unitType && !["iduNum", "oduNum"].includes(unitType),
              then: Yup.string().required(t`Required`),
              otherwise: Yup.string()
            }),
            parameter2: Yup.string().when(["operator"], {
              is: (operator) => operator && (operator.includes("threshold") || operator.includes("parameter")),
              then: Yup.string().required(t`Required`),
              otherwise: Yup.string()
            })
          })
        )
    }));
    return objectGroups;
  };
  const schema = Yup.object().shape({
    priority: Yup.string()
      .required(t`Required`),
    ruleName: Yup.string()
      .required(t`Required`).max(50),
    selectedBrand: Yup.string()
      .required(t`Required`),
    conditions1: Yup.array()
      .of(
        Yup.object().shape({
          unitType: Yup.string()
            .required(t`Required`),
          parameter: Yup.string().when(["unitType"], {
            is: (unitType) => unitType && !["iduNum", "oduNum"].includes(unitType),
            then: Yup.string().required(t`Required`),
            otherwise: Yup.string()
          }),
          operator: Yup.string().when(["unitType"], {
            is: (unitType) => unitType && !["iduNum", "oduNum"].includes(unitType),
            then: Yup.string().required(t`Required`),
            otherwise: Yup.string()
          }),
          thresholdOperator: Yup.string().when(["operator"], {
            is: (operator) => operator && operator === "threshold",
            then: Yup.string().required(t`Required`),
            otherwise: Yup.string()
          }),
          value: Yup.number().when("operator", {
            is: (operator) => !operator || (operator && !operator.includes("parameter")),
            then: Yup.number().required(t`Required`),
            otherwise: Yup.number()
          }).when("unitType", {
            is: (unitType) => unitType === "iduNum" || unitType === "oduNum",
            then: Yup.number().required(t`Required`).min(1, "Only positive numbers are allowed"),
            otherwise: Yup.number()
          }).when(["unitType", "indoorPerc", "outdoorPerc"], {
            is: (unitType, indoorPerc, outdoorPerc) =>
              (unitType === "iduNum" && indoorPerc === 2) || (unitType === "oduNum" && outdoorPerc === 2),
            then: Yup.number().required(t`Required`).max(100, t`100% max is allowed`),
            otherwise: Yup.number()
          }),
          duration: Yup.string().when(["unitType"], {
            is: (unitType) => unitType && !["iduNum", "oduNum"].includes(unitType),
            then: Yup.string().required("Required"),
            otherwise: Yup.string()
          }),
          parameter2: Yup.string().when(["operator"], {
            is: (operator) => operator && (operator.includes("threshold") || operator.includes("parameter")),
            then: Yup.string().required("Required"),
            otherwise: Yup.string()
          })
        })
      ),
    groups: Yup.object().shape(
      getObjectSchema()

    )
  });

  const sensorSchema = Yup.object().shape({
    ruleName: Yup.string()
      .required(t`Required`),
    selectedBrand: Yup.string()
      .required(t`Required`),
    conditions1: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string().required(t`Required`),
          operator: Yup.string().required(t`Required`),
          duration: Yup.string().required(t`Required`),
          value: Yup.number().required(t`Required`),
          parameter: Yup.string().when(["type"], {
            is: (type) => type === "powerMeter",
            then: Yup.string().required("Required"),
            otherwise: Yup.string()
          })
        }))
  });

  const inOrder = (tree: any) => {
    let relations: any = [];
    let nodes: any = [];
    let current = _.cloneDeep(tree);
    if (current) {
      if (current.isCondition || current.isGroup) {
        return { relations: [], nodes: [current] };
      }

      let traverse = (node: any) => {
        if (node.ruleSets && node.ruleSets[0]?.relation && !node.ruleSets[0]?.isGroup && !node.ruleSets[0]?.isCondition) {
          traverse(node.ruleSets[0]);
        }
        else {
          nodes.push(node.ruleSets[0]);
        }

        relations.push(node.relation);

        if (node.ruleSets && node.ruleSets[1]?.relation && !node.ruleSets[1]?.isGroup && !node.ruleSets[1]?.isCondition) {
          traverse(node.ruleSets[1]);
        }
        else {
          nodes.push(node.ruleSets[1]);
        }
      };

      traverse(current);
    }
    return { relations, nodes };
  };

  const reCreateCondition = (node: any, relation: string) => {
    const conditionId = conditionUtils.generateId();
    const nodeData: any = { id: conditionId };
    const subNode = "thresholdValue" in node ? node : node.rules[0];
    const { scaleType = -10, scale } = subNode;

    const scaleTypeString = +scaleType === +measurementUnitTypes["temperature"] ? "temp" : (+scaleType === +measurementUnitTypes["pressure"] ? "pressure" : "other");
    const needConvert = scaleTypeString !== "other" && ((scaleTypeString === "temp" && +scale !== +userTempScale) || (scaleTypeString === "pressure" && +scale !== +userPressureScale));

    const isThreshold = "thresholdValue" in node;
    let value: any = isThreshold ? node.thresholdValue : node.rules[0].value;

    if (scaleTypeString !== "other" && needConvert) {
      let method: any = "";
      if (scaleTypeString === "temp") {
        if (temperatureScale[userTempScale] === "celsius") {
          if (isThreshold) {
            method = (num: any) => num / 2;
          } else {
            method = toC;
          }
        }
        else {
          if (isThreshold) {
            method = (num: any) => num * 2;
          } else {
            method = toF;
          }
        }
      }
      else {
        if (pressureScale[userPressureScale] === "PSI") {
          method = toPSI;
        }
        else {
          method = toKgPerCm2;
        }
      }

      if (method) {
        value = method(value);
      }
    }

    if ("thresholdValue" in node) {
      nodeData.operator = "threshold";
      nodeData.thresholdOperator = node.relation;
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.parameter2 = "" + node.rules[1].code;
      nodeData.unitType = +node.rules[0].unitType === +node.rules[1].unitType ? "" + node.rules[0].unitType : "mixed";
    }
    else if ("comparisonOperator" in node) {
      nodeData.operator = node.comparisonOperator + " parameter";
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.parameter2 = "" + node.rules[1].code;
      nodeData.unitType = +node.rules[0].unitType === +node.rules[1].unitType ? "" + node.rules[0].unitType : "mixed";
    }
    else {
      nodeData.operator = node.rules[0].operator;
      nodeData.value = value;
      nodeData.parameter = "" + node.rules[0].code;
      nodeData.unitType = node.rules[0].unitType || "";
    }
    nodeData.relation = relation;

    if (node.triggerDelayTimeInMinutes + "") {
      nodeData.duration = CommonUtils.toHoursAndMinutes(node.triggerDelayTimeInMinutes) + "" || "0";
    }

    let type: any;
    if (nodeData.unitType) {
      type = nodeData.unitType === "mixed" ? "mixed" : (+unitTypes["service"] === +nodeData.unitType ? "indoor" : "outdoor");
    }
    return { data: nodeData, type };
  };

  useEffect(() => {

    if (!editTrap || !dataReady) {
      return;
    }

    extractConditions(editTrap, false);

  }, [editTrap, dataReady]);

  const extractConditions = (editTrap: any, onlyConditions: boolean) => {

    const mainBody = editTrap.trapSets[0].ruleSets[0];

    if (!onlyConditions) {
      setDescription(editTrap.description);
      setSelectedBrand(editTrap.type === trapTypes.operationalTelemetry ? editTrap.userSelections?.brand === sensorBrandvalue ? sensorBrandvalue : operationalTelemetryValue : editTrap.userSelections?.brand);
      const isTemplateB = !!editTrap.userSelections?.isTemplateBased;
      setIsTemplateBased(isTemplateB);
      isTemplateB && setRuleType(editTrap.userSelections?.trapTemplateId);
      setRuleName(editTrap.name);
      setPriority(editTrap.priority);

      if (mainBody.systemIds) {
        setSelectedSystems(mainBody.systemIds);
      }

      if (mainBody.unitIds) {
        setSelectedUnits(mainBody.unitIds);
      }
    }

    if (editTrap.fileId) {
      setFile({
        fileId: editTrap.fileId,
        fileURL: editTrap.fileURL,
        name: editTrap.fileName || _.last(editTrap.fileURL?.split("/"))
      })
    }

    if (editTrap.userSelections?.brand === sensorBrandvalue) {
      const rule = mainBody?.ruleSets[0]?.rules[0];
      const duration = CommonUtils.toHoursAndMinutes(mainBody?.ruleSets[0]?.triggerDelayTimeInMinutes) || "0";
      let type: any = +editTrap.userSelections?.sensorType;
      let { operator, value, scale, code, unitType } = rule;
      if (+unitType === 6 || editTrap.userSelections?.sensorType === "powerMeter") {
        type = "powerMeter";
        unitType = 6;
      }

      const needConvert = scale && +scale !== +userTempScale;
      if (needConvert) {
        if (temperatureScale[userTempScale] === "celsius") {
          value = toC(value);
        }
        else {
          value = toF(value);
        }
      }

      const condition = { ...newRule, type, operator, value, parameter: code, unitType, duration };
      const options: any = {};
      sensorsOptions.forEach((option: any) => options[option.value] = true);
      if (mainBody.sensorObjects) {
        const ids: any = [];
        mainBody.sensorObjects.forEach((obj: any) => {
          const sensor = customerSensors[obj.id];
          if (sensor && sensor.type === type && options[type]) {
            ids.push(obj.id);
          }
        });
        setSelectedSensors(ids);
      }
      if (mainBody.powerMeterIds) {
        const ids: any = [];
        mainBody.powerMeterIds.forEach((id: any) => {
          const powerMeter = customerPowerMeters[id];
          if (powerMeter) {
            ids.push(id);
          }
        });
        setSelectedPowerMeters(ids);
      }
      setConditions([condition]);
      return;
    }
    const treeRoot = mainBody.ruleSets[0];
    const data = inOrder(treeRoot);

    data.relations.push("and");
    const newConds: any = [];
    const newGroups: any = {};

    const { nodes, relations } = data;
    for (let x in nodes) {
      const node = nodes[x];
      const relation = relations[x];

      if (node.isCondition) {
        const nodeResult = reCreateCondition(node, relation);
        newConds.push({ ...newRule, ...nodeResult.data });
      }

      if (node.isGroup) {
        delete node.isGroup;
        const groupData = inOrder(node);
        groupData.relations.push("and");
        const groupConditions: any = [];
        const { relations: gRelations, nodes: gNodes } = groupData;
        const groupId = conditionUtils.generateId();

        for (let i in gNodes) {
          const gNode = gNodes[i];
          const gRelation = gRelations[i];

          if (gNode.isCondition) {
            const nodeResult = reCreateCondition(gNode, gRelation);
            groupConditions.push({ ...newRule, ...nodeResult.data });
          }

        }

        newGroups[groupId] = { relation, conditions: groupConditions, id: groupId };
      }
    }

    if (mainBody.numberOfIndoorUnitsForEvent) {
      newConds.push({ ...newRule, unitType: "iduNum", value: mainBody.numberOfIndoorUnitsForEvent, indoorPerc: mainBody.numberOfIndoorUnitsForEventIsPerc ? 2 : 1, id: conditionUtils.generateId() });
    }

    if (mainBody.numberOfOutdoorUnitsForEvent) {
      newConds.push({ ...newRule, unitType: "oduNum", value: mainBody.numberOfOutdoorUnitsForEvent, outdoorPerc: mainBody.numberOfOutdoorUnitsForEventIsPerc ? 2 : 1, id: conditionUtils.generateId() });
    }
    setConditions(newConds);
    setGroups(newGroups);
  };

  const onDragEnd = (result: any) => (elements: any, ruleName: string, isGroups = false) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorder = (list: any, startIndex: any, endIndex: any) => {
      const [removed] = list.splice(startIndex, 1);
      list.splice(endIndex, 0, removed);
      return list;
    };

    const newOrder: any = reorder(
      elements,
      result.source.index,
      result.destination.index
    );
    if (isGroups) {
      setGroupsList(newOrder);
    }
    else {
      setConditions(newOrder);
    }
    setRuleName(ruleName);
  };

  const countTypes = (values: any) => {
    let allConditions = values.conditions1;
    Object.values(values.groups).forEach((group: any) => allConditions = [...allConditions, ...group.conditions]);
    let hasIndoor = false;
    let hasOutdoor = false;
    let hasMixed = false;
    let hasEmptyFields = false;
    let iduNumIn = false;
    let oduNumIn = false;

    allConditions.forEach((condition: any) => {
      if (condition.unitType === "") {
        hasEmptyFields = true;
        return;
      }

      if (condition.unitType === unitTypes.service) {
        hasIndoor = true;
        return;
      }
      if (condition.unitType === unitTypes.outdoor) {
        hasOutdoor = true;
        return;
      }
      if (condition.unitType === "mixed") {
        hasMixed = true;
        return;
      }

      if (condition.unitType === "iduNum") {
        iduNumIn = true;
      }

      if (condition.unitType === "oduNum") {
        oduNumIn = true;
      }
    });
    return {
      hasIndoor,
      hasOutdoor,
      hasMixed,
      hasEmptyFields,
      iduNumIn,
      oduNumIn
    };
  };

  const uploadNewFile = (event: any) => {
    const files = event?.target?.files;

    if (!files?.length) {
      return;
    }

    const file = files[0];

    if (file?.size > maxFileSizeAllowed) {
      setUploadError("files that larger than 50MB are not allowed");
      setTimeout(() => setUploadError(""), 3000);
      return;
    }

    setUploadLoading(true);

    let formData = new FormData();
    formData.append("file", file);
    uploadFile(formData)
      .then((fileId: any) => {
        return setFile({ fileId, file, name: file.name });
      })
      .catch(({ message }: any) => setUploadError(message))
      .finally(() => setUploadLoading(false));
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{editTrap ? t`Edit Rule` : t`Add New Rule`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <Formik
        initialValues={{ ruleName, selectedBrand, ruleType, priority, conditions1: conditions, ...{ groups } }}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={selectedBrand === sensorBrandvalue ? sensorSchema : schema}
        render={({ values, setFieldValue, errors, touched
        }) => {
          const {
            hasIndoor,
            hasOutdoor,
            hasMixed,
            hasEmptyFields,
            iduNumIn,
            oduNumIn
          } = countTypes(values);

          const numberOfUnits = iduNumIn || oduNumIn;
          const hasIndoorOnly = hasIndoor && !hasMixed && !hasOutdoor && !numberOfUnits;
          const hasOutdoorOnly = hasOutdoor && !hasMixed && !hasIndoor && !numberOfUnits;
          const unitType = hasIndoorOnly ? "indoorUnits" : hasOutdoorOnly ? "outdoorUnits" : "systems";
          const isFirstConditionFake = values.conditions1[0]?.unitType === "iduNum" || values.conditions1[0]?.unitType === "oduNum";

          const ruleTypeOptions = !template && values.selectedBrand && trapTemplatesToBrands[values.selectedBrand] ? [...ruleTypes, ...trapTemplatesToBrands[values.selectedBrand]] : ruleTypes;
          const isNotCustomRule = values.ruleType !== "Custom Rule";
          return (
            // @ts-ignore
            <Form translate="yes" className={classes.dialogContent}>
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: !!openTree
                })}
              >
                {!!openTree && <div className={classes.opacityStyle} />}
                <div className={classes.boxesHolder}>
                  <div id="basic-info" className={clsx(classes.basicInfoContainer)}>
                    <div style={{ marginRight: 15 }}>
                      <LightTooltip title={t`Rule's name length must be less than 30 characters`}>
                        <div>
                          <CustomedTextField
                            value={values.ruleName}
                            name="ruleName"
                            className={classes.removeTopMargin}
                            onChange={(event: any) => setFieldValue("ruleName", event.target.value)}
                            label={t`Rule Name`}
                            error={errors.ruleName && touched.ruleName}
                            maxLength={50}
                            disabled={disabled}
                          />
                        </div>
                      </LightTooltip>
                      <div className={classes.brandTypeContainer}>
                        <CustomAutoComplete
                          className={classes.mainSelect}
                          error={errors.selectedBrand && touched.selectedBrand}
                          placeholder={t`Select Type/Brand`}
                          customTooltip={t`This is the HVAC Brand the rule is relevant for.  Different brands have different set of parameters, therefore you have to be specific.`}
                          value={brandsObject[values.selectedBrand] || ""}
                          options={brands}
                          onChange={(event: any, value: any) => handleBrandChange(value?.value || "", "selectedBrand", setFieldValue, values.ruleName, values.priority)}
                          disabled={isNotCustomRule || disabled || (editTrap && (+selectedBrand === operationalTelemetryValue || +selectedBrand === sensorBrandvalue))}
                          name="selectedBrand"
                        />
                        <CustomSelect disableTooltip
                          disabled={disabled}
                          className={classes.mainSelect}
                          placeholder={t`Priority`}
                          name="priority"
                          error={errors.priority && touched.priority}
                          value={values.priority}
                          options={trapPriorityOptions}
                          onChange={(event: any) => setFieldValue("priority", event.target.value)}
                        />
                        <CustomSelect disableTooltip label="name" optionValue="id" className={clsx(classes.mainSelect, classes.removeRightMargin)} disabled={template || isTemplateBased ? true : ruleTypeOptions.length === 1} placeholder="Rule Type" name="ruleType" value={values.ruleType} options={isTemplateBased ? templateBaseOption : ruleTypeOptions} onChange={(event: any) => template ? {} : handleRuleTypeChange(event.target.value, "ruleType", setFieldValue, values.ruleType, values.ruleName, values.selectedBrand, values.priority)} />
                      </div>
                    </div>
                    <div className={classes.descriptionContainer}>
                      <div className={classes.descriptionLabel}>{t`Description`}</div>
                      <TextareaAutosize
                        disabled={!!openTree || disabled}
                        className={classes.textArea}
                        aria-label="minimum height"
                        minRows={4}
                        maxRows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <Button
                        disabled={disabled}
                        white
                        onClick={(e: any) => setAttachmentPopup(e.currentTarget)}
                        className={classes.attachmentFloatingBtn}
                      >
                        {t`Attachment`}
                      </Button>
                    </div>
                  </div>
                  <Box
                    title={t`Conditions`}
                    bLabel1={t`Add Condition`}
                    bLabel2={t`Group`}
                    bDisabled1={disabled || (indoorParams.length === 0 && outdoorParams.length === 0) || openTree || isNotCustomRule}
                    bDisabled2={disabled || selectedConditions.length < 2 || openTree}
                    action1={() => addCondition(values.conditions1, values.groups, values.ruleName, values.selectedBrand, values.priority)}
                    action2={() => addGroup(values.conditions1, values.ruleName, values.selectedBrand, values.priority)}
                    bTooltip1={t`Create a new condition to be to monitored for this rule`}
                    bTooltip2={t`Logically connect two(or more) defined and checked conditions. Button is disabled if there are less than 2 conditions checked.`}
                    addBottomMargin
                  >
                    <DragDropContext onDragEnd={(result: any) => onDragEnd(result)(groupsList, values.ruleName, true)} >
                      <Droppable droppableId="groups-droppable" type="groups">
                        {(provided: any) => (
                          <div ref={provided.innerRef} >
                            {!_.isEmpty(groupsList) && (groupsList).map((group: any, index: number) => {
                              if (!group?.id || _.isEmpty(groups) || !groups[group.id]) {
                                return null;
                              }
                              groups[group.id].index = index;
                              return <FieldArray
                                name={`groups.${group.id}.conditions`}
                                key={`array-group-${group.id}`}
                                render={() =>
                                  <Draggable
                                    key={`group-${group.id}`}
                                    draggableId={`group-${group.id}`}
                                    index={index}
                                  >
                                    {(provided: any) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps} >
                                        <div key={index}>
                                          <ConditionsGroup
                                            ignoreUnitType={selectedBrand === operationalTelemetryValue}
                                            isFirstConditionFake={isFirstConditionFake}
                                            enumParams={enumParams}
                                            serviceParamTypes={serviceParamTypes}
                                            errors={errors}
                                            touched={touched}
                                            noSingleConditions={values.conditions1.length === 0}
                                            groupsLastIndex={Object.keys(values.groups).length - 1}
                                            groupIndex={index}
                                            showWranning={(value: any, id: string, name: string, inGroup: boolean, groupKey: string) => handleUnitsWarning({ conditions: values.conditions1, groups: values.groups, value, id, setFieldValue, name, inGroup, groupKey })}
                                            isUnitsSelected={selectedUnits.length > 0}
                                            disabled={openTree || isNotCustomRule || disabled}
                                            key={`group-${group.id}`}
                                            indexKey={group.id}
                                            unGroup={() => unGroup(group.id, values.conditions1, values.groups)}
                                            conditions={values.groups[group.id].conditions}
                                            indoorParams={indoorParams}
                                            outdoorParams={outdoorParams}
                                            allParamsMap={{ ...indoorParamsMap, ...outdoorParamsMap }}
                                            relation={values.groups[group.id].relation}
                                            deleteCondition={(index: any) => deleteGroupCondition(values.conditions1, values.groups, group.id, index)}
                                            onSelect={onSelect}
                                            name={`groups.${group.id}.conditions`}
                                            setFieldValue={setFieldValue}
                                            conditionsUnitsType={unitType}
                                            unitTypes={types.unitTypes}
                                            moveProps={provided.dragHandleProps}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                }
                              />;
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <DragDropContext onDragEnd={(result: any) => onDragEnd(result)(values.conditions1, values.ruleName)} >
                      <Droppable droppableId="droppable" type="conditions">
                        {(provided: any) => (
                          <div ref={provided.innerRef} >
                            <FieldArray
                              name="conditions1"
                              render={() => (values.conditions1.map((condition: any, index: number) =>
                                <Draggable
                                  key={`condition-${condition.id}`}
                                  draggableId={`condition-${condition.id}`}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} >
                                      <div key={index}>
                                        {values.selectedBrand === sensorBrandvalue ?
                                          <SensorCondition
                                            options={sensorsOptions}
                                            condition={condition}
                                            enums={{}}
                                            setFieldValue={setFieldValue}
                                            name={"conditions1"}
                                            key={condition.id}
                                            index={index}
                                            disabled={disabled || openTree || isNotCustomRule}
                                            errors={errors}
                                            touched={touched}
                                            showWranning={(value: any, id: any, name: any) => handleUnitsWarning({ conditions: values.conditions1, value, id: index, setFieldValue, name, item: selectedPowerMeters.length ? "powerMeter" : "sensor" })}
                                            isSensorSelected={selectedSensors.length}
                                            isPowerMeterSelected={selectedPowerMeters.length}
                                          /> :
                                          <Condition
                                            ignoreUnitType={selectedBrand === operationalTelemetryValue}
                                            hideIduNum={iduNumIn}
                                            hideOduNum={oduNumIn}
                                            enumParams={enumParams}
                                            serviceParamTypes={serviceParamTypes}
                                            nextCondition={values.conditions1[index + 1]}
                                            conditions={values.conditions1}
                                            errors={errors}
                                            touched={touched}
                                            showWranning={(value: any, id: any, name: any) => handleUnitsWarning({ conditions: values.conditions1, value, id: index, setFieldValue, name, item: "unit" })}
                                            isUnitsSelected={selectedUnits.length > 0}
                                            disabled={disabled || openTree || isNotCustomRule}
                                            key={condition.id}
                                            index={index}
                                            condition={condition}
                                            setFieldValue={setFieldValue}
                                            indoorParams={indoorParams}
                                            outdoorParams={outdoorParams}
                                            allParamsMap={{ ...indoorParamsMap, ...outdoorParamsMap }}
                                            deleteCondition={() => deleteCondition(values.conditions1, condition.id)}
                                            onSelect={onSelect}
                                            name={"conditions1"}
                                            lastCondition={index !== values.conditions1.length - 1}
                                            conditionsUnitsType={unitType}
                                            unitTypes={types.unitTypes}
                                            moveProps={provided.dragHandleProps}

                                          />
                                        }
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            />
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                  {!template && <Box
                    small
                    title={t`Apply to`}
                    bLabel2={t`Add Units`}
                    bDisabled2={disabled || !values.selectedBrand || openTree || (_.isEmpty(values.conditions1) && _.isEmpty(values.groups)) || (values.selectedBrand === sensorBrandvalue && !values.conditions1[0]?.type)}
                    action2={() => openTreeDiag(values.conditions1, values.groups, values.selectedBrand === sensorBrandvalue ? values.conditions1[0]?.type : hasEmptyFields ? "systems" : unitType, values.ruleName, values.selectedBrand === sensorBrandvalue ? values.conditions1[0]?.type === "powerMeter" ? "powerMeter" : "sensor" : "unit")}
                  >
                    <div className={classes.unitsSystemsContainer}>
                      {selectedSystems.map((id: any) => !allSystems[id] ? null : <SystemWrapper disabled={disabled} key={`selected-system-${id}`} systemName={allSystems[id]?.name} siteName={sites[allDevices[allSystems[id]?.device]?.site]?.name} onRemove={() => setSelectedSystems(selectedSystems.filter((systemId: string) => systemId !== id))} />)}
                      {selectedUnits.map((id: any) => {
                        const unit = allUnits[id];
                        if (!unit || !unit.isVisible) {
                          return null;
                        }
                        const { name = "", system, type, controlUnit, address, site } = unit;
                        let unitName: string = name;
                        if (type === 3) {
                          const controlName = allUnits[controlUnit]?.name || t`Unassigned`;
                          unitName = `${controlName} (${address})`;
                        }

                        return <UnitWrapper key={`selected-unit-${id}`} disabled={disabled} unitName={unitName} siteName={sites[site]?.name} systemName={allSystems[system]?.name} onRemove={() => setSelectedUnits(selectedUnits.filter((unitId: string) => unitId !== id))} />;
                      })}
                      {selectedSensors.map((id: any) => !customerSensors[id] ? null : <SystemWrapper disabled={disabled} key={`selected-sensor-${id}`} systemName={customerSensors[id]?.sensorGroup ? `${customerSensors[id]?.name} (${getSensorGroupNameBySensorId(id)})` : customerSensors[id]?.name} siteName={sites[customerSensors[id]?.site]?.name} onRemove={() => setSelectedSensors(selectedSensors.filter((sensorId: string) => sensorId !== id))} />)}
                      {selectedPowerMeters.map((id: any) => !customerPowerMeters[id] ? null : <SystemWrapper disabled={disabled} key={`selected-power-meter-${id}`} systemName={customerPowerMeters[id]?.name} siteName={sites[customerPowerMeters[id]?.site]?.name} onRemove={() => setSelectedPowerMeters(selectedPowerMeters.filter((powerMeterId: string) => powerMeterId !== id))} />)}
                    </div>
                  </Box>}
                </div>
                <div className={classes.actionsContainer}>
                  {!!errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
                  <Button
                    disabled={disabled}
                    onClick={() => setCancelClicked(true)}
                    onMouseDown={(event: any) => event.preventDefault()}
                    marginRight
                    white
                    width={150}
                  >
                    {t`cancel`}
                  </Button>
                  <Button
                    type="submit"
                    onMouseDown={(event: any) => event.preventDefault()}
                    disabled={!!openTree || disabled}
                    width={150}
                  >
                    {t`save`}
                  </Button>
                </div>

              </main>
              <Drawer
                className={clsx(classes.drawer, { [classes.closedDrawer]: !openTree })}
                variant="persistent"
                anchor="right"
                open={!!openTree}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                {openTree && openTree?.itemType === "unit" &&
                  <ItemsTree
                    operational={selectedBrand === operationalTelemetryValue}
                    mode={openTree?.type}
                    brandSystems={brandSystems}
                    systemsToSites={systemsToSitesMap}
                    allSites={sites}
                    customers={customers}
                    allUnits={allUnits}
                    selectedUnits={selectedUnits}
                    selectedSystems={selectedSystems}
                    onClose={() => setOpenTree("")}
                    onSave={saveSelectedItems}
                    brandCustomers={selectedBrandCustomers}
                    userPref={userPref}
                    isODRule={isODRule}
                  />}
                {openTree && openTree?.itemType === "sensor" && <SensorsTree
                  mode={openTree?.type}
                  allSites={sites}
                  siteSensors={sensorsPerSite}
                  customerSensors={customerSensors}
                  allCustomers={customers}
                  selectedSensors={selectedSensors}
                  onClose={() => setOpenTree("")}
                  onSave={saveSensorSelectedItems}
                  userPref={userPref}
                />}
                {openTree && openTree?.itemType === "powerMeter" && <SensorsTree
                  mode={openTree?.type}
                  allSites={sites}
                  siteSensors={powerMetersPerSite}
                  customerSensors={customerPowerMeters}
                  allCustomers={customers}
                  selectedSensors={selectedPowerMeters}
                  onClose={() => setOpenTree("")}
                  onSave={savePowerMetersSelectedItems}
                  userPref={userPref}
                />}
              </Drawer>
            </Form>
          );
        }}
      />

      {brandToBeChange && <ErrorBox error={"Are you sure? All Conditions will be deleted."} onAccept={handleWraningBrandChanging} onClose={() => setBrandToBeChange(null)} />}
      {ruleTypeTobeChanged && <ErrorBox error={"Are you sure? All Conditions will be deleted."} onAccept={handleRuleTypeWarning} onClose={() => setRuleTypeTobeChanged(null)} />}
      {cancelClicked && <ErrorBox error={"Do you want to discard changes?"} onAccept={close} onClose={() => setCancelClicked(false)} />}
      {!_.isEmpty(showDeletingUnitsWarnning) && <ErrorBox error={showDeletingUnitsWarnning.item === "sensor" ? t`All selected sensors will be removed` : showDeletingUnitsWarnning.item === "powerMeter" ? t`All selected power meters will be removed` : t`All selected units will be removed`} onAccept={handleAcceptDeletingUnits} onClose={handleDeclineDeletingUnits} />}

      <Popover
        open={!!attachmentPopup}
        anchorEl={attachmentPopup}
        onClose={() => setAttachmentPopup(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        style={{
          marginTop: "7px"
        }}
      >
        <div style={{
          width: "350px",
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative"
        }}>
          {
            !!file &&
            <div
              style={{
                display: "flex",
                margin: "5px 0",
                justifyContent: "space-between"
              }}>
              <Tooltip classes={{ tooltip: classes.tooltip }} title={file.name}>
                <Typography className={classes.fileName}>
                  <span className={classes.bold}>{t`Attached file:  `}</span>
                  {`${file.name}`}
                </Typography>
              </Tooltip>
              {!!file &&
                <IconButton onClick={() => setFile(null)}>
                  <Delete />
                </IconButton>
              }
            </div>

          }
          <div style={{
            display: "flex",
            alignItems: "center",
            margin: "5px 0",
            justifyContent: "space-between"
          }}>

            <Typography className={clsx(classes.fileName, classes.bold)}>
              {!!file ? t`Load new file` : t`Attach file:`}
            </Typography>
            <>
              <label htmlFor="trapFile" >
                <IconButton component="span">
                  <AttachFile />
                </IconButton>
              </label>
              <input type="file" hidden name="trapFile" id="trapFile" onChange={uploadNewFile} />
            </>
          </div>
          {
            !!file?.fileURL &&
            <div style={{
              display: "flex",
              alignItems: "center",
              margin: "5px 0",
              justifyContent: "space-between"
            }}>

              <Typography
                className={clsx(classes.fileName, classes.bold)}>
                {t`Download File`}
              </Typography>
              <IconButton onClick={() => downloadAs(file.fileURL, file.name)}>
                <FiDownload style={{ fontSize: "25px" }} />
              </IconButton>
            </div>
          }
          {uploadError &&
            <Typography
              style={{
                fontSize: "12px",
                color: "#f00"
              }}
            >
              {uploadError}
            </Typography>}
          {
            uploadLoading &&
            <div style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(0,0,0, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <MoonLoader color="#8b25ff" />
            </div>
          }
        </div>
      </Popover>

    </Dialog >
  );
};

export default AddRule;
