import { alpha, makeStyles } from "@material-ui/core";

export default makeStyles((theme: any) => ({
  permissionsBox: {
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
    width: "100%"
  },
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: "100%"
  },
  itemRow: {
    width: "99%",
    height: "50px",
    // backgroundColor: "#FCFCFC",
    display: "flex",
    justifyContent: "space-between",
    margin: "5px 0",
    // border: "1px solid #F1F1F1",
    borderRadius: "6px",
    padding: "0 20px",
    boxSizing: "border-box",
    "& p": {
      display: "flex",
      alignItems: "center"
    }
  },
  itemRowUnitName: {
    width: "120px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    margin: 'auto',
    paddingRight: '17px'
  },
  treeItemLabel: {
    "&:hover": {
      backgroundColor: "transparent"
    },
    backgroundColor: "transparent !important"
  },
  rowControls: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "30%",
    flex: 1
  },
  selectRoot: {
    height: "75%",
    backgroundColor: "#fff",
    width: "130px",
    "&:focus": {
      background: "#fff"
    },
    "&:hover": {
      background: "#fff"
    }
  },
  chkboxRoot: {
    color: theme.palette.primary.main
  },
  diabledChkbox: {
    color: "#736973"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px"
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold"

  },
  treeItemGroup: {
    marginLeft: "0"
  },
  contentLv1: {
    backgroundColor: "#F1F1F1 !important",
    paddingLeft: 0,
    boxSizing: "border-box"
    // "&:focus": {
    //   backgroundColor: "transparent !important"
    // },
    // "&:hover": {
    //   backgroundColor: "transparent !important"
    // }
  },
  contentLv2: {
    backgroundColor: "#F1F1F1 !important",
    paddingLeft: "10px",
    boxSizing: "border-box"
  },
  contentLv3: {
    backgroundColor: "#F1F1F1 !important",
    paddingLeft: "20px",
    boxSizing: "border-box"
  },
  contentLv4: {
    backgroundColor: "#F1F1F1 !important",
    paddingLeft: "30px",
    boxSizing: "border-box"
  },
  treeItemRoot: {
    "& *": {
      color: "#545964"
    },
    "& .MuiTreeItem-label": {
      width: 'calc(100% - 20px)'
    }
  },
  searchRoot: {
    "& .MuiInputBase-input": {
      color: "#fff", // Text color
      padding: "10px 12px"
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff8" // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff" // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff" // Solid underline on focus
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "rgb(202 202 202 / 20%)" // Solid underline on focus
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "rgb(202 202 202 / 20%)" // Solid underline on focus
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "rgb(202 202 202 / 9%)"
    }
  },
  selectMenu: {
    padding: 0,
    width: "80%",
    fontSize: "15px",
    paddingLeft: "5px",
    "&:focus": {
      background: "#fff"
    },
    "&:hover": {
      background: "#fff"
    }
  },
  inputRoot: {
    color: "#4b4850",
    fontFamily: "Roboto",
    fontSize: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    marginBottom: "25px",
    height: "52px"
    // maxWidth: "70%",
    // marginRight: "40px"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  closeIcon: {
    fill: "#AAA2AA"
  }
}));
