import { alpha, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({


  unitStyle: {
    width: "100%",
    height: "60px",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: alpha(theme.palette.colors.white, 0.1),
    color: theme.palette.colors.white,
    marginBottom: "12px"
  },

  importantNoteSec: {
    display: "flex",
    height: "100%",
    // width: "20px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  alertMessage: {
    color: "#f05146",
    fontSize: "13px",
    marginRight: "7px"
  },
  iconStyle: {
    fontSize: "17.5px"
  },
  modeIcon: {
    margin: "0 5px"
  },
  noteIcon: {
    fontSize: "20px"
  },

  powerAlertsSchedulesContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    minWidth: "14%"
  },
  smallIcons: {
    transform: "scale(0.5)",
    margin: "-5px",
    padding: "0 2px"
  },
  iconMidSize: {
    transform: "scale(0.7)",
    padding: "0 2px"
  },
  nameStyle2Lines: {
    lineHeight: "20px",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "16px",
    fontWeight: 400

  },
  selected: { border: "solid 2px " + alpha(theme.palette.colors.white || "fff", 0.6) },
  statusBox: {
    margin: "8px",
    height: "45px",
    minHeight: "45px",
    width: "45px",
    minWidth: "45px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.light || "#5c4f5f",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "17px",
    fontWeight: 400,
    whiteSpace: "nowrap"
  },
  statusHeat: {
    backgroundColor: theme.palette.colors.heat || "#f05146"
  },
  statusCool: {
    backgroundColor: theme.palette.colors.cool || "#35a8e0"
  },
  midSection: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden"
  },
  midUpper: {

  },
  midLower: {
    display: "flex"
  },
  midLowerItem: {
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "20px"
  },
  currTemp: {
    fontSize: "12px",
    fontWeight: 400,
    color: alpha(theme.palette.colors.white, 0.6)
  },

  /////////////////////////////////////////////////////////////////
  tempSec: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "26px",
    height: "100%",
    width: "25%",
    flex: 1
  },
  tempInfo: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    justifyContent: "flex-start",
    marginLeft: "5px"
  },
  iconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },
  roomTemp: {
    fontSize: "13px",
    fontWeight: 300,
    color: theme.palette.colors.white,
    display: "flex"
  },
  tempText: {
    fontSize: "23px",
    lineHeight: "unset"
  },
  locSec: {
    paddingLeft: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "bold",
    width: "35%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    maxHeight: "40px"
  },
  notesSec: {
    display: "flex",
    height: "100%",
    width: "42%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  notVisibile: {
    visibility: "hidden"
  },
  removeElements: {
    display: "none"
  },
  removedStyle: {
    opacity: 0.5
  },
  errorColor: {
    color: theme.palette.colors.carminePink
  },
  nameStyle: {
    fontSize: "20px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "inline-block"
  },
  alertIcon: { width: "20px", height: "20px" },
  centerWithMargin: {
    marginLeft: "5px"
  },
  tempTextSensor: {
    fontSize: "26px",
    fontWeight: 300,
    fontFamily: "RobotoLight",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginLeft: 5,
    flexFlow: "row nowrap",
    alignItems: "flex-end"
  },
  offText: {
    color: alpha(theme.palette.colors.white || "#fff", 0.4)
  },
  boldText: {
    fontFamily: "RobotoMedium",
    fontWeight: 500
  },
  onOffSensor: {
    display: "flex",
    alignItems: "center",
    width: "34%",
    justifyContent: "flex-end",
    height: "100%"
  },
  optionalNoteSec: {
    display: "flex",
    height: "100%",
    width: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 365px)": {
      display: "none"
    }
  },
  offStyle: {
    color: alpha(theme.palette.colors.gray5 || "#fdfdfe", 0.6)
  },
  disconnectedContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  disconnectedIconStyle: {
    transform: "scale(2.5)"
  },
  specialFont: {
    fontFamily: "-webkit-body",
    fontSize: "16px",
    alignItems: "flex-end"
  },
  powerUnit: {
    fontSize: "16px",
    lineHeight: "24px",
    marginLeft: "4px"
  },

  /////////////////////////////////////////////////

}));

export default useStyles;
