import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { t } from 'ttag';
import FilterRequire from '@components/FilterRequire/FilterRequire';
import Header from '@components/Header/Header';
import ServiceNavigationBar from '@components/Menu/ServiceNavigationBar';
import { useStoreActions, useStoreState } from '@models/RootStore';
import useStyles from './styles';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import Switch from '@cool_widgets/CoolSwitch/Switch';
import { orderBy, isEmpty, find, filter, has, omit } from 'lodash';
import Button from '@cool_widgets/Button';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { Delete } from 'src/svgComponents';
import { RefreshRounded, SvgArrowDownO } from '@icons/index';
import NewTemplateDialog from './NewTemplateDialog';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ParamMappingDialog from './ParamMappingDialog';
import LinearProgressBar from '@components/LinearProgressBar';
import useBacNet from '@hooks/useBacNet';
import EditInPlaceInput from '@components/EditInPlaceInput';
import DynamicCircleIcon from '@components/DynamicCircleIcon';
import useTypes from '@hooks/useTypes';
import SvgSearch from '@icons/SearchIcon';
import { filterByTerm } from '@utils/ArrayUtils';

const TemplateSelectionTypes = {
  new: {
    type: 'new',
    name: t`New`,
    saveText: t`Create`,
    title: t`Create Mapping Template`,
    visible: false,
  },
  list: {
    type: 'list',
    name: t`From List`,
    saveText: t`Select`,
    title: t`Select Mapping Template`,
    visible: false,
  },
};

/**
 * BacNetMapping component handles the mapping of BacNet devices to parameter templates.
 * It allows for fetching BacNet device data, displaying a list of objects, and saving new or existing mapping templates.
 *
 * @component
 * @param {Object} props - Component properties.
 *
 * @returns {JSX.Element} The rendered BacNetMapping component.
 */
const BacNetMapping = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { siteId = '', bacnetUnitId = '' } = match.params;

  const {
    getSiteBacNetParameterTemplates,
    getBacNetUnitObjects,
    getBacNetUnitById,
    createBacNetParameterTemplate,
    updateBacNetParameterTemplate,
    siteBacNetParameterTemplates,
    isLoading,
    updateBacNetUnit,
    getBacnetIconInformation
  } = useBacNet();

  const {bacnetMeasurementUnits} = useTypes();


  const updateSelections = useStoreActions(
    (a) => a.selections.updateSelections
  );
  const { siteId: selectedSiteId } = useStoreState(
    (s) => s.selections.selections
  );

  const { bacnetMeasurementUnitEnums, bacnetUnitTypes, bacnetCovOptionsTypes } =
    useStoreState((state) => state.types);

  const [viewAllObject, setViewAllObject] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateSelectionType, setTemplateSelectionType] = useState(
    TemplateSelectionTypes['new']
  );
  const [bacNetDevice, setbacNetDevice] = useState({});
  const [bacnetUnitObjects, setBacnetUnitObjects] = useState({});
  const [selectedObjectId, setSelectedObjectId] = useState();
  const [template, setTemplate] = useState({});
  const [templateName, setTemplateName] = useState(template?.name);
  const [deletingObjectId, setDeletingObjectId] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');

  const templateSelectionOptions = useMemo(
    () => Object.values(TemplateSelectionTypes),
    [TemplateSelectionTypes]
  );

  const bacnetUnitObjectsOptions = useMemo(
    () => Object.values(bacnetUnitObjects),
    [bacnetUnitObjects, viewAllObject, template]
  );


  const bacnetUnitMappedObjectsOptions = useMemo(
    () =>
      filter(Object.values(bacnetUnitObjects), (obj) =>
        has(template?.data, obj.objectId)
      ),
    [bacnetUnitObjects, viewAllObject, template]
  );

  useEffect(() => {
    if (!selectedSiteId && siteId) {
      updateSelections({ type: 'site', data: siteId });
    }
    if (selectedSiteId != siteId) {
      handleBack();
    }
  }, [siteId, selectedSiteId]);


  const fetchData = async () => {
    await getSiteBacNetParameterTemplates(siteId);
    const deviceData = await getBacNetUnitById(bacnetUnitId);
    setbacNetDevice(deviceData);
    const unitObjectsData = await getBacNetUnitObjects(bacnetUnitId);
    setBacnetUnitObjects(unitObjectsData);
  };

  useEffect(() => {
    if (!bacnetUnitId || !siteId) {
      return;
    }
    fetchData();
  }, [siteId, bacnetUnitId]);

  useEffect(() => {
    setTemplateName(template?.name || '');
  }, [template]);

  /**
   * Handles the toggle of the menu.
   * @param {Object} event - The event object.
   * @returns {void}
   */
  const handleToggle = (event) => {
    setMenuOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the selection change from the dropdown menu.
   * @param {Object} event - The event object.
   * @returns {void}
   */
  const handleSelectChange = (event) => {
    setTemplateSelectionType({
      ...TemplateSelectionTypes[`${event.target.value}`],
      visible: true,
    });
    setMenuOpen(false);
  };

  /**
   * Saves a new or existing mapping template.
   * @param {string} selectedTemplateId - The ID of the selected template.
   */
  const handleSaveTemplate = async (selectedTemplateId) => {
    if (selectedTemplateId) {
      setTemplate({
        ...siteBacNetParameterTemplates[selectedTemplateId],
        type: 'list',
      });
      setTemplateSelectionType(TemplateSelectionTypes['list']);
    } else {
      const newTemplate = await createBacNetParameterTemplate({
        name: templateName,
        site: siteId,
        model: bacNetDevice.modelName,
        vendorId: bacNetDevice.vendorId,
      });
      setTemplate({ ...newTemplate, type: 'new' });
      setTemplateSelectionType(TemplateSelectionTypes['new']);
    }
  };

  useEffect(()=>{
    if(bacNetDevice.paramTemplate){
      handleSaveTemplate(bacNetDevice.paramTemplate);
    }
  },[bacNetDevice]);

  const handleDelete = async (event, objectId) => {
    event.stopPropagation();
    if (deletingObjectId || !template?.data?.[objectId]) {
      return;
    }
    setDeletingObjectId(objectId);
    try {
      const tempData = omit(template?.data, objectId);
      const updatedTemplate = await updateBacNetParameterTemplate({
        id: template.id,
        data: {
          data: tempData,
        },
      });
      setTemplate(updatedTemplate);
    } finally {
      setDeletingObjectId(null);
    }
  };

  const handleSaveTemplateUpdates = async (updatedTemplate) => {
    setTemplate(updatedTemplate);
    handleMappingDialogClose();
  };

  const showTemplateSelectionDialog = () => {
    setTemplateSelectionType((prevParam) => ({
      ...prevParam,
      visible: true,
    }));
  };

  const closeTemplateSelectionDialog = () => {
    setTemplateSelectionType((prevParam) => ({
      ...prevParam,
      visible: false,
    }));
  };

  const handleRefresh = async () => {
    const unitObjectsData = await getBacNetUnitObjects(bacnetUnitId);
    setBacnetUnitObjects(unitObjectsData);
  };

  const handleBack = () => {
    history.push('/automation/bacnet-detection/');
  };

  const handleTemplateNameChange = useCallback((e) => {
    setTemplateName(e.target.value);
  }, []);

  const handleRowClick = (object) => {
    if (isEmpty(template)) {
      return;
    }
    setSelectedObjectId(object);
  };

  const handleMappingDialogClose = () => {
    setSelectedObjectId();
  };

  const handleAppNameChange = async (newValue) => {
    const {id} = bacNetDevice;
    await updateBacNetUnit({
      id,
      data:{
        title:newValue
      }
    });
    fetchData();
  };

  const handleSearchInputChange = ({target}) => {
    const {value} = target;

    setSearchTerm(value);

  };

  const tableRows = useMemo(() => {
    const activeOptions = viewAllObject ? bacnetUnitObjectsOptions : bacnetUnitMappedObjectsOptions;
    return orderBy(
      activeOptions,
      ['name'],
      ['asc']
    );
  },[viewAllObject,bacnetUnitObjectsOptions,bacnetUnitMappedObjectsOptions]);

  const filteredTableRows = useMemo(()=>{
    if(!tableRows){
      return [];
    }
    if(!searchTerm){
      return tableRows;
    }
    return filterByTerm(tableRows,searchTerm,['objectId','name']);
  },[tableRows,searchTerm]);

  const renderContent = () => {
    if (!bacnetUnitId) {
      return <FilterRequire type={t`device`} />;
    }

    if (isLoading.getSiteBacNetParameterTemplates) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }

    return (
      <div className={classes.pageView}>
        <div className={classes.titleContainer}>
          <IconButton onClick={handleBack}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box className={classes.titleBar}>
            <Typography>{bacNetDevice?.vendorName}</Typography>
            <Box className={classes.titleHeaderNames}>
              <Typography variant="h6" className={classes.headerTitle}>
                {t`Name: ${bacNetDevice?.name} [ID: ${bacNetDevice?.bacnetId}]`}
              </Typography>
              <Box className={classes.titleHeaderAppName}>
                <Typography variant="h6" className={classes.headerTitle}>
                  {t`Device App Name:`}
                </Typography>
                <EditInPlaceInput
                  value={bacNetDevice.title}
                  onSave={handleAppNameChange}
                />
              </Box>
            </Box>

          </Box>
        </div>
        {!isLoading.getBacNetUnitObjects && (
          <div className={classes.verticalLine}></div>
        )}
        <div className={classes.mainContainer}>
          <Grid container spacing={2} className={classes.topContainer}>
            <Grid item xs={5} className={classes.leftSideContainer}>
              <Typography
                className={classes.title}
              >{t`BACNET INFO`}</Typography>
              <div className={classes.actionContainer}>
                <Typography className={classes.label}>
                  {t`All Objects`}
                  {`[${bacnetUnitObjectsOptions.length}]`}
                </Typography>
                <Switch
                  color={'default'}
                  checked={!viewAllObject}
                  switchChange={() => {
                    setViewAllObject(!viewAllObject);
                  }}
                  name="viewType"
                />
                <Typography className={classes.label}>
                  {t`Mapped `}
                  {`[${bacnetUnitMappedObjectsOptions.length}]`}
                </Typography>
                <IconButton onClick={handleRefresh}>
                  <RefreshRounded width={20} height={20} />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={5} className={classes.rightSideContainer}>
              <Typography
                className={classes.title}
              >{t`MAPPING INFO`}</Typography>
              <div className={classes.inputContainer}>
                <Box className={classes.inputSection}>
                  <Typography
                    className={classes.label}
                  >{t`Mapping Template: `}</Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t`New`}
                    value={templateName}
                    onChange={handleTemplateNameChange}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                  />
                  <Button width={125} height={36}>
                    <Box
                      className={classes.textSection}
                      onClick={showTemplateSelectionDialog}
                    >
                      {templateSelectionType.name}
                    </Box>
                    <Box className={classes.iconSection} onClick={handleToggle}>
                      <SvgArrowDownO fill="#fff" />
                    </Box>
                  </Button>
                  <Select
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                    onChange={handleSelectChange}
                    value=""
                    style={{ display: 'none' }}
                    MenuProps={{
                      anchorEl: anchorEl,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {templateSelectionOptions.map(({ type, name }) => (
                      <MenuItem value={type} key={`key-${type}`}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </div>
            </Grid>
            <Grid item xs={2} className={classes.searchContainer}>
              <Typography
                className={classes.title}
              >{t`SEARCH`}</Typography>
              <Box className={classes.inputContainer}>
                <TextField
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  variant="outlined"
                  className={classes.searchField}
                  InputProps={{
                    classes: { root: classes.inputSearchRoot },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SvgSearch />
                      </InputAdornment>
                    ),
                  }}
                />

              </Box>
            </Grid>
          </Grid>
          <div className={classes.bottomContainer}>
            {isLoading.getBacNetUnitObjects && (
              <div className={classes.progressBarContainer}>
                {<LinearProgressBar durationInSeconds={15} />}
              </div>
            )}
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell classes={{ root: classes.tableSmallHeadCell }} />
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`OBJECT ID`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`NAME`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`UNITS`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`NAME`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`TYPE`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`UNITS`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`COV`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableSmallHeadCell }}
                      align="center"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading.getBacNetUnitObjects &&
                   filteredTableRows.map((row) => {
                     const dynamicWidth = 'calc((43% - 62px) / 3)';
                     const { id, objectId, name, bacnetProps, bacnetType } =
                       row;
                     const {
                       color,
                       shortName,
                       name: fullName,
                     } = getBacnetIconInformation(bacnetType);
                     const {UNITS} = bacnetProps;
                     const units = bacnetMeasurementUnits[UNITS] ?? '-';
                     return (
                       <TableRow
                         key={id}
                         hover
                         onDoubleClick={() => handleRowClick(id)}
                       >
                         <TableCell
                           align="center"
                           className={{ root: classes.tableSmallCell }}
                           style={{width:62}}
                         >
                           <DynamicCircleIcon color={color} content={shortName} title={fullName} />
                         </TableCell>
                         <TableCell
                           classes={{ root: classes.tableCell }}
                           align="left"
                           style={{width:dynamicWidth}}
                         >
                           {objectId}
                         </TableCell>
                         <TableCell
                           classes={{ root: classes.tableCell }}
                           align="left"
                           style={{width:dynamicWidth}}
                         >
                           {name}
                         </TableCell>
                         <TableCell
                           classes={{ root: classes.tableCell }}
                           align="left"
                           style={{width:dynamicWidth}}
                         >
                           {units}
                         </TableCell>
                         <TableCell
                           className={{ root: classes.tableCell }}
                           align="left"
                         >
                           {template?.data?.[objectId]?.name || '-'}
                         </TableCell>
                         <TableCell
                           className={{ root: classes.tableCell }}
                           align="left"
                         >
                           {find(bacnetUnitTypes, {
                             value: template?.data?.[objectId]?.type,
                           })?.label || '-'}
                         </TableCell>
                         <TableCell
                           className={{ root: classes.tableCell }}
                           align="left"
                         >
                           {bacnetMeasurementUnitEnums?.[
                             template?.data?.[objectId]?.units
                           ] || '-'}
                         </TableCell>
                         <TableCell
                           className={{ root: classes.tableCell }}
                           align="left"
                         >
                           {bacnetCovOptionsTypes?.[
                             template?.data?.[objectId]?.cov
                           ]?.toUpperCase() || '-'}
                         </TableCell>
                         <TableCell
                           align="center"
                           className={{ root: classes.tableSmallCell }}
                         >
                           <IconButton
                             onClick={(event) => handleDelete(event, objectId)}
                             disabled={deletingObjectId === objectId}
                           >
                             <Delete />
                           </IconButton>
                         </TableCell>
                       </TableRow>
                     );
                   })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {templateSelectionType.visible && (
            <NewTemplateDialog
              templateName={templateName}
              setTemplateName={setTemplateName}
              templateSelectionType={templateSelectionType}
              templatesList={siteBacNetParameterTemplates}
              handleClose={closeTemplateSelectionDialog}
              handleSave={handleSaveTemplate}
            />
          )}
          {selectedObjectId && !isEmpty(template) && (
            <ParamMappingDialog
              bacNetObject={bacnetUnitObjects[selectedObjectId]}
              template={template}
              handleSave={handleSaveTemplateUpdates}
              handleClose={handleMappingDialogClose}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header hideUnitSelection hideSystemSelection />
        {renderContent()}
      </div>
    </div>
  );
};

export default BacNetMapping;
