import {
  PowerReportSchedule as PowerReportScheduleSdk,
  Site as SiteSdk,
} from "coolremote-sdk";
import { Action, action, actionOn, ActionOn, thunk, Thunk } from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IScheduledReport {
  name: string;
  id?: string;
  systemId?: string;
  users: any[];
  timeUnit: string;
  subTimeUnit: string;
  format: string;
  granularity: string;
  systems?: any[];
  zones?: any[];
  siteId?: string;
  specificDay?: number;
  distributeExcessPower?: boolean;
  reportFormatType?: number;
  reportPerTenant?: boolean;
}

export interface IScheduledReportMap {
  [key: string]: IScheduledReport;
}

export interface IScheduledReportsModel {
  allReports: IScheduledReportMap;
  initialize: Action<IScheduledReportsModel, any>;
  onInitialized: ActionOn<IScheduledReportsModel, IRootStoreModel>;
  getReport: Thunk<IScheduledReportsModel, { id: string, type: number }>;
  createReport: Thunk<IScheduledReportsModel, { data: any }>;
  updateReport: Thunk<IScheduledReportsModel, { data: any; reportId: string }>;
  removeReport: Thunk<IScheduledReportsModel, { reportId: string }>;
  updateReportsState: Action<IScheduledReportsModel, any>;
  updateRemovedReportsState: Action<IScheduledReportsModel, string>;
}

export const scheduledReportsModel: IScheduledReportsModel = {
  allReports: {},

  initialize: action((state, payload) => {
    const newReports: IScheduledReportMap = _(Object.values(payload))
      .map((group: any) => {
        const newPowerMeter: IScheduledReport = { ...group };
        return newPowerMeter;
      })
      .keyBy("id")
      .value();

    state.allReports = newReports;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),
  getReport: thunk(async (actions, payload) => {
    const reports = await SiteSdk.getScheduledReports(payload);

    actions.initialize(reports);
    return reports;
  }),

  createReport: thunk(async (actions, payload) => {
    const updatedReport = await PowerReportScheduleSdk.createScheduledReports(
      payload
    );

    actions.updateReportsState(updatedReport);
    return updatedReport;
  }),

  updateReport: thunk(async (actions, payload) => {

    const updatedReport = await PowerReportScheduleSdk.updateScheduledReport(
      payload.reportId,
      payload.data
    );
    actions.updateReportsState({ ...updatedReport, ...payload.data }); // should be fix from backend the API return the ScheduledReport before updates
    // actions.updateReportsState(updatedReport);
    return updatedReport;
  }),

  removeReport: thunk(async (actions, payload) => {
    const updatedReport = await PowerReportScheduleSdk.removeScheduledReport(
      payload.reportId
    );
    actions.updateRemovedReportsState(payload.reportId);

    return updatedReport;
  }),

  updateReportsState: action((state, payload) => {
    if(payload?.id){
      state.allReports[payload.id] = payload;
    }
  }),

  updateRemovedReportsState: action((state, payload) => {
    delete state.allReports[payload];
  })
};
