import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import confirmationDialogStyle from "./confirmationDialog.style";

interface ToolbarProps {
  onCancel: any;
  onConfirm: any;
  onClose: any;
  title?: string;
  text?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  openDialog: boolean;
  dialogType?: string;
  hideCancle?: boolean;
}

const ConfirmationDialog = (props: ToolbarProps) => {
  const {
    onCancel,
    onConfirm,
    title = t`Confirmation`,
    text = t`Are you sure you want to proceed`,
    openDialog,
    cancelLabel = t`Cancel`,
    confirmLabel = t`Yes`,
    onClose,
    dialogType = "info",
    hideCancle = false
  } = props;

  const useStyles = makeStyles(confirmationDialogStyle);
  const classes: { [index: string]: any } = useStyles();

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.dialogStyle }}
    >
      <DialogTitle
        disableTypography
        id="responsive-dialog-title"
        className={classes.dialogTitle}
      >
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentStyle }}>
        <DialogContentText
          className={classes.contentStyle}
        >{text}</DialogContentText>
      </DialogContent>
      <DialogActions
        className={classes.dialogActionStyle}
        classes={{ spacing: classes.actionsSpacing }}
      >
        {!hideCancle &&
          <Button
            width={100}
            white
            marginRight
            onClick={onCancel}
          >
            {cancelLabel}
          </Button>
        }

        <Button
          width={100}
          onClick={onConfirm}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
