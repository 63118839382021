import {
  Dialog,
  IconButton,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Close } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  toC,
  toF,
  toKgPerCm2,
  toPSI
} from "../../services/converter";
import CommonUtils from "../../utils/CommonUtils";
import ErrorBox from "../WarningBox/ErrorBox";
import Action from "./Action";
import styles from "./AutomationRule.style";
import ConditionItem from "./ConditionItem";
import Group from "./ConditionsGroup";
import conditionUtils from "./conditionUtils";
import { Box, CustomedTextField, CustomSelect } from "./CustomedComponents";
import useChurnZero from "@hooks/useChurnZero";

const hasValue = (value: any) => {
  return !!value || value === 0;
};

const powerMeterParameters = [{ code: 60, title: t`Energy` }, { code: 59, title: t`Power` }];


const sourceTypesMatch: any = {
  indoors: 3,
  outdoors: 2,
  sensors: 5,
  systems: 7,
  services: 3,
  outputSensors: 5,
  WRCLocks: 8,
  time: "time",
  powerMeters: 6
};

const typeSourceMapping: any = {
  2: "outdoors",
  1: "indoors",
  3: "services",
  5: "sensors",
  7: "systems",
  8: "WRCLocks",
  time: "time",
  6: 'powerMeters'
};

const sensorCode = 58;

const checkAndConvertValue = (value: number, scaleType: number, scale: number, userTempScale: number, userPressureScale: number, types: any) => {
  if (!scaleType || !hasValue(value)) {
    return value;
  }

  const { measurementUnitTypes, temperatureScale, pressureScale } = types;

  if (measurementUnitTypes.temperature === scaleType && userTempScale !== scale) {
    if (temperatureScale[userTempScale] === "celsius") {
      return Math.round(toC(+value));
    }
    return Math.round(toF(+value));
  }

  if (measurementUnitTypes.pressure === scaleType && userPressureScale !== scale) {
    if (pressureScale[userPressureScale] === "PSI") {
      return Math.round(toPSI(+value));
    }
    return Math.round(toKgPerCm2(+value));
  }

  return value;
};

const getItem = (id: any, siteId: any, source: any, getSystem: any, getUnit: any, getSensor: any, sensorTypes: any, allSensorGroups: any, getPowerMeter: any) => {
  let item: any = "";
  switch (source) {
    case "systems":
      {
        const system = getSystem(id);
        if (!system || system?.site !== siteId) {
          break;
        }
        const { name, brandNum } = system;
        item = { value: id, name, brandNum };
        break;
      }
    case "indoors":
    case "WRCLocks":
    case "services":
      {
        const unit = getUnit(id);
        if (!unit || unit?.site !== siteId) {
          break;
        }
        const { name, brand, type, subType } = unit;
        item = { value: id, name, brandNum: brand, type, subType };
        break;
      }
    case "outdoors":
      {
        const unit = getUnit(id);
        if (!unit || unit?.site !== siteId) {
          break;
        }
        const { name, brand, type } = unit;
        item = { value: id, name, brandNum: brand, type };
        break;
      }
    case "sensors":
      {
        const sensor = getSensor(id);
        if (!sensor || sensor?.site !== siteId) {
          break;
        }
        const { name, type, sensorGroup } = sensor;
        if (!sensorTypes[type]?.enableView) {
          break;
        }

        if (sensorGroup) {
          let targetGroup: any = Object.values(allSensorGroups).find((obj: any) => _.includes(obj.sensors, id));
          item = { value: id, name: targetGroup ? `${name} (${targetGroup?.name})` : name, type };
        } else {
          item = { value: id, name, type };
        }
        break;
      }
    //////////////////////////////////
    case "powerMeters":
      {
        const powerMeter = getPowerMeter(id);
        if (!powerMeter || powerMeter?.site !== siteId) {
          break;
        }
        const { name } = powerMeter;
        item = { value: id, name, };
        break;
      }

    case "time":
      {
        item = { value: id };
        break;
      }
  }
  return item;
};

const controlParameters: any = {
  52: {
    allBrands: true,
    code: 52,
    data_unit_of_measurement: "",
    enabledInTriggers: false,
    enum: "unitFanModes",
    hvac_param_name: "FanSpeed",
    max: 10,
    min: 0,
    plotable: true,
    showInGraph: true,
    title: "Fan Speed"
  },
  51: {
    allBrands: true,
    code: 51,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    enum: "unitOperationModes",
    hvac_param_name: "Mode",
    max: 10,
    min: 0,
    plotable: true,
    showInGraph: true,
    title: "Mode"
  },
  48: {
    allBrands: true,
    code: 48,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    enum: "unitOperationStatuses",
    hvac_param_name: "IndoorOnOffStatus",
    max: 1,
    min: 0,
    plotable: true,
    showInGraph: false,
    title: "ON/OFF"
  },
  49: {
    allBrands: true,
    code: 49,
    data_unit_of_measurement: "°C",
    enabledInTriggers: true,
    hvac_param_name: "RoomTemp",
    max: 0,
    min: 100,
    plotable: true,
    showInGraph: false,
    title: "Room Temp"
  },
  50: {
    allBrands: true,
    code: 50,
    data_unit_of_measurement: "°C",
    enabledInTriggers: true,
    hvac_param_name: "SetTemp",
    max: 0,
    min: 100,
    plotable: true,
    showInGraph: false,
    title: "Set Temp"
  },
  57: {
    allBrands: true,
    code: 57,
    data_unit_of_measurement: "",
    enabledInTriggers: false,
    enum: "",
    hvac_param_name: "SiteTemp",
    max: 150,
    min: -50,
    plotable: true,
    showInGraph: true,
    title: "Site Temperature"
  },
  109: {
    allBrands: true,
    code: 109,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    hvac_param_name: "",
    plotable: true,
    showInGraph: true,
    title: "*Proportional power (hourly)"
  }
};

const airQualityParameters: any = {
  101: {
    allBrands: true,
    code: 101,
    data_unit_of_measurement: "",
    hvac_param_name: "",
    max: 100,
    min: 0,
    plotable: true,
    title: "AQ - Air Quality Index"
  },
  102: {
    allBrands: true,
    code: 102,
    data_unit_of_measurement: "ppb",
    hvac_param_name: "",
    plotable: true,
    title: "AQ - Carbon monoxide"
  },
  103: {
    allBrands: true,
    code: 103,
    data_unit_of_measurement: "ppb",
    hvac_param_name: "",
    plotable: true,
    title: "AQ - Nitrogen dioxide"
  },
  104: {
    allBrands: true,
    code: 104,
    data_unit_of_measurement: "ppb",
    hvac_param_name: "",
    plotable: true,
    title: "AQ - Ozone"
  },
  105: {
    allBrands: true,
    code: 105,
    data_unit_of_measurement: "ug/m3",
    hvac_param_name: "",
    plotable: true,
    title: "AQ - Inhalable particulate matter (<10µm)"
  },
  106: {
    allBrands: true,
    code: 106,
    data_unit_of_measurement: "ug/m3",
    hvac_param_name: "",
    plotable: true,
    title: "AQ - Fine particulate matter (<2.5µm)"
  },
  107: {
    allBrands: true,
    code: 107,
    data_unit_of_measurement: "ppb",
    hvac_param_name: "",
    plotable: true,
    title: "AQ - Sulfur dioxide"
  }
};

const timeFrequenciesWithMultiOperators: any = {
  hourTime: true,
};


const schema = Yup.object().shape({
  name: Yup.string().required(t`Required`).max(50),
  priority: Yup.string().required(t`Required`),
  actions: Yup.array().of(
    Yup.object().shape({
      source: Yup.string().required(t`Required`),
      value: Yup.string().when(["source", "action"], {
        is: (source, action) => source === "alert" || action === 7,
        then: Yup.string(),
        otherwise: Yup.string().required(t`Required`)
      }),
      action: Yup.number().when(["source"], {
        is: (source) => source !== "alert",
        then: Yup.number().required(t`Required`),
        otherwise: Yup.number()
      }),
      itemId: Yup.object().when(["source"], {
        is: (source) => source !== "alert",
        then: Yup.object().required(t`Required`),
        otherwise: Yup.object()
      })
    })
  ),
  conditions: Yup.array().of(
    Yup.object().shape({
      source: Yup.string().required("Required"),

      itemId: Yup.object().when(["source"], {
        is: (source) => source !== "time",
        then: Yup.object().required("Required"),
        otherwise: Yup.object()
      }),

      duration: Yup.string().when(["source"], {
        is: (source) => source !== "time",
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      }),

      timeFrequency: Yup.string().when(["source"], {
        is: (source) => source === "time",
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      }),

      operator: Yup.string().when(["source", "timeFrequency"], {
        is: (source, timeFrequency) => source === "time" && (!timeFrequenciesWithMultiOperators[timeFrequency]),
        then: Yup.string(),
        otherwise: Yup.string().required("Required")
      }),

      value: Yup.string().when(["source", "timeFrequency"], {
        is: (source, timeFrequency) =>
          source === "time" && timeFrequency === "hourlyTime",
        then: Yup.string(),
        otherwise: Yup.string().when(["source", "timeFrequency"], {
          is: (source, timeFrequency) =>
            source === "time" && timeFrequency !== "hourlyTime",
          then: Yup.string().test(
            "match",
            "Value must match at least one of the provided regular expressions",
            (value) => {

              const regexes = [
                /^([01]\d|2[0-3]):([0-5]\d)$/,
                /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2[0-9]|3[01])|(end)\s([01]\d|2[0-3]):([0-5]\d)$/,
                /^(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday)\s([01]\d|2[0-3]):([0-5]\d)$/,
                /^([0-2]\d|3[01])\s([01]\d|2[0-3]):([0-5]\d)$/
              ];
              return regexes.some((regex) => regex.test(value));
            }
          ),
          otherwise: Yup.string().required("Required")
        }),
        action: Yup.number().when(["source"], {
          is: (source) => source !== "alert" || source !== "time",
          then: Yup.number().required(t`Required`),
          otherwise: Yup.number()
        }),
        unitsNumType: Yup.string().when(["source"], {
          is: (source) => source === "systems",
          then: Yup.string().required(t`Required`),
          otherwise: Yup.string()
        })
      }),
      // ),
      groups: Yup.array().of(
        Yup.object().shape({
          conditions: Yup.array().of(
            Yup.object().shape({
              duration: Yup.string().required("Required"),
              value: Yup.string().required("Required"),
              operator: Yup.string().when(["source", "timeFrequency"], {
                is: (source, timeFrequency) => source === "time" && (!timeFrequenciesWithMultiOperators[timeFrequency]),
                then: Yup.string(),
                otherwise: Yup.string().required("Required")
              }),
              itemId: Yup.object().required("Required"),
              source: Yup.string().required("Required"),
              parameter: Yup.object().when(["source"], {
                is: (source) => source !== "sensors",
                then: Yup.object().required(t`Required`),
                otherwise: Yup.object()
              }),
              unitsNum: Yup.number().when(["source"], {
                is: (source) => source === "systems",
                then: Yup.number()
                  .required(t`Required`)
                  .max(100, t`100 max is allowed`)
                  .min(1, t`1 min is allowed`),
                otherwise: Yup.number()
              }),
              unitsNumType: Yup.string().when(["source"], {
                is: (source) => source === "systems",
                then: Yup.string().required(t`Required`),
                otherwise: Yup.string()
              })
            })
          )
        })
      )
    })
  )
});
const AutomationRule = (props: any) => {

  const classes = styles();
  const { close, create, selectedItem, update, customerData, siteId } = props;

  const types = useStoreState((s) => s.types);
  const serviceParams = useStoreState((s) => s.serviceParams);
  const allSites = useStoreState((state) => state.sites.allSites);
  const getServiceParams = useStoreActions((action) => action.traps.getServiceParams);
  const user = useStoreState((s) => s.users.me);
  const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
  const getSystemUnits = useStoreState((s) => s.systems.getSystemUnits);
  const getSystem = useStoreState((s) => s.systems.getSystemByIdLocaly);
  const getUnit = useStoreState((s) => s.units.getUnit);
  const getPowerMeter = useStoreState((s) => s.powerMeters.getPowerMeter);
  const getSensor = useStoreState((s) => s.sensors.getSensorByIdLocaly);
  const allSensorGroups = useStoreState((state) => state.sensors.allSensorGroups);
  const getSensorData = useStoreState((s) => s.sensors.getSensorData);
  const customEnumOptions = useStoreState((s) => s.customEnumOptionsForAutomationRules);
  const { measurementUnitTypes, sensorTypes, trapPriorityTypes } = types;

  const trapPriorityOptions = Object.keys(trapPriorityTypes).reduce((obj: any, priority: any) => {
    obj.push({ name: trapPriorityTypes[priority], value: +priority });
    return obj;
  }, []);

  const [ruleData, setRuleData] = useState<any>({
    name: "",
    siteId: "",
    description: "",
    priority: "",
    isEnabled: true,
    error: "",
    actions: [],
    conditions: [],
    groups: [],
    checkedConditionIds: [],
    editedDescription: false,
    value: "00:00"
  });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cancelClicked, setCancelClicked] = useState<boolean>(false);
  const descriptionRef: any = useRef(null);
  const conditionsBoxRef: any = useRef(null);
  const actionsBoxRef: any = useRef(null);
  const boxesHolderRef: any = useRef(null);

  const {trackEvent} = useChurnZero()

  const { canUpdateAutomationRule = true } =
    allSites[selectedItem?.site || ""] || {};
  const {
    temperatureScale: userTempScale = 1,
    measurementUnits: userPressureScale = 2
  } = user;
  const scaleMatch: any = {
    "°C": {
      scaleType: measurementUnitTypes["temperature"],
      scale: userTempScale
    },
    "kg/cm2": {
      scaleType: measurementUnitTypes["pressure"],
      scale: userPressureScale
    },
    MPa: {
      scaleType: measurementUnitTypes["pressure"],
      scale: userPressureScale
    },
    PSI: {
      scaleType: measurementUnitTypes["pressure"],
      scale: userPressureScale
    }
  };
  const scaleNoMatch = {
    scaleType: measurementUnitTypes["unspecified"],
    scale: null
  };

  const mainCondition = {
    source: "",
    itemId: "",
    parameter: "",
    operator: "",
    value: "",
    duration: "0",
    params:
      siteId && allSites[siteId]?.enableAirQuality
        ? Object.values({ ...controlParameters, ...airQualityParameters })
        : Object.values(controlParameters),
    unitsNum: "",
    unitsNumType: "",
    relation: "and"
  };

  const inOrder = (tree: any) => {
    let relations: any = [];
    let nodes: any = [];
    let current = tree;
    if (current) {
      if (current.isCondition || current.isGroup) {
        return { relations: [], nodes: [current] };
      }
      let traverse = (node: any) => {
        if (node.ruleSets && node.ruleSets[0]?.relation && !node.ruleSets[0]?.isGroup && !node.ruleSets[0]?.isCondition) {
          traverse(node.ruleSets[0]);
        }
        else {
          nodes.push(node.ruleSets[0]);
        }

        relations.push(node.relation);

        if (node.ruleSets && node.ruleSets[1]?.relation && !node.ruleSets[1]?.isGroup && !node.ruleSets[1]?.isCondition) {
          traverse(node.ruleSets[1]);
        }
        else {
          nodes.push(node.ruleSets[1]);
        }
      };

      traverse(current);
    }
    return { relations, nodes };
  };

  const reCreateCondition = (node: any, relation: string, siteId: any) => {
    const {
      rules = [{}],
      triggerDelayTimeInMinutes: duration = "0",
      logicalSystemRuleObject,
      sensorValue = ""
    } = node;
    const rule = logicalSystemRuleObject ? logicalSystemRuleObject : rules[0];
    const {
      code,
      value: conditionValue,
      operator,
      timeFrequency,
      unitType,
      resourceId,
      scaleType,
      scale,
      unitsNum = "",
      unitsNumType = ""
    } = rule;

    const source = typeSourceMapping[unitType] ? typeSourceMapping[unitType] : unitType;
    const value = source === "sensors" && hasValue(sensorValue) ? sensorValue : checkAndConvertValue(conditionValue, scaleType, scale, userTempScale, userPressureScale, types);
    const itemId: any = !siteId ? "" : getItem(resourceId, siteId, source, getSystem, getUnit, getSensor, sensorTypes,allSensorGroups, getPowerMeter,);

    const isThreshold = "thresholdValue" in node;

    const condition = isThreshold
      ? {
        key: conditionUtils.generateId(),
        value: (node?.thresholdValue || value) + "",
        operator: "threshold",
        thresholdOperator: node.relation,
        unitsNum,
        unitsNumType,
        duration: CommonUtils.toHoursAndMinutes(duration),
        source,
        itemId,
        params:
          siteId && allSites[siteId]?.enableAirQuality
            ? Object.values({ ...controlParameters, ...airQualityParameters })
            : Object.values(controlParameters),
        parameter: source === "sensors" ? "" : serviceParams[code],
        parameter2: serviceParams[rules[1].code],
        relation
      }
      : {
        key: conditionUtils.generateId(),
        value: value + "",
        operator,
        timeFrequency,
        unitsNum,
        unitsNumType,
        duration: CommonUtils.toHoursAndMinutes(duration),
        source,
        itemId,
        params:
          siteId && allSites[siteId]?.enableAirQuality
            ? Object.values({ ...controlParameters, ...airQualityParameters })
            : Object.values(controlParameters),
        parameter: source === "sensors" ? "" : (source === 'powerMeters' ? powerMeterParameters.filter((param: any) => +param.code === +code)[0] : serviceParams[code]),
        relation
      };

    return { data: condition };
  };

  const extractConditions = (ruleSets: any, siteId: any) => {
    const data = inOrder(ruleSets);

    data.relations.push("and");
    const newConds: any = [];
    const newGroups: any = [];

    const { nodes, relations } = data;
    for (let x in nodes) {
      const node = { ...nodes[x] };
      const relation = relations[x];

      if (node.isCondition) {
        const nodeResult = reCreateCondition(node, relation, siteId);
        newConds.push(nodeResult.data);
      }

      if (node.isGroup) {
        delete node.isGroup;
        const groupData = inOrder(node);
        groupData.relations.push("and");
        const groupConditions: any = [];
        const { relations: gRelations, nodes: gNodes } = groupData;

        for (let i in gNodes) {
          const gNode = gNodes[i];
          const gRelation = gRelations[i];

          if (gNode.isCondition) {
            const nodeResult = reCreateCondition(gNode, gRelation, siteId);
            groupConditions.push({ ...mainCondition, ...nodeResult.data });
          }

        }

        newGroups.push({
          relation,
          conditions: groupConditions,
          key: conditionUtils.generateId()
        });
      }
    }

    return { conditions: newConds, groups: newGroups };
  };

  useEffect(() => { conditionUtils.generateId.reset(); }, []);
  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    const {
      trapSets = [{}],
      description,
      name,
      site: selectedSiteId,
      isEnabled,
      priority
    } = selectedItem;
    const { ruleSets = [{}], actions = [] } = trapSets[0];
    const siteId = allSites[selectedSiteId] ? selectedSiteId : "";

    const { groups, conditions } = extractConditions(ruleSets[0], siteId);
    const uiActions: any = [];

    actions.forEach((action: any) => {
      const {
        scaleType,
        scale,
        command,
        resourceId,
        resourceType = "",
        value = ""
      } = action;
      if (command === 7 && !resourceType) {
        uiActions.push({ source: "alert", action: "", value: "", itemId: "" });
      } else {
        // source match
        const source = typeSourceMapping[resourceType];
        uiActions.push({
          source: source === "sensors" ? "outputSensors" : source,
          action: command,
          value:
            checkAndConvertValue(
              value,
              scaleType,
              scale,
              userTempScale,
              userPressureScale,
              types
            ) + "",
          itemId: !siteId
            ? ""
            : getItem(
              resourceId,
              siteId,
              source,
              getSystem,
              getUnit,
              getSensor,
              sensorTypes,
              allSensorGroups,
              getPowerMeter
            )
        });
      }
    });

    setRuleData({
      priority,
      groups,
      conditions,
      siteId,
      description,
      name,
      isEnabled,
      actions: uiActions,
      error: "",
      checkedConditionIds: []
    });
  }, []);

  const arrayToTree = (conditionsArray: any[]) => {
    const relations = extractRelations(conditionsArray);
    const groupedAnds = groupByRelation(relations, conditionsArray, "and");
    const tree = groupByRelation(groupedAnds.relations, groupedAnds.nodes, "or");
    return tree.nodes;
  };

  const extractRelations = (nodes: any[]) => {
    if (!nodes.length || nodes.length === 1) {
      return [];
    }
    const relations = nodes.map((node: any) => node.relation);
    relations.pop();
    return relations;
  };

  const groupByRelation = (relations: any[], nodes: any[], relation: string) => {
    let result: any = { relations, nodes };
    let firstAndIndex = result.relations.indexOf(relation);

    while (firstAndIndex !== -1) {
      result = groupNodes(firstAndIndex, nodes, result.relations);
      firstAndIndex = result.relations.indexOf(relation);
    }
    return result;
  };

  const groupNodes = (index: number, nodes: any[], relations: string[]) => {
    const node1 = isBasicNode(nodes[index]) ? parseNode(nodes[index]) : nodes[index];
    const node2 = isBasicNode(nodes[index + 1]) ? parseNode(nodes[index + 1]) : nodes[index + 1];

    const newNode = {
      relation: relations[index],
      ruleSets: [node1, node2]
    };

    relations.splice(index, 1);
    nodes.splice(index + 1, 1);
    nodes[index] = newNode;

    return { relations, nodes };
  };

  const isBasicNode = (node: any) => {
    return "operator" in node;
  };

  const parseNode = (condition: any) => {
    const {
      unitsNumType,
      unitsNum,
      source,
      value,
      itemId,
      timeFrequency,
      operator,
      parameter,
      duration,
      parameter2,
      thresholdOperator,
    } = condition;
    const timeArray = duration.split(":");
    let ruleSetsFirstOption: any = {
      rules: [],
      isCondition: true,
      triggerDelayTimeInMinutes:
        Number(timeArray[0] || "") * 60 + Number(timeArray[1] || "")
    };
    const scaleValues =
      source === "sensors" && itemId?.type === 1
        ? {
          scaleType: measurementUnitTypes["temperature"],
          scale: userTempScale
        }
        : scaleMatch[parameter?.data_unit_of_measurement] || scaleNoMatch;
    const unitType =
      source === "indoors" ? itemId?.type : sourceTypesMatch[source];

    const rulesObj: any = operator.includes("threshold")
      ? {
        relation: thresholdOperator,
        thresholdValue: +value,
        ...scaleValues,
        rules: [
          {
            resourceId: itemId?.value,
            code: parameter?.code,
            operator: "self",
            unitType
          },
          {
            resourceId: itemId?.value,
            code: parameter2?.code,
            operator: "self",
            unitType
          }
        ]
      }
      : {
        rule: {
          code: source === "sensors" ? sensorCode : parameter?.code,
          value,
          resourceId: itemId?.value,
          operator,
          unitType,
          ...scaleValues
        }
      };

    const rule = {
      code: source === "sensors" ? sensorCode : parameter?.code,
      value,
      resourceId: source === "time" ? "all" : itemId?.value,
      timeFrequency,
      operator,
      unitType,
      ...scaleValues
    };
    const rules: any = [];
    if (source === "systems") {
      const unitType = unitsNumType === 1 || unitsNumType === 2 ? 3 : 2;
      const systemUnits: any = getSystemUnits(itemId?.value, unitType === 2 ? "outdoor" : controlParameters[rule.code] ? "indoor" : "service"); //backhere
      const isPercent = unitsNumType % 2 === 0 ? true : false;
      const numberOfResources = isPercent ? ((systemUnits.length / 100) * unitsNum) : unitsNum;
      systemUnits.forEach((resource: any) => rules.push({ ...rule, ...resource }));
      ruleSetsFirstOption.relation = "resourcecount";
      ruleSetsFirstOption.numberOfResourcesForEvent = numberOfResources;
      ruleSetsFirstOption.logicalSystemRuleObject = {
        ...rule,
        unitsNumType,
        unitsNum
      };
    } else if (source === "sensors") {
      const {
        userMinMax = null,
        valueMax,
        valueMin
      } = getSensorData(itemId?.value) || {};
      if (!userMinMax) {
        rules.push(rule);
      } else {
        const { userMin, userMax } = userMinMax;
        ruleSetsFirstOption.sensorValue = +value;
        const convertedValue = ((((+value - +userMin) * (valueMax - valueMin)) / (+userMax - +userMin)) + +valueMin);
        rules.push({ ...rule, value: convertedValue });
      }
    } else {
      rules.push(rule);
    }
    ruleSetsFirstOption.rules = rules;
    if (operator.includes("threshold"))
      ruleSetsFirstOption = { ...ruleSetsFirstOption, ...rulesObj };
    return ruleSetsFirstOption;
  };

  const onSubmit = (values: any) => {

    trackEvent('InterlockingApplyRule','The user created an Interlock rule');

    const { conditions, groups, actions, name, isEnabled, priority } = values;
    const filteredActions: any = [];
    actions.forEach((action: any) => {
      if (action.source === "alert") {
        filteredActions.push({ command: 7 });
        return;
      }

      const scaleValues =
        action.action === 3
          ? {
            scaleType: measurementUnitTypes["temperature"],
            scale: userTempScale
          }
          : {};
      let userMinMaxValues: any = {};
      if (action.source === "outputSensors") {
        const { userMinMax = null } = getSensorData(action.itemId?.value) || {};
        if (userMinMax) {
          userMinMaxValues = userMinMax;
        }
      }
      filteredActions.push({
        command: +action.action,
        resourceType: action.source === "indoors" ? action.itemId?.type : sourceTypesMatch[action.source],
        resourceId: action.itemId?.value,
        value: +action.value,
        ...scaleValues,
        ...userMinMaxValues
      });
    });

    //extract relations!
    const pageNodes = [];
    const pageRelations = [];

    for (let index in groups) {
      const group = groups[index];
      pageRelations.push(group?.relation);
      const groupTree = arrayToTree(group.conditions)[0];
      groupTree.isGroup = true;
      pageNodes.push(groupTree);
    }

    if (conditions.length === 0) {
      pageRelations.pop();
    } else {
      const conditionsTree = conditions.length > 1 ? arrayToTree(conditions)[0] : parseNode(conditions[0]);
      pageNodes.push(conditionsTree);
    }

    const groupPageByAnds = groupByRelation(pageRelations, pageNodes, "and");
    const pageTree = groupByRelation(groupPageByAnds.relations, groupPageByAnds.nodes, "or").nodes;

    const trap: any = {
      name,
      type: 201,
      isEnabled,
      site: siteId,
      priority,
      description: descriptionRef?.current?.value || "",
      trapSets: [
        {
          ruleSets: pageTree,
          actions: filteredActions
        }
      ]
    };

    if (!selectedItem) {
      create(trap).finally(() => close());
      return;
    }

    delete trap.type;
    delete trap.site;
    update(trap, selectedItem.id).finally(() => close());
  };

  const disabled = !canUpdateAutomationRule;

  const scrollPage = (elementTo: string) => {
    // We used setTimeout to make sure that the element is rendered before scrolling to it
    setTimeout(() => {
      if (elementTo === "conditions") {
        conditionsBoxRef?.current?.scrollTo({ top: 9999, behavior: "smooth", });
        boxesHolderRef?.current?.scrollTo({ top: 0, behavior: "smooth", });
      }
      if (elementTo === "actions") {
        actionsBoxRef?.current?.scrollTo({ top: 9999, behavior: "smooth", });
        boxesHolderRef?.current?.scrollTo({ top: 9999, behavior: "smooth", });
      }
    });
  };

  const handleTextareaChange = (values:any,setFieldValue:Function) => (event: any):void => {

      const {
        target: { value }
      } = event;
      if (
        values.description === value &&
        values.editedDescription
      ) {
        setFieldValue("editedDescription", false);
      } else if(!values.editedDescription){
         setFieldValue("editedDescription", true);
      }

  }


  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{selectedItem ? t`Edit Rule` : t`Add New Rule`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => setCancelClicked(true)}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <Formik
        initialValues={ruleData}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ values, setFieldValue, setValues, errors, touched, dirty }) => {
          return (
            // @ts-ignore
            <Form translate="yes" className={classes.dialogContent}>
              <div ref={boxesHolderRef} className={classes.boxesHolder}>
                <div id="basic-info" className={clsx(classes.basicInfoContainer)}>
                  <div style={{ marginRight: 15, width: "400px" }}>
                    <CustomedTextField
                      disabled={disabled}
                      value={values.name}
                      name="ruleName"
                      className={classes.removeTopMargin}
                      onChange={(event: any) => setFieldValue("name", event.target.value)}
                      label={t`Rule Name`}
                      error={errors.name && touched.name}
                      maxLength={50}
                    />
                    <div className={classes.selectsContainer}>
                      <CustomSelect
                        disableTooltip
                        disabled={disabled}
                        className={clsx(classes.mainSelect, classes.removeRightMargin)}
                        placeholder={t`Priority`}
                        name="priority"
                        error={errors.priority && touched.priority}
                        value={values.priority}
                        options={trapPriorityOptions}
                        onChange={(event: any) => setFieldValue("priority", event.target.value)}
                      />
                    </div>
                  </div>
                  <TextareaAutosize
                    disabled={disabled}
                    className={classes.textArea}
                    aria-label="minimum height"
                    minRows={4}
                    maxRows={4}
                    placeholder={t`Insert Description / Automatic Rule Description…`}
                    defaultValue={values.description}
                    ref={descriptionRef}
                    onChange={handleTextareaChange(values,setFieldValue)}
                  />
                  <CoolSwitch disabled={disabled} className={classes.switchButton} checked={values.isEnabled} onChange={(event: any) => setFieldValue("isEnabled", event.target.checked)} />
                </div>
                <Box
                  boxRef={conditionsBoxRef}
                  title={t`Conditions`}
                  addBottomMargin
                  bLabel1={t`Add Condition`}
                  action1={() => {
                    const newConditions = [
                      ...values.conditions,
                      { key: conditionUtils.generateId(), ...mainCondition }
                    ];
                    setFieldValue("conditions", newConditions);
                    scrollPage("conditions");
                  }}
                  bDisabled1={disabled}
                  bLabel2={t`Group`}
                  bDisabled2={values.checkedConditionIds.length < 2 || disabled}
                  action2={() => {
                    const leftConditions: any = [];
                    const groupConditions: any = [];
                    values.conditions.forEach((cond: any) => {
                      if (values.checkedConditionIds.includes(cond.key)) {
                        groupConditions.push(cond);
                      } else {
                        leftConditions.push(cond);
                      }
                    });
                    setValues({
                      ...values,
                      checkedConditionIds: [],
                      conditions: leftConditions,
                      groups: [
                        ...values.groups,
                        {
                          relation: "and",
                          key: conditionUtils.generateId(),
                          conditions: groupConditions
                        }
                      ]
                    });
                  }}
                >
                  {values.groups.map((group: any, index: number) => (
                    <Group
                      disabled={disabled}
                      key={`group-${group.key}`}
                      groupIndex={index}
                      name="groups"
                      groupKey={group.key}
                      conditions={group.conditions}
                      relation={group.relation}
                      site={customerData?.sites[siteId]}
                      errors={errors}
                      touched={touched}
                      serviceParamTypes={serviceParamTypes}
                      customEnumOptions={customEnumOptions}
                      getServiceParams={getServiceParams}
                      controlParameters={controlParameters}
                      setFieldValue={setFieldValue}
                      unGroup={(key: any, newConditions: any) => {
                        setValues({
                          ...values,
                          groups: values.groups.filter(
                            (group: any) => group.key !== key
                          ),
                          conditions: [...values.conditions, ...newConditions]
                        });
                      }}
                      duplicateGroup={() => {
                        const newConditions = group.conditions.map(
                          (cond: any) => ({
                            ...cond,
                            key: conditionUtils.generateId()
                          })
                        );
                        const newGroup = JSON.parse(
                          JSON.stringify({
                            conditions: newConditions,
                            key: conditionUtils.generateId(),
                            relation: group.relation
                          })
                        );
                        setValues({
                          ...values,
                          groups: [...values.groups, newGroup]
                        });
                      }}
                      hideRelation={
                        values.conditions.length === 0 &&
                        index === values.groups.length - 1
                      }
                    />
                  ))}
                  {values.conditions.map((condition: any, index: number) =>
                    <ConditionItem
                      disabled={disabled}
                      powerMeterParameters={powerMeterParameters}
                      key={`condition-${condition.key}`}
                      index={index}
                      name="conditions"
                      lastCondition={index === (values.conditions.length - 1)}
                      values={values}
                      site={customerData?.sites[siteId]}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                      serviceParamTypes={serviceParamTypes}
                      customEnumOptions={customEnumOptions}
                      getServiceParams={getServiceParams}
                      condition={condition}
                      errors={errors}
                      touched={touched}
                      controlParameters={controlParameters}
                      checkedConditionIds={values.checkedConditionIds}
                      duplicateCondition={() => {
                        const newCondition = JSON.parse(JSON.stringify(condition));
                        newCondition.key = conditionUtils.generateId();
                        setFieldValue("conditions", [
                          ...values.conditions,
                          newCondition
                        ]);
                        scrollPage("conditions");
                      }}
                      deleteCondition={() => setFieldValue("conditions", values.conditions.filter((condition1: any) => condition1.key !== condition.key))}
                    />)
                  }
                </Box>
                <Box
                  boxRef={actionsBoxRef}
                  title={t`Action`}
                  bLabel1={t`Add Action`}
                  action1={() => {
                    const newActions = [
                      ...values.actions,
                      { key: conditionUtils.generateId() }
                    ];
                    setFieldValue("actions", newActions);
                    scrollPage("actions");
                  }}
                  bDisabled1={disabled}
                >
                  {values.actions.map((action: any, index: number) => (
                    <Action
                      disabled={disabled}
                      key={`action-${index}`}
                      index={index}
                      values={values}
                      site={customerData?.sites[siteId]}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                      serviceParamTypes={serviceParamTypes}
                      customEnumOptions={customEnumOptions}
                      action={action}
                      errors={errors}
                      touched={touched}
                      duplicateAction={() => {
                        const newAction = JSON.parse(JSON.stringify(action));
                        newAction.key = conditionUtils.generateId();
                        setFieldValue("actions", [
                          ...values.actions,
                          newAction
                        ]);
                        scrollPage("actions");
                      }}
                      deleteAction={() =>
                        setFieldValue(
                          "actions",
                          values.actions.filter(
                            (_: any, i: number) => i !== index
                          )
                        )
                      }
                    />
                  ))}
                </Box>
              </div>
              <div className={classes.actionsContainer}>
                {!!errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
                <Button disabled={disabled} onClick={() => setCancelClicked(true)} onMouseDown={(event: any) => event.preventDefault()} marginRight white width={150}> {t`cancel`}</Button>
                <Button type="submit" width={150}> {t`save`}</Button>
              </div>
              {values.error && <ErrorBox
                error={"All conditions and actions will be deleted"}
                onAccept={() => setValues(
                  {
                    ...values,
                    siteId: values.error,
                    actions: [],
                    conditions: [],
                    groups: [],
                    checkedConditionIds: [],
                    error: ""
                  })}
                onClose={() => setFieldValue("error", "")}
              />}
              {cancelClicked ? dirty ?
                <ErrorBox
                  error={"All conditions and actions will be deleted"}
                  onAccept={close}
                  onClose={() => setCancelClicked(false)}
                />
                : close() : null}
            </Form>
          );
        }}
      </Formik>

    </Dialog>
  );
};

export default AutomationRule;
