import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { InputLabel, Typography } from '@material-ui/core';
import {t} from 'ttag';

import Checkbox from '@cool_widgets/CoolCheckbox';
import CheckboxChecked from '@icons/CheckboxChecked';

import classes from './CheckboxesList.module.css';

/**
 * Returns appropriate CSS classes based on the list type.
 * @param {string} type - The list type, either 'list' or 'grid'.
 * @returns {{ wrapperClass: string, itemClass: string }} An object containing wrapper and item class names.
 */
const getClassesByListType = (type) => {
	if(type === 'grid'){
		return {
			wrapperClass:classes.wrapperGrid,
			itemClass:classes.itemGrid
		};
	}

	return {
		wrapperClass:classes.wrapperList,
		itemClass:classes.itemList
	};
};

/**
 * CheckboxesList component for rendering a list of checkboxes.
 * @prop {function} onChange - Callback function invoked when checkbox values change.
 * @prop {Array} value - Array of currently selected values.
 * @prop {Array} options - Array of options to render as checkboxes, each with `text` and `value` properties.
 * @prop {boolean} selectAllOnMount - Whether to select all checkboxes on mount.
 * @prop {boolean} showSelectAll - Whether to show a "Select All" checkbox.
 * @prop {string} selectAllLabel - A custom text to show in the "Select All" checkbox.
 * @prop {string} itemClassName - Additional class name for individual checkbox items.
 * @prop {string} wrapperClassName - Additional class name for the checkbox wrapper.
 * @prop {string} containerClassName - Additional class name for the overall container.
 * @prop {string} checkboxClassName - Additional class name for individual checkboxes.
 * @prop {string} error - Error message to display.
 * @prop {string} listType - Layout of the list, either 'list' or 'grid'.
 * @prop {boolean} disabled - Whether to disable all checkboxes.
 */
const CheckboxesList = ({onChange, value, options, selectAllOnMount,showSelectAll,selectAllLabel,itemClassName,wrapperClassName,containerClassName,checkboxClassName,error,disabled,listType}) => {

	
	/**
	 * Sets the checked state of all checkboxes.
	 * @param {boolean} checked - Whether to set all checkboxes as checked or unchecked.
	 */
	const setAllCheckboxes = (checked) => {
		if(checked){
			const newValue = options.map(({value})=> value);
			return onChange(newValue);
		}
		onChange([]);
	};

	/**
	 * Handles clicks on individual checkboxes.
	 * @param {string|number} value - The value of the clicked checkbox.
	 * @param {Event} event - The click event.
	 */
	const handleCheckboxClick = (key) => (event) => {
		const {target:{checked}} = event;

		if(key === 'all'){
			return setAllCheckboxes(checked);
		}

		const newValue = [];
		computedOptions.forEach(({value,checked:optionChecked}) => {
			if(value === key){
				if(checked){
					newValue.push(value);
				}
			}else{
				if(optionChecked){
					newValue.push(value);
				}
			}			
		});

		onChange(newValue);
	};

	/**
 	* Memoized array of options with updated checked states.
 	* @type {Array<{ value: string|number, text: string, checked: boolean }>}
 	*/
	const computedOptions = useMemo(() => {
		return options.map(option => {
			const checked = value.includes(option.value);
			return {...option, checked};
		});

	},[options,value]);

	/**
 	* Determines if all checkboxes are currently selected.
 	* @type {boolean}
 	*/
	const isAllSelected = value.length === options.length;

	/**
 	* Selects all checkboxes on mount if the `selectAllOnMount` prop is true and no options are initially selected.
 	*/
	useEffect(()=>{
		if(selectAllOnMount && !value.length){
			setAllCheckboxes(true);
		}
	},[options]);

	/**
 	* Retrieves appropriate CSS classes based on the list type.
 	* @returns {{ wrapperClass: string, itemClass: string }} An object containing wrapper and item class names.
 	*/
	const {wrapperClass,itemClass} = getClassesByListType(listType);

	/**
 	* Renders the "Select All" checkbox conditionally.
 	* @returns {JSX.Element|null} The "Select All" checkbox element or null if not needed.
 	*/
	const renderSelectAllCheckbox = () => {

		if(options.length < 2 || !showSelectAll){
			return null;
		}

		return (	
			<InputLabel className={clsx('checkboxes-list-item',classes.itemName,itemClass,itemClassName)}>
				<Checkbox
					disabled={disabled}
					checked={isAllSelected}
					onChange={handleCheckboxClick('all')}
					checkedIcon={<CheckboxChecked />}
					className={clsx('checkboxes-list-checkbox',classes.smallCheckbox,checkboxClassName)}
					color="default"
					variant="outlined"
					edge="end"
				/>
				{selectAllLabel}
			</InputLabel>);
	};

	return (
		<div className={clsx('checkboxes-list-container',containerClassName ,{[classes.disabled]:disabled})}>
			<div className={(clsx('checkboxes-list-wrapper',wrapperClass,wrapperClassName))}>
				{renderSelectAllCheckbox()}
				{computedOptions.map(({value,checked,text}) => {
					return (
						<InputLabel key={value} className={clsx('checkboxes-list-item',classes.itemName,itemClass,itemClassName)}>
							<Checkbox
								disabled={disabled}
								checked={checked}
								onChange={handleCheckboxClick(value)}
								checkedIcon={<CheckboxChecked />}
								className={clsx('checkboxes-list-checkbox',classes.smallCheckbox,checkboxClassName)}
								color="default"
								variant="outlined"
								edge="end"
							/>
							{text}
						</InputLabel>
					);
				})}
			</div>
			<Typography className={classes.error}>{error}</Typography>
		</div>
	);
};

CheckboxesList.propTypes = {
	options:PropTypes.arrayOf(PropTypes.shape({
		text:PropTypes.string,
		value:PropTypes.oneOfType([PropTypes.string,PropTypes.number])
	})),
	value:PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string,PropTypes.number])),
	onChange:PropTypes.func.isRequired,
	selectAllOnMount:PropTypes.bool,
	disabled:PropTypes.bool,
	showSelectAll:PropTypes.bool,
	selectAllLabel:PropTypes.string,
	itemClassName:PropTypes.string, 
	wrapperClassName:PropTypes.string,
	containerClassName:PropTypes.string,
	checkboxClassName:PropTypes.string,
	error:PropTypes.string,
	listType:PropTypes.oneOf(['list','grid'])

};

CheckboxesList.defaultProps = {
	options:[],
	value:[],
	selectAllOnMount:false,
	disabled:false,
	showSelectAll:true,
	selectAllLabel:t`Select All`,
	itemClassName:'',
	wrapperClassName:'',
	containerClassName:'',
	checkboxClassName:'',
	error:'',
	listType:'list'
};

export default CheckboxesList;