import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import { number } from "yup";

const ExportUtils = {
    downloadFile(csvContent: any, filename: string = "Parameters") {
        const encodedUri = encodeURI(csvContent),
         aElement = document.createElement("a");
        aElement.style.display = "none";
        aElement.setAttribute("href", encodedUri);
        aElement.setAttribute("download", `${filename}.csv`);
        document.body.appendChild(aElement);
        aElement.click();
        document.body.removeChild(aElement);
    },

    buildUniqueCodesHeader(rows: any[], source: string) {
        // Build Unique Codes Header with first header 'timestamp', second header 'unitName' and third header 'unitType'
        let codeHeadersArray = ["timestamp", "unitName", "unitType"];
        if (source.indexOf("full") !== -1)
        {
          rows.forEach(function(row) {
            const keysArray = Object.keys(row);
            codeHeadersArray = _.union(codeHeadersArray, keysArray);
        });
      }else{
          const keysArray: any[] = Object.values(rows);
          codeHeadersArray = _.union(codeHeadersArray, keysArray);
        }
        return codeHeadersArray;
    },

    buildTitlesHeader(codeHeadersArray: any[], serviceParams: any, tempUnit: string) {
        // build titles header
        const csvHeadersArray = ["Time Stamp", "Unit Name", "Unit Type"];
        codeHeadersArray.forEach((code) => {
            if (serviceParams[code]) {
                const unit = serviceParams[code].data_unit_of_measurement;
                if (unit === "°C") {
                    csvHeadersArray.push(serviceParams[code].title + " (" + tempUnit + ")");
                } else if (unit) {
                    csvHeadersArray.push(serviceParams[code].title + " (" + unit + ")");
                } else {
                    csvHeadersArray.push(serviceParams[code].title);
                }
            }
        });
        return csvHeadersArray;
    },

    buildContent(
        rows: any[],
        unitName: string,
        unitType: string,
        codeHeadersArray: string[],
        timezone: string,
        dateTimeFormat: any
    ) {
        let content = "";
        rows.forEach(function(rowArray) {
            const rowValues = Array<string>();
            codeHeadersArray.forEach((key) => {
                if (key === "timestamp") {
                    rowValues.push(moment(rowArray[key]).tz(timezone).format(dateTimeFormat));
                } else if (key === "unitName") {
                    rowValues.push(unitName);
                } else if (key === "unitType") {
                    rowValues.push(unitType);
                } else {
                    const value = rowArray[key];
                    rowValues.push(_.isNil(value) ? "" : value.toString());
                }
            });
            const row = rowValues.join(",");
            content += row + "\r\n";
        });
        return content;
    },
    getHeadersCodesAndTitles(codeHeadersArray: any[], serviceParams: any, tempUnit: string) {
        // build titles header
        const titlesArray = ["Time Stamp", "Unit Name", "Unit Type"];
        const codesArray: any = ["timestamp", "unitName", "unitType"];
        codeHeadersArray.forEach((code) => {
            if (serviceParams[code]) {
                codesArray.push(code);
                const unit = serviceParams[code].data_unit_of_measurement;
                if (unit === "°C") {
                    titlesArray.push(serviceParams[code].title + " (" + tempUnit + ")");
                } else if (unit) {
                    titlesArray.push(serviceParams[code].title + " (" + unit + ")");
                } else {
                    titlesArray.push(serviceParams[code].title);
                }
            }
        });
        return {codes: codesArray, titles: titlesArray};
    }
};

export default ExportUtils;
