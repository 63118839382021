import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { ChevronRight, Close, ExpandMore, Search } from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import CoolRemoteSdk from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import styles from "./policies.style";

interface IPoliciesAddEdit {
  policies: any;
  setPolicies: any;
  disabled?: boolean;
}

const createDataTree = (dataset: any) => {
  const hashTable = Object.create(null);
  dataset.forEach((aData: any) => hashTable[aData.id] = { ...aData, children: [] });
  const dataTree: any = [];
  dataset.forEach((aData: any) => {
    if (aData.parentId) {
      hashTable[aData.parentId]?.children.push(hashTable[aData.id]);
    }
    else {
      dataTree.push(hashTable[aData.id]);
    }
  });
  return dataTree;
};

const AddEditPolicies = (props: IPoliciesAddEdit) => {
  const {
    policies,
    setPolicies
  } = props;

  const [availablePolices, setAvailablePolicies] = useState<any>({});
  const [roles, setRoles] = useState<any>({});
  const [customerUnits, setCustomerUnits] = useState<any>(null);
  const [customerGroups, setCustomerGroups] = useState<any>(null);

  const [tree, setTree] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [flatTree, setFlatTree] = useState<any>({});
  const [defaultExpanded, setDefaultExpanded] = useState<any>([]);
  const [expanded, setExpanded] = useState<any>([]);

  const { customerId } = useStoreState((state) => state.selections.mobileSelections);
  const customers = useStoreState((state) => state.customers.allCustomers);
  const customerSites = useStoreState((state) => state.sites.allSites);
  const startLoader = useStoreActions(
    (actions) => actions.loader.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loader.finishLoader
  );

  useEffect(() => {
    if (!customerId || !CoolRemoteSdk) {
      return;
    }
    startLoader();

    Promise.all([
      CoolRemoteSdk?.User?.getAvailablePolicies(customerId),
      CoolRemoteSdk?.Services?.getRoles(),
      CoolRemoteSdk?.Customer?.getCustomerUnits(customerId),
      CoolRemoteSdk?.Customer?.getCustomerGroups(customerId)
    ])
      .then(([polices, rolesResp, unitsResp, groupsResp]: any) => {
        const policiesObj = _.groupBy(polices, (p: any) => p.entityId);
        setAvailablePolicies(policiesObj);
        setRoles(rolesResp);
        setCustomerUnits(unitsResp);
        setCustomerGroups(groupsResp);
      })
      .finally(() => finishLoader());

  }, [customerId, CoolRemoteSdk]);

  useEffect(() => {
    if (!customerId || _.isEmpty(availablePolices) || !customerGroups || !customerUnits) {
      return;
    }
    const selectedCustomer = customerId;
    const expanded: any = new Set();
    expanded.add(customerId);
    const entitiesWithPols = Object.keys(policies);

    const data: any = {};
    data[customerId] = {
      ...customers[selectedCustomer],
      hasPro: true,
      hasPermissions: true,
      removeIfEmpty: false,
      parents: [],
      level: 1
    };

    const sitesArr: any = Object.values(customerSites);
    for (let site of sitesArr) {
      if (site?.customer !== selectedCustomer) {
        continue;
      }
      data[site.id] = {
        ...site, parentId: site.customer,
        hasPro: true,
        hasPermissions: true,
        removeIfEmpty: false,
        parents: [site?.customer],
        level: 2
      };
      data[`groups-${site.id}`] = {
        id: `groups-${site.id}`,
        name: t`Groups`,
        parentId: site.id,
        removeIfEmpty: true,
        level: 3,
        controlOnly: true
      };

      data[`units-${site.id}`] = {
        id: `units-${site.id}`,
        name: t`Units`,
        parentId: site.id,
        removeIfEmpty: true,
        level: 3,
        controlOnly: true
      };

      if (entitiesWithPols.indexOf(site.id) > -1) {
        expanded.add(site.id);
      }

    }

    const unitsArr: any = Object.values(customerUnits);
    for (let unit of unitsArr) {
      if (unit?.customer !== selectedCustomer) {
        continue;
      }
      data[unit.id] = {
        ...unit,
        parentId: `units-${unit.site}`,
        hasPro: false,
        hasPermissions: true,
        removeIfEmpty: false,
        parents: [unit?.customer, unit?.site, `units-${unit?.site}`],
        controlOnly: true,
        level: 4
      };
      if (entitiesWithPols.indexOf(unit.id) > -1) {
        expanded.add(unit.site);
        expanded.add(`units-${unit.site}`);
      }
    }

    const groupsArr: any = Object.values(customerGroups);
    for (let group of groupsArr) {
      if (group?.customer !== selectedCustomer) {
        continue;
      }
      data[group.id] = {
        ...group,
        parentId: `groups-${group.site}`,
        hasPro: false,
        hasPermissions: true,
        removeIfEmpty: false,
        parents: [group?.customer, group?.site, `groups-${group?.site}`],
        controlOnly: true,
        level: 4
      };
      if (entitiesWithPols.indexOf(group.id) > -1) {
        expanded.add(group.site);
        expanded.add(`groups-${group.site}`);
      }
    }

    const treeData = createDataTree(Object.values(data));
    setTree(treeData[0]);
    setFlatTree(data);
    setDefaultExpanded(Array.from(expanded));
    setExpanded(Array.from(expanded));
  }, [customerId, availablePolices, customerGroups, customerUnits]);

  useEffect(() => {
    if (!flatTree || _.isEmpty(flatTree)) {
      return;
    }
    if (!searchTerm) {
      setExpanded([...defaultExpanded]);
      const treeData = createDataTree(Object.values(flatTree));
      setTree(treeData[0]);
      return;
    }

    const filtered: any = Object.values(flatTree)?.reduce((data: any, item: any) => {
      if (!item.removeIfEmpty && item?.name?.indexOf(searchTerm?.toUpperCase()) > -1 && !data[item.id]) {
        data[item.id] = item;
        for (let p of item?.parents) {
          if (!data[p]) {
            data[p] = flatTree[p];
          }
        }
      }

      return data;
    }, {});

    setExpanded(Object.keys(filtered));
    const treeData = createDataTree(Object.values(filtered));
    setTree(treeData?.length ? treeData[0] : []);

  }, [searchTerm]);

  const updateRole = (event: any, entityId: any) => {
    event.preventDefault();
    event.stopPropagation();
    const tempPols = { ...policies };
    if (!event.target?.value) {
      const { [entityId]: temp, ...rest } = tempPols;
      setPolicies({ ...rest });
      return;
    }

    if (!!tempPols[entityId]) {
      const entityPolicies = { ...tempPols[entityId] };
      for (let app of Object.keys(entityPolicies)) {
        const policy = entityPolicies[app];
        policy.role = event.target.value;
      }
      tempPols[entityId] = entityPolicies;
      setPolicies(tempPols);
      return;
    }

    const roleObj = roles[event.target.value];

    const tempPolsEntities = Object.keys(tempPols);
    const toDelEnts = tempPolsEntities.filter((tempEntityId: any) => {
      const entityParents = flatTree[tempEntityId]?.parents;
      const isChild = entityParents?.indexOf(entityId) > -1;
      return isChild;
    });

    const filteredPols = tempPolsEntities.reduce((data: any, id: any) => {
      if (toDelEnts.indexOf(id) > -1) {
        return data;
      }
      data[id] = tempPols[id];
      return data;
    }, {});

    filteredPols[entityId] = {
      control: {
        role: event.target.value,
        level: roleObj.level,
        entityId,
        application: "control"
      }
    };

    setPolicies(filteredPols);
  };

  const toggleProPolicy = (event: any, entityId: any) => {
    event.preventDefault();
    event.stopPropagation();
    const tempPols = { ...policies };
    if (!!policies[entityId]?.professional) {
      const { professional: remove, ...rest } = policies[entityId];
      tempPols[entityId] = { ...rest };
      setPolicies({ ...tempPols });
      return;
    }

    tempPols[entityId] = {
      control: tempPols[entityId].control,
      professional: { ...tempPols[entityId].control, application: "professional" }
    };
    setPolicies({ ...tempPols });
  };

  const classes: any = styles();

  const renderTree = (nodes: any) => {
    if (nodes?.removeIfEmpty && !nodes.children?.length) {
      return <></>;
    }
    const entities = Object.keys(policies);
    const isDisabled = !!entities?.length && nodes?.parents?.filter((p: any) => entities?.indexOf(p) > -1)?.length;
    const nodeId = nodes.id || `${Math.round(Math.random() * 1000000)}`;
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodeId}
        classes={{
          root: classes.treeItemRoot,
          label: classes.treeItemLabel,
          group: classes.treeItemGroup,
          content: classes[`contentLv${nodes.level}`]
        }}
        style={{
          borderRadius: "4px"
        }}
        label={
          <div className={classes.itemRow}>
            <Typography className={classes.itemRowUnitName}>{nodes.name}</Typography>
            <div className={classes.rowControls}>
              {nodes.hasPermissions &&
                <Select
                  className={classes.selectRoot}
                  classes={{
                    selectMenu: classes.selectMenu
                  }}

                  value={policies[nodes.id]?.control?.role || ""}
                  onChange={(e) => updateRole(e, nodes.id)}
                  displayEmpty
                  variant="filled"
                  disabled={isDisabled || props.disabled}
                >
                  <MenuItem value={""}>{t`Limited`}</MenuItem>
                  {
                    (Object.keys(availablePolices[nodes.id]?.reduce((acc: any, p: any) => { acc[p.role] = true; return acc; }, {}) || {})).map((role: any) => {
                      return roles[role]?.showInProfessional && <MenuItem value={role} key={`${nodes.id}-${role}`}>{roles[role]?.name}</MenuItem>;
                    })
                  }

                </Select>
              }
              {!nodes.controlOnly &&
                <FormControlLabel
                  style={{
                    margin: "0 5px"
                  }}
                  disabled={!policies[nodes.id]?.control || isDisabled}
                  classes={{
                    root: classes.chkboxRoot
                  }}
                  onClick={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      checked={!!policies[nodes.id]?.professional?.role?.length}
                      onClick={(e: any) => toggleProPolicy(e, nodes.id)}
                      name="checkedB"
                      color={"primary"}
                      classes={{
                        root: classes.chkboxRoot,
                        checked: classes.chkboxRoot,
                        disabled: classes.diabledChkbox
                      }}
                    />
                  }
                  label={t`Pro`}
                />}

            </div>

          </div>
        }
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node: any) => renderTree(node)) : null}
      </TreeItem>
    );
  };

  return (
    <div className={classes.permissionsBox}>
      <div className={classes.header}>
        <TextField
          fullWidth
          placeholder={t`Search Entities`}
          value={searchTerm}
          onChange={(e: any) => setSearchTerm(e.target.value)}
          InputProps={{
            disableUnderline: true,
            classes: { root: classes.inputRoot },
            endAdornment:
              !searchTerm ? (<Search className={classes.searchIcon} />) : (
                <IconButton
                  onClick={() => setSearchTerm("")}
                  className={classes.closeIconStyle}
                >
                  <Close className={classes.closeIcon} />
                </IconButton>
              )
          }}
        />

      </div>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMore />}
        expanded={expanded}
        defaultExpandIcon={<ChevronRight />}
        onNodeToggle={(e: any, ids: any) => {
          e.preventDefault();
          e.stopPropagation();
          setExpanded(ids);
        }}
      >
        {renderTree(tree)}
      </TreeView>
    </div>
  );
};

export default AddEditPolicies;
