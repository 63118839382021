import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Check, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { TopBar } from "../../components";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  AddIcon,
  Alert,
  Delete,
  DisconnectIcon,
  Edit,
  OverflowMenu,
  ScheduleOffIcon,
  SmallPlus,
  SystemOff,
  SystemOn
} from "../../svgComponents";

import Button from "../../cool_widgets/Button";
import {
  CoolModeOff as SvgCoolModeOff,
  CoolModeOn as SvgCoolModeOn,
  HeatModeOff as SvgHeatModeOff,
  HeatModeOn as SvgHeatModeOn,
  MobileLogo,
  Refresh as SvgRefresh,
} from "../../icons/";

// import rotateStyles from "./DeviceCard.module.css";
import * as queryString from "query-string";
import { isIOS } from "react-device-detect";
import { useLocation } from "react-router";
import rotateStyles from "../../screens/SiteManagement/DeviceCard.module.css";
import useStyles, { paperStyle } from "./MobileSiteManagement.style";
interface ISelectedItemToDeleteType {
  itemName: string;
  itemType: string;
  itemId: string;
}

const MobileSiteManagement = () => {
  const classes: any = useStyles();
  const history = useHistory();

  const [refreshData, toggleRefreshData] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [openSite, setOpenSite] = useState<boolean>(false);
  const [selectedSystem, setSelectedSystem] = useState<any>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [anchorElDevice, setAnchorElDevice] = React.useState<null | HTMLElement>(null);
  const [anchorElSite, setAnchorElSite] = React.useState<null | HTMLElement>(null);
  const [anchorElSystem, setAnchorElSystem] = React.useState<null | HTMLElement>(null);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<ISelectedItemToDeleteType>({ itemName: "", itemType: "", itemId: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [openSiteDisconnectedUnits, setOpenSiteDisconnectedUnits] = useState<boolean>(false);
  const [complexToDeleteItem, setComplexToDeleteItem] = useState<{ type: string, data: any } | null>(null);
  const [complexToDeleteItemName, setComplexToDeleteItemName] = useState<string>("");
  const [siteMgmtData, setSiteMgmtData] = useState<any>({});

  const name = complexToDeleteItem?.type === "site" ? complexToDeleteItem?.data?.name : complexToDeleteItem?.data?.serial || "";
  const trimmedName = name.replace(/\s+/g, " ").trim();
  const trimmedText = complexToDeleteItemName.replace(/\s+/g, " ").trim();
  const isNamesMatching = trimmedName === trimmedText;
  const getDevicesTypesNames = useStoreState((state) => state.getDevicesTypesNames);

  const types = useStoreState((state) => state.types);
  const operationStatusesMirror = useStoreState((state) => state.operationStatusesMirror);
  const { operationModesMirror } = useStoreState((state) => state);
  const allSystems = useStoreState((s) => s.systems.allSystems);

  const systemTogglePower = useStoreActions((action) => action.systems.systemTogglePower);
  const startLoader = useStoreActions((actions) => actions.loader.startLoader);
  const finishLoader = useStoreActions((actions) => actions.loader.finishLoader);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const deleteDevice = useStoreActions((action) => action.deleteDevice);
  const changeSystemOperationMode = useStoreActions((action) => action.systems.changeSystemOperationMode);
  const deleteSite = useStoreActions((action) => action.deleteSite);
  const deleteSystem = useStoreActions((action) => action.deleteSystem);
  const refreshDevice = useStoreActions((action) => action.refreshDevice);
  const acknowledgeSystems = useStoreActions((action) => action.systems.acknowledgeSystems);
  const getSiteMgmtData = useStoreActions((a) => a.sites.getSiteMgmtData);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const { customerId } = useStoreState((s) => s.selections.mobileSelections);
  const openDeviceMenu = Boolean(anchorElDevice);
  const openSystemMenu = Boolean(anchorElSystem);
  const { hvacBrands = [] } = types;
  const customer = getCustomer(customerId)
  const { canCreateSites } = customer?.permissions || {}
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { selectedSite: prevSelectedSite } = params;

  useEffect(() => {
    if (prevSelectedSite) {
      setSelectedSite(prevSelectedSite as string);
      setOpenSite(true);
    }
    fetchData();
  }, []);

  const fetchData = () => {

    startLoader();
    if (!customerId) {
      return;
    }
    return getSiteMgmtData(customerId)
      .then((resp: any) => {
        setSiteMgmtData(resp);
      })
      .finally(() => {
        finishLoader();
      });

  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const handleCollapse = (siteId: string) => {
    if (selectedSite === siteId && openSite === true) {
      setOpenSite(false);
      setSelectedSite(siteId);
      setOpenSiteDisconnectedUnits(false);
      return;
    }
    if (selectedSite === siteId && openSite === false) {
      setOpenSite(true);
      return;
    }

    setOpenSite(true);
    setOpenSiteDisconnectedUnits(false);
    setSelectedSite(siteId);
  };

  const deleteSelectedDevice = (deviceId: string) => {
    handleClose();
    startLoader();

    deleteDevice({ id: deviceId })
      .then(() => {
        closeComplexDeleteDialog();
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        fetchData();
        finishLoader();
      });
  };

  const changeSystemMode = (systemId: string, state: number) => {

    changeSystemOperationMode({ id: systemId, data: +state })
      .then(() => {
        fetchData();
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      );
  };

  const siteMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    siteId: string
  ) => {
    setSelectedSite(siteId);
    setAnchorElSite(event.currentTarget);
  };

  const deviceMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    deviceId: string
  ) => {
    if (selectedDevice !== deviceId) {
      setSelectedDevice(deviceId);
    }
    setAnchorElDevice(event.currentTarget);
  };

  const systemMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    systemId: string
  ) => {
    if (selectedSystem !== systemId) {
      setSelectedSystem(systemId);
    }
    setAnchorElSystem(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElDevice(null);
    setAnchorElSite(null);
    setAnchorElSystem(null);
  };

  const deleteItem = (type: string, idObj: any) => {
    if (type === "site") {
      removeSite(idObj.id);
    } else {
      deleteSelectedDevice(idObj.id);
    }
  };

  const closeComplexDeleteDialog = () => {
    setComplexToDeleteItem(null);
    setComplexToDeleteItemName("");
  };
  const moveToSiteInfoPage = (siteId: string) => {
    history.push(`/site/${siteId}`);
  };

  const addNewDevice = (siteId: string) => {
    history.push(`/device-registration/${siteId}/user-selection/${customerId}`);
  };
  const addNewSystem = (deviceId: string) => {
    history.push(`/site-management/${deviceId}/add-edit-system/`);
  };

  const editSystem = (deviceId: string, systemId: string) => {
    handleClose();
    history.push(`/site-management/${deviceId}/add-edit-system/${systemId}`);
  };
  const removeSystem = (systemId: string) => {
    handleClose();

    startLoader();
    toggleRefreshData(!refreshData);
    deleteSystem({ id: systemId })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => {
        fetchData();
        finishLoader();
      });
  };
  const removeSite = (siteId: string) => {
    startLoader();
    deleteSite({ id: siteId })
      .then(() => {
        closeComplexDeleteDialog();
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => {
        finishLoader();
        fetchData();
      });
  };
  const changeDeleteDialogState = (...theArgs: any) => {
    handleClose();
    setDeleteDialog(theArgs[0]);
    setSelectedItemToDelete(theArgs[1]);
  };

  const removeItemSelector = () => {
    changeDeleteDialogState(false);
    const { itemType, itemId } = selectedItemToDelete;

    if (itemType === "system") {
      removeSystem(itemId);
      return;
    } else if (itemType === "unit") {
      // deleteSelectedUnit(itemId);
      return;
    }
  };

  const navigateItemToSchedules = (itemId: string) => {
    history.push(`/system/${itemId}/schedules`);
  };

  const goToAddSitePAge = () => {
    history.push(`/site-management/customer/${customerId}/add-site`);
  };

  const handleRefreshDevice = async (id: string) => {
    setIsLoading(true);

    refreshDevice({ id })
      .then(() => {
        toggleRefreshData(!refreshData);
        handleClose();
      })
      .finally(() => setIsLoading(false));

  };

  const handleAcknowledgeSystems = (systems: any) => {
    acknowledgeSystems({ systems })
      .then(() => {
        fetchData();
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const changeSystemStatus = (systemId: string, currentStatus: number) => {
    let val: any;

    if (+currentStatus === +operationStatusesMirror.on) {
      val = operationStatusesMirror.off;
    } else {
      val = operationStatusesMirror.on;
    }
    systemTogglePower({ id: systemId, data: +val })
      .then(() => {
        fetchData();
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  return (
    <>
      <TopBar leftAction={() => history.push("/dashboard")}
        screenTitle="siteMgmt"
        leftIconComponent={<MobileLogo />}
        hideUnitsFilter
        hideSitesFilter
        // title={t`Site Management `}
        rightIconComponent={<AddIcon />}
        hideRightComponent={!customerId || !canCreateSites}
        rightAction={customerId ? goToAddSitePAge : undefined}
      />
      <div className={classes.sitesContainer}>
        <div className={clsx(classes.listStyle, { [classes.IOSListStyle]: isIOS })}>
          {Object.values(siteMgmtData).map((site: any, i: number) => {

            let {
              id: siteId,
              name,
              address,
              city,
              state = "",
              country,
              postalCode = "",
              devices: siteDevices,
              permissions = {},
              canAddDevice = true
            } = site;
            const { canDelete } = permissions;
            let sitesystems: any = [];
            let siteIndoorUnits: any = 0;
            let siteDisconnectedIndoorunits: any = 0;

            siteDevices.reduce((obj: any, data: any) => {
              siteIndoorUnits = +siteIndoorUnits + +data.numOfControlUnits;
              siteDisconnectedIndoorunits = +siteDisconnectedIndoorunits + +data.numOfDisconnectedOutdoorUnits;
              sitesystems.push(...data.systems);
            }, []);

            if (site.country !== "US") {
              state = "";
            }

            return (
              <div
                key={`site-item-${siteId}`}
                className={classes.listItemStyle}
              >
                <div className={classes.siteNameContainer}>
                  <Typography className={classes.listItemTextStyle}>{name}</Typography>
                  <div className={classes.iconHolderStyle}>
                    {(selectedSite !== siteId || !openSite) ?
                      <div style={{ width: "16px" }}></div> :
                      <>
                        <IconButton color="primary"
                          id={`site-overflow-menu-${siteId}`}
                          component="span"
                          disableRipple
                          onClick={(e: any) => siteMenuClick(e, siteId)}
                        >
                          <OverflowMenu className={classes.overflowIcon} />
                        </IconButton>
                        <Menu
                          id="site-menu"
                          anchorEl={anchorElSite}
                          keepMounted
                          open={selectedSite === siteId && !_.isNull(anchorElSite)}
                          onClose={handleClose}
                          className={classes.menuStyle}
                          PaperProps={{
                            style: paperStyle
                          }}
                        >
                          <MenuItem
                            disabled={!canAddDevice}
                            onClick={() => addNewDevice(siteId)}
                            className={classes.menuItemStyle}
                          >
                            <SmallPlus
                              id={`add-device-${siteId}`}
                              className={classes.plusIconStyle}
                            />
                            {t`Add Device`}
                          </MenuItem>
                          <MenuItem
                            onClick={() => moveToSiteInfoPage(siteId)}
                            className={classes.menuItemStyle}
                          >
                            <Edit
                              id={`site-${siteId}`}
                              className={classes.editIcon}
                            />
                            {t`Edit Site`}
                          </MenuItem>
                          <MenuItem
                            disabled={!canDelete}
                            key={`site-menu-delete-${siteId}`}
                            onClick={() => setComplexToDeleteItem({ type: "site", data: { ...site, numOfControlUnits: siteIndoorUnits, numOfDevices: siteDevices?.length, numOfSystems: sitesystems?.length } })}
                            className={classes.menuItemStyle}
                          >
                            <Delete
                              id={`delete-site-${siteId}`}
                              className={classes.deleteIconStyle}
                            />
                            {t`Delete Site`}
                          </MenuItem>
                        </Menu>
                      </>}
                    <IconButton
                      id={`site-button-arrow-down-${siteId}`}
                      onClick={() => handleCollapse(siteId)}
                      className={
                        selectedSite === siteId && openSite
                          ? classes.expandOpen
                          : classes.expand
                      }
                    >
                      <ExpandMore id={`site-overflow-menu-${siteId}`} />
                    </IconButton>
                  </div>
                </div>
                <Collapse
                  in={selectedSite === siteId && openSite}
                  timeout="auto"
                  unmountOnExit
                  className={classes.collapseStyle}
                >
                  <div className={classes.siteFirstRow}>
                    <Typography
                      className={classes.typoStyle}
                      display="inline"
                    >
                      {`${address}, ${city}, ${state}${state &&
                        ", "}${country}${postalCode && ", "}${postalCode}`}
                    </Typography>
                  </div>

                  <div className={classes.blockContainer}>
                    <div className={classes.siteFontStyle}>
                      <Typography className={classes.deviceTypoStyle}>
                        {t`Total indoor units on site: `}{siteIndoorUnits}
                      </Typography>
                    </div>
                    {siteDisconnectedIndoorunits !== 0 && (
                      <div>
                        <div className={clsx(classes.siteFontStyle, classes.disconnectedUnits)}>
                          <Typography className={classes.deviceTypoStyle}>
                            <span className={classes.redText}>
                              {siteDisconnectedIndoorunits.length} {t` Disconnected Units`}
                              <Alert width="16" height="16" className={classes.alertIcon} />
                            </span>
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* device card */}
                  {siteDevices?.map((currentDevice: any) => {
                    let devicePendingAckSystems: any = [];
                    const {
                      id: deviceId,
                      serial: name,
                      systems: deviceSystems,
                      isConnected,
                      numOfControlUnits,
                      numOfDisconnectedControlUnits,
                      // unassignedControlUnits,
                      deviceType = 1,
                      type: deviceBrand = 0,
                      permissions = {}
                    } = currentDevice;

                    const { canCreateSystems, canDelete, canRefresh } = permissions;
                    const deviceSystemsCount = deviceSystems.length;
                    // const numOfUnassignedControlUnits = unassignedControlUnits.length;
                    const deviceTypeName = getDevicesTypesNames(deviceType, deviceBrand);

                    return (
                      <div
                        key={`device-item-${siteId}-${deviceId}`}
                        className={classes.blockContainer}
                      >
                        <div className={classes.deviceInfoFontStyle}>
                          <Typography className={classes.deviceTypoStyle}>
                            {t`${deviceTypeName} `} {name}
                          </Typography>
                          {!isConnected && (
                            <DisconnectIcon />
                          )}

                          <Grid>
                            <IconButton color="primary" aria-label="upload picture" component="span" id="device-overflow-menu"
                              className={classes.menuIconStyle}
                              onClick={(e: any) =>
                                deviceMenuClick(e, deviceId)
                              }
                              disableTouchRipple={true}>
                              <OverflowMenu className={classes.overflowIcon} />
                            </IconButton>
                            <Menu
                              id="device-menu"
                              anchorEl={anchorElDevice}
                              keepMounted
                              open={
                                openDeviceMenu &&
                                selectedDevice === deviceId
                              }
                              onClose={handleClose}
                              PaperProps={{
                                style: paperStyle
                              }}
                              className={classes.menuStyle}
                            >
                              <MenuItem
                                disabled={!canCreateSystems}
                                onClick={() => addNewSystem(deviceId)}
                                className={classes.menuItemStyle}
                              >
                                <SmallPlus
                                  id={`system-${deviceId}`}
                                  className={classes.plusIconStyle}
                                />
                                {t`Add System`}
                              </MenuItem>
                              <MenuItem
                                disabled={!isConnected || !canRefresh}
                                onClick={() => handleRefreshDevice(deviceId)}
                                className={classes.menuItemStyle}
                              >
                                <SvgRefresh
                                  className={isLoading ? rotateStyles.refreshStyleLoading : rotateStyles.refreshStyle}
                                  style={{ marginRight: "10px" }}
                                />
                                {t`Refresh Device`}
                              </MenuItem>
                              <MenuItem
                                disabled={!canDelete}
                                onClick={() => setComplexToDeleteItem({ type: "device", data: { ...currentDevice, numOfControlUnits, numOfSystems: deviceSystemsCount } })}
                                className={classes.menuItemStyle}
                              >
                                <Delete
                                  id={`delete-device-${deviceId}`}
                                  className={classes.deleteIconStyle}
                                />
                                {t`Delete`}
                              </MenuItem>
                            </Menu>
                          </Grid>
                        </div>
                        <div className={classes.deviceInfoFontStyle}>
                          <Typography className={classes.deviceTypoStyle}>
                            {numOfControlUnits} {t`Indoor units`}
                          </Typography>
                        </div>
                        {numOfDisconnectedControlUnits !== 0 && (
                          <div className={classes.deviceInfoFontStyle}>
                            <Typography className={classes.deviceTypoStyle}>
                              <span className={classes.redText}>
                                {numOfDisconnectedControlUnits} {t` Disconnected Units`}
                                <Alert width="16" height="16" className={classes.alertIcon} />
                              </span>
                            </Typography>
                          </div>
                        )}

                        {/* {numOfUnassignedControlUnits !== 0 && (
                          <div
                            className={clsx(
                              classes.warningContainerStyle,
                              classes.removeHeight
                            )}
                          >
                            <Typography className={classes.notMappedMessage}>
                              {t`Note:` + " " + numOfUnassignedControlUnits + " " + t`Units not mapped to refrigerant system/s (required for changing systems mode)`}
                            </Typography>
                          </div>
                        )} */}

                        {/*system card*/}
                        <div className={classes.systemsContainer}>
                          {deviceSystems &&
                            deviceSystems.map((currentSystem: any) => {

                              const {
                                name,
                                brandNum,
                                id: systemId,
                                schedules,
                                pendingAck,
                                numOfControlUnits,
                                hasDisconnectedUnits,
                                permissions = {}
                              } = currentSystem;

                              const { canControl, canDelete, canUpdate } = permissions;
                              if (!allSystems[systemId]) {
                                return;
                              }
                              const { operationMode, operationStatus } = allSystems[systemId];

                              const isPowerOn = +operationStatus === +operationStatusesMirror.on;
                              const isSystemInHeatMode = +operationMode === +operationModesMirror.HEAT;
                              const isSystemInCoolMode = +operationMode === +operationModesMirror.COOL;

                              const isWaterHeater = _.find(hvacBrands, { value: brandNum })
                                ? _.find(hvacBrands, { value: brandNum }).isWaterHeater
                                : false;
                              if (pendingAck) {
                                devicePendingAckSystems.push(systemId);
                                return;
                              }

                              const hasSchedules = schedules?.length > 0;

                              return (
                                <div
                                  key={`system-item-${deviceId}-${systemId}`}
                                  className={classes.systemCard}
                                >
                                  <Grid
                                    container className={classes.systemInfoContainer}
                                  >
                                    <div className={classes.systemNameContainer}
                                    >
                                      <Typography className={classes.systemNameStyle}>
                                        {name}
                                      </Typography>
                                      {hasDisconnectedUnits && (
                                        <DisconnectIcon className={classes.disconnectedIconStyle} />
                                      )}
                                    </div>
                                    <div
                                      id={`system-menu-container-${systemId}`}
                                      className={classes.systemMenuContainer}
                                    >
                                      <IconButton color="primary" aria-label="upload picture" component="span" onClick={(e: any) =>
                                        systemMenuClick(e, systemId)
                                      }
                                        className={classes.systemMenuIconStyle}
                                        disableTouchRipple={true}>
                                        <OverflowMenu className={classes.overflowIcon} />
                                      </IconButton>

                                      <Menu
                                        id={`menu-system-${selectedSystem}`}
                                        key={`menu-${systemId}`}
                                        anchorEl={anchorElSystem}
                                        keepMounted
                                        open={
                                          openSystemMenu &&
                                          systemId === selectedSystem
                                        }
                                        onClose={handleClose}
                                        PaperProps={{
                                          style: paperStyle
                                        }}
                                      >
                                        <MenuItem
                                          disabled={!canUpdate}
                                          onClick={() => editSystem(deviceId, systemId)}
                                          className={classes.menuItemStyle}
                                        >
                                          <Edit
                                            id={`system-${systemId}`}
                                            className={classes.editIcon}
                                          />
                                          {t`Edit System`}
                                        </MenuItem>
                                        <MenuItem
                                          disabled={!canDelete}
                                          onClick={() =>
                                            changeDeleteDialogState(
                                              true,
                                              {
                                                itemName: name,
                                                itemType: "system",
                                                itemId: systemId
                                              }
                                            )
                                          }
                                          className={classes.menuItemStyle}
                                        >
                                          <Delete
                                            id={`delete-system-${systemId}`}
                                            className={classes.deleteIconStyle}
                                          />
                                          {t`Delete System`}
                                        </MenuItem>
                                      </Menu>
                                    </div>
                                  </Grid>
                                  <Typography
                                    className={classes.systemTypoStyle}
                                  >
                                    {numOfControlUnits} {t`Indoor`}
                                  </Typography>
                                  <div
                                    className={classes.systemControlsContainer}
                                  >
                                    <div
                                      className={classes.systemControlSecondRow}
                                    >
                                      <Grid item>
                                        {isSystemInCoolMode ? (
                                          <SvgCoolModeOn onClick={() => !canControl ? {} : changeSystemMode(systemId, operationModesMirror.COOL)} className={clsx(classes.modeButton)} />
                                        ) : (
                                          <SvgCoolModeOff
                                            onClick={() => !canControl ? {} : changeSystemMode(systemId, operationModesMirror.COOL)} className={clsx(classes.modeButton)} />
                                        )}
                                        {isSystemInHeatMode ? (
                                          <SvgHeatModeOn onClick={() => !canControl ? {} : changeSystemMode(systemId, operationModesMirror.HEAT)} className={clsx(classes.modeButton)} />
                                        ) : (
                                          <SvgHeatModeOff onClick={() => !canControl ? {} : changeSystemMode(systemId, operationModesMirror.HEAT)} className={clsx(classes.modeButton)} />
                                        )}
                                      </Grid>
                                      {!isWaterHeater && <div className={classes.iconContainer}>
                                        <IconButton
                                          disableFocusRipple
                                          disableRipple
                                          className={classes.scheduleIconBtn}
                                          name="schedule"
                                          onClick={() => navigateItemToSchedules(systemId)}
                                        >
                                          <ScheduleOffIcon color="#AAA2AA" className={classes.scheduleIcon} />
                                          {hasSchedules && <span className={classes.badge}>{schedules.length}</span>}
                                        </IconButton>
                                      </div>}
                                      <div className={classes.iconContainer}>
                                        <IconButton
                                          disableFocusRipple
                                          disableRipple
                                          className={classes.mainIconsStyle}
                                          name="power"
                                          onClick={() => changeSystemStatus(systemId, operationStatus)}
                                          disabled={!canControl}
                                        >
                                          {isPowerOn ? <SystemOn
                                            className={classes.systemPowerButton}
                                          /> :
                                            <SystemOff
                                              className={classes.systemPowerButton}
                                            />}
                                        </IconButton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            )}

                          {devicePendingAckSystems && devicePendingAckSystems.length > 0 && deviceSystems &&
                            <>
                              <div className={classes.pendingSystems}>
                                <div className={classes.pendingAckText}> {devicePendingAckSystems.length + t` New Systems Detected and Added`} </div>
                                {deviceSystems.map((currentSystem: any) => {

                                  const {
                                    name,
                                    brandNum,
                                    id: systemId,
                                    pendingAck,
                                    operationStatus,
                                    operationMode,
                                    numOfControlUnits,
                                    hasDisconnectedUnits
                                  } = currentSystem;

                                  if (!pendingAck) {
                                    return;
                                  }

                                  const isPowerOn = +operationStatus === +operationStatusesMirror.on;
                                  const isSystemInHeatMode = +operationMode === +operationModesMirror.HEAT;
                                  const isSystemInCoolMode = +operationMode === +operationModesMirror.COOL;

                                  const isWaterHeater = _.find(hvacBrands, { value: brandNum })
                                    ? _.find(hvacBrands, { value: brandNum }).isWaterHeater
                                    : false;

                                  return (
                                    <div
                                      key={`system-item-${deviceId}-${systemId}`}
                                      className={clsx(classes.systemCard, classes.pendingAck)}
                                    >
                                      <Grid container className={classes.systemInfoContainer}>
                                        <div className={classes.systemNameContainer} >
                                          <Typography className={classes.systemNameStyle} >
                                            {name}
                                          </Typography>
                                          {hasDisconnectedUnits && (
                                            <DisconnectIcon className={classes.disconnectedIconStyle} />
                                          )}
                                        </div>
                                      </Grid>
                                      <Typography className={classes.systemTypoStyle}>
                                        {numOfControlUnits} {t`Indoor`}
                                      </Typography>
                                      <div className={classes.systemControlsContainer}>
                                        <div className={classes.systemControlSecondRow}>
                                          <Grid item>
                                            {isSystemInCoolMode ? (
                                              <SvgCoolModeOn onClick={() => changeSystemMode(systemId, operationModesMirror.COOL)} className={clsx(classes.modeButton)} />
                                            ) : (
                                              <SvgCoolModeOff
                                                onClick={() => changeSystemMode(systemId, operationModesMirror.COOL)} className={clsx(classes.modeButton)} />
                                            )}
                                            {isSystemInHeatMode ? (
                                              <SvgHeatModeOn onClick={() => changeSystemMode(systemId, operationModesMirror.HEAT)} className={clsx(classes.modeButton)} />
                                            ) : (
                                              <SvgHeatModeOff onClick={() => changeSystemMode(systemId, operationModesMirror.HEAT)} className={clsx(classes.modeButton)} />
                                            )}
                                          </Grid>
                                          {!isWaterHeater && <div className={classes.iconContainer}>
                                            <IconButton
                                              disabled={true}
                                              disableFocusRipple
                                              disableRipple
                                              className={classes.mainIconsStyle}
                                              name="power"
                                            >
                                              {isPowerOn ? <SystemOn
                                                onClick={() => changeSystemStatus(systemId, operationStatus)}
                                                className={
                                                  classes.systemPowerButton
                                                }
                                              /> :
                                                <SystemOff
                                                  onClick={() => changeSystemStatus(systemId, operationStatus)}
                                                  className={
                                                    classes.systemPowerButton
                                                  }
                                                />}
                                            </IconButton>
                                          </div>}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <Button
                                variant="contained"
                                className={classes.ackButton}
                                bold
                                red
                                onClick={() => { handleAcknowledgeSystems(devicePendingAckSystems); }}
                              >
                                <Check className={classes.ackButtonIcon} />
                                <Typography className={classes.ackButtonText}>{t`Acknowledge`}</Typography>
                              </Button>
                            </>
                          }
                        </div>
                      </div>
                    );
                  }
                  )}
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>
      <ConfirmationDialog
        onCancel={() => changeDeleteDialogState(false)}
        onConfirm={() => removeItemSelector()}
        title={t`Delete ` + selectedItemToDelete?.itemType}
        text={t`Are you sure you want to delete ` + selectedItemToDelete?.itemName + ` ?`}
        openDialog={deleteDialog}
        cancelLabel={t`No`}
        confirmLabel={t`Delete`}
        onClose={() => changeDeleteDialogState(false)}
        dialogType="error"
      />

      {!!complexToDeleteItem &&
        <Dialog
          open={!!complexToDeleteItem}
          onClose={closeComplexDeleteDialog}
          aria-labelledby="responsive-dialog-title"
          classes={{ paper: classes.dialogStyle }}
        >
          <DialogTitle
            disableTypography
            id="responsive-dialog-title"
            className={classes.dialogTitle}
          >
            {t`Delete ` + complexToDeleteItem?.type}
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContentStyle }}>
            <DialogContentText
              className={clsx(classes.contentStyle, classes.boldText)}
            >
              {t`You are trying to delete ` + complexToDeleteItem?.type}
              <span className={classes.redItemName}>{complexToDeleteItem?.type === "site" ? ` ` + complexToDeleteItem.data.name : ` ` + complexToDeleteItem.data.serial}</span>
            </DialogContentText>
            {(!!complexToDeleteItem.data.numOfDevices || !!complexToDeleteItem.data.numOfSystems || !!complexToDeleteItem.data.numOfControlUnits) &&
              <div className={classes.deleteInfoBlock}>
                <Typography className={classes.DialogContentText}>{t`Note this ` + complexToDeleteItem.type + ` includes:`}</Typography>
                {complexToDeleteItem?.type === "site" && !!complexToDeleteItem.data.numOfDevices && <Typography className={classes.DialogContentText}>
                  <Typography className={classes.innerInfoCounter}>{complexToDeleteItem.data.numOfDevices}</Typography>
                  {t` devices`}
                </Typography>}
                {!!complexToDeleteItem.data.numOfSystems && <Typography className={classes.DialogContentText}>
                  <Typography className={classes.innerInfoCounter}>{complexToDeleteItem.data.numOfSystems}</Typography>
                  {t` systems`}
                </Typography>}
                {!!complexToDeleteItem.data.numOfControlUnits && <Typography className={classes.DialogContentText}>
                  <Typography className={classes.innerInfoCounter}>{complexToDeleteItem.data.numOfControlUnits}</Typography>
                  {t` units`}
                </Typography>}
              </div>}
            <div className={classes.deleteInfoBlock}>
              <Typography className={clsx(classes.DialogContentText, classes.boldText)}>{t`To proceed, please type precisely the ` + complexToDeleteItem.type + (complexToDeleteItem.type === "device" ? ` serial number in the box below, and click "Delete"` : ` name in the box below, and click "Delete"`)}</Typography>
              <TextField
                variant={"outlined"}
                label={complexToDeleteItem?.type === "site" ? "Enter Site Name" : " Enter Device Serial"}
                placeholder={complexToDeleteItem?.type === "site" ? "Site Name" : "Device Serial"}
                value={complexToDeleteItemName}
                className={classes.deleteNameInput}
                InputProps={{ classes: { root: classes.inputStyle } }}
                onChange={(e: any) => setComplexToDeleteItemName(e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions
            className={classes.dialogActionStyle}
            classes={{ spacing: classes.actionsSpacing }}
          >
            <Button
              onClick={closeComplexDeleteDialog}
              className={classes.cancelButtonStyle}
              variant="contained"
              width={100}
            >
              {t`Cancel`}
            </Button>

            <Button
              onClick={() => deleteItem(complexToDeleteItem?.type, { "id": complexToDeleteItem.data.id })}
              disabled={!isNamesMatching}
              white={!isNamesMatching}
              red={isNamesMatching}
              width={100}
              variant="outlined"
            // className={classes.confirmButtonStyle}
            >
              {t`Delete`}
            </Button>
          </DialogActions>
        </Dialog>}
    </>
  );
};

export default MobileSiteManagement;
