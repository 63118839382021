import React, { useState, useEffect, useMemo } from "react";
import {
	Button,
	Grid,
	IconButton,
	LinearProgress,
	Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from "clsx";
import { find, isUndefined, setWith, sortBy, cloneDeep } from "lodash";
import { useStoreActions, useStoreState } from "@models/RootStore";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import {
	CoolModeOff as SvgCoolModeOff,
	CoolModeOn as SvgCoolModeOn,
	Disconnect as SvgDisconnect,
	HeatModeOff as SvgHeatModeOff,
	HeatModeOn as SvgHeatModeOn,
	MoreVert,
	OnGrey as SvgPowerOff,
	OnWhite as SvgPowerOn,
} from "../../icons/";
import { ScheduleOffIcon } from "../../icons/index";
import { EditIcon } from "../../logos";
import assetsService from "../../services/assetsService";
import { systemViewStyle } from "./SystemView.style";
import { IServiceTypes } from "@models/Types";

interface System {
	name: string;
	brandNum: number;
	numOfControlUnits: number;
	numOfOutdoorUnits: number;
	numOfBsBoxUnits: number;
	id: string;
	hasDisconnectedUnits: boolean;
	isMapping?: boolean;
	schedules: Array<any>; // Define a more specific type if possible
	mappingCurrent: number;
	mappingTotal: number;
	permissions: {
		canControl: boolean;
		canDelete: boolean;
		canUpdate: boolean;
		canCreateSchedules: boolean;
	};
	operationMode: string;
	operationStatus: string;
	internalId?: string | number;
}

interface Actions {
	openEditSensors: (deviceId: string) => void;
	openSystemSchedule: (id: string, deviceId: string, system: System) => void;
	fetchData: () => void;
	setOpenMappingInProgress: (isOpen: boolean) => void;
	openAddEditSystem: (deviceId: string, systemId: string) => void;
	changeSystemMode: (systemId: string, mode: string) => void;
	changeSystemStatus: (systemId: string, status: string) => void;
}

interface Device {
	id: string;
	numOfSensors: number;
	permissions?: DevicePermissions;
	unassignedLines: {
		[lineId: string]: {
			outdoor?: number;
			control?: number;
			service?: number;
			bsbox?: number;
		};
	};
}
interface SiteSystemProps {
	system: System;
	actions: Actions;
	device: Device;
	hasOnlyControlUnit: boolean;
}

const SiteSystem: React.FC<SiteSystemProps> = ({
	system,
	actions,
	device,
	hasOnlyControlUnit,
}) => {
	const classes = systemViewStyle();
	const deleteSystem = useStoreActions((action) => action.deleteSystem);
	const types: IServiceTypes = useStoreState((state) => state.types);
	const {
		hvacBrands,
		operationModesExtended: typesOperationModes,
		operationStatuses: typesOperationStatuses,
	} = types;
	const altermaBrandNum =
		hvacBrands?.filter(
			(brand: any) => brand?.type?.toUpperCase() === "ALTHERMA"
		)[0]?.value || 10;

	const {
		overWriteDisplay,
		iconButton,
		powerButton,
		powerButtonDisabled,
		PowerButtonOperationStatusContainer,
		systemName,
		logo,
		groupIcons,
		nameModelContainer,
		powerButtonSVG,
		modeButton,
		powerOff,
		powerOn,
		modeButtonDisabled,
		indoorOutdoorContainer,
		outdoor,
		indoor,
		controlContainer,
		SiteSystem,
	} = classes;

	const {
		name,
		brandNum,
		numOfControlUnits,
		numOfOutdoorUnits,
		numOfBsBoxUnits,
		id,
		hasDisconnectedUnits,
		isMapping = false, //system or line differs?
		schedules = [],
		mappingCurrent,
		mappingTotal,
		permissions,
		operationMode,
		operationStatus,
    internalId
	} = system;

	const { canControl, canDelete, canUpdate, canCreateSchedules } = permissions;

	const isWaterHeater = find(hvacBrands, { value: brandNum })
		? find(hvacBrands, { value: brandNum }).isWaterHeater
		: false;
	const isPowerOn = typesOperationStatuses[operationStatus] === "on";
	const isSystemInHeatMode = typesOperationModes[operationMode] === "HEAT";
	const isSystemInCoolMode = typesOperationModes[operationMode] === "COOL";
	const systemHasControlUnits = !!numOfControlUnits;
	const isAlterma = +brandNum === +altermaBrandNum;
	const hasSchedules = schedules.length > 0;
	const { changeSystemMode, changeSystemStatus } = actions;

	const delSystem = (idObj: any) => {
		deleteSystem(idObj).then(() => {
			actions.fetchData();
		});
	};

  const computedInternalId = useMemo(() => {
    //If the system is Mitsubishy Electric (4) convert the internalId to base 10 from base 16.
    if (brandNum === 4 && internalId !== undefined) {
      return parseInt(`${internalId}`, 16);
    }
    return internalId;
  }, [internalId]);

	return (
		<div className={clsx(classes.BaseTile, classes.SiteSystem)}>
			<div className={overWriteDisplay}>
				<Typography className={systemName}>{name}</Typography>
				<div className={groupIcons}>
					<LightTooltip title={t`Edit system information - unit names, mapping control /service addreses, assigining units between systems`}>
						<IconButton
							disableRipple
							disabled={!canUpdate}
							onClick={() =>
								isMapping
									? actions.setOpenMappingInProgress(true)
									: actions.openAddEditSystem(device.id, id)
							}
							className={classes.iconBtnStyle}
						>
							<EditIcon />
						</IconButton>
					</LightTooltip>
					<Delete
						classes={{ root: iconButton }}
						type={t`System`}
						object={system}
						detach={delSystem}
						disabled={isMapping || !canDelete}
					></Delete>
				</div>
			</div>
			<div className={nameModelContainer}>
				<div className={classes.minLogoHeight}>
					{brandNum ? (
						<img
							height={25}
							src={assetsService.getBrandLogo(`${brandNum}-small.svg`)}
							className={logo}
							onError={(event: any) => {
								return (event.target.src =
									assetsService.getBrandLogo("empty.png"));
							}}
							alt={
								find(hvacBrands, { value: brandNum })
									? find(hvacBrands, {
											value: brandNum,
								}).name
									: ""
							}
						/>
					) : (
						""
					)}
				</div>
				<div style={{ textAlign: "right" }}>
					{hasDisconnectedUnits && <SvgDisconnect />}
				</div>
			</div>
			<div className={indoorOutdoorContainer}>
				{
          (!isAlterma && !hasOnlyControlUnit) && (
            <div>
              <Typography className={clsx("", outdoor)} variant="body1">
                <span>
                  {numOfOutdoorUnits}
                  {t` Outdoor`}
                </span>
              </Typography>
            </div>
          )
        }
				<div>
					<Typography className={clsx("", indoor)} variant="body1">
						<span>
							{numOfControlUnits}
							{t` Indoor `}
						</span>
					</Typography>
				</div>
        {
          (computedInternalId !== undefined) && (
            <div className="SysInternalId">
              <Typography className={clsx("", indoor)} variant="body1">
                <span>{t`Sys#:`} {computedInternalId}</span>
              </Typography>
            </div>
          )
        }
			</div>
			{isMapping ? (
				<div className={classes.mappingContainer}>
					<Typography className={classes.mappingText}>
						{t`Mapping`}... ({mappingCurrent}/{mappingTotal})
						<span>
							{mappingCurrent === 0 && mappingTotal === 0
								? 100
								: Math.round((mappingCurrent / mappingTotal) * 100)}
							%
						</span>
					</Typography>
					<Typography className={classes.mappingTime}>
						{(Math.round((mappingTotal - mappingCurrent) / 2) * 100) / 100}{" "}
						{t`min left`}
					</Typography>
					<LinearProgress
						classes={{
							root: classes.progressRoot,
							bar: classes.progressBar,
						}}
						variant="determinate"
						value={
							mappingCurrent === 0 && mappingTotal === 0
								? 100
								: Math.round((mappingCurrent / mappingTotal) * 100)
						}
					/>
				</div>
			) : (
				<div className={PowerButtonOperationStatusContainer}>
					<LightTooltip title={t`Change mode (cool/heat) for the whole system. Mode change triggers a process of turning OFF all units in the system and then changing the mode. Note: units will not start automatically after the mode change to avoid conflicts.`}>
						<div>
							{isSystemInCoolMode ? (
								<SvgCoolModeOn
									onClick={() =>
										!canControl ? {} : changeSystemMode(id, "COOL")
									}
									className={clsx(
										modeButton,
										(!canControl || !systemHasControlUnits) && modeButtonDisabled
									)}
								/>
							) : (
								<SvgCoolModeOff
									onClick={() =>
										!canControl ? {} : changeSystemMode(id, "COOL")
									}
									className={clsx(
										modeButton,
										(!canControl || !systemHasControlUnits) && modeButtonDisabled
									)}
								/>
							)}
							{isSystemInHeatMode ? (
								<SvgHeatModeOn
									onClick={() =>
										!canControl ? {} : changeSystemMode(id, "HEAT")
									}
									className={clsx(
										modeButton,
										(!canControl || !systemHasControlUnits) && modeButtonDisabled
									)}
								/>
							) : (
								<SvgHeatModeOff
									onClick={() =>
										!canControl ? {} : changeSystemMode(id, "HEAT")
									}
									className={clsx(
										modeButton,
										(!canControl || !systemHasControlUnits) && modeButtonDisabled
									)}
								/>
							)}
						</div>
					</LightTooltip>
					{!isWaterHeater && (
						<IconButton
							disableFocusRipple
							disableRipple
							disabled={!canCreateSchedules}
							onClick={() => actions.openSystemSchedule(id, device.id, system)}
							name="schedule"
							classes={{ root: classes.scheduleIconBtn }}
						>
							<ScheduleOffIcon
								color="#AAA2AA"
								className={classes.scheduleIcon}
							/>
							{hasSchedules && (
								<span className={classes.badge}>{schedules.length}</span>
							)}
						</IconButton>
					)}
					<div className={controlContainer}>
						<LightTooltip title={t`Turn ON/OFF all units of the system.`}>
							<IconButton
								disabled={!canControl}
								onClick={() =>
									!canControl ? {} : changeSystemStatus(id, operationStatus)
								}
								className={clsx(
									powerButton,
									(!canControl || !systemHasControlUnits) && powerButtonDisabled
								)}
							>
								{isPowerOn ? (
									<div className={powerOn}>
										<SvgPowerOn className={powerButtonSVG} />
									</div>
								) : (
									<div className={powerOff}>
										<SvgPowerOff className={powerButtonSVG} />
									</div>
								)}
							</IconButton>
						</LightTooltip>
					</div>
				</div>
			)}
		</div>
	);
};

interface Permissions {
	canUpdate: boolean;
}

interface SiteSensorProps {
	actions: Actions;
	device: Device;
}

const SiteSensor: React.FC<SiteSensorProps> = ({ actions, device }) => {
	const classes = systemViewStyle();
	const { systemName, groupIcons, indoorOutdoorContainer, outdoor } = classes;

	return (
		<Grid key={`ppd-details`} className={clsx(classes.BaseTile, classes.SiteSensor)}>
			<div
				className={classes.overWriteDisplayVirtualSystems}
			>
				<Typography className={systemName || ""}>{t`Aux Devices`}</Typography>
				<div className={groupIcons}>
					<LightTooltip title={t`Edit system information - unit names, mapping control /service addreses, assigining units between systems`}>
						<IconButton
							disableRipple
							disabled={!device?.permissions?.canUpdate}
							onClick={() => actions.openEditSensors(device?.id)}
							className={classes.iconBtnStyle}
						>
							<EditIcon />
						</IconButton>
					</LightTooltip>
				</div>
			</div>
			<div className={indoorOutdoorContainer}>
				<div>
					<Typography className={clsx("", outdoor)} variant="body1">
						<span>
							{device?.numOfSensors} {t` Devices`}
						</span>
					</Typography>
				</div>
			</div>
		</Grid>
	);
};

interface PowerMetersProps {
	device: {
		id: string;
		numOfPowerMeters: number;
		permissions?: {
			canUpdate: boolean;
		};
	};
	actions: {
		openEditPPD: (deviceId: string) => void;
	};
}

const PowerMeters: React.FC<PowerMetersProps> = ({ device, actions }) => {
	const classes = systemViewStyle();
	const { systemName, groupIcons, indoorOutdoorContainer, outdoor } = classes;

	return (
		<Grid key={`ppd-details`} className={clsx(classes.BaseTile, classes.SiteSystem)}>
			<div className={classes.overWriteDisplayVirtualSystems}>
				<Typography className={systemName || ""}>{t` Power Meters`}</Typography>
				<div className={groupIcons}>
					<LightTooltip title={t`Edit system information - unit names, mapping control /service addreses, assigining units between systems`}>
						<IconButton
							disableRipple
							disabled={!device?.permissions?.canUpdate}
							onClick={() => actions.openEditPPD(device.id)}
							className={classes.iconBtnStyle}
						>
							<EditIcon />
						</IconButton>
					</LightTooltip>
				</div>
			</div>
			<div className={indoorOutdoorContainer}>
				<div>
					<Typography className={clsx("", outdoor)} variant="body1">
						<span>
							{device.numOfPowerMeters} {t` Power Meters`}
						</span>
					</Typography>
				</div>
			</div>
		</Grid>
	);
};

interface BACnetProps {
	device: {
		id: string;
		bacnetUnits: [];
		permissions?: {
			canUpdate: boolean;
		};
	};
	actions: {
		openEditBacnetUnits: (device: any) => void;
	};
}

const BACnetDevices: React.FC<BACnetProps> = ({ device, actions }) => {
	const classes = systemViewStyle();
	const { systemName, groupIcons, indoorOutdoorContainer, outdoor } = classes;

	return (
		<Grid key={`ppd-details`} className={clsx(classes.BaseTile, classes.SiteSystem)}>
			<div className={classes.overWriteDisplayVirtualSystems}>
				<Typography className={systemName || ""}>{t`BACnet devices`}</Typography>
				<div className={groupIcons}>
					<IconButton
						disableRipple
						disabled={!device?.permissions?.canUpdate}
						onClick={() => actions.openEditBacnetUnits(device)}
						className={classes.iconBtnStyle}
					>
						<EditIcon />
					</IconButton>
				</div>
			</div>
			<div className={indoorOutdoorContainer}>
				<div>
					<Typography className={clsx("", outdoor)} variant="body1">
						<span>
							{device?.bacnetUnits?.length || 0} {t`BACnet devices`}
						</span>
					</Typography>
				</div>
			</div>
		</Grid>
	);
};

interface Line {
	isMapping?: boolean;
	mappingCurrent: number;
	mappingTotal: number;
	line: string;
}

interface DevicePermissions {
	canUpdate: boolean;
}
interface Actions {
	setOpenMappingInProgress: (isOpen: boolean) => void;
	openCloseAssignLineUnits: (
		deviceId: string,
		lineId: string,
		isService: boolean
	) => void;
}

interface UnassignedLinesProps {
	line: Line;
	device: Device;
	actions: Actions;
	isService: boolean;
}

const UnassignedLines: React.FC<UnassignedLinesProps> = ({
	line,
	device,
	actions,
	isService,
}) => {
	const classes = systemViewStyle();
	const {
		overWriteDisplay,
		systemName,
		groupIcons,
		indoorOutdoorContainer,
		outdoor,
		indoor,
	} = classes;

	const {
		isMapping = false,
		mappingCurrent,
		mappingTotal,
		line: lineId,
	} = line;
	return (
		<Grid
			key={`unassigned-line-${lineId}`}
			className={clsx(classes.BaseTile, classes.UnassignedLines)}
			style={{ backgroundColor: "#fff0f0" }}
		>
			<div className={overWriteDisplay}>
				<Typography className={systemName}>
					{`Line ${lineId} unassigned Units`}
				</Typography>
				<div className={groupIcons}>
					<LightTooltip title={t`Edit system information - unit names, mapping control /service addreses, assigining units between systems`}>
						<IconButton
							disableRipple
							disabled={!device?.permissions?.canUpdate}
							onClick={() =>
								isMapping
									? actions.setOpenMappingInProgress(true)
									: actions.openCloseAssignLineUnits(
											device.id,
											lineId,
											isService
										)
							}
							className={classes.iconBtnStyle}
						>
							<EditIcon />
						</IconButton>
					</LightTooltip>
				</div>
			</div>
			<div className={indoorOutdoorContainer}>
				{!!device.unassignedLines[lineId]?.outdoor && (
					<div>
						<Typography className={clsx("", outdoor)} variant="body1">
							<span>
								{device.unassignedLines[lineId]?.outdoor} {t` Outdoor`}
							</span>
						</Typography>
					</div>
				)}
				{!!device.unassignedLines[lineId]?.control && (
					<div>
						<Typography className={clsx("", indoor)} variant="body1">
							<span>
								{device.unassignedLines[lineId]?.control} {t` Indoor `}
							</span>
						</Typography>
					</div>
				)}
				{!!device.unassignedLines[lineId]?.service && (
					<div>
						<Typography className={clsx("", indoor)} variant="body1">
							<span>
								{device.unassignedLines[lineId]?.service} {t` Service `}
							</span>
						</Typography>
					</div>
				)}
				{!!device.unassignedLines[lineId]?.bsbox && (
					<div>
						<Typography className={clsx("", indoor)} variant="body1">
							<span>
								{device.unassignedLines[lineId]?.bsbox} {t` BS Box `}
							</span>
						</Typography>
					</div>
				)}
			</div>
			{isMapping && (
				<div className={classes.mappingContainer}>
					<Typography className={classes.mappingText}>
						{t`Mapping`}... ({mappingCurrent}/{mappingTotal})
						<span>
							{mappingCurrent === 0 && mappingTotal === 0
								? 100
								: Math.round((mappingCurrent / mappingTotal) * 100)}
							%
						</span>
					</Typography>
					<Typography className={classes.mappingTime}>
						{(Math.round((mappingTotal - mappingCurrent) / 2) * 100) / 100}{" "}
						{t`min left`}
					</Typography>
					<LinearProgress
						classes={{
							root: classes.progressRoot,
							bar: classes.progressBar,
						}}
						variant="determinate"
						value={
							mappingCurrent === 0 && mappingTotal === 0
								? 100
								: Math.round((mappingCurrent / mappingTotal) * 100)
						}
					/>
				</div>
			)}
		</Grid>
	);
};

interface ViewWidgetProps {
	widgetType:
	| "SiteSystem"
	| "PowerMeter"
	| "SiteSensor"
	| "UnassignedLines"
	| "BACnetDevices"
	| string;
	data: any; // Consider replacing 'any' with a more specific type based on the data structure
	actions: Actions; // Add the 'actions' property
	device: Device;
	isService: boolean;
	hasOnlyControlUnit: boolean;
}

const ViewWidget: React.FC<ViewWidgetProps> = (props) => {
	const { widgetType, data } = props;

	if (widgetType === "SiteSystem") {
		// @ts-ignore
		return <SiteSystem {...props} system={data} />;
	}

	if (widgetType === "PowerMeter") {
		// @ts-ignore
		return <PowerMeters {...props} />;
	}

	if (widgetType === "SiteSensor") {
		// @ts-ignore
		return <SiteSensor {...props} />;
	}

	if (widgetType === "UnassignedLines") {
		// @ts-ignore
		return <UnassignedLines {...props} line={data} />;
	}

	if (widgetType === "BACnetDevices") {
		// @ts-ignore
		return <BACnetDevices {...props} />;
	}

	return <></>;
};

interface ISystemsViewProps {
	device: any;
	actions: any;
	isService: boolean;
	hasOnlyControlUnit: boolean;
	siteId: string | number;
}
const SystemsView = (props: ISystemsViewProps) => {
	const classes = systemViewStyle();
	const {
		device,
		actions,
		isService,
		hasOnlyControlUnit = false,
		siteId,
	} = props;

	const [isTileDraggable, setIsTileDraggable] = useState<any>({});
	const [draggedTile, setDraggedTile] = useState<any>(null);
	const [dragOverTile, setDragOverTile] = useState<any>(null);
	const [tiles, setTiles] = useState<any>([]);
	const userPreferences = useStoreState((state) => state.users.userPreferences);
	const updateUserPreferences = useStoreActions(
		(action) => action.users.updateUserPreferences
	);
	const selections = useStoreState((state) => state.selections.selections);
	const { customerId } = selections;

	const { enableBacnet } = useStoreState((s) => s.users.me);

	if (isUndefined(device)) {
		return null;
	}

	const {
		unassignedLines,
		bacnetUnits,
		systems,
		numOfSensors,
		numOfPowerMeters,
		id: deviceId,
	} = device;

	useEffect(() => {
		const userOrder =
			userPreferences?.siteSystemsOrderPerCustomer?.[customerId || ""]?.[
				siteId
			]?.[deviceId || ""];

		const items = [];
		systems?.forEach((entry: any) => {
			items.push({
				data: entry,
				widgetType: "SiteSystem",
			});
		});

		if (+numOfSensors) {
			items.push({
				data: {
					id: "SiteSensor",
				},
				widgetType: "SiteSensor",
			});
		}

		if (numOfPowerMeters > 0) {
			items.push({
				data: {
					id: "PowerMeter",
				},
				widgetType: "PowerMeter",
			});
		}

		Object.keys(unassignedLines)?.forEach((entry: any) => {
			items.push({
				data: {
					...unassignedLines[entry],
					line: entry,
					id: `UnassignedLines-${entry}`,
				},
				widgetType: "UnassignedLines",
			});
		});

		if (enableBacnet && bacnetUnits?.length > 0) {
			items.push({
				data: {
					id: "BACnetDevices",
				},
				widgetType: "BACnetDevices",
			});
		}

		//Sort objects based on their index in the array of IDs
		const result = sortBy(items, [
			(system) => {
				return userOrder && userOrder.includes(system?.data?.id)
					? userOrder.indexOf(system?.data?.id)
					: Infinity;
			},
			//@ts-ignore
			(entry) => entry.data?.id?.toLowerCase(),
		]);
		setTiles(result);
	}, [
		systems,
		unassignedLines,
		numOfSensors,
		numOfPowerMeters,
		userPreferences,
	]);

	// Drag and drop functions
	const saveUserOrder = async (tileIds: number[]): Promise<any> => {
		const currentStored = userPreferences?.siteSystemsOrderPerCustomer ? cloneDeep(userPreferences?.siteSystemsOrderPerCustomer) : {};
		const customer: string = customerId || "";
    const { id: deviceId } = device;
		return updateUserPreferences({
			siteSystemsOrderPerCustomer: setWith(currentStored, [customer, siteId, deviceId], tileIds, Object),
		});
	};

	const handleDragStart = (e: React.DragEvent<HTMLDivElement>, tile: any) => {
		setDraggedTile(tile);
		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("text/html", e.currentTarget.outerHTML);
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>, tile: any) => {
		e.preventDefault();
		setDragOverTile(tile);
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		if (
			draggedTile &&
			dragOverTile &&
			draggedTile.id !== dragOverTile.data.id
		) {
			const newTiles = tiles.filter(
				(t: any) => t.data.id !== draggedTile.data.id
			);
			const hoverIndex = newTiles.findIndex(
				(t: any) => t.data.id === dragOverTile.data.id
			);
			newTiles.splice(hoverIndex, 0, draggedTile);

			try {
				saveUserOrder(newTiles.map((entry: any) => entry.data.id));
			} catch (e) {
				console.error("An errror while saving user order", e);
			}

			setTiles(newTiles);
		}
		setDraggedTile(null);
		setDragOverTile(null);
	};

	const handleDragEnd = (id: string) => {
		setDraggedTile(null);
		setDragOverTile(null);
    setIsTileDraggable({
      ...isTileDraggable,
      [id]: false
    });
	};

  const handleMouseDown = (e: any, id: string) => {
    if (!!e.target.closest(`.${classes.dragContainer}`)) {
      setIsTileDraggable({
        ...isTileDraggable,
        [id]: true
      });
    }
  };

  const handleMouseUp = (id: string) => {
    setIsTileDraggable({
      ...isTileDraggable,
      [id]: false
    });
  };

	// End drag and drop functions

	return (
		<>
			<>
				<div className={classes.SiteSystemsGrid}>
					{tiles.map((tile: { widgetType: string; data: any }) => (
						<div
							draggable={isTileDraggable?.[tile.data.id]}
							key={tile.data.id}
							onDragOver={(e) => handleDragOver(e, tile)}
							onDrop={handleDrop}
							onDragStart={(e) => handleDragStart(e, tile)}
							onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
                e.preventDefault();
                handleDragEnd(tile.data.id);
              }}
							className={clsx(classes.Tile, {
								Dragging: draggedTile?.data.id === tile.data.id,
								DragOver: dragOverTile?.data.id === tile.data.id,
							})}
              onMouseDown={(e: any) => {
                handleMouseDown(e, tile.data.id);
              }}
              onMouseUp={() => {
                handleMouseUp(tile.data.id);
              }}
						>
              <div className={classes.dragContainer}>
                <MoreVert className={classes.whiteMoreVert} />
              </div>
              <div className={classes.baseTileWrapper}
                  draggable={false}>
                <ViewWidget
                  actions={actions}
                  device={device}
                  isService={isService}
                  hasOnlyControlUnit={hasOnlyControlUnit}
                  widgetType={tile.widgetType}
                  data={tile.data}
                />
              </div>
						</div>
					))}
					<div>
						<Grid item>
							<LightTooltip title={t`Manually add a system if it was not automatically detected by the application when connected with the CoolAutomation device.`}>
								<Button
									disabled={
										device?.canAddDevice || !device?.permissions?.canCreateSystems
									}
									disableRipple
									className={classes.addNewSystem}
									onClick={() => actions.openAddEditSystem(deviceId, true)}
								>
									<Add className={classes.addIcon} />
								</Button>
							</LightTooltip>
						</Grid>
					</div>
				</div>
			</>
		</>
	);
};

// @ts-ignore
export default SystemsView;
