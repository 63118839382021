import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  dialogContent: {
    flex: "1 1 auto",
    overflowY: "auto",
    paddingBottom: 0,
    fontSize: "18px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "25px",
    color: theme.overrides.MuiButton.contained.color
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "end"
  },
  columnsTitles: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  tableContainer: {
    height: "calc(100% - 53px)",
    width: "auto"
  },
  cellStyle: {
    ...theme.tables.tableCell,
    height: "35px",
    padding: "0 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "150px"
  }

}));
export default useStyles;
