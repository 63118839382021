import React from 'react';
import PropTypes from 'prop-types';

const OpenedSensor = ({ width, height }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.8 6.09116C19.8 7.74802 18.4569 9.09116 16.8 9.09116C15.1431 9.09116 13.8 7.74802 13.8 6.09116C13.8 4.43431 15.1431 3.09116 16.8 3.09116C18.4569 3.09116 19.8 4.43431 19.8 6.09116ZM19.8 6.09116H24M4.2 6.09116H0M9.32132 3.96984C10.4929 5.14141 10.4929 7.04091 9.32132 8.21248C8.14974 9.38406 6.25025 9.38406 5.07868 8.21248C3.9071 7.04091 3.9071 5.14141 5.07868 3.96984C6.25025 2.79827 8.14974 2.79827 9.32132 3.96984ZM9.32132 3.96984L12.2912 1"
			stroke="white"
		/>
	</svg>
);

OpenedSensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

OpenedSensor.defaultProps = {
	width: 24,
	height: 10
};

export default OpenedSensor;