import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import {
  AutoMode,
  AutoVRMode,
  ColdBlue,
  DryIcon,
  Fan1Icon,
  Fan2Icon,
  Fan3Icon,
  Fan4Icon,
  FanMode,
  HotRed,
  Swing,
  Swing30,
  Swing45,
  Swing60,
  SwingHor,
  SwingVer
} from "../../../svgComponents";

import { useStoreState } from "../../../models/RootStore";
import modesListStyle from "./modesList.style";

const mappedTypes: any = {
  swingModes: "subTypesSwingModes",
  operationModesExtended: "subTypesOperationModes",
  fanModes: "subTypesFanModes"
};
const mappedTypesExtended: any = {
  swingModes: "swingModes",
  operationModesExtended: "operationModesExtended",
  fanModes: "fanModesExtended"
};

const nameStyle: any = {
  fontSize: "16px",
  color: "#545964",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  fontFamily: "Roboto",
  letterSpacing: "-0.39px"
};

const ModesList = (props: any) => {
  const { activeModes = [], action, customClass, modeName, disabled = false, subType = 0 } = props;
  const useStyle = makeStyles(modesListStyle);
  const classes = useStyle();
  const { container, modeStyle, activeModeStyle } = classes;
  const types: any = useStoreState((states) => states.types);

  const icons: any = {
    operationModesExtended: {
      0: <ColdBlue style={{ transform: "scale(1.889)" }} />,
      1: <HotRed style={{ transform: "scale(1.889)" }} />,
      2: <AutoMode maincolor="#aaa2aa" />,
      3: <DryIcon maincolor="#aaa2aa" />,
      5: <FanMode maincolor="#aaa2aa" />,
      7: <AutoVRMode maincolor="#aaa2aa" />,
    },
    fanModes: {
      0: subType === 2 ? <Typography style={nameStyle}>{t`LOW`}</Typography> : <Fan1Icon color1="#4b1c46" />,
      1: <Fan2Icon color1="#4b1c46" />,
      2: <Fan3Icon color1="#4b1c46" />,
      4: <Fan4Icon color1="#4b1c46" />,
      3: <Typography style={nameStyle}>{t`Auto`}</Typography>
    },
    swingModes: {
      0: <Typography style={nameStyle}>{t`Vertical`}</Typography>,
      4: <Typography style={nameStyle}>{t`Horizontal`}</Typography>,
      1: <Typography style={nameStyle}>30°</Typography>,
      2: <Typography style={nameStyle}>45°</Typography>,
      3: <Typography style={nameStyle}>60°</Typography>,
      5: <Typography style={nameStyle}>{t`Swing`}</Typography>
    }
  };

  const modesSelections = types[mappedTypes[modeName]];
  const modeNames = types[mappedTypesExtended[modeName]];
  if (!modesSelections) {
    return null;
  }

  return (
    <div className={container}>
      {(modesSelections[subType] || []).map((modeKey: any, index: number) => {
        const isSelected = activeModes.indexOf(modeKey) > -1;
        const modeIcon = icons[modeName][modeKey] || (
          <Typography className={classes.nameStyle}>{modeNames[modeKey] || modeKey}</Typography>
        );

        return (
          <div
            className={clsx(
              classes.modeStyle,
              {
                [activeModeStyle]: isSelected
                // [classes.inactiveText]: !isSelected
              },
              customClass
            )}
            key={index}
            onClick={() => disabled ? {} : action(modeName, modeKey)}
          >
            {modeIcon}
          </div>
        );
      })}
    </div>
  );
};

export default ModesList;
