import { css } from "@emotion/core";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  Popover,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons/";
import clsx from "clsx";
import {
  addDays,
  differenceInDays,
  sub
} from "date-fns";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useLocation } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from "recharts";
import { t } from "ttag";
import CButton from "../../cool_widgets/Button";
import {
  Critical as CriticalIcon,
  Download as SVGDownload,
  Refresh as SvgRefresh,
  rightAxis as RightAxisIcon
} from "../../icons";
import { useStoreState } from "../../models/RootStore";
import LightTooltip from "../Tooltip/LightTooltip";
import useStyles from "./UnitStatsGraph.style";
import styles from "./UnitStatsTable.module.css";
import useChurnZero from "@hooks/useChurnZero";

const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as any);
};

const hex2rgba = (hex: any = "#000", alpha: number = 1) => {
  const [r, g, b] = hex?.match(/\w\w/g)?.map((x: any) => parseInt(x, 16)) ?? [];
  return `rgba(${r},${g},${b},${alpha})`;
};

const override = css(`animation-duration: 2s;`);

//Custome colored checkboxes
const TempColors = {
  alerts: { line: "rgba(200, 0, 0, 1)", fill: "rgba(200, 0, 0, 0.45)" }, // light red
  setpoint: { line: "rgba(0, 200, 200, 1)", fill: "rgba(0, 200, 200, 0.45)" }, // light blue
  ambient: { line: "rgba(200, 200, 0, 1)", fill: "rgba(200, 200, 0, 0.75)" }, // light yellow
  weather: { line: "#ef3b2f", fill: "#ef3b2f" }
},
  modeColors: any = {
    COOL: "#35A8E0", // blue
    HEAT: "#F05146", // red
    AUTO: "#7303FC", // purple
    DRY: "#35A8E0", // blue
    FAN: "#00AD4B", // green
    OFF: "#888", // gray
    NODATA: "#000", // black
    OTHER: "#7303FC" // purple
  },
  outdoorModeColors: any = {
    COOL: "#35A8E0", // blue
    HEAT: "#F05146", // red
    AUTO: "#7303FC", // purple
    DRY: "#35A8E0", // blue
    FAN: "#888", // gray
    OFF: "#888", // gray
    NODATA: "#000", // black
    OTHER: "#7303FC" // purple
  };

const chkboxStyles = makeStyles(() => ({
  alert: {
    color: `${TempColors.alerts.fill} !important`,
    "&$checked": {
      color: `${TempColors.alerts.line} !important`
    }
  },
  setpoint: {
    color: `${TempColors.setpoint.fill} !important`,
    "&$checked": {
      color: `${TempColors.setpoint.line} !important`
    }
  },
  ambiant: {
    color: `${TempColors.ambient.fill} !important`,
    "&$checked": {
      color: `${TempColors.ambient.line} !important`
    }
  },
  weather: {
    color: `${TempColors.weather.fill} !important`,
    "&$checked": {
      color: `${TempColors.weather.line} !important`
    }
  }

}));

const UnitStatsGraph = (props: any) => {
  const chkbox = chkboxStyles();

  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const { siteId } = useStoreState((s) => s.selections.selections);
  const timeLimit = sitesFlags[siteId || ""]?.numOfHourLimitUnitDiag || 0;
  const pastTimeAllowed = new Date().getTime() - (timeLimit * 60 * 60 * 1000);

  const {
    graphs,
    onDateRangeChange,
    onChipDelete,
    isSystemLoading = false,
    isUnitLoading = false,
    exportFile,
    isNotSystemUnit,
    startTime,
    endTime,
    paramsTable,
    paramsMap,
    staticCodes,
    hideAlerts = false,
    alertsData,
    unitType,
    paramsColors,
    siteExternalTemps,
    hideSiteTemp = false,
    isLoading,
    disableDatepicker,
    disableExport,
    enums,
    types,
    timezone,
    setZoomTime,
    zoomTime,
    sensorsRows,
    externalsData,
    powerMetersRows,
    minDate,
    storedRuleId
  } = props;

  const {trackEvent} = useChurnZero()

  useEffect(() => {
    const paramsAxisConfig: any = Array.from(paramsColors)?.reduce((vals: any, item: any) => {
      return { ...vals, [item[0]]: "left" };
    }, {});
    setAxisConfig({ ...paramsAxisConfig, ...axisConfig });
  }, [paramsColors.size]);

  const graphsData: any = Object.values(graphs);
  const supportedParams: any = Object.keys(paramsTable);
  const selectedStatsSorted = Array.from(paramsColors, ([id, color]) => {
    if (sensorsRows[id]) {
      return { id, type: "sensor" };
    }
    if (powerMetersRows[id]) {
      return { id, type: "powerMeter" };
    }
    return { id, type: "param" };
  });

  let customRanges = [
    {
      label: "last hour",
      hasCustomRendering: false,
      range: () => ({
        startDate: sub(new Date(moment().tz(timezone).format("llll")), { hours: 1 }),
        endDate: new Date(moment().tz(timezone).format("llll"))
      }),
      isSelected() {
        return false;
      }
    },
    {
      label: "last 24 hours",
      hasCustomRendering: false,
      range: () => ({
        startDate: sub(new Date(moment().tz(timezone).format("llll")), { hours: 24 }),
        endDate: new Date(moment().tz(timezone).format("llll"))
      }),
      isSelected() {
        return false;
      }
    }
  ];
  customRanges = !timeLimit || timeLimit > 167 ? [...customRanges, {
    label: "last 7 days",
    hasCustomRendering: false,
    range: () => ({
      startDate: sub(new Date(moment().tz(timezone).format("llll")), { days: 7 }),
      endDate: new Date(moment().tz(timezone).format("llll"))
    }),
    isSelected() {
      return false;
    }
  }] : customRanges;
  const { calculatedMode: calculatedModeTypes, operationStatuses, operationModesExtended } = types;
  const { ROOM_TEMP_CODE, SETPOINT_CODE, SITE_TEMP } = staticCodes;
  const [alertsChecked, setAlertsChecked] = useState(false);
  const [setpointChecked, setSetpointChecked] = useState(false);
  const [ambientChecked, setAmbientChecked] = useState(false);
  const [weatherChecked, setWeatherChecked] = useState(false);
  const [refAreaLeft, setRefAreaLeft] = useState<any>("");
  const [refAreaRight, setRefAreaRight] = useState<any>("");
  const [XDomain, setXDomain] = useState<any>([startTime, endTime]);
  const [xTicks, setXTicks] = useState<any>([]);
  const [showRangePicker, setShowRangePicker] = useState<boolean>(false);
  const [selectedGraph, setSelectedGraph] = useState<string>("");
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const [dateRange, setDateRange] = useState<any>(null);
  const location: any = useLocation();
  const { state } = location;
  const [selectedError, setSelectedError] = useState<any>(null);
  const classes: any = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [axisConfig, setAxisConfig] = useState<any>({});
  const storedAlertCheck = JSON.parse(localStorage.getItem("alertCheck") || "false");

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!startTime || !endTime) {
      return;
    }

    setDateRange([
      {
        startDate: moment(startTime).tz(timezone).toDate(),
        endDate: moment(endTime).tz(timezone).toDate(),
        key: "selection"
      }
    ]);
    // }
  }, [startTime, endTime, minDate]);

  useEffect(() => {
    if (zoomTime) {
      return;
    }
    setXDomain([startTime, endTime]);
  }, [startTime, endTime, zoomTime]);

  useEffect(() => {
    if (!_.isNil(state)) {
      setAlertsChecked(state.alertCheck);
      return;
    }
    if (storedAlertCheck) {
      setAlertsChecked(storedAlertCheck);
    }
    localStorage.removeItem("alertCheck");
  }, [state, storedAlertCheck]);

  useEffect(() => {
    if (storedRuleId) {
      const storedStringTime = localStorage.getItem("time");
      const storedTime = storedStringTime ? parseInt(storedStringTime, 10) : 0;
      if (storedTime) {
        const eventTime = moment(storedTime);
        const startTime = eventTime.clone().subtract(4, 'hours').valueOf();
        const endTime = eventTime.clone().add(4, 'hours').valueOf();
        setZoomTime({ startTime, endTime });
        setXDomain([startTime, endTime]);
      }
      setAmbientChecked(true);
      setSetpointChecked(true);
      setAlertsChecked(true);
    }
  }, [state, storedRuleId]);

  useEffect(() => {
    generateXTicks(XDomain[0], XDomain[1]);
  }, [XDomain]);

  const availableModes = Object.values(calculatedModeTypes);

  const generateXTicks = (startTime: any, endTime: any) => {
    const interval = (endTime - startTime) / 6,
      ticks = [startTime];
    for (let i = 1; i < 6; i++) {
      ticks.push(startTime + interval * i);
    }
    ticks.push(endTime);
    setXTicks(ticks);
  };

  const showError = Boolean(selectedError);

  const handleDateRangeChange = (item: any) => {

    trackEvent('ChangeDateRange','',1,{oneTimeTrack:true})

    const range = item.selection;
    let { startDate, endDate, key } = range;

    if (differenceInDays(endDate, startDate) > 7) {
      endDate = addDays(startDate, 7);
    }

    setDateRange([{ startDate, endDate, key }]);
  }

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    const dateTime = `${dateFormat} ${timeFormat}`;
    let hasMode = false;

    if (active) {
      return (
        <div className={classes.customTooltip}>
          <Typography className="label">
            <strong>{`Date/Time: ${moment(label).tz(timezone).format(dateTime)}`}</strong>
          </Typography>
          {showError && (
            <div className={clsx(classes.paramCont, classes.errorTooltip)}>
              <Typography>{`Alert Type: ${selectedError.alerttype}`}</Typography>
              <Typography>{`Alert ID: ${selectedError.shortId}`}</Typography>
              <Typography>{`Open Date: ${selectedError.time}`}</Typography>
              {selectedError.errorCode && <Typography>{`Error Code: ${selectedError.errorCode}`}</Typography>}
              <Typography className={classes.capText}>{`Status: ${selectedError.status}`}</Typography>
              {selectedError.status === "closed" && <Typography>{`Clear Date: ${selectedError.clearTime}`}</Typography>}
              {selectedError.errorDescription ?
                <Typography>{`Description: ${selectedError.errorDescription}`}</Typography>
                : ((selectedError.alerttype === "Anomalies" || selectedError.alerttype === "Maintenance") && selectedError.description ?
                  <Typography>{`Description: ${selectedError.description} `}{!!selectedError.ruleName && `(${selectedError.ruleName})`}</Typography> : null)}
            </div>
          )}
          {payload &&
            payload.length &&
            payload.map((param: any, index: any) => {
              if (param.name && param.name === "errors" || param.name === "noData") {
                return null;
              }

              if (param.dataKey === "reading_value") {
                const { name: sensorId, payload = {}, color } = param;
                const { reading_value } = payload;
                const { enums, name } = sensorsRows[sensorId];
                const sensorValue = (enums && enums[reading_value]) || reading_value;

                return (<div className={classes.paramCont} key={`${sensorId}-1--${index}`}>
                  <Typography className={classes.paramName} style={{ color }}>{name}</Typography>
                  <Typography className={classes.paramVal}>{sensorValue}</Typography>
                </div>);
              }

              if (param.dataKey === "power" || param.dataKey === "energy") {
                const { name: powerMeterId, payload = {}, color } = param;
                const { [param.dataKey]: val } = payload;
                const { name } = powerMetersRows[powerMeterId];

                return (<div className={classes.paramCont} key={`${powerMeterId}-1--${index}`}>
                  <Typography className={classes.paramName} style={{ color }}>{name}</Typography>
                  <Typography className={classes.paramVal}>{val}</Typography>
                </div>);
              }

              if (availableModes.indexOf(param.name) > -1) {
                if (+param.value === 0) {
                  return null;
                }
                const name = param.name || "other";
                hasMode = true;

                if (unitType === "indoor" || unitType === "service") {
                  const { payload = {} } = param;
                  const operationEnum = payload[48] || "";
                  const modeEnum = payload[51] || "";
                  const operation = operationStatuses[operationEnum];
                  const mode = modeEnum || modeEnum === 0 ? operationModesExtended[modeEnum] || "other" : "";

                  return (
                    <>
                      {operation && <div className={classes.paramCont} key={`${param.name}-1--${index}`}>
                        <Typography className={classes.paramName}>{"Operational status:"}</Typography>
                        <Typography className={clsx(classes.paramVal, classes.capitalizedText)}>{`${operation}`}</Typography>
                      </div>}
                      {mode && <div className={classes.paramCont} key={`${param.name}-2--${index}`}>
                        <Typography className={classes.paramName}>{"Mode:"}</Typography>
                        <Typography className={classes.paramVal}>{`${mode.toLowerCase()}`}</Typography>
                      </div>}
                    </>
                  );
                }

                return (
                  <div className={classes.paramCont} key={`${param.name}---${index}`}>
                    <Typography className={classes.paramName}>{"Demand status:"}</Typography>
                    <Typography className={classes.paramVal}>{`${name || "other"}`}</Typography>
                  </div>
                );
              }

              let name = paramsMap[param.name] ? paramsMap[param.name].title : param.name;
              name = name && name.length <= 20 ? name : name && name.substr(0, 17) + "...";
              const enumName: null = paramsMap[param.name]?.enum;
              const value = enumName && enums[enumName] && enums[enumName][param.value] ? enums[enumName][param.value] : param.value;
              return (
                <div className={classes.paramCont} key={`${param.name}---${index}`}>
                  <Typography
                    className={classes.paramName}
                    style={{ color: param.color }}
                  >{`${name}:`}</Typography>
                  <Typography className={classes.paramVal}>{`${value}`}</Typography>
                </div>
              );
            })}
          {
            hasMode ? null : (unitType === "indoor" || unitType === "service") ? null :
              <div className={classes.paramCont} key={`nodata---0909`}>
                <Typography className={classes.paramName}>{"Demand status:"}</Typography>
                <Typography className={classes.paramVal}>{t`No Data`}</Typography>
              </div>
          }
        </div>
      );
    }

    return null;
  },
    CustomXAxisTick = (props: any) => {
      const {
        x,
        y,
        payload: { value }
      } = props;
      const direction = "middle";

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} fill="#666" fontSize={"13px"}>
            <tspan textAnchor={direction} x="0" fontFamily="Roboto">
              {moment(value).tz(timezone).format(dateFormat)}
            </tspan>
            <tspan textAnchor={direction} x="0" dy="20" fontSize={"13px"} fontFamily="Roboto">
              {moment(value).tz(timezone).format(timeFormat)}
            </tspan>
          </text>
        </g>
      );
    },
    CustomYAxisTick = (props: any) => {
      const { x, y, payload, orientation } = props;
      const { value } = payload;

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} textAnchor="end" fill="#666" fontSize={"13px"}>
            <tspan x={orientation === "left" ? -3 : 35} dy="0.355em" fontFamily="Roboto">
              {Math.round(value * 10) / 10}
            </tspan>
          </text>
        </g>
      );
    },
    toggleGraph = (graphName: string) => {
      if (selectedGraph === graphName) {
        return setSelectedGraph("");
      }
      setSelectedGraph(graphName);
    },
    toggleAxis = (e: any, value: any) => {
      e.preventDefault();
      e.stopPropagation();
      setAxisConfig({ ...axisConfig, [value]: axisConfig[value] === "left" ? "right" : "left" });
    },
    CustomizedLegend = ({ payload }: any) => {

      const legendItems: any = payload.map((graph: any, index: any) => {
        const { value, color, type } = graph;
        if (
          value === "errors" ||
          value === "setpoint" ||
          value === "roomtemp" ||
          value === "sitetemp" ||
          value.indexOf("mode") > -1
        ) {
          return null;
        }

        let graphName = "";
        if (type === "sensor") {
          graphName = sensorsRows[value]?.name || value.toString();
        } else if (type === "powerMeter") {
          graphName = powerMetersRows[value]?.name || value.toString();
          if (!axisConfig["visited" + value]) {
            axisConfig[value] = "right";
            axisConfig["visited" + value] = true;
          }
        } else {
          graphName = paramsMap[value] ? paramsMap[value].title : value.toString();
        }

        const isSelected = selectedGraph === value;
        if (
          typeof value !== "string" ||
          value === "errors" ||
          value === "setpoint" ||
          value === "roomtemp" ||
          value === "sitetemp" ||
          value.indexOf("mode") > -1
        ) {
          return null;
        }
        const isLeft = axisConfig[value] && axisConfig[value] === "left";
        return (
          <div
            key={`legend - ${index}`}
            onClick={() => toggleGraph(value)}
            className={clsx(classes.legendItem, !isLeft && classes.dashedBox)}
            style={{
              borderColor: isSelected ? "#fff" : color,
              backgroundColor: isSelected ? color : "#fff"
            }}
          >
            <div
              onClick={(e: any) => toggleAxis(e, value)}
              className={clsx(classes.toggleAxisStyle, isLeft && classes.flipIcon)}
              style={{ borderColor: isSelected ? "#fff" : color, borderStyle: isLeft ? "solid" : "dashed", backgroundColor: isSelected ? color : hex2rgba(color, 0.1) }}
            >

              <RightAxisIcon
                color={isSelected ? "#fff" : color}
              />

            </div>
            <Typography style={{ color: isSelected ? "#fff" : color, fontSize: "0.8rem" }}>
              {graphName.length <= 20 ? graphName : graphName.substr(0, 17) + "..."}
            </Typography>

            <Close
              style={{ color: isSelected ? "#fff" : color }}
              className={classes.legendRemove}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChipDelete(value, type);
              }}
            />
          </div>
        );
      });

      return (
        <div className={classes.legendWrapper}>
          <div className={classes.legendContainerLeft}>
            {legendItems}
          </div>
        </div>
      );
    };


  const getSelectedStaticCodes = () => {
    const codes = [];

    if (ambientChecked) {
      codes.push(ROOM_TEMP_CODE);
    }
    if (setpointChecked) {
      codes.push(SETPOINT_CODE);
    }
    if (weatherChecked) {
      codes.push(SITE_TEMP)
    }
    return codes;
  };
  const zoom = (didLeaveGraph: any) => {

    trackEvent('GraphAttributesChanges','', 1, {oneTimeTrack:true});

    let rightRef = refAreaRight,
      leftRef = refAreaLeft;
    const firstTime = graphsData && graphsData.length && graphsData[0].timestamp;
    const lastTime = graphsData && graphsData.length && graphsData[graphsData.length - 1].timestamp;

    if (firstTime && leftRef < firstTime) {
      leftRef = firstTime;
    }

    if (lastTime && rightRef > lastTime) {
      rightRef = lastTime;
    }

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    setZoomTime({ startTime: firstTime, endTime: lastTime });
    //pass time // get data again
    if (rightRef < leftRef) { [leftRef, rightRef] = [rightRef, leftRef]; }

    if (_.isString(didLeaveGraph) && didLeaveGraph === "dataMax") {
      const endTimeIndex = xTicks.length - 1;
      setZoomTime({ startTime: leftRef, endTime });
      setXDomain([leftRef, endTime]);
    } else if (_.isString(didLeaveGraph) && didLeaveGraph === "dataMin" || !refAreaRight) {
      setZoomTime({ startTime, endTime: leftRef });
      setXDomain([startTime, leftRef]);
    } else {
      setZoomTime({ startTime: leftRef, endTime: rightRef });
      setXDomain([leftRef, rightRef]);
    }

    setRefAreaLeft("");
    setRefAreaRight("");
  },
    selectGraph = (e: any) => {
      const { value } = e;
      setSelectedGraph(value);
    },
    changeDateRange = () => {
      const { startDate, endDate } = dateRange[0];
      if (!isValidDate(startDate) || !isValidDate(endDate)) {
        cancelDateSelection();
        return;
      }

      onDateRangeChange({ startDate, endDate }, false, timeLimit);
      setShowRangePicker(false);
    },
    cancelDateSelection = () => {
      setShowRangePicker(false);
      setDateRange([
        {
          startDate: new Date(moment(startTime).tz(timezone).format("llll")),
          endDate: new Date(moment(endTime).tz(timezone).format("llll")),
          key: "selection"
        }
      ]);
    },
    lineWidth = 2,
    selectedLineWidth = 5,
    currentDay = new Date(startTime);
  return (
    <Fragment>
      <Grid container className={classes.chartContainer1}>
        <div className={classes.headerContainer}>
          <Button
            disabled={disableDatepicker}
            variant="contained"
            onMouseUp={(event: any) => {
              setDatePickerAnchor(event.currentTarget);
              setShowRangePicker(!showRangePicker);
            }}
          >{`${moment(startTime).tz(timezone).format(dateFormat)} > ${moment(endTime).tz(timezone).format(
            dateFormat
          )}`}</Button>
          <Popover
            id={"graph-date-picker"}
            open={showRangePicker}
            style={{ overflow: "unset" }}
            anchorEl={datePickerAnchor}
            onClose={cancelDateSelection}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <div className={clsx(classes.rangePickerContainer)}>
              <DateRangePicker
                onChange={handleDateRangeChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                preventSnapRefocus={true}
                months={2}
                direction={"horizontal"}
                ranges={dateRange}
                maxDate={new Date(moment().tz(timezone).format("llll"))}
                staticRanges={customRanges}
                inputRanges={[]}
                shownDate={new Date(currentDay.setMonth(currentDay.getMonth() - 1))}
                minDate={!!timeLimit ? pastTimeAllowed > minDate ? new Date(pastTimeAllowed) : new Date(minDate) : new Date(minDate)}
              />
              <div className={classes.calendarButtons}>
                <CButton onMouseUp={() => cancelDateSelection()} white width={100} marginRight>
                  {t`Cancel`}
                </CButton>
                <CButton
                  width={100}
                  onMouseUp={() => changeDateRange()}
                >
                  {t`Done`}
                </CButton>
              </div>
            </div>
          </Popover>
          <FormGroup row className={classes.checkboxForm}>

            {(!hideAlerts) &&

              <LightTooltip arrow interactive placement="top-start" title={t`Show Alerts on graph`}>
                <FormControlLabel
                  className={classes.fixedWidth}
                  control={
                    <Checkbox
                      color="default"
                      checked={alertsChecked}
                      onChange={() => setAlertsChecked(!alertsChecked)}
                      value="checkedAlerts"
                      disabled={isLoading}
                      classes={{ root: chkbox.alert }}
                    />
                  }
                  label={t`Alerts`}
                />
              </LightTooltip>
            }
            {(!hideSiteTemp) && <LightTooltip arrow interactive placement="top-start" title={t`Show Site Temp on graph`}>
              <FormControlLabel
                className={classes.fixedWidth}
                control={
                  <Checkbox
                    checked={weatherChecked}
                    onChange={() => setWeatherChecked(!weatherChecked)}
                    value="checkedWeather"
                    disabled={isLoading || supportedParams.indexOf(SITE_TEMP) === -1}
                    classes={{ root: chkbox.weather }}
                  />
                }
                label={t`Site Temp`}
              />
            </LightTooltip>}
            {(unitType === "indoor" || unitType === "service" || unitType === "other") && (
              <LightTooltip arrow interactive placement="top-start" title={t`Show Room Temp on graph`}>
                <FormControlLabel
                  className={classes.fixedWidth}
                  control={
                    <Checkbox
                      checked={ambientChecked}
                      onChange={() => setAmbientChecked(!ambientChecked)}
                      value="checkedAmbient"
                      disabled={isLoading || supportedParams.indexOf(ROOM_TEMP_CODE) === -1}
                      classes={{ root: chkbox.ambiant }}
                    />
                  }
                  label={t`Room temp`}
                />
              </LightTooltip>
            )}
            {(unitType === "indoor" || unitType === "service" || unitType === "other") && (
              <LightTooltip arrow interactive placement="top-start" title={t`Show Setpoint on graph`}>

                <FormControlLabel
                  className={classes.fixedWidth}
                  control={
                    <Checkbox
                      color={"default"}
                      checked={setpointChecked}
                      onChange={() => setSetpointChecked(!setpointChecked)}
                      value="checkedSetpoint"
                      disabled={isLoading || supportedParams.indexOf(SETPOINT_CODE) === -1}
                      classes={{ root: chkbox.setpoint }}
                    />
                  }
                  label={t`Setpoint`}
                />
              </LightTooltip>
            )}

            {isSystemLoading || isUnitLoading ? <div className={classes.loaderContainer}><MoonLoader css={override} size={20} color={"#7f7e7e"} loading={true} /></div> :
              <LightTooltip arrow interactive placement="top-start" title={t`Export data to CSV`}>
                <IconButton disabled={disableExport} onClick={handleClick}>
                  <SVGDownload className={classes.SVGDownload} />
                </IconButton>
              </LightTooltip>
            }
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              classes={{ paper: classes.exportDialog }}
            >
              <div key={1} onClick={handleClose}>
                <Button onMouseUp={() => exportFile("full unit")} className={classes.exportBtn}>
                  {t` Export all unit parameters `}
                </Button>
              </div>
              <div key={2} onClick={handleClose}>
                <Button onMouseUp={() => exportFile("unit", getSelectedStaticCodes())} className={classes.exportBtn}>
                  {t` Export selected unit parameters `}
                </Button>
              </div>
              <div key={3} onClick={handleClose}>
                <Button
                  disabled={isNotSystemUnit}
                  onMouseUp={() => exportFile("full system")}
                  className={classes.exportBtn}
                >
                  {t` Export all system parameters `}
                </Button>
              </div>
              <div key={4} onClick={handleClose}>
                <Button
                  disabled={isNotSystemUnit}
                  onMouseUp={() => exportFile("system", getSelectedStaticCodes())}
                  className={classes.exportBtn}
                >
                  {t` Export selected system parameters `}
                </Button>
              </div>
            </Popover>
          </FormGroup>
        </div>
        <div className={classes.chartContainer}>
          {isLoading && <div className={classes.loadingContainer}>
            <SvgRefresh className={clsx(styles.refreshStyleLoading, classes.refreshIcon)} />
          </div>}

          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={graphsData}
              margin={{ top: 10, right: 0, bottom: 15, left: 5 }}
              onMouseDown={(e: any) => {
                e && e.activeLabel && setRefAreaLeft(e.activeLabel);
              }}
              onMouseMove={(e: any) => {
                refAreaLeft && setRefAreaRight(e.activeLabel);
              }}
              onMouseLeave={() => {
                if (refAreaLeft && refAreaLeft < refAreaRight) {
                  zoom("dataMax");
                }
                if (refAreaLeft && !refAreaRight) {
                  zoom("dataMin");
                }

              }}
              onMouseUp={zoom}
            >
              <XAxis
                xAxisId={"mainX"}
                dataKey="timestamp"
                type={"number"}
                domain={XDomain}
                allowDataOverflow={true}
                allowDuplicatedCategory={false}
                tick={<CustomXAxisTick />}
                height={40}
                strokeWidth={3}
                ticks={xTicks}
                interval={0}
              />
              <YAxis
                yAxisId={"left"}
                orientation={"left"}
                type="number"
                domain={["dataMin - 5", "dataMax + 5"]}
                allowDuplicatedCategory={false}
                allowDataOverflow={true}
                allowDecimals={false}
                strokeWidth={3}
                tickCount={8}
                tick={<CustomYAxisTick orientation={"left"} />}
                width={43}
                padding={{ top: 10, bottom: 20 }}
              />
              <YAxis
                yAxisId={"sec"}
                orientation={"right"}
                type="number"
                domain={["dataMin - 5", "dataMax + 5"]}
                allowDuplicatedCategory={false}
                allowDataOverflow={true}
                allowDecimals={false}
                strokeWidth={3}
                tickCount={8}
                tick={<CustomYAxisTick orientation={"right"} />}
                axisLine={{ strokeDasharray: "5 5" }}
                width={43}
                padding={{ top: 10, bottom: 20 }}
              />

              <YAxis
                yAxisId="right"
                stroke="#000"
                orientation="right"
                hide={true}
                domain={[0, 20]}
              />

              <ZAxis zAxisId="zAx" dataKey="z" range={[0, 1000]} />
              <CartesianGrid xAxisId={"mainX"} yAxisId={"left"} strokeDasharray="5 5" />
              <Tooltip content={<CustomTooltip />} />

              {!isLoading && ["noData", ...Object.values(calculatedModeTypes)].map((graphName: any, index: any) => {
                const graphNameCap = graphName.toUpperCase();
                const color: any = unitType === "outdoor" ? outdoorModeColors[graphNameCap] : modeColors[graphNameCap];

                return (
                  <Area
                    key={`modeGraph-${index}`}
                    xAxisId={"mainX"}
                    yAxisId="right"
                    type="step"
                    name={graphName}
                    dataKey={graphName}
                    data={graphsData}
                    stroke={color}
                    fill={color}
                    strokeWidth={1}
                    fillOpacity="1"
                    legendType="none"
                    dot={false}
                  />
                );
              })}

              {!isLoading && setpointChecked && (
                <Area
                  xAxisId={"mainX"}
                  yAxisId="left"
                  name={"setpoint"}
                  data={graphsData}
                  dataKey={SETPOINT_CODE}
                  type={"monotone"}
                  stroke={TempColors.setpoint.line}
                  fill={TempColors.setpoint.line}
                  dot={false}
                  opacity={0.2}
                />
              )}

              {!isLoading && ambientChecked && (
                <Area
                  xAxisId={"mainX"}
                  yAxisId="left"
                  name={"roomtemp"}
                  data={graphsData}
                  dataKey={ROOM_TEMP_CODE}
                  type={"monotone"}
                  stroke={TempColors.ambient.line}
                  fill={TempColors.ambient.line}
                  opacity={0.2}
                  dot={false}
                />
              )}
              {!isLoading && weatherChecked && (
                <Area
                  xAxisId={"mainX"}
                  yAxisId="left"
                  name={"sitetemp"}
                  data={
                    _.isEmpty(graphsData) && !isLoading
                      ? Object.values(siteExternalTemps)
                      : graphsData
                  }
                  dataKey={SITE_TEMP}
                  type={"monotone"}
                  stroke={TempColors.weather.line}
                  fill={TempColors.weather.line}
                  opacity={0.2}
                  dot={false}
                />
              )}

              {!isLoading &&
                selectedStatsSorted.length &&
                selectedStatsSorted.map((sensor: any, lineIndex: any) => {
                  const { id, type } = sensor;
                  const stroke: string = paramsColors.get(id);
                  const dataKey: string = type === "param" ? id : type === "sensor" ? "reading_value" : (id?.split("-")[1] === "p" ? "power" : "energy");
                  const strokeWidth: number = selectedGraph === id ? selectedLineWidth : lineWidth;
                  const originalID = type === "powerMeter" ? id.split("-")[0] : "";
                  const data: any = type === "param" ? graphsData : type === "sensor" ?
                    externalsData.sensors[id] : externalsData.powerMeters[originalID];
                  const isLeft = type === "powerMeter" ? (axisConfig[id] && axisConfig[id] === "left") : (axisConfig[dataKey] && axisConfig[dataKey] === "left");

                  return (
                    <Line
                      xAxisId={"mainX"}
                      yAxisId={isLeft ? "left" : "sec"}
                      key={`${id}-${lineIndex}`}
                      name={id === "qualityScore" ? "HVAC Comm Line quality" : id}
                      sensorId={id}
                      strokeDasharray={`5 ${!isLeft ? 8 : 0}`}
                      data={data}
                      dataKey={dataKey}
                      type={"monotone"}
                      stroke={stroke}
                      strokeWidth={strokeWidth}
                      dot={false}
                    />
                  );
                })}

              {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  xAxisId={"mainX"}
                  yAxisId="left"
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}

              {!isLoading &&
              alertsChecked &&
              alertsData.length &&
              alertsData.map((alert: any, alertIndex: any) => (
                <Scatter
                  key={`scatter-${alertIndex}`}
                  xAxisId={"mainX"}
                  yAxisId="right"
                  zAxisId="zAx"
                  name="errors"
                  shape={
                    <CriticalIcon
                      className={
                        classes[alert.alerttype.replace(/ /g, "_")] || ""
                      }
                    />
                  }
                  data={[alert]}
                  dataKey={"y"}
                  legendType={"none"}
                  onMouseEnter={(event: any) => {
                    setSelectedError(event);
                  }}
                  onMouseLeave={() => {
                    setSelectedError(null);
                  }}
                />
              ))}

              <Legend
                onClick={selectGraph}
                layout="horizontal"
                verticalAlign="top"
                align="left"
                content={<CustomizedLegend />}
                payload={
                  selectedStatsSorted.map((code: any) => {
                    if (code?.type) {
                      return { value: code.id, color: paramsColors.get(code.id), type: code.type };
                    }
                    return { value: code, color: paramsColors.get(code) };
                  })
                }
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div className={classes.legendOrthers}>
            <div className={classes.zoomControlsSection}></div>
            <Typography className={classes.zoomInstruc}>
              {t`Click and drag over an area of the graph to zoom`}
            </Typography>
            <div className={classes.zoomControlsSection}>
            <LightTooltip title={t`Click to Reset Zoom - this action allows you to revert to the original high-level view of the selected parameters`}>
              <div>
                <CButton
                  disabled={!zoomTime}
                  onMouseUp={() => setZoomTime(null)}
                >
                  {t`Reset Zoom`}
                </CButton>
              </div>
            </LightTooltip>
            </div>
          </div>
        </div>
      </Grid>
    </Fragment >
  );
};

export default UnitStatsGraph;
