import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100%"
  },
  contentArea: {
    width: "100%",
    height: "100vh",
    maxWidth: "calc(100% - 240px)",
    overflow: "hidden"
  },

  loadingContainer: {
    height: "100%",
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  headerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 1rem",
    height: "70px",
    background: theme.palette.background.default
  },

  wrapper: {
    /* 130px = headerMenu(70px) + headerContainer(60px) */
    height: "calc(100vh - 130px)",
    padding: "0 1rem 1rem",
    background: "rgb(236, 235, 239)",
    flexFlow: "row nowrap"
    // display: 'grid'
    // width: 'auto'
  },
  // buttonContainer: {
  //   justifySelf: 'flex-end',
  //   padding: '0 0 1rem'
  // },
  titleContainer: {
    paddingLeft: 20,
    paddingRight: 13,
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  headerTitle: {
    fontFamily: "RobotoMedium",
    color: "#29132e",
    lineHeight: "normal",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "normal"
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto"
  },

  // ---- Audits filters
  filterContainer: {
    height: "100%",
    paddingRight: "1rem",
    overflow: "auto"
  },

  filterGridItem: {
    height: "40%",
    background: theme.palette.background.paper,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    borderBottom: "1rem solid #efeff2"
  },

  filter: {
    height: "100%",
    overflow: "auto",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column"
  },

  // ---- Audits table
  tableWrapper: {
    // width: '100%',
    height: "100%"
    // marginLeft: '1rem',
    // flexGrow: 1,
  },

  tableContainer: {
    height: "100%",
    width: "100%"
    // padding: '0 1rem'
  },
  table: {
    // padding: '0 1rem',
    background: theme.palette.background.paper
  },
  tableHeadRow: {
    height: "3rem"
  },
  overWritePadding: {
    // fontSize: '0.8rem',
    // padding: '0 0.8rem'
  },
  tableHeadCell: {
    height: 45,
    ...theme.tables.headerCell,
    padding: "0 10px !important"
  },
  tableCell__icon: {
    width: 30,
    padding: "0 10px !important"
  },
  tableCell__date: {
    width: "24ch",
    paddingLeft: 0,
    padding: "0 10px !important"
  },
  tableCell__actor: {
    width: "20ch",
    padding: "0 10px !important"
  },
  tableCell__actionOnSubject: {
    width: "25ch",
    padding: "0 10px !important"
  },
  tableCell__subject: {
    width: "15ch"
  },
  tableCell__data: {
    width: "auto",
    minWidth: "17ch",
    padding: "0 10px !important"
  },
  tableCell__value: {
    padding: "0 5px 0 10px"
  },
  editIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "green"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },

  title: {
    borderBottom: "1px gray solid",
    marginBottom: "2rem",
    width: "100%",
    lineHeight: "50px"
  },
  addNewUser: {
    borderRadius: "4px",
    width: "15%",
    alignSelf: "flex-end",
    color: "#fff",
    background:
      "linear-gradient(-60deg, rgb(66, 26, 64) 50%, rgb(41, 19, 46) 50%)",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    textAlign: "center",
    marginRight: "1rem"
  },

  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconButton: {
    minWidth: "fit-content",
    padding: "0"
  },

  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
    width: "100%",
    lineHeight: "6rem",
    borderBottom: "1px rgb(87, 69, 92) solid",
    padding: "0 10px 0 10px",
    boxSizing: "border-box"
  },
  "[class^=\"NavigationBar\"]": {
    padding: 0
  },
  body: {
    background: "#fafafa"
  },
  emailColor: {
    color: "#0079ff"
  },
  button: {
    maxHeight: "23px"
  },
  itemButton: {
    display: "flex",
    alignItems: "center"
  },
  dialogTitle: {
    backgroundColor: "#f6f6f7",
    // color: "#29132e",
    color: "#4b1c46",
    borderBottom: "1px solid #d5d2d5",
    padding: "4px 24px"
  },

  container: {
    border: "1px solid #d5d2d5",
    borderRadius: "4px",
    padding: 10
  },
  auditContent: {
    padding: ".5rem"
  },
  rowTitle: {
    fontWeight: "bold",
    textTransform: "capitalize",
    width: "270px"
  },
  rowData: {
    width: "80%"
  },
  mainRow: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    padding: "5px 10px"
  },
  grayRow: { backgroundColor: "#efeff2" },
  shareButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "none",
    borderRadius: "6px",
    background: theme.gradiants.button,
    "&:hover": {
      boxShadow: "none"
    },
    "&:focus": {
      boxShadow: "none"
    }
  },
  scheduleIcon: {
    fontSize: 18,
    marginLeft: "3.5px",
    "&>g": {
      fill: "grey",
      width: "10px"
    }
  },
  homeIcon: {
    fontSize: 25
  },
  tableCell__unit: {
    width: "33ch",
    minWidth: "33ch",
    maxWidth: "33ch",
    padding: "0 10px !important"
  },
  tableHeadCell__icon: {
    width: "71px",
    minWidth: "71px",
    maxWidth: "71px"
  },
  tableCell__customer: {
    width: "21ch",
    minWidth: "21ch",
    maxWidth: "21ch",
    padding: "0 10px !important"
  },
  userIcon: { fontSize: 23, marginLeft: "1px" },
  hvacIcon: { fontSize: 21, marginLeft: "2px" },
  lcdIcon: { fontSize: 27, marginLeft: "1px" },
  iconWrapper: { display: "flex", width: 35, alignItems: "center" },
  noIcon: { width: 25, textAlign: "center" },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  searchIcon: {
    color: "#fff"
  },
  inputRoot: {
    color: theme.palette.colors.menuFont,
    fontFamily: "Roboto",
    fontSize: "13px",
    margin: "auto",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: theme.palette.colors.mainSearchBackground,
    maxWidth: "70%"
    // marginRight: "40px"
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  }
}));

export default useStyles;
