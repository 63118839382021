import Validator from 'validatorjs';

Validator.register(
  'include-capital',
  (value: any, args: any = 1, attribute: any) => {
    const capitalCharacters = value.toString().match(/[A-Z]/g);
    if (!capitalCharacters) {
      return false;
    }

    return capitalCharacters.length >= parseInt(args, 10);
  },
  'the :attribute must include :include-capital capital letters'
);

Validator.register(
  'include-lower-case',
  (value: any, args: any = 1, attribute: any) => {
    const capitalCharacters = value.toString().match(/[a-z]/g);
    if (!capitalCharacters) {
      return false;
    }

    return capitalCharacters.length >= parseInt(args, 10);
  },
  'the :attribute must include :include-lower-case lower case letters'
);

Validator.register(
  'include-numbers',
  (value: any, args: any = 1, attribute: any) => {
    const capitalCharacters = value.toString().match(/[0-9]/g);
    if (!capitalCharacters) {
      return false;
    }

    return capitalCharacters.length >= parseInt(args, 10);
  },
  'the :attribute must include :include-numbers numbers'
);

export function validatorForm(
  rules: {},
  customErrorMessages?: {} | undefined,
  attributeNames?: {} | undefined
) {
  return (data: {}) => {
    const validator = new Validator(data, rules, customErrorMessages);

    if (attributeNames) {
      validator.setAttributeNames(attributeNames);
    }

    return validator;
  };
}
