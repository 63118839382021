import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {t} from 'ttag';

import CheckboxesList from '@components/CheckboxesList';

import useStyles from '../../AddEditDialog.style'; 
import { useStoreState } from '../../../../../../../models/RootStore';

const dataReportTypesNames = {
	modeHours: t`Overall Cooling & Heating hours`,
	powerConsumption: t`Power Consumption*`,
	eventCountBySystem: t`Alerts Statistics by System`,
	systemDiversity: t`System Diversity Indication`,
	unitStandbyTime: t`Unit Standby Time`,
	setpointMaintenance: t`Setpoint Maintenance`
};

const getState = (state) => {
	const types = state.types ?? [];
	return {
		types
	};
};


//todo: consider replacing this render with a reusable component that renders a List of checkboxes.
//todo: add Jsdoc where missing.
const ReportDataSelector = ({onChange,value,error, disabled}) => {

	const classes = useStyles();

	const {types} = useStoreState(getState);

	/**
    * An array of options, each with key, checked, id, and text properties, optimized with useMemo.
    */
	const options = useMemo(() => {

		const {dataReportTypes} = types;
		
		return Object.entries(dataReportTypes).map(([key, id]) => {
			return {
				value:id,
				text:dataReportTypesNames[key],
				id
			};
		});	
	},[types]);


	return (
		<div className='report-data-selector'>
			<Typography className={classes.title}>{t`Select Data`}</Typography>
															
			<CheckboxesList 
				options={options}
				value={value}
				onChange={onChange}
				selectAllOnMount={true}
				error={error}
				disabled={disabled}
				listType={'grid'}
			/>
		</div>
	);
};

ReportDataSelector.propTypes = {
	onChange:PropTypes.func.isRequired,
	value:PropTypes.oneOfType([PropTypes.array,PropTypes.string]),
	error:PropTypes.string,
	disabled:PropTypes.bool
};

ReportDataSelector.defaultProps = {
	value:[],
	error:'',
	disabled:false

};

export default ReportDataSelector;