import { useEffect, useState, useCallback, useRef } from 'react';

const useQuery = ({
  queryKey,
  queryFn,
  onSuccess,
  onError,
  enabled = true,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Store the query key in a ref and track its changes internally
  const queryKeyRef = useRef(queryKey);
  const hasQueryKeyChanged = useRef(false);

  // Check if queryKey has actually changed
  useEffect(() => {
    const currentKeyString = JSON.stringify(queryKey);
    const previousKeyString = JSON.stringify(queryKeyRef.current);

    if (currentKeyString !== previousKeyString) {
      queryKeyRef.current = queryKey;
      hasQueryKeyChanged.current = true;
    }
  }, [queryKey]);

  const fetchData = useCallback(async () => {
    // Prevent concurrent requests
    if (isLoading) {
      return;
    }

    // Reset the change flag before fetching
    hasQueryKeyChanged.current = false;

    setIsLoading(true);
    setError(null);

    try {
      const result = await queryFn();
      setData(result);
      onSuccess?.(result);
    } catch (err) {
      setError(err);
      onError?.(err);
    } finally {
      setIsLoading(false);
    }
  }, [queryFn, onSuccess, onError, isLoading]); // Added isLoading to dependencies

  // Effect for handling initial fetch and queryKey changes
  useEffect(() => {
    if (enabled && (hasQueryKeyChanged.current || data === null)) {
      fetchData();
    }
  }, [enabled, queryKey, fetchData]);

  const refetch = useCallback(() => {
    return fetchData();
  }, [fetchData]);

  return { data, error, isLoading, refetch };
};

export default useQuery;
