import {
  CircularProgress,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { ArrowDownO, Close, Download } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./UnassignedUnitsDialog.style";

export default (function UnassignedUnitsDialog(props: any) {
  const { close, deviceId, deviceName, actions } = props;
  const classes = useStyles();

  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const updateMultiUnitAPI = useStoreActions((actions) => actions.units.updateMultiUnit);
  const getDeviceUnits = useStoreActions((actions) => actions.devices.getDeviceUnitsAPI);
  const types = useStoreState((state) => state.types);
  const { unitTypes } = types;

  const [units, setUnits] = useState<any>({});
  const [changes, setChanges] = useState<any>({ selectedOptions: {}, controlsChanges: {} });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);
  const [unitsOptionsPerLine, setUnitsOptionsPerLine] = useState<any>({});
  const [unmappedUnits, setUnmappedUnits] = useState<any>([]);

  useEffect(() => {
    if (!deviceId) {
      return;
    }

    getDeviceUnits({ id: deviceId }).then((unitsResp: any) => {
      const unmappedServiceAndOtherUnitsPerLine: any = {};
      const unmappedControlUnitsPerLine: any = {};

      const unitsTemp: any = [];
      Object.values(unitsResp).forEach((unit: any) => {
        if (unitTypes["indoor"] === unit.type && !unit?.serviceUnits?.length && !unit?.otherUnits?.length) {
          const { line, id, address } = unit;
          if (unmappedControlUnitsPerLine[line]) {
            unmappedControlUnitsPerLine[line] = [...unmappedControlUnitsPerLine[line], { id, address, line }];
          } else {
            unmappedControlUnitsPerLine[line] = [{ id, address, line }];
          }
        }

        if ((unitTypes["service"] === unit.type || unitTypes["other"] === unit.type) && !unit?.controlUnit) {
          const { line, id, name } = unit;
          units[id] = unit;
          if (unmappedServiceAndOtherUnitsPerLine[line]) {
            unmappedServiceAndOtherUnitsPerLine[line] = [...unmappedServiceAndOtherUnitsPerLine[line], { value: id, key: id, name }];
          } else {
            unmappedServiceAndOtherUnitsPerLine[line] = [{ name: t`Select unit`, value: "", key: "none" }, { value: id, key: id, name }];
          }
        }
      });

      const unmappedUnits: any = [];
      Object.values(unmappedControlUnitsPerLine).forEach((units: any) => unmappedUnits.push(...units));

      setUnmappedUnits(unmappedUnits);
      setUnitsOptionsPerLine(unmappedServiceAndOtherUnitsPerLine);
      setIsLoading(false);
      setUnits(units);
    })
      .catch((err: any) => addMessage({ message: err.message }));
  }, [deviceId]);

  const save = async () => {
    setSaveIsLoading(true);
    const unitsChanages: any = [];
    for (let id in changes.selectedOptions) {
      unitsChanages.push({ id, associatedUnit: changes.selectedOptions[id] });
    }
    if (unitsChanages.length) {
      await updateMultiUnitAPI(unitsChanages);
      actions.fetchData().finally(() => close());
    }
  };

  const handleSelectChange = (controlId: any, unitId: string) => {
    if (changes.controlsChanges[controlId]) {
      const oldUnitId = changes.controlsChanges[controlId];
      delete changes.selectedOptions[oldUnitId];
    }
    changes.selectedOptions[unitId] = controlId;
    changes.controlsChanges[controlId] = unitId;
    setChanges({ ...changes });
  };

  return (
    <Dialog
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="unassign-dialog"
      open={deviceId}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`Unmapped Control IDs`} - {deviceName} {t`Device`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}><Close color="#7f7692" /></IconButton>
      </div>
      <div className={classes.dialogContent}>
        {isLoading ? <div className={classes.loaderHolder}><CircularProgress /></div> : <TableContainer className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.tableHeadCell }} style={{ minWidth: 250 }} align="left">
                  {t`Central Id`}
                </TableCell>
                <TableCell classes={{ root: classes.tableHeadCell }} style={{ width: 200 }} align="left">
                  {t`Service/Other Unit`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unmappedUnits.map((unit: any, index: number) => {
                const { address, line, id, canUpdate = true } = unit;
                const options = (!unitsOptionsPerLine[line] || unitsOptionsPerLine[line]?.length < 2) ?
                  [{ name: t`No Options`, value: "", key: "none" }] : unitsOptionsPerLine[line];

                return (
                  <TableRow key={`unit-row-${id}`} classes={{ root: classes.overWritePaddingRow }}>
                    <TableCell classes={{ root: classes.overWritePadding }} align="left">
                      {address}
                    </TableCell>
                    <TableCell classes={{ root: classes.overWritePadding }} align="left">
                      <Select
                        displayEmpty
                        onChange={(event: any) => handleSelectChange(id, event.target.value)}
                        variant="outlined"
                        disableUnderline
                        classes={{ icon: classes.arrowDownIcon }}
                        IconComponent={ArrowDownO}
                        className={classes.selectStyle}
                        value={changes.controlsChanges[id] || ""}
                        MenuProps={{
                          anchorOrigin: { vertical: "bottom", horizontal: "left" },
                          transformOrigin: { vertical: "top", horizontal: "left" },
                          getContentAnchorEl: null
                        }}
                        disabled={options.length < 2 || saveIsLoading || !canUpdate}
                      >
                        {options.map((option: any) => (
                          <MenuItem
                            key={option.key}
                            value={option.value}
                            className={clsx({ [classes.hidden]: option.value === "" })}
                            disabled={changes.selectedOptions[option.value]}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select >
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>}
        <div className={classes.actionsHolder}>
          {saveIsLoading && <CircularProgress className={classes.loader} />}
          <Button width={160} white marginRight onMouseDown={close}>{t`Cancel`}</Button>
          <Button width={160} onMouseDown={save} disabled={isLoading || saveIsLoading}>{t`Save`}</Button>
        </div>
      </div>
    </Dialog>
  );
});
