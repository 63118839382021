import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  dialogPaper: {
    width: "850px",
    maxWidth: "95% !important",
    maxHeight: "calc(100vh - 140px)",
    borderRadius: "8px",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #d5d2d5"
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    height: "60px",
    alignItems: "center",
    ...theme.dialogs.header
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  actionsContainer: {
    padding: "20px",
    display: "flex",
    justifyContent: "flex-end"
  },
  table: {
    background: theme.palette.background.paper
  },
  iconBtnStyle: {
    width: "30px",
    height: "30px",
    borderRadius: "6px",
    padding: 0
  },
  paperTableContainer: {
    flex: "1 1 auto",
    padding: "20px",
    minHeight: "250px",
    overflowY: "auto"
  },
  overWritePadding: {
    padding: "0 15px",
    ...theme.tables.tableCell
  },
  tableHeadCell: {
    padding: "0 15px",
    height: "45px",
    ...theme.tables.headerCell
  },
  tableContainer: {
    height: "100%"
  },
  mediumWidth: {
    width: "60%",
    wordBreak: "break-word"
  },
}));

export default useStyles;
