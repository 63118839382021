import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { t } from 'ttag';
import _ from 'lodash';
import CheckboxesList from '@components/CheckboxesList';


/**
 * @typedef {Object} SystemSelectorProps
 * @property {Object} classes - The CSS classes applied to the component.
 * @property {Function} onChange - The callback function to handle the change event when the selected systems change.
 * @property {Array<import('@models/Systems').ISystem>} [systems=[]] - The array of system objects to be displayed in the list.
 * @property {Array<string>} [value=[]] - The array of selected system values.
 * @property {boolean} [disabled=false] - Indicates whether the selection is disabled.
 * @property {string} [error=''] - The error message to be displayed.
 */

/**
 * SystemSelector component allows users to select multiple systems from a list.
 *
 * @param {SystemSelectorProps} props - The props for the component.
 */
const SystemSelector = ({classes,value,onChange,disabled,systems,error}) => {

	/**
   * Memoized array of systems, sorted alphabetically and filtered for valid entries.
   */
	const options = useMemo(()=>{
		const sortedSystems = _.orderBy(systems, [(item) => item?.name?.toUpperCase()],['asc']).filter((system)=> !!system);
		return sortedSystems.map(system => ({...system, text:system.name,value:system.id}));
	},[systems]);

	return (
		<div className={classes.middleSideSection}>
			<Typography className={classes.sectionTitle}>{t`Select systems`}</Typography>
			<div className={classes.middleSideSectionScrollable}>
				<CheckboxesList
					options={options}
					value={value}
					onChange={onChange}
					disabled={disabled}
					selectAllOnMount={true}
					selectAllLabel={t`All Systems`}
				/>
			</div>
			<Typography className={classes.error}>{error}</Typography>
		</div>
	);
};

SystemSelector.propTypes = {
	classes:PropTypes.object.isRequired,
	onChange:PropTypes.func.isRequired,
	systems:PropTypes.array,
	value:PropTypes.array,
	disabled:PropTypes.bool,
	error:PropTypes.string
};

SystemSelector.defaultProps = {
	systems:[],
	value:[],
	disabled:false,
	error:''
};

export default SystemSelector;
