import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import LightTooltip from "../../../components/Tooltip/LightTooltip";
import { QuestionMark as SvgQuestionMark } from "../../../icons/";
import { ISite } from "../../../models/Sites";
import assetsService from "../../../services/assetsService";
import useStyles from "./RegisterDevice.style";
import { useStoreState } from "../../../models/RootStore";
import { DRL } from "../SiteManagement";

interface IProps {
  site: ISite;
  isLoading: boolean;
  className?: string;
  deviceBrandSelected: number;
  setDeviceBrandSelected: (id: any) => void;
  deviceBrands: any;
  devicesRegistrationsInfoRequeried: { [deviceBrand: string]: Array<{ id: string; label: string; isNotRequired?: boolean; }> };
  newDeviceRegistrationData: any;
  setNewDeviceRegistrationData: any;
  replaceExistingDevice: boolean;
  oldReplacedDevice: string;
  setReplaceExistingDevice: any;
  setOldReplacedDevice: any;
  siteDevices: any;
  confirmCheck: boolean;
  setConfirmCheck: (value: boolean) => void;
}

const RegisterDevice: React.FC<IProps> = (props) => {
  const {
    site,
    newDeviceRegistrationData,
    setNewDeviceRegistrationData,
    devicesRegistrationsInfoRequeried,
    deviceBrandSelected,
    replaceExistingDevice,
    oldReplacedDevice,
    setReplaceExistingDevice,
    setOldReplacedDevice,
    siteDevices,
    confirmCheck,
    setConfirmCheck
  } = props;
  const classes: any = useStyles();
  const brandNames = Object.entries(props?.deviceBrands || {})
    .reduce((acc: any, [brandName, brandNo]) => {
      acc["" + brandNo] = brandName[0].toUpperCase() + brandName.slice(1);
      return acc;
    }, {});

  const config = useStoreState(state => state.config)

  const devicesOptions = siteDevices && siteDevices.map((device: any) => <MenuItem value={device.id} key={`deviceOption-${device.id}`}>{device.serial}</MenuItem>);

  return (
    <Grid container spacing={3} direction="row" className={props.className && classes[props.className]}>
      <Modal className={classes.loadingModal} open={props.isLoading}>
        <CircularProgress classes={{ root: classes.root }} size={240} />
      </Modal>
      <Grid item xs={6}>
        <div>
          <Typography align="center" className={classes.title} variant="h4">
            {t`Let's connect`}
          </Typography>
          <Typography align="center" className={classes.title} variant="h4">
            {t`your HVAC System`}
          </Typography>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.imgContainer}>
          <img
            className={classes.deviceImg}
            src={assetsService.getImagesPathFromDomainFolder("cmnet.png")}
          ></img>
        </div>
      </Grid>
      <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2} style={{ paddingBottom: "15px" }}>
          <Grid item xs={12} className={classes.formWrapper}>
            <Typography variant="h5">{(deviceBrandSelected !== 0 && brandNames[deviceBrandSelected] + t` Cloud integration`) || t`Add Device`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.selectField} >
              <InputLabel id="device-brand-label">Device brand</InputLabel>
              <Select
                id={"deviceBrandName"}
                name={"deviceBrandName"}
                value={deviceBrandSelected}
                onChange={(event) => {
                  props.setDeviceBrandSelected(event.target.value);
                  replaceExistingDevice && setReplaceExistingDevice(false);
                  oldReplacedDevice && setOldReplacedDevice("");
                }}
                labelId={`device-brand-label`}
                label={DRL.APIKey}
                MenuProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  getContentAnchorEl: null
                }}
              >
                {Object.entries(props.deviceBrands || {}).map(([deviceBrandName, deviceBrandId]: any) => (
                  <MenuItem id={`brand-${deviceBrandId}`} key={deviceBrandId} value={deviceBrandId} className={classes.MenuItem}>
                    {deviceBrandName !== "ca" ? deviceBrandName + t` Cloud integration` : (config?.deviceBrandName ? config?.deviceBrandName : "CoolAutomation")}
                  </MenuItem>
                )) || <MenuItem value={0} >{config?.deviceBrandName ? config?.deviceBrandName : "CoolAutomation"}</MenuItem>
                }
              </Select >
            </FormControl>
          </Grid>
          {devicesRegistrationsInfoRequeried[deviceBrandSelected].map(({ id, label, isNotRequired }) =>
          (<Grid item key={id} xs={12}>
            <FormControl variant="filled" className={classes.textField} >
              <TextField
                id={`RegField-${id}`}
                required={!isNotRequired}
                error={!isNotRequired && newDeviceRegistrationData[id] === ""}
                value={newDeviceRegistrationData[id]}
                onChange={(event: any) => setNewDeviceRegistrationData({ ...newDeviceRegistrationData, [id]: event.target.value })}
                margin="dense"
                variant="filled"
                label={label}
                InputProps={{
                  endAdornment: (
                    label !== DRL.Location && <InputAdornment position="end">
                      <LightTooltip title={t`You can find the ` + label + t` on the back of the device`}>
                        <IconButton disableRipple aria-label={label} classes={{ root: classes.iconButton }}>
                          <SvgQuestionMark style={{ color: "#aaa2aa" }} />
                        </IconButton>
                      </LightTooltip>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
          )
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="replaceExistingDevice"
                  color={"default"}
                  checked={replaceExistingDevice}
                  onChange={() => setReplaceExistingDevice(!replaceExistingDevice)}
                  value="replaceExistingDevice"
                  disabled={deviceBrandSelected !== 0}
                />
              }
              label={t`Replace existing device`}
            />
          </Grid>
          {(deviceBrandSelected === 0 && replaceExistingDevice) &&
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.selectField}>
                <InputLabel id={"old-device"}>{!!siteDevices?.length ? t`Select device to replace` : t`No Devices`}</InputLabel>
                <Select
                  id="oldDevice"
                  name="device"
                  disabled={!siteDevices?.length}
                  value={oldReplacedDevice}
                  onChange={(event) => setOldReplacedDevice(event.target.value)}
                  labelId={`old-device`}
                  label={!!siteDevices?.length ? t`Select device to replace` : t`No Devices`}
                  MenuProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                    getContentAnchorEl: null
                  }}
                >
                  {devicesOptions}
                </Select>
              </FormControl>
            </Grid>
          }
        </Grid>
        <Grid container >
          <FormControlLabel
            control={
              <Checkbox
                id={"confirmCheck"}
                color={"default"}
                checked={confirmCheck}
                onChange={() => setConfirmCheck(!confirmCheck)}
              />
            }
            classes={{ label: classes.lableStyle, root: !confirmCheck && classes.checkBoxRed }}
            label={t`I confirm I have the official approval to connect to the HVAC system using this device and start pulling all technical and control data using this solution.`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegisterDevice;
