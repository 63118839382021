import {
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import {
  CoolModeOff as SvgCoolModeOff,
  CoolModeOn as SvgCoolModeOn,
  Disconnect as SvgDisconnect,
  HeatModeOff as SvgHeatModeOff,
  HeatModeOn as SvgHeatModeOn,
  OnGrey as SvgPowerOff,
  OnWhite as SvgPowerOn
} from "../../icons/";
import { ScheduleOffIcon } from "../../icons/index";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import { systemViewStyle } from "./SystemViewMgmt.style";

interface IProps {
  device: any;
  actions: any;
  hasOnlyControlUnit: boolean;
}

const SystemsView = (props: IProps) => {
  const classes = systemViewStyle();
  const { device, actions, hasOnlyControlUnit = false } = props;

  const {
    overWriteDisplay,
    iconButton,
    powerButton,
    powerButtonDisabled,
    PowerButtonOperationStatusContainer,
    systemContainer,
    systemName,
    logo,
    groupIcons,
    nameModelContainer,
    powerButtonSVG,
    modeButton,
    powerOff,
    powerOn,
    modeButtonDisabled,
    indoorOutdoorContainer,
    outdoor,
    indoor,
    controlContainer
  } = classes;

  const deleteSystem = useStoreActions((action) => action.deleteSystem);
  const types = useStoreState((state) => state.types);
  const { hvacBrands, operationModesExtended: typesOperationModes, operationStatuses: typesOperationStatuses } = types;
  const altermaBrandNum = hvacBrands?.filter((brand: any) => brand?.type?.toUpperCase() === "ALTHERMA")[0]?.value || 10;
  const allSystems = useStoreState((state) => state.systems.allSystems);

  const delSystem = (idObj: any) => {
    deleteSystem(idObj)
      .then(() => {
        actions.fetchData();
      });
  };

  if (_.isUndefined(device)) {
    return null;
  }

  const { systems, numOfSensors, numOfPowerMeters, id: deviceId } = device;
  const {
    changeSystemMode,
    changeSystemStatus
  } = actions;

  return (
    <>
      {_.sortBy(systems, "name").map((system: any, i: number) => {
        const {
          name,
          brandNum,
          numOfControlUnits,
          numOfOutdoorUnits,
          numOfBsBoxUnits,
          numOfServiceUnits,
          operationMode: operationModeSystem,
          operationStatus: operationStatusSystem,
          id,
          hasDisconnectedUnits,
          isMapping,
          schedules = [],
          permissions = {}
        } = system;
        const { canControl, canDelete, canUpdate, canCreateSchedules } = permissions;

        if (!allSystems[id]) {
          return;
        }
        const { operationMode = operationModeSystem, operationStatus = operationStatusSystem } = allSystems[id];

        const isWaterHeater = _.find(hvacBrands, { value: brandNum })
          ? _.find(hvacBrands, { value: brandNum }).isWaterHeater
          : false;
        const isPowerOn = typesOperationStatuses[operationStatus] === "on";
        const isSystemInHeatMode = typesOperationModes[operationMode] === "HEAT";
        const isSystemInCoolMode = typesOperationModes[operationMode] === "COOL";
        const systemHasControlUnits = !!numOfControlUnits;
        const isAlterma = +brandNum === +altermaBrandNum;
        const hasSchedules = schedules.length > 0;

        return (
          <Grid key={`system-details-${i}`} className={systemContainer}>
            <Grid item xs={8} classes={{ root: overWriteDisplay }}>
              <Typography className={systemName}>
                {name}
              </Typography>
              <div className={groupIcons}>
                <LightTooltip title={t`Edit System`}>
                  <IconButton disableRipple disabled={!canUpdate} onClick={() => actions.openAddEditSystem(deviceId, id, false)} className={classes.iconBtnStyle}>
                    <EditIcon />
                  </IconButton>
                </LightTooltip>
                <Delete
                  disabled={!canDelete}
                  classes={{ root: iconButton }}
                  type={t`System`}
                  object={system}
                  detach={delSystem}
                  isMapping={isMapping}
                ></Delete>
              </div>
            </Grid>

            <div className={nameModelContainer}>
              <Grid item xs={5} className={classes.minLogoHeight}>
                {brandNum ? (
                  <img
                    height={25}
                    src={assetsService.getBrandLogo(`${brandNum}-small.svg`)}
                    className={logo}
                    onError={(event: any) =>
                      (event.target.src = assetsService.getBrandLogo("empty.png"))
                    }
                    alt={
                      _.find(hvacBrands, { value: brandNum })
                        ? _.find(hvacBrands, { value: brandNum }).name
                        : ""
                    }
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={7} style={{ textAlign: "right" }}>
                {hasDisconnectedUnits && <SvgDisconnect />}
              </Grid>
            </div>

            <div className={indoorOutdoorContainer}>
              {!isAlterma && !hasOnlyControlUnit ? <Grid item xs={4}>
                <Typography className={clsx("", outdoor)} variant="body1">
                  <span>

                    {
                      numOfOutdoorUnits
                    }
                    {t` Outdoor`}
                  </span>
                </Typography>
              </Grid>
                : <span />}
              <Grid item xs={4}>
                <Typography className={clsx("", indoor)} variant="body1">
                  <span>
                    {
                      numOfControlUnits
                    }
                    {t` Indoor `}
                  </span>
                </Typography>
              </Grid>
              {
                numOfBsBoxUnits && numOfBsBoxUnits > 0 && !isAlterma ?
                  <Grid item xs={4}>
                    <Typography className={clsx("", indoor)} variant="body1">

                      <span>
                        {
                          numOfBsBoxUnits
                        }
                        {t` BS Box `}
                      </span>
                    </Typography>
                  </Grid> : <></>}
            </div>

            <Grid item xs={6} classes={{ root: PowerButtonOperationStatusContainer }}>
              <Grid item>
                {isSystemInCoolMode ? (
                  <SvgCoolModeOn onClick={() => !canControl ? {} : changeSystemMode(id, "COOL")} className={clsx(modeButton, (!canControl || !systemHasControlUnits) && modeButtonDisabled)} />
                ) : (
                  <SvgCoolModeOff
                    onClick={() => !canControl ? {} : changeSystemMode(id, "COOL")}
                    className={clsx(modeButton, (!canControl || !systemHasControlUnits) && modeButtonDisabled)}
                  />
                )}
                {isSystemInHeatMode ? (
                  <SvgHeatModeOn onClick={() => !canControl ? {} : changeSystemMode(id, "HEAT")} className={clsx(modeButton, (!canControl || !systemHasControlUnits) && modeButtonDisabled)} />
                ) : (
                  <SvgHeatModeOff onClick={() => !canControl ? {} : changeSystemMode(id, "HEAT")} className={clsx(modeButton, (!canControl || !systemHasControlUnits) && modeButtonDisabled)} />
                )}
              </Grid>
              {!isWaterHeater &&
                <IconButton disableFocusRipple disableRipple onClick={() => !canControl ? {} : actions.openSystemSchedule(id, deviceId, false)} name="schedule" classes={{ root: classes.scheduleIconBtn }}>
                  <ScheduleOffIcon disabled={!canCreateSchedules} color="#AAA2AA" className={classes.scheduleIcon} />
                  {hasSchedules && <span className={classes.badge}>{schedules.length}</span>}
                </IconButton>
              }
              <div className={controlContainer}>
                <IconButton onClick={() => !canControl ? {} : changeSystemStatus(id, operationStatus)} className={clsx(powerButton, (!canControl || !systemHasControlUnits) && powerButtonDisabled)}>
                  {isPowerOn ? (
                    <div className={powerOn}>
                      <SvgPowerOn className={powerButtonSVG} />
                    </div>
                  ) : (
                    <div className={powerOff}>
                      <SvgPowerOff className={powerButtonSVG} />
                    </div>
                  )}
                </IconButton>
              </div>
            </Grid>

          </Grid>
        );
      })}

      {+numOfSensors > 0 &&
        <Grid key={`ppd-details`} className={classes.virtualSystemContainer}>
          <Grid item xs={8} classes={{ root: classes.overWriteDisplayVirtualSystems }}>
            <Typography className={systemName || ""}>
              {t`Aux Devices`}
            </Typography>

            <div className={groupIcons}>
              <LightTooltip title={t`Edit System`}>
                <IconButton disableRipple disabled={!device?.permissions?.canUpdate} onClick={() => actions.openEditSensors(deviceId)} className={classes.iconBtnStyle}>
                  <EditIcon />
                </IconButton>
              </LightTooltip>
            </div>
          </Grid>
          <div className={indoorOutdoorContainer}>
            <Grid item xs={6}>
              <Typography className={clsx("", outdoor)} variant="body1">
                <span>
                  {numOfSensors}
                  {t` Devices`}
                </span>
              </Typography>
            </Grid>
          </div>
        </Grid>}

      {numOfPowerMeters > 0 &&
        <Grid key={`ppd-details`} className={classes.virtualSystemContainer}>
          <Grid item xs={8} classes={{ root: classes.overWriteDisplayVirtualSystems }}>
            <Typography className={systemName || ""}>
              {t` Power Meters`}
            </Typography>
            <div className={groupIcons}>
              <LightTooltip title={t`Edit System`}>
                <IconButton disableRipple disabled={!device?.permissions?.canUpdate} onClick={() => actions.openEditPPD(deviceId)} className={classes.iconBtnStyle}>
                  <EditIcon />
                </IconButton>
              </LightTooltip>
            </div>
          </Grid>
          <div className={indoorOutdoorContainer}>
            <Grid item xs={6}>
              <Typography className={clsx("", outdoor)} variant="body1">
                <span>
                  {numOfPowerMeters}
                  {t` Power Meters`}
                </span>
              </Typography>
            </Grid>
          </div>
        </Grid>
      }

      {
        Object.keys(device.unassignedLines).map((line: any) => {
          return (
            <Grid key={`unassigned-line-${line}`} className={systemContainer} style={{ backgroundColor: "#fff0f0" }}>
              <Grid item xs={8} classes={{ root: overWriteDisplay }}>
                <Typography className={systemName}>
                  {`Line ${line} unassigned Units`}
                </Typography>
                <div className={groupIcons}>
                  <LightTooltip title={t`Edit System`}>
                    <IconButton disableRipple disabled={!device?.permissions?.canUpdate} onClick={() => actions.openCloseAssignLineUnits(deviceId, line)} className={classes.iconBtnStyle}>
                      <EditIcon />
                    </IconButton>
                  </LightTooltip>
                </div>
              </Grid>

              <div className={indoorOutdoorContainer}>
                {!!device.unassignedLines[line]?.outdoor &&
                  <Grid item xs={6}>
                    <Typography className={clsx("", outdoor)} variant="body1">
                      <span>
                        {
                          device.unassignedLines[line]?.outdoor
                        }
                        {t` Outdoor`}
                      </span>
                    </Typography>
                  </Grid>
                }
                {!!device.unassignedLines[line]?.control && <Grid item xs={6}>
                  <Typography className={clsx("", indoor)} variant="body1">
                    <span>
                      {
                        device.unassignedLines[line]?.control
                      }
                      {t` Indoor `}
                    </span>
                  </Typography>
                </Grid>}
                {!!device.unassignedLines[line]?.service && <Grid item xs={6}>
                  <Typography className={clsx("", indoor)} variant="body1">
                    <span>
                      {
                        device.unassignedLines[line]?.service
                      }
                      {t` Service `}
                    </span>
                  </Typography>
                </Grid>}
                {!!device.unassignedLines[line]?.bsbox &&
                  <Grid item xs={3}>
                    <Typography className={clsx("", indoor)} variant="body1">
                      <span>
                        {
                          device.unassignedLines[line]?.bsbox
                        }
                        {t` BS Box `}
                      </span>
                    </Typography>
                  </Grid>}
              </div>
            </Grid>
          );
        })
      }
    </>
  );
};

export default SystemsView;
