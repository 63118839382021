import { Checkbox, Dialog, FormControlLabel, IconButton, InputLabel, Menu, MenuItem, Slide, Switch, TextField, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { File as sdkFile, Sensor as sdkSensor, Site as sdkSite } from "coolremote-sdk";
import _ from "lodash";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import FloorPlanItem from "../../cool_widgets/FloorPlanItem/FloorPlanItem";
import { Plus, UploadFile } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import LightTooltip from "../Tooltip/LightTooltip";
import useStyles from "./style";
import { generateRandomString } from "../../utils/CommonUtils";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const AddEditFloorPlan = ({
    data,
    siteId,
    actions,
    isOpen
}: any) => {

    const classes = useStyles();

    const [newUnit, setNewUnit] = useState<any>(null);
    const [planData, setPlanData] = useState<any>(null);
    const [tempData, setTempData] = useState<any>(null);
    const [discardDialog, setDiscardDialog] = useState<boolean>(false);
    const [options, setOptions] = useState<any>([]);
    const [floorPlansOptions, setFloorPlansOptions] = useState<any>([]);
    const [floorPlans, setFloorPlans] = useState<any>({});
    const [tempEditUnit, setTempEditUnit] = useState<any>(null);
    const [editUnit, setEditUnit] = useState<any>(null);
    const [units, setUnits] = useState<any>({});
    const [groups, setGroups] = useState<any>({});
    const [sensors, setSensors] = useState<any>({});
    const [sensorsOptions, setSensorsOptions] = useState<any>([]);
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const { unitTypes } = useStoreState((state) => state.types);
    const allSites = useStoreState((state) => state.sites.allSites);
    const updateSiteState = useStoreActions((actions) => actions.sites.updateSiteState);

    useEffect(() => {
        if (!siteId || !planData?.id) {
            return;
        }
        const site = allSites[siteId];
        setIsDefault(site?.defaultFloorPlan === planData?.id);
    }, [siteId, planData?.id]);

    useEffect(() => {
        if (!siteId || !planData?.id) {
            return;
        }

        Promise.allSettled([sdkSite.getUnits(siteId), sdkSite.getGroups(siteId)])
            .then(([unitsResp, groupsResp]: any) => {
                const options: any = [];
                if (groupsResp.status === "fulfilled") {
                    const sortedGroups: any = _.orderBy(Object.values(groupsResp.value), "name", "asc");
                    for (let group of sortedGroups) {
                        options.push({
                            label: group.name,
                            value: group.id,
                            type: "Groups"
                        });
                    }
                }

                if (unitsResp.status === "fulfilled") {
                    const controlUnits = Object.values(unitsResp?.value).filter((u: any) => +u.type === +unitTypes["indoor"]);
                    const sorted: any = _.orderBy(controlUnits, "name", "asc");
                    for (let unit of sorted) {
                        if (!unit.isVisible) {
                            continue;
                        }
                        options.push({
                            label: unit.name,
                            value: unit.id,
                            type: "Units"
                        });
                    }
                }

                setOptions(options);
                setUnits(unitsResp?.value);
                setGroups(groupsResp);
            });

        sdkSite.getFloorPlans(siteId)
            .then((resp: any) => {
                const options: any = [];
                const floorPlansObj: any = {};
                const sortedFloorPlans: any = _.orderBy(resp, "name", "asc");

                for (let fp of sortedFloorPlans) {
                    floorPlansObj[fp.id] = fp;
                    if (fp?.id !== planData?.id) {
                        options.push({
                            label: fp.name,
                            value: fp.id
                        });
                    }
                }
                setFloorPlansOptions(options);
                setFloorPlans(floorPlansObj);
            });

        sdkSensor.getSiteSensors(siteId)
            .then((resp: any) => {
                const options: any = [];
                const sortedSensors: any = _.orderBy(resp, "name", "asc");

                for (let sensor of sortedSensors) {
                    options.push({
                        label: sensor.name,
                        value: sensor.id
                    });

                }
                setSensorsOptions(options);
                setSensors(resp);
            });

    }, [siteId, planData?.id]);

    useEffect(() => {
        setPlanData(data);
        setTempData(data);
    }, [data]);

    const prevState: any = usePrevious(newUnit);
    useEffect(() => {
        if (!prevState || prevState?.unitId === newUnit?.unitId || !newUnit) {
            return;
        }
        addUnit();
    }, [newUnit]);

    const addNewUnitToFloorPlan = () => {
        setNewUnit({
            x: 95,
            y: 0,
            isMove: true,
            width: 120,
            height: 65,
            isNew: true
        });
    };

    const addSensor = () => {
        setNewUnit({
            x: 95,
            y: 0,
            isMove: true,
            width: 120,
            height: 65,
            type: "sensor",
            isNew: true
        });
    };

    const linkFloorPlan = () => {
        setNewUnit({
            x: 95,
            y: 0,
            isMove: true,
            width: 120,
            height: 65,
            type: "link",
            isNew: true
        });
    };

    const moveUnit = (event: any) => {
        if (!newUnit?.isMove) {
            return;
        }
        let bounds: any = document?.getElementById("floorImgCont")?.getBoundingClientRect();
        if (!bounds) {
            return;
        }

        let x = ((event.clientX - bounds.left) / bounds.width) * 100;
        let y = ((event.clientY - bounds.top) / bounds.height) * 100;
        if (x <= 0 || y <= 0) {
            return;
        }
        setNewUnit({ ...newUnit, x, y });
    };

    const positionUnit = (event: any) => {
        if (!newUnit) {
            return;
        }
        if (newUnit.reposition) {
            addUnit();
            return;
        }
        setNewUnit({ ...newUnit, isMove: false, newConfig: true });
    };

    const createUpdateFloorPlan = async () => {
        if (planData.isEdit) {
            const isDefaultChanged = (allSites[siteId]?.defaultFloorPlan === planData.id) !== isDefault;
            const updatedData: any = {
                connectedUnits: planData.connectedUnits,
                stretch: planData.stretch,
                name: planData.name
            };

            if (planData.img) {
                let formData = new FormData();
                const floorPlanImg = new File([planData.img.file], generateRandomString() + "-" + planData.img.file.name, { type: planData.img.file.type });
                formData.append("file", floorPlanImg);
                const imgId = await sdkFile.uploadFile(formData);
                updatedData["fileId"] = imgId;
            }

            sdkSite.updateFloorPlan(planData.id, updatedData)
                .then(() => {
                    if (isDefaultChanged) {
                        return sdkSite.update(siteId, { defaultFloorPlan: planData?.id });
                    }
                    return;
                })
                .then(() => {
                    updateSiteState({ id: siteId, defaultFloorPlan: planData?.id });
                    actions.fetchData();
                    actions.openFloorPlanDialog(null);
                });
            return;
        }

        if (!planData.name) {
            setPlanData({ ...planData, nameErr: true });
            return;
        }

        if (!planData.img) {
            setPlanData({ ...planData, imgErr: true });
            return;
        }

        let formData = new FormData();
        const floorPlanImg = new File([planData.img.file], generateRandomString() + "-" + planData.img.file.name, { type: planData.img.file.type });
        formData.append("file", floorPlanImg);
        sdkFile.uploadFile(formData)
            .then((fileId: any) => {
                return sdkSite.createFloorPlan({
                    name: planData.name,
                    fileId,
                    site: siteId,
                    stretch: planData.stretch
                });
            })
            .then((resp: any) => {
                actions.fetchData();
                actions.openFloorPlanDialog(siteId, { ...resp, isEdit: true });
            })
            .catch((err: any) => {
                // addMessage
            });
    };

    const cancelUnitUpdates = () => {
        if (newUnit.isConfig) {
            addUnit(null, true);
            return;
        }
        setNewUnit(null);
    };

    const cancelFloorPlan = () => {
        if (
            !planData?.isEditInfo ||
            (tempData?.name !== planData?.name) ||
            (tempData?.stretch !== planData?.stretch) ||
            !!planData?.img
        ) {
            setDiscardDialog(true);
            return;
        }
        discardFloorPlan();
    };

    const discardFloorPlan = () => {
        setDiscardDialog(false);
        setPlanData(null);
        setTempData(null);
        actions.openFloorPlanDialog(null);
    };

    const addUnit = (e = null, isCancel = false) => {
        const units = planData?.connectedUnits || [];
        const unitObj: any = isCancel ? tempEditUnit : newUnit;
        units.push({
            x: unitObj.x,
            y: unitObj.y,
            width: unitObj.width,
            height: unitObj.height,
            unitId: unitObj.unitId,
            type: unitObj?.type || "item"
        });
        setPlanData({ ...planData, connectedUnits: units });
        setNewUnit(null);
        isCancel && setTempEditUnit(null);
    };

    const repositionUnit = () => {
        const { anchor: _, ...rest } = editUnit;
        setNewUnit({ ...rest, isMove: false, reposition: true });
        const units = planData.connectedUnits?.filter((u: any) => u.unitId !== editUnit.unitId);
        setPlanData({ ...planData, connectedUnits: units });
        setEditUnit(null);
    };

    const reConfigUnit = () => {
        if (newUnit) {
            return;
        }
        const { anchor: _, ...rest } = editUnit;
        setTempEditUnit(editUnit);
        setNewUnit({ ...rest, isMove: false, isConfig: true });
        const units = planData.connectedUnits?.filter((u: any) => u.unitId !== editUnit.unitId);
        setPlanData({ ...planData, connectedUnits: units });
        setEditUnit(null);
    };

    const deleteUnitFromPlan = () => {
        const units = planData.connectedUnits?.filter((u: any) => u.unitId !== editUnit.unitId);
        setPlanData({ ...planData, connectedUnits: units });
        setEditUnit(null);
    };

    const close = () => {
        setNewUnit(null);
        actions.openFloorPlanDialog(null);
    };

    const newUnitConfig = !!newUnit && !newUnit.isMove && (newUnit.isConfig || newUnit.newConfig);
    const isUnitMoving = !!newUnit && newUnit.isMove;
    const isStreach = planData?.stretch;
    return (
        <>
            <Dialog
                open={!!planData && isOpen}
                onClose={close}
                classes={{ paperWidthSm: (!planData?.isEdit || planData?.isEditInfo) ? classes.dialogPaperNewFP : classes.dialogPaper }}
                TransitionComponent={Transition as any}
            >
                <div className={classes.dialogHeader}>
                    <Typography className={classes.headerTitle}>{planData?.isEdit ? (!planData.isEditInfo ? planData.name : t`Edit Floor Plan`) : t`Add New Floor Plan`}</Typography>
                    <div>
                        {planData?.isEdit && !planData?.isEditInfo &&
                            <IconButton onClick={() => setPlanData({ ...planData, isEditInfo: true })} className={classes.iconBtnStyle}>
                                <EditIcon />
                            </IconButton>}
                        <IconButton onClick={close} className={classes.iconBtnStyle}>
                            <Close style={{ color: "#7f7692" }} />
                        </IconButton>
                    </div>
                </div>
                <div className={classes.dialogContent}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%"
                        }}>
                        {!planData?.isEdit || planData?.isEditInfo ?
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    height: "100%",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    name="floorPlanName"
                                    placeholder={t`Floor Plan Name`}
                                    label={t`Floor Plan Name`}
                                    value={planData?.name}
                                    onChange={(e: any) => setPlanData({ ...planData, name: e.target.value })}
                                    style={{
                                        margin: "20px 0",
                                        width: "90%"
                                    }}
                                    error={planData?.nameErr}

                                />

                                <DropzoneAreaBase
                                    fileObjects={planData?.img ? [planData?.img] : []}
                                    acceptedFiles={["image/*"]}
                                    dropzoneText={"Drag and drop an image here or click"}
                                    onAdd={(files) => {
                                        setPlanData({ ...planData, img: files[0] });
                                    }}
                                    onDelete={() => setPlanData({ ...planData, img: null })}
                                    filesLimit={1}
                                    Icon={UploadFile as any}
                                    classes={{
                                        root: classes.dropZone
                                    }}
                                />
                                {
                                    planData?.isEditInfo && planData?.url &&
                                    <div style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">{t`current plan`}</Typography>
                                        <div
                                            style={{
                                                backgroundImage: `url('${planData?.url}')`,
                                                width: "200px",
                                                height: "200px",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat"
                                            }}
                                        />
                                    </div>
                                }
                            </div> :
                            <>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    height: `calc(100vh - 105px)`,
                                    width: `calc(calc(100vh - 105px) * 1.4)`,
                                    border: "1px solid #c8c8c8"
                                }}>
                                    <div
                                        onMouseMove={moveUnit}
                                        onClick={positionUnit}
                                        id={"floorImgCont"}
                                        style={{
                                            backgroundImage: `url('${planData?.url}')`,
                                            backgroundRepeat: "no-repeat",
                                            position: "relative",
                                            backgroundSize: isStreach ? "100% 100%" : "contain",
                                            backgroundPosition: "50%",
                                            width: isStreach ? "100%" : "unset",
                                            height: isStreach ? "100%" : "unset",
                                            cursor: !!newUnit && newUnit.reposition && !newUnit.isMove ? "grab" : (!!newUnit && newUnit.reposition && newUnit.isMove ? "grabbing" : " default")
                                        }}>
                                        <img src={planData?.url} style={{ visibility: "hidden", maxWidth: "100%", maxHeight: "100%" }} />

                                        {
                                            newUnit &&
                                            <FloorPlanItem
                                                unitId={newUnit?.unitId || null}
                                                x={newUnit.x}
                                                y={newUnit.y}
                                                width={newUnit.width}
                                                height={newUnit.height}
                                                isEdit={!!newUnit}
                                                isReposition={newUnit?.reposition}
                                                isMoving={newUnit?.isMove}
                                                floorPlans={floorPlans}
                                                onMouseDown={() => setNewUnit({ ...newUnit, isMove: true })}
                                                onMouseUp={() => setNewUnit({ ...newUnit, isMove: false })}
                                                type={newUnit.type || "unit"}
                                                isConfig={newUnit.newConfig}
                                            />
                                        }
                                        {
                                            planData?.connectedUnits?.map((u: any, i: any) => (
                                                <FloorPlanItem
                                                    key={`unit--${i}`}
                                                    unitId={u.unitId}
                                                    x={u.x}
                                                    y={u.y}
                                                    width={u.width}
                                                    height={u.height}
                                                    isEdit={false}
                                                    floorPlans={floorPlans}
                                                    type={newUnit?.type || "unit"}
                                                    onClick={(e: any) => setEditUnit({ ...u, anchor: e.currentTarget })}
                                                />
                                            ))
                                        }
                                        {
                                            !!editUnit &&
                                            <Menu
                                                anchorEl={editUnit?.anchor}
                                                open={true}
                                                onClose={() => setEditUnit(null)}
                                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                                style={{ marginTop: `68px` }}
                                            >
                                                <MenuItem
                                                    onClick={repositionUnit}
                                                >
                                                    {t`Reposition Unit`}
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={reConfigUnit}
                                                >
                                                    {t`Edit`}
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={deleteUnitFromPlan}
                                                >
                                                    {t`Delete`}
                                                </MenuItem>

                                            </Menu>
                                        }

                                    </div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    height: "100%",
                                    padding: "20px 0",
                                    flexDirection: "column"

                                }}>

                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex"
                                        }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                width: !newUnitConfig ? "100%" : 0,
                                                height: "100%",
                                                flexDirection: "column",
                                                overflow: "hidden",
                                                transition: "width 0.5s"
                                            }}>
                                            <div className={classes.headerSection} style={{ marginBottom: "40px" }}>
                                                <LightTooltip title={!options?.length ? t`This site has no units/groups` : ""}>
                                                    <span>
                                                        <Button white={true} onClick={addNewUnitToFloorPlan} disabled={!!newUnit || !options?.length} fullWidth>
                                                            <span style={{ marginLeft: "5px" }}>{t`Add Unit/Group`}</span>
                                                        </Button>
                                                    </span>
                                                </LightTooltip>
                                                <LightTooltip title={!sensorsOptions?.length ? t`This site has no sensors` : ""}>
                                                    <span>
                                                        <Button white={true} onClick={addSensor} disabled={!!newUnit || !sensorsOptions?.length} fullWidth>
                                                            <span style={{ marginLeft: "5px" }}>{t`Add Sensor`}</span>
                                                        </Button>
                                                    </span>
                                                </LightTooltip>
                                                <LightTooltip title={!floorPlansOptions?.length ? t`This site has no other floor plans to link to` : ""}>
                                                    <span>
                                                        <Button white={true} onClick={linkFloorPlan} disabled={!!newUnit || !floorPlansOptions?.length} fullWidth>
                                                            <span style={{ marginLeft: "5px" }}>{t`Add link to floor plan`}</span>
                                                        </Button>
                                                    </span>
                                                </LightTooltip>
                                            </div>

                                            <div className={classes.headerSection} style={{ justifyContent: "unset" }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isDefault}
                                                            onChange={(event: any) => setIsDefault(event.target.checked)}
                                                            name="isDefault"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Default floor plan"
                                                    labelPlacement={"start"}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={isStreach}
                                                            onChange={() => setPlanData({ ...planData, stretch: !planData.stretch })}
                                                        />
                                                    }
                                                    label="Fit to page"
                                                    labelPlacement={"start"}
                                                />
                                            </div>
                                        </div>

                                        <div style={{
                                            width: newUnitConfig ? "100%" : 0,
                                            overflow: "hidden",
                                            transition: "width 0.5s",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            {newUnit?.type !== "link" && newUnit?.type !== "sensor" &&
                                                <Autocomplete
                                                    value={units && (units[newUnit?.unitId] ?
                                                        { label: units[newUnit?.unitId]?.name, value: newUnit?.unitId, type: "Units" } :
                                                        { label: groups[newUnit?.unitId]?.name, value: newUnit?.unitId, type: "Groups" })}
                                                    options={options}
                                                    groupBy={(option: any) => option.type}
                                                    getOptionLabel={(option: any) => option.label}
                                                    onChange={(event, newValue) => setNewUnit({ ...newUnit, unitId: newValue?.value, type: newValue?.type, newConfig: false, isConfig: false })}
                                                    renderInput={(params: any) => <TextField {...params} label={t`Group/Unit Name`} />}
                                                    style={{ width: "80%" }}
                                                    getOptionDisabled={(option) => planData.connectedUnits.filter((u: any) => u.unitId === option.value)?.length > 0}
                                                    disableClearable
                                                />}

                                            {newUnit?.type === "link" &&
                                                <Autocomplete
                                                    value={{ label: floorPlans[newUnit?.unitId]?.name, value: newUnit?.unitId }}
                                                    options={floorPlansOptions}
                                                    getOptionLabel={(option: any) => option.label}
                                                    onChange={(event, newValue) => setNewUnit({ ...newUnit, unitId: newValue?.value, type: "link", newConfig: false, isConfig: false })}
                                                    renderInput={(params: any) => <TextField {...params} label={t`Link to another floor plan`} />}
                                                    style={{ width: "80%" }}
                                                    disableClearable
                                                />}
                                            {newUnit?.type === "sensor" &&
                                                <Autocomplete
                                                    value={{ label: sensors[newUnit?.unitId]?.name, value: newUnit?.unitId }}
                                                    options={sensorsOptions}
                                                    getOptionLabel={(option: any) => option.label}
                                                    onChange={(event, newValue) => setNewUnit({ ...newUnit, unitId: newValue?.value, type: "sensor", newConfig: false, isConfig: false })}
                                                    renderInput={(params: any) => <TextField {...params} label={t`Sensor Name`} />}
                                                    style={{ width: "80%" }}
                                                    disableClearable
                                                />}
                                        </div>
                                    </div>

                                    <div className={classes.actionsHolder}>
                                        <Button
                                            onClick={newUnitConfig ? cancelUnitUpdates : cancelFloorPlan}
                                            autoFocus={true}
                                            width={100}
                                            marginRight
                                            disabled={isUnitMoving}
                                        >
                                            {t`Cancel`}
                                        </Button>
                                        <Button
                                            onClick={createUpdateFloorPlan}
                                            disabled={!!newUnit}
                                            white
                                            width={100}
                                        >
                                            {t`Save`}
                                        </Button>
                                    </div>

                                </div>
                            </>
                        }
                    </div>
                </div>
                {
                    (!planData?.isEdit || planData?.isEditInfo) &&
                    <div className={classes.actionsHolder} style={{ justifyContent: "flex-end", marginRight: "5%" }}>
                        <Button
                            onClick={() => {
                                if (!planData?.isEdit) {
                                    close();
                                    return;
                                }
                                setPlanData({ ...planData, isEditInfo: false });
                            }}
                            autoFocus={true}
                            width={100}
                            marginRight
                            disabled={isUnitMoving}
                        >
                            {t`Cancel`}
                        </Button>
                        <Button
                            onClick={createUpdateFloorPlan}
                            white
                            width={100}
                            disabled={isUnitMoving}
                        >
                            {t`Save`}
                        </Button>
                    </div>
                }
            </Dialog >

            {discardDialog &&
                <Dialog
                    open={discardDialog}
                    onClose={() => setDiscardDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ paper: classes.dialog }}
                >
                    <div className={classes.titleContent}>
                        <Typography className={classes.headerTitle}>{t`Close and Discard Changes?`}</Typography>
                        <IconButton disableRipple onClick={() => setDiscardDialog(false)} className={classes.overWriteIcon}>
                            <Close />
                        </IconButton>
                    </div>
                    <div className={classes.dialogContent}>
                        <Typography className={clsx(classes.boldText, classes.DialogContentText)}>
                            {t`Are you sure you would like to discard changes made to floor plan?`}
                        </Typography>
                    </div>
                    <div className={classes.actionsHolder}>
                        <Button
                            className={classes.yesBtn}
                            onClick={() => setDiscardDialog(false)}
                            white
                            width={130}
                            marginRight
                        >
                            {t`Cancel`}
                        </Button>

                        <Button
                            autoFocus={true}
                            onClick={discardFloorPlan}
                            width={130}
                        >
                            {t`Discard`}
                        </Button>
                    </div>
                </Dialog>
            }
        </>
    );
};

export default AddEditFloorPlan;
