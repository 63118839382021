import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // Mapping Container
  mappingContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&>*': {
      userSelect: 'none',
    },
  },
  //730, 800
  mappingHolder: {
    position: 'relative',
    width: '650px',
    height: '720px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  dataPointMapWrapper: {
    display: 'inline-block',
    position: 'absolute',
  },
}));

export default useStyles;
