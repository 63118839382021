import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import CircularSlider from "react-circular-slider-svg";
import clsx from 'clsx';
import moment from 'moment';
import { t } from 'ttag';
import { useStoreState } from "../../../../models/RootStore";
import sensorThumbnailStyles from './sensorThumbnail.style';
import SensorIcon from '@icons/SensorIcon';

const unitsSymbols: any = {
	1: '°',
	2: 'ppm',
	3: 'rpm',
	4: '',
	5: '%',
	6: '𝜇g/m³',
	7: '',
	14: '% RH'
};

const hasValue = (value: any) => {
	return !!value || value === 0;
};

const timeToString = (timestamp: any, timezone: any, timeFormat: any) => {
	return moment(timestamp).tz(timezone).format(timeFormat);
};

const dateToString = (timestamp: any, timezone: any, dateFormat: any) => {
	return moment(timestamp).tz(timezone).format(dateFormat);
};

const SensorThumbnail = (props: any) => {
	const useStyles = makeStyles(sensorThumbnailStyles);
	const classes = useStyles();

	const { sensor, isCelsius } = props;
	const {
		name,
		readingValue = 0,
		readingValueTimestamp,
		userData = {},
		type,
		isWritable,
		permissions = {},
		site
	} = sensor;
	const { canControl } = permissions;

	const types = useStoreState((s) => s.types);
	const { dateFormat, timeFormat } = useStoreState((state) => state.users);
	const allSites = useStoreState((state) => state.sites.allSites);
	const timezone = allSites[site]?.timezone || "";
	const value = +readingValue;
	const { rangeMax: userMax, rangeMin: userMin, normalStateValue } = userData;
	const { sensorTypes, sensorMeasurementUnits } = types;
	const sensorType = sensorTypes[type] || {};
	const hasUserValues = hasValue(userMax) && hasValue(userMin);

	const { enableMinMaxSelection, enableNormalModeSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin } = sensorType;
	const doConvert = enableMinMaxSelection && hasUserValues;
	const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
	const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
	const displayValue = !sensorUnit || type === 130 || type === 7 ? (enableMinMaxSelection ? hasValue(userMax) && hasValue(userMin) ?
		(((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin : value : value).toFixed(1) :
		sensorUnit === 'Temperature' ? (isCelsius ? Math.round(value) : (Math.round((value * 9 / 5) + 32))) :
			sensorUnit === 'Open/Close' ? type === 129 ? +value === 0 ? t`OFF` : t`ON` : (+value === 1 ? t`OPEN` : t`CLOSE`) : value;

	return (
		<div className={classes.sensorWrapperStyle} >
			<Typography className={classes.sensorName}>{name}</Typography>
			{type !== 130 && <div className={classes.sensorIcon}>
				<SensorIcon type={type} width={70} height={70} readingValue={+readingValue} />
			</div>}
			{type === 130 && isWritable ? <div className={classes.sensorSliderContainer}>
				<CircularSlider
					disabled={!canControl}
					size={275}
					minValue={valueMin}
					maxValue={valueMax}
					startAngle={0}
					endAngle={180}
					coerceToInt={true}
					angleType={{ direction: 'cw', axis: '-x' }}
					handle1={{ value: value, onChange: () => { } }}
					arcColor={'#FFF'}
					arcBackgroundColor={'#FFF'}
				/>
				<div className={classes.sensorValuesContainer}>
					<Typography className={classes.sensorValues}>{hasUserValues ? userMin : valueMin}</Typography>
					<Typography className={classes.sensorValues}>{hasUserValues ? userMax : valueMax}</Typography>
				</div>
				<div className={classes.valueControl}>
					<Typography className={classes.sensorValue}>{doConvert ? ((((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin).toFixed(1) : value.toFixed(1)}</Typography>
					<Typography className={clsx(classes.measurementUnit, { [classes.percentStyle]: activeMeasurementUnit === 5, [classes.tempUnit]: activeMeasurementUnit === 1 })}>{unitsSymbols[activeMeasurementUnit || ''] || ''}</Typography>
				</div>

			</div> :
				(<>
					<div className={classes.valueContainer}>
						<Typography className={clsx(classes.sensorValueText, { [classes.offText]: displayValue === t`OFF` })}>{displayValue}</Typography>
						<Typography className={clsx(classes.measurementUnitsStyle, { [classes.percentStyle]: activeMeasurementUnit === 5, [classes.tempUnit]: activeMeasurementUnit === 1 })}>{unitsSymbols[activeMeasurementUnit || ''] || ''}</Typography>
					</div>
					{(sensorUnit === 'Open/Close' && enableNormalModeSelection && hasValue(normalStateValue)) && <Typography className={classes.setPointDiscription}>{+normalStateValue === +readingValue ? t`(Normal)` : ''}</Typography>}
				</>)
			}
			<div className={clsx(classes.timeDateStyle, { [classes.addTopMargin]: type === 130 })}>
				<div className={clsx(classes.dataTimeFont)}> {timeToString(readingValueTimestamp, timezone, timeFormat)}</div>
				<div className={classes.dataTimeFont}> {dateToString(readingValueTimestamp, timezone, dateFormat)}</div>
			</div>
		</div>
	);
};


export default SensorThumbnail;