import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import { cloneDeep } from 'lodash';

import useStyles from './AlertUnitsList.style';
import useDateTime from '@hooks/useDateTime';

/**
 * @typedef {Object} Unit
 * @property {string} id - The unique identifier for the unit.
 * @property {string} name - The name of the unit.
 * @property {boolean} isConnected - Connection status of the unit.
 * @property {number|string} timestamp - The timestamp associated with the unit, either as a number or a formatted string.
 */

/**
 * AlertUnitsList component displays a list of alert units, sorted by their connection status and name.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Unit[]} props.units - Array of unit objects.
 */
const AlertUnitsList = ({units}) => {

	const classes = useStyles();

	const {timeStampToDateString} = useDateTime();

	// Compute the sorted and formatted units using useMemo for performance optimization
	const computedUnits = useMemo(() => {
		const unSorted = cloneDeep(units);
		return unSorted.sort((a, b) => {
			// Sort by isConnected: false first
			if (a.isConnected !== b.isConnected) {
				return a.isConnected ? 1 : -1;
			}
			// If both are connected or both are disconnected, sort alphabetically by name
			if (a.isConnected) {
				return a.name.localeCompare(b.name);
			} else {
				return a.name.localeCompare(b.name);
			}
		}).map(item => {
			// Convert timestamp to string if it's not already
			if(typeof item.timestamp !== 'string'){
				item.timestamp = timeStampToDateString(item.timestamp);
			}
			return item;
		});
	}, [units]);

	const renderUnits = () => {
		return computedUnits.map((unit)=>{
			const {id,name,isConnected,timestamp} = unit;
			return(
				<div key={id} className={classes.item}>
					<span>{name}</span>
					<span className={classes.centerItem}>{isConnected ? t`Re-connected` : null}</span>
					<span>{isConnected ? timestamp : ''}</span>
				</div>
			);
		});
	};


	return (
		<div>
			{renderUnits()}
		</div>
	);
};

AlertUnitsList.propTypes = {
	units:PropTypes.array.isRequired
};

export default AlertUnitsList;
