/* eslint-disable react/display-name */
import React, { useState, useCallback, memo } from 'react';

import { getDummyDataPointData } from '../constants';

import DataPointConfig from './DataPointConfig';
import MappingArea from './MappingArea';
import DataPointsList from './DataPointsList';

import useStyles from './styles';

// New TemplateArea component with state management
const TemplateArea = memo(
  ({
    dataPoints,
    onChange,
    templateImgPreview,
    paramTemplates,
    onSelectParamTemplate,
    selectedParamTemplate,
  }) => {
    const classes = useStyles();
    const [selectedDataPoint, setSelectedDataPoint] = useState(null);

    const handleSelectDataPoint = useCallback((dataPointId) => {
      setSelectedDataPoint(dataPointId);
    }, []);

    const handleUpdateDataPoint = useCallback((dataPointId, newData) => {
      onChange((prev) => ({
        ...prev,
        [dataPointId]: newData,
      }));
    }, []);

    const handleUpdateDataPointPosition = useCallback(
      (dataPointId, position) => {
        onChange((prev) => ({
          ...prev,
          [dataPointId]: {
            ...prev[dataPointId],
            position,
          },
        }));
      },
      []
    );

    const handleDeleteDataPoint = useCallback((dataPointId) => {
      onChange((prev) => {
        const { [dataPointId]: _, ...rest } = prev;
        return rest;
      });
      setSelectedDataPoint(null);
    }, []);

    const handleClickDataPoint = useCallback((dataPoint) => {
      onChange((prev) => {
        if (prev[dataPoint.id]) {
          setSelectedDataPoint(dataPoint.id);
          return prev;
        }
        return {
          ...prev,
          [dataPoint.id]: getDummyDataPointData(dataPoint),
        };
      });
      setSelectedDataPoint(dataPoint.id);
    }, []);

    return (
      <div className={classes.templateArea}>
        <div className={classes.dataPointsListWrap}>
          <DataPointsList
            onClickDataPoint={handleClickDataPoint}
            paramTemplates={paramTemplates}
            selectedParamTemplate={selectedParamTemplate}
            onSelectParamTemplate={onSelectParamTemplate}
          />
        </div>
        <div className={classes.mappingAreaWrap}>
          <MappingArea
            dataPoints={dataPoints}
            selectedDataPoint={selectedDataPoint}
            onSelectDataPoint={handleSelectDataPoint}
            onUpdateDataPointPosition={handleUpdateDataPointPosition}
            onDeleteDataPoint={handleDeleteDataPoint}
            templateImg={templateImgPreview}
            mode="edit"
          />
        </div>
        <div className={classes.dataPointConfigWrap}>
          {selectedDataPoint && dataPoints[selectedDataPoint] && (
            <DataPointConfig
              dataPoint={dataPoints[selectedDataPoint]}
              onUpdateDataPoint={(data) => {
                handleUpdateDataPoint(selectedDataPoint, data);
              }}
            />
          )}
        </div>
      </div>
    );
  }
);

export default TemplateArea;
