import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import DatesList from "../../cool_widgets/DatesList/DatesList";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import Switch from "../../cool_widgets/Switch/Switch";
import { Close } from "../../icons";
import {
  ColdIcon,
  HeatIcon,
  ScheduleOffIcon,
  AutoMode
} from "../../icons/index";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { minsToTime } from "../../services/timeService";
import { Edit } from "../../svgComponents";
import AddEditSchedule from "./AddEditSchedule";
import useStyles from "./SystemSchedule.style";

const SystemSchedule: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const { close, isOpen, systemId, system } = props;
  const { canAddSchedule = true, canUpdateSchedule = true, canDeleteSchedule = true } = system || {};

  const [systemSchedules, setSystemSchedules] = useState<any>({});
  const [openAddEdit, setOpenAddEdit] = useState<string>("");

  const { getSystemSchedules } = useStoreActions((action) => action.systems);
  const { updateSchedule, deleteSchedule } = useStoreActions((actions) => actions.schedules);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const types = useStoreState((state) => state.types);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay);
  const { scheduleCategories, weekDays, operationModesExtended } = types;
  const systemName = allSystems[systemId]?.name || "system";

  const operationIcons: any = {
    COOL: <ColdIcon className={clsx(classes.modeIcon, classes.blueCool)} />,
    HEAT: <HeatIcon className={clsx(classes.modeIcon, classes.redHeat)} />,
    AUTO: <AutoMode maincolor="#aaa2aa" />
  };

  useEffect(() => {
    getSystemSchedules(systemId)
      .then((res: any) => {
        setSystemSchedules(res);
      });
  }, []);

  const deleteSelectedSchedule = (id: string) => {
    deleteSchedule(id)
      .then(() => {
        const { [id]: toDelete, ...restOfSchedules } = systemSchedules;
        setSystemSchedules(restOfSchedules);
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const changeScheduleActiveState = (e: any, id: string) => {
    const isDisabled = !e.target.checked;
    updateSchedule({ id, data: { isDisabled } })
      .then(() => {
        setSystemSchedules({
          ...systemSchedules, [id]: { ...systemSchedules[id], isDisabled }
        });
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const AddScheduleBtn = () => {
    return (
      <Button
        disabled={!canAddSchedule}
        disableRipple
        variant="contained"
        color="default"
        onMouseUp={() => setOpenAddEdit("new")}
        classes={{ contained: classes.addDeviceBtn }}
      >
        <Add className={classes.addIcon} />

        {t`Add Schedule`}
      </Button>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => close()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      classes={{
        paper: classes.dialog,
        paperWidthMd: openAddEdit && classes.smallDialog
      }}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{t`System ` + systemName + ` Schedules`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={() => close()}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      {!openAddEdit &&
        <>
          {Object.values(systemSchedules).length !== 0 && <div className={classes.header}>
            <AddScheduleBtn />
          </div>}
          <div className={classes.dialogContent}>
            {Object.values(systemSchedules).length === 0 ? (
              <div className={classes.noSchedules}>
                <div className={classes.mainIconsStyle}>
                  <ScheduleOffIcon color="#AAA2AA" />
                </div>
                <Typography
                  className={classes.noScheduleTitle}
                >{t`There are no schedules to show`}</Typography>
                <AddScheduleBtn />
              </div>
            ) : (
              <>
                {Object.values(systemSchedules).map((schedule: any) => {
                  const {
                    id: scheduleId,
                    powerOnTime,
                    days = [],
                    dates = [],
                    name,
                    isDisabled,
                    operationMode,
                    setpoint,
                    scheduleCategory
                  } = schedule;
                  const startHour = powerOnTime || powerOnTime === 0 ? minsToTime(powerOnTime, timeFormat) : "";
                  const modeIcon = operationIcons[operationModesExtended[operationMode]];

                  return (<div
                    className={classes.scheduleContainer}
                    key={`schedule-${scheduleId}`}
                  >
                    <div className={classes.scheduleHeader}>
                      <div className={classes.scheduleTitle}>
                        <Typography className={classes.nameStyle}>
                          {name}
                        </Typography>
                      </div>
                      <div className={classes.scheduleControl}>
                        <LightTooltip title={t`Edit Schedule`}>
                          <IconButton disabled={!canUpdateSchedule} disableRipple onClick={() => setOpenAddEdit(scheduleId)} className={classes.iconBtnStyle}>
                            <Edit className={classes.editIcon} />
                          </IconButton>
                        </LightTooltip>
                        <IconButton disableRipple className={classes.iconBtnStyle}>
                          <Delete className={classes.editIcon}
                            disabled={!canDeleteSchedule}
                            type={t`Schedule`}
                            object={schedule}
                            detach={() => deleteSelectedSchedule(scheduleId)} />
                        </IconButton>
                      </div>
                    </div>
                    <div className={classes.scheduleBody}>
                      <div className={classes.bodyRow}>
                        <div className={classes.statusText}>
                          <Typography className={clsx(classes.selectModeStyle, classes.status)}>{t`Status: `}</Typography>
                          <Typography className={classes.selectModeStyle}>{isDisabled ? t`Inactive` : t`Active`}</Typography>
                        </div>
                        {modeIcon}
                        <div className={classes.controlSec}>
                          <Switch
                            disabled={!canUpdateSchedule}
                            color={"default"}
                            checked={!isDisabled}
                            disableRipple={true}
                            classes={{
                              thumb: classes[!isDisabled ? "thumb" : "thumbDisabled"],
                              track: classes[!isDisabled ? "track" : "trackDisabled"]
                            }}
                            onChange={(e: any) =>
                              changeScheduleActiveState(e, scheduleId)
                            }
                            value={true}
                          />
                        </div>
                      </div>
                      <div className={classes.bodyRow}>
                        <div className={classes.timeSection}>
                          <Typography className={classes.selectModeStyle}>{t`Start Time: ` + startHour}</Typography>
                        </div>
                        <div className={clsx({ [classes.hideSetpoint]: !setpoint })}>
                          <Typography className={classes.setpointStyle}>
                            {Math.round(setpoint)}
                            <span className={classes.tempSymbolStyle}>
                              {temperatureSymbol()}
                            </span>
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.bodyRow}>
                        {scheduleCategory === scheduleCategories.weekly && <DaysList
                          days={Object.keys(weekDays)}
                          activeDays={days}
                          action={() => { }}
                          disablePointer
                          white={false}
                        />}
                        {scheduleCategory === scheduleCategories.calendar && <DatesList
                          dates={dates}
                        />}
                      </div>
                      <Divider className={classes.dividerStyle} />
                    </div>
                  </div>);
                }

                )}
              </>)}
          </div>
        </>
      }
      {openAddEdit &&
        <AddEditSchedule
          editMode={openAddEdit !== "new"}
          setOpenAddEdit={setOpenAddEdit}
          systemId={systemId}
          systemName={systemName}
          scheduleData={systemSchedules[openAddEdit] || {}}
          setSystemSchedules={setSystemSchedules}
          systemSchedules={systemSchedules}
        />
      }
    </Dialog>
  );
};

export default SystemSchedule;
