import { Divider, Grid, MenuItem, Paper, Select, Switch, Typography } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import React from "react";
import { t } from "ttag";
import { languages } from "../../constants/languages";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Arrow as SvgArrow } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import { IUser } from "../../models/Users";
import PhoneField from "./PhoneField";
import UserDetailsField from "./UserDetailsField";
import useStyles from "./UserDetailsMyProfile.style";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
interface IProps extends Omit<FormikProps<any>, "handleSubmit"> {
  user: IUser;
  errors: any;
  touched: any;
  withPasswordField?: boolean;
  withChangePasswordField?: boolean;
  temperatureScale: any;
  pressureScale: any;
  invite?: boolean;
  inviteEdit?: boolean;
  showLanguage?: boolean;
  setPasswordOpen?: any;
  passwordOpen?: boolean;
  toggleCustomersNicknamesDialog?: any;
}

const UserDetailsMyProfile: React.FC<IProps> = ({
  user,
  errors,
  touched,
  withPasswordField,
  withChangePasswordField,
  showLanguage = false,
  values,
  setFieldValue,
  temperatureScale,
  pressureScale,
  invite = false,
  inviteEdit = false,
  setPasswordOpen,
  passwordOpen,
  toggleCustomersNicknamesDialog,
  ...props
}) => {
  const classes = useStyles();

  const types = useStoreState((s) => s.types);
  const languagesObj = Object.keys(languages).map((language: any) => <MenuItem value={language} key={`languageOption-${language}`}>{languages[language]}</MenuItem>);

  return (
    <Grid className={classes.personalDetailsContainer}>
      <Grid container className={classes.dialogSubContent}>
        <Grid item xs={5}>
          <Paper className={classes.leftCard} elevation={0}>
            <Typography className={classes.headerStyle}>{t`Personal Details`}</Typography>
            <Divider className={classes.divider} orientation="horizontal" />
            <Field
              disabled={inviteEdit}
              label={t`First name*`}
              value={values.firstName}
              name="firstName"
              component={UserDetailsField}
              error={errors.firstName && touched.firstName ? true : false}
              helperText={
                errors.firstName && touched.firstName ? errors.firstName : t`Up to 20 characters`
              }
            />
            <Field
              disabled={inviteEdit}
              label={t`Last name*`}
              value={values.lastName}
              name="lastName"
              component={UserDetailsField}
              error={errors.lastName && touched.lastName ? true : false}
              helperText={errors.lastName && touched.lastName ? errors.lastName : t`Up to 20 characters`}
            />
            <Field
              label={t`Username/Email`}
              disabled={true}
              value={values.username}
              name="username"
              component={UserDetailsField}
              error={errors.username && touched.username ? true : false}
              helperText={
                errors.username && touched.username ? errors.username : t`6-20 characters, must be unique`
              }
            />
            {withPasswordField && (
              <Field
                label={t`Password`}
                value={values.password}
                name="password"
                component={UserDetailsField}

                error={withPasswordField && errors.password && touched.password ? true : false}
                helperText={
                  errors.password && touched.password
                    ? errors.password
                    : t`8+ characters, 1 capital, 1 number`
                }
              />
            )}
            <Field
              label={t`Telephone`}
              value={values.phone}
              name="phone"
              component={PhoneField}
              onChange={(e: any) => setFieldValue("phone", e)}
              error={errors.phone && touched.phone ? true : false}
              helperText={
                errors.phone && touched.phone ? errors.phone : t`International format telephone number`
              }
            />
          </Paper>
        </Grid>

        <Grid item xs={7}>
          <Paper className={classes.rightCard} elevation={0}>
            <Typography className={classes.headerStyle}>{t`User Preferences`}</Typography>
            <Divider className={classes.divider} orientation="horizontal" />
            <div className={classes.unitsMeasurementContainer}>
              <Grid component="label" alignItems="center">
                <Grid item className={classes.itemTitle}>
                  <div style={{ alignSelf: "center" }}>
                    <Typography>{t`Temperature Units`}</Typography>
                  </div>
                </Grid>
                <Grid item className={classes.itemSwtich}>
                  <Grid container direction="row" className={classes.switchContainer}>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{t`°C`}</Typography>
                    </Grid>
                    <Grid item className={classes.tempFontStyle}>
                      <Field
                        name="temperatureScale"
                        value={values.temperatureScale}
                        as={() => (
                          <Switch
                            color={"default"}
                            onChange={() => {
                              setFieldValue("temperatureScale", values.temperatureScale !== 2 ? 2 : 1);
                            }}
                            checked={temperatureScale[values.temperatureScale] === "fahrenheit"}
                            name="temperatureScale"
                            classes={{
                              thumb: classes.thumb,
                              track: classes.track
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{t`°F`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div className={classes.unitsMeasurementContainer}>
              <Grid container component="label" direction="row" alignItems="center">
                <Grid item className={classes.itemTitle}>
                  <div style={{ alignSelf: "center" }}>
                    <Typography>{t`Pressure Units`}</Typography>
                  </div>
                </Grid>
                <Grid item className={classes.itemSwtich}>
                  <Grid container direction="row" className={classes.switchContainer}>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{t`kg/cm2`}</Typography>
                    </Grid>
                    <Grid item className={classes.tempFontStyle}>
                      <Field
                        name="measurementUnits"
                        value={values.measurementUnits}
                        as={() => (
                          <Switch
                            color={"default"}
                            onChange={() => {
                              setFieldValue("measurementUnits", values.measurementUnits !== 2 ? 2 : 1);
                            }}
                            checked={pressureScale[values.measurementUnits] === "PSI"}
                            name="measurementUnits"
                            classes={{
                              thumb: classes.thumb,
                              track: classes.track
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{t`PSI`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div className={classes.unitsMeasurementContainer}>
              <Grid container component="label" direction="row" alignItems="center">
                <Grid item className={classes.itemTitle}>
                  <div style={{ alignSelf: "center" }}>
                    <Typography>{t`Time Format`}</Typography>
                  </div>
                </Grid>
                <Grid item className={classes.itemSwtich}>
                  <Grid container direction="row" className={classes.switchContainer}>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{types.timeFormat[0]?.text}</Typography>
                    </Grid>
                    <Grid item className={classes.tempFontStyle}>
                      <Field
                        name="timeFormat"
                        value={values.timeFormat}
                        as={() => (
                          <Switch
                            color={"default"}
                            onChange={() => {
                              setFieldValue("timeFormat", values.timeFormat !== types.timeFormat[0]?.value ? types.timeFormat[0]?.value : types.timeFormat[1]?.value);
                            }}
                            checked={values.timeFormat === types.timeFormat[1]?.value}
                            name="timeFormat"
                            classes={{
                              thumb: classes.thumb,
                              track: classes.track
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{types.timeFormat[1]?.text}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div className={classes.unitsMeasurementContainer}>
              <Grid container component="label" direction="row" alignItems="center">
                <Grid item className={classes.itemTitle}>
                  <div style={{ alignSelf: "center" }}>
                    <Typography>{t`Date Format`}</Typography>
                  </div>
                </Grid>
                <Grid item className={classes.itemSwtich}>
                  <Grid container direction="row" className={classes.switchContainer}>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{types.dateFormat[0]?.text}</Typography>
                    </Grid>
                    <Grid item className={classes.tempFontStyle}>
                      <Field
                        name="dateFormat"
                        value={values.dateFormat}
                        as={() => (
                          <Switch
                            color={"default"}
                            onChange={() => {
                              setFieldValue("dateFormat", values.dateFormat !== types.dateFormat[0].value ? types.dateFormat[0].value : types.dateFormat[1]?.value);
                            }}
                            checked={values.dateFormat === types.dateFormat[1].value}
                            name="dateFormat"
                            classes={{
                              thumb: classes.thumb,
                              track: classes.track
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{types.dateFormat[1]?.text}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div className={classes.unitsMeasurementContainer}>
              <Grid container component="label" direction="row" alignItems="center">
                <Grid item className={classes.itemTitle}>
                  <div style={{ alignSelf: "center" }}>
                    <Typography>{t`Hide filter alerts`}</Typography>
                  </div>
                </Grid>
                <Grid item className={classes.itemSwtich}>
                  <Grid container direction="row" className={classes.switchContainer}>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{""}</Typography>
                    </Grid>
                    <Grid item className={classes.tempFontStyle}>
                      <Field
                        name="showClearFilterAlerts"
                        value={values.dateFormat}
                        as={() => (
                          <CoolSwitch
                            color={"default"}
                            switchChange={() => {
                              setFieldValue("showClearFilterAlerts", !values?.showClearFilterAlerts);
                            }}
                            checked={!values?.showClearFilterAlerts}
                            name="showClearFilterAlerts"
                            classes={{
                              thumb: classes[!values?.showClearFilterAlerts ? "thumb" : "thumbDisabled"],
                              track: classes[!values?.showClearFilterAlerts ? "track" : "trackDisabled"]
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{""}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div className={classes.unitsMeasurementContainer}>
              <Grid container component="label" direction="row" alignItems="center">
                <Grid item className={classes.itemTitle}>
                  <div style={{ alignSelf: "center" }}>
                    <Typography>{t`Enable unit/group reorder`}</Typography>
                  </div>
                </Grid>
                <Grid item className={classes.itemSwtich}>
                  <Grid container direction="row" className={classes.switchContainer}>
                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{""}</Typography>
                    </Grid>
                    <Grid item className={classes.tempFontStyle}>
                      <Field
                        name="lockItemsReorder"
                        value={!values.dateFormat}
                        as={() => (
                          <CoolSwitch
                            color={"default"}
                            switchChange={() => {
                              setFieldValue("lockItemsReorder", !values?.lockItemsReorder);
                            }}
                            checked={!values?.lockItemsReorder}
                            name="lockItemsReorder"
                            classes={{
                              thumb: classes[!values?.lockItemsReorder ? "thumb" : "thumbDisabled"],
                              track: classes[!values?.lockItemsReorder ? "track" : "trackDisabled"]
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item style={{ alignSelf: "center" }}>
                      <Typography variant="body2" className={classes.switchOptionText}>{""}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            {
              showLanguage && (
                <div className={classes.newBlock}>
                  <Divider className={classes.divider} orientation="horizontal" />
                  <div className={classes.language}>
                    <Typography className={classes.languageTitle}>{t`Language`}</Typography>
                    <Select
                      variant="outlined"
                      name="language"
                      className={classes.input}
                      value={`${values.language}`}
                      onChange={(event: any) => setFieldValue("language", event.target.value)}
                      IconComponent={SvgArrow}
                    >
                      {languagesObj}
                    </Select>
                  </div>
                </div>
              )
            }

            <div className={classes.newBlock}>
              <Divider className={classes.divider} orientation="horizontal" />
              <div className={classes.authFactorContainer}>
                <Grid container component="label" direction="row" alignItems="center">
                  <Grid item className={classes.itemTitle} xs={6} md={10}>
                    <div style={{ alignSelf: "center" }}>
                      <Typography>{t`Enable email 2-factor-authentication`}</Typography>
                    </div>
                  </Grid>
                  <Grid item className={classes.authFactorItemSwtich} xs={6} md={2}>
                    <Grid item className={classes.tempFontStyle}>
                      <Field
                        name="is2FA"
                        value={values.is2FA}
                        as={() => (
                          <CoolSwitch
                            color={"default"}
                            switchChange={() => {
                              setFieldValue("is2FA", !values.is2FA);
                            }}
                            checked={values.is2FA}
                            name="is2FA"
                            classes={{
                              thumb: classes[values?.is2FA ? "thumb" : "thumbDisabled"],
                              track: classes[values?.is2FA ? "track" : "trackDisabled"]
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.flexColumn}>

                <div className={classes.changePassword} onClick={() => setPasswordOpen(!passwordOpen)}>
                  <Typography className={classes.passwordTitle}>
                    {t`Change Password`}
                  </Typography>
                  <SvgArrow />
                </div>
                <div className={classes.changePassword} onClick={() => toggleCustomersNicknamesDialog(true)}>
                  <Typography className={classes.passwordTitle}>
                    {t`Customers Nicknames`}
                  </Typography>
                  <SvgArrow />
                </div>

              </div>

            </div>
          </Paper>
        </Grid>
      </Grid>
    </Grid>

  );
};

export default UserDetailsMyProfile;
