import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, Dialog, FormControlLabel, IconButton, Typography } from "@material-ui/core";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Customer as sdkCustomer, Policy as sdkPolicy, Services as sdkServices } from "coolremote-sdk";
import { fromUnixTime, getUnixTime } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../../cool_widgets/Button";
import { Close } from "../../../icons";
import { useStoreActions, useStoreState } from "../../../models/RootStore";
import { PermissionsSummary } from "../PermissionsSummary";
import { PermissionsTree } from "../PermissionsTree";
import useStyles from "./EditUser.style";
interface IEditUser {
    onClose: any;
    user: any;
    deletePolicies: any;
    customerId: string;
    refreshData: any;
    isCurrentUser?: boolean;
}



const EditUser = ({ onClose, user, customerId, deletePolicies, refreshData, isCurrentUser }: IEditUser) => {
    const [policies, setPolicies] = useState<any>({});
    const [withExpire, setWithExpire] = useState<boolean>(false);
    const [expireDate, setExpireDate] = useState<any>(null);
    const { addMessage } = useStoreActions((action) => action.errorMessage);

    useEffect(() => {
        if (withExpire) {
            return;
        }
        setExpireDate(null);
    }, [withExpire]);

    useEffect(() => {
        if (!user) {
            return;
        }

        const pols: any = user?.policies?.reduce((data: any, item: any) => {
            const { entityId, level, application, role, expiryTimestamp } = item;
            if (data[entityId]) {
                data[entityId][application] = { entityId, level, application, role, expiryTimestamp };
                return data;
            }
            data[entityId] = {
                [application]: { entityId, level, application, role, expiryTimestamp }
            };
            return data;
        }, {});

        setPolicies(pols);

        let shortestExp: any = null;
        for (let p of user?.policies) {
            if (!p?.expiryTimestamp) {
                continue;
            }
            if (!shortestExp && !!p?.expiryTimestamp) {
                shortestExp = p?.expiryTimestamp;
                continue;
            }
            if (!!p?.expiryTimestamp && p?.expiryTimestamp < shortestExp) {
                shortestExp = p?.expiryTimestamp;
            }
        }
        setWithExpire(!!shortestExp);
        if (shortestExp) {
            setExpireDate(fromUnixTime(shortestExp));
        }

    }, [user]);

    const submit = () => {

        const policiesToDel = user.policies.map((p: any) => p.id || p._id);
        const arrPolicies: any = Object.values(policies).reduce((data: any, entity: any) => {
            const apps: any = Object.values(entity);
            for (let ap of apps) {
                if (withExpire && !!expireDate) {
                    ap["expiryTimestamp"] = getUnixTime(expireDate);
                }
                else {
                    delete ap.expiryTimestamp;
                }
            }

            if (apps?.length && apps[0]?.role !== -1) {
                data.push(...apps);
            }
            return data;
        }, []);

        if (!arrPolicies?.length) {
            addMessage({ message: t`Please add policies to the user before you update` });
            return;
        }

        const data = {
            customer: customerId,
            user: user.id,
            policies: arrPolicies
        };

        if (!arrPolicies?.length) {
            addMessage({ message: t`Please add policies to the user` });
            return;
        }

        policiesToDel?.length && deletePolicies(policiesToDel)
            .then(() => {
                return sdkPolicy.createMultiPolicy(data);

            })
            .finally(async () => {
                const updatedUser = (await sdkCustomer.getCustomerUsers(customerId))?.[user.id];
                sdkServices.sendEmailUpdateUserPolicies({ ...data, policies: updatedUser?.policies?.map((p: any) => p.id || p._id) });
                refreshData();
                onClose();
            });
    };

    const classes = useStyles();
    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            classes={{ paper: classes.dialog }}
        >
            <div className={classes.titleContent}>
                <Typography className={classes.headerTitle}>{t`Edit user`}</Typography>
                <IconButton disableRipple onClick={onClose} className={classes.iconBtnStyle}>
                    <Close color="#7f7692" />
                </IconButton>
            </div>

            <div className={classes.dialogContent}>

                <div className={classes.userInfo}>
                    <Typography className={classes.fullName}>
                        {`${user.firstName || ""} ${user.lastName || ""}`}
                    </Typography>
                    <div className={classes.field}>
                        <Typography className={classes.fieldTitle}>{t`E-mail (Username)`}</Typography>
                        <Typography className={classes.fieldContent}>
                            {user?.email || ""}
                        </Typography>
                    </div>
                </div>
                <div className={classes.expireField}>

                    <FormControlLabel
                        style={{
                            marginRight: "30px"
                        }}
                        control={
                            <Checkbox
                                checked={withExpire}
                                onChange={(e: any) => setWithExpire(e.target.checked)}
                                name="expires"
                                disabled={isCurrentUser}
                            />
                        }
                        label="Invitation expires"
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {expireDate ? <DatePicker
                            disabled={!withExpire || isCurrentUser}
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={expireDate}
                            onChange={setExpireDate}
                            inputVariant="standard"
                            InputProps={{
                                disableUnderline: true
                            }}
                            style={{
                                marginTop: "8px"
                            }}
                        /> : <KeyboardDatePicker
                            disabled={!withExpire || isCurrentUser}
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={expireDate}
                            onChange={setExpireDate}
                            inputVariant="standard"
                            InputProps={{
                                disableUnderline: true,
                                classes: { root: classes.datePickerRoot }
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                            style={{
                                marginTop: "8px"
                            }}
                        />}
                    </MuiPickersUtilsProvider>
                </div>

                <PermissionsSummary
                    policies={policies}
                />

                {!isCurrentUser && <PermissionsTree
                    policies={policies}
                    setPolicies={setPolicies}
                />}

            </div>

            <div className={classes.buttonsContainer}>
                <Button
                    onClick={onClose}
                    width={150}
                    white
                    marginRight
                >
                    {t`Cancel`}
                </Button>
                {!isCurrentUser &&
                    <Button
                        onMouseUp={submit}
                        width={150}
                    >
                        {t`Update`}
                    </Button>}
            </div>

        </Dialog>
    );
};

export default EditUser;
