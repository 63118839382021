import { getIn } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import { MoreVert } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import CommonUtils from "../../utils/CommonUtils";
import styles from "./AddRule.style";
import AnomaliesUtils from "./AnomaliesUtils";
import { CustomAutoComplete, CustomedTextField, CustomSelect } from "./CustomedComponents";

const booleanSensors = [{ value: "=", label: "=" }, { value: "!=", label: "≠" }];
const valueBasedSensors = [{ value: "=", label: "=" }, { value: ">", label: ">" }, { value: "<", label: "<" }];
const enums: any = {
  129: [{ value: 0, name: t`OFF` }, { value: 1, name: t`ON` }],
  5: [{ value: 1, name: t`OPEN` }, { value: 0, name: t`CLOSE` }],
  6: [{ value: 1, name: t`OPEN` }, { value: 0, name: t`CLOSE` }]
};
const powerMeterParameters = [{ value: 60, label: t`Energy` }, { value: 59, label: t`Power` }];

const SensorCondition = (props: any) => {
  const {
    errors,
    touched,
    showWranning,
    name,
    condition,
    index,
    setFieldValue,
    disabled,
    options,
    isSensorSelected,
    isPowerMeterSelected
  } = props;

  const classes = styles();
  const getTemperatureScaleDisplay = useStoreState((s) => s.users.getTemperatureScaleDisplay);
  const { getDurationByMin, selectableDurations } = AnomaliesUtils;
  const isPreDefined = CommonUtils.isValidDuration(condition.duration) && selectableDurations.find((element) => getDurationByMin(element.value) === getDurationByMin(condition.duration));
  const [isCustomDuration, setIsCustomDuration] = useState<boolean>(!isPreDefined);

  const handletypeChange = (event: any, name1: any, condition: any) => {
    const { target: { value } } = event;
    const type = condition?.type;
    if (!type) {
      setFieldValue(name1, value);
      return;
    }

    if (isSensorSelected || isPowerMeterSelected) {
      showWranning(value, index, "type");
      return;
    }

    setFieldValue(name1, value);
    setFieldValue(`${name}[${index}].value`, "");
    setFieldValue(`${name}[${index}].operator`, "");

    if (condition.parameter) {
      setFieldValue(`${name}[${index}].parameter`, "");
    }

  };

  return (
    <div className={classes.conditionContainer}>
      <div className={classes.moreVertContainer}><MoreVert className={classes.whiteMoreVert} /></div>
      <CustomSelect
        className={classes.width180}
        error={getIn(errors, `${name}[${index}].type`) && getIn(touched, `${name}[${index}].type`)}
        placeholder="Type"
        name={`${name}[${index}].type`}
        onChange={(event: any) => handletypeChange(event, `${name}[${index}].type`, condition)}
        value={condition.type}
        options={options}
        disabled={disabled}
      />
      {condition.type === "powerMeter" &&
        <CustomSelect
          placeholder={t`Parameter`}
          className={classes.width180}
          error={getIn(errors, `${name}[${index}].parameter`) && getIn(touched, `${name}[${index}].parameter`)}
          onChange={(event: any) => setFieldValue(`${name}[${index}].parameter`, event.target.value)}
          value={condition.parameter}
          label="label"
          optionValue="value"
          options={powerMeterParameters}
          name={`${name}[${index}].parameter`}
          disabled={disabled}
        />

      }
      <CustomSelect
        placeholder="Operator"
        className={classes.width180}
        error={getIn(errors, `${name}[${index}].operator`) && getIn(touched, `${name}[${index}].operator`)}
        onChange={(event: any) => setFieldValue(`${name}[${index}].operator`, event.target.value)}
        value={condition.operator}
        label="label"
        optionValue="value"
        options={enums[condition.type] ? booleanSensors : valueBasedSensors}
        name={`${name}[${index}].operator`}
        disabled={disabled}
      />

      {enums[condition.type] ?
        (
          <CustomSelect disabled={disabled}
            className={classes.width180}
            placeholder={"Value"}
            onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
            value={condition.value + ""}
            label="name"
            optionValue="value"
            options={enums[condition.type]}
            notDisabledOptions={["default"]}
            name={`${name}[${index}].value`}
          />
        )
        : (<CustomedTextField
          disabled={disabled}
          className={(classes.width180Text)}
          error={getIn(errors, `${name}[${index}].value`) && getIn(touched, `${name}[${index}].value`)}
          onChange={(event: any) => setFieldValue(`${name}[${index}].value`, event.target.value)}
          value={condition.value}
          name={`${name}.${index}.value`}
          type="number"
          label={t`Value` + `${+condition.type === 1 ? `(${getTemperatureScaleDisplay()})` : ""}`}
        />)}

      <CustomAutoComplete
        disabled={disabled}
        error={getIn(errors, `${name}[${index}].duration`) && getIn(touched, `${name}[${index}].duration`)}
        placeholder="Duration"
        onChange={(event: any, value1: any) => {
          const value = value1?.value || "";
          setIsCustomDuration(value === "00:00");
          setFieldValue(`${name}[${index}].duration`, value);
        }}
        value={isPreDefined ? isPreDefined : { title: t`Custom`, value: "00:00" }}
        label="title"
        optionValue="value"
        options={[{
          title: t`Custom`,
          value: "00:00"
        }, ...selectableDurations]}
        name={`${name}[${index}].duration`}
      />

      {isCustomDuration && <CustomedTextField
        className={classes.width180}
        error={!CommonUtils.isValidDuration(condition.duration) || (getIn(errors, `${name}[${index}].duration`) && getIn(touched, `${name}[${index}].duration`))}
        placeholder={t`hh:mm`}
        name={`${name}[${index}].duration`}
        onChange={(event: any) => setFieldValue(`${name}[${index}].duration`, event.target.value)}
        value={condition.duration}
        label={t`Duration`}
        optionValue="value"
        disabled={disabled}
        maxLength={5}
      />}

    </div>
  );
};

export default SensorCondition;
