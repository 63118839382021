import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './OpenGlobalReport.style';
import { useStoreActions, useStoreState } from '@models/RootStore';

const OpenGlobalReportButton = ({closeParent}) => {

	const classes = useStyles();

	const setGlobalErrorReport = useStoreActions(actions => actions.errorMessage.setGlobalErrorReport);
	const globalErrorReport = useStoreState(state => state.errorMessage.globalErrorReport);

	const handleHereClick = () => {
		setGlobalErrorReport({
			...globalErrorReport,
			showGlobalReport:true,
		});
		closeParent?.();
	};

	return (
		<span>If issue is not resolved in a few minutes, please click <span className={classes.clickable} onClick={handleHereClick}>Here</span> to open a ticket</span>
	);
};

OpenGlobalReportButton.propTypes = {
	closeParent:PropTypes.func.isRequired
};

export default OpenGlobalReportButton;
