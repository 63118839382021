import React from "react";
import { Disconnect } from "../../icons";
import styles from "./AutomationRule.style";
import Condition from "./ConditionItem";
import { GButton } from "./CustomedComponents";

export default (props: any) => {
  const {
    errors,
    touched,
    unGroup,
    name,
    conditions,
    relation,
    groupKey,
    setFieldValue,
    serviceParamTypes,
    customEnumOptions,
    site,
    groupIndex,
    hideRelation,
    getServiceParams,
    controlParameters,
    disabled = false,
    duplicateGroup
  } = props;
  const classes = styles();

  return (
    <>
      <div className={classes.groupContainer}>
        <div className={classes.groupContent}>
          <div className={classes.ingroupConditionsContainer}>
            {conditions.map((condition: any, index: any) =>
              <Condition
                disabled={disabled}
                key={`${name}.${index}`}
                index={index}
                errors={errors}
                touched={touched}
                inGroup
                site={site}
                condition={condition}
                getServiceParams={getServiceParams}
                serviceParamTypes={serviceParamTypes}
                customEnumOptions={customEnumOptions}
                controlParameters={controlParameters}
                duplicateGroup={duplicateGroup}
                deleteCondition={(key: any) => {
                  if (conditions.length === 2) {
                    const conditionsToBeAdded = conditions.filter((condition1: any) => condition1.key !== condition.key);
                    unGroup(groupKey, conditionsToBeAdded);
                  } else {
                    setFieldValue(`${name}[${groupIndex}].conditions`, conditions.filter((condition1: any) => condition1.key !== condition.key));
                  }
                }}
                setFieldValue={setFieldValue}
                name={`groups[${groupIndex}].conditions`}
                lastCondition={index === (conditions.length - 1)}
              />)}
          </div>
          <div onClick={() => !disabled && unGroup(groupKey, conditions)} className={classes.dissconnnectRect}>
            <Disconnect className={classes.disconnectIcon} />
          </div>
        </div>
      </div>
      {!hideRelation &&
        <GButton disabled={disabled} selected={relation} onClick={(text: any) => setFieldValue(`${name}[${groupIndex}].relation`, text)} />
      }
    </>
  );
};
