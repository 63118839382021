import coolremoteSDK, { PowerMeter as sdkPowerMeter, Site as sdkSite } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  thunk,
  Thunk
} from "easy-peasy";
import _ from "lodash";
import { IGroup } from "./Groups";
import { IRootStoreModel } from "./RootStore";

export interface IPowerMeter {
  isConnected: boolean;
  id: string;
  identifier: string;
  name: string;
  site: string;
  device: string;
  system: string;
  ca: string;
  model: string;
  systems?: string[];
  type: number;
  power?: number;
  lastReadingTimestamp?: number;
}

export interface IPowerMeterMap {
  [key: string]: IPowerMeter;
}

export interface IPowerMetersModel {
  allPowerMeters: IPowerMeterMap;
  initialize: Action<IPowerMetersModel, any>;
  updatePowerMeter: Thunk<IPowerMetersModel, { id: string; data: any }>;
  updatePowerMeterState: Action<IPowerMetersModel, any>;
  getAllPowerMeters: Thunk<IPowerMetersModel>;
  getPowerMeter: Computed<
    IPowerMetersModel,
    (powerMeterId: string | null | undefined) => IPowerMeter | undefined,
    IRootStoreModel
  >;
  getPowerMeterName: Computed<
    IPowerMetersModel,
    (powerId: string | null | undefined) => string,
    IRootStoreModel
  >;
  getPowerStats: Thunk<IPowerMetersModel, { id: string, data: any }>;
  getAllPowerStats: Thunk<IPowerMetersModel, { siteId: any, data: any }>;
  createVirtualPowerMeter: Thunk<IPowerMetersModel, any>;
  updateMultiPowerMeter: Thunk<IPowerMetersModel, { powerMetersData: any }>;
  deletePowerMeter: Thunk<IPowerMetersModel, string>;
  getCustomersPowerMetersData: Computed<
    IPowerMetersModel,
    () => any,
    IRootStoreModel
  >;
  updatePowerMeterPowerLocally: Action<IPowerMetersModel, { id: string, power: number, lastReadingTimestamp: number }>;
  fetchSitePowerMeters: Thunk<IPowerMetersModel, string>;
}

export const powerMetersModel: IPowerMetersModel = {
  allPowerMeters: {},

  initialize: action((state, payload) => {
    state.allPowerMeters = { ...state.allPowerMeters, ...payload };
  }),
  updatePowerMeter: thunk(async (actions, payload) => {
    const system = payload.data.system;
    const updatedPowerMeter = await coolremoteSDK.PowerMeter.updatePowerMeter(
      payload.id,
      payload.data
    );
    actions.updatePowerMeterState({
      ...updatedPowerMeter.data,
      ...{ system }
    });
    return updatedPowerMeter;
  }),

  updatePowerMeterState: action((state, payload) => {
    state.allPowerMeters[payload.id] = payload;
  }),
  updatePowerMeterPowerLocally: action((state, payload) => {
    const { id, power, lastReadingTimestamp } = payload;
    if (state.allPowerMeters[id]) {
      state.allPowerMeters[id].power = power;
      state.allPowerMeters[id].lastReadingTimestamp = lastReadingTimestamp;
    }
  }),
  getAllPowerMeters: thunk(async (actions) => {
    const data = await sdkPowerMeter.find();
    actions.initialize(data);
    return data;

  }),
  getPowerMeter: computed([state => state.allPowerMeters], allPowerMeters =>
    memo(powerMeterId => {
      if (_.isNil(powerMeterId)) return undefined;
      return allPowerMeters[powerMeterId];
    }, 100)
  ),
  getPowerMeterName: computed([(state) => state.allPowerMeters], (allPowerMeters) =>
    memo((powerId) => {
      if (!powerId) { return "-"; }
      return allPowerMeters[powerId]?.name;
    }, 100)
  ),
  getPowerStats: thunk(async (actions, payload) => {
    const data = await sdkPowerMeter.getPowerMeterStats(payload.id, payload.data);
    return data;
  }),
  getAllPowerStats: thunk(async (actions, payload, { getState }) => {
    const powerMeters = getState().allPowerMeters;
    const ids: any = [];

    Object.values(powerMeters).forEach((powerMeter: any) => {
      if (powerMeter.site === payload.siteId) {
        ids.push(powerMeter.id);
      }
    });

    const data = await sdkPowerMeter.getMultiPowerMetersStats(ids, payload.data);
    return data;
  }),
  createVirtualPowerMeter: thunk(async (actions, payload) => {
    const data = await sdkPowerMeter.createVirtualPowerMeter(payload);
    return data;
  }),
  updateMultiPowerMeter: thunk(async (actions, payload) => {
    const data = await sdkPowerMeter.updateMultiPowerMeter(payload);
    return data;
  }),
  deletePowerMeter: thunk(async (actions, payload) => {
    const data = await sdkPowerMeter.deletePowerMeter(payload);
    return data;
  }),
  getCustomersPowerMetersData: computed([(state) => state.allPowerMeters,
  (state, storeState: any) => storeState.sites.allSites],
    (allPowerMeters, allSites) =>
      () => {
        const powerMetersPerSite: any = {};
        const customerPowerMeters: any = {};

        Object.values(allPowerMeters).forEach((powerMeter: any) => {
          const { site: siteId, id } = powerMeter;
          customerPowerMeters[id] = powerMeter;

          if (powerMetersPerSite[siteId]) {
            powerMetersPerSite[siteId].push(id);
          } else {
            powerMetersPerSite[siteId] = [id];
          }
        });

        const powerMeterOption = _.isEmpty(customerPowerMeters) ? null : { value: "powerMeter", name: "Power Meter" };
        return { powerMeterOption, powerMetersPerSite, customerPowerMeters };
      }),
  fetchSitePowerMeters: thunk(async (actions, payload) => {
    const data = await sdkSite.getSitePowerMeters(payload, 0);
    actions.initialize(data);
    return data;

  })
};
