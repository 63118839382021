import { makeStyles } from "@material-ui/core";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => ({
  selectionsContainer: {
    whiteSpace: "nowrap",
    width: "70%"
  },
  selectUnitButton: {
    width: "270px",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    ...placeBackgroundColor(theme.palette.background.topDropDownSelect),
    color: theme.palette.colors.menuFont,
    "&:hover": {
      ...placeBackgroundColor(theme.palette.background.topDropDownSelect),
    }
  },
  selectUnitButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectUnitButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  selectDateRangeButton: {
    width: "20%",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  selectDateRangeButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  selectDateRangeButton__icon: {
    "&>svg": {
      fontSize: "0.5rem"
    }
  },
  datePicker: {
    input: {
      color: "white"
    }
  },
  dateRangePickerDialog: {
    "& .MuiPickersCalendar-transitionContainer": {
      minHeight: 218,
      marginTop: 10
    }
  },

  selectedUnitStyle: {
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  paperBg: { background: "transparent" },
  btnDisabled: {
    cursor: "default",
    pointerEvents: "none",
    opacity: 0.6
  },
  Arrow: {
    marginLeft: "0px",
    "&>svg": {
      fontSize: "36px !important"
    }
  }
}));
export default useStyles;
