import {
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import clsx from "clsx";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { TopBar } from "../../components";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ArrowBack, Delete, DisconnectIcon, Edit } from "../../svgComponents";
import styles from "./addSystem.styles";

const lineOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 }
];

const AddEditSystem = (props: any) => {
  const [formVals, setFormVals] = useState<any>({
    name: "",
    brandNum: "",
    model: "",
    line: 1
  });
  const [deviceUnits, setDeviceUnits] = useState<any>({});
  const [deviceSystems, setDeviceSystems] = useState<any>({});
  const [lineSystems, setLineSystems] = useState<any>({});
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [editedUnitName, setEditedUnitName] = useState<any>("");
  const [brandsOptions, setBrandsOptions] = useState<any>([]);
  const [selectedSite, setSelectedSite] = useState<any>("");
  const types = useStoreState((state) => state.types);
  const { hvacBrands } = types;

  const { history, match } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    params: { systemId, deviceId }
  } = match;
  const isAdd = !systemId;
  const { unitTypes } = types;
  const getSystem = useStoreState((state) => state.systems.getLocalSystem);
  const getDevice = useStoreState((s) => s.devices.getDevice);
  // const socketMessages = useStoreState((state) => state.sites.socketMessages);
  const getDeviceSystems = useStoreState((state) => state.devices.getDeviceSystems);
  const getDeviceUnits = useStoreState((state) => state.devices.getDeviceUnits);
  const createSystem = useStoreActions((actions) => actions.systems.createSystem);
  const startLoader = useStoreActions((action) => action.loader.startLoader);
  const finishLoader = useStoreActions((action) => action.loader.finishLoader);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const updateUnit = useStoreActions((actions) => actions.units.updateUnit);
  const updateSystem = useStoreActions((actions) => actions.systems.updateSystem);
  const deleteUnit = useStoreActions((action) => action.units.deleteUnit);
  const system = getSystem(systemId);
  const { canUpdate = true } = system || {};
  // useEffect(() => {
  //   if (_.isEmpty(socketMessages)) {
  //     return;
  //   }

  //   const { item, status, unitId } = socketMessages;

  //   if (item === "device") {
  //     return;
  //   }

  //   if (item === "unit" && !_.isEmpty(deviceUnits)) {
  //     if (!deviceUnits[unitId]) {
  //       return;
  //     }

  //     if (status && !deviceUnits[unitId].isConnected) {
  //       setDeviceUnits({
  //         ...deviceUnits,
  //         [unitId]: { ...deviceUnits[unitId], isConnected: true }
  //       });
  //       return;
  //     }

  //     if (!status && deviceUnits[unitId].isConnected) {
  //       setDeviceUnits({
  //         ...deviceUnits,
  //         [unitId]: { ...deviceUnits[unitId], isConnected: false }
  //       });
  //     }
  //   }
  // }, [deviceUnits, socketMessages]);

  const hexToAscii = (hexString: string) => {
    let asciiString = "";

    for (let i = 0; i < hexString.length; i += 2) {
      asciiString += String.fromCharCode(parseInt(hexString.substr(i, 2), 16));
    }
    return asciiString;
  };

  const goBack = useCallback(() => {
    history.push(`/site-management?selectedSite=${selectedSite}`);
  }, [history, selectedSite]);

  useEffect(() => {
    if (!systemId) {
      const device = getDevice(deviceId);
      setSelectedSite(device?.site);

      return;
    }
    let systemLine: any = null;

    setSelectedSite(system?.site);
    const { name = "", line, brandNum, model } = system || {};
    systemLine = line;
    setFormVals({
      name,
      brandNum,
      model,
      line
    });

    const units = getDeviceUnits(deviceId, system?.id, "control");
    setDeviceUnits(units);

    const deviceSys = getDeviceSystems(deviceId);
    setDeviceSystems(deviceSys);
    const sysArr = deviceSys && Object.values(deviceSys);
    const lineSys: any = {};
    for (let sys in sysArr) {
      const currentSys: any = sysArr[sys];
      if (currentSys.line === systemLine) {
        lineSys[currentSys.id] = {
          label: currentSys.name,
          value: currentSys.id
        };
      }
    }

    setLineSystems([
      { label: "None", value: null },
      ...Object.values(lineSys)
    ]);
  }, [
    systemId,
    getSystem,
    addMessage,
    startLoader,
    finishLoader,
    getDeviceSystems,
    deviceId,
    getDeviceUnits,
    goBack,
    system
  ]);

  useEffect(() => {
    if (!types || !hvacBrands) {
      return;
    }

    const options = [];
    for (let brand of hvacBrands) {
      options.push({ value: brand.value, label: brand.name });
    }

    setBrandsOptions(options);
  }, [types]);

  const handleUnitSystemChange = (systemId: string, unitId: string) => {
    const updatedDeviceUnits = {
      ...deviceUnits,
      [unitId]: { ...deviceUnits[unitId], system: systemId }
    };
    setDeviceUnits(updatedDeviceUnits);
  };
  const handleSystemSelect = (
    newSystem: any,
    unitId: string,
    oldSystem: string
  ) => {
    const { value: newSystemId } = newSystem;
    handleUnitSystemChange(newSystemId, unitId);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    brandNum: Yup.string().required(t`required field`),
    model: Yup.string(),
    line: Yup.string().required(t`required field`)
  });
  const updateSystemValidationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    model: Yup.string()
  });

  const createNewSystem = (values: any) => {
    startLoader();
    const data = {
      name: values.name,
      brandNum: values.brandNum,
      model: values.model,
      line: values.line,
      device: deviceId
    };
    createSystem(data)
      .then(() => {
        goBack();
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => finishLoader());
  };

  const onSubmitAction = (values: any) => {
    if (isAdd) {
      createNewSystem(values);
      return;
    }

    editSystem(values);
  };

  const editSystem = (values: any) => {
    startLoader();
    updateSystem({
      systemId,
      updatedData: {
        name: values.name,
        model: values.model,
        line: values.line
      }
    })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const saveUnit = (unitId: string, unit: any) => {
    setSelectedUnit(null);

    if (!unit.name) {
      return;
    }
    startLoader();
    updateUnit({ id: unitId, updatedData: { ...unit } })
      .then(() => {
        setDeviceUnits({
          ...deviceUnits,
          [unitId]: { ...deviceUnits[unitId], ...unit }
        });
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => finishLoader());
  };

  const handleUnitNameChange = (event: any, id: any) => {
    const name = event.target.value;

    const updatedDeviceUnits = {
      ...deviceUnits,
      [id]: { ...deviceUnits[id], name }
    };
    setEditedUnitName(name);
    setDeviceUnits(updatedDeviceUnits);
  };

  const deleteSelectedUnit = (unitId: string) => {
    startLoader();
    deleteUnit(unitId)
      .then(() => {
        delete deviceUnits[unitId];
        setDeviceUnits(deviceUnits);
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };

  return (
    <div className={classes.screenContainer}>
      <div className={classes.container}>
        <TopBar
          leftIconComponent={<ArrowBack />}
          leftAction={() => goBack()}
          title={isAdd ? t`Add System` : t`System Details`}
        />
        <div className={classes.content}>
          <Formik
            initialValues={formVals}
            onSubmit={onSubmitAction}
            enableReinitialize={true}
            validationSchema={
              isAdd ? validationSchema : updateSystemValidationSchema
            }
            render={({ values, setFieldValue, dirty, errors, ...formikProps }) => {
              return (
                // @ts-ignore
                <Form translate="yes" className={classes.formStyle}>
                  <div className={classes.fieldsContainer}>
                    <TextField
                      disabled={!canUpdate}
                      variant={"outlined"}
                      name="name"
                      label={t`System Name`}
                      placeholder={t`System Name`}
                      value={values?.name}
                      error={!!errors.name}
                      onChange={(event: any) => setFieldValue("name", event.target.value)}
                      className={classes.inputFieldStyle}
                    />
                    <FormControl variant="outlined" className={classes.selectStyle}>
                      <InputLabel>Brand</InputLabel>
                      <Select
                        disabled={!isAdd || !canUpdate}
                        label={t`Brand`}
                        name="brandNum"
                        value={values?.brandNum}
                        onChange={(event: any) => {
                          setFieldValue("brandNum", event.target.value);
                        }}
                        MenuProps={{
                          classes: { paper: classes.menuItemsContainer }
                        }}
                      >
                        {!!brandsOptions.length ?
                          brandsOptions.map((brandOption: any) => (
                            <MenuItem value={brandOption.value} key={brandOption.value}>{brandOption.label}</MenuItem>
                          ))
                          : <MenuItem key={`no-brand-option`} disabled value={""}>
                            {t`No brand Options`}
                          </MenuItem>}
                      </Select>
                    </FormControl>
                    <TextField
                      disabled={!canUpdate}
                      variant={"outlined"}
                      name="model"
                      label={t`Model`}
                      placeholder={t`Model`}
                      value={values?.model}
                      className={classes.inputFieldStyle}
                      error={!!errors.model}
                      onChange={(event: any) => setFieldValue("model", event.target.value)}
                    />
                    <FormControl variant="outlined" className={classes.selectStyle}>
                      <InputLabel>Device Line</InputLabel>
                      <Select
                        disabled={!isAdd || !canUpdate}
                        value={values?.line}
                        error={!!errors.line}
                        label={t`Device Line`}
                        onChange={(event: any) =>
                          setFieldValue("line", event.target.value)
                        }
                        MenuProps={{
                          classes: { paper: classes.menuItemsContainer }
                        }}
                      >
                        {!!lineOptions.length ?
                          Object.values(lineOptions).map((line: any) => (
                            <MenuItem value={line.value} key={line.value}>{line.label}</MenuItem>
                          ))
                          : <MenuItem key={`no-line-option`} disabled value={""}>
                            {t`No line Options`}
                          </MenuItem>}
                      </Select>
                    </FormControl>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.saveButtonStyle}
                    disabled={!dirty}
                  >
                    {isAdd ? `Create System` : `Update`}
                  </Button>

                  <div
                    className={clsx(classes.systemUnitsSection, {
                      [classes.hide]: isAdd
                    })}
                  >
                    <Collapse
                      in={true}
                      timeout="auto"
                      className={classes.unitsContainer}
                      unmountOnExit
                    >
                      {Object.values(deviceUnits).map(
                        (unit: any, index: number) => {
                          const {
                            id: unitId,
                            name: unitName,
                            type: unitType,
                            system: unitSystem,
                            proId,
                            isConnected,
                            line,
                            privateId,
                            permissions = {}
                          } = unit;

                          const { canUpdate, canDelete } = permissions;

                          if (unitType !== unitTypes.indoor) {
                            return null;
                          }
                          const unitSystemOption = deviceSystems[unitSystem];

                          let unitSystemOptionValue = {
                            label: unitSystemOption && unitSystemOption.name,
                            value: unitSystemOption && unitSystemOption.id
                          };

                          if (!unitSystem) {
                            unitSystemOptionValue = {
                              label: "None",
                              value: ""
                            };
                          }

                          const isEditable = selectedUnit === unitId;
                          if (+unit.line !== +formVals.line) {
                            return <Fragment key={`${index}-unused`} />;
                          }
                          return (
                            <div
                              key={`unit-${unitId}`}
                              className={clsx(classes.unitContainerStyle, {
                                [classes.highlighted]: !isConnected
                              })}
                            >
                              {!isConnected && (
                                <IconButton
                                  disabled={!canDelete}
                                  className={classes.deleteIconStyle}
                                  onClick={() => deleteSelectedUnit(unitId)}
                                >
                                  <Delete />
                                </IconButton>
                              )}

                              <div className={classes.rowStyle}>
                                <InputLabel className={classes.labelStyle}>
                                  {t`Unit Name`}
                                </InputLabel>
                                <TextField
                                  value={unitName}
                                  onChange={(e) =>
                                    handleUnitNameChange(e, unitId)
                                  }
                                  disabled={selectedUnit !== unitId || !canUpdate}
                                  inputProps={{
                                    className: classes.textFieldInputStyle
                                  }}
                                  className={classes.textFieldsStyle}
                                  InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {!isEditable ? (
                                          <>
                                            <span className={classes.address}>
                                              {privateId && line ? `L${line}.${privateId}` : ""}
                                            </span>
                                            <Edit
                                              disabled={!canUpdate}
                                              className={classes.iconPadding}
                                              onClick={() =>
                                                setSelectedUnit(unitId)
                                              }
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <span className={classes.address}>
                                              {privateId && line ? `L${line}.${privateId}` : ""}
                                            </span>

                                            <Check
                                              className={clsx(
                                                classes.iconPadding,
                                                classes.opacityStyle,
                                                classes.iconColor
                                              )}
                                              onClick={() =>
                                                !canUpdate
                                                  ? {}
                                                  : saveUnit(unitId, {
                                                      name: editedUnitName
                                                    })
                                              }
                                            />
                                          </>
                                        )}
                                      </InputAdornment>
                                    ),
                                    startAdornment: !isConnected && (
                                      <InputAdornment position="start">
                                        <DisconnectIcon />
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </div>
                              <Divider className={classes.dividerStyle} />
                              <div className={classes.rowStyle}>
                                <InputLabel className={classes.labelStyle}>
                                  {t`System`}
                                </InputLabel>
                                <Select
                                  className={classes.selectStyle}
                                  variant={"outlined"}
                                  disabled={isAdd || !canUpdate}
                                  onChange={(value: any) =>
                                    handleSystemSelect(
                                      value,
                                      unitId,
                                      unitSystem
                                    )
                                  }
                                  MenuProps={{
                                    classes: {
                                      paper: classes.menuItemsContainer
                                    }
                                  }}
                                />
                              </div>
                              <Divider className={classes.dividerStyle} />
                              <div className={classes.rowStyle}>
                                <InputLabel className={classes.labelStyle}>
                                  {t`Unit Type`}
                                </InputLabel>
                                <TextField
                                  value={unitTypes[unitType]}
                                  disabled={true}
                                  inputProps={{
                                    className: clsx(
                                      classes.textFieldInputStyle,
                                      classes.opacityStyle
                                    )
                                  }}
                                  className={classes.textFieldsStyle}
                                  InputProps={{ disableUnderline: true }}
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                    </Collapse>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditSystem;
