import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  dialogPaper: {
    width: "fit-content",
    maxWidth: "95% !important",
    minWidth: "850px",
    minHeight: "485px",
    height: "fit-content",
    maxHeight: "calc(100vh - 140px)",
    borderRadius: "8px",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    justifyContent: "space-between",
    overflow: "hidden"
  },
  paperTableContainer: {
    boxSizing: "border-box",
    marginTop: "1rem",
    // marginTop: 0,
    overflow: "auto",
    height: "100%",
    // padding: "20px",
    maxHeight: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
  },
  tableContainer: {
    display: "flex"
  },
  table: {
    background: theme.palette.background.paper
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  iconsColumnWidth: { maxWidth: "7rem", width: "5rem" },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  overWritePadding: {
    ...theme.tables.tableCell,
    padding: "0 15px"
  },
  overWritePaddingRow: {
    fontSize: "0.8rem",
    padding: "0 15px",
    cursor: "pointer"
  },
  drawer: {
    width: 360,
    flexShrink: 0
  },
  drawerPaper: {
    width: 360,
    position: "absolute",
    zIndex: 999999,
    overflow: "hidden"
  },
  content: {
    flexGrow: 1,
    padding: "1rem",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -360,
    overflow: "auto",
    paddingBottom: 0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  dialogContent: {
    overflow: "hidden",
    // overflowX: "hidden",
    display: "flex",
    flexFlow: "row nowrap",
    flex: 1
    // position: "relative"
  },
  textField: {
    maxHeight: "30px",
    maxWidth: "190px",
    "& div": {
      maxHeight: "30px"
    }
  },
  opacityStyle: {
    opacity: 0.2,
    backgroundColor: "#000",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  }

}));
export default useStyles;
