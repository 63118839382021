import { ClickAwayListener, FormControl, IconButton, MenuItem, Select, Tooltip as MUIToolTip, Typography, Divider } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { t } from "ttag";
import DateRangePicker from "../../cool_widgets/DateRangePicker";
import MonthPicker from "../../cool_widgets/MonthPicker";
import { ExportPDF, Maximize, ZoomOut } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import GraphPlaceHolder from "../Reports/GraphPlaceHolder";

const PowerReport = ({ siteId, classes, setIsLoading, id, maxed, maximize, small = false, updatedDate }: any) => {

  const firstUpdate = useRef(true);
  const [openTt, setOpenTt] = useState<boolean>(false);
  const [loadDataFailed, setLoadDataFailed] = useState<boolean>(false);
  const [sysOptions, setSysOptions] = useState<any>([]);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const getPowerData = useStoreActions((actions) => actions.ReportsData.getPowerData);
  const setInfo = useStoreActions((actions) => actions.ReportsData.setPowerData);
  const graphInfo = useStoreState((state) => state.ReportsData.PowerReportsData);
  const { date, data: dataObject, system: sysId } = graphInfo;
  const { data, total, average } = dataObject;

  useEffect(() => {
    updatedDate && setInfo({ date: updatedDate });
  }, [updatedDate, siteId]);

  useEffect(() => {
    if (!siteId) {
      return;
    }

    const options = Object.values(allSystems).reduce((result: any, item: any) => {
      if (item.site === siteId) {
        result.push(<MenuItem value={item.id}>{item.name}</MenuItem>);
      }
      return result;
    }, []);

    setSysOptions(options);
  }, [siteId]);

  useEffect(() => {
    if (!data.length && !!siteId && !!date) {
      setLoadDataFailed(true);
    }
  }, [data]);

  useEffect(() => {
    setLoadDataFailed(false);
    //prevent firing at first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!siteId || !date) {
      return;
    }
    setIsLoading(true);
    getPowerData({ site: siteId, date, system: sysId })
      .catch(() => {
        setLoadDataFailed(true);
      })
      .finally(() => setIsLoading(false));
  }, [date, siteId, sysId]);

  const CustomizedAxisTick = (props: any) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize="12px" fontWeight="bold">
          {moment.utc(payload.value).format("D")}
        </text>
      </g>
    );
  };

  const setGraphDate = (date: any) => {
    setInfo({ date });
  };

  const isMaxed = id === maxed;

  return (
    <div className={clsx(classes.reportContainer, isMaxed && classes.maxedContainer, small && classes.smallContainer)} id={!data.length ? "powerXX" : "powerGraphId"}>
      <div className={classes.reportContainerHeader}>
        <Typography className={classes.reportTitle}>{t`Power Consumption*`}</Typography>
        <div className={classes.headerMid}>

          <FormControl
            style={{ width: "150px", backgroundColor: "#fff", marginRight: "10px" }}
            variant="outlined"
          >
            <ClickAwayListener onClickAway={() => setOpenTt(false)}>
              <MUIToolTip
                title={!siteId ? t`Please select a site from the top bar first` : ""}
                open={openTt}
                PopperProps={{
                  disablePortal: true
                }}
                onClose={() => setOpenTt(false)}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow

              >

                <Select
                  value={sysId}
                  onChange={(e: any) => setInfo({ system: e.target.value })}
                  style={{ width: "100%", height: "36px" }}
                  displayEmpty
                  disabled={!siteId}
                  onClick={!siteId ? () => setOpenTt(true) : () => { }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  <MenuItem value={""}>{t`All Systems`}</MenuItem>
                  {sysOptions}
                </Select>
              </MUIToolTip>
            </ClickAwayListener>
          </FormControl>

          <DateRangePicker disabled={!siteId} date={date} setDate={(date: any) => setInfo({ date })} autoSelectMonth={true} />
        </div>
        <div className={classes.reportHeaderActions}>
          <IconButton onClick={() => maximize(id)} data-html2canvas-ignore="true">
            {isMaxed ? <ZoomOut /> : <Maximize />}
          </IconButton>
        </div>
      </div>
      <div className={classes.reportContainerBody}>
        {!!data.length && <Typography style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>{t`*Estimated`}</Typography>}
        {!!data.length ? (<div className={classes.customDoubleGraphCont}>

          <ResponsiveContainer width="100%" height={"75%"}>
            <BarChart
              data={data}
              width={500}
              height={300}
              margin={{
                top: 0,
                right: 20,
                left: 0,
                bottom: 10
              }}
            >
              <XAxis
                dataKey="timestamp"
                tick={<CustomizedAxisTick />}
                interval={0}
              />
              {data?.length && <YAxis
                yAxisId="left"
                dataKey={"power"}
                domain={[0, "dataMax + 10"]}
                name={t`Power`}
                unit={"kWh"}
                tick={{ fontSize: "10px" }}
                tickFormatter={(value: any) => Math.round(value)}
              />}
              {
                data?.length &&
                <Tooltip
                  formatter={(value: any) => `${Math.round(value * 10) / 10} kWh`}
                  labelFormatter={(val: any) => moment.utc(val).format("MMM Do, YYYY")}
                  cursor={{ fill: "rgba(128,128,128,0)" }}
                />
              }
              <Bar
                dataKey={"power"}
                stackId="a"
                yAxisId="left"
                fill={"#4b1c46"}
              />
            </BarChart>
          </ResponsiveContainer>
          <div className={classes.customLegend}>
            {!!data.length &&
              <>
                <div className={classes.legendBlock} style={{ flex: 1 }}>
                  <div className={classes.legendBlockInfo}>
                    <Typography style={{ width: "100%" }}>{t`Power Consumption`}</Typography>
                    <div className={classes.LegendTemp} >
                      <Typography><span>{t`Average daily`}&nbsp;</span><span className={classes.legendTempVal}>{Number(average.toFixed(1))}</span></Typography>
                      <Divider orientation="vertical" className={classes.legendTempDivider} />
                      <Typography><span>{t`Total`}&nbsp;</span><span className={classes.legendTempVal}>{Number(total.toFixed(1))}</span></Typography>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>

        </div>) :
          <GraphPlaceHolder loadDataFailed={loadDataFailed} />
        }
      </div>

    </div >
  );
};

export default PowerReport;
