import { makeStyles } from "@material-ui/core";

export default makeStyles({
  menuStyle: { "& ul": { display: "flex", flexFlow: "column nowrap" } },
  menuExtraWidth: {
    minWidth: '430px !important',
    maxWidth: '430px !important',
    width: '430px !important',
    marginLeft: '-100px'
  },
  menuHeader: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "9px",
    marginBottom: "10px"
  },
  menuItemStyle: { padding: 0, width: "100%" },
  applyBtn: { margin: "15px 0", width: "90%", alignSelf: "center" },
  resetBtn: { width: "90%", alignSelf: "center", textTransform: "capitalize", fontSize: "16px" },
  optionsContainer: { width: "100%", maxHeight: "150px", overflow: "auto" },
  optionTextStyle: {
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    }
  }
});

export const paperStyle = {
  marginTop: "43px",
  borderRadius: "6px",
  boxShadow: "rgba(0, 0, 0, 0.46) 0px 6px 17px 3px",
  backgroundColor: "#FFF",
  padding: "5px",
  minWidth: "200px",
  maxWidth: "200px",
  minHeight: "150px",
  maxHeight: "320px",
  overflow: "hidden"
};
