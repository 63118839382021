import { Divider, IconButton, Typography } from "@material-ui/core";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import styles from "../../components/Loading/Loader.module.css";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import DateRangePicker from "../../cool_widgets/DateRangePicker";
import { ExportPDF } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import DiversityReport from "../Reports/DiversityReport";
import ModeReport from "../Reports/ModeReport";
import AlertsReport from "./alertsReport";
import PowerReport from "./PowerReport";
import useStyles from "./reports.style";
import SetpointMainteneance from "./SetpointMainteneance";
import StandByTime from "./StandByTime";
import { IUnit } from "../../models/Units";
import useChurnZero from "@hooks/useChurnZero";

const SiteReportsScreen = () => {
    const classes = useStyles();
    const history = useHistory();
    const params: any = useParams();
    const divRef = useRef(null);
    const [isExporting, setIsExporting] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [maxed, setMaxed] = useState<string>("");
    const [stats, setStats] = useState<{ sites: number, ODU: number, IDU: number, totalCapacity: number }>({ sites: 0, ODU: 0, IDU: 0, totalCapacity: 0 });
    const [date, setDate] = useState<any>("");

    const { customerId, siteId } = useStoreState((state) => state.selections.selections);
    const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
    const updateSelections = useStoreActions((actions) => actions.selections.updateSelections);
    const allSites = useStoreState((state) => state.sites.allSites);
    const allSystems = useStoreState((state) => state.systems.allSystems);
    const allUnits = useStoreState((state) => state.units.allUnits);
    const { unitTypes } = useStoreState((state) => state.types);

    const {trackEvent} = useChurnZero()


    const calculateUnitCapacity = (unit: IUnit) => {
        const { capacity, capacityMeasurementUnits } = unit;
        switch (capacityMeasurementUnits) {
            case 1:
                return Number(capacity) * 0.21;
            case 2:
                return Number(capacity) * 0.28;
            case 3:
                return Number(capacity) / 12000;
            default:
                return 0;
        }
    }

    useEffect(() => {
        const onESC = (ev: any) => {
            if (ev.key === "Escape") {
                maximize("");
            }
        };
        window.addEventListener("keyup", onESC, false);
        return () => {
            window.addEventListener("keyup", onESC, false);
        };
    }, []);

    useEffect(() => {
        if (!params.siteId && !!siteId) {
            history.push(`/site-reports-live/${siteId}`);
            return;
        }

        if (siteId !== params.siteId && params.siteId) {
            updateSelections({ type: "site", data: params.siteId });
        }
    }, [params.siteId]);

    useEffect(() => {
        if (!allSites || !allUnits) {
            return;
        }

        const numOfSites = Object.keys(allSites)?.length;

        const unitsCount = Object.values(allUnits).reduce((result: any, unit: any) => {
            if (!!siteId && unit.site !== siteId) {
                return result;
            }
            if (unit.type === unitTypes["indoor"] && unit.isVisible) {
                result.indoor += 1;
            }
            if (unit.type === unitTypes["outdoor"]) {
                result.outdoor += 1;
            }
            return result;
        }, { indoor: 0, outdoor: 0 });

        const totalCapacity = Object.values(allUnits).reduce((sum: any, unit: any) => {
            if ((!!siteId && unit.site !== siteId) || unit.type !== unitTypes["outdoor"]) {
                return sum;
            }
            return sum + calculateUnitCapacity(unit)
        }, 0);

        setStats({ sites: numOfSites, ODU: unitsCount.outdoor, IDU: unitsCount.indoor, totalCapacity: totalCapacity });

    }, [allSites, allUnits, siteId]);

    const maximize = (id: string) => {
        if (maxed) {
            setMaxed("");
            return;
        }
        setMaxed(id);
        const ref: any = divRef.current;
        ref?.scrollTo(0, 0);
    };

    const saveAs = (blob: any, fileName: any) => {
        let elem: any = window.document.createElement("a");
        elem.href = blob;
        elem.download = fileName;
        elem.style = "display:none;";
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === "function") {
            elem.click();
        } else {
            elem.target = "_blank";
            elem.dispatchEvent(new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true
            }));
        }
        URL.revokeObjectURL(elem.href);
        elem.remove();
    };

    const exportPdf = async () => {
        setIsExporting(true);
        const doc = new jsPDF("l", "mm", "a4");
        const graphIds = ["modeGraphId", "standByTimeId", "powerGraphId", "alertsGraphId", "diversityGraphId", "maintenanceGraphId"];

        let first = true;
        for (let i in graphIds) {
            const id = graphIds[i];

            const el: any = document.getElementById(id);
            if (!el) {
                continue;
            }

            if (!first) {
                doc.addPage();
            }
            first = false;

            const canvas = await html2canvas(el);
            const image = canvas.toDataURL("image/jpeg", 1.0);
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();

            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;

            const marginX = (pageWidth - canvasWidth) / 2;
            const marginY = (pageHeight - canvasHeight) / 2;

            doc.addImage(image, "JPEG", marginX, marginY, canvasWidth, canvasHeight);

        }

        const site: any = allSites[siteId as any];
        doc.save(`${site?.name}-reports`);
        setIsExporting(false);
    };

    const handleExportPDFClick = () => {
      exportPdf();
      trackEvent('ReportExport','The user clicked on export report');
    };

    return (
        <div className={classes.view}>
            <ServiceNavigationBar />
            <div className={classes.contentArea}>
                {isLoading &&
                    <div className={classes.loaderContainer}>
                        <div id="loader" className={styles.redMainLoader} />
                    </div>
                }

                <Header
                    hideUnitSelection
                    hideSystemSelection
                    onSiteSelect={(id) => history.push(`/site-reports-live/${id}`)}
                    screenTitle="reports"
                />
                <div className={classes.content} ref={divRef} style={{ overflowY: maxed ? "hidden" : "auto" }}>
                    <div className={classes.reportsHeader}>
                        <Typography className={classes.customerName}>
                            {siteId && allSites[siteId] ? allSites[siteId]?.name : (customerId && getCustomerName(customerId))}
                        </Typography>
                        <div className={classes.headerInfoContainer}>
                            {!siteId &&
                                <>
                                    <div className={classes.headerInfoSection}>
                                        <Typography className={classes.headerInfoVal}>{stats.sites}</Typography>
                                        <Typography className={classes.headerInfoTitle}>{t`Sites`}</Typography>
                                    </div>

                                    <Divider orientation={"vertical"} className={classes.headerInfoDivider} />
                                </>
                            }

                            <div className={classes.headerInfoSection}>
                                <Typography className={classes.headerInfoVal}>{Object.values(allSystems).filter((item: any) => !siteId || item.site === siteId)?.length}</Typography>
                                <Typography className={classes.headerInfoTitle}>{t`Systems`}</Typography>
                            </div>

                            <Divider orientation={"vertical"} className={classes.headerInfoDivider} />

                            <div className={classes.headerInfoSection}>
                                <Typography className={classes.headerInfoVal}>{stats.ODU}</Typography>
                                <Typography className={classes.headerInfoTitle}>{t`Outdoor Units`}</Typography>
                            </div>

                            <Divider orientation={"vertical"} className={classes.headerInfoDivider} />

                            <div className={classes.headerInfoSection}>
                                <Typography className={classes.headerInfoVal}>{stats.IDU}</Typography>
                                <Typography className={classes.headerInfoTitle}>{t`Indoor Units`}</Typography>
                            </div>

                            <Divider orientation={"vertical"} className={classes.headerInfoDivider} />

                            {!!siteId && <div className={classes.headerInfoSection}>
                                <Typography className={classes.headerInfoVal}>{Number(stats.totalCapacity.toFixed(1))}</Typography>
                                <Typography className={classes.headerInfoTitle}>{t`Tons`}</Typography>
                            </div>}

                        </div>

                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                            <div style={{ marginRight: 48 }}>
                                <DateRangePicker disabled={!siteId} date={date} setDate={setDate} autoSelectMonth={true} />
                            </div>
                            {!!siteId &&
                                <>
                                    {
                                        isExporting ?
                                            <ClipLoader size={35} /> :
                                            <IconButton disabled={isExporting} onClick={handleExportPDFClick}>
                                                <ExportPDF />
                                            </IconButton>
                                    }
                                </>
                            }
                        </div>

                    </div>
                    <ModeReport
                        id={"mode"}
                        classes={classes}
                        siteId={siteId}
                        updatedDate={date}
                        setIsLoading={setIsLoading}
                        maximize={maximize}
                        maxed={maxed}
                        isCustomer={false}
                    />
                    <PowerReport
                        id={"power"}
                        classes={classes}
                        siteId={siteId}
                        updatedDate={date}
                        setIsLoading={setIsLoading}
                        maximize={maximize}
                        maxed={maxed}
                        isCustomer={false}
                    />

                    <AlertsReport
                        id={"alerts"}
                        classes={classes}
                        siteId={siteId}
                        updatedDate={date}
                        setIsLoading={setIsLoading}
                        maximize={maximize}
                        maxed={maxed}
                        isCustomer={false}
                        isLoading={isLoading}
                    />

                    <DiversityReport
                        id={"diversity"}
                        classes={classes}
                        siteId={siteId}
                        updatedDate={date}
                        setIsLoading={setIsLoading}
                        maximize={maximize}
                        maxed={maxed}
                        isCustomer={false} />

                    <StandByTime
                        id={"standByTime"}
                        classes={classes}
                        siteId={siteId}
                        updatedDate={date}
                        setIsLoading={setIsLoading}
                        maximize={maximize}
                        maxed={maxed}
                    />

                    <SetpointMainteneance
                        id={"SetpointMainteneance"}
                        classes={classes}
                        siteId={siteId}
                        updatedDate={date}
                        setIsLoading={setIsLoading}
                        maximize={maximize}
                        maxed={maxed}
                    />

                    {maxed && <div className={classes.maxShader} onClick={() => maximize("")} />}
                </div>
            </div>
        </div >
    );
};

export default SiteReportsScreen;
