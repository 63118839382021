import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  dialogPaper: {
    width: '1300px',
    maxWidth: '90% !important',
    height: '780px',
    maxHeight: '90%',
    overflow: 'hidden',
    borderRadius: '8px',
  },
  dialogContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
    flex: 1,
    padding: '20px 20px 0 20px',
    justifyContent: 'space-between'
  },
  iconBtnStyle: {
    width: '30px',
    height: '30px',
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  actionsHolder: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  note: {
    height: '52px',
    minHeight: '52px',
    margin: '11px 0 0',
    padding: '7px 11px',
    borderRadius: '4px',
    border: 'solid 1px #f8b133',
    backgroundColor: '#f8b13326',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px'
  },
  fileExample: {
    padding: '11px',
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '174px',
    borderRadius: '4px',
    border: 'solid 1px #d5d2d5',
    justifyContent: 'space-between',
    marginBottom: '37px'
  },
  warningIcon: {
    fill: '#f8b133',
    fontSize: '19.21px',
    marginRight: '13px',
  },
  noteText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#545964'
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#7f7182'
  },
  downloadBtn: {
    width: '160px',
    height: '30px',
    borderRadius: '4px',
    border: 'solid 1px #d5d2d5',
    backgroundColor: '#fff',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#545964',
    textTransform: 'capitalize'
  },
  downloadIcon: {
    fontSize: '16px !important'
  },
  tableHolder: {
    display: 'flex',
    flex: 1,
    height: 'fit-content',
    maxHeight: '100%',
    padding: '20px',
    minHeight: '230px'
  },
  tableContainer: {
    flex: 1
  },
  tableHeadStyle: {
    padding: '0 15px',
    fontSize: '13px',
    color: '#79797d',
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    backgroundColor: '#efeff2',
    '& tr': {
      '& th': {
        padding: '10px'
      }
    },
    border: 'none',
    height: '40px',
    whiteSpace: 'nowrap'
  },
  tableCellStyle: {
    padding: '0 15px',
    height: '49px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'auto',
    minWidth: 'auto',
    maxWidth: '1ch',
    fontSize: '13px'
  },
  errorText: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '10px',
    marginRight: '20px'
  },
  dialogHeader: {
    paddingLeft: '20px',
    paddingRight: '13px',
    ...theme.dialogs.header,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '60px',
    minHeight: '60px'
  },
  SVGDownload: {
    fontSize: '21px'
  },
  uploadInput: {
    width: '100%',
    height: '215px',
    minHeight: '215px',
    borderRadius: '4px',
    border: 'dashed 1px #aaa2aa',
    backgroundColor: '#aaa2aa1a',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px'
  },
  uploadIcon: {
    fontSize: '50px',
    fill: '#aaa2aa'
  },
  fileIconHolder: {
    display: 'flex',
    alignItems: 'center',
    height: '50px'
  },
  fileIcon: {
    fontSize: '25px',
    marginRight: '6px'
  },
  resultText: {
    height: "24px",
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: 'normal',
    color: '#12a312'
  },
  errorResult: {
    color: 'red'
  },
  oddRow: {
    background: '#fafafa'
  },
  resultRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e0e0e0'
  },
  rowIndexCell: {
    width: '60px',
    height: '45px',
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center"
  },
  uploadSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  uploadContainer: {
    height: '100%'
  },
  resultsTitle: {
    marginBottom: '10px'
  },
  resultsContainer: {
    overflow: 'auto',
    flexFlow: 'column nowrap',
    display: 'flex',
    marginTop: '8px',
    marginBottom: '20px'
  },
  wideCell: {
    minWidth: "130px"
  },
  resultsHolder: {
    padding: "10px 0"
  },
  summaryText: {
    fontSize: '15px'
  },
  loader: {
    marginRight: '10px',
    color: theme.palette.colors.font || '#fff',
    opacity: 0.3
  }
}));

export default useStyles;
