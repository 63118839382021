import { Checkbox, FormControlLabel, IconButton, List, ListItem, ListItemText, Paper, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useState } from "react";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { symbols } from "../../constants/sensorsUnitsSymbols";
import { Add, Refresh as SvgRefresh } from "../../icons/";
import { useStoreState } from "../../models/RootStore";
import styles from "./UnitStatsTable.module.css";
import useStyles from "./UnitStatsTable.style";

interface IColumn {
  id: "plottable" | "name" | "value" | "measurementUnits" | "slider" | "hvac_param_name";
  label: string;
  minWidth?: number;
  align?: "left";
  format?: (value: number) => string;
  type?: string;
}

const columns: IColumn[] = [
  {
    id: "plottable",
    label: "",
    type: "boolean"
  },
  {
    id: "name",
    label: t`Name`
  },
  {
    id: "hvac_param_name",
    label: ""
  },
  {
    id: "value",
    label: t`Value`
  },
  {
    id: "measurementUnits",
    label: ``
  },
  {
    id: "slider",
    label: t`Value range`,
    type: "slider"
  }
];

export default function UnitStatsTable(props: any) {
  const {
    isLoading,
    onRefresh,
    updateTime,
    autoupdate,
    setAutoupdate,
    paramsTable,
    showHideAllParams,
    showHideParam,
    timezone,
    updateParamRow,
    type,
    multi,
    disableUpdateBtn
  } = props;

  const classes = useStyles();

  const { dateFormat, timeFormat } = useStoreState((state) => state.users);
  const dateTime = `${dateFormat} ${timeFormat}`;
  const [anchorEl, setAnchorEl] = useState(null);

  const openParamsList = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const numOfParams = Object.values(paramsTable)?.filter((param: any) => !!param)?.length;
  const numOfShowing: any = Object.values(paramsTable).filter((row: any) => row?.isShowing).length;
  const allShowed = numOfParams === numOfShowing;
  const someShowed = !allShowed && (numOfShowing !== 0);
  const typeTitle = type === "sensor" ? t`Sensors` : t`Power Meters`;

  return (
    <Paper className={classes.root}>

      <div className={classes.header}>
        <div className={classes.parametersHeader}>
          <Typography variant="h6" className={classes.title}>{typeTitle}</Typography>
          {multi && <IconButton disableRipple className={clsx(classes.addIcon, classes.iconBtnStyle, someShowed && classes.pushedAddIcon)} onClick={openParamsList} id="open-unit-params">
            <Add disabled={isLoading} />
          </IconButton>}
        </div>
        <div className={classes.autoUpdateContainer}>
          <Tooltip arrow interactive placement="top-start" title={disableUpdateBtn ? t`Auto update is only available for periods shorter than 24 hours` : t`Refresh data automatically with new real time data`} >
            <FormControlLabel
              classes={{ label: classes.autoRefreshLabel, root: classes.autoUpdate }}
              control={
                <Checkbox
                  color="default"
                  classes={{ root: clsx(classes.removePaddingRight, classes.grayCheckbox) }}
                  checked={autoupdate}
                  disabled={disableUpdateBtn}
                  onChange={() => setAutoupdate(!autoupdate)}
                  value="checkedA"
                />
              }
              label="AUTO UPDATE"
            />
          </Tooltip>
          <Typography variant="body2" className={classes.lastUpdatedTitle}>
            {multi ? "" : updateTime
              ? t`Last updated at:` + ` ${moment(updateTime).tz(timezone).format(dateTime)}`
              : t`No` + " " + typeTitle + " " + t`data`}
          </Typography>
          <IconButton disableRipple className={classes.iconBtnStyle} aria-label="refresh icon" onClick={onRefresh ?? _.noop} disabled={autoupdate || disableUpdateBtn}>
            <SvgRefresh className={isLoading ? styles.refreshStyleLoading : styles.refreshStyle} />
          </IconButton>
        </div>
      </div>

      {!isLoading && (<Popover
        id="unitParamsListPopup"
        disableAutoFocus={false}
        disableEnforceFocus={false}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div className={classes.dialogTitle}>
          <Typography variant="h5">{t`Edit ` + typeTitle + ` list`}</Typography>
          <IconButton disableRipple className={classes.iconBtnStyle} onClick={handleClose}>
            <Clear />
          </IconButton>
        </div>

        {numOfParams ? <List className={classes.paramsPopupList} >
          <ListItem disableGutters dense button onClick={(event: any) => showHideAllParams(event.target.checked)}>

            <Checkbox
              color="default"
              checked={allShowed}
              classes={{ root: classes.grayCheckbox }}
            />

            <ListItemText id={`checkbox-text-all`} primary={t`All`} />
          </ListItem>

          {_.orderBy(Object.values(paramsTable), ["name"], ["asc"]).map((row: any, index: number) => {
            if (!row) {
              return;
            }
            const isSelected = row.isShowing;
            return (
              <ListItem key={`param-show-${index}`} disableGutters dense button onClick={(event: any) => showHideParam(row.id)}>
                <Checkbox
                  color="default"
                  checked={isSelected}
                  classes={{ root: classes.grayCheckbox }}
                />
                <ListItemText id={`checkbox-text-${index}`} primary={row.name} />
              </ListItem>

            );
          })}
        </List>
          : <Typography style={{ padding: "20px 15px", fontSize: "18px" }}>{t`No parameters for this unit`}</Typography>
        }
      </Popover>)}
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <colgroup>
            <col style={{ width: "20px" }} />
            <col style={{ width: "fit-content" }} />
            <col style={{ width: "43px" }} />
            <col style={{ width: "3rem" }} />
            <col style={{ width: "auto", minWidth: "10rem" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {``}
              </TableCell>
              <TableCell
                className={classes.tableCellAlignLeft}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Name`}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Value`}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {``}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align={"left"}
                classes={{ stickyHeader: classes.tableHeadStyle }}
              >
                {t`Value range`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && _.orderBy(Object.values(paramsTable), ["name"], ["asc"]).map((row: any, index: number) => {

              const {
                name,
                displayValue,
                unit = "",
                id,
                slider,
                tempSign,
                isChecked,
                isShowing,
                disabled,
                plottable,
                value,
              } = row;

              if (!isShowing && multi) {
                return null;
              }
              return (

                <TableRow hover role="checkbox" tabIndex={-1} key={`graphData-${index}`}>
                  <Tooltip
                    arrow
                    title={t`Show parameter on graph`}
                    placement="top-start"
                  >
                    <TableCell
                      className={classes.tableCell}
                      style={{
                        paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                      }}
                    >
                    {
                      plottable ? (
                        <Checkbox
                        color="default"
                        checked={isChecked}
                        disabled={disabled}
                        classes={{ root: classes.grayCheckbox }}
                        onClick={() => updateParamRow(id)}
                      />
                      ) : (
                        <span/>
                      )
                    }
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    arrow
                    title={`${name}`}
                    placement="top-start"
                    classes={{ popper: classes.popper }}
                  >
                    <TableCell
                      style={{ backgroundColor: "white" }}
                      className={
                        clsx(classes.tableCellAlignLeft, classes.nameCell)}
                    >
                      {name}

                    </TableCell>
                  </Tooltip>

                  <TableCell
                    className={classes.tableCell}
                    style={{
                      paddingLeft: "auto", minWidth: "auto", backgroundColor: "white"
                    }}
                  >
                    {displayValue ?? value}
                  </TableCell>

                  <TableCell
                    className={classes.tableCellAlignLeft}
                    style={{
                      paddingLeft: "15px", minWidth: "auto", backgroundColor: "white"
                    }}
                  >
                    {symbols[unit] || ""}{tempSign}
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    style={{
                      paddingLeft: "auto", minWidth: "200px", backgroundColor: "white"
                    }}
                  >
                    {slider}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

    </Paper >
  );

}
