import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  tableContainer: {
    display: "flex"
    // paddingRight: '5px'
  },

  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "1rem",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
  },

  table: {
    background: theme.palette.background.paper
  },
  overWritePadding: {
    padding: "0 15px",
    height: "35px",
    ...theme.tables.tableCell
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  editIcon: {
    marginLeft: "-6px"
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial"
  },

  anomaliesContainer: {
    height: "82%",
    display: "flex",
    paddingBottom: "1rem"
  },

  titleBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  title: {
    height: "18px",
    width: "72px",
    color: "#312e34",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px"
  },
  "[class^=\"NavigationBar\"]": {
    padding: "0"
  },
  smallWidth: { width: "20%", wordBreak: "break-word" },
  mediumWidth: { width: "60%", wordBreak: "break-word" },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  }
}));
export default useStyles;
