import React, { useState, useCallback } from 'react';
import { t } from 'ttag';
import Header from '@components/Header/Header';
import ServiceNavigationBar from '@components/Menu/ServiceNavigationBar';
import { useStoreState } from '@models/RootStore';
import useStyles from './styles';
import useSigma from '@hooks/useSigma';
import { CustomAutoComplete } from '@components/AddRule/CustomedComponents';
import GraphPlaceHolder from '../Reports/GraphPlaceHolder';

/**
 * SigmaReports component handles generating embed URL and load it into iframe.
 * It allows selecting report type from a dropdown before generating the report.
 *
 * @component
 * @param {Object} props - Component properties.
 *
 * @returns {JSX.Element} The rendered SigmaReports component.
 */
const SigmaReports = (props) => {
  const classes = useStyles();

  const { generateEmbedURL } = useSigma();

  const { siteId, customerId } = useStoreState((s) => s.selections.selections);
  const { embedReportTypeOptions = [] } = useStoreState((state) => state.types);

  const [selectedReportType, setSelectedReportType] = useState('');
  const [embedURL, setEmbedURL] = useState('');

  /**
   * Updates the selected report type and retrieves the corresponding embed URL.
   * @param {Object} event - The event triggering the change.
   * @param {Object} value - The selected report option.
   * @returns {void}
   */
  const handleSelectChange = useCallback(async (event, value) => {
    setSelectedReportType(value);
    const data = await generateEmbedURL(customerId, siteId, value.value);
    if (data) {
      setEmbedURL(data.embedURL);
    }
  }, [customerId, siteId]);

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header hideUnitSelection hideSystemSelection/>
        <div className={classes.topContainer}>
          <CustomAutoComplete
            placeholder={t`Report type`}
            className={classes.reportTypeSelect}
            value={selectedReportType}
            onChange={handleSelectChange}
            label="label"
            optionValue="value"
            options={embedReportTypeOptions}
          />
        </div>
        <div className={classes.mainContainer}>
          <div className={classes.paper}>
            {embedURL ?
              <iframe className={classes.iframeContent} src={embedURL}></iframe> :
              <GraphPlaceHolder type="report type" />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigmaReports;
