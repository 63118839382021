import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import { DeleteIcon } from "../../logos";
import ErrorBox from "../ErrorBox/ErrorBox";
import LightTooltip from "../Tooltip/LightTooltip";
import useStyles from "./Delete.style";

export default function Delete(props: any) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(null);

  const { buttonClass, iconStyle, object: objectToDelete, type, detach, getActionResult, disabled = false } = props;

  const classes = useStyles();
  const fullName = !objectToDelete.name ? `${objectToDelete.firstName} ${objectToDelete.lastName}` : "";
  const itemName = objectToDelete.name || objectToDelete.serial || fullName;

  const handleClickOpen = async (event: any) => {
    event.stopPropagation();
    // if (type === `System`) {
    //   const mapping = await props.isMapping(objectToDelete);
    //   if (!mapping) {

    //   }
    // }
    // else { setOpen(true); }
    setOpen(true);
  };

  const handleClose = async (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleAgree = async (event: any) => {
    event.stopPropagation();
    const id = objectToDelete.id || objectToDelete._id;
    const result = await detach({ id });
    setError(result);
    !result && setOpen(false);
    // detach && detach({ id })
    //   .then((res: any) => {
    //     setOpen(false);
    //     getActionResult && getActionResult(true);
    //   })
    //   .catch(({ message }: any) => {
    //     getActionResult && getActionResult(false);
    //     setError(message);
    //   });
  };

  if (error) {
    return <ErrorBox
      error={error}
      onClose={() => {
        setError(null);
        setOpen(false);
      }}
    />;
  }
  return (
    <>

      <LightTooltip title={t`Delete ` + type}>
        <IconButton disableRipple disabled={disabled} onClick={handleClickOpen} classes={{ root: classes.overWriteIcon }}>
          <DeleteIcon className={iconStyle} />
        </IconButton>
      </LightTooltip>
      {open && <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.titleContent}>
          <Typography className={classes.headerTitle}>{t`Delete ` + type}</Typography>
          <IconButton disableRipple onClick={(event: any) => { setOpen(false); event.stopPropagation(); }} className={classes.overWriteIcon}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Typography className={classes.DialogContentText}>
            {t`Are you sure you want to delete ` + `${itemName}  ?`}
          </Typography>
        </div>
        <div className={classes.dialogActions}>
          <Button
            onClick={handleAgree}
            className={classes.yesBtn}
            white width={130}
            marginRight
          >
            {t`Yes`}
          </Button>

          <Button
            autoFocus={true}
            onClick={handleClose}
            width={130}
          >
            {t`No`}
          </Button>
        </div>
      </Dialog>}
    </>
  );
}
