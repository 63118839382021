import { makeStyles } from "@material-ui/core";
import React from "react";
import Slider, { Mark } from '@material-ui/core/Slider'
import SliderStyle from "./SliderDS.style";

interface IProps {
  min: number;
  max: number;
  marks?: boolean | Mark[];
  value: number[];
  onChange?: ((event: React.ChangeEvent<{}>, value: number | number[]) => void);
  getAriaLabel?: ((index: number) => string);
  disabled?: boolean | undefined
}
const SliderDS: React.FC<any> = (props: IProps) => {
  const useStyles = makeStyles(SliderStyle);
  const classes = useStyles();
  const {
    min,
    max,
    marks,
    value,
    disabled,
    onChange,
    getAriaLabel
  } = props;



  function ThumbComponent(props: any) {
    const index = props["data-index"]
    const isCool = props["aria-label"] === "cool"
    const color = isCool ? "#57abff" : "#F25146";
    return (
      <span {...props}  >
        <span
          className={classes.thumbComponent}
          style={{
            backgroundColor: color,
            border: `1px solid ${color}`,
            boxShadow: `0 0 2px 1px ${color}`,
          }}
        >
          {`${value[index]}°`}
        </span>
      </span>
    );
  }

  return (
    <div className={classes.container}>
      <Slider
        getAriaLabel={getAriaLabel}
        ThumbComponent={ThumbComponent}
        min={min}
        max={max}
        marks={marks}
        step={1}
        value={value}
        onChange={onChange}
        disabled={disabled}
        classes={{
          // root: classes.root,
          // mark: classes.mark,
          // markLabel: classes.markLabel,
          thumb: classes.thumb,
          active: classes.active,
          track: classes.track,
          rail: classes.rail
        }}
      />
    </div>
  );
};

export default SliderDS;
