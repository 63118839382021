/**
 * Filters an array of objects based on a search term and specified properties.
 *
 * @param {Array<Object>} list - The array of objects to search through.
 * @param {string} term - The search term to match.
 * @param {string|string[]} [props] - Optional property or array of properties to search within each object.
 *                                    If not provided, all string and number properties are searched.
 * @returns {Array<Object>} - The filtered array of objects where the term matches in the specified properties.
 *
 * @example
 * const data = [
 *   { name: 'Alice', email: 'alice@example.com', age: 30 },
 *   { name: 'Bob', email: 'bob@example.com', age: 25 },
 *   { name: 'Charlie', email: 'charlie@example.com', age: 35 },
 * ];
 *
 * // Search by single property
 * filterByTerm(data, 'ali', 'name');
 * // Returns: [{ name: 'Alice', email: 'alice@example.com', age: 30 }]
 *
 * // Search by multiple properties
 * filterByTerm(data, 'example', ['email', 'name']);
 * // Returns all items since 'example' is in all emails
 *
 * // Search all string and number properties
 * filterByTerm(data, '35');
 * // Returns: [{ name: 'Charlie', email: 'charlie@example.com', age: 35 }]
 */
export const filterByTerm = (list, term, props) => {
  if (!term){
    return list;
  }

  const lowerTerm = term.toString().toLowerCase();

  return list.filter((item) => {
    if (props) {
      const propsArray = Array.isArray(props) ? props : [props];
      return propsArray.some((prop) => {
        const value = item[prop];
        if (value !== undefined && value !== null) {
          return value.toString().toLowerCase().includes(lowerTerm);
        }
        return false;
      });
    } else {
      // Search all string and number properties
      return Object.values(item).some((value) => {
        if (value !== undefined && value !== null) {
          return value.toString().toLowerCase().includes(lowerTerm);
        }
        return false;
      });
    }
  });
};
