import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import ErrorBox from "../ErrorBox/ErrorBox";
import useStyles from "./style";

const DisableWarning = (props: any) => {
  const [error, setError] = useState(null);

  const { object: objectToDisable, type, approve, handleClose } = props;

  const classes = useStyles();
  const itemName = objectToDisable.name;

  const handleAgree = async () => {
    const id = objectToDisable.id;
    const result = await approve(id);
    setError(result);
  };

  if (error) {
    return <ErrorBox
      error={error}
      onClose={() => {
        setError(null);
      }}
    />;
  }
  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.titleContent}>
          <Typography className={classes.headerTitle}>{t`Disable ` + type}</Typography>
          <IconButton disableRipple onClick={handleClose} className={classes.overWriteIcon}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Typography className={classes.DialogContentText}>
            {t`Are you sure you want to disable ` + `${itemName}  ?`}
          </Typography>
        </div>
        <div className={classes.dialogActions}>
          <Button onClick={handleAgree}
            className={classes.yesBtn}
            white width={130}
            marginRight
          >
            {t`Yes`}
          </Button>

          <Button
            autoFocus={true}
            onClick={handleClose}
            width={130}
          >
            {t`No`}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default DisableWarning;
