import { Dialog, IconButton, Typography } from "@material-ui/core";
import _ from "lodash";
import { t } from "ttag";
import React, { } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import Button from "@cool_widgets/Button";
import { Close } from "@icons";
import useStyle from "./EmailDialog.style";

interface EmailDialogProps {
    open: boolean;
    onClose: () => void;
    emails: string[];
    setEmails: (emails: string[]) => void;
    shareAuditsByEmail: () => void;
}

const EmailDialog: React.FC<EmailDialogProps> = ({
    open,
    onClose,
    emails,
    setEmails,
    shareAuditsByEmail,
}) => {
    const classes = useStyle();

    const addLabeledEmail = (email: string, index: number, removeEmail: (index: number) => void) => {
        return (
            <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                </span>
            </div>
        );
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="email-alert-dialog-title"
            aria-describedby="email-alert-dialog-description"
            maxWidth="sm"
            classes={{ paper: classes.emailDialogStyle }}
        >
            <div className={classes.dialogHeader}>
                <Typography className={classes.headerTitle} >
                    {t`Share alert`}
                </Typography>
                <IconButton disableRipple onClick={onClose} className={classes.iconBtnStyle} >
                    <Close color="#7f7692" />
                </IconButton>
            </div>
            <div className={classes.emailDialogContent}>
                <Typography>
                    {t`Recipients`}
                </Typography>
                <ReactMultiEmail
                    placeholder="Email Address/es"
                    emails={emails}
                    onChange={(emails: any) => setEmails(emails)}
                    validateEmail={(email: any) => isEmail(email)}
                    getLabel={addLabeledEmail}
                />
            </div>
            <div className={classes.actionsHolder}>
                <Button
                    white
                    width={150}
                    marginRight
                    onClick={onClose}
                >
                    {t`Cancel`}
                </Button>
                <Button
                    type="submit"
                    width={150}
                    disabled={_.isEmpty(emails)}
                    onClick={shareAuditsByEmail}
                >
                    {t`Share`}
                </Button>
            </div>
        </Dialog>
    );
};

export default EmailDialog;
