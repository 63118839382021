import React from 'react';
import { CoolSwitch } from '@cool_widgets/CoolSwitch';

const Toggle = ({ value, onChange, isDisabled}) => {
  return (
    <CoolSwitch
      disableRipple={true}
      onChange={(e) => {
        onChange?.(e.target.checked ? 1 : 0);
      }}
      checked={value}
      disabled={isDisabled}
    />
  );
};

export default Toggle;
