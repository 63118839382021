import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  initialScreen: {
    padding: '16px 47px',
  },
  dropzoneAreaWrapper: {
    marginTop: '16px',
    position: 'relative',
  },
  dropZone: {
    borderWidth: '2px',
    borderColor: '#BDB8BD',
    padding: '30px',
    '& p': {
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '33.84px',
      color: '#212121',
    },
    /*     '& .MuiDropzonePreviewList-image': {
      position: 'absolute',
      top: '50%',
      left: '0',
      width: '100%',
      height: 'auto',
      transform: 'translateY(-50%)',
    },
    '& .MuiDropzonePreviewList-removeButton': {
      top: '5px',
      right: '5px',
    },
    '& .MuiDropzonePreviewList-imageContainer': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      maxWidth: 'none',
      background: '#fff',
      padding: '0',
      overflow: 'hidden',
    }, */
  },
  template__imagePreviewWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    background: '#fff',
    zIndex: 99,
  },
  template__imagePreview: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderWidth: '2px',
    borderColor: '#BDB8BD',
    borderStyle: 'dashed',
    borderRadius: '4px',
  },
  template__imagePreviewActions: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '5px',
  },
  source__radioWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '9px',
    '&>span': {
      cursor: 'pointer',
    },
  },
  source__radio: {
    padding: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
    '& .MuiRadio-root': {
      padding: 0,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        '&:first-child': {
          color: '#545964',
        },
        '&:last-child': {
          color: '#545964',
        },
      },
    },
    '& .MuiSvgIcon-root:first-child': {
      color: '#545964',
    },
  },
  ////
  customAutocompleteField: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '1px 9px',
    },
  },
  customTextField: {
    '& .MuiInputBase-input': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.06px',
      backgroundColor: 'white',
      padding: '10px 15px',
      borderRadius: '4px',
      color: '#828282',
    },
  },
  resetButtonWrapper: {
    marginTop: '10px',
    '&> span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    }
  }
}));

export default useStyles;
