import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  // Dialog styles
  dialogTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18.75px',
    textAlign: 'left',
    padding: '19px 24px',
    backgroundColor: '#4B1C46',
    borderBottom: '1px solid #D5D2D5',
    color: theme.palette.common.white,
    '& h2': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '18.75px',
      margin: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#fff',
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    borderTop: '1px solid #e0e0e0',
    padding: '16px 24px',
    backgroundColor: '#F6F5F6',
  },
}));

export default useStyles;
