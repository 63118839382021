import {
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import {
  Invite as sdkInvite
} from "coolremote-sdk";
import { Formik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { isIOS } from "react-device-detect";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { t } from "ttag";
import { AddEditPolicies } from "../../components/AddEditPolicies";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import WarningBox from "../../components/WarningBox/ErrorBox";
import Button from "../../cool_widgets/Button";
import { Delete } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { ArrowBack } from "../../svgComponents";
import UserDetails, { InviteSchema } from "./UserDetails";
import useStyles from "./UserEdit.style";

const UserNew: React.FC<RouteComponentProps<any> & any> = (props) => {
  const classes = useStyles();
  const { close, customerId, newInvite = false, inviteEdit = false, user = {} } = props;

  const [step, setStep] = useState<number>(1);
  const [policies, setPolicies] = useState<any>({});
  const [inviteEmailError, setInviteEmailError] = useState<string>("");
  const [isExpiriedEnabled, enableExpired] = useState<boolean>(false);
  const [expireTimestamp, setExpireTimestamp] = useState<any>(null);

  const [deletePending, setDeletePending] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    temperatureScale: 1,
    measurementUnits: 1,
    timeFormat: 0,
    dateFormat: 0,
    appAccess: 0,
    enableExpired: false,
    expiresDate: "",
    adminRole: "",
    checkedSites: {},
    checkedUnits: {},
    checkedGroups: {}
  });

  const types = useStoreState((state) => state.types);
  const addMessage = useStoreActions((a) => a.errorMessage.addMessage);
  const deleteInvite = useStoreActions((action) => action.invites.deleteInvite);
  const deleteUser = useStoreActions((a) => a.deleteUser);
  const getCustomer = useStoreState((state) => state.customers.getCustomer);
  const { canDeleteInvite = true, canShareInvite = true, canUpdateUser = true, canDeleteUser = true } = getCustomer(customerId) || {};
  const { temperatureScale, pressureScale } = types;

  const userPref = useStoreState((s) => s.users.userPreferences);
  const customerNickname = (userPref?.nicknames && userPref.nicknames[customerId]);


  const createInvite = (values: any) => {
    if (inviteEmailError) {
      return;
    }
    const arrPolicies = Object.values(policies).reduce((data: any, entity: any) => {
      const apps = Object.values(entity);
      data.push(...apps);
      return data;
    }, []);

    const data: any = {
      email: values?.email?.trim(),
      policies: arrPolicies,
      customer: customerId
    };

    if (customerNickname) data.customerNickname = customerNickname;

    if (isExpiriedEnabled && expireTimestamp) {
      data["expiryTimestamp"] = expireTimestamp;
    }

    return sdkInvite.createInvite(data)
      .then((resp: any) => {
        sendEmail(resp);
      }).catch((err: any) => {
        setStep(1);
        addMessage({ message: err?.message });
      });
  };

  const sendEmail = (data: any = {}) => {
    sdkInvite.sendInviteEmail(data.inviteId, { email: data.email })
      .then(() => {
        close();
      });
  };

  const deleteItem = () => {
    setDeletePending(false);
    const { id } = user;
    if (inviteEdit) {
      deleteInvite(id)
        .then(() => close())
        .catch((e: any) => setSubmitError(e.message));
      return;
    }

    deleteUser({ id })
      .then(() => close())
      .catch((e: any) => setSubmitError(e.message));
  };

  return (
    <div className={classes.fullDialog}>
      <div className={clsx(classes.titleContent, { [classes.iosTopHeight]: isIOS })}>
        <IconButton disableRipple onClick={close} className={classes.iconBtnStyle}>
          <ArrowBack />
        </IconButton>
        <Typography className={classes.headerTitle}>{inviteEdit ? t`Edit Invite` : newInvite ? t`Add new user` : t`Edit User`}</Typography>
        {(newInvite || (inviteEdit && !canDeleteInvite) || (!inviteEdit && !canDeleteUser)) ? <span style={{ width: "40px" }}></span> : <Delete color={"#fff"} className={classes.deleteIcon} onClick={() => setDeletePending(true)} />}
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => createInvite(values)}
        enableReinitialize={true}
        validationSchema={InviteSchema}

      >
        {({ handleSubmit, errors, touched, dirty, values, ...restFormikProps }) => (
          <form onSubmit={handleSubmit} className={classes.dialogContent}>
            <Grid container className={classes.dialogSubContent}>
              {step === 1 ?
                <div className={classes.permissionsSection || ""}>
                  <AddEditPolicies
                    policies={policies}
                    setPolicies={setPolicies}
                  />
                </div> :
                <>
                  <div className={classes.personalDetailsSections}>
                    <Typography
                      className={classes.sectionTitleStyle}
                    >
                      {t`Personal Details`}
                    </Typography>

                    <UserDetails
                      user={user}
                      errors={errors}
                      touched={touched}
                      inviteEdit={inviteEdit || (!canUpdateUser && !inviteEdit && !newInvite)}
                      temperatureScale={temperatureScale}
                      pressureScale={pressureScale}
                      inviteOnly={newInvite}
                      values={values}
                      {...restFormikProps}
                      {...props}
                    />
                  </div>
                </>
              }

            </Grid>
            <div className={classes.actionsHolder}>
              {submitError &&
                <ErrorBox
                  error={submitError}
                  title={t`Missing users permissions`}
                  onClose={() => setSubmitError(null)}
                />
              }
              {deletePending &&
                <WarningBox
                  title={t`Delete` + ` ${inviteEdit ? t`Invite` : t`User`}`}
                  error={user.firstName + " " + user.lastName + " " + (inviteEdit ? t`invite will be deleted, are you sure?` : t`user will be deleted, are you sure?`)}
                  onAccept={deleteItem}
                  onClose={() => setDeletePending(false)}
                  acceptTitle={t`Delete`}
                  cancelTitle={t`Cancel`}
                  mobileView
                />}
              {step === 2 &&
                <Button
                  white
                  width={"40%"}
                  onMouseDown={() => setStep(1)}
                  className={classes.btnStyle}
                >
                  {t`Back`}
                </Button>}
              <Button
                width={step === 1 ? "100%" : "40%"}
                onMouseDown={() => step === 1 ? setStep(2) : createInvite(values)}

                className={classes.btnStyle}
              >
                {step === 1 ? t`Continue` : t`Send invite`}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserNew;
