import React from 'react';

function NumericControlIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#6B4B68" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3753 9L13 9.69677L8.41765 14.8082C8.30571 14.9312 8.15591 15 8 15C7.84409 15 7.69429 14.9312 7.58235 14.8082L3 9.69677L3.62529 9L8 13.8794L12.3753 9Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3753 7L13 6.30323L8.41765 1.19181C8.30571 1.06879 8.15591 1 8 1C7.84409 1 7.69429 1.06879 7.58235 1.19181L3 6.30323L3.62529 7L8 2.12061L12.3753 7Z"
        fill="white"
      />
    </svg>
  );
}

export default NumericControlIcon;
