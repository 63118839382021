import {
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis, YAxis
} from "recharts";
import { t } from "ttag";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import { Loader } from "../../components/Loader";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import useStyles from "./UnitStatistics.style";

const UnitStatistics: React.FC<any> = (props) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allObj, setAllObj] = useState<any[]>([]);
  const [unit, setUnit] = useState<any>(null);
  const [timeUnit, setTimeUnit] = useState<string>("day");
  const [results, setResults] = useState<any>({ buckets: [] });
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);
  const [paramsData, setParamsData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enabledlimits, setEnabledLimits] = useState<any>({ enableWeek: false, enableMonth: false });


  const history = useHistory();
  const location: any = useLocation();
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allSystems = useStoreState((state) => state.systems.allSystems);
  const allSites = useStoreState((state) => state.sites.allSites);
  const types = useStoreState((state) => state.types);
  const getUnit = useStoreState((s) => s.units.getUnit);
  const selections = useStoreState((s) => s.selections.selections);
  const { unitId: selectedUnitId, siteId } = useStoreState((s) => s.selections.selections);
  const setSelections = useStoreActions((s) => s.selections.setSelections);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const getUnitStats = useStoreActions((state) => state.units.getUnitStatistics);
  const fetchUnitDiagByParams = useStoreActions((a) => a.units.fetchUnitDiagByParams);
  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);

  const user = useStoreState((state) => state.users.me);
  const { temperatureScale, timeFormat } = user;
  const temperatureScaleMirror = useStoreState((s) => s.temperatureScaleMirror);
  const temperatureSample = + temperatureScaleMirror.fahrenheit === temperatureScale ? "°F" : "°C";

  const isDayRange = timeUnit === "day";
  const isWeekRange = timeUnit === "week";
  const isMonthRange = timeUnit === "month";
  const site = siteId ? allSites[siteId] : null;
  const timezone = site?.timezone || "utc";

  const legends: any = [
    { name: t`Outside Temp`, color: "#afafaf" },
    { name: t`Room Temp`, color: "#5f5f5f" },
    { name: t`Setpoint`, color: "#000000" },
    { name: t`Cool`, color: "#35a8e0" },
    { name: t`Heat`, color: "#f05146" }
  ];

  useEffect(() => {
    if (!siteId) {
      return;
    }

    const timelimit = sitesFlags[siteId]?.numOfHourLimitUnitStats || 0;
    const allTimeFramsAllowed = timelimit === 0;
    if (allTimeFramsAllowed) {
      setEnabledLimits({ enableWeek: true, enableMonth: true })
    } else {
      const limits = { enableWeek: timelimit > 24, enableMonth: timelimit > 168 };
      if ((timeUnit === "week" && !limits.enableWeek) || (timeUnit === "month" && !limits.enableMonth)) {
        setTimeUnit("day");
      }
      setEnabledLimits(limits)
    }
  }, [siteId]);

  useEffect(() => {
    if (!selectedUnitId) {
      onUnitSelect(null);
      setUnit(null);
    } else {
      onUnitSelect(selectedUnitId);
      setUnit(getUnit(selectedUnitId));
    }
  }, [selectedUnitId]);

  useEffect(() => {

    if (!allSites || !allSystems || !allUnits || allObj.length) {
      return;
    }

    const sites = Object.values(allSites).map((site: any) => {
      return {
        name: site.name,
        label: site.name,
        type: "site",
        id: site.id
      };
    });

    const systems = Object.values(allSystems).map((system: any) => {
      const siteName = allSites[system.site]?.name;
      return {
        name: system.name,
        label: `${siteName} - ${system.name}`,
        type: "system",
        id: system.id
      };
    });

    const units = Object.values(allUnits).reduce((val: any, unit: any) => {
      if (unit.type === types.unitTypes["indoor"]) {
        return val;
      }
      const siteName = allSites[unit.site]?.name;
      const systemName = allSystems[unit.system]?.name || t`Unassigned`;
      val.push({
        name: unit.name,
        label: `${siteName} - ${systemName} - ${unit.name}`,
        type: "unit",
        id: unit.id
      });
      return val;
    }, []);

    setAllObj([...sites, ...systems, ...units]);

  }, [allUnits]);

  const setRange = useCallback(() => {

    let startTimeGMT = moment().tz(timezone).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).tz("utc");
    let endTimeGMT = moment().tz(timezone).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).add(1, "second").tz("utc");
    const currentTimeStamp = moment().valueOf();

    if (isWeekRange) {
      startTimeGMT = startTimeGMT.subtract(7, "days");
    } else if (isMonthRange) {
      startTimeGMT = startTimeGMT.subtract(1, "months");
    }

    let startTime = startTimeGMT.valueOf();
    const endTime = endTimeGMT.valueOf();

    //check if selected site has a limited number of hours for unit stats
    const hourslimit = sitesFlags[siteId || ""]?.numOfHourLimitUnitStats || 0;
    const miliSecondsLimit = hourslimit * 3600 * 1000;
    if (miliSecondsLimit && (currentTimeStamp - startTime > miliSecondsLimit)) {
      startTime = currentTimeStamp - miliSecondsLimit;
    }

    return {
      bucketSizeMsec: isDayRange ? 60 * 60 * 1000 : 24 * 60 * 60 * 1000,
      startTime,
      endTime
    };
  }, [timeUnit, siteId]);

  const fetchData = useCallback(() => {
    setResults({ buckets: [] });
    setParamsData(null);

    const { bucketSizeMsec, startTime, endTime } = setRange();

    if (!selectedUnitId) {
      setIsLoading(false);
      return;
    }

    getUnitStats({
      unitId: selectedUnitId,
      startTime,
      endTime,
      size: bucketSizeMsec
    })
      .then((basicState: any) => {
        const { buckets } = basicState;

        setStart(startTime);
        setEnd(endTime);
        if (buckets) {
          setResults(basicState);
        } else {
          setResults({ buckets: [] });
        }

        return fetchUnitDiagByParams({
          unitId: selectedUnitId,
          startTime,
          endTime,
          params: ["50", "57", "49"]
        });
      })
      .then((diag: any) => {
        setParamsData(diag);
      })
      .catch((err: any) => {
        setResults({ buckets: [] });
      })
      .finally(() => setIsLoading(false));
  }, [selectedUnitId, timeUnit]);

  useEffect(() => {
    if (!selectedUnitId) {
      return;
    }
    setIsLoading(true);
    fetchData();
  }, [selectedUnitId, timeUnit]);

  const onUnitSelect = (unitId: string | null) => {
    const { state } = location;
    const unit = unitId || state?.unitId;
    if (unit) {
      history.push({
        pathname: `/unit-statistics/${unit}`,
        state
      });
    } else {
      history.push("/unit-statistics/");
    }
  };

  const clearUnit = () => {
    history.push(`/unit-statistics/`);
  };

  const searchComponent = (
    <Autocomplete
      id="combo-box-demo"
      options={allObj}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.label || ""}
      groupBy={(option) => option.type}
      value={""}
      inputValue={searchTerm}
      classes={{
        clearIndicator: classes.searchIcon,
        popupIndicator: classes.searchIcon,
        popper: classes.autoCompletePoper,
        paper: classes.autoCompletePaper,
        listbox: classes.autoCompleteItem,
        noOptions: classes.autoCompleteItem,
        groupLabel: classes.autoCompleteGroup,
        groupUl: classes.autoCompleteGroupUl

      }}
      onInputChange={(e: any, val: any) => {
        setSearchTerm(val);
      }}
      renderInput={(params) => <TextField {...params} placeholder={t`Search...`} InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot }, ...params.InputProps, endAdornment: <Search /> }} />}
      onChange={(e: any, val: any) => {
        setSearchTerm("");
        if (!val) {
          return;
        }
        if (val.type === "unit") {
          onUnitSelect(val.id);
          updateSelections({ type: val.type, data: val.id });
          return;
        }
        updateSelections({ type: val.type, data: val.id });
        onUnitSelect(null);
      }}
    />
  );

  const CustomizedXAxisTick = (props: any) => {
    const {
      x, y, stroke, payload, isDay, isMonth
    } = props;

    const format = isDay ? timeFormat === 1 ? "hh:mm A" : "HH:mm" : "DD/MM";
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={isDay ? 25 : 10} dy={20} textAnchor="end" fill="#79797d" fontSize={14} transform={isDay ? "" : "rotate(-35)"}>{moment(payload.value).tz(timezone).format(format)}</text>
      </g>
    );
  };

  const CustomizedYAxisTick = (props: any) => {
    const {
      x, y, stroke, payload, unit
    } = props;
    const val = payload.value;
    const dx = 2;
    const rotation = 0;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={dx} dy={3} textAnchor="start" fill="#79797d" fontSize={14}>{val}{unit}</text>
      </g>
    );
  };

  const CustomizedY2AxisTick = (props: any) => {
    const {
      x, y, stroke, payload, unit, isDayRange
    } = props;
    const val = (isDayRange ? `${Math.round(payload.value / (1000 * 60))}` : `${Math.round(payload.value / (1000 * 60 * 60))}`);
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={-2} dy={3} textAnchor="end" fill="#79797d" fontSize={14} >{`${val} ${unit}`}</text>
      </g>
    );
  };

  const CustomizedLegend = ({ payload }: any) => {
    return (
      <div className={classes.legendsContainer}>
        {payload.map((legend: any, index: any) =>
          <div className={classes.legendHolder} key={`legend - ${index}`}>
            <div className={classes.legendColor} style={{ backgroundColor: legend.color }}></div>
            <Typography className={classes.legendName}>{legend.name}</Typography>
          </div>
        )}
      </div>
    );
  };

  const ticksArr = results.buckets.map((bucket: any) => bucket.timestamp) || [];
  const ticksMinsArr = [0, 15, 30, 45, 60].map((mins) => mins * 1000 * 60);
  const ticksHoursArr = [0, 6, 12, 18, 24].map((hours) => hours * 1000 * 60 * 60);

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        {isLoading && <Loader showLoader={true} />}
        <Header
          customGeneralNames={{ unit: t`Select Unit` }}
          hideAllOptionsFromDropdown={["unit"]}
          searchComponent={searchComponent}
          onSiteSelect={clearUnit}
          onSystemSelect={clearUnit}
          countControlUnits
          hideIndoor
          hideOther
          hideBsBox
          hideOutdoor
          screenTitle="unitStats"
        />
        {unit ? (<div className={classes.contentContainer}>
          <div className={classes.header}>
            {unit.name + t` Statistics`}
          </div>
          <div className={classes.frequencySelection}>
            <ButtonGroup color="primary" aria-label="outlined primary button group" className={classes.btnGroupTimeunits}>
              <Button disableRipple disableElevation onClick={() => setTimeUnit("day")} className={clsx(classes.btnText, timeUnit === "day" && classes.selectedBtn)}>Daily</Button>
              <Button disableRipple disableElevation disabled={!enabledlimits.enableWeek} onClick={() => setTimeUnit("week")} className={clsx(classes.btnText, timeUnit === "week" && classes.selectedBtn)}>Weekly</Button>
              <Button disableRipple disableElevation disabled={!enabledlimits.enableMonth} onClick={() => setTimeUnit("month")} className={clsx(classes.btnText, timeUnit === "month" && classes.selectedBtn)}>Monthly</Button>
            </ButtonGroup>
          </div>
          <Grid className={classes.graphContainer}>
            <ResponsiveContainer width="100%" height="80%">

              <ComposedChart height={500} data={results ? results.buckets : []}>
                <XAxis
                  xAxisId={"mainX"}
                  dataKey="timestamp"
                  height={60}
                  interval={0}
                  type="number"
                  tickCount={isDayRange ? 8 : (isWeekRange ? 7 : 10)}
                  domain={[start, end]}
                  tick={<CustomizedXAxisTick isDay={isDayRange} isMonth={isMonthRange} />}
                  ticks={ticksArr.length > 10 ? ticksArr.filter((tick: any, index: any) => (index % 3 === 0 && index !== ticksArr.length - 2) || index === ticksArr.length - 1) : ticksArr}
                  stroke="#fdfdfe"
                  tickLine={false}
                />
                <YAxis
                  yAxisId={"left"}
                  width={50}
                  type={"number"}
                  domain={isDayRange ? [0, (1000 * 60 * 60) + (5000)] : [0, (1000 * 60 * 60 * 24) + 60000]}
                  allowDecimals={false}
                  tick={<CustomizedY2AxisTick unit={isDayRange ? "min" : "h"} isDayRange={isDayRange} />}
                  ticks={isDayRange ? ticksMinsArr : ticksHoursArr}
                  stroke="#fdfdfe"
                  tickLine={false}
                />
                <YAxis
                  yAxisId={"right"}
                  width={50}
                  type={"number"}
                  allowDecimals={false}
                  orientation={"right"}
                  domain={["auto", "auto"]}
                  tick={<CustomizedYAxisTick unit={temperatureSample} />}
                  stroke="#fdfdfe"
                  tickLine={false}
                />
                <CartesianGrid stroke="#edebed" />

                {paramsData &&
                  (
                    <Area
                      name={t`Outside Temp`}
                      xAxisId={"mainX"}
                      yAxisId={"right"}
                      data={paramsData}
                      dataKey={57}
                      type="monotone"
                      stroke="none"
                      fillOpacity={1}
                      fill="#afafaf"
                      dot={false}
                    />

                  )}

                <Bar yAxisId={"left"} name="Heat" xAxisId={"mainX"} dataKey="unitBucketHeatTime" stackId={"a1"} barSize={10} fill="#f05146" />
                <Bar yAxisId={"left"} name="Cool" xAxisId={"mainX"} dataKey="unitBucketCoolTime" stackId={"a1"} barSize={10} fill="#35a8e0" />
                <Bar yAxisId={"left"} name="Other" xAxisId={"mainX"} dataKey="unitBucketOtherTime" stackId={"a1"} barSize={10} fill="#aaa2aa" />
                {paramsData &&
                  (
                    <Line
                      name={t`Room Temp`}
                      xAxisId={"mainX"}
                      yAxisId={"right"}
                      data={paramsData}
                      dataKey={49}
                      type="monotone"
                      stroke="#5f5f5f"
                      strokeWidth={3}
                      dot={false}
                    />

                  )}
                {paramsData &&
                  (
                    <Line
                      name={t`Set Point`}
                      xAxisId={"mainX"}
                      yAxisId={"right"}
                      data={paramsData}
                      dataKey={50}
                      type="monotone"
                      stroke="#000000"
                      strokeWidth={3}
                      dot={false}
                    />

                  )}
                <Tooltip labelFormatter={(val: any) => "Time: " + moment(val).tz(timezone).format(isDayRange ? timeFormat === 1 ? "hh:mm A" : "HH:mm" : "DD/MM HH:mm")} />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  content={<CustomizedLegend />}
                  payload={
                    legends.map((legend: any) => {
                      return legend;
                    })
                  }
                />

              </ComposedChart>
            </ResponsiveContainer>
            <div>
              <Typography className={classes.workingHours}>
                {results && results.onTime ? (results.onTime / (1000 * 60 * 60)).toFixed(2) : 0}
                <span style={{ fontSize: 14 }}>{t`Hrs`}</span>
              </Typography>
              <Typography className={classes.workingHoursTextStyle}>
                {t`Working Hours`}
              </Typography>
            </div>
          </Grid>
        </div>)
          : (
            <FilterRequire type={t`unit`} />
          )}
      </div>
    </div>
  );
};

export default UnitStatistics;
