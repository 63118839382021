import { createStyles, makeStyles } from "@material-ui/core";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => createStyles({
  item: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "31px",
    position: "relative",
    color: "white",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    fontSize: "1em",
    background: "rgba(41, 19, 46, 0)",
    paddingBottom: "8px"
  },

  onClick: {
    flexGrow: 9,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center"
  },

  locationTitle: {
    cursor: "pointer",
    display: "inline",
    lineHeight: "normal",
    verticalAlign: "middle",
    marginRight: "1rem",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Roboto"
  },

  collapsibleIcon: {
    fontSize: "0.875rem"
  },
  lengthTitle: {
    background: "rgba(236, 235, 239, 0.15)",
    borderRadius: "9px",
    padding: "2px 8px",
    width: "fit-content",
    justifySelf: "center",
    fontSize: "14px",
    fontFamily: "Roboto"
  },
  unitsList: {
    ...placeBackgroundColor(theme.palette.background.topDropDownDropDown),
    maxHeight: "40vh",
    overflowY: "auto"
  }
}));

export default useStyles;
