import { useState } from "react";

import { isUndefined, orderBy, merge, filter } from "lodash";

import { useStoreActions } from "../../models/RootStore";
import { UnitType, IParam } from "./types";

// special parameter to check procedure progress
const procedureStatusParam = {
  code: "procedureStatus",
  data_unit_of_measurement: "",
  enum: "procedureStatus",
  hvac_param_name: "ProcedureStatus",
  title: "Procedure Status",
  unitType: "",
};

const controlParamsMap: any = {
  52: {
    allBrands: true,
    code: 52,
    data_unit_of_measurement: "",
    enabledInTriggers: false,
    enum: "unitFanModes",
    hvac_param_name: "FanSpeed",
    max: 10,
    min: 0,
    plotable: true,
    showInGraph: true,
    title: "Fan Speed",
    unitType: UnitType.Service,
  },
  51: {
    allBrands: true,
    code: 51,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    enum: "unitOperationModes",
    hvac_param_name: "Mode",
    max: 10,
    min: 0,
    plotable: true,
    showInGraph: true,
    title: "Mode",
    unitType: UnitType.Service,
  },
  48: {
    allBrands: true,
    code: 48,
    data_unit_of_measurement: "",
    enabledInTriggers: true,
    enum: "unitOperationStatuses",
    hvac_param_name: "IndoorOnOffStatus",
    max: 1,
    min: 0,
    plotable: true,
    showInGraph: false,
    title: "ON/OFF",
    unitType: UnitType.Service,
  },
  49: {
    allBrands: true,
    code: 49,
    data_unit_of_measurement: "°C",
    enabledInTriggers: true,
    hvac_param_name: "RoomTemp",
    max: 0,
    min: 100,
    plotable: true,
    showInGraph: false,
    title: "Room Temp",
    unitType: UnitType.Service,
  },
  50: {
    allBrands: true,
    code: 50,
    data_unit_of_measurement: "°C",
    enabledInTriggers: true,
    hvac_param_name: "SetTemp",
    max: 0,
    min: 100,
    plotable: true,
    showInGraph: false,
    title: "Set Temp",
    unitType: UnitType.Service,
  },
  [procedureStatusParam.code]: procedureStatusParam,
};

const sortOptions = (options: { [key: string]: IParam }) => {
  const procedureStatus: IParam = options.procedureStatus;

  // filter and sort others (excluding those starting with *)
  const others: IParam[] = orderBy(
    filter(options, option => option.code !== "procedureStatus" && !option.title.startsWith("*")),
    ["title"],
    ["asc"]
  );

  // filter and sort options starting with *
  const starred: IParam[] = orderBy(
    filter(options, option => option.title.startsWith("*")),
    ["title"],
    ["asc"]
  );

  // build the final sorted array
  return [
    ...(procedureStatus ? [procedureStatus] : []),
    ...others,
    ...starred,
  ];
}

const orderedControlParams = sortOptions(controlParamsMap);

const filterParams = (params: Record<string, any>) => {
  return Object.values(params).filter(
    (param: any) =>
      (isUndefined(param.enabledInTriggers) ||
        param.enabledInTriggers === true) &&
      !param.isCustomParam
  );
}

export function useParams() {
  const getServiceParams = useStoreActions(
    (action) => action.traps.getServiceParams
  );

  const [paramsMap, setParamsMap] = useState<any>(controlParamsMap);
  const [params, setParams] = useState<any>(orderedControlParams);
  const [indoorParams, setIndoorParams] = useState<any>(orderedControlParams);


  const getParams = async (brand: any, siteId: string) => {
    let paramsMap = controlParamsMap;
    let orderedParams = orderedControlParams;
    let orderedIndoorParams = orderedControlParams;

    if (brand !== "all") {
      const [indoorParamsMap, outdoorParamsMap] = await Promise.all([
        getServiceParams({ site: siteId, brand, unitType: UnitType.Service }),
        getServiceParams({ site: siteId, brand, unitType: UnitType.Outdoor }),
      ]);

      Object.values(indoorParamsMap).forEach(
        (param: any) => (param.unitType = UnitType.Service)
      );
      Object.values(outdoorParamsMap).forEach(
        (param: any) => (param.unitType = UnitType.Outdoor)
      );

      paramsMap = merge({}, indoorParamsMap, outdoorParamsMap);
      paramsMap[procedureStatusParam.code] = procedureStatusParam;

      const filteredParamsArr = filterParams(paramsMap);
      orderedParams = sortOptions(paramsMap);
      orderedIndoorParams = sortOptions(indoorParamsMap);
    }

    setParamsMap(paramsMap);
    setParams(orderedParams);
    setIndoorParams(orderedIndoorParams);

    return paramsMap;
  };

  return { getParams, paramsMap, params, indoorParams };
}
