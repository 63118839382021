import {
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Delete from "../../components/Delete/Delete";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import StartEndTimePicker from "../../components/StartEndTimePicker/StartEndTimePicker";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { modeIcons } from "../../constants/modes";
import SButton from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import { Close } from "../../icons";
import { EditIcon } from "../../logos";
import { useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import { minsToTime, stringTimeToUTCMins } from "../../services/timeService";
import SwitchItem from "./SwitchItem";
import useStyles from "./UnitSettings.style";
import useChurnZero from "@hooks/useChurnZero";

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: any;
}
function ValueLabelComponent(props: Props) {
  const classes = useStyles();
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom"
      title={value}
      classes={{ popper: classes.tooltip, tooltip: classes.tooltipBox }}
    >
      {children}
    </Tooltip>
  );
}
const UnitSetting: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const { capabilityFlags = {} } = props;
  const {
    enableAppSettingsOnOffLock = false, enableAppSettingsSetpointChangeLock = false,
    enableAppSettingsModeLock = false, enableAppSettingsModeControl = false,
    enableAppSettingsFanControl = false, enableAppSettingsSwingControl = false,
    enableAppSettingsLimitCoolingSetpoint = false, enableAppSettingsLimitHeatingSetpoint = false,
    enableAppSettingsLimitAutoSetpoint = false, enableAppSettingsSetpointControlResolution = false
  } = capabilityFlags;
  const temperatureScale = props.useTemperatureScale;
  const types = useStoreState((states) => states.types);
  const user = useStoreState((state) => state.users.me);
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const [scheduleName, setScheduleName] = useState<any>("");
  const endActionTypesArr: any = Object.values(types.endActionTypes);
  const [endActionType, setEndActionType] = useState<any>(endActionTypesArr[1] ? endActionTypesArr[1].value : "");

  const { weekDays: weekDaysTypes = [] } = types;
  const weekDaysArray = Object.keys(weekDaysTypes);

  const [openPicker, setOpenPicker] = useState<string>("");
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isSavingInprogress, setIsSavingInprogress] = useState<boolean>(false);
  const [weekDays, setWeekDays] = useState<any>([]);
  const [error, setError] = useState<any>("");
  const [allDay, setAllDay] = useState<boolean>(true);
  const { timeFormat: timeFormatTypes } = types;
  const { timeFormat: myTimeFormat } = user;
  const { canEditScheule } = props;
  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours" ? true : false;

  const {trackEvent} = useChurnZero()

  const [supportedValues, setSupportedValues] = useState<any>({
    supportedOperationModes: [],
    supportedFanModes: [],
    supportedSwingModes: [],
    temperatureLimits: {
      0: temperatureScale === 1 ? [5, 28] : [41, 82],
      1: temperatureScale === 1 ? [15, 34] : [59, 93],
      2: temperatureScale === 1 ? [0, 28] : [32, 82]
    }
  });
  const [tempLimitFlags, setTempLimitFlags] = useState<any>({
    enableCoolMode: false,
    enableHeatMode: false,
    enableAutoMode: false
  });
  const [tStatFlags, setTStatFlags] = useState<any>({
    enableOnoff: false,
    enableMode: false,
    enableSetpoint: false
  });
  const hasValue = (value: any) => {
    return !!value || value === 0;
  };

  useEffect(() => {
    if (props.selectedSchedule) {
      const values: any = {};
      const schedule = _.cloneDeep(props.selectedSchedule);
      if (schedule) {
        setEndActionType(schedule.endActionType || Object.values(types.endActionTypes)[1]);

        if (!props.idDeviceLimit) {
          // values.supportedOperationModes = schedule.supportedOperationModes;
          // values.supportedFanModes = schedule.supportedFanModes;
          // values.supportedSwingModes = schedule.supportedSwingModes;
          values.temperatureLimits = schedule.temperatureLimits;
          setTStatFlags({
            enableOnoff: schedule.enableOnoff || false,
            enableMode: schedule.enableMode || false,
            enableSetpoint: schedule.enableSetpoint || false
            // enableOnState: schedule.enableOnState || false
          });
          setTempLimitFlags({
            enableCoolMode: schedule.enableCoolMode || false,
            enableHeatMode: schedule.enableHeatMode || false,
            enableAutoMode: schedule.enableAutoMode || false
          });
          setScheduleName(schedule.name);
          setWeekDays(schedule.days);
          setIsDisabled(schedule.isDisabled);
        }

        if (props.idDeviceLimit) {
          values.temperatureLimits = schedule.eWrcTemperatureLimits;
          setTStatFlags({
            enableOnoff: schedule.eWrcEnableOnoff || false,
            enableMode: schedule.eWrcEnableMode || false,
            enableSetpoint: schedule.eWrcEnableSetpoint || false
            // enableOnState: schedule.eWrcEnableOnState || false
          });
          setTempLimitFlags({
            enableCoolMode: schedule.eWrcEnableCoolMode || false,
            enableHeatMode: schedule.eWrcEnableHeatMode || false,
            enableAutoMode: schedule.eWrcEnableAutoMode || false
          });
          setScheduleName(schedule.name);
          setWeekDays(schedule.days);
          setIsDisabled(schedule.isDisabled);
        }

      }
      const { powerOnTime, powerOffTime } = schedule;
      if (powerOnTime === 0 && powerOffTime === 1439) {
        setAllDay(true);
      } else {
        setAllDay(false);
      }

      setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
      setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");

      setSupportedValues(values);
    } else {
      setTimeAllDay();
    }

  }, [props.selectedSchedule, is12Hours]);

  const getUnitKeyOfMode = (typesMode: string) => {
    let unitModeName: any = "";
    switch (typesMode) {
      case "operationModesExtended":
        unitModeName = "supportedOperationModes";
        break;
      case "fanModes":
        unitModeName = "supportedFanModes";
        break;
      case "swingModes":
        unitModeName = "supportedSwingModes";
        break;
      case "cooling":
        unitModeName = 0;
        break;
      case "heat":
        unitModeName = 1;
        break;
      case "auto":
        unitModeName = 2;
        break;
      default:
        unitModeName = null;
        break;
    }
    return unitModeName;
  };

  const errorBox = error ? <ErrorBox error={error} onClose={() => {
    setError(null);
    //  setOpen(false);
  }} /> : null;

  const changeSupportedValues = (e: any, mode: string) => {
    const isChecked = e.target.checked;
    const modeName = getUnitKeyOfMode(mode);
    const updatedValues: any = { ...supportedValues };

    if (!modeName) {
      return;
    }
    updatedValues[modeName] = !isChecked
      ? []
      : Object.keys(types[mode as any]).map((key: any) => +key);
    setSupportedValues(updatedValues);
  };

  const valueLabelFormat = (value: number) => {
    const valueToShow = value + (temperatureScale === 1 ? " °C" : " °F");
    return `${valueToShow}`;
  };

  const toggleAllWeekDays = () => {
    if (weekDays.length) {
      setWeekDays([]);
    } else {
      setWeekDays([
        "Friday",
        "Monday",
        "Saturday",
        "Sunday",
        "Thursday",
        "Tuesday",
        "Wednesday"]);
    }
  };
  const getTime = (selectedHour: any, selectedMinutes: any = 0) => {
    let exactHour = selectedHour; //0;
    const local = new Date();
    local.setHours(exactHour);
    local.setMinutes(selectedMinutes);

    const utcHours = local.getUTCHours();
    const utcMin = local.getUTCMinutes();
    const currentSelectedTime = +utcHours * 60 + utcMin;
    return currentSelectedTime;
  };
  const toggleAllDay = () => {
    if (allDay) {
      setAllDay(false);
    }
    else {
      setAllDay(true);
      setTimeAllDay();
    }

  };

  const setTimeAllDay = () => {
    let startHour, endHour;
    if (is12Hours) {
      startHour = "12".padStart(2, "0") + ":" + "00".padStart(2, "0") + "am";
      endHour = "11".padStart(2, "0") + ":" + "59".padStart(2, "0") + "pm";
    } else {
      startHour = "00".padStart(2, "0") + ":" + "00".padStart(2, "0");
      endHour = "23".padStart(2, "0") + ":" + "59".padStart(2, "0");
    }
    setPowerOnTime(startHour);
    setPowerOfftime(endHour);
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...[], ...weekDays];

    currentDays.includes(selectedDay)
      ? (currentDays = weekDays.filter((day: any) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setWeekDays(currentDays);
  };

  const updateValues = (modeName: string, modeNumber: any) => {
    const locValues: any = { ...supportedValues };
    const unitModeName = getUnitKeyOfMode(modeName);
    if (!unitModeName) {
      return;
    }

    const checkIndex = locValues[unitModeName].indexOf(+modeNumber);
    if (checkIndex > -1) {
      locValues[unitModeName].splice(checkIndex, 1);
    } else {
      locValues[unitModeName] = [...locValues[unitModeName], +modeNumber];
    }
    setSupportedValues(locValues);
  };

  const handleSetCooling = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[0] = newValue;
    setSupportedValues(locValues);
  };

  const handleSetHeat = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[1] = newValue;
    setSupportedValues(locValues);
  };

  const handleSetAuto = (event: any, newValue: number | number[]) => {
    const locValues: any = { ...supportedValues };
    locValues.temperatureLimits[2] = newValue;
    setSupportedValues(locValues);
  };

  const handleSetTempLimitRange = (flagName: string) => {
    const nextFlagState = _.clone(tempLimitFlags);
    nextFlagState[flagName] = !nextFlagState[flagName];
    setTempLimitFlags(nextFlagState);
  };

  const handleSave = async () => {
    trackEvent('AppControlSaveSchedule','App control schedule created');
    let name = scheduleName;
    if (!scheduleName) {
      setError("Please enter name");
      // name = weekDays.join('-');
      return;
    }
    if (!weekDays.length) {
      setError("Please select at least one day");
      return;
    }

    if (_.isNil(powerOnTime) || powerOnTime === "" || _.isNil(powerOffTime) || powerOffTime === "") {
      setError("Add start and end hour");
      return;
    }

    const onTime = stringTimeToUTCMins(powerOnTime, is12Hours);
    const offTime = stringTimeToUTCMins(powerOffTime, is12Hours);

    if (onTime > offTime) {
      setError("start time is after end time, please enter valid time");
      return;
    }

    // if (_.isNil(powerOffTime)) {
    //     setError('please select off time')
    //     return;
    // }
    // if (!tStatFlags.enableOnoff && !tStatFlags.enableMode && !tStatFlags.enableSetpoint &&
    //     !tStatFlags.enableOnState && !tempLimitFlags.enableCoolMode && !tempLimitFlags.enableHeatMode &&
    //     !tempLimitFlags.enableAutoMode) {
    //     setError("please select at least one mode");
    //     return;
    // }
    setIsSavingInprogress(true);

    const defaultEndActionToSave = endActionType || types.endActionTypes.revert.value;

    const data = {
      // supportedOperationModes: supportedValues.supportedOperationModes,
      // supportedFanModes: supportedValues.supportedFanModes,
      // supportedSwingModes: supportedValues.supportedSwingModes,
      temperatureLimits: supportedValues.temperatureLimits,
      // isVisible: isUnitVisible,
      enableOnoff: tStatFlags.enableOnoff || false,
      enableMode: tStatFlags.enableMode || false,
      enableSetpoint: tStatFlags.enableSetpoint || false,
      enableOnState: tStatFlags.enableOnState || false,
      enableCoolMode: tempLimitFlags.enableCoolMode || false,
      enableHeatMode: tempLimitFlags.enableHeatMode || false,
      enableAutoMode: tempLimitFlags.enableAutoMode || false,
      name,
      days: weekDays,
      powerOnTime: onTime,
      powerOffTime: offTime,
      endActionType: defaultEndActionToSave

      // isDisabled: !isDisabled,
    };
    await props.onSave(data);
    props.onCancle();
    setIsSavingInprogress(false);

  };
  const updateDisableState = () => {
    setIsDisabled(!isDisabled);
    props.onDisableUpdate({ isDisabled: !isDisabled, id: props.selectedSchedule.id });
  };

  const handleCancel = () => {
    props.onCancle();
  };

  const getHeatTempRange = (inDialog: boolean = false) => {
    const limitsTitleText = props.canEdit ? t`Limit heating setpoint range` : t`No heat temp limits`;
    const wrcTitleText = props.canEdit ? t`WRC Limit heating setpoint range` : t`No heating restrictions`;

    return (
      <div className={clsx(classes.modeCardScheculeView, { [classes.limitedCard]: !props.canEdit, [classes.limitedLargeWidth]: inDialog })}>
        <div className={classes.modeHeader}>
          <div className={classes.unitModeHeader}>
            <img
              src={assetsService.getImagesPath(`${modeIcons[1]}.svg`)}
              className={classes.imgIconStyle}
            ></img>
            {!props.idDeviceLimit &&
              <Typography className={classes.unitTitle}>
                {limitsTitleText}
              </Typography>
            }

            {props.idDeviceLimit &&
              <Typography className={classes.unitTitle}>
                {wrcTitleText}</Typography>
            }

          </div>

          {props.canEdit &&
            <CoolSwitch
              disabled={!canEditScheule}
              checked={tempLimitFlags.enableHeatMode}
              onChange={() =>
                handleSetTempLimitRange("enableHeatMode")
              }
            />
          }
        </div>
        {props.canEdit &&
          <div className={classes.sliderHolder}>
            <div className={classes.sliderAndLabelContainer}>
              <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[1][0])}</Typography>
              <Slider
                className={classes.heatSlider}
                onChange={handleSetHeat}
                aria-labelledby="discrete-slider-always"
                min={temperatureScale === 1 ? 0 : 32}
                max={temperatureScale === 1 ? 50 : 122}
                value={supportedValues.temperatureLimits[1]}
                valueLabelDisplay="off"
                step={1}
                disabled={!tempLimitFlags.enableHeatMode || !canEditScheule}
              />
              <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[1][1])}</Typography>
            </div>
          </div>
        }
      </div>
    );
  };

  const getCoolTempRange = (inDialog: boolean = false) => {
    const limitsTitleText = props.canEdit ? t`Limit cooling setpoint range` : t`No cool temp limits`;
    const wrcTitleText = props.canEdit ? t`WRC Limit cooling setpoint range` : t`No cooling restrictions`;

    return (
      <div className={clsx(classes.modeCardScheculeView, { [classes.limitedCard]: !props.canEdit, [classes.limitedLargeWidth]: inDialog })}>
        <div className={clsx(classes.modeHeader, classes.tempRangSlider)}>
          <div className={classes.unitModeHeader}>
            <img
              src={assetsService.getImagesPath(`${modeIcons[0]}.svg`)}
              className={classes.imgIconStyle}
            ></img>
            {!props.idDeviceLimit &&
              <Typography className={classes.unitTitle}>
                {limitsTitleText}</Typography>
            }

            {props.idDeviceLimit &&
              <Typography className={classes.unitTitle}>
                {wrcTitleText}</Typography>
            }
          </div>
          {props.canEdit &&
            <CoolSwitch
              disabled={!canEditScheule}
              checked={tempLimitFlags.enableCoolMode}
              onChange={() =>
                handleSetTempLimitRange("enableCoolMode")
              }
            />
          }
        </div>
        {props.canEdit &&
          <div className={classes.sliderHolder}>
            <div className={classes.sliderAndLabelContainer}>
              <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[0][0])}</Typography>
              <Slider
                className={classes.coolSlider}
                onChange={handleSetCooling}
                aria-labelledby="discrete-slider-always"
                min={temperatureScale === 1 ? 0 : 32}
                max={temperatureScale === 1 ? 50 : 122}
                value={supportedValues.temperatureLimits[0]}
                valueLabelDisplay="off"
                step={1}
                disabled={!tempLimitFlags.enableCoolMode || !canEditScheule}
              />
              <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[0][1])}</Typography>
            </div>
          </div>
        }
      </div>

    );
  };

  const getAutoTempRange = (inDialog: boolean = false) => {
    const limitsTitleText = props.canEdit ? t`Limit auto setpoint range` : t`No Auto temp limits`;

    return (
      <div style={{ marginRight: "0px" }} className={clsx(classes.modeCardScheculeView, { [classes.limitedCard]: !props.canEdit, [classes.limitedLargeWidth]: inDialog })}>
        <div className={classes.modeHeader}>
          <div className={classes.unitModeHeader}>
            <img
              src={assetsService.getImagesPath(`auto.svg`)}
              className={classes.imgIconStyle}
            ></img>
            <Typography className={classes.unitTitle}>
              {limitsTitleText}</Typography>
          </div>
          {props.canEdit &&
            <CoolSwitch
              disabled={!canEditScheule}
              checked={tempLimitFlags.enableAutoMode}
              onChange={() =>
                handleSetTempLimitRange("enableAutoMode")
              }
            />
          }

        </div>
        {props.canEdit &&
          <div className={classes.sliderHolder}>
            <div className={classes.sliderAndLabelContainer}>
              <Typography className={classes.leftLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[2][0])}</Typography>
              <Slider
                className={classes.autoSlider}
                onChange={handleSetAuto}
                aria-labelledby="discrete-slider-always"
                min={temperatureScale === 1 ? 0 : 32}
                max={temperatureScale === 1 ? 50 : 122}
                value={supportedValues.temperatureLimits[2]}
                valueLabelDisplay="off"
                step={1}
                disabled={!tempLimitFlags.enableAutoMode || !canEditScheule}
              />
              <Typography className={classes.rightLabelForamt}>{valueLabelFormat(supportedValues.temperatureLimits[2][1])}</Typography>
            </div>
          </div>
        }

      </div>
    );
  };

  const getEditLimits = (inDialog: boolean = false) => {
    if (!props.canEdit) {
      let enabledModesLength = tempLimitFlags.enableHeatMode ? 1 : 0;
      enabledModesLength = tempLimitFlags.enableCoolMode ? enabledModesLength + 1 : enabledModesLength;
      enabledModesLength = tempLimitFlags.enableAutoMode ? enabledModesLength + 1 : enabledModesLength;

      return (
        <>
          {(!tempLimitFlags.enableHeatMode && enableAppSettingsLimitHeatingSetpoint) && getHeatTempRange()}
          {(!tempLimitFlags.enableCoolMode && enableAppSettingsLimitCoolingSetpoint) && getCoolTempRange()}
          {(!tempLimitFlags.enableAutoMode && enableAppSettingsLimitAutoSetpoint) && getAutoTempRange()}
        </>
      );
    }

    return (
      <div className={classes.modeTempRangeContainer}>
        {enableAppSettingsLimitHeatingSetpoint && getHeatTempRange(inDialog)}
        {enableAppSettingsLimitCoolingSetpoint && getCoolTempRange(inDialog)}
        {enableAppSettingsLimitAutoSetpoint && getAutoTempRange(inDialog)}
      </div>
    );
  };

  const updateTstatFlags = (key: string, value: boolean) => setTStatFlags({ ...tStatFlags, [key]: value });

  const getEditTStats = () => {

    // let items = tStatFlags;
    // if (!props.canEdit) {
    //   items = _.reduce(tStatFlags, (obj: any, value: any, key: any) => {
    //     if (!tStatFlags[key]) {
    //       obj[key] = value;
    //     }
    //     return obj;
    //   }, {});
    // }
    return (
      <>
        {enableAppSettingsOnOffLock && ((!tStatFlags.enableOnoff && !props.canEdit) || props.canEdit) &&
          <SwitchItem
            disabled={!canEditScheule}
            onChange={(value: boolean) => updateTstatFlags("enableOnoff", value)}
            value={tStatFlags.enableOnoff}
            title={t`Allow On/Off`}
            hideSwitch={!props.canEdit}
          />}
        {enableAppSettingsModeLock && ((!tStatFlags.enableMode && !props.canEdit) || props.canEdit) &&
          <SwitchItem
            disabled={!canEditScheule}
            onChange={(value: boolean) => updateTstatFlags("enableMode", value)}
            value={tStatFlags.enableMode}
            title={t`Allow mode settings`}
            hideSwitch={!props.canEdit}
          />}
        {enableAppSettingsSetpointChangeLock && ((!tStatFlags.enableSetpoint && !props.canEdit) || props.canEdit) &&
          <SwitchItem
            disabled={!canEditScheule}
            onChange={(value: boolean) => updateTstatFlags("enableSetpoint", value)}
            value={tStatFlags.enableSetpoint}
            title={t`Allow setpoint change`}
            hideSwitch={!props.canEdit}
          />}
      </>
    );
  };
  const onSetTime = (openPicker: string, time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
  };

  const hasTime = (time: any) => {
    return time !== "" && time !== null && time >= 0;
  };
  const handleSelectEndActionType = (type: any) => {
    setEndActionType(type);
  };
  const getOnEnd = () => {
    const options: any = {
      "noAction": "no action",
      "revert": "Return to previous settings",
      "doNothing": "Keep schedule settings",
      "allowAll": "Allow all"
    };
    const actionTypesOptions = Object.values(types.endActionTypes).filter((type: any) => {
      return type.text !== "noAction";
    });
    return <Select
      disabled={!canEditScheule}
      labelId="endActionType"
      value={endActionType}
      className={classes.endActionTypeSelect}
      onChange={(event: any) => handleSelectEndActionType(event.target.value)}
      variant="outlined"
      disableUnderline
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
    >
      {actionTypesOptions.map((type: any) => (
        <MenuItem key={`type-${type?.value}`} value={type.value}>{options[type.text]}</MenuItem>
      ))}
    </Select>;

  };
  const getTimeSettingsView = () => {
    let time = `${powerOnTime && powerOnTime} ${powerOnTime && powerOffTime ? "-" : ""} ${powerOffTime}`;
    return (
      <div className={classes.weekDaysConatiner}>
        {!!time.trim().length &&
          <Button
            disableRipple
            disableElevation
            disabled
            style={{ backgroundColor: "transparent", color: "black", padding: "0" }}
            onClick={() => { }}
            variant="contained"
            className={clsx(classes.cancelActionBtn, classes.rightTimeBtn, classes.timeBtnView, classes.timeBtns, classes.viewTimeBtns)}
          >
            {time}
          </Button>
        }
        <div style={{ marginBottom: "10px" }}>
          <DaysList
            days={weekDaysArray}
            activeDays={weekDays}
            action={addRemoveDay}
            white={false}
            disablePointer
          />
        </div>
      </div>
    );
  };

  const getTimeSettings = () => {
    return (
      <div className={classes.timeContainer}>
        <div className={classes.weekDaysConatinerEdit}>
          <Typography className={clsx(classes.selectModeStyle)}              >
            {t`Choose Days`}
          </Typography>
          <Grid container className={classes.daysContainer} id="days">
            <DaysList
              days={weekDaysArray}
              activeDays={weekDays}
              action={addRemoveDay}
              white={false}
              disablePointer={!canEditScheule}
            />
          </Grid>
        </div>
        <div className={classes.divider}></div>
        <div style={{ display: "flex", flexFlow: "column", marginLeft: "-7px" }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
            <Checkbox
              disabled={!canEditScheule}
              checked={!allDay}
              onChange={toggleAllDay}
              name="checkedB"
              color="default"
            />
            <Typography className={classes.timeCheckbox}>{t`Daily Hours`}</Typography>
          </div>
          <div style={{ width: is12Hours ? "450px" : "350px", marginLeft: "-9px", marginBottom: "9px" }}>
            <StartEndTimePicker
              powerOnTime={powerOnTime}
              powerOffTime={powerOffTime}
              is12Hours={is12Hours}
              onSetTime={onSetTime}
              disabled={allDay || !canEditScheule}
            />
          </div>
        </div>
      </div>
    );
  };

  if (!props.canEdit) {
    return (
      <div className={classes.scheduleItemContainer}>

        <div className={classes.scheduleItemheader}>
          <Typography className={classes.scheduleName}>{scheduleName}</Typography>
          <div>
            <LightTooltip title={t`Edit Schedule`}>
              <IconButton disableRipple onClick={props.onEditAction} className={classes.iconBtn}>
                <EditIcon />
              </IconButton>
            </LightTooltip>
            <Delete
              iconBtnStyle={classes.deleteIcon}
              type={t`Schedule`}
              object={props.selectedSchedule || {}}
              detach={props.onDelete}
              disabled={!props.canDelete}
            />
            <CoolSwitch disabled={!props.canEditScheule} checked={!isDisabled} onChange={updateDisableState} />
          </div>
        </div>
        {getTimeSettingsView()}
        <div className={classes.viewContainer}>
          {getEditTStats()}
        </div>
        <div className={classes.viewContainer}>
          {getEditLimits()}
        </div>

      </div>
    );
  }

  return (
    <>
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{props.title}</Typography>
        <IconButton disableRipple onClick={handleCancel} className={classes.iconBtnStyle}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <div className={classes.dialogContent}>
        <InputLabel className={classes.nameLabel}>{t`Name`}
          <TextField
            disabled={!canEditScheule}
            InputProps={{ classes: { root: classes.nameInput }, disableUnderline: true }}
            classes={{ root: classes.inputField }}
            value={scheduleName}
            onChange={(e) => setScheduleName(e.target.value)} />
        </InputLabel>
        {/* <div className={classes.timeContainer}> */}
        {getTimeSettings()}
        {/* </div> */}

        <div className={classes.onOffSettings}>
          {getEditTStats()}
        </div>
        {errorBox}
        {getEditLimits(true)}
        <Card className={classes.onEndSelect}>
          <div className={classes.endTypeLabel}>{t`On End:`}</div>
          {getOnEnd()}
        </Card>
      </div>

      <div className={classes.actionsHolder}>
        <SButton disabled={!canEditScheule} white width={150} marginRight onClick={handleCancel}>
          {t`CANCEL`}
        </SButton>
        <SButton disabled={!canEditScheule || isSavingInprogress} width={150} onClick={handleSave}>
          {t`SAVE`}
        </SButton>
      </div>
    </>
  );

};

export default UnitSetting;
