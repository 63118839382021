import { makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: "15px",
    overflow: "auto",
    display: "flex",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    margin: "20px",
  },
  sideMenuContent: {
    height: "100%",
    backgroundColor: "white",
    marginLeft: "10px",
  },
  headerApplyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1.2px #e1e2ea",
    paddingBottom: 15,
  },
  unitsContainer: {
    height: "500px",
  },
  thresholdBlock: {
    width: "47%",
  },
  title: {
    margin: "10px 0",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.light,
  },
  modeThresholdRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 10px",
    alignItems: "center",
  },
  modeIcon: {
    transform: "scale(1.5)",
    marginRight: "10px",
  },
  thresholdInput: {
    width: "86%",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  root: {
    width: "100%",
    borderRadius: "8px",
    border: "solid 1px #d5d2d5",
  },
  rightBorder: {
    borderRight: "solid 1px #d5d2d5",
  },
  selectedTab: {
    background: "#e1e2ea",
  },
  indicatorColor: {
    backgroundColor: theme.palette.primary.main,
    display: "none",
  },
  selectModeStyle: {
    fontSize: "18px",
    lineHeight: "normal",
    fontWeight: "normal",
    margin: "10px",
    marginLeft: "0px",
    display: "flex",
    alignItems: "center",
    color: theme.overrides.MuiButton.contained.color,
  },
  daysContainer: {
    padding: "0px 0px",
    margin: "0px 0px",
    justifyContent: "center",
    width: "auto",
  },
  calendar: {
    fontFamily: "Roboto",
    "& .DayPicker-Month": {
      width: "350px",
      height: "290px",
    },
    "& .DayPicker-Weekday": {
      color: theme.palette.colors.black,
    },
    "& .DayPicker-Day": {
      color: theme.palette.colors.black,
    },
    "& .DayPicker-Day--disabled": {
      color: alpha(theme.palette.colors.black || "#000", 0.2),
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)":
      {
        backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color,
      },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover":
      {
        backgroundColor: theme.overrides.MuiButton.outlinedPrimary.color,
      },
    "& .DayPicker:not(.DayPicker--interactionDisabled):hover": {
      backgroundColor: `${alpha(
        theme.palette.colors.black || "#000",
        0.1
      )} !important`,
    },
    "& .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover":
      {
        backgroundColor: `${alpha(
          theme.palette.colors.black || "#000",
          0.1
        )} !important`,
      },
  },
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
    width: "100%",
  },
  cancelActionBtn: {
    color: "#4b1c46",
    height: "40px",
    fontSize: "16px",
    minWidth: "120px",
    background: "#fff",
    fontWeight: 500,
    lineHeight: "normal",
    borderRadius: "4px",
    textTransform: "uppercase",
    border: "solid 1px #413044",
    "&:hover": {
      background: "#fff",
    },
  },
  rightTimeBtn: {
    marginLeft: ".5rem",
    marginRight: "1.5rem",
  },
  timeBtns: {
    borderRadius: "6px",
    border: "solid 1px #d5d2d5",
    color: "#545964",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  timeCheckbox: {
    color: "#545964",
    fontSize: "13px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginRight: "1.5rem",
    display: "inline-block",
  },
  localTimePicker: {
    width: 280,
    height: 140,
    position: "relative",
    "& div button": {
      color: "#545964",
    },

    "& > div > div": {
      width: "280px",
      height: "187px",
      border: "1px solid #d5d2d5",
      backgroundColor: "white",
    },
  },
  topTitle: {
    fontFamily: "RobotoMedium",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#7f7182",
    alignItems: "center",
    display: "flex",
    marginRight: "1.5rem",
    fontSize: "16px",
  },
  nameInput: {
    width: "97%",
    marginBottom: "1rem",
  },
  errorMsg: {
    color: "red",
    fontSize: "15px",
    fontFamily: "Roboto",
    height: "20px",
    margin: "0 20px",
  },
  daysDatesContent: {
    borderBottom: "1px solid #d5d2d5",
    marginBottom: "20px",
  },
  section: {
    padding: "4px 20px !important",
  },
  diagramContainer: {
    marginTop: "20px",
  },
  setbackDescription: {
    padding: "20px",
    fontSize: "16px",
    fontWeight: "normal",
    whiteSpace: "pre-line",
    color: "#7f7182",
    fontFamily: "Roboto",
    lineHeight: 1.63,
  },
}));

export default useStyles;
