import * as React from 'react';

function SvgExcell(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#107C41"
          strokeWidth={1.4}
          d="M15.91.7a1.3 1.3 0 01.966.43h0l1.804 2.005 3.086 3.43c.215.238.334.548.334.87h0V20a3.29 3.29 0 01-.967 2.333 3.286 3.286 0 01-2.333.967h0H5.2a3.29 3.29 0 01-2.333-.967A3.286 3.286 0 011.9 20h0V4c0-.911.37-1.736.967-2.333A3.286 3.286 0 015.2.7h0z"
        />
        <path
          fill="#107C41"
          d="M8.4 16.8l2.608-4.212L8.619 8.4h1.923l1.303 2.677c.12.254.203.443.248.568h.017c.086-.203.176-.4.27-.591L13.772 8.4h1.765l-2.45 4.165L15.6 16.8h-1.877l-1.507-2.94a2.791 2.791 0 01-.18-.393h-.022a1.867 1.867 0 01-.174.38L10.289 16.8H8.4z"
        />
      </g>
    </svg>
  );
}

export default SvgExcell;
