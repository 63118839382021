import { Card, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { t } from "ttag";
import alertSeverities from "../../constants/alertSeverities";
import urlFlagMap from "../../constants/urlToFlagMapping";
import { useStoreState } from "../../models/RootStore";

const useStyles = makeStyles((theme) => ({
  alerts: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    minHeight: "145px",
    paddingTop: "20px",
    borderBottom: "solid 1px #e5e2e5"
  },

  alertsTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0.5rem 0",
    fontSize: "33px",
    color: "#fff"
  },

  alertIcon: {
    paddingRight: "0.5rem"
  },

  alertsHeaderTitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    paddingLeft: "15px"
  },

  alertsStatistics: {
    display: "flex",
    marginTop: "25px",
    overflowX: "auto",
    overflowY: "hidden",
    width: "100%",
    justifyContent: "space-between"
  },

  singleAlertStatisticsBlock: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    "&:last-child": {
      border: "none"
    }
  },
  iconStyle: {
    color: "#aaa2aa",
    fontSize: "12px"
  },
  hideIcon: {
    visibility: "hidden"
  },
  singleAlertStatisticsText: {
    fontSize: "11px",
    fontFamily: "Roboto",
    fontWeight: 600,
    color: "#29132e",
    paddingRight: "3px",
    whiteSpace: "nowrap",
    lineHeight: "normal",
    letterSpacing: "normal",
    paddingTop: "10px"
  },

  singleAlertStatisticsCount: {
    fontSize: "36px",
    fontFamily: "RobotoLight",
    paddingRight: "2px"
  },
  selectedStyle: {
    backgroundColor: "#e9e7e9"
  }
}));



const AlertsBand = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { alertsCount } = props;

  const alertsTypes = [
    { key: "System", code: "system", label: t`System`, color: alertSeverities.SYSTEM_ERROR.color },
    { key: "Indoor", code: "indoor", label: t`Indoor`, color: alertSeverities.INDOOR_ERROR.color },
    { key: "Communication", code: "communication", label: t`Communication`, color: alertSeverities.NOT_CONNECTED.color },
    { key: "Anomalies", code: "anomalies", label: t`Anomalies`, color: alertSeverities.ANOMALIES.color },
    { key: "Maintenance", code: "maintenance", label: t`Maintenance`, color: alertSeverities.MAINTENANCE.color }
  ];

  return (
    <Card elevation={0} className={classes.alerts}>
      <Typography className={classes.alertsHeaderTitle}>{t`OPEN ALERTS`}</Typography>
      <div className={classes.alertsStatistics}>
        {alertsTypes.map(({ key, code, label, color }: any) => {
          const count = alertsCount[code] || 0;
          return (
            <Fragment key={key}>
              <div className={clsx(classes.singleAlertStatisticsBlock)}
                onClick={() => {
                  const data: any = {
                    filters: { status: { "Open": true }, type: { [key]: true }, code: {}, description: {} }
                  };

                  history.push({
                    pathname: `/alerts`,
                    state: data
                  });

                }}
              >
                <span
                  className={classes.singleAlertStatisticsCount}
                  style={{ color }}
                >
                  {count < 10 ? "0" + count : count}
                </span>
                <span className={classes.singleAlertStatisticsText}>{label}</span>
              </div>
            </Fragment>
          );
        })}
      </div>
    </Card>
  );
};

export default AlertsBand;
