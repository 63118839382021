import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto",
  },

  container: {
    width: "100%",
    background: "rgb(236, 235, 239)",
    alignContent: "flex-start",
  },
  headerStyle: {
    fontWeight: "bold",
    color: theme.palette.primary.light,
    marginBottom: "5px",
  },
  header: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginTop: "15px",
  },
  rightCard: {
    marginBottom: "15px",
    marginLeft: "15px",
    paddingLeft: "20px",
    borderLeft: "1px solid #d5d2d5",
  },

  submitButton: {
    width: "100%",
  },
  dialog: {
    width: "100%",
    borderRadius: "8px",
    maxWidth: "600px !important"
  },
  title: {
    height: "60px",
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    alignItems: "center",
    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignContent: "center",
    },
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0",
    },
  },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
  },
  personalDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    "& input": {
      padding: "8px 12px",
    },
    "& *": {
      color: theme.palette.colors.gray,
    },
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    ...theme.dialogs.title,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    flex: "1 1 auto",
    padding: 20,
    overflowY: "auto",
    paddingBottom: 0,
  },
  actionsHolder: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  emailContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  emailField: {
    width: "63%",
  },
  sendTestEmailButton: {
    height: "35px",
    marginTop: "8px",
  }
}));

export default useStyles;
