import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";
import { filter, reject } from 'lodash';
import { getIn } from "formik";
import { t } from "ttag";

import { ISingleStep, UnitType, IGroupProps } from '../../hooks/useScripts/types';
import { Disconnect, MoreVert } from "../../icons";
import MultiSelect from "./UnitsMultiSelect";
import Condition from "./Condition";

import styles from "./AddEditScript.style";

const GroupedSteps = ({
  key,
  name,
  group,
  unGroup,
  moveProps = {},
  deleteCondition,
  index: groupIndex,
  getGroupedStepsUnitType,
  ...props
}: IGroupProps) => {

  const { steps, selectedEntities } = group;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [selectTrigger, setSelectTrigger] = useState<boolean>(false);

  const handleDeleteCondition = (index: number) => () => {
    deleteCondition(index);
  };

  const renderSteps = () => {
    return steps.map((step: ISingleStep, index: number) =>
      <Condition
        {...props}
        key={step.id}
        index={index}
        condition={step}
        deleteCondition={handleDeleteCondition(index)}
        name={`${name}.${groupIndex}.steps`}
        inGroup
        handleUnitTypeChange={handleUnitTypeChange}
      />
    )
  }

  const handleUnitTypeChange = (stepUnitType: UnitType | '', index: number) => {
    const groupSteps = [...steps];
    groupSteps[index].unitType = stepUnitType;

    const unitType: UnitType = getGroupedStepsUnitType(groupSteps);
    let selectedEntities = group.selectedEntities;

    if (unitType === UnitType.Service) {
      selectedEntities = reject(selectedEntities, { type: UnitType.Outdoor });
    } else if (unitType === UnitType.Outdoor) {
      selectedEntities = filter(selectedEntities, { type: UnitType.Outdoor });
    }

    props.setFieldValue(`${name}.${groupIndex}.unitType`, unitType);
    props.setFieldValue(`${name}.${groupIndex}.selectedEntities`, selectedEntities);
    setSelectTrigger(!selectTrigger);
  }

  return (
    <div key={key} className={classes.groupContainer}>
      <div className={classes.groupContent}>
        <div {...moveProps} className={classes.groupLeftPart}>
          <MoreVert className={classes.whiteMoreVert} />
        </div>
        <div className={classes.ingroupConditionsContainer}>
          {props.specificUnitsEnabled &&
            <div className={classes.unitsSelectContainer}>
              <MultiSelect
                options={props.systemUnitsOptions[group.unitType]}
                entitiesMap={props.entitiesMap}
                onChange={(value: any) => props.setFieldValue(`${name}.${groupIndex}.selectedEntities`, value)}
                disabled={props.disabled}
                error={!!(getIn(props.errors, `${name}[${groupIndex}].selectedEntities`) && getIn(props.touched, `${name}[${groupIndex}].selectedEntities`))}
                name={`${name}.${groupIndex}.selectedEntities`}
                value={group.selectedEntities}
                placeholder={t`Specific Unit/s`}
                forceRender={selectTrigger}
              />
            </div>
          }
          {renderSteps()}
        </div>
        <div onClick={unGroup} className={classes.dissconnnectRect}>
          <Disconnect className={classes.disconnectIcon} />
        </div>
      </div>
    </div>
  );
};

export default GroupedSteps
