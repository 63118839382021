import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    background: "rgb(236, 235, 239)"
  },
  content: {
    padding: "1rem 1rem",
    height: "100%",
    overflow: "hidden",
    maxHeight: "calc(100% - 65px)"
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  mapArea: {
    maxHeight: "calc(100vh - 85px)"
  },
  tablesArea: {
    maxHeight: "calc(100vh - 85px)"
  },
  tablesContainer: {
    height: "calc(100vh - 100px)",
    flexWrap: "nowrap"
  },
  HVACContainer: {
    width: "100%",
    maxHeight: "60%",
    display: "flex"
  },
  HVACCard: {
    height: "100%",
    padding: "1.25rem",
    display: "flex"
  },
  alertsContainer: {
    width: "100%",
    height: "40%",
    maxHeight: "40%",
    flexGrow: 2,
    overflow: "hidden",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column"
  },
  errorLogsCard: {
    boxSizing: "border-box",
    height: "100%",
    padding: "0.85rem 1.25rem 0"
  },
  googleCard: {
    padding: "1.25rem"
  },
  listItem: {
    display: "block",
    fontSize: "0.75rem",
    color: theme.tables.tableCell.color,
    paddingLeft: 0
  },
  detailsSectionSecondTitle: {
    display: "inline-block",
    fontSize: "20px",
    fontWeight: 500,
    padding: "0 0 1rem 0",
    color: "rgb(75, 28, 70)"
  },

  HVAC: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto"
  },
  operationalView: {
    height: "100%",
    overflow: "auto",
    flex: 1,
    maxWidth: "415px"
  },
  fullHeight: {
    height: "100%",
    paddingLeft: "16px",
    minWidth: "410px",
    flex: 1
  },
  maxAlerts: {
    height: '100%',
    maxHeight: '100%'
  }

}));

export default useStyles;
