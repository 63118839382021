import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { CustomConfirmationDialogStyles } from "./CustomConfirmationDialog.style";

interface ToolbarProps {
    onCancel: any;
    onConfirm: any;
    onInputChange?: any;
    onClose: any;
    title?: string;
    text?: string;
    errorText?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    openDialog: boolean;
}

//todo:
// 1. Convert to js.
// 2. Either rename to "2FA Confirmation Dialog" or make more generic:
// pass in Header, Content, Footer components as props. 
const CustomConfirmationDialog = (props: ToolbarProps) => {
    const {
        onCancel,
        onConfirm,
        title = "t`Confirmation with input`",
        text = t`Are you sure you want to proceed`,
        openDialog,
        cancelLabel = t`Cancel`,
        confirmLabel = t`Yes`,
        onClose,
        errorText,
        onInputChange,
    } = props;

    const useStyles = makeStyles(CustomConfirmationDialogStyles);
    const classes: { [index: string]: any } = useStyles();
    const [inputValue, setInputValue] = useState<any>(null);

    const handleConfirm = () => {
        onConfirm(inputValue)
        setInputValue('')
    }

    const handleInputChange = (e:any) => {
        onInputChange()
        setInputValue(e.target.value)
    }

    return (
        <Dialog
            open={openDialog}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            classes={{ paper: classes.dialogStyle }}
        >
            <DialogTitle
                disableTypography
                id="responsive-dialog-title"
                className={classes.dialogTitle}
            >
                {title}
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentStyle }}>
                <DialogContentText className={classes.contentStyle}>
                    {text}
                </DialogContentText>
            </DialogContent>

            <TextField
                className={classes.input}
                id="2FAcode"
                label="Code"
                value={inputValue ?? ""}
                onChange={handleInputChange}
            />

            {errorText ? <Typography style={{ color: "red" }}> {errorText} </Typography> : null}

            <DialogActions
                className={classes.dialogActionStyle}
                classes={{ spacing: classes.actionsSpacing }}
            >
                <Button
                    width={100}
                    white
                    marginRight
                    onClick={onCancel}
                >
                    {cancelLabel}
                </Button>
                <Button
                    width={100}
                    onClick={handleConfirm}
                    disabled={!inputValue}
                >
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomConfirmationDialog;
