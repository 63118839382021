import { makeStyles, Theme } from "@material-ui/core/";
import { placeBackgroundColor } from "../../services/utils";

const useStyles = makeStyles((theme: any) => ({
  optionStyle: {
    color: theme.palette.colors.menuFont,
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%"
  },
  optionText: {
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  selectContainer: {
    height: "36px",
    width: "20%",
    backgroundColor: "transparent !important",
    "&:disabled": {
      backgroundColor: "transparent !important"
    }
  },
  selectStyle: {
    color: theme.palette.colors.menuFont,
    ...placeBackgroundColor(theme.palette.background.topDropDownSelect),
    borderRadius: "4px",
    margin: "1rem .5rem",
    padding: "0 9px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      ...placeBackgroundColor(theme.palette.background.topDropDownSelect),
    }
  },
  inputStyle: {},
  iconStyle: {
    right: "0"
  },
  focusStyle: {
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "4px"
    }
  },
  arrowIcon: {
    color: "#fff",
    fontSize: "2.5rem !important",
    top: "0",
    height: "36px"
  },
  paperStyle: {
    backgroundColor: theme.palette.background.topDropDownDropDown
  },
  selected: {
    ...placeBackgroundColor(theme.palette.background.topDropDownDropDownSelected),

  },
  input: {
    color: theme.palette.colors.menuFont
  },
  textField: {
    "& fieldset": {
      borderColor: "rgba(170, 162, 170, 0.3)",
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "rgba(170, 162, 170, 0.3) !important"
      }
    }
  },
  hideOption: {
    visibility: "hidden",
    height: 0,
    padding: 0
  },
  subHeader: {
    position: "unset"
  }
}));

export default useStyles;
