import {
  Card,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import React from "react";
import { t } from "ttag";
import { Close } from "../../icons";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./AuditsList.style";
import auditActions from "./AuditActions";

const dataPropertiesReplacement: { [key: string]: any } = {
  isRegistered: "Connected",
  isExpired: "Expired"
},
  notReadable = [
    "customer",
    "isAcceptedTOU",
    "_id",
    "__v",
    "user",
    "userLocationsMetadata",
    "password",
    "randId",
    "id",
    "device",
    "admin",
    "lat",
    "lon",
    "fileId",
    "type",
    "site",
    "scheduleCategory"
  ];

// tried to keep the changes as minimal to avoid regressions
// TBD: needs to be refactored also

export default function AuditsDataPopUp(props: any) {
  const allUnits = useStoreState((state) => state.units.allUnits);
  const allGroups = useStoreState((state) => state.groups.allGroups);
  const allSystems = useStoreState((s) => s.systems.allSystems);
  const allDevices = useStoreState((s) => s.devices.allDevices);
  const allCustomers = useStoreState((s) => s.customers.allCustomers);
  const getSite = useStoreState((s) => s.sites.getSite);

  const { audit, roles, schedules, changeovers, traps, users } = props,
    { schedule, changeover, trap, source, sourceType, customer, action: actionId, actor: user, site, device, unit, line, data = {} } = audit,
    { operationStatuses, operationModesExtended, fanModes, swingModes, temperatureScale, actionSources } = useStoreState((s) => s.types),
    classes = useStyles(),
    timeFormat = useStoreState((state) => state.users.timeFormat),

    valueMapping = (value: number, action: string) => {
      const lowerCaseAction = action.toLowerCase();
      if (lowerCaseAction.includes("setpoint")) { return value; }
      if (lowerCaseAction.includes("operationstatus")) { return operationStatuses[value] || value; }
      if (lowerCaseAction.includes("operationmode")) { return operationModesExtended[value] || value; }
      if (lowerCaseAction.includes("fanmode")) { return fanModes[value] || value; }
      if (lowerCaseAction.includes("swingmode")) { return swingModes[value] || value; }
      return value;
    },
    valueTitleMapping = (action: string) => {
      const lowerCaseAction = action.toLowerCase();
      if (lowerCaseAction.includes("setpoint")) { return t`Setpoint`; }
      if (lowerCaseAction.includes("operationstatus")) { return t`Operation Status`; }
      if (lowerCaseAction.includes("operationmode")) { return t`Operation Mode`; }
      if (lowerCaseAction.includes("fanmode")) { return t`Fan Mode`; }
      if (lowerCaseAction.includes("swingmode")) { return t`Swing Mode`; }
      return t`value`;
    },
    replaceTitle = (property: string) => {
      const replacement = dataPropertiesReplacement[property];
      if (replacement) { return replacement; }
      const title = property
        .split(/(?=[A-Z])/)
        .join(" ")
        .trim();
      return title;
    },
    getActionText = (actionId: string) => {
      const action: any = auditActions[actionId] || actionId;
      if (action) {
        return action;
      }
      const actionArray = actionId.split(/(?=[A-Z])/);
      if (["update", "create", "accept", "delete", "remove"].includes(actionArray[0])) {
        actionArray[0] = actionArray[0] + "d";
      }
      if (["add"].includes(actionArray[0])) {
        actionArray[0] = actionArray[0] + "ed";
      }

      actionArray[0] = actionArray[0].charAt(0).toUpperCase() + actionArray[0].substring(1);
      return actionArray.join(" ").trim();
    },
    convertDate = () => {
      const { timestamp } = audit;
      const timezone = getSite(site)?.timezone || '';
      const date = timezone ? moment(timestamp).tz(timezone).format(`ll ${timeFormat}`) : moment(timestamp).format(`ll ${timeFormat}`);
      return date;
    },
    DataRow = ({ property, content, action, index }: any) => {
      const timezone = getSite(site)?.timezone || '';
      const contentText = typeof content === "boolean" ? (content ? "yes" : "no") : content;
      return (
        <div className={clsx(classes.mainRow, { [classes.grayRow]: index % 2 })}>
          <Typography className={classes.rowTitle}>
            {property === "value" ? valueTitleMapping(action) : replaceTitle(property)}
          </Typography>
          <Typography className={classes.rowData}>
            {property === "value" ? `${valueMapping(contentText, action)}`
              : property === "temperatureScale" ? `${temperatureScale[contentText]}`
                : property === "unit" ? `${allUnits[contentText]?.name || ""}`
                  : property === "system" ? `${allSystems[contentText]?.name || ""}`
                    : property === "group" ? `${allGroups[contentText]?.name || ""}`
                      : property === "expiryTimestamp" ? `${moment(contentText * 1000).tz(timezone).format(`ll ${timeFormat}`) || moment(contentText).format(`ll ${timeFormat}`)}`
                        : property === "role" ? `${roles[contentText]?.label || "-"}`
                          : `${contentText}`}
          </Typography>
        </div>
      );
    },
    MainDataRow = ({ title, content, index }: any) => (
      <div className={clsx(classes.mainRow, { [classes.grayRow]: index % 2 })}>
        <Typography className={classes.rowTitle}>{`${title}`}</Typography>
        <Typography className={classes.rowData}>{`${content}`}</Typography>
      </div>
    ),
    getTimeFromMins = (mins: number) => {

      const h = (mins / 60) || 0,
        m = (mins % 60) || 0;
      return moment.utc().hours(h).minutes(m).format("hh:mm A");
    };
  let countedIndex = 0;
  const auditSource = (source: number) => {
    switch (source) {
      case +actionSources.user: return t`Application User`;
      case +actionSources.schedule: return t`Scheduled Operation`;
      case +actionSources.homeAutomation: return t`Home automation / BMS`;
      case +actionSources.hvac: return t`HVAC`;
      case +actionSources.applicationLogic: return t`Application Logic`;
      case +actionSources.ecobee: return t`Ecobee`;
      case +actionSources.deviceLCD: return t`CA Device LCD`;
      default: return t`Unknown source`;
    }
  };

  if (source === +actionSources.schedule || source === +actionSources.applicationLogic) {
    if (schedule)
      data.scheduleName = schedules[schedule]?.name || data.scheduleName;

    if (changeover)
      data.ruleName = changeovers[changeover]?.name || data.ruleName;

    if (trap) {
      data.ruleName = traps[trap]?.name;
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.titleContainer}>
          <Typography className={classes.headerTitle}>{t`Audit Data`}</Typography>
          <IconButton disableRipple onClick={() => props.setIsSeen(null)} className={classes.iconBtnStyle}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <div className={classes.dialogContent}>
          <Card className={classes.container}>
            <MainDataRow title={t`Source`} content={auditSource(+source)} index={countedIndex++} />
            <MainDataRow title={t`Source Type`} content={sourceType || "_"} index={countedIndex++} />
            <MainDataRow title={t`Customer`} content={allCustomers[customer]?.name || '-'} index={countedIndex++} />
            <MainDataRow title={t`Action`} content={getActionText(actionId).replace(/Trap/g, "Trigger").replace(/Event/g, "Alert")} index={countedIndex++} />
            <MainDataRow title={t`Date`} content={convertDate()} index={countedIndex++} />
            <MainDataRow title={t`User`} content={users[user]?.username || '-'} index={countedIndex++} />
            {site && <MainDataRow title={t`Site`} content={getSite(site)?.name || '-'} index={countedIndex++} />}
            {device && <MainDataRow title={t`Device`} content={allDevices[device]?.name || '-'} index={countedIndex++} />}
            {unit && <MainDataRow title={t`Unit`} content={allUnits[unit]?.name || '-'} index={countedIndex++} />}
            {line && <MainDataRow title={t`Line`} content={line} index={countedIndex++} />}
            {data &&
              Object.keys(data).map((key: any) => {
                let content = data[key];
                if (
                  typeof content !== "string" &&
                  typeof content !== "number" &&
                  typeof content !== "boolean"
                ) {
                  return null;
                }

                if (notReadable.includes(key)) {
                  return null;
                }
                if (typeof content === "number" && (key === "powerOffTime" || key === "powerOnTime")) {
                  content = getTimeFromMins(content);
                }
                return (
                  <DataRow
                    key={`audit-data-${countedIndex}`}
                    property={key}
                    content={content}
                    action={actionId}
                    index={countedIndex++}
                  />
                );
              })}
          </Card>
        </div>
      </Dialog>
    </div>
  );
}
