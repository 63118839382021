import { createStyles, Grid, IconButton, Input, makeStyles, Theme, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Tooltip from "../../components/Tooltip/LightTooltip";
import { Delete } from "../../icons";
import {
  Alert as SvgAlert,
  Disconnect as SvgDisconnect,
  Refresh as SvgRefresh
} from "../../icons/";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import styles from "./DeviceCard.module.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      "& div": {
        maxWidth: 330
      }
    },
    deviceInfo: {
      padding: "1rem 1rem 0",
      display: "flex",
      flexDirection: "column",
      borderRadius: 4,
      minWidth: "428px",
      paddingLeft: 20
    },
    deviceInfoRow: {
      borderBottom: "1px solid rgb(213, 210, 213)",
      padding: "5px 0",
      justifyContent: "space-between",
      '&:last-child': {
        borderBottom: 'none'
      }
    },
    deviceSubTitle: {
      fontFamily: "RobotoMedium",
      fontSize: "0.875rem",
      color: "rgb(84, 89, 100)",
      marginRight: ".5rem",
      whiteSpace: "nowrap"
    },
    deviceSubTitleNotAssigned: {
      fontFamily: "RobotoMedium",
      fontSize: "0.875rem",
      width: "70%",
      display: "flex",
      alignItems: "center",
      color: "rgb(240, 81, 70)",
      "& span": {
        marginLeft: ".5rem"
      }
    },
    serialStyle: {
      fontWeight: "bold",
      color: "#29132e",
      fontFamily: "RobotoMedium",
      fontSize: "0.875rem",
      width: "50%",
      margin: "auto"
    },
    iconButtonStyleDeviceBox: {
      paddingRight: 0,
      padding: 5
    },
    alertIcon: {
      color: "rgb(240, 81, 70)",
      paddingRight: 5
    },
    errorText: {
      color: "rgb(240, 81, 70)"
    },
    cmntTitle: {
      display: "flex",
      alignItems: "center"
    },
    cmntIcons: {
      display: "grid",
      gridAutoFlow: "column",
      gridGap: 8
    },
    cmnetContainer: {
      alignItems: "center",
      justifyContent: "space-between"
    },
    refreshIcon: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    },
    noselect: {
      "-webkit-touch-callout": "none", /* iOS Safari */
      "-webkit-user-select": "none", /* Safari */
      "-khtml-user-select": "none", /* Konqueror HTML */
      "-moz-user-select": "none", /* Old versions of Firefox */
      "-ms-user-select": "none", /* Internet Explorer/Edge */
      "user-select": "none" /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    },
    pointer: {
      cursor: "pointer"
    },
    fastLink: {
      textDecoration: "underline"
    },
    svgIcon: {
      "&span>svg:active": {
        color: "black"
      }
    },
    iconStyle: {
      "&:hover": {
        background: "none"
      },
      "& svg": {
        fontSize: "18px"
      }
    },
    inputRoot: {
      display: "flex",
      width: "70%"
    }
  })
);

const DeviceCard: React.FC<{ device: any; actions: any, isExecutingAddDevice: boolean }> = ({ device, actions, isExecutingAddDevice }) => {
  const refreshDevice = useStoreActions((action) => action.refreshDevice);
  const { dateFormat: userDateFormat } = useStoreState((state) => state.users.me);
  const { dateFormat: dateFormatTypes } = useStoreState((state) => state.types);
  const getDevicesTypesNames = useStoreState((state) => state.getDevicesTypesNames);
  const [isLoading, setIsLoading] = useState(false);

  const {
    numOfBsBoxUnits,
    numOfOutdoorUnits,
    numOfServiceUnits,
    numOfUnassignedBsBoxUnits,
    numOfUnassignedOutdoorUnits,
    numOfUnassignedServiceUnits,
    numOfUnmappedControlUnits,
    numOfDisconnectedServiceUnits,
    numOfDisconnectedOutdoorUnits,
    id,
    isConnected,
    serial,
    registrationDate,
    deviceType = 1,
    type: deviceBrand = 0,
    description,
    permissions,
    protocolVersion = "",
    firmwareVersion = "",
    buildDate = "",
    replacedDevice,
    replaceDate
  } = device;
  const { canUpdate, canDelete, canRefresh } = permissions
  const deviceTypeName = getDevicesTypesNames(deviceType, deviceBrand);
  const classes = useStyles();
  const [deviceDescription, setDeviceDescription] = useState<string>(description);

  const fetchData = async () => {
    setIsLoading(true);

    refreshDevice({ id })
      .then(() => {
        return actions.fetchData();
      })
      .finally(() => setIsLoading(false));

  };

  const DeviceDataTooltip = () => {
    return <>
      <Typography>FW Version: {firmwareVersion}</Typography>
      <Typography>Build Date: {moment(buildDate).format("MMM D YYYY HH:MM:SS")}</Typography>
      <Typography>Prot Version: {protocolVersion}</Typography>
      {(replacedDevice && replaceDate) && <Typography>{`Note: Replaced ${replacedDevice} at ${moment(replaceDate).format("MMM D YYYY")}`}</Typography>}
    </>;
  };
  return (
    <Grid item xs={3} className={classes.deviceInfo}>
      <Grid container direction="column" className={classes.deviceInfoRow}>
        <Grid container wrap={"nowrap"} className={classes.cmnetContainer}>
          <Tooltip title={<DeviceDataTooltip />}>
            <div className={classes.cmntTitle}>
              <Typography
                className={classes.deviceSubTitle}
                style={{
                  color: "#545964 !important"
                }}
              >{(deviceTypeName)}</Typography>
              <Typography className={classes.serialStyle}>{serial}</Typography>
            </div>
          </Tooltip>
          <div className={classes.cmntIcons}>
            {!isConnected ? <SvgDisconnect style={{ fontSize: "1.8rem" }} /> : <></>}
            <Tooltip title={t`Device Line Type Setting`}>
              <IconButton
                disableRipple
                disabled={!isConnected || !canUpdate}
                aria-label="edit icon"
                onClick={() => actions.openEditSubType(id, device)}
                className={classes.refreshIcon}
              >
                <EditIcon className={classes.svgIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t`Retrieve latest setup info from your HVAC systems  (e.g. after setting/editing unit addresses). This allows the application to bring up the latest data of that HVAC system. No changes will be made to your existing setting: you will get an indication if new elements are detected that requires your intervention (e.g. remapping of airnet addresses, new units detected and need mapping, etc.)`}>
              <IconButton
                disableRipple
                disabled={!isConnected || !canRefresh}
                aria-label="refresh icon"
                onClick={fetchData}
                className={classes.refreshIcon}
              >
                <SvgRefresh
                  className={(isLoading || isExecutingAddDevice) ? styles.refreshStyleLoading : styles.refreshStyle}
                />
              </IconButton>
            </Tooltip>
            <IconButton
              disabled={!canDelete}
              className={classes.refreshIcon}
              onClick={() => { actions.setOpenToDeleteItem(true); actions.setToDeleteItem({ type: "device", data: device }); }}
            >
              <Delete style={{ width: 20, height: 20 }} />
            </IconButton>
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={clsx(classes.deviceInfoRow, { [classes.pointer]: numOfDisconnectedOutdoorUnits !== 0 })}
        onClick={() => numOfDisconnectedOutdoorUnits !== 0 ? actions.setOpenDisconnectedDialog(id, "outdoor") : {}}>
        <Typography className={clsx(classes.deviceSubTitle, { [classes.fastLink]: numOfDisconnectedOutdoorUnits !== 0 })}>{t`Outdoor Units:`}</Typography>
        <Typography>
          {numOfOutdoorUnits}
          {numOfDisconnectedOutdoorUnits !== 0 && (
            <span className={classes.errorText}> ({numOfDisconnectedOutdoorUnits} {t`disconnected`})</span>
          )}
        </Typography>
      </Grid>

      <Grid container alignItems="center" className={clsx(classes.deviceInfoRow, { [classes.pointer]: numOfDisconnectedServiceUnits !== 0 })}
        onClick={() => numOfDisconnectedServiceUnits !== 0 ? actions.setOpenDisconnectedDialog(id, "service") : {}}>
        <Typography className={clsx(classes.deviceSubTitle, { [classes.fastLink]: numOfDisconnectedServiceUnits !== 0 })}>{t`Indoor Units:`}</Typography>
        <Typography>
          {numOfServiceUnits}
          {numOfDisconnectedServiceUnits !== 0 && (
            <span className={classes.errorText}> ({numOfDisconnectedServiceUnits} {t`disconnected`})</span>
          )}
        </Typography>
      </Grid>
      {numOfBsBoxUnits > 0 &&
        (
          <Grid container alignItems="center" className={classes.deviceInfoRow}>
            <Typography className={classes.deviceSubTitle}>{t`BS Box Units:`}</Typography>
            <Typography>
              {numOfBsBoxUnits}
              {numOfUnassignedBsBoxUnits !== 0 && (
                <span className={classes.errorText}> ({numOfUnassignedBsBoxUnits} {t`disconnected`})</span>
              )}
            </Typography>
          </Grid>
        )
      }
      {numOfUnmappedControlUnits > 0 &&
        (
          <Grid container alignItems="center" className={clsx(classes.deviceInfoRow, classes.pointer)}
            onClick={() => actions.setOpenUnmappedUnitsDialog(id)}>
            <Typography className={clsx(classes.deviceSubTitle, classes.fastLink)}>{t`Unmapped Control IDs:`}</Typography>
            <Typography>{numOfUnmappedControlUnits}</Typography>
          </Grid>
        )
      }
      <Grid container alignItems="center" className={clsx(classes.deviceInfoRow, classes.noselect)} onClick={(e: any) => !canUpdate ? {} : actions.openDateMenu(id, e, new Date(registrationDate))}>
        <Typography className={clsx(classes.deviceSubTitle)}>{t`Connection Date:`}</Typography>
        <Typography>{registrationDate ? moment(registrationDate).format(dateFormatTypes && userDateFormat ? dateFormatTypes[userDateFormat]?.text : dateFormatTypes[0]?.text) : t`Unknown`}</Typography>
      </Grid>
      <Grid container alignItems="center" className={clsx(classes.deviceInfoRow, classes.noselect)}>
        <Typography className={clsx(classes.deviceSubTitle)}>{t`Device Location:`}</Typography>
        <Input
          disabled={!canUpdate}
          value={deviceDescription}
          onChange={(event: any) => setDeviceDescription(event.target.value)}
          disableUnderline={true}
          classes={{ root: classes.inputRoot }}
          inputProps={{ style: { textAlign: "end" } }}
          endAdornment={
            (deviceDescription !== description) && (
              <IconButton
                onClick={() => actions.saveDeviceLocation(id, deviceDescription)}
                className={classes.iconStyle}
              >
                <Check />
              </IconButton>
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default DeviceCard;
