import coolremoteSDK from "coolremote-sdk";
import { Action, action, actionOn, ActionOn, thunk, Thunk } from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IZone {
  id: string;
  name?: string;
  description?: string;
  site: string;
  units: string[];
  role: any;
  canDelete?: boolean;
}

export interface IZoneMap {
  [key: string]: IZone;
}

export interface IZonesModel {
  allZones: IZoneMap;
  initialize: Action<IZonesModel, any>;
  onInitialized: ActionOn<IZonesModel, IRootStoreModel>;
  getZonesBySiteId: Thunk<IZonesModel, string>;
  getZones: Thunk<IZonesModel>;
  updateZonesState: Action<IZonesModel, any>;
  createZone: Thunk<IZonesModel, { data: any }>;
  addZone: Action<IZonesModel, IZone>;
  deleteZone: Thunk<IZonesModel, string>;
  updateZone: Thunk<IZonesModel, { id: string; data: any }>;
  removeZone: Action<IZonesModel, string>;
  setZones: Action<IZonesModel, any>;
}

export const zonesModel: IZonesModel = {
  allZones: {},

  initialize: action((state, payload) => {
    const newZones: IZoneMap = _(Object.values(payload))
      .map((zone: any) => {
        const newZone: IZone = { ...zone };

        return newZone;
      })
      .keyBy("id")
      .value();
    return { ...state, allZones: newZones };
  }),
  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),
  getZonesBySiteId: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Site.getZones(payload);
    // actions.setSelectedGroups(response);
    actions.initialize(response);
    return response;
  }),
  getZones: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Zone.getZones();
    actions.setZones(response);
    return response;
  }),
  setZones: action((state, payload) => {
    state.allZones = payload;
  }),
  updateZonesState: action((state, payload) => {
    state.allZones[payload.id] = payload;
  }),
  createZone: thunk(async (actions, payload) => {
    const newZone = await coolremoteSDK.Zone.createZone(payload.data);
    actions.addZone(newZone);
    return newZone;
  }),
  addZone: action((state, payload) => {
    state.allZones[payload.id] = payload;
  }),
  deleteZone: thunk(async (actions, payload) => {
    const response = await coolremoteSDK.Zone.delete(payload);
    actions.removeZone(payload);
    return response;
  }),
  updateZone: thunk(async (actions, payload) => {
    const updatedZone = await coolremoteSDK.Zone.update(
      payload.id,
      payload.data
    );
    actions.updateZonesState(updatedZone);
    return updatedZone;
  }),
  removeZone: action((state, payload) => {
    delete state.allZones[payload];
  }),
};
