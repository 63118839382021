import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { Close } from "../../icons";
import useStyles from "./ErrorBox.style";

export default function ErrorBox(props: any) {
  const { acceptTitle, cancelTitle, mobileView } = props;
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    props.onClose ? props.onClose() : props.onAccept();
    setOpen(false);
  };

  const classes = useStyles();
  const missingFields = _.isArray(props.error)
    ? <ul className={classes.content}>{_.map(props.error, (error, index) => <li key={index}>{error}</li>)}</ul>
    : props.error;
  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      classes={{ paper: classes.dialogStyle }}
    >
      <div className={classes.titleContainer}>
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <IconButton disableRipple onClick={handleClose} className={classes.overWriteIcon}>
          <Close color="#7f7692" />
        </IconButton>
      </div>
      <DialogContent className={clsx(classes.view, { [classes.textWidth]: mobileView })}>
        <Typography>{missingFields}</Typography>
      </DialogContent>
      <DialogActions className={clsx({ [classes.removePadding]: mobileView })}>
        {props.onClose && <Button onClick={handleClose} width={"45%"} white>
          {cancelTitle || t`Cancel`}
        </Button>}
        {props.onAccept && <Button onClick={props.onAccept} width={"45%"}>
          {acceptTitle || t`Ok`}
        </Button>}
      </DialogActions>
    </Dialog >
  );
}
