import { Sigma as sigmaSdk} from 'coolremote-sdk';
import { useStoreActions } from '@models/RootStore';


const useSigma = () => {
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  // generate sigma embed URL based on provided filters
  const generateEmbedURL = async (customer, site, reportType) => {
    const payload = {
      customer,
      site,
      reportType
    };

    try {
      const result = await sigmaSdk.generateEmbedUrl(payload);
      return result;
    } catch (error) {
      addMessage({ message: error.message || 'An error occurred' });
    }
  };

  return {
    generateEmbedURL
  };
};

export default useSigma;
