import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    border: 'solid 1px #ebebeb',
    fontSize: '1.25rem',
  },
  unitInfoHeader: {
    display: 'flex',
  },

  attButton: {
    marginLeft: '1.2px',
    marginTop: '8px',
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  unitInfoTitle: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: '#421a40',
    padding: '0.8rem 0.8rem 0',
    textTransform: 'capitalize',
  },
  unitInfoDisconnectedText: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: 'red',
    padding: '0.8rem 0.8rem 0',
  },
  itemsWrapper: {
    display: 'flex',
    marginLeft: '0.4rem',
    overflowX: 'hidden',
  },
  item: {
    borderLeft: '1px solid #ebebeb',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: '1%',
    paddingLeft: '1%',
    marginBottom: '0.7rem',
    marginTop: '0.7rem',
  },
  firstItem: {
    borderLeft: 'none',
  },
  infoTitle: {
    fontSize: '0.8125rem',
    color: '#545964',
    fontFamily: 'RobotoLight',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  info: {
    fontSize: '0.8125rem',
    color: '#29132e',
    fontFamily: 'RobotoMedium',
    width: '100%',
  },
  limited20: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  '@media (min-width: 1270px)': {
    item: {
      padding: '0 auto 0 0.8rem',
    },
  },
  'item:last-child': {
    borderRightWidth: '0px',
    paddingRight: '1rem',
  }
});

export default useStyles;
