import * as React from 'react';

function SvgPdf(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <path id="pdf_svg__a" d="M0 0h11.956v11.671H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#EF3B2F"
          strokeWidth={1.4}
          d="M15.91.7a1.3 1.3 0 01.966.43h0l1.804 2.005 3.086 3.43c.215.238.334.548.334.87h0V20a3.29 3.29 0 01-.967 2.333 3.286 3.286 0 01-2.333.967h0H5.2a3.29 3.29 0 01-2.333-.967A3.286 3.286 0 011.9 20h0V4c0-.911.37-1.736.967-2.333A3.286 3.286 0 015.2.7h0z"
        />
        <g transform="translate(6 6)">
          <mask id="pdf_svg__b" fill="#fff">
            <use xlinkHref="#pdf_svg__a" />
          </mask>
          <path
            fill="#EF3B2F"
            d="M8.159 7.348s2.933-.531 2.933.47c0 1.003-1.816.595-2.933-.47m-2.17.077c-.902.22-1.355.45-1.355.45l.37-.83c.371-.828.75-1.957.75-1.957C6.266 6.04 7.28 7.16 7.28 7.16s-.388.044-1.29.265M4.827 1.446c0-.873.282-1.11.503-1.11.22 0 .467.105.476.863.009.759-.476 2.24-.476 2.24-.168-.176-.503-1.12-.503-1.993M.552 11.12c-.9-.538 1.887-2.196 2.39-2.249 0 0-1.449 2.812-2.39 2.249m11.401-3.184c-.008-.088-.088-1.11-1.904-1.067-1.817.044-2.258.16-2.258.16S6.433 5.652 5.94 4.584c0 0 .6-1.754.574-2.857C6.486.626 6.222-.009 5.375 0c-.847.009-.97.75-.859 1.852.1.99.612 2.151.612 2.151s-.39 1.217-.908 2.428c-.517 1.211-.87 1.846-.87 1.846S1.6 8.862.842 9.567c-.758.706-1.066 1.248-.667 1.79.344.468 1.55.574 2.625-.837 1.076-1.41 1.561-2.293 1.561-2.293s1.642-.45 2.152-.573c.511-.123 1.128-.22 1.128-.22s1.5 1.508 2.945 1.455c1.447-.053 1.376-.864 1.367-.953"
            mask="url(#pdf_svg__b)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgPdf;
