import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  tableContainer: {
    display: "flex",
    height: "100%"
    // paddingRight: '5px'
  },
  filterStyle: { marginLeft: "10px" },
  blueFilter: { fill: "#1976d2" },
  ImportExportContainer: {
    padding: "15px",
    marginRight: "15px"
  },
  table: {
    background: theme.palette.background.paper
  },
  overWritePadding: {
    fontSize: "13px",
    padding: "0 15px",
    height: "35px"
  },
  tableHeadCell: {
    ...theme.tables.headerCell
  },
  editIcon: {
    marginLeft: "-6px"
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial"
  },

  anomaliesContainer: {
    height: "82%",
    display: "flex",
    paddingBottom: "1rem"
  },

  titleBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  smallWidth: { width: "20%", wordBreak: "break-word" },
  mediumWidth: { width: "60%", wordBreak: "break-word" },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  /////
  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    overflow: "hidden",
    height: "100%",
    padding: "1rem",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "row nowrap",
    minHeight: "250px"
  },
  groupsSection: {
    flex: 1,
    paddingRight: 6,
    borderRight: "solid 1px #e1e2ea",
    width: "50%",
    maxWidth: "fit-content"

  },
  unitssSection: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 6
  },
  title: {
    fontFamily: "RobotoMedium",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b1c46",
    marginBottom: 20
  },
  itemsContainer: {
    overflow: "auto",
    paddingRight: 14,
    maxHeight: "calc(100% - 43px)",
    width: 532,
    maxWidth: "100%",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: "transparent",
      borderRadius: 2
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#dfdde1"
    }
  },
  unitsItemsContainer: {
    width: "100%"
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  newbtn: {
    margin: "15px 18px 0 0"
  }
}));
export default useStyles;
