import React from 'react';
import PropTypes from 'prop-types';

const OzoneSensor = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="mask0_1177_1693" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
			<path d="M1.5 1.95837H22.5V22.9584H1.5V1.95837Z" fill="white" />
		</mask>
		<g mask="url(#mask0_1177_1693)">
			<path d="M16.1734 4.71611C16.1734 5.43814 15.5845 6.02344 14.858 6.02344C14.1314 6.02344 13.5424 5.43814 13.5424 4.71611C13.5424 3.99411 14.1314 3.40881 14.858 3.40881C15.5845 3.40881 16.1734 3.99411 16.1734 4.71611Z" stroke="white" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M18.3168 9.23107C18.3158 9.20957 18.3152 9.18788 18.3152 9.16614C18.3152 8.4441 18.9042 7.85881 19.6307 7.85881C20.3573 7.85881 20.9462 8.4441 20.9462 9.16614C20.9462 9.6397 20.6928 10.0545 20.3134 10.2838" stroke="white" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.18116 3.88094C9.18116 4.60298 8.59222 5.18823 7.86566 5.18823C7.13916 5.18823 6.55017 4.60298 6.55017 3.88094C6.55017 3.1589 7.13916 2.57361 7.86566 2.57361C8.59222 2.57361 9.18116 3.1589 9.18116 3.88094Z" stroke="white" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4.74622 9.16606C4.74622 9.8881 4.15728 10.4734 3.43073 10.4734C2.70422 10.4734 2.11523 9.8881 2.11523 9.16606C2.11523 8.44402 2.70422 7.85873 3.43073 7.85873C4.15728 7.85873 4.74622 8.44402 4.74622 9.16606Z" stroke="white" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M21.2381 16.0558C21.6453 15.3603 21.8757 14.5499 21.8676 13.6854C21.8439 11.1701 19.7754 9.11753 17.2444 9.09755C15.9384 9.08726 14.7555 9.61099 13.9032 10.4619C13.8925 8.61357 12.3578 7.12396 10.4845 7.17171C8.70858 7.21703 7.26786 8.665 7.24108 10.4303C7.23045 11.1302 7.43857 11.7811 7.80156 12.3202C7.62146 12.2772 7.43356 12.2542 7.24013 12.2542C5.915 12.2542 4.84076 13.3217 4.84076 14.6386C4.84076 15.0323 4.93735 15.4035 5.10744 15.7307C4.10912 16.2664 3.43073 17.3154 3.43073 18.522C3.43073 20.6236 5.16093 22.3431 7.27565 22.3431H18.1573C20.2074 22.3431 21.8848 20.6762 21.8848 18.6388V18.2566C21.8848 17.4462 21.6474 16.6911 21.2381 16.0558Z" stroke="white" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M14.5645 15.2769C14.5645 16.9888 13.5818 18.3766 12.3696 18.3766C11.1574 18.3766 10.1747 16.9888 10.1747 15.2769C10.1747 13.565 11.1574 12.1772 12.3696 12.1772C13.5818 12.1772 14.5645 13.565 14.5645 15.2769Z" stroke="white" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M15.7155 17.1452C15.7952 16.7419 16.1529 16.4375 16.5822 16.4375C17.0699 16.4375 17.4654 16.8305 17.4654 17.3154C17.4654 17.8001 17.0699 18.1931 16.5822 18.1931" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M16.5823 18.193C17.0701 18.193 17.4656 18.586 17.4656 19.0708C17.4656 19.5556 17.0701 19.9486 16.5823 19.9486C16.1363 19.9486 15.7675 19.6201 15.7075 19.1933C15.702 19.1533 15.699 19.1124 15.699 19.0708" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		</g>
	</svg>
);

OzoneSensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

OzoneSensor.defaultProps = {
	width: 24,
	height: 25
};

export default OzoneSensor;