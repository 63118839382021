const roundTo2Places = (num: number) => {
    return Math.round(num * 100) / 100;
}

export const toC = (tempInF: number) => {
    const temp = (tempInF - 32) * (5 / 9);
    return roundTo2Places(temp);
};

export const toF = (tempInC: number) => {
    const temp = (tempInC * 9 / 5) + 32;
    return roundTo2Places(temp);
};

export const toPSI = (presInKgPCm: number) => {
    const pres = presInKgPCm * 14.2233;
    return roundTo2Places(pres);
};

export const toKgPerCm2 = (presInPSI: number) => {
    const pres = presInPSI * 0.0703070;
    return roundTo2Places(pres);
}

export const paramValueToScale = (value: number, scaleType: number, scale: number, userTempScale: number, userPressureScale: number, types: any) => {
  if (!scaleType || !hasValue(value)) {
    return value;
  }

  const { measurementUnitTypes, temperatureScale, pressureScale } = types;

  if (measurementUnitTypes.temperature === scaleType && userTempScale !== scale) {
    if (temperatureScale[userTempScale] === "celsius") {
      return Math.round(toC(+value));
    }
    return Math.round(toF(+value));
  }

  if (measurementUnitTypes.pressure === scaleType && userPressureScale !== scale) {
    if (pressureScale[userPressureScale] === "PSI") {
      return Math.round(toPSI(+value));
    }
    return Math.round(toKgPerCm2(+value));
  }

  return value;
};

export const hasValue = (value: any) => {
  return !!value || value === 0;
};
