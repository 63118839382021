import React from 'react';

const SvgVer = props => (
	<svg width={40} height={40} {...props}>
		<g fill="none" fillRule="evenodd" opacity={props.opacity}>
			<path
				stroke={props.color2 || '#FFF'}
				strokeLinecap="round"
				strokeOpacity={0.5}
				strokeWidth={2}
				d="M19.599 9.766l13.717 6.07m-17.119-.139l10.606 10.606M10.694 19.15l5.438 13.98"
			/>
			<path fill={props.color1 || '#FDFDFE'} d="M3 3v9a9 9 0 009-9H3z" />
			<path
				stroke={props.color1 || '#FDFDFE'}
				strokeLinecap="round"
				strokeWidth={3}
				d="M4 20v15"
			/>
			<path
				stroke={props.color2 || '#FFF'}
				strokeLinecap="round"
				strokeOpacity={0.5}
				strokeWidth={2}
				d="M20 4h15"
			/>
		</g>
	</svg>
);

export default SvgVer;
