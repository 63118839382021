// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { StylesProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import coolRemoteSDK, {
  Admin as sdkAdmin,
  AlertGroup as sdkAlertGroup,
  Compressor as sdkCompressor,
  Customer as sdkCustomer,
  Device as sdkDevice,
  Ecobee as sdkEcobee,
  Event as sdkEvent,
  File as sdkFile,
  Group as sdkGroup,
  Invite as sdkInvite,
  Notes as sdkNote,
  OperationAutomate as sdkOperationAutomate,
  PowerMeter as sdkPowerMeter,
  Scheduler as sdkScheduler,
  Sensor as sdkSensor,
  Services as sdkService,
  Site as sdkSite,
  System as sdkSystem,
  Unit as sdkUnit,
  User as sdkUser,
  xhr as sdkXhr
} from "coolremote-sdk";
import { createStore, StoreProvider } from "easy-peasy";
import _ from "lodash";
import React from "react";
import { addLocale, useLocale } from "ttag";
import RootStore from "./models/RootStore";
import Routes from "./screens/Routes/Routes";
import { getHostname } from "./services/utils";

const LOCALE_COOKIE = "__locale";

const getLocale = () => {
  const items = document.cookie.split(";");
  const cookies = _(items)
    .mapKeys((item) => _.trim(item.split("=")[0]))
    .mapValues((item) => _.trim(item.split("=")[1]))
    .value();

  return cookies[LOCALE_COOKIE] || "en";
};

const store = createStore(RootStore, {
  injections: {
    coolRemoteSDK,
    sdkUser,
    sdkPowerMeter,
    sdkXhr,
    sdkDevice,
    sdkCustomer,
    sdkSite,
    sdkSensor,
    sdkOperationAutomate,
    sdkInvite,
    sdkGroup,
    sdkUnit,
    sdkScheduler,
    sdkSystem,
    sdkEcobee,
    sdkAdmin,
    sdkAlertGroup,
    sdkService,
    sdkEvent,
    sdkCompressor,
    sdkNote,
    sdkFile
  }
});

export default function App() {
  const locale = getLocale();
  const translationsObj = require(`./locale/${locale}.po.json`);
  addLocale(locale, translationsObj);
  useLocale(locale);

  return (
    <StoreProvider store={store}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Routes />
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </StoreProvider>
  );
}
