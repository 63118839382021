import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useStoreState } from "../../../models/RootStore";
import { datesListStyles } from "./CustomDatesList.style";

const CustomDatesList = (props: any) => {
  const useStyles = makeStyles(datesListStyles);
  const classes = useStyles();
  const { dateFormat } = useStoreState((state: any) => state.users);
  const dayDateFormat = `ddd  ${dateFormat}`;

  const [aggregatedDates, setAggregatedDates] = useState<any>([]);
  const { dates = [], white = true, customClass } = props;

  useEffect(() => {
    if (!dates) {
      return;
    }
    const sortedDates = dates.map((date: any) => new Date(date));
    sortedDates.sort((a: any, b: any) => a - b);
    let lastMin = sortedDates[0];
    let tempDates = [];
    lastMin && tempDates.push([lastMin]);
    let rangeCount = 0;

    setAggregatedDates([...tempDates]);

    for (let i = 0; i < sortedDates.length; i++) {
      if (i < sortedDates.length - 1) {
        const daysDiff = Math.round((sortedDates[i + 1] - sortedDates[i]) / (1000 * 60 * 60 * 24));
        if (daysDiff === 1) {
          tempDates[rangeCount].push(sortedDates[i + 1]);
        } else {
          tempDates.push([sortedDates[i + 1]]);
          rangeCount++;
        }
      }
    }
    setAggregatedDates(tempDates);
  }, [dates]);

  return (
    <div className={classes.container}>
      {aggregatedDates.map((datesRange: any, index: number) => {
        return (
          <div
            className={clsx(classes.dateStyle, { [customClass]: !!customClass })}
            key={index}
          >
            {datesRange?.length === 1 ?
              <span className={clsx({ [classes.textStyle]: white })}>{moment(datesRange[0]).format(dayDateFormat)}</span>
              : <span className={clsx({ [classes.textStyle]: white })}>{moment(datesRange[0]).format(dateFormat)} - {moment(datesRange[datesRange?.length - 1]).format(dateFormat)}</span>}
          </div>
        );
      })}
    </div>
  );
};

export default CustomDatesList;
