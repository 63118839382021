import React from 'react';
import useStyles from './Login.style';

import GenericLogin from '../../components/Login';

import InputField from './InputField';

const Login = () => {

	return (
		<GenericLogin useStyles={useStyles} inputField={InputField} />
	);
};


export default Login;