import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((darkTheme: any) =>
({
  loading: {
    background: "white",
    MozFlexDirection: "column",
    flexDirection: "column",
    height: "100vh",
    display: "flex",
    fontSize: "1.5rem",
    color: "#29abe2"
  },

  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    MozFlex: "1",
    flex: 1,
    backgroundImage: darkTheme.gradiants.themeBackground
  },

  logos: {
    justifyContent: "center",
    display: "flex",
    MozFlexDirection: "column",
    flexDirection: "column",
    alignItems: "center"
  },

  logo: {
    height: "33rem",
    width: "35rem",
    marginBottom: "6rem"
  },

  logoTitle: {
    paddingBottom: "2rem"
  },
  mobileLogo: {
    transform: "scale(0.5)",
    marginBottom: "30px"
  },
  logo1: {
    height: "100px",
    width: "auto",
    marginRight: "20px"
  },
  logoContainer: {
    display: "flex",
    alignItems: "center"
  },
  mobileLogo2: {
    height: "30px"
  },
  mobileLogo1: {
    height: "50px",
    width: "auto"
  }
}));

export default useStyles;
