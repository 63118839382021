import { useStoreActions } from '@models/RootStore';
import { Audit } from 'coolremote-sdk';
import { useEffect, useState } from 'react';

/**
 * Represents an audit log entry.
 * @interface
 */
export interface IAudit {
	/** Unique identifier for the audit entry. */
	id: string;
	/** Timestamp of when the audit event occurred. */
	timestamp: string;
	/** Action performed in the audit event. */
	action: string;
	/** Actor who performed the action. */
	actor: string;
	/** Device associated with the audit event. */
	device?: string;
	/** Unit associated with the audit event. */
	unit: string;
	/** Customer associated with the audit event. */
	customer?: string;
	/** Site associated with the audit event. */
	site?: string;
	/** System associated with the audit event. */
	system?: string;
	/** Group associated with the audit event. */
	group?: string;
	/** Schedule associated with the audit event. */
	schedule?: string;
	/** Trap associated with the audit event. */
	trap?: string;
	/** Changeover associated with the audit event. */
	changeover?: string;
	/** User associated with the audit event. */
	user?: string;
	/** Value associated with the audit event. */
	value?: any;
	/** Additional data associated with the audit event. */
	data?: any;
	/** Source of the audit event. */
	source?: number;
	/** Line number associated with the audit event. */
	line?: number;
	/** Type of source associated with the audit event. */
	sourceType?: string;
	/** Sensor associated with the audit event. */
	sensor?: string;
	/** Additional properties associated with the audit event. */
	[key: string]: any;
}

/**
 * Represents an actor in the system.
 * @interface
 */
export interface IActor {
	/** First name of the actor. */
	firstName: string;
	/** Last name of the actor. */
	lastName: string;
	/** Username of the actor. */
	username: string;
}

/**
 * Represents a map of audits along with metadata.
 * @interface
 */
export interface IAuditsMap {
	/** Array of audit entries. */
	audits: IAudit[];
	/** Total count of audit entries. */
	totalCount: number;
	/** Total number of pages available for pagination. */
	totalPages: number;
	/** The number of the current Page. */
	page: number;
	/** The size of the Page. */
	pageSize: number;
	/** Indicates whether newer audit records exist beyond the current result set. */
	newerRecordsExist: boolean;
}

export interface IAuditsFilters {
	/** Array of unique audit sources. */
	auditSources: number[];
	/** Array of unique audit actions. */
	auditActions: string[];
	/** Array of unique audit actors. */
	auditActors: Record<string, IActor>;
}

/**
 * Represents parameters for querying audit logs.
 * @interface
 */
export interface AuditParams {
	/** ID of the customer for which audits are requested. */
	customerId: string;
	/** Optional ID of the site for which audits are requested. */
	siteId?: string | null;
	/** Optional ID of the system for which audits are requested. */
	systemId?: string | null;
	/** Optional ID of the unit for which audits are requested. */
	unitId?: string | null;
	/** Start date/time in milliseconds for filtering audits. */
	startDate: number;
	/** End date/time in milliseconds for filtering audits. */
	endDate: number;
	/** Optional page number for paginated results. */
	pageNum?: number;
	/** Optional page size for paginated results. */
	pageSize?: number;
	/** Optional array of audit actions to filter results. */
	actionArr?: string[];
	/** Optional array of audit sources to filter results. */
	sourceArr?: string[];
	/** Optional array of audit actors to filter results. */
	actorArr?: string[];
}

/**
 * The return type for the useAudits hook.
 * @interface
 */
interface IUseAuditsReturn {
    /**
     * This function retrieves paginated audit logs based on provided parameters.
     * @param {AuditParams} params - Parameters for querying audit logs.
     * @returns {Promise<IAuditsMap>} A promise that resolves to a map of audit logs.
     */
    getAuditsPaginated: (params: AuditParams) => Promise<IAuditsMap>;

    /**
     * Filters available for audits, such as audit sources, actions, and actors.
     * @type {IAuditsFilters}
     */
    auditsFilters: IAuditsFilters;

    /**
     * Loading state indicating whether an async operation is in progress.
     * @type {boolean}
     */
    loading: boolean;
}

/**
 * Custom hook for fetching audit logs.
 * @returns {Object} An object containing functions for fetching audit logs.
 */
const useAudits = (): IUseAuditsReturn => {
	const { getCustomerAuditsPaginated, getAllAuditsFilters } = Audit;
	const { addMessage } = useStoreActions((action) => action.errorMessage);
    const [loading, setLoading] = useState(false);
    const [auditsFilters, setAuditsFilters] = useState<IAuditsFilters>({
        auditSources: [],
        auditActions: [],
        auditActors: {}
    });

	useEffect(() => {
        fetchAuditsFilters();
    }, []);

	  /**
     * Fetches audit filters and updates the state.
     *
     * @returns A promise that resolves when the fetch and state update are complete.
     */
	  const fetchAuditsFilters = async () => {
        try {
            setLoading(true);
            const filters = await getAllAuditsFilters();
            setAuditsFilters(filters);
        } catch (error) {
            addMessage(error);
        } finally {
            setLoading(false);
        }
    };

	/**
	 * Retrieves paginated audit logs based on provided parameters.
	 * @param {AuditParams} params - Parameters for querying audit logs.
	 * @returns {Promise<IAuditsMap>} A promise that resolves to a map of audit logs.
	 */
	const getAuditsPaginated = async (params: AuditParams) => {
		return getCustomerAuditsPaginated(params);
	};

	return { getAuditsPaginated, loading, auditsFilters };
};

export default useAudits;


