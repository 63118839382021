import React from "react";

function AngleBracket(props) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.17116 5.17168L0.464156 5.87868L6.12116 11.5357L7.53516 10.1217L3.29316 5.87868L7.53516 1.63568L6.12116 0.22168L1.17116 5.17168Z"
        fill="#A58EA3"
      />
    </svg>
  );
}

export default AngleBracket;
