import React, { useEffect, useRef, useState } from "react";

import {
  Dialog,
  IconButton,
  makeStyles,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { FieldArray, Form, Formik } from "formik";
import { isEmpty, filter, reject } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { t } from "ttag";
import * as Yup from "yup";

import {
  IProps,
  IUnitOption,
  ISystemOption,
  EntityOption,
  IMixedStep,
  IFormattedSingleStep,
  IFormattedGroupStep,
  ISingleStep,
  IGroupStep,
  Step,
  UnitType,
  scriptSystemUnitTypes
} from '../../hooks/useScripts/types';
import { useValidationSchema } from '../../hooks/useScripts/validators';
import { useParams } from '../../hooks/useScripts/useParams';
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import { Close } from "../../icons";
import { useStoreState, useStoreActions } from "../../models/RootStore";
import { paramValueToScale } from "../../services/converter";
import ErrorBox from "../WarnningBox/ErrorBox";
import Condition from "./Condition";
import conditionUtils from "./conditionUtils";
import { Box, CustomedTextField, CustomSelect } from "./CustomedComponents";
import GroupedSteps from "./GroupedSteps";

import styles from "./AddEditScript.style";
import useChurnZero from "@hooks/useChurnZero";

const defaultScript = { operator: "=", command: "", type: "", value: "", proceedOnFailure: true, unitType: "", selectedEntities: [], thresholdOperator: "", thresholdParameter: "", valueSource: "", parameter: "" };

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const AddEditScript = (props: IProps) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { siteId, systemUnitsOptions, siteEntitesMap, close, createScript, editScript, updateScript, canEdit = true } = props;

  const types = useStoreState((s) => s.types);
  const temperatureScaleMirror = useStoreState((s) => s.temperatureScaleMirror);
  const user = useStoreState((s) => s.users.me);
  const serviceParamTypes = useStoreState((s) => s.serviceParamTypes);
  const getServiceParams = useStoreActions((action) => action.traps.getServiceParams);
  const { unitTypes, measurementUnitTypes, temperatureScale: tempScales, pressureScale, stepsRunningModesOptions, stepsRunningModes, actionValueSources } = types;

  const ruleTypeOptions: any = Object.values(stepsRunningModesOptions);

  const [ruleName, setRuleName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<any>("all");
  const [ruleType, setRuleType] = useState<number>(1);
  const [conditions, setConditions] = useState<any>([]);
  const [brandSystems, setBrandSystems] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cancelClicked, setCancelClicked] = useState<boolean>(false);
  const [isParallel, setIsParallel] = useState<boolean>(true);
  const [ruleTypeTobeChanged, setRuleTypeTobeChanged] = useState<any>(null);
  const [filteredSystemUnits, setFilteredSystemUnits] = useState<any>({ [UnitType.Service]: [], [UnitType.Outdoor]: [], [UnitType.Mixed]: [] });
  const [selectedSteps, setSelectedSteps] = useState<number[]>([]);


  const { getParams, params, indoorParams, paramsMap } = useParams();

  const {trackEvent} = useChurnZero()

  const descriptionRef: any = useRef(null);
  const { temperatureScale: userTempScale = 1, measurementUnits: userPressureScale = 2 } = user;
  const { hvacBrands = [], procedureDeviceCommands, procedureStepTypes, procedureConditions, procedureRunningModes } = types;

  const allbrandsOption = [{ value: "all", name: "All" }, ...hvacBrands];

  useEffect(() => {
    conditionUtils.generateId.reset();
    filterSystemUnitsByBrand(selectedBrand);
  }, []);

  const { schema } = useValidationSchema({ruleType});


  const filterSystemUnitsByBrand = async (brand: any) => {
    let filteredOptions: Record<UnitType, EntityOption[]> = Object.values(scriptSystemUnitTypes).reduce((acc: Record<UnitType, EntityOption[]>, unitType) => {
      acc[unitType as UnitType] = [];
      return acc;
    }, {} as Record<UnitType, EntityOption[]>);

    (Object.keys(filteredOptions) as unknown as UnitType[]).forEach((unitType) => {

      systemUnitsOptions[unitType].forEach((system: any) => {
        const systemOption: ISystemOption = { id: system.id, name: system.name, entityType: system.entityType, isDisabled: false };

        // fake system is just a placeholder for units not assgined to any system
        // if all units belong to a system then don't add that fake system option
        if (system.id === "fake-system") {
          const units = brand === 'all' ? system.units : system.units.filter((unit: any) => unit.brand === brand);
          if (units.length > 0) {
            filteredOptions[unitType].push(systemOption);
            filteredOptions[unitType] = filteredOptions[unitType].concat(units);
          }
          return;
        }

        // systems with no units will still be displayed as option but disabled
        if (brand === 'all' || system.brand === brand) {
          if (!system.units?.length) {
            systemOption.isDisabled = true;
          }
          filteredOptions[unitType].push(systemOption);
          filteredOptions[unitType] = filteredOptions[unitType].concat(system.units);
        }
      });
    });

    setFilteredSystemUnits(filteredOptions);
  };

  const addCondition = (conditions: any, ruleName: string, brand: any, ruleType: any) => {
    const id = conditionUtils.generateId();
    setConditions([...conditions, { id, ...defaultScript }]);
    setRuleName(ruleName);
    setSelectedBrand(brand);
    setRuleType(ruleType)

    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const deleteCondition = (conditions: any, index: number, ruleName: string, brand: any, ruleType: any) => {
    setConditions(conditions.filter((condition: any) => condition.id !== index));
    setRuleName(ruleName);
    setSelectedBrand(brand);
    setRuleType(ruleType)
  };

  const scaleMatch: any = {
    "°C": {
      scaleType: measurementUnitTypes["temperature"],
      scale: userTempScale
    },
    "kg/cm2": {
      scaleType: measurementUnitTypes["pressure"],
      scale: userPressureScale
    },
    MPa: {
      scaleType: measurementUnitTypes["pressure"],
      scale: userPressureScale
    },
    PSI: {
      scaleType: measurementUnitTypes["pressure"],
      scale: userPressureScale
    }
  };

  /**
   * Parses selected entity options and groups their IDs into corresponding arrays based on the entity type.
   *
   * @param {EntityOption[]} [selectedOptions=[]] - The list of selected entity options.
   */

  const parseSelectedEntitiesOptions = (selectedOptions: EntityOption[] = []) => {
    const units: string[] = [];
    const systems: string[] = [];

    for (const option of selectedOptions) {
      if (option.entityType === "system") {
        systems.push(option.id);
      } else if (option?.entityType === "unit") {
        units.push(option.id);
      }
    }

    return { units, systems };
  }

/**
 * Prepares step data to match the expected format for the backend.
 *
 * @param {IMixedStep} step - The step data to format.
 * @param {number} ruleType - The type of rule.
 * @param {boolean} [isGroupedStep=false] - Indicates if the step is grouped.

  - adjusting fields based on the step type.
  - setting scale and scaleType for value scale conversions.
  - converting certain fields to the expected format.
 */

  const formatStep = (step: IMixedStep, ruleType: number, isGroupedStep: boolean = false) => {
    const { type, value = "", operator = "=" } = step;

    const parsedStep: IFormattedSingleStep = { // all steps have type/value/operator fields
      type,
      value,
      operator
    };

    if (step.type === procedureStepTypes.wait) { // convert time value to milliseconds
      parsedStep.value = +(value as string) * 60000;
      return parsedStep;
    }

    parsedStep.value = step.value;
    parsedStep.operator = step.operator;
    if (ruleType === stepsRunningModes.specificUnits && !isGroupedStep) {
      const { units, systems } = parseSelectedEntitiesOptions(step.selectedEntities);
      parsedStep.units = units;
      parsedStep.systems = systems;
    }

    if (step.type === procedureStepTypes.condition) {
      parsedStep.proceedOnFailure = step.proceedOnFailure;
      parsedStep.condition = step.condition;
      parsedStep.unitType = step.unitType;

      const paramMeasureUnit = paramsMap[step.condition || ""]?.data_unit_of_measurement;
      if (paramMeasureUnit) {
        parsedStep.scale = scaleMatch[paramMeasureUnit]?.scale;
        parsedStep.scaleType = scaleMatch[paramMeasureUnit]?.scaleType;
      }

      if (step.operator === 'threshold') {
        parsedStep.thresholdParameter = step.thresholdParameter;
        parsedStep.thresholdOperator = step.thresholdOperator;
      }

    } else {
      parsedStep.command = step.command;
      parsedStep.valueSource = step.valueSource;
      parsedStep.parameter = step.parameter;

      if (step.command === procedureDeviceCommands.setUnitSetpoint) {
        parsedStep.scale = userTempScale;
        parsedStep.scaleType = measurementUnitTypes.temperature;
      }
    }

    return parsedStep;
  }

  const onSubmit = (values: any) => {

    trackEvent('ProceduresApplyRule','The user created a procedure');

    setErrorMessage("");
    const { conditions, selectedBrand, ruleName, ruleType } = values;
    if (isEmpty(conditions)) {
      setErrorMessage("one step at least is required");
      return;
    }

    if (ruleType === stepsRunningModes.specificUnits) {
      const isEntitiesMissing = conditions.some((step: IMixedStep) => {
        if (step.type === procedureStepTypes.wait || (step.type === procedureStepTypes.condition && step.condition === "procedureStatus")) {
          return false;
        }

        return !step.selectedEntities?.length;
      });

      if (isEntitiesMissing) {
        setErrorMessage(t`Please fill in the "Specific unit/s" field to proceed. This field is required to run the rule.`);
        return;
      }
    }

    const steps: any = conditions.map((step: IMixedStep) => {
      if (!step.isGroup) {
        return formatStep(step, ruleType);
      }

      const steps = (step.steps || []).map((step: ISingleStep) => formatStep(step, ruleType, true));
      const groupedStep: IFormattedGroupStep = { steps };
      if (ruleType === stepsRunningModes.specificUnits) {
        const { units, systems } = parseSelectedEntitiesOptions(step.selectedEntities);
        groupedStep.units = units;
        groupedStep.systems = systems;
      }

      return groupedStep;
    })

    // const mode = isParallel ? procedureRunningModes["parallel"] : procedureRunningModes["serial"];
    const mode = procedureRunningModes["parallel"];
    const scriptBody: any = {
      name: ruleName,
      description: descriptionRef?.current?.value || "",
      userSelections: {
        brand: selectedBrand === "all" ? "" : selectedBrand
      },
      steps,
      runningMode: mode,
      stepsRunningMode: ruleType
    };

    if (editScript) {
      updateScript(scriptBody, editScript.id)
        .finally(() => close());
    }
    else {
      createScript({ ...scriptBody, type: 1 }, siteId)
        .finally(() => close());
    }
  };

  useEffect(() => {
    if (!editScript) {
      return;
    }
    const { steps = [], name, description, userSelections = {}, runningMode = procedureRunningModes["parallel"], stepsRunningMode } = editScript;
    const brand = userSelections.brand || "all";

    const conditions: any = steps.map((step: any) => {
      if (!step.steps?.length) {
        return parseSingleStep(step, brand, stepsRunningMode);
      }

      const id: number = conditionUtils.generateId();
      const selectedEntities = collectSelectedEntities(step.units, step.systems, brand, stepsRunningMode);
      const groupedStep: IGroupStep = {
        id,
        selectedEntities,
        isGroup: true,
        steps: []
      };

      groupedStep.steps = step.steps.map((step: any) => parseSingleStep(step, brand, stepsRunningMode));
      groupedStep.unitType = getGroupedStepsUnitType(groupedStep.steps)
      return groupedStep;
    });

    setRuleName(name);
    setRuleType(stepsRunningMode);
    setSelectedBrand(brand);
    setDescription(description);
    // setIsParallel(runningMode === procedureRunningModes["parallel"] ? true : false);
    setIsParallel(true);
    getParams(brand, siteId).then(() => setConditions(conditions));
    filterSystemUnitsByBrand(brand);
  }, [editScript]);

/**
 * This function parses a step format received from the backend to make it usable in the frontend
 * by adding necessary modifications and collecting selected entities.
 *
 * @param {IMixedStep} step - Single script step to parse.
 * @param {number | string} brand - The brand associated with the script.
 * @param {number} stepsRunningMode - The execution flow type of script steps.
 */

  const parseSingleStep = (step: IMixedStep, brand: number | string, stepsRunningMode: number) => {
    const id = conditionUtils.generateId();
    const currentStep = { id, ...step };
    const { scale, scaleType, value = "", unitType = scriptSystemUnitTypes.service, valueSource = actionValueSources.constant } = currentStep;

    if (currentStep.type === procedureStepTypes.command) {
      currentStep.valueSource = valueSource;
    }

    if (currentStep.type === procedureStepTypes.condition) {
      currentStep.unitType = unitType;
    }

    if (currentStep.type === procedureStepTypes.wait) {
      currentStep.value = +value / 60000;
    } else if (scaleType && scale) {
      currentStep.value = paramValueToScale(+value, scaleType, scale, userTempScale, userPressureScale, types);
    }

    currentStep.selectedEntities = collectSelectedEntities(step.units, step.systems, brand, stepsRunningMode);

    return currentStep;
  }

/**
 * Builds selected entity options from unit and system IDs on script edit,
 * filtering out units that no longer exist or do not belong to the current selected brand.
 *
 * @param {string[] | undefined} units - An array of unit IDs.
 * @param {string[] | undefined} systems - An array of system IDs.
 * @param {number | string} brand - The brand associated with the script.
 * @param {number} stepsRunningMode - The execution flow type of script steps.
 */

  const collectSelectedEntities = (units: string[] | undefined, systems: string[] | undefined, brand: number | string, stepsRunningMode: number) => {
    if (stepsRunningMode !== stepsRunningModes.specificUnits) {
      return [];
    }

    let selectedEntities: EntityOption[] = [];
    if (units) {
      for (const unitId of units) {
        const unit: IUnitOption = siteEntitesMap[unitId] as IUnitOption;
        if (!unit) {
          continue;
        }

        const system = siteEntitesMap[unit.system || ""]

        if (brand === "all" || (system?.brand === brand) || (!system && unit.brand === brand)) {
          selectedEntities.push(unit);
        }
      }
    }

    if (systems) {
      for (const systemId of systems) {
        const system = siteEntitesMap[systemId];
        if (!system) {
          continue;
        }

        if (brand === "all" || (system?.brand === brand)) {
          selectedEntities.push(system);
        }
      }
    }

    return selectedEntities;
  }

  const onDragEnd = (result: any) => (steps: any, ruleName: string) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrder: any = reorder(
      steps,
      result.source.index,
      result.destination.index
    );

    setConditions(newOrder);
    setRuleName(ruleName);
  };

  const handleModeChange = () => {
    setIsParallel(!isParallel);
  };

  const handleBrandChange = async(brand: number, setFieldValue: any, steps: any) => {
    const prevParamsMap = paramsMap;
    const currentParamsMap = await getParams(brand, siteId);
    filterSystemUnitsByBrand(brand);

    for (const index in steps) {
      const step = steps[index];

      if (step.steps?.length) {
        for (const groupedStep of step.steps) {
          step.selectedEntities = [];
          step.id = conditionUtils.generateId();

          handleStepReplacedParameters(groupedStep, prevParamsMap, currentParamsMap);
        }
      } else {
        handleStepReplacedParameters(step, prevParamsMap, currentParamsMap);
      }
    }

    setFieldValue('selectedBrand', brand);
    setFieldValue('conditions', steps);
  };


  const handleStepReplacedParameters = (step: any, prevParamsMap: any, currentParamsMap: any) => {
    step.selectedEntities = [];
    step.id = conditionUtils.generateId();

    if (step.type === procedureStepTypes.condition) {
      if(step.condition) {
        const param = prevParamsMap[step.condition];
        if (!currentParamsMap[step.condition]) {
          step.replacedCondition = param;
          step.condition = "";
        }

      }
      if(step.thresholdParameter) {
        const param = prevParamsMap[step.thresholdParameter];
        if (!currentParamsMap[step.thresholdParameter]) {
          step.replacedThresholdParameter = param;
          step.thresholdParameter = "";
        }
      }
      return;
    }

    if (step.type === procedureStepTypes.action && step.valueSource === actionValueSources.parameter) {
      if(step.parameter) {
        const param = prevParamsMap[step.parameter];
        if (!currentParamsMap[step.parameter]) {
          step.replacedParameter = param;
          step.parameter = "";
        }
      }
    }
  }

  const handleRuleTypeChange = (newRuleType: string, setFieldValue: any, steps: any, setRuleType:any) => {
    for (const step of steps) {
      step.selectedEntities = [];
      if (step.steps?.length) {
        for (const groupedStep of step.steps) {
          step.selectedEntities = [];
        }
      }
    }

    setFieldValue("ruleType", newRuleType);
    setRuleType(newRuleType)
    setFieldValue("conditions", steps)
  };

  /**
   * Handles the selection of a step.
   *
   * @param {number} stepId - The ID of the step.
   * @param {boolean} status - The status indicating whether the step is selected or deselected.
   * @returns {void}
   */

  const onSelect = (stepId: number, status: boolean) => {
    if (status) {
      selectedSteps.push(stepId);
      setSelectedSteps([...selectedSteps]);
      return;
    }

    setSelectedSteps(selectedSteps.filter((id: number) => id !== stepId));
  };

/**
 * Adds a group step to the list of steps, combining selected steps into a group.
 *
 * @param {any[]} steps - An array of steps.
 * @param {string} ruleName - The name of the rule.
 * @param {number | string} brand - The brand associated with the script.
 * @param {number} ruleType - The type of the rule.
 */

  const addGroup = (steps: any[], ruleName: string, brand: number | string, ruleType: number) => {
    const id = conditionUtils.generateId();
    const group: IGroupStep = { id, isGroup: true, selectedEntities: [], steps: [] };

    let groupIndex: number | null = null; // the index at which to insert the new group
    const restSteps: Step[] = [];
    let selectedEntities: EntityOption[] = [];

    for (const index in steps) {
      const step: any = steps[index];
      if (selectedSteps.includes(step.id)) {
        if (groupIndex === null) {
          groupIndex = +index;
        }

        if (step.selectedEntities?.length) {
          selectedEntities = selectedEntities.concat(step.selectedEntities);
        }

        group.steps.push({ ...step, selectedEntities: [] });
      } else {
        restSteps.push(step);
      }
    }

    group.unitType = getGroupedStepsUnitType(group.steps);

    // to avoid duplicate
    // 1- filter out units/systems that appears more than once
    // 2- filter out selected units if their system already selected
    if (selectedEntities.length) {
      const selectedIds: { [id: string]: boolean } = {};
      const uniqueEntities: EntityOption[] = [];

      selectedEntities.forEach((option: EntityOption) => {
        if (!selectedIds[option.id]) {
          selectedIds[option.id] = true;
          uniqueEntities.push(option);
        }
      });

      selectedEntities = uniqueEntities.filter((option: EntityOption) => !selectedIds[(option as IUnitOption).system || ""]);
      group.selectedEntities = selectedEntities;
    }

    if (groupIndex !== null) {
      restSteps.splice(groupIndex, 0, group);
    }

    setConditions(restSteps)
    setSelectedSteps([]);

    setRuleName(ruleName);
    setSelectedBrand(brand);
    setRuleType(ruleType)
  };

/**
 * Ungroups a group step from the list of steps, restoring the grouped steps individually.
 *
 * @param {number} index - The index of the group step to ungroup.
 * @param {Step[]} steps - An array of steps.
 * @param {string} ruleName - The name of the rule.
 * @param {number | string} brand - The brand associated with the script.
 * @param {number} ruleType - The type of the rule.
 */

  const unGroup = (index: number, steps: Step[], ruleName: string, brand: number | string, ruleType: number) => {
    const newSteps: Step[] = [];
    if (!steps[index] || !(steps[index] as IGroupStep).steps) {
      return;
    }

    const selectedEntities: EntityOption[] = steps[index].selectedEntities;
    const stepsToUngroup = (steps[index] as IGroupStep).steps;

    if (!stepsToUngroup || !stepsToUngroup.length) {
      return;
    }

    for (let i = 0; i < index; i++) {
      newSteps.push(steps[i]);
    }

    const selectedOutdoors: EntityOption[] = filter(selectedEntities as EntityOption[], { type: unitTypes.outdoor });
    const otherSelectedEntities: EntityOption[] = reject(selectedEntities, { type: unitTypes.outdoor });

    for (const step of stepsToUngroup) {
      if (step.type === procedureStepTypes.wait) {
        step.selectedEntities = [];
      } else if (step.unitType === scriptSystemUnitTypes.outdoor) {
        step.selectedEntities = selectedOutdoors;
      } else {
        step.selectedEntities = otherSelectedEntities
      }

      newSteps.push({
        ...step
      });
    }

    for (let i = index + 1; i < steps.length; i++) {
      newSteps.push(steps[i]);
    }

    setConditions(newSteps);
    setRuleName(ruleName);
    setSelectedBrand(brand);
    setRuleType(ruleType)
  };

/**
 * Deletes a step from a group within the list of steps.
 *
 * @param {Step[]} steps - An array of steps.
 * @param {number} groupIndex - The index of the group containing the step to delete.
 * @param {number} stepIndex - The index of the step to delete within the group.
 * @param {string} ruleName - The name of the rule.
 * @param {number | string} brand - The brand associated with the script.
 * @param {number} ruleType - The type of the rule.
 */

  const deleteGroupStep = (steps: Step[], groupIndex: number, stepIndex: number, ruleName: string, brand: number | string, ruleType: number) => {
    let groupedStep: IGroupStep = steps[groupIndex] as IGroupStep;
    if (!groupedStep?.steps?.length) {
      return;
    }

    const selectedOutdoors = filter(groupedStep.selectedEntities, { type: UnitType.Outdoor });
    const otherSelectedEntities = reject(groupedStep.selectedEntities, { type: UnitType.Outdoor });

    if (groupedStep.steps.length === 2) {
      const otherStep: any = stepIndex === 0 ? groupedStep.steps[1] : groupedStep.steps[0];
      otherStep.selectedEntities = otherStep.unitType === UnitType.Outdoor ? selectedOutdoors : otherSelectedEntities;
      steps[groupIndex] = otherStep;
    } else {
      const filteredSteps: any = groupedStep.steps.filter((step: any, index: number) => stepIndex !== index);
      groupedStep.steps = filteredSteps;
      groupedStep.unitType = getGroupedStepsUnitType(filteredSteps);

      if (groupedStep.unitType === UnitType.Service) {
        groupedStep.selectedEntities = otherSelectedEntities;
      } else if (groupedStep.unitType === UnitType.Outdoor) {
        groupedStep.selectedEntities = [...selectedOutdoors];
      }
    }

    groupedStep.id = conditionUtils.generateId(); // to force group render

    setConditions([...steps]);
    setRuleName(ruleName);
    setSelectedBrand(brand);
    setRuleType(ruleType)
  };

  const getGroupedStepsUnitType = (steps: ISingleStep[]) => {
    let groupedUnitType;
    for (const step of steps) {
      const { type, unitType = scriptSystemUnitTypes.service, condition } = step;

      if (type === procedureStepTypes.wait || condition === 'procedureStatus')
        continue;

      if (!groupedUnitType)
        groupedUnitType = unitType;
      else if (unitType !== groupedUnitType)
        groupedUnitType = scriptSystemUnitTypes.mixed;
    }

    return groupedUnitType || scriptSystemUnitTypes.service;
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={true}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{editScript ? t`Edit procedure script` : t`Add new procedure script`}</Typography>
        <IconButton disableRipple className={classes.iconBtnStyle} onClick={close}><Close color="#7f7692" /></IconButton>
      </div>

      <div id="dialogContent" className={classes.dialogContent}>
        <div style={{ position: "relative" }}>
          <div id="basic-info" className={clsx(classes.basicInfoContainer)}>
            <div className={clsx(classes.mainDataContainer, classes.topMargin)}>
            </div>
            <TextareaAutosize
              disabled={!canEdit}
              className={classes.textArea}
              aria-label="minimum height"
              minRows={4}
              maxRows={4}
              placeholder={t`Insert Script Description`}
              defaultValue={description}
              ref={descriptionRef}
            />
            <div className={classes.switchContainer} style={{ display: "none" }}>
              sequential{<CoolSwitch disabled={!canEdit} checked={isParallel} onChange={handleModeChange} name="ParellelSwitch" />}parallel
            </div>
          </div>
          <Formik
            initialValues={{ ruleName, selectedBrand, ruleType, conditions }}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={schema}
            render={({ values, setFieldValue, errors, touched
            }) => {
              return (
                // @ts-ignore
                <Form translate="yes">
                  <div className={classes.mainDataInPlace}>
                    <CustomedTextField
                      disabled={!canEdit}
                      value={values.ruleName}
                      name="ruleName"
                      onChange={(event: any) => setFieldValue("ruleName", event.target.value)}
                      label={t`Script Name`}
                      error={errors.ruleName && touched.ruleName}
                    />
                    <div className={classes.rowContainer}>
                      <CustomSelect
                        disabled={!canEdit}
                        className={classes.mainSelect}
                        error={errors.selectedBrand && touched.selectedBrand}
                        placeholder="Select Brand"
                        name="selectedBrand"
                        value={values.selectedBrand}
                        options={allbrandsOption}
                        onChange={(event: any) => handleBrandChange(event.target.value, setFieldValue, values.conditions)}
                      />
                      <CustomSelect
                        disabled={!canEdit}
                        className={clsx(classes.mainSelect, classes.noMargin, classes.ruleTypeSelect)}
                        error={errors.ruleType && touched.ruleType}
                        placeholder={t`Execution Flow Type`}
                        name="ruleType"
                        value={values.ruleType}
                        label="label"
                        optionValue="value"
                        options={ruleTypeOptions}
                        onChange={(event: any) => handleRuleTypeChange(event.target.value, setFieldValue, values.conditions,setRuleType)}
                      />
                    </div>
                  </div>
                  <Box
                    disabled={!canEdit}
                    title={t`Steps`}
                    bLabel1={t`Add Step`}
                    action1={() => addCondition(values.conditions, values.ruleName, values.selectedBrand, values.ruleType)}
                    bLabel2={t`Group`}
                    bDisabled2={selectedSteps.length < 2}
                    action2={() => addGroup(values.conditions, values.ruleName, values.selectedBrand, values.ruleType)}
                  >
                    <DragDropContext onDragEnd={(result: any) => onDragEnd(result)(values.conditions, values.ruleName)} >
                      <Droppable droppableId="droppable" type="steps">
                        {(provided: any) => (
                          <div ref={provided.innerRef} >
                            <FieldArray
                              name="conditions"
                              render={() => (values.conditions.map((condition: any, index: number) => {
                                if (condition.isGroup) {
                                  return <Draggable
                                    key={`step-${condition.id}`}
                                    draggableId={`step-${condition.id}`}
                                    index={index}
                                  >
                                    {(provided: any) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps} >
                                        <div key={index}>
                                          <GroupedSteps
                                            disabled={!canEdit}
                                            errors={errors}
                                            touched={touched}
                                            key={condition.id}
                                            index={index}
                                            group={condition}
                                            setFieldValue={setFieldValue}
                                            deleteCondition={(conditionIndex: number) => deleteGroupStep(values.conditions, index, conditionIndex, values.ruleName, values.selectedBrand, values.ruleType)}
                                            name={"conditions"}
                                            stepTypes={procedureStepTypes}
                                            commandsTypes={procedureDeviceCommands}
                                            moveProps={provided.dragHandleProps}
                                            params={params}
                                            indoorParams={indoorParams}
                                            paramsMap={paramsMap}
                                            serviceParamTypes={serviceParamTypes}
                                            systemUnitsOptions={filteredSystemUnits}
                                            entitiesMap={siteEntitesMap}
                                            specificUnitsEnabled={values.ruleType === stepsRunningModes.specificUnits}
                                            unGroup={() => unGroup(index, values.conditions, values.ruleName, values.selectedBrand, values.ruleType)}
                                            getGroupedStepsUnitType={getGroupedStepsUnitType}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                }

                                return <Draggable
                                  key={`step-${condition.id}`}
                                  draggableId={`step-${condition.id}`}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} >
                                      <div key={index}>
                                        <Condition
                                          disabled={!canEdit}
                                          errors={errors}
                                          touched={touched}
                                          key={condition.id}
                                          index={index}
                                          condition={condition}
                                          setFieldValue={setFieldValue}
                                          deleteCondition={() => deleteCondition(values.conditions, condition.id, values.ruleName, values.selectedBrand, values.ruleType)}
                                          name={"conditions"}
                                          stepTypes={procedureStepTypes}
                                          commandsTypes={procedureDeviceCommands}
                                          moveProps={provided.dragHandleProps}
                                          params={params}
                                          indoorParams={indoorParams}
                                          paramsMap={paramsMap}
                                          serviceParamTypes={serviceParamTypes}
                                          systemUnitsOptions={filteredSystemUnits}
                                          entitiesMap={siteEntitesMap}
                                          specificUnitsEnabled={values.ruleType === stepsRunningModes.specificUnits}
                                          onSelect={onSelect}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>

                              }
                              ))}

                            />
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                  <div className={classes.actionsContainer}>
                    {!!errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
                    <Button disabled={!canEdit} onClick={() => setCancelClicked(true)} onMouseDown={(event: any) => event.preventDefault()} marginRight white width={150}> {t`cancel`}</Button>
                    <Button disabled={!canEdit} type="submit" onMouseDown={(event: any) => event.preventDefault()} width={150}> {t`save`}</Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>
      {cancelClicked && <ErrorBox error={"Do you want to discard changes?"} onAccept={close} onClose={() => setCancelClicked(false)} />}
    </Dialog>
  );
};

export default AddEditScript
