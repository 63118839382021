import {
  Button as MuiButton,
  Grid,
  InputLabel,
  Popover,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { t } from "ttag";
import { Checkbox } from "../../components/Checkbox";
import Button from "../../cool_widgets/Button";
import DaysList from "../../cool_widgets/DaysList/DaysList";
import CoolSwitch from "../../cool_widgets/CoolSwitch/Switch";
import {
  AutoMode as SvgAutoMode,
  CoolMode as SvgCoolMode,
  HotMode as SvgHeatMode
} from "../../icons/";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import {
  minsToTime,
  stringTimeToUTCMins
} from "../../services/timeService";
import { ArrowDownControl, ArrowUp } from "../../svgComponents";
import useStyles from "./SystemSchedule.style";
import TimePicker from "./TimePicker";

const AddEditSchedule: React.FC<any> = (props: any) => {
  const classes: any = useStyles();
  const { editMode, setOpenAddEdit, systemId, setSystemSchedules, systemSchedules, scheduleData } = props;
  const { name, scheduleCategory, dates: selectedDates = [], id, days: scheduleDays, isDisabled, powerOnTime: schedulePowerOnTime, operationMode, setpoint: scheduleSetpoint } = scheduleData;

  const types = useStoreState((state) => state.types);
  const { operationModesMirror } = useStoreState((state) => state);
  const user = useStoreState((state) => state.users.me);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const { createSystemSchedule } = useStoreActions((action) => action.systems);
  const updateSchedule = useStoreActions((actions) => actions.schedules.updateSchedule);
  const timeFormat = useStoreState((state) => state.users.timeFormat);
  const temperatureSymbol = useStoreState((state) => state.users.getTemperatureScaleDisplay);

  const { timeFormat: myTimeFormat, temperatureScale } = user;
  const { weekDays = [], timeFormat: timeFormatTypes, scheduleCategories } = types;

  const weekDaysArray = Object.keys(weekDays);
  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours";

  const [scheduleDisabled, setScheduleStatus] = useState<boolean>(isDisabled || false);
  const [powerOnTime, setPowerOnTime] = useState<string>(editMode ? minsToTime(schedulePowerOnTime, timeFormat) : "");
  const [days, setDays] = useState<[]>(scheduleDays || []);
  const [scheduleMode, setScheduleMode] = useState<string>(editMode ? String(operationMode) : operationModesMirror.COOL);
  const [openPicker, setOpenPicker] = useState<string>("");
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const [errorMsg, setErrorMsg] = useState<any>({ name: "", dates: "", days: "", time: "" });
  const [setpoint, setSetpoint] = useState<number>(editMode && scheduleSetpoint ? scheduleSetpoint : temperatureScale === 2 ? 75 : 24);
  const [setpointEnabled, enableSetpoint] = useState<boolean>(editMode && scheduleSetpoint ? true : false);
  const [disableAdding, setDisableAdding] = useState<boolean>(false);
  const [scheduleName, setScheduleName] = useState<string>(editMode ? name : "");
  const [tab, setTab] = useState<number>(editMode ? scheduleCategory : scheduleCategories.weekly);
  const today = new Date();
  const [dates, setDates] = useState<any>(editMode ? selectedDates.map((date: any) => new Date(date)) : []);

  const aYearFromToday = new Date();
  aYearFromToday.setFullYear(today.getFullYear() + 1);

  const isScheduleInHeatMode = scheduleMode === operationModesMirror.HEAT;
  const isScheduleInCoolMode = scheduleMode === operationModesMirror.COOL;
  const isScheduleInAutoMode = scheduleMode === operationModesMirror.AUTO;

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...[], ...days];

    currentDays.includes(selectedDay)
      ? (currentDays = days.filter((day) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setDays(currentDays);
  };

  const onClear = () => {
    setOpenPicker("");
    setPowerOnTime("");
  };

  const onSetTime = (time: string) => {
    setPowerOnTime(time);
    setOpenPicker("");
  };

  const checkRequiredFields = () => {
    setErrorMsg({ name: "", dates: "", time: "", days: "" });

    if (!scheduleName) {
      setErrorMsg({ name: t`Schedule Name is Required`, dates: "", time: "", days: "" });
      return false;
    }
    if (scheduleName.length < 3 || scheduleName.length > 25) {
      setErrorMsg({ name: t`Schedule Name should be from 3 to 25 chars`, dates: "", time: "", days: "" });
      return false;
    }

    if (tab === scheduleCategories.calendar && dates.length === 0) {
      setErrorMsg({ name: "", dates: t`Pick one date at least`, time: "", days: "" });
      return false;
    }

    if (tab === scheduleCategories.weekly && days.length === 0) {
      setErrorMsg({ name: "", dates: "", time: "", days: t`Pick one day at least` });
      return false;
    }

    if (!powerOnTime) {
      setErrorMsg({ name: "", dates: "", time: t`Add start time`, days: "" });
      return false;
    }
    return true;
  };

  const handleSetpoint = () => {
    enableSetpoint(!setpointEnabled);
  };

  const decreaseSetpoint = () => {
    if (setpoint < 1) {
      return;
    }
    const decreasedSetpoint = setpoint - 1;
    setSetpoint(Math.ceil(decreasedSetpoint * 10) / 10);
  };

  const increaseSetpoint = () => {
    const increasedSetpoint = setpoint + 1;
    setSetpoint(Math.ceil(increasedSetpoint * 10) / 10);
  };

  const save = () => {
    const startHour = powerOnTime ? stringTimeToUTCMins(powerOnTime, is12Hours) : undefined;
    const allRequiredNotEmpty = checkRequiredFields();
    if (!allRequiredNotEmpty) {
      return;
    }
    setDisableAdding(true);
    const data: any = {
      isDisabled: scheduleDisabled,
      name: scheduleName,
      powerOnTime: startHour,
      operationMode: Number(scheduleMode),
      scheduleCategory: tab
    };
    if (setpointEnabled) {
      data.setpoint = setpoint;
    } else {
      data.setpoint = null;
    }
    if (tab === scheduleCategories.calendar) {
      data.dates = dates;
    }
    if (tab === scheduleCategories.weekly) {
      data.days = days;
    }

    if (editMode) {
      updateSchedule({ id, data })
        .then((res: any) => {
          setSystemSchedules({ ...systemSchedules, [id]: res });
          setOpenAddEdit("");
        })
        .catch((err: any) => {
          addMessage({ message: err.message });
        })
        .finally(() => {
          setDisableAdding(false);
        });

      return;
    }
    createSystemSchedule({ id: systemId, data })
      .then((res: any) => {
        setSystemSchedules({ ...systemSchedules, [res.id]: res });
        setOpenAddEdit("");
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      })
      .finally(() => {
        setDisableAdding(false);
      });

  };
  const handleDayClick = (date: any, { selected }: any) => {
    const selectedDates: any = dates.concat();
    if (selected) {
      const selectedIndex = selectedDates.findIndex((selectedDate: any) =>
        DateUtils.isSameDay(selectedDate, date)
      );
      selectedDates.splice(selectedIndex, 1);
    } else {
      selectedDates.push(date);
    }
    setDates(selectedDates);
  };

  return (
    <div className={classes.scheduleInfoContainer}>
      <div className={classes.addEditHeader}>
        <Typography className={classes.subTitle}>{(editMode ? t`Edit ` : t`Add `) + t`Schedule`}</Typography>
      </div>
      <div className={classes.scheduleInfoPaper}>
        <div>
          <Grid container className={classes.container}>
            <div className={classes.bodyRow}>
              <Grid container direction="row" className={classes.statusRow}>
                <div className={classes.nameContainer}>
                  <TextField
                    variant={"outlined"}
                    label={"Schedule Name"}
                    placeholder={"Schedule Name"}
                    value={scheduleName}
                    onChange={(e: any) => setScheduleName(e.target.value)}
                    error={errorMsg.name}
                    className={classes.nameInput}
                  />
                  <Typography className={classes.errorLabelStyle}>{errorMsg.name || " "}</Typography>
                </div>
                <div className={classes.controlSec}>
                  <CoolSwitch
                    color={"default"}
                    checked={!scheduleDisabled}
                    switchChange={() => {
                      setScheduleStatus(!scheduleDisabled)
                    }}
                    name="scheduleStatus"
                    classes={{
                      thumb: classes[!scheduleDisabled? "thumb" : "thumbDisabled"],
                      track: classes[!scheduleDisabled ? "track" : "trackDisabled"]
                    }}
                  />
                </div>
              </Grid>
            </div>
            <Tabs
              value={tab}
              onChange={(event: any, newValue: number) => setTab(newValue)}
              variant="fullWidth"
              aria-label="icon label tabs example"
              classes={{ root: classes.root, indicator: classes.indicatorColor }}
            >
              <Tab value={scheduleCategories.weekly} label={t`Weekly`} />
              <Tab value={scheduleCategories.calendar} label={t`Calendar`} />
            </Tabs>
            {tab === scheduleCategories.weekly && <Grid container className={classes.container}>
              <Typography className={clsx(classes.selectModeStyle, { [classes.errorRedText]: errorMsg.days })}>{t`Choose Days`}</Typography>
              <Grid container className={classes.daysContainer} id="days">
                <DaysList
                  days={weekDaysArray}
                  activeDays={days}
                  action={addRemoveDay}
                  white={false}
                />
              </Grid>
              <Typography className={classes.errorLabelStyle}>{errorMsg.days || " "}</Typography>
            </Grid>}
            {tab === scheduleCategories.calendar && <Grid
              container
              className={classes.tabContainer}
            >
              <Typography className={clsx(classes.selectModeStyle, { [classes.errorRedText]: errorMsg.dates })}>{t`Choose Dates`}</Typography>
              <Grid container className={classes.daysContainer} id="dates">
                <DayPicker
                  selectedDays={dates}
                  onDayClick={handleDayClick}
                  className={classes.calendar}
                  disabledDays={
                    {
                      before: editMode ? undefined : today,
                      after: aYearFromToday
                    }}
                  fromMonth={editMode ? undefined : today}
                  toMonth={aYearFromToday}
                />
                <Typography className={classes.errorLabelStyle}>{errorMsg.dates || " "}</Typography>

              </Grid>
            </Grid>}
            <Grid container >
              <Typography className={clsx(classes.selectModeStyle, { [classes.errorRedText]: errorMsg.time })}>{t`Start Time`}</Typography>
              <MuiButton
                disableRipple
                variant="contained"
                className={clsx(classes.timeContainer, { [classes.errorBorder]: errorMsg.time })}
                onClick={(event: any) => { setOpenPicker("start"); setDatePickerAnchor(event.currentTarget); }}
              >
                {powerOnTime
                  ? powerOnTime
                  : "Set a time"}
              </MuiButton>
            </Grid>
            <Typography className={classes.errorLabelStyle}>{errorMsg.time || " "}</Typography>
          </Grid>

          <div className={clsx(classes.container, classes.modeSetpointontainer)}>
            <div className={classes.operationContainer}>
              <Typography className={classes.selectModeStyle}>{t`Choose Mode`}</Typography>
              <Grid className={classes.modeContainer} item>
              <div
                onClick={() => setScheduleMode(operationModesMirror.COOL)}
                className={clsx(classes.modeButton, {[classes.selectedModeBtn]: isScheduleInCoolMode})}
              >
                <SvgCoolMode className={classes.modeIconBtn}/>
              </div>
              <div
                onClick={() => setScheduleMode(operationModesMirror.HEAT)}
                className={clsx(classes.modeButton, {[classes.selectedModeBtn]: isScheduleInHeatMode})}
              >
                <SvgHeatMode  className={classes.modeIconBtn} />
              </div>
              <div
              onClick={() => setScheduleMode(operationModesMirror.AUTO)}
              className={clsx(classes.modeButton, {[classes.selectedModeBtn]: isScheduleInAutoMode})}
              >
                <SvgAutoMode maincolor="#aaa2aa" className={classes.modeIconBtn}/>
              </div>
              </Grid>
            </div>
            <div className={classes.setpointContainer}>
              <InputLabel htmlFor="setpoint-input" className={clsx(classes.valueTitle, classes.selectModeStyle)}>
                {t`Setpoint`}
                <Checkbox
                  onChange={handleSetpoint}
                  checked={setpointEnabled}
                  className={classes.checkboxStyle}
                />
              </InputLabel>
              <Grid className={clsx(classes.setpointContainer, {
                [classes.hideSetpoint]: !setpointEnabled
              })}>
                <MuiButton
                  disableElevation
                  disableRipple
                  disabled={!setpointEnabled}
                  onClick={increaseSetpoint}
                  className={classes.controlArrowButton}
                >
                  <ArrowUp />
                </MuiButton>
                <Typography className={classes.setpointStyle}>
                  {setpointEnabled ? Math.round(setpoint) : temperatureScale === 1 ? "24" : "75"}
                  <span className={classes.tempSymbolStyle}>
                    {temperatureSymbol()}
                  </span>
                </Typography>
                <MuiButton
                  disableElevation
                  disableRipple
                  disabled={!setpointEnabled}
                  onClick={decreaseSetpoint}
                  className={classes.controlArrowButton}
                >
                  <ArrowDownControl />
                </MuiButton>
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div>
          </div>
        </div>
      </div>
      <div className={classes.actionHolder}>

        <Button
          onClick={() => setOpenAddEdit("")}
          className={classes.saveBtn}
          marginRight
          white
          width={150}>
          {t`Cancel`}
        </Button>

        <Button
          onClick={() => save()}
          className={classes.saveBtn}
          disabled={disableAdding}
          width={150}> {t`Save`}
        </Button>
      </div>
      <Popover
        id={"graph-date-picker"}
        open={!!openPicker}
        style={{ overflow: "unset" }}
        anchorEl={datePickerAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{ className: classes.popover }}
      >
        <TimePicker
          show={!!openPicker}
          onSet={onSetTime}
          time={powerOnTime}
          onDismiss={() => setOpenPicker("")}
          onClear={onClear}
          overWriteCss={true}
          is12Hours={is12Hours}
          black={true}
        />
      </Popover>
    </div >
  );
};

export default AddEditSchedule;
