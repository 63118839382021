import React from 'react';

function SvgUser(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <defs>
        <path id="user_svg__a" d="M0 0h22v22H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M16.413 13.64c.108.278.179.569.21.864.04.265.069.533.09.8l.006.074v.037c0 .718-.58 1.3-1.298 1.3H6.578a1.299 1.299 0 01-1.298-1.3v-.037l.006-.078c.022-.266.053-.534.089-.8.033-.294.104-.584.212-.86.4-.87 3.339-2.125 5.413-2.125 2.074 0 5.013 1.255 5.413 2.125M11 10.687a2.701 2.701 0 110-5.402 2.701 2.701 0 010 5.402"
          fill="currentColor"
        />
        <mask id="user_svg__b" fill="currentColor">
          <use xlinkHref="#user_svg__a" />
        </mask>
        <path
          fill="currentColor"
          d="M11 0C4.925 0 0 4.926 0 11c0 6.075 4.925 11 11 11s11-4.925 11-11c0-6.074-4.925-11-11-11m0 1.56c5.206 0 9.44 4.235 9.44 9.44 0 5.205-4.234 9.441-9.44 9.441-5.206 0-9.44-4.236-9.44-9.441 0-5.205 4.234-9.44 9.44-9.44"
          mask="url(#user_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgUser;
