import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight, Close, Search } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { t } from "ttag";
import DateRangePicker from "../../cool_widgets/DateRangePicker";
import { Maximize, ZoomOut } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import GraphPlaceHolder from "../Reports/GraphPlaceHolder";

const SetpointMainteneance = ({ siteId, classes, setIsLoading, id, maxed, maximize, small = false, updatedDate }: any) => {

  const firstUpdate = useRef(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [loadDataFailed, setLoadDataFailed] = useState<boolean>(false);
  const tempMirror = useStoreState((state) => state.temperatureScaleMirror);
  const getData = useStoreActions((actions) => actions.ReportsData.getMantenanceData);
  const setInfo = useStoreActions((actions) => actions.ReportsData.setMantenanceData);
  const { data, date, avgData, max } = useStoreState((state) => state.ReportsData.maintenanceReportsData);
  const user: any = useStoreState((state) => state.users.me);
  const isC = +user?.temperatureScale === +tempMirror?.celsius;

  const container: any = useRef(null);

  useEffect(() => {
    updatedDate && setInfo({ date: updatedDate });
  }, [updatedDate, siteId]);

  useEffect(() => {
    setLoadDataFailed(false);
    //prevent firing at first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!siteId || !date) {
      return;
    }
    setIsLoading(true);
    getData({ date, site: siteId })
      .catch(() => {
        setLoadDataFailed(true);
      })
      .finally(() => setIsLoading(false));
  }, [date]);

  useEffect(() => {
    if (!data.length && !!siteId && !!date) {
      setLoadDataFailed(true);
    }
  }, [data]);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredData(data);
      return;
    }
    const filtered = data.filter((item: any) => item.name?.toLowerCase().indexOf(searchTerm?.toLocaleLowerCase()) > -1);
    setFilteredData(filtered);
  }, [data, searchTerm]);

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject x={-40} y={3} dy={16} transform="rotate(-25)" width="65" height="180" textAnchor="end" fontSize="12px" fontWeight="bold">
          <Typography
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "12px",
              fontWeight: 500,
              color: "#746B7E"
            }}>{payload.value}</Typography>
        </foreignObject>
      </g>
    );
  };

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && !!payload) {
      return (
        <div className={classes.graphTooltip} >
          <Typography style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
            {label}
          </Typography>

          <div style={{ display: "flex", width: "100%", alignItems: "center", marginBottom: "5px" }}>
            <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>
              {`System: ${payload[0]?.payload?.systemName || "-"}`}
            </Typography>
          </div>
          <div style={{ display: "flex", width: "100%", alignItems: "center", marginBottom: "5px" }}>
            <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>
              {`Off range : ${Number(payload[0]?.payload?.minutes_on_not_in_setpoint_percent).toFixed(0)}%`}
            </Typography>
          </div>
          <div style={{ display: "flex", width: "100%", alignItems: "center", marginBottom: "5px" }}>
            <Typography style={{ fontSize: "14px", verticalAlign: "center" }}>
              {`Run Time: ${Math.round((payload[0]?.payload?.minutes_on_total / 60) * 10) / 10}Hrs`}
            </Typography>
          </div>
        </div>
      );
    }

    return null;
  };

  const isMaxed = id === maxed;

  return (
    <div className={clsx(classes.reportContainer, isMaxed && classes.maxedContainer, small && classes.smallContainer)} id={!data.length ? "maintXX" : "maintenanceGraphId"}>
      {!!data?.length &&
        <>
          <IconButton
            style={{
              position: "absolute",
              bottom: "40%",
              right: 5,
              zIndex: 100
            }}
            onClick={() => {
              container?.current?.scrollTo({
                top: 0,
                left: container?.current.scrollLeft + 150,
                behavior: "smooth"
              });

            }}>
            <ChevronRight style={{
              fontSize: "40px"
            }} />
          </IconButton>
          <IconButton
            style={{
              position: "absolute",
              bottom: "40%",
              left: 5,
              zIndex: 100
            }}
            onClick={() => {
              container?.current?.scrollTo({
                top: 0,
                left: container?.current.scrollLeft - 150,
                behavior: "smooth"
              });

            }}>
            <ChevronLeft style={{
              fontSize: "40px"
            }} />
          </IconButton>
        </>
      }

      <div className={classes.reportContainerHeader}>
        <Typography className={classes.reportTitle}>{t`Setpoint Maintenance`}</Typography>
        <div className={classes.headerMid}>

          {
            isMaxed && !!data?.length &&
            <FormControl variant="outlined" size="small"
              style={{
                marginRight: "50px",
                backgroundColor: "#fff"
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password">{t`Search Unit`}</InputLabel>
              <OutlinedInput
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e?.target?.value)}
                endAdornment={
                  <InputAdornment position="end">

                    <IconButton onClick={() => setSearchTerm("")}
                      style={{
                        visibility: !searchTerm?.length ? "hidden" : "visible"
                      }}>
                      <Close />
                    </IconButton>
                    <Search />
                  </InputAdornment>

                }
                label="Search System"
              />
            </FormControl>
          }
          <DateRangePicker disabled={!siteId} date={date} setDate={(date: any) => setInfo({ date })} autoSelectMonth={true} />
        </div>
        <div className={classes.reportHeaderActions}>
          <IconButton onClick={() => maximize(id)} data-html2canvas-ignore="true">
            {isMaxed ? <ZoomOut /> : <Maximize />}
          </IconButton>
        </div>
      </div>
      <div className={classes.reportContainerBody}>
        {!!data?.length && <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 5px"
              }}
            >
              <div style={{ backgroundColor: "#9a7d97", width: "10px", height: "10px", marginRight: "5px" }} />
              <Typography style={{ fontSize: "13px" }}>{t`% of running time unit was not in setpoint range` + ` ${isC ? "(±2C)" : "(±4F)"}`}</Typography>
            </div>
          </div>
        </div>}
        {!!data.length ? (
          <div className={classes.doubleGraphCont}>

            <ResponsiveContainer width={140} height={"95%"}>
              <BarChart
                width={filteredData.length * 80}
                height={300}
                data={avgData}
                barGap={100}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 33
                }}
              >
                {!!data?.length && <XAxis
                  dataKey={"name"}
                  tick={<CustomizedAxisTick />}
                  interval={0}
                  allowDataOverflow={true}
                />}
                {data?.length &&
                  <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ zIndex: 99999 }}
                    cursor={{ fill: "rgba(128,128,128,0)" }}
                    offset={50}
                    allowEscapeViewBox={{ x: true, y: false }}
                  />}
                {data?.length &&
                  <YAxis
                    domain={[0, max]}
                    tick={{ fontSize: "12px", fontWeight: "bold" }}
                    tickFormatter={(value: any) => Math.round((value / 60) * 10) / 10}

                  />}
                <Bar
                  dataKey={"minutes_on_in_setpoint_range"}
                  fill={"#4b1c46"}
                  stackId="a"
                  name="In Range"
                />
                <Bar
                  dataKey={"minutes_on_not_in_setpoint_range"}
                  fill={"#9a7d97"}
                  stackId="a"
                  name="Not in Range"

                />

              </BarChart>
            </ResponsiveContainer>

            <ScrollContainer innerRef={container} vertical={false} style={{ display: "inline-block", minWidth: "55%", height: "95%", overflowY: "hidden", overflowX: "scroll" }} hideScrollbars={false} >
              <ResponsiveContainer width={filteredData.length * 80} height={"100%"}>
                <BarChart
                  width={data.length * 80}
                  height={300}
                  data={filteredData}
                  barGap={100}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 25
                  }}
                >
                  {!!data?.length &&
                    <XAxis
                      dataKey={"name"}
                      tick={<CustomizedAxisTick />}
                      interval={0}
                      allowDataOverflow={true}
                    />}
                  {data?.length &&
                    <Tooltip
                      content={<CustomTooltip />}
                      wrapperStyle={{ zIndex: 99999 }}
                      cursor={{ fill: "rgba(128,128,128,0)" }}
                      offset={50}
                      allowEscapeViewBox={{ x: true, y: false }} />}
                  <Bar
                    dataKey={"minutes_on_in_setpoint_range"}
                    fill={"#4b1c46"}
                    stackId="a"
                    name="In Range"

                  />
                  <Bar
                    dataKey={"minutes_on_not_in_setpoint_range"}
                    fill={"#9a7d97"}
                    stackId="a"
                    name="Not in Range"

                  />
                </BarChart>
              </ResponsiveContainer>
            </ScrollContainer>
          </div>) :
          <GraphPlaceHolder loadDataFailed={loadDataFailed} />
        }
      </div>

    </div >
  );
};

export default SetpointMainteneance;
