import { createStyles, Theme } from "@material-ui/core";

export const manuallySetSystemViewStyle = (theme: any) =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    inputName: {
      width: "25%",
      marginRight: "1rem"
    },
    inputBrand: {
      width: "20%",
      marginRight: "1rem"
    },
    inputModel: {
      width: "10%",
      marginRight: "1rem"
    },
    inputLine: { width: "9%" },
    inputCapacity: {
      width: "10%",
      marginRight: "1rem"
    },
    inputCapacityMeasurement: {
      width: "8%",
      marginTop: "1.4rem"
    },
    autocomplete: {
      width: "10rem",
      height: "3rem"
    },
    systemContainer: {
      borderRadius: "4px",
      border: "solid 1px #d5d2d5"
      // padding: '1rem'
    },
    unitsContainer: {
      borderRadius: "4px",
      border: "solid 1px #d5d2d5",
      marginTop: "1rem",
      display: "flex",
      flexFlow: "column",
      overflow: "hidden",
      maxHeight: "calc(100vh - 478px)",
      paddingBottom: "14px"
    },
    unitsInfoTitle: {
      borderBottom: "1px solid #d5d2d5",
      padding: 0,
      paddingLeft: "14px",
      marginBottom: "14px",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      color: "#4b1c46",
      height: "50px",
      lineHeight: "50px"
    },
    scrollableTable: {
      overflow: "auto",
      maxHeight: "500px",
      padding: "0 7px", display: "flex",
      height: "500px"
    },
    modalActions: {
      marginTop: "1rem",
      display: "flex",
      justifyContent: "flex-end",
      position: "sticky",
      bottom: 0,
      right: 0,
      height: "80px",
      background: "white",
      width: "100%",
      padding: "20px 0",
      zIndex: 7
    },
    container: {
      paddingRight: "2rem"
    },

    headCell: {
      backgroundColor: "whitesmoke"
    },

    inputFormWrapper: {
      margin: "1rem 0 1rem 0"
    },
    textField: {
      width: "100%"
    },
    inputFieldStyle: {
      minWidth: "10ch",
      height: "44px",
      width: "100%",
      "& label": {
        color: "rgba(255, 255, 255, 0.7)",
        "&focus": {
          color: "rgba(255, 255, 255, 0.7)"
        }
      },
      "& div": {
        backgroundColor: "rgba(255, 255, 255, 0.1)"
      },
      "& MuiInputBase-root": {
        color: "white"
      }
    },
    otherFormWarpper: {
      border: "1px solid #D5D2D5",
      overflow: "auto"
    },
    tableWrapper: {
      display: "flex",
      overflow: "auto"
    },
    otherFormWarpperTitle: {
      color: "#4b1c46",
      backgroundColor: "#f6f5f6",
      height: "50px",
      padding: "0",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: 500,
      lineHeight: "50px",
      paddingLeft: "14px",
      borderBottom: "1px solid #d5d2d5",
      marginBottom: "14px"
    },
    tableHeadCell: {
      padding: "0 15px",
      height: 45,
      width: "37ch",
      minWidth: "37px",
      ...theme.tables.headerCell
    },
    systemAttributes: {
      width: "100%",
      flex: 1,
      overflowY: "auto",
      padding: 20,
      paddingBottom: 0
    },
    disconnectedRow: {
      backgroundColor: "rgba(240, 81, 70, 0.2)"
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "-webkit-fill-available",
      margin: "1rem",
      flex: 1,
      overflowY: "auto"
    },
    deviceSystemsContainer: {
      width: "50rem",
      marginBottom: "2rem"
    },
    plusIcon: { fontSize: "13px !important" },
    btn: {
      height: "36px !important",
      fontSize: "14px !important"
    },
    unitsInfoHeader: {
      backgroundColor: "#f6f5f6",
      borderBottom: "1px solid #d5d2d5",
      padding: "0 14px",
      marginBottom: "14px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    unitInfoTypo: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      color: "#4b1c46",
      lineHeight: "50px"
    },
    mediumCell: {
      width: "20ch",
      minWidth: "20px"
    },
    rangeError: {
      color: "red"
    },
    unFocusedSelect: {
      "& .MuiSelect-select:focus": {
        backgroundColor: "white"
      },
      "&>svg": {
        width: "25px",
        height: "25px"
      }
    },
    Wrapper: {
      width: "100%",
      height: "100%",
      overflowY: "auto"
    },
    loader: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%"
    },
    message: {
      fontSize: "26px",
      color: "#545964"
    },

    //////////////////////dialogs
    dialogTitle: {
      paddingLeft: 20,
      paddingRight: 13,
      backgroundColor: "#f6f6f7",
      borderBottom: "1px solid #d5d2d5",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      minHeight: 60
    },
    dialogText: {
      fontFamily: "RobotoMedium",
      color: "#29132e",
      lineHeight: "normal",
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: "normal"
    },
    clearButton: {
      width: "40px",
      height: "40px"
    },
    dialogContent: {
      flex: "1",
      display: "flex",
      height: "100%",
      // flex: 1,
      maxHeight: "fit-content"
    },
    sensorGroupTableCell: {
      padding: "0 16px",
      height: "42px",
      border: 0
    },
    sensorGroupFirstTableCell: {
      border: 0,
      borderTop: "1px solid #d5d2d5",

    },
  });
