import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { Close, FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import AddRule from "../../components/AddRule";
import Delete from "../../components/Delete/Delete";
import FilterRequire from "../../components/FilterRequire/FilterRequire";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { Lookup } from "../../components/LookupTableSysDiag";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { CoolSwitch } from "../../cool_widgets/CoolSwitch";
import SVGDownload from "../../icons/Download";
import Export from "../../icons/Export";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { MenuSearch as Search } from "../../svgComponents";
import ExportTraps from "./ExportTraps";
import ImportTraps from "./ImportTraps";
import useStyles from "./TrapsList.style";
import useChurnZero from "@hooks/useChurnZero";

const sensorBrandvalue = -88;

const TrapsList: React.FC = (props: any) => {
  const classes = useStyles();
  const types = useStoreState((s) => s.types);
  const isInitialized = useStoreState((s) => s.isInitialized);
  const { customerId: selectedCustomer, siteId } = useStoreState((s) => s.selections.selections);
  const getCustomerName = useStoreState((state) => state.customers.getCustomerName);
  const getSiteTraps = useStoreActions((action) => action.traps.getTraps);
  const addTrap = useStoreActions((action) => action.traps.addTrap);
  const updateTrap = useStoreActions((action) => action.traps.updateTrap);
  const deleteTrap = useStoreActions((action) => action.traps.deleteTrap);
  const setSelectedtrapId = useStoreActions((action) => action.traps.setSelectedtrapId);
  const selectedtrapId = useStoreState((state) => state.traps.selectedtrapId);
  const getTrapTemplates = useStoreActions((action) => action.anomalyTemplates.getTrapTemplates);
  const [openAddRule, setOpenAddRule] = useState<boolean>(false);
  const [traps, setTraps] = useState<any>({});
  const [editTrap, setEditTrap] = useState<any>(null);
  const [trapTemplatesToBrands, setTrapTemplatesTobrands] = useState<any>({});
  const [trapTemplatesMap, setTrapTemplatesMap] = useState<any>({});
  const [lookupAnchor, setAnchor] = useState(null);
  const [filteredTraps, setFilteredTraps] = useState<any>({});
  const [brandsArray, setBrandsArray] = useState<any>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const [openExportTraps, setOpenExportTraps] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any>("");
  const [errors, setErrors] = useState<any>({ file: false, error: "" });
  const { hvacBrands = [] } = types;
  const sortedTraps = _.orderBy(Object.values(filteredTraps), [(trap: any) => trap.name?.toLowerCase()], ["asc"]);
  const [multiTrapsToImport, setMultiTrapsToImport] = useState<any>(null);
  const dateFormat = useStoreState((state) => state.users.dateFormat);
  const getSite = useStoreState((state) => state.sites.getSite);
  const [permissions, setPermissions] = useState<any>({});

  const {trackEvent} = useChurnZero()

  useEffect(() => {
    if (selectedCustomer && siteId) {
      setPermissions(getSite(siteId)?.permissions);
      Promise.all([getSiteTraps({ siteId, type: "" }), getTrapTemplates()]).then((response: any) => {
        const templatesToBrands: any = {};
        Object.values(response[1] || {}).forEach((template: any) => {
          const brandNum = template?.userSelections?.brand || null;
          if (!brandNum) {
            return;
          }
          if (templatesToBrands[brandNum]) {
            templatesToBrands[brandNum].push(template);
            return;
          }

          templatesToBrands[brandNum] = [template];
        });
        Object.values(response[0] || {}).forEach((trap: any) => {
          if (trap.type !== 1 && trap.type !== 50) {
            delete response[0][trap.id];
          }
        });
        setTraps(response[0]);
        setTrapTemplatesMap(response[1]);
        setTrapTemplatesTobrands(templatesToBrands);
      });
    } else {
      setTraps({});
    }
  }, [siteId, selectedCustomer]);

  useEffect(() => {
    setFilteredTraps(getFilteredTraps(traps));
  }, [brandsArray, searchValue]);

  useEffect(() => {
    if (_.isEmpty(traps)) {
      setFilteredTraps({});
      return;
    }

    const brands = Object.keys(traps)?.reduce((acc: any, curr: any) => {
      const { userSelections: { brand } } = traps[curr];
      if (traps[curr]?.type === 50 && brand !== sensorBrandvalue) {
        acc[""] = { show: true, name: t`Operational Controls`, id: "" };
        return acc;
      }
      if (brand === sensorBrandvalue) {
        acc[brand] = { show: true, name: t`External I/O devices`, id: brand };
      } else {
        acc[brand] = { show: true, name: getBrandName(brand), id: brand };
      }
      return acc;
    }, {});

    setFilteredTraps(traps);
    setBrandsArray(brands);
    if (selectedtrapId && traps[selectedtrapId]) {
      setEditTrap(traps[selectedtrapId]);
      setSelectedtrapId("");
    }

  }, [traps]);

  useEffect(() => {
    if (!fileData) {
      return;
    }
    const currNames = Object.values(traps).map((t: any) => t.name);
    const today = moment().format(dateFormat);

    const importedTraps = fileData?.length && fileData.map((impTrap: any) => {
      const { customer, fileURL, account, ...restTrap } = impTrap;

      const name = currNames.indexOf(impTrap?.name) > -1 ? `${impTrap.name}-imported at ${today}` : impTrap.name;
      return addTrap({ data: { ...restTrap, name, site: siteId } });
    });
    Promise.all(importedTraps)
      .then((res) => {
        const newTraps = res.reduce((acc: any, cur: any) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
        setTraps({ ...traps, ...newTraps });
      });
  }, [fileData]);

  const getBrandName = (value: any) => {
    if (value === sensorBrandvalue) {
      return t`External I/O devices`;
    }
    return hvacBrands?.filter((item) => item.value === value)[0]?.name;
  };
  const addNewTrap = (data: any, customerId: string) => {
    return addTrap({ data: { ...data, site: siteId } })
      .then((resp: any) => {
        setTraps({ ...traps, [resp.id]: { ...resp } });
      })
      .catch((error: any) => {

      });
  };

  const trapDelete = (id: string) => {
    deleteTrap({ id })
      .then(() => {
        delete traps[id];
        setTraps({ ...traps });
      });
  };

  const updateExistingTrap = (data: any, trapId: string) => {
    return updateTrap({ data, trapId })
      .then((resp: any) => {
        setTraps({ ...traps, [trapId]: { ...traps[trapId], ...resp } });
      })
      .catch((error: any) => {

      });
  };

  const closeDialog = () => {
    setOpenAddRule(false);
    setEditTrap(null);
  };

  const closeExport = () => {
    setOpenExportTraps(false);
  };

  const openFilterbrands = (event: any, traps: any) => {
    setAnchor(event.currentTarget);
  };

  const onApply = (appliedFilters: any) => {
    setBrandsArray({ ...appliedFilters });
    setAnchor(null);
  };

  const getFilteredTraps = (traps: any[]) => {
    function applyFilters(traps: any[]) {
      return _(traps)
        .filter((trap) => {
          return brandsArray[trap.type === 50 ? "" : trap?.userSelections?.brand || ""]?.show;
        }).filter((trap) => {
          return searchValue?.length
            ? (trap["name"].toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || trap["description"].toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
            : true;
        })
        .value();
    }
    const filteredRows = applyFilters(traps);
    return filteredRows;
  };

  const handleUploadFile = (event: any) => {
    setErrors({ file: false, error: "" });
    const noFileChoosed = event.target.files.length < 1;

    if (noFileChoosed) {
      setErrors({ file: noFileChoosed, error: "" });
      return;
    }

    const ext: any = _.last(event.target.files[0].name.split(".")) || "";
    if (ext !== "json") {
      setErrors({ file: false, error: t`file type is not allowed, use Json files`, });
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = (event) => {
      const x: any = event.target?.result;
      const data = JSON.parse(x);

      if (!Array.isArray(data)) {
        return;
      }

      if (data.length < 2) {
        setFileData(data);
        return;
      }

      setMultiTrapsToImport(data);
    };

  };

  if (!isInitialized) { return <Loading />; }

  const handleAddNewRuleClick = () => {
    trackEvent('AnomaliesAddRule','The user initiated an Anomaly rule setup');
    setOpenAddRule(true);

  };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchValue}
      onChange={(event: any) => setSearchValue(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot },
        endAdornment:
          !searchValue ? (<Search />) : (
            <IconButton
              onClick={() => setSearchValue("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  const hasFilter = Object.keys(brandsArray)?.length !== Object.keys(brandsArray)?.filter((id: any) => brandsArray[id].show)?.length;

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header hideSystemSelection hideUnitSelection screenTitle="anomalies"
          searchComponent={searchComponent}
          customGeneralNames={{ site: t`Select Site` }}
        />
        {(siteId && selectedCustomer) ? <>
          <div className={classes.titleBar}>
            <div className={classes.barRIghtSide}>
              <div className={classes.ImportExportContainer}>
                <div>
                  <LightTooltip title={"Export Anomaly"}>
                    <IconButton disableRipple onClick={() => setOpenExportTraps(true)} className={clsx(classes.iconBtnStyle, classes.exportIcon)}>
                      <Export />
                    </IconButton>
                  </LightTooltip>
                  <LightTooltip title={"Import Anomaly"}>
                    <IconButton disabled={!permissions?.canCreateTraps} disableRipple className={clsx(classes.iconBtnStyle, classes.importIcon)}>
                      <label htmlFor="file"><SVGDownload /></label>
                      <input disabled={!permissions?.canCreateTraps} value={""} type="file" hidden name="file" id="file" accept=".json" onChange={handleUploadFile} />
                    </IconButton>

                  </LightTooltip>
                </div>
                <Typography style={{ color: "red", fontSize: "12px", width: "100%" }}>{errors.error}</Typography>
              </div>
              <LightTooltip title={t`Define a new rule that will later run in the background`}>
                <div>
                  <Button
                    disabled={!permissions?.canCreateTraps}
                    onClick={handleAddNewRuleClick}
                  >
                    {t`Add New Rule`}
                  </Button>
                </div>
              </LightTooltip>
            </div>
          </div>
          <Paper elevation={0} className={classes.paperTableContainer}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >{t`Rule Name`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >{t`Customer`}</TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                      onClick={(e: any) => openFilterbrands(e, sortedTraps)}
                    >
                      <div className={classes.filterIconContainer}>
                        {t`TYPE/BRAND`}
                        <FilterList
                          className={clsx(classes.filterStyle, {
                            [classes.blueFilter]: hasFilter
                          })}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >{t`Description`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`STATUS`}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadCell }}
                      align="left"
                    >
                      {t`REMOVE`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedTraps.map((trap: any) => {
                    const { id, name, customer, userSelections: { brand = -99 } = {}, description, isEnabled } = trap;
                    return (
                      <TableRow
                        hover={permissions?.canUpdateTraps}
                        tabIndex={-1}
                        key={id}
                        onClick={() => setEditTrap(trap)}
                        className={classes.tableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          classes={{ root: clsx(classes.overWritePadding, classes.nameCell) }}
                          align="left"
                        >
                          {name}
                        </TableCell>
                        <TableCell
                          classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                          align="left"
                        >
                          {getCustomerName(customer)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          classes={{ root: clsx(classes.overWritePadding, classes.brandCell) }}
                          align="left"
                        >
                          {trap.type === 50 && brand !== sensorBrandvalue ? t`Operational Controls` : getBrandName(brand)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          classes={{ root: clsx(classes.overWritePadding, classes.descriptionCell, classes.mediumWidth) }}
                          align="left"
                        >
                          {description}
                        </TableCell>
                        <TableCell classes={{ root: classes.overWritePadding }} align="left">
                          <LightTooltip title={t`Deactivate (and reactivate) the rule without deleting it`}>
                            <div>
                              <CoolSwitch
                              disableRipple
                              disabled={!permissions?.canUpdateTraps}
                              checked={isEnabled}
                              onClick={(e: any) => updateExistingTrap({ isEnabled: !isEnabled }, id) && e.stopPropagation()}
                              />
                            </div>
                          </LightTooltip>
                        </TableCell>
                        <TableCell classes={{ root: classes.overWritePadding }} align="left">
                          <Delete
                            disabled={!permissions?.canDeleteTraps}
                            type={t`anomaly`}
                            object={trap}
                            detach={() => trapDelete(id)}
                            buttonClass={classes.deleteIcon}
                          ></Delete>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {(openAddRule || editTrap) &&
            <AddRule
              disabled={!permissions?.canUpdateTraps}
              close={closeDialog}
              createRule={addNewTrap}
              editTrap={editTrap}
              updateRule={updateExistingTrap}
              trapTemplatesToBrands={trapTemplatesToBrands}
              trapTemplatesMap={trapTemplatesMap}
              siteId={siteId}
              customerId={selectedCustomer}
            />
          }
        </> : <FilterRequire type={t`site`} />
        }
      </div>
      {lookupAnchor && (
        <Lookup
          filtersMap={brandsArray}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setAnchor(null)}
          tableHasFilters={hasFilter}
        />
      )}
      {openExportTraps && (
        <ExportTraps traps={sortedTraps}
          close={closeExport}
          getBrandName={getBrandName}
        />
      )}
      {multiTrapsToImport && (
        <ImportTraps
          traps={multiTrapsToImport}
          close={() => setMultiTrapsToImport(null)}
          getBrandName={getBrandName}
          passTraps={(traps: any) => setFileData(traps)}
        />
      )}
    </div>
  );
};

export default TrapsList;
