import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "rgb(236, 235, 239)",
    overflowY: "auto",
    overflowX: "auto"
  },
  container: {
    flex: "1"
    // textAlign: "center"
    // width: "100%",
    // background: "rgb(236, 235, 239)",
    // alignContent: "flex-start"
  },
  card: {
    padding: "15px",
    marginBottom: "15px",
    // marginLeft: "15px",
    marginTop: "15px"
  },
  header: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginTop: "15px"
  },
  rightCard: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "15px"
  },

  submitButton: {
    width: "100%"
  },
  dialog: {
    padding: "10px"
  },
  titleContent: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden"
  },
  title: {},
  headerStyle: {
    fontWeight: "bold",
    color: theme.palette.primary.light,
    marginBottom: "5px"
  },
  buttonsContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  headerTitle: {
    ...theme.dialogs.title
  },
  dropDownItem: {
    width: "100%",
    padding: "0 16px",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  passwordfield: {
    width: "100%",
    marginBottom: "20px"
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60
  },
  PasswordChangeContent: {
    padding: 20,
    paddingBottom: 20,
    overflowY: 'auto'
  }
}));

export default useStyles;
