import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      flex: 1,
      minHeight: "max-content"
      // background: theme.palette.colors.blackFontShadow
    },
    userFormContainer: {
      flexFlow: "column nowrap",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      minHeight: "max-content",
      maxWidth: "768px"
    },
    form: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
      marginTop: "10px",
      width: "100%",
      alignItems: "center"
    },
    errorLabelStyle: {
      width: "100%",
      // color: theme.palette.secondary.main,
      fontSize: "12px",
      margin: "3px 14px 0px"
    },
    submitButtonStyle: {
      height: "55px",
      borderRadius: "6px",
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      // color: theme.palette.colors.white,
      width: "100%",
      // backgroundColor: theme.palette.secondary.dark,
      // boxShadow: `0 2px 3px 0 ${theme.palette.colors.buttonShadow}`,
      "&:hover": {
        // background: theme.palette.secondary.main,
        opacity: 0.9
      },
      marginTop: "30px",
      marginBottom: "40px"
    },
    errorStyle: {
      fontSize: "12px",
      marginTop: "3px"
      // color: `${theme.palette.secondary.main} !important`
    },
    inputsContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0 1px 2px 0 rgba(21, 17, 31, 0.09)",
      // backgroundImage: theme.gradient.default,
      padding: "30px 20px 20px"
    },
    inputClass: {
      // color: "white",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      padding: "16px 0px",
      paddingLeft: "20px"
    },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingTop: "20px",
      boxSizing: "border-box"
      // boxShadow: `0 1px 2px 0 ${theme.palette.colors.fieldsShadow}`,
      // backgroundImage: theme.gradient.default
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    pageTitle: {
      fontFamily: "Roboto",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center"
      // color: theme.palette.colors.lightWhite
    },
    deviceImgStyle: {
      alignSelf: "center",
      maxWidth: "202px",
      maxHeight: "114px",
      position: "absolute"
    },
    deviceImageShadow: {
      position: "absolute",
      width: "206px",
      height: "206px",
      opacity: 0.19,
      WebkitFilter: "blur(36px)",
      filter: "blur(36px)"
      // backgroundColor: theme.palette.colors.lightWhite
    },
    deviceImageContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "206px",
      marginTop: "-10px",
      marginBottom: "-10px",
      position: "relative"
    },
    inputWrapperStyle: {
      marginTop: 0,
      marginBottom: "10px",
      fontSize: "30px",
      width: "100%",
      "& *": { borderRadius: "6px" },
      "&  div": {
        border: "1px solid rgba(253, 253, 254, 0.3)"
      },
      "& input:focus + fieldset": {
        borderWidth: "1px !important"
      }
    },
    zipFieldStyle: {
      marginTop: 0,
      fontSize: "30px",
      borderRadius: "4px",
      width: "45%",
      maxHeight: "50px",
      "& *": { maxHeight: "50px", margin: 0 },
      "& div": {
        border: "1px solid rgba(253, 253, 254, 0.3)"
      }
    },
    subTitle: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center"
      // color: theme.palette.colors.lightWhite
    },
    wrapperErrorClass: { "& div": { border: "none" } },
    scrolledSection: {
      overflow: "auto",
      width: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      height: "calc( 100vh - 145px)"
    },
    inputsRow: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      marginBottom: "10px",
      width: "100%",
      height: "50px"
    },
    selectStyle: { width: "100%" },
    buttonGrid: { width: "100%", padding: "0px 20px", marginBottom: "40px" },
    selectContainer: { width: "100%", marginBottom: "10px" },
    selectErrorStyle: {
      "& div": {
        // borderColor: theme.palette.secondary.main
      }
    },
    selectLabel: {
      paddingLeft: "14px",
      color: "rgba(0, 0, 0, 0.87)",
      opacity: 0.4
    },
    menuItemsContainer: {
      maxHeight: "200px",
      transitionDuration: "0s !important"
    }
  });
