import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #d5d2d5",
    padding: "16px 9px 16px 24px",
    color: "#29132e",
  },
  dialog: {
    // border: '1px solid  rgba(0, 0, 0, 0.54)',
    // height: "15.625rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5",
    height: "50rem",
  },
  closeIcon: {
    width: "24px",
    height: "24px",
  },
  dialogContent: {
    margin: "1rem",
    padding: "1.25rem",
    paddingBottom: 0,
  },
  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964",
  },
  noBtn: {
    color: "#4b1c46",
    width: "9rem",
    height: "2.625rem",
    border: "1px solid #421a40",
    // marginRight: '1rem'
  },
  yesBtn: {
    width: "9rem",
    height: "2.625rem",
    border: "none",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "1rem",
  },
  hover: {},
  "MuiIconButton-root": {
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  "MuiDialogTitle-root": {
    padding: "4px 24px",
  },
  overWriteIcon: {
    paddingRight: "2px",
    paddingLeft: "3px",
    "&:hover": {
      borderRadius: "0",
    },
  },
  addIcon: {
    width: "30px",
    height: "30px",
    borderRadius: "4px",
    backgroundColor: "#f6f5f6",
    color: "#7f7692",
  },
  checkContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  item: {
    "&>span>label": {
      display: "flex",
      alignItems: "center",
    },
  },
  title: {
    color: "#29132e",
    fontSize: "20px",
  },
  smallCheckbox: {
    marginRight: "10px",
    padding: 0,
    "& span": {
      width: "18px",
      height: "18px",
      borderRadius: "3px",
      backgroundColor: "#fff"
    }
  }
});

export default useStyles;
