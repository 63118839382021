import * as React from "react";

function SvgZoomOut(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.708 9.747V2.952a.793.793 0 111.586 0l.001 4.133 4.852-4.853a.793.793 0 011.032-.077l.089.077c.31.31.31.812 0 1.121l-4.808 4.81 4.02-.001c.405 0 .738.302.787.693l.006.1a.793.793 0 01-.792.792h-6.773zm-4.384 0V2.952a.793.793 0 00-1.586 0v4.133L3.884 2.232a.793.793 0 00-1.032-.077l-.089.077a.793.793 0 000 1.121l4.808 4.81-4.02-.001a.793.793 0 00-.787.693l-.006.1c0 .437.355.792.792.792h6.773zm4.384 4.509v6.795a.793.793 0 101.586 0l.001-4.132 4.852 4.852a.793.793 0 001.032.077l.089-.077a.793.793 0 000-1.12l-4.808-4.81h4.02a.793.793 0 00.787-.693l.006-.099a.793.793 0 00-.792-.793h-6.773zm-4.384 0v6.795a.793.793 0 01-1.586 0V16.92l-4.853 4.852a.793.793 0 01-1.032.077l-.089-.077a.793.793 0 010-1.12l4.808-4.81h-4.02a.793.793 0 01-.787-.693l-.006-.099c0-.438.355-.793.792-.793h6.773z"
        fill="#AAA2AA"
      />
    </svg>
  );
}

export default SvgZoomOut;
