import React from 'react';
import GenericLogin from '../../components/Login';
import useStyles from './Login.style';

import InputField from './InputField';


const Login = () => {
	return (
		<GenericLogin useStyles={useStyles} isMobile={true} inputField={InputField}/>
	);
};

Login.propTypes = {};

export default Login;