import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  logoutScreen: {
    fontSize: "20px",
    fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
    background: "rgba(1, 13, 19, 0.9)",
    height: "100%",
    width: "100%"
  },
  modalScreen: {
    width: "85%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  header: {
    display: "block",
    backgroundColor: "rgb(41, 171, 226)",
    minHeight: "50px",
    width: "100%",
    position: "relative",
    color: "white"
  },

  title: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },

  main: {
    minHeight: "50px",
    backgroundColor: "white"
  },

  content: {
    padding: "10px",
    lineHeight: "70px",
    verticalAlign: "middle",
    margin: "1rem"
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "rgb(39, 70, 97)",
    height: "70px",
    flexFlow: "row wrap",
    alignContent: "center"
  },

  button: {
    padding: "10px",
    width: "70px"
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    ...theme.dialogs.header,
    "& h6": {
      ...theme.dialogs.title
    }
  },
  noBtn: {
    width: "7rem",
    marginRight: "1rem"
  },
  yesBtn: {
  marginRight: "22px"
  },

  logOut: {
    flex: "1"
    // textAlign: "center"
  },
  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  dialogActions: {
    justifyContent: "center"
  },
  dropDownItem: {
   width: "100%",
   padding: "0 16px",
   height: "100%",
   display: "flex",
   alignItems: "center"
 }
}));

export default useStyles;
