import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
    emailDialogStyle: {
        padding: 0,
        minWidth: "370px",
        width: "400px",
        minHeight: "300px",
        maxWidth: "90%",
        height: "auto",
        display: "flex",
        flexFlow: "column nowrap",
        maxHeight: "400px"
    },
    dialogHeader: {
        paddingLeft: 20,
        paddingRight: 13,
        ...theme.dialogs.header,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60,
        minHeight: 60
    },
    headerTitle: {
        ...theme.dialogs.title
    },
    emailDialogContent: {
        display: "flex",
        flexFlow: "column nowrap",
        padding: "20px",
        maxHeight: "300px",
        overflow: "auto",
        minHeight: "235px"
    },
    actionsHolder: {
        padding: 20,
        display: "flex",
        justifyContent: "center"
    },
    iconBtnStyle: {
        width: 30,
        height: 30,
        borderRadius: 6,
        padding: 0
    }
}));

export default useStyles;
