import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available"
  },
  tableContainer: {
    display: "flex"
  },

  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    overflowY: "auto",
    height: "100%",
    padding: "1rem",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px",
    marginTop: 0
  },

  table: {
    background: theme.palette.background.paper
  },
  tableHeadCell: {
    padding: "0 15px",
    height: 45,
    ...theme.tables.headerCell
  },
  cellStyle: {
    ...theme.tables.tableCell,
    height: "35px",
    padding: "0 15px"
  },
  title: {
    fontSize: "1.25rem",
    fontFamily: "RobotoMedium",
    display: "inline-block",
    color: "rgb(75, 28, 70)",
    marginBottom: "10px"
  },
  "[class^=\"NavigationBar\"]": {
    padding: "0"
  },
  loaderContainer: {
    alignSelf: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexFlow: "column nowrap",
    justifyContent: "center"
  },

  loaderPaper: {
    minHeight: "calc(100% - 102px)"
  },
  headerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px"
  },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  siteNames: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    fontSize: theme.tables.tableCell.fontSize,
    padding: "2 15px"
  },
  smallWidth: {
    width: "5%"
  },
  mediumWidth: {
    width: "20%"
  },
  largeWidth: {
    width: "30%"
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },
  smallTable: {
    height: "70%"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  SVGDownload: {
    fontSize: "1.3rem"
  }

}));
export default useStyles;
