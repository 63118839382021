import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
    fontSize: 22,
  },
  button: {
    width: 20,
    height: 20,
    background: '#4B1C46',
    '&:hover': {
      background: '#4B1C46',
    },
    textAlign: 'center',
    '& svg': {
      height: 15,
      width: 15,
      color: '#fff',
    },
    '&[disabled]': {
      background: '#ccc',
      '& svg': {
        color: '#999',
      },
    },
  },
}));

export default useStyles;
