import { makeStyles } from '@material-ui/core';

const sideBarsWidth = '277px';

const useStyles = makeStyles((theme) => ({
  // Templating Area
  templateArea: {
    display: 'flex',
  },

  dataPointConfigWrap: {
    padding: '24px 37px',
    width: sideBarsWidth,
    backgroundColor: '#F1EAF0',
  },
  dataPointsListWrap: {
    width: sideBarsWidth,
    backgroundColor: '#F1EAF0',
  },
}));

export default useStyles;
