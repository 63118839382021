import { alpha, makeStyles } from "@material-ui/core";
import image from "./arrow.svg";

export default makeStyles((theme: any) => ({
  menuStyle: { "& ul": { display: "flex", flexFlow: "column nowrap" } },
  menuHeader: {
    borderBottom: "solid 1px #e5e2e5",
    // backgroundColor: "#f6f6f7",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    height: 83,
    alignItems: "flex-end",
    paddingLeft: 20,
    paddingRight: 10,
    paddingBottom: 12
  },
  menuItemStyle: { padding: 0, width: "100%" },
  applyBtn: { margin: "15px 0", width: "90%", alignSelf: "center", height: "50px !important" },
  resetBtn: { width: "90%", alignSelf: "center" },
  optionsContainer: { width: "100%", height: "calc(100% - 245px)", overflow: "auto", padding: "0 20px" },
  optionTextStyle: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "normal",
    // line-height: 3.4,
    letterSpacing: "normal",
    color: "#545964",
    maxWidth: "calc(100vw - 88px)",
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "calc(100% - 70px)"
    }
  },
  filtersPopup: {
    minWidth: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    position: "absolute",
    top: 0,
    margin: 0,
    borderRadius: 0,
    justifyContent: "space-between",
    background: "white",
    alignItems: "center",
    zIndex: 1
  },
  mainTitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#29132e"
  },
  tabTitle: {
    fontSize: 16,
    fontWeight: "normal",
    letterSpacing: "normal",
    color: alpha("#15111f", 0.6),
    flex: 1,
    lineHeight: "52px",
    textAlign: "center",
    cursor: "pointer",
    textTransform: "capitalize"
  },
  iconBtn: {
    borderRadius: "6px", height: 30, width: 30, padding: 0, marginBottom: -4
  },
  inputRoot: {
    width: "100%",
    paddingLeft: 9,
    paddingRight: 4,
    height: "100%",
    borderRadius: "4px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4b4850" //charcoal-grey
  },
  searchBox: {
    height: 52,
    width: "100%",
    margin: "20px 0",
    padding: "0 20px"
  },
  searchIcon: {
    transform: "scale(0.8695)"
  },
  closeIconStyle: {
    width: 25,
    height: 25,
    borderRadius: 6,
    padding: 0,
    "& svg": {
      transform: "scale(0.9)"
    }
  },
  listItemStyle: {
    height: 50,
    cursor: "pointer"
  },
  noValuesText: {
    textAlign: "center"
  },
  filtersContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    height: "100%",
    maxHeight: "calc(100% - 110px)"
  },
  ////// add edit schedule style

  scheduleInfoContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    maxWidth: "768px"
    // background: theme.gradient.addSchedule
  },
  pageContent: {
    overflow: "auto",
    flexFlow: "column nowrap",
    padding: "20px",
    flex: 1,
    alignItems: "center",
    background: "transparent"
  },
  fieldContainerStyle: { width: "100%" },
  inputStyle: {
    fontSize: "18px",
    color: "white"
  },
  iconStyle: { fontSize: "22px", color: "#fff" },

  errorLabelStyle: {
    color: "red",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "10px",
    minHeight: "15px"
  },
  dividerStyle: { marginBottom: "5px", marginTop: "5px" },
  daysContainer: {
    padding: "0px 0px",
    margin: "0px 0px",
    justifyContent: "space-between"
  },
  dayLabelStyle: {
    color: "white",
    backgroundColor: alpha("#fff", 0.1),
    width: "12%",
    fontSize: "14px",
    textTransform: "none",
    padding: "0px 0px",
    margin: "5px 0px",
    "&:hover": {
      background: alpha("#fff", 0.1),
      color: "white"
    }
  },
  tabRoot: {
    minWidth: "45px",
    minHeight: "45px",
    marginRight: "7px"
  },
  highlited: {
    // background: main,
    color: "white",
    "&:hover": {
      // background: theme.palette.secondary.main,
      color: "white"
    }
  },
  inputsContainer: {
    display: "flex",
    flexFlow: "row no-wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  controlArrowButton: {
    "& path": {
      stroke: "gray"
    },
    "&:disabled": {
      opacity: 0.3
      // "& path": {
      //   stroke: "gray"
      // }
    }
  },
  disabled: {
    color: "gray"
  },
  setpointStyle: {
    width: "fit-content",
    minHeight: "94px",
    fontSize: "80px",
    fontWeight: "normal",
    lineHeight: "normal"
  },
  checkboxContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  setpointContainer: {
    minHeight: "max-content",
    padding: "15px 10px",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center"
  },
  wideSetpointcontainer: {
    width: "200px"
  },
  setpointBox: {
    minHeight: "max-content",
    padding: "15px 10px",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    width: "50%"
  },
  fieldStyle: { width: "30px", "& input": { fontSize: "16px" } },
  valueTitle: {
    fontSize: "16px",
    color: "white",
    fontWeight: "normal",
    lineHeight: "normal",
    height: "100%",
    display: "block"
  },
  startEndTimeContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  timeContainer: {
    height: "44px",
    width: "48%",
    backgroundColor: alpha("#fff", 0.1),
    borderRadius: 6,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#79797d"
  },
  statusStyle: {
    fontSize: "18px",
    fontHeight: "normal",
    fontWeight: "normal"
  },
  selectModeStyle: {
    fontSize: "18px",
    lineHeight: "normal",
    fontWeight: "normal",
    width: "100%",
    marginBottom: "18px"
  },
  setPointSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  saveButton: {
    width: "100%",
    height: "100%",
    display: "flex",
    minHeight: "60px",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  container: {
    width: "100%",
    color: "white",
    padding: "9px 10px 16px 10px",
    borderRadius: "6px",
    background: alpha("#fdfdfe", 0.1),
    marginTop: "20px",
    maxWidth: "768px",
    minHeight: "max-content"
  },

  bodyRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "13px"
  },
  scheduleStatusStyle: {
    color: "white",
    fontSize: "18px"
  },
  controlSec: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%"
  },
  powerOnPowerOffTimeIndication: {
    // border: `1px solid ${theme.palette.secondary.main}`
  },
  checkboxStyle: { marginLeft: "5px" },
  selectModeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    transition: "height 0.15s ease-out"
  },
  tempSymbolStyle: { fontSize: "30px" },
  timeSelected: {
    // border: `solid 1px ${theme.palette.secondary.main}`
  },
  iosTimeInput: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 10,
    visibilty: "none",
    borderRadius: "22px",
    "& input": {
      background: "blue",
      borderRadius: "22px",
      height: "100%",
      padding: 0,
      opacity: 0
    }
  },
  iosTimeLabel: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 9,
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#79797d"
  },
  iosHourButton: {
    position: "relative",
    display: "flex",
    height: "44px",
    width: "48%",
    borderRadius: 6,
    backgroundColor: alpha("#fff", 0.1),
    border: "1px solid #d5d2d5"
  },
  textStyle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964"
  },
  statusSectioning: {
    borderBottom: "solid 1px #e5e2e5",
    minHeight: 60,
    display: "flex",
    padding: "0 10px",
    alignItems: "center",
    justifyContent: "space-between"
  },
  daysSection: {
    borderBottom: "solid 1px #e5e2e5",
    display: "flex",
    padding: "15px 10px",
    // alignItems: "center",
    // justifyContent: "space-between",
    flexFlow: "column nowrap"
  },
  timeSection: {
    borderBottom: "solid 1px #e5e2e5",
    display: "flex",
    padding: "15px 10px",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "solid 1px #e5e2e5"
  },
  addBtmMargin: {
    marginBottom: 15
  },
  inputClass: {
    "& input": {
      color: "#545964"
    },
    width: "260px",
    fontSize: "16px",
    color: "#545964",
    borderColor: `${"#d5d2d5"} !important`,
    "& input + fieldset": {
      borderColor: `${"#d5d2d5"} !important`
    },
    "& input:disabled + fieldset": {
      borderColor: `${"#d5d2d5"} !important`
    },
    "& ::placeholder": {
      color: "#545964",
      opacity: 0.6,
      fontSize: "16px",
      lineHieght: "normal",
      fontWeight: "normal"
    },
    "& label": {
      color: `${"#545964"} !important`,
      opacity: 0.6
    }
  },
  tab: {
    boxShadow: "0 3px 6px -6px #aaa2aa",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#fefefe",
    height: "50px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#736973",
    opacity: 1,
    textTransform: "capitalize"
  },
  tabContainer: {
    width: "100%",
    color: "rgb(255,255,255)",
    padding: "20px 10px 16px 10px",
    borderRadius: " 0 0 6px 6px",
    background: alpha("#fdfdfe", 0.1),
    maxWidth: "calc(100vw - 28px)",
    minHeight: "max-content"
  },
  calendar: {
    overflow: "hidden",
    "& .DayPicker-wrapper": {
      paddingBottom: 0
    },
    "& .DayPicker-NavButton": {
      height: "32px",
      width: "32px",
      backgroundSize: "unset",
      borderRadius: "4px"
    },
    "& .DayPicker-NavButton--next": {
      backgroundImage: `url(${image})`,
      right: 0,
      margin: 0
    },
    "& .DayPicker-NavButton--prev": {
      backgroundImage: `url(${image})`,
      transform: "rotate(180deg)",
      left: 0,
      margin: 0
    },
    "& .DayPicker-Day": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#545964",
      minWidth: "43px",
      height: "45px"
    },
    "& .DayPicker-Day--today": {
      color: "#4b1c46"
    },
    "& .DayPicker-Month": {
      width: "400px",
      height: "298px"
    },
    "& .DayPicker-Weekday": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#5c465d"
    },
    "& .DayPicker-Caption": {
      color: "#4b1c46",
      paddingLeft: "10px",
      textAlign: "center",
      height: "32px",
      lineHeight: "32px",
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal"
    },
    "& .DayPicker-Day--disabled": {
      color: alpha("#545964", 0.6)
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: "#4b1c46"
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover": {
      backgroundColor: "#4b1c46"
    },
    "& .DayPicker:not(.DayPicker--interactionDisabled):hover": {
      backgroundColor: `${alpha("#fdfdfe", 0.1)} !important`
    },
    "& .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover": {
      backgroundColor: `${alpha("#fdfdfe", 0.1)} !important`
    }
  },
  removeUnderline: {
    display: "none"
  },
  tabs: {
    paddingTop: "20px"
  },
  removeLeftBorder: {
    borderLeft: 0
  },
  selectedTab: {
    backgroundColor: "#edebed"
  },
  rightBtnRadius: {
    borderRadius: " 0px 8px 8px 0px"
  },
  leftBtnRadius: {
    borderRadius: " 8px 0px 0px 8px"
  },
  textTitle: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#545964",
    marginBottom: "24px"
  },
  controlHeader: {
    display: "flex",
    alignItems: "center"
  },
  collapse: {
    height: "0px"
  }
}));

export const paperStyle = {
  marginTop: "43px",
  borderRadius: "6px",
  boxShadow: "rgba(0, 0, 0, 0.46) 0px 6px 17px 3px",
  backgroundColor: "#FFF",
  padding: "5px",
  minWidth: "200px",
  maxWidth: "200px",
  minHeight: "150px",
  maxHeight: "320px",
  overflow: "hidden"
};
