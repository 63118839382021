import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, ListItem, ListItemText, Tooltip, Typography } from '@material-ui/core';
import { t } from 'ttag';
import { useStyles } from '../PropsFilter.style';

/**
 * Custom prop-type validator for allChecked.
 *
 * @param {Object} props - The props object.
 * @param {string} propName - The name of the prop.
 * @param {string} componentName - The name of the component.
 * @returns {Error|null} An error if validation fails, otherwise null.
 */
const allCheckedPropType = (props, propName, componentName) => {
	if (props.item === 'all' && props[propName] === undefined) {
		return new Error(`The prop \`${propName}\` is marked as required in \`${componentName}\` when \`item\` is 'all'.`);
	}
	return null;
};

/**
 * @typedef {Object} Item
 * @property {string} code - The code of the item.
 * @property {string} name - The name of the item.
 * @property {boolean} isCustomParam - Indicates if the item is a custom parameter.
 * @property {boolean} [flag] - Indicates if the item is checked based on the flag.
 * // Add other properties of the item as needed
 */

/**
 * @typedef {Object} PropsListItemProps
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange - The function to call when the item is clicked.
 * @property {Item|string} item - The item object containing details of the list item.
 * @property {boolean} [allChecked] - Indicates if all items are checked. Required if the item is 'all'.
 * @property {string} flag - Indicates what part of the param should indicate checked.
 */

const PropsListItem = React.forwardRef(({ onChange, item, allChecked, flag, ...props }, ref) => {
	const styles = useStyles();

	if (item === 'all') {
		return (
			<ListItem
				disableGutters
				dense
				onClick={onChange}
				ref={ref}
				{...props}
			>
				<Checkbox
					color="default"
					checked={allChecked}
					classes={{ root: styles.grayCheckbox }}
				/>
				<ListItemText id={'checkbox-text-all'} primary={t`All`} />
			</ListItem>
		);
	}

	if (typeof item !== 'object') {
		console.warn('item is missing!');
		return null;
	}

	const { code, name, isCustomParam } = item;
	const isChecked = item[flag];

	return (
		<ListItem
			disableGutters
			dense
			key={code}
			ref={ref}
			{...props}
		>
			<Checkbox
				color="default"
				edge="end"
				checked={isChecked}
				onChange={onChange}
				inputProps={{ 'aria-labelledby': name }}
				classes={{ root: styles.grayCheckbox }}
			/>
			<Tooltip arrow title={name} placement="top-start">
				<ListItemText
					id={code}
					primary={name}
					className={styles.limitShownText}
				/>
			</Tooltip>
			{isCustomParam && (
				<Typography className={styles.customLabel}>
					{t`CUSTOM`}
				</Typography>
			)}
		</ListItem>
	);
});

// Adding display name for better debugging
PropsListItem.displayName = 'PropsListItem';

PropsListItem.propTypes = {
	onChange: PropTypes.func.isRequired,
	item: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			code: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			isCustomParam: PropTypes.bool,
		}),
	]).isRequired,
	allChecked: allCheckedPropType,
	flag: PropTypes.string.isRequired,
};

PropsListItem.defaultProps = {
	allChecked: false,
};

export default PropsListItem;
