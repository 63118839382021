import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import useStyles from '../../AddEditDialog.style';
import CheckBoxFormControl from '@components/CheckBoxFormControl';
import clsx from 'clsx';

const PPDExtraOptions = ({disabled,connect,onGenerateMultipleReportsChange}) => {

	const classes = useStyles();

	return (
		<div className='PPD-extra-options'>

			<CheckBoxFormControl 
				disabled={disabled}
				label={t`Distribute Excess Power to all units`}
				labelClassName={clsx(classes.itemName, classes.add20TopMargin)}
				className={classes.smallCheckbox}
				{...connect('distributeExcessPower',{initialValue:true})}
			/>

			<CheckBoxFormControl 
				disabled={disabled}
				label={t`Generate Multiple Reports - one for Each selected Tenant`}
				labelClassName={clsx(classes.itemName, classes.add20TopMargin)}
				className={classes.smallCheckbox}
				{...connect('generateMultipleReports',{customOnChange:onGenerateMultipleReportsChange})}
			/>

		</div>
	);
};

PPDExtraOptions.propTypes = {
	disabled:PropTypes.bool,
	connect:PropTypes.func.isRequired,
	onGenerateMultipleReportsChange:PropTypes.func.isRequired
};

PPDExtraOptions.defaultProps = {
	disabled:false
};

export default PPDExtraOptions;