import { css } from "@emotion/core";
import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  Popover,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons/";
import clsx from "clsx";
import {
  addDays,
  differenceInDays,
  sub
} from "date-fns";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { MoonLoader } from "react-spinners";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from "recharts";
import { t } from "ttag";
import CButton from "../../cool_widgets/Button";
import {
  Download as SVGDownload,
  Refresh as SvgRefresh,
  rightAxis as RightAxisIcon
} from "../../icons";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./UnitStatsGraph.style";
import styles from "./UnitStatsTable.module.css";

interface IProps {
  startTime: number;
  endTime: number;
  unitStatsData?: any;
  onDateRangeChange?: any;
  onChipDelete?: any;
  onRefresh?: () => void;
  isLoading?: boolean;
  isSystemLoading?: boolean;
  exportFile: (sourse: string) => void;
  isNotSystemUnit: boolean;
  types: any;
  staticCodes: any;
  alertsData: any;
  unitType: string;
  disableDatepicker: boolean;
  enums: any;
  timezone: string;
  hideLegends: boolean;
}

const hex2rgba = (hex: any = "#000", alpha: number = 1) => {
  const [r, g, b] = hex?.match(/\w\w/g).map((x: any) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as any);
};

const override = css(`animation-duration: 2s;`);

const UnitStatsGraph = (props: any) => {
  const {
    unitStatsData,
    onDateRangeChange,
    onChipDelete,
    isSystemLoading = false,
    exportFile,
    startTime,
    endTime,
    isLoading,
    disableDatepicker,
    enums,
    timezone,
    dateFormat,
    timeFormat,
    hideLegends,
    type,
    paramName,
    multi,
    paramsColors,
    paramsTable,
    minDate
  } = props;

  const sitesFlags = useStoreState((s) => s.sites.sitesFlags);
  const { siteId } = useStoreState((s) => s.selections.selections);
  const timeLimit = sitesFlags[siteId || ""]?.numOfHourLimitUnitDiag || 0;
  const pastTimeAllowed = new Date().getTime() - (timeLimit * 60 * 60 * 1000);

  const selectedStatsSorted = useMemo(() => {
    return Array.from(paramsColors, ([id]) => {
      return id;
    })
  }, [paramsColors]);

  let customRanges = [
    {
      label: "last hour",
      hasCustomRendering: false,
      range: () => ({
        startDate: sub(new Date(moment().tz(timezone).format("llll")), { hours: 1 }),
        endDate: new Date(moment().tz(timezone).format("llll"))
      }),
      isSelected() {
        return false;
      }
    },
    {
      label: "last 24 hours",
      hasCustomRendering: false,
      range: () => ({
        startDate: sub(new Date(moment().tz(timezone).format("llll")), { hours: 24 }),
        endDate: new Date(moment().tz(timezone).format("llll"))
      }),
      isSelected() {
        return false;
      }
    }
  ];

  customRanges = timeLimit === 0 || timeLimit > 167 ? [...customRanges, {
    label: "last 7 days",
    hasCustomRendering: false,
    range: () => ({
      startDate: sub(new Date(moment().tz(timezone).format("llll")), { days: 7 }),
      endDate: new Date(moment().tz(timezone).format("llll"))
    }),
    isSelected() {
      return false;
    }
  }] : customRanges;
  const [refAreaLeft, setRefAreaLeft] = useState<any>("");
  const [refAreaRight, setRefAreaRight] = useState<any>("");
  const [XDomain, setXDomain] = useState<any>([startTime, endTime]);
  const [xTicks, setXTicks] = useState<any>([]);
  const [showRangePicker, setShowRangePicker] = useState<boolean>(false);
  const [selectedGraph, setSelectedGraph] = useState<string>("");
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const [dateRange, setDateRange] = useState<any>(null);
  const classes: any = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [axisConfig, setAxisConfig] = useState<any>({});
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!startTime || !endTime) {
      return;
    }

    if (startTime < minDate) {
      const newStartTime = new Date(minDate);
      let newEndTime;
      if (addDays(minDate, 7) > new Date()) {
        newEndTime = new Date();

      }
      else {
        newEndTime = addDays(minDate, 7);
      }
      setDateRange([
        {
          startDate: moment(newStartTime).tz(timezone).toDate(),
          endDate: moment(newEndTime).tz(timezone).toDate(),
          key: "selection"
        }
      ]);
      onDateRangeChange({ startDate: newStartTime, endDate: newEndTime });
    } else {
      setDateRange([
        {
          startDate: moment(startTime).tz(timezone).toDate(),
          endDate: moment(endTime).tz(timezone).toDate(),
          key: "selection"
        }
      ]);
    }
  }, [startTime, endTime, minDate]);

  useEffect(() => {
    setXDomain([startTime, endTime]);
  }, [startTime, endTime]);

  useEffect(() => {
    const paramsAxisConfig: any = Array.from(paramsColors)?.reduce((vals: any, item: any) => {
      return { ...vals, [item[0]]: "left" };
    }, { power: "left", "energy": "left", reading_value: "left" });
    setAxisConfig({ ...paramsAxisConfig, ...axisConfig });
  }, [paramsColors.size]);

  useEffect(() => {
    generateXTicks(XDomain[0], XDomain[1]);
  }, [XDomain]);

  const generateXTicks = (startTime: any, endTime: any) => {
    const interval = (endTime - startTime) / 6,
      ticks = [startTime];
    for (let i = 1; i < 6; i++) {
      ticks.push(startTime + interval * i);
    }
    ticks.push(endTime);
    setXTicks(ticks);
  };

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (!payload || !payload[0]) {
      return null;
    }
    const dateTime = `${dateFormat} ${timeFormat}`;

    return (
      <div className={classes.customTooltip}>
        <Typography className="label">
          <strong>{`Date/Time: ${moment(label).tz(timezone).format(dateTime)}`}</strong>
        </Typography>
        {
          payload.map((param: any, index: number) => {
            const { name, value, unit: enums, color } = param;

            return (
              <div key={`line-${name}-${index}`} className={classes.paramCont}>
                <Typography className={classes.paramName} style={{ color }}>{name}</Typography>
                <Typography className={classes.paramVal}>{!enums ? value : enums[value]}</Typography>
              </div>
            );
          })
        }
      </div>
    );
  },
    CustomXAxisTick = (props: any) => {
      const {
        x,
        y,
        payload: { value }
      } = props;
      const direction = "middle";

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} fill="#666" fontSize={"13px"}>
            <tspan textAnchor={direction} x="0" fontFamily="Roboto">
              {moment(value).tz(timezone).format(dateFormat)}
            </tspan>
            <tspan textAnchor={direction} x="0" dy="20" fontSize={"13px"} fontFamily="Roboto">
              {moment(value).tz(timezone).format(timeFormat)}
            </tspan>
          </text>
        </g>
      );
    },
    CustomYAxisTick = (props: any) => {
      const { x, y, payload, orientation } = props;
      const { value } = payload;

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} textAnchor="end" fill="#666" fontSize={"13px"}>
            <tspan x={orientation === "left" ? -5 : 35} dy="0.355em" fontFamily="Roboto">
              {Math.round(value * 10) / 10}
            </tspan>
          </text>
        </g>
      );
    },
    toggleAxis = (e: any, value: any) => {
      e.preventDefault();
      e.stopPropagation();
      setAxisConfig({ ...axisConfig, [value]: axisConfig[value] === "left" ? "right" : "left" });
    },
    toggleGraph = (graphName: string) => {
      if (selectedGraph === graphName) {
        return setSelectedGraph("");
      }
      setSelectedGraph(graphName);
    };

  const zoom = (didLeaveGraph: any) => {
    let rightRef = refAreaRight,
      leftRef = refAreaLeft;

    const firstTime = unitStatsData && unitStatsData.length && unitStatsData[0].timestamp;
    const lastTime = unitStatsData && unitStatsData.length && unitStatsData[unitStatsData.length - 1].timestamp;

    if (firstTime && leftRef < firstTime) {
      leftRef = firstTime;
    }

    if (lastTime && rightRef > lastTime) {
      rightRef = lastTime;
    }

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    if (rightRef < leftRef) { [leftRef, rightRef] = [rightRef, leftRef]; }

    if (_.isString(didLeaveGraph) && didLeaveGraph === "dataMax") {
      const endTimeIndex = xTicks.length - 1;
      setXDomain([leftRef - 5, endTime + 5]); //"dataMax +5"]);

      // refAreaRight === undefined only when clicking on graph's left boundry
    } else if (
      _.isString(didLeaveGraph) && didLeaveGraph === "dataMin"
      || !refAreaRight
    ) {
      setXDomain([startTime - 5, leftRef]);

    } else {
      setXDomain([leftRef - 5, rightRef + 5]);
    }

    setRefAreaLeft("");
    setRefAreaRight("");
  },
    selectGraph = (e: any) => {
      const { value } = e;
      setSelectedGraph(value);
    },
    changeDateRange = () => {
      const { startDate, endDate } = dateRange[0];
      if (!isValidDate(startDate) || !isValidDate(endDate)) {
        cancelDateSelection();
        return;
      }

      onDateRangeChange({ startDate, endDate }, false, timeLimit);
      setShowRangePicker(false);
    },
    cancelDateSelection = () => {
      setShowRangePicker(false);
      setDateRange([
        {
          startDate: new Date(moment(startTime).tz(timezone).format("llll")),
          endDate: new Date(moment(endTime).tz(timezone).format("llll")),
          key: "selection"
        }
      ]);
    },
    isZoomActive = XDomain[0] !== startTime || XDomain[1] !== endTime,
    currentDay = new Date(),

    CustomizedLegend = ({ payload }: any) => {
      return (
        <div className={classes.legendWrapper}>
          <div className={classes.legendContainer}>
            {payload.map((graph: any, index: any) => {
              const { id, name, color } = graph;
              if (!name) {
                return;
              }
              const isSelected = selectedGraph === id;
              const isLeft = axisConfig[id] && axisConfig[id] === "left";

              return (
                <div
                  key={`legend - ${index}`}
                  onClick={() => toggleGraph(id)}
                  className={clsx(classes.legendItem, !isLeft && classes.dashedBox)}

                  style={{
                    borderColor: isSelected ? "#fff" : color,
                    backgroundColor: isSelected ? color : "#fff"
                  }}
                >
                  <div
                    onClick={(e: any) => toggleAxis(e, id)}
                    className={clsx(classes.toggleAxisStyle, isLeft && classes.flipIcon)}
                    style={{ borderColor: isSelected ? "#fff" : color, borderStyle: isLeft ? "solid" : "dashed", backgroundColor: isSelected ? color : hex2rgba(color, 0.1) }}
                  >

                    <RightAxisIcon
                      color={isSelected ? "#fff" : color}
                    />

                  </div>
                  <Typography style={{ color: isSelected ? "#fff" : color, fontSize: "0.8rem", paddingRight: "2px" }}>
                    {name.length <= 20 ? name : name.substr(0, 17) + "..."}
                  </Typography>
                  <Close
                    style={{ color: isSelected ? "#fff" : color }}
                    className={classes.legendRemove}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onChipDelete(id);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    };


  //This controls the legends drawing
  const graphLegendPayload = useMemo(() => {
    if (multi || type === 'powerMeter') {
      return selectedStatsSorted.map((id: any) => {
        return { id, color: paramsColors.get(id), name: paramsTable[id]?.name };
      });
    }

    if (hideLegends) {
      return [];
    }

    if (type === "sensor") {
      return [{ id: "reading_value", name: "value", color: "green" }];
    }

    return [
      { id: "power", name: "Power (kW)", color: "green" },
      { id: "energy", name: "Energy (kWh)", color: "blue" },
    ];
  }, [multi, selectedStatsSorted, hideLegends, type]);

  return (
    <React.Fragment>
      <Grid container className={classes.chartContainer1}>
        <div className={classes.headerContainer}>
          <Button
            disableElevation
            disableRipple
            disabled={disableDatepicker}
            variant="contained"
            onMouseUp={(event: any) => {
              setDatePickerAnchor(event.currentTarget);
              setShowRangePicker(!showRangePicker);
            }}
          >{`${moment(startTime).tz(timezone).format(dateFormat)} > ${moment(endTime).tz(timezone).format(
            dateFormat
          )}`}</Button>
          <Popover
            id={"graph-date-picker"}
            open={showRangePicker}
            style={{ overflow: "unset" }}
            anchorEl={datePickerAnchor}
            onClose={cancelDateSelection}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <div className={clsx(classes.rangePickerContainer)}>
              <DateRangePicker
                onChange={(item: any) => {
                  const range = item.selection;
                  let { startDate, endDate, key } = range;

                  if (differenceInDays(endDate, startDate) > 7) {
                    endDate = addDays(startDate, 7);
                  }

                  setDateRange([{ startDate, endDate, key }]);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                preventSnapRefocus={true}
                months={2}
                direction={"horizontal"}
                ranges={dateRange}
                maxDate={new Date(moment().tz(timezone).format("llll"))}
                staticRanges={customRanges}
                inputRanges={[]}
                shownDate={new Date(currentDay.setMonth(currentDay.getMonth() - 1))}
                minDate={timeLimit !== 0 ? pastTimeAllowed > minDate ? new Date(pastTimeAllowed) : new Date(minDate) : new Date(minDate)}
              />
              <div className={classes.calendarButtons}>
                <CButton onMouseUp={() => cancelDateSelection()} white width={100} marginRight>
                  {t`Cancel`}
                </CButton>
                <CButton
                  width={100}
                  onMouseUp={() => changeDateRange()}
                >
                  {t`Done`}
                </CButton>
              </div>
            </div>
          </Popover>
          <FormGroup row className={classes.checkboxForm}>
            {isSystemLoading ? <div className={classes.loaderContainer}><MoonLoader css={override} size={20} color={"#7f7e7e"} loading={true} /></div> :
              <IconButton disableRipple disabled={multi && !selectedStatsSorted.length} onClick={handleClick} className={classes.iconBtnStyle}>
                <SVGDownload className={classes.SVGDownload} />
              </IconButton>}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              classes={{ paper: classes.exportDialog }}
            >
              <div key={1} onClick={handleClose}>
                <Button disableRipple disableElevation onMouseUp={() => exportFile()} className={classes.exportBtn}>
                  {type === "sensor" ? t`Export sensor data` : t`Export power meter data`}
                </Button>
              </div>
            </Popover>
          </FormGroup>
        </div>
        <div className={classes.chartContainer}>
          {isLoading && <div className={classes.loadingContainer}>
            <SvgRefresh className={clsx(styles.refreshStyleLoading, classes.refreshIcon)} />
          </div>}

          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={unitStatsData}
              margin={{ top: 10, right: 0, bottom: 15, left: 5 }}
              onMouseDown={(e: any) => {
                e && e.activeLabel && setRefAreaLeft(e.activeLabel);
              }}
              onMouseMove={(e: any) => {
                refAreaLeft && setRefAreaRight(e.activeLabel);
              }}
              onMouseLeave={() => {
                if (refAreaLeft && refAreaLeft < refAreaRight) {
                  zoom("dataMax");
                }
                if (refAreaLeft && !refAreaRight) {
                  zoom("dataMin");
                }

              }}
              onMouseUp={zoom}
            >
              <XAxis
                xAxisId={"mainX"}
                dataKey="timestamp"
                type={"number"}
                domain={XDomain}
                allowDataOverflow={true}
                allowDuplicatedCategory={false}
                tick={<CustomXAxisTick />}
                height={40}
                strokeWidth={3}
                ticks={xTicks}
                interval={0}
              />
              <YAxis
                yAxisId={"left"}
                orientation={"left"}
                type="number"
                domain={["dataMin - 5", "dataMax + 5"]}
                allowDuplicatedCategory={false}
                allowDataOverflow={true}
                allowDecimals={false}
                strokeWidth={3}
                tickCount={8}
                tick={<CustomYAxisTick orientation={"left"} />}
                width={43}
              />

              <YAxis
                yAxisId={"right"}
                orientation={"right"}
                type="number"
                domain={["dataMin - 5", "dataMax + 5"]}
                allowDuplicatedCategory={false}
                allowDataOverflow={true}
                allowDecimals={false}
                strokeWidth={3}
                tickCount={8}
                tick={<CustomYAxisTick orientation={"right"} />}
                width={43}
              />

              <CartesianGrid xAxisId={"mainX"} yAxisId={"left"} strokeDasharray="5 5" />
              <Tooltip content={<CustomTooltip />} />
              {!isLoading && !multi && type === "sensor" &&
                <Line
                  xAxisId={"mainX"}
                  yAxisId={axisConfig["reading_value"] || "left"}
                  name={paramName}
                  data={unitStatsData}
                  strokeDasharray={`5 ${axisConfig["reading_value"] !== "left" ? 8 : 0}`}
                  dataKey={"reading_value"}
                  unit={enums}
                  type={"monotone"}
                  stroke={"green"}
                  strokeWidth={selectedGraph ? 5 : 2}
                  dot={false}
                />}

              {!isLoading &&
                selectedStatsSorted.map((id: any, lineIndex: any) => {
                  const color: string = paramsColors.get(id);
                  const param = paramsTable[id];
                  const key = type === "sensor" ? id : id.split("-")[0];
                  if (!param) {
                    return;
                  }
                  const isEnergy = type !== "sensor" && (id === "energy" || id.split("-")[1] === "e");
                  const isLeft = axisConfig[id] && axisConfig[id] === "left";
                  const { name, enums } = param;
                  return (
                    <Line
                      xAxisId={"mainX"}
                      yAxisId={isLeft ? "left" : "right"}
                      key={`${id}-${lineIndex}`}
                      strokeDasharray={`5 ${!isLeft ? 8 : 0}`}
                      unit={enums}
                      name={name}
                      data={unitStatsData}
                      dataKey={key}
                      type={"monotone"}
                      stroke={color}
                      strokeWidth={selectedGraph === id ? 5 : 2}
                      dot={false}
                    />
                  );
                })}

              {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  xAxisId={"mainX"}
                  yAxisId="left"
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}
              <Legend
                onClick={selectGraph}
                layout="horizontal"
                verticalAlign="top"
                align="left"
                content={<CustomizedLegend />}
                payload={graphLegendPayload}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div className={classes.legendOrthers}>
            <div className={classes.zoomControlsSection}></div>
            <Typography className={classes.zoomInstruc}>
              {t`Click and drag over an area of the graph to zoom`}
            </Typography>
            <div className={classes.zoomControlsSection}>
              <CButton
                disabled={!isZoomActive}
                onMouseUp={() => {
                  generateXTicks(startTime, endTime);
                  setXDomain([startTime, endTime]);
                }}
              >
                {t`Reset Zoom`}
              </CButton>
            </div>
          </div>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default UnitStatsGraph;
