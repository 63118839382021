import { orderBy } from "lodash";
import { ParamsTable, ParamsTableMap } from "../UnitStats";

export const containsText = (name: any, searchText: any) =>
  searchText && name?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

export const getComputedParams = (params: ParamsTableMap, searchText: string): ParamsTable[] => {
  if (!params) {
    return [];
  }

  const sortedParams = orderBy(Object.values(params), ["plottable", "isCalculated", "name"], ["desc", "desc", "asc"]);

  if (!searchText) {
    return sortedParams;
  }

  return sortedParams.filter((param: any) => {
    return (
      containsText(param?.name, searchText) ||
      containsText(param?.measurementUnits, searchText)
    );
  });
};
