import { createStyles, makeStyles } from "@material-ui/core";
import { lighten } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((darkTheme: any) =>
({
  buttonBack: {
    height: "53px",
    width: "192px",
    borderRadius: "4px",
    fontFamily: "roboto",
    fontWeight: "bold",
    margin: "0",
    marginBottom: "10px",
    ...darkTheme.overrides.MuiButton.outlinedSecondary
  },
  buttonReset: {
    minHeight: "53px",
    width: "192px",
    marginBottom: "10px",
    background: darkTheme.palette.colors.loginBtnColor || "#f05347",
    "&:hover": {
      background: darkTheme.palette.colors.loginBtnColor ? lighten(darkTheme.palette.colors.loginBtnColor, 0.1) : "rgba(240, 83, 71, 0.9)"
    },
    "&:focus": {
      background: darkTheme.palette.colors.loginBtnColor ? lighten(darkTheme.palette.colors.loginBtnColor, 0.1) : "rgba(240, 83, 71, 0.9)"
    },
    "&:disabled": {
      background:  darkTheme.palette.colors.loginBtnColor ? lighten(darkTheme.palette.colors.loginBtnColor, 0.5) : "#f4867e"
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "400px",
    margin: "1rem"
  },
  container: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    width: "640px",
    height: "450px",
    borderRadius: "6px",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  radio: {
    "& span": { color: "white" }
  },
  buttonLogin: {
    height: "53px",
    width: "480px",
    borderRadius: "4px",
    border: "none",
    fontFamily: "roboto",
    fontWeight: "bold",
    margin: "0",
    marginBottom: "10px",
    backgroundColor: "rgb(240, 83, 71)",
    color: "rgb(255, 255, 255)",
    "&:hover": {
      backgroundColor: "rgb(240, 83, 71)"
    },
    "& span": { fontSize: "14px" }
  },

  Login: {
    background: "white",
    MozFlexDirection: "column",
    flexDirection: "column",
    height: "100vh",
    display: "flex",
    fontSize: "1.5rem",
    color: "#29abe2"
  },

  logos: {
    justifyContent: "center",
    display: "flex",
    MozFlexDirection: "column",
    flexDirection: "column",
    alignItems: "center"
  },

  title: {
    ...darkTheme.typography.themeTitle,
    paddingBottom: "20px",
    paddingTop: "40px",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  overrideField: {
    "&>label": {
      "&.Mui-disabled": {
        color: darkTheme.textField.loginForm.color
      }
    }
  },

  bottom: {
    height: "48px",
    opacity: "0.6",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },

  mailto: {
    color: darkTheme.link.email.color
  },
  splashScreen: {
    width: "100%",
    height: "100%",
    position: "fixed",
    background: "rgba(41, 19, 46, 0.95)",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    zIndex: 100
  },

  logo: {
    paddingBottom: "50px"
  },

  logoTitle: {
    height: "32px",
    width: "206px",
    paddingBottom: "40px"
  },
  CoolAutomationName: {
    fontSize: "50px",
    color: "white",
    marginTop: "8rem"
  },

  splashScreenImg: {
    marginTop: "10rem"
  },

  interactionSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingBottom: "15rem"
  },

  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    MozFlex: "1",
    flex: "1",
    backgroundImage: darkTheme.gradiants.themeBackground
  },

  forgotPassBtn: {
    color: "rgba(255, 255, 255, 0.7)",
    backgroundColor: "inherit"
  },

  newPasswordForm: {
    display: "inline-flex",
    flexDirection: "column"
  }
})
);

export default useStyles;
