import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import { Reply } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import { t } from "ttag";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TopBar } from "@components";
import ErrorBox from "@components/ErrorBox/ErrorBox";
import Loading from "@components/Loading/Loading";
import DatePicker from "@components/MobileDatePicker/MobileDatePicker";
import { Lookup } from "@components/MobileLookup";
import Button from "../../cool_widgets/Button";
import { isIOS } from "react-device-detect";
import { ArrowDown, MobileAudit, MobileLogo } from "@icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AuditInfo from "./AuditInfo";
import useStyle from "./MobileAudits.style";
import useAudits, { AuditParams, IAudit, IAuditsMap } from '@hooks/useAudits';
import LinearProgressBar from "@components/LinearProgressBar";
import AuditActions from "../../screens/Audits/AuditActions";
import AuditSource, { getSourceNames } from "../../screens/Audits/AuditSource";
import EmailDialog from "./EmailDialog";
import FilterBar from "./Filters";

/**
* Converts an array of numbers or strings into an object with keys from the array and values set to true.
* 
* @param {(number | string)[]} array - The array of numbers or strings to convert.
* @returns An object with keys from the array and values set to true.
*/
function arrayToObject(array: (number | string)[]): Record<number | string, boolean> {
    return array.reduce((obj, item) => {
        obj[item] = true;
        return obj;
    }, {} as Record<number | string, boolean>);
}

/**
* Gets the relevant state properties needed for the component.
* @param {Function} state.sites.getSite - Function to get site data.
* @param {boolean} state.isInitialized - Flag indicating if the app is initialized.
* @param {string} state.users.timeFormat - The time format preference.
* @param {string} state.users.dateFormat - The date format preference.
* @param {Array} state.users.users - Array containing all users data.
* @param {Array} state.units.allUnits - Array containing all units data.
* @param {Object} state.mobileSelections - The mobileSelections object containing selected data.
* @param {Object} state.selections - The selections object containing selected data.
*  @param {Function} state.customers.getCustomer - Function to get customer.
* @returns The relevant state properties.
*/
const getState = (state: any) => {
    return {
        getSite: state.sites.getSite,
        isInitialized: state.isInitialized,
        timeFormat: state.users.timeFormat,
        dateFormat: state.users.dateFormat,
        allUsers: state.users.users,
        allUnits: state.units.allUnits,
        mobileSelections: state.selections.mobileSelections,
        selections: state.selections.selections,
        getCustomer: state.customers.getCustomer,
        stillLoadingInitialAPIs: state.stillLoadingInitialAPIs,
    }
}

const MobileAudits: React.FC = () => {
    const classes = useStyle();
    const history = useHistory();
    const {
        getSite,
        isInitialized,
        timeFormat,
        dateFormat,
        allUnits,
        allUsers,
        mobileSelections,
        getCustomer,
        selections,
        stillLoadingInitialAPIs
    } = useStoreState(getState);
    const {getAuditsPaginated, auditsFilters} = useAudits();
    const { customerId, siteId, systemId, unitId } = mobileSelections;
    const { dateRange } = selections;
    const sendAuditReportByEmail = useStoreActions((s) => s.audits.shareAuditsByEmail);
    const updateSelections = useStoreActions((s) => s.selections.updateSelections);
    const { canShareAuditByEmail = true } = getCustomer(customerId) || {};
    const [loading, setLoading] = useState(false);
    const [opendatePicker, setOpenDatePicker] = useState<boolean>(false);
    const [openFilters, setOpenedFilters] = useState<boolean>(false);
    const [selectedFilters, setSelectedFilters] = useState<{
        users: { [key: string]: any };
        source: { [key: string]: any };
        actions: { [key: string]: any };
    }>({ users: {}, source: {}, actions: {} });
    const [emails, setEmails] = useState<string[]>([]);
    const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false);
    const [selectedAudit, setSelectedAudit] = useState<IAudit | null>(null);
    const [error, setError] = useState<any>(null);
    const [loadingNextPage, setLoadingNextPage] = useState<boolean>(false);
    const [actorsFilter, setActorsFilter] = useState<any>({});
    const [actionsFilter, setActionsFilter] = useState<any>({});
    const [sourcesFilter, setSourcesFilter] = useState<any>({});
    const [auditsMap, setAuditsMap] = useState<IAuditsMap>({
        audits: [],
        totalCount: 0,
        totalPages: 0,
        pageSize: 20,
        page: 1,
        newerRecordsExist: false
    });

    /**
      * Fetches audits data based on the specified parameters.
      *
      * @param {number} [pageNum] - The page number for pagination (optional).
      * @param {number} [pageSize] - The page size for pagination (optional).
      * @returns A Promise that resolves when audits data is fetched and updated.
    */
    const fetchAudits = async (pageNum?: number, pageSize?: number): Promise<void> => {
        if (!dateRange || !customerId) {
            return;
        }
        // Calculate UTC start and end dates
        const startDate = moment.utc(dateRange?.startDate).subtract(9, 'hours').valueOf();
        const endDate = moment.utc(dateRange?.endDate).endOf('day').add(9, 'hours').valueOf();
        // Extract selected filters
        const actionArr = Object.keys(selectedFilters.actions).filter(key => selectedFilters.actions[key]);
        const sourceArr = Object.keys(selectedFilters.source).filter(key => selectedFilters.source[key]);
        const actorArr =  Object.keys(selectedFilters.users).filter(key => selectedFilters.users[key]);

        // Construct params object for API request
        const params: AuditParams = {
            pageNum,
            pageSize,
            customerId,
            siteId,
            systemId,
            unitId,
            startDate,
            endDate,
        };
    
        // Precompute lengths
        const allActionsCount = auditsFilters?.auditActions?.length;
        const allSourcesCount = auditsFilters?.auditSources?.length;
        const allActorsCount = Object.keys(auditsFilters?.auditActors).length;
    
        // Conditionally add arrays based on lengths
        if (actionArr.length < allActionsCount) {
            params.actionArr = actionArr;
        }
    
        if (sourceArr.length < allSourcesCount) {
            params.sourceArr = sourceArr;
        }
    
        if (actorArr.length < allActorsCount) {
            params.actorArr = actorArr;
        }
  
        // Set loading state based on pagination
        pageNum ? setLoadingNextPage(true) : setLoading(true);
        try {
            // Fetch audits data from API
            const result = await getAuditsPaginated(params);
            setAuditsMap(result || {});
        } catch (error) {
            // Handle errors
            setError(JSON.stringify(error));
        } finally {
            // Reset loading states
            setLoading(false);
            setLoadingNextPage(false);
        }
    };

    useEffect(() => {
        if (!dateRange?.endDate || !dateRange?.startDate) {
            updateSelections({
                type: "time",
                data: {
                    startDate: new Date(new Date().setHours(0, 0, 0) - 2 * 24 * 60 * 60 * 1000),
                    endDate: new Date()
                }
            });
        }
        const users: any = {};
        Object.values(allUsers).forEach((user: any) => {
            users[user?.username] = true;
        });
        setActorsFilter(users);
    }, []);

    useEffect(() => {
        try {
            fetchAudits();
        } catch (error) {
            setError(error);
        }
    }, [dateRange, customerId, siteId, systemId, unitId, selectedFilters, stillLoadingInitialAPIs]);

    useEffect(() => {
        // Generate filter maps based on preferred filters and audit data
        const actorsFilterMap = arrayToObject(Object.keys(auditsFilters?.auditActors));
        const actionsFilterMap = arrayToObject(auditsFilters?.auditActions);
        const sourcesFilterMap = arrayToObject(auditsFilters?.auditSources);
        // Set filter states
        setActorsFilter(actorsFilterMap);
        setActionsFilter(actionsFilterMap);
        setSourcesFilter(sourcesFilterMap);
    }, [auditsMap]);

    const shareAuditsByEmail = () => {
        if (!dateRange) {
            return;
        }
        const startTime = moment(dateRange?.startDate).startOf('day').utc().subtract(15, 'hours').valueOf();
        const endTime = moment(dateRange?.endDate).endOf('day').utc().add(15, 'hours').valueOf();
        const { actions, source, users } = selectedFilters;
        const payload: any = {
            params: { startTime, endTime, customerId: customerId },
            data: {
                emails: emails,
                users: Object.keys(users),
                sources: Object.keys(source),
                actions: Object.keys(actions)
            }
        }
        if (siteId) {
            payload.data.sites = [siteId]
        }
        if (unitId) {
            payload.data.unit = unitId
        }
        sendAuditReportByEmail({ ...payload })
            .catch((err: { message: any; }) => setError(err.message || "Something went wrong"));
        setIsEmailDialogOpen(false);
        setEmails([]);
    };

    /**
     * Callback function invoked when applying selected filters.
     * @param {Object} selectedFilters - The selected filters to apply.
    */
    const onApply = useCallback((selectedFilters: any) => {
        setSelectedFilters({ ...selectedFilters });
        setOpenedFilters(false);
    }, []);

    if (!isInitialized) {
        return <Loading />;
    }

    const filterValues: any = {
        users: actorsFilter,
        source: sourcesFilter,
        actions: actionsFilter
    };

    /**
     * Moves to the next or previous page of audit records.
     * @param {string} direction - The direction to move. Either 'next' or 'prev'.
    */
    const moveToPage = (direction: 'next' | 'prev'): void => {
        let { page, pageSize } = auditsMap;
        // Increment or decrement the page based on the direction
        direction === "next" ? ++page : --page;
        // Fetch audits for the new page
        fetchAudits(page, pageSize);
    };

    /**
    * Retrieves the name of a unit based on its ID.
    * @param {string} unitId - The ID of the unit.
    * @returns The name of the unit, including control unit name and address if applicable.
    */
    const getUnitName = (unitId: string) => {
        const unit = allUnits[unitId];
        if (!unit || !unit.isVisible) {
            return '';
        }
        if (+unit.type === 3) { // is service unit
            const controlUnitId: any = unit.controlUnit;
            const controlUnit = allUnits[controlUnitId];
            const controlName = controlUnit?.name || t`Unassigned`;
            return `${controlName} (${unit.address})`;
        }
        return unit.name;
    };

    /**
    * Retrieves the display name for a filter option based on the filter type.
    * @param {string} filter - The type of filter (e.g., 'source', 'actions', 'users').
    * @param {string} option - The option value for the filter.
    * @returns The display name for the filter option.
    */
    const getFilterName = (filter: string, option: string): string => {
        switch (filter) {
            case 'source':
                // Call getSourceNames to get a human-readable name for the source, or use the option itself
                return getSourceNames(option) || option;
            case 'actions':
                // Retrieve the action name from the AuditActions object or use the option itself
                return AuditActions[option] || option;
            case 'users':
                // Retrieve the username from the auditsFilters or use a default value '-'
                return auditsFilters?.auditActors[option]?.username || '-';
            default:
                // If the filter type is unknown, return the option itself
                return option;
        }
    }

    return (
        <>
            <div className={classes.view}>
                <div className={clsx(classes.contentArea, { [classes.iosPadding]: isIOS })}>
                    <TopBar
                        leftIconComponent={<MobileLogo />}
                        leftAction={() => history.push("/dashboard")}
                        screenTitle="audits"
                        applySiteTypeFiltering
                    />
                    <div id="title" className={classes.titleContainer}>
                        <MobileAudit className={classes.titleIcon} />
                        <Typography className={classes.title}>{t`Activity Audit`}</Typography>
                    </div>

                    <div onClick={() => setOpenDatePicker(true)} className={classes.datePickerButton}>
                        <Typography className={classes.datePickerText}>
                            {`${moment(dateRange?.startDate || new Date()).format("MMM DD")} - ${moment(dateRange?.endDate || new Date()).format("MMM DD")}`}
                        </Typography>
                        <ArrowDown className={classes.arrow} />
                    </div>

                    <FilterBar
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setOpenedFilters={setOpenedFilters}
                        getFilterName={getFilterName}
                    />

                    <div className={classes.progressBarContainer}>{loadingNextPage && <LinearProgressBar durationInSeconds={30} />}</div>
                    <div style={{ display: "flex", justifyContent: "flex-end", padding: "0 9px", paddingTop: 15, height: 50 }}>
                        {(auditsMap.totalCount !== 0 && !loading) &&
                            <>
                                <Typography>
                                    <span>{((auditsMap.page - 1) * auditsMap.pageSize) + 1}</span>
                                    <span>-</span>
                                    <span>
                                        {(auditsMap.page - 1) * auditsMap.pageSize + auditsMap.pageSize > auditsMap.totalCount
                                        ? auditsMap.totalCount
                                        : (auditsMap.page - 1) * auditsMap.pageSize + auditsMap.pageSize}
                                    </span>
                                    <span>{" "}of{" "}</span>
                                    <span>{auditsMap.totalCount}</span>
                                </Typography>
                                <div style={{ marginLeft: 20 }}>
                                    <IconButton className={classes.iconBtn} disabled={auditsMap.page === 1} onClick={() => moveToPage("prev")}><ArrowDown className={clsx(classes.arrow, classes.transformLeft)} /></IconButton>
                                    <IconButton className={classes.iconBtn} disabled={auditsMap.page === auditsMap.totalPages} onClick={() => moveToPage("next")}><ArrowDown className={clsx(classes.arrow, classes.transformRight)} /></IconButton>
                                </div>
                            </>
                        }
                    </div>
                    <div id="audits rows" className={classes.auditsRowsContainer}>
                        {loading ? <CircularProgress className={classes.alignCenter} /> :
                            (<div style={{ width: "100%" }}>
                                {auditsMap?.audits?.map((audit: any) => {
                                    const { _id, source, action, timestamp, unit, site } = audit;
                                    const siteObject = getSite(site);
                                    const timezone = siteObject?.timezone || '';
                                    const siteName = siteObject?.name || '-';
                                    const unitName = getUnitName(unit);
                                    const formatedDate = timezone ? moment(timestamp).tz(timezone).format(`${dateFormat} ${timeFormat}`) : moment(timestamp).format(`${dateFormat} ${timeFormat}`);
                                    return (
                                        <div key={`audit-row-${_id}`} className={classes.auditRow} onClick={() => setSelectedAudit(audit)}                                        >
                                            <div className={classes.auditSource}>
                                                {<AuditSource source={source || ''} />}
                                            </div>
                                            <div className={classes.auditContainer}>
                                                <div className={classes.auditCard}>
                                                    <Typography className={classes.auditAction}>
                                                        {AuditActions[action] || action}
                                                    </Typography>
                                                    <div className={classes.auditDateContainer}>
                                                        <Typography className={classes.auditDate}>
                                                            {formatedDate}
                                                        </Typography>
                                                        <IconButton>
                                                            <ArrowDown className={clsx(classes.arrow, classes.transformRight)} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                                <Typography className={classes.auditDetails}>
                                                    {`${unitName ? unitName + "," : ""} ${siteName !== "-" ? siteName : ""}`}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                            )
                        }
                    </div>
                    <Button
                        disabled={loading || !dateRange || !canShareAuditByEmail}
                        variant="contained"
                        className={classes.shareButton}
                        startIcon={<Reply style={{ transform: "rotateY(180deg)", marginTop: "-3px" }} />}
                        onClick={() => setIsEmailDialogOpen(true)}
                    >
                        {t`Share Report`}
                    </Button>
                </div>
            </div>
            {opendatePicker && <DatePicker close={() => setOpenDatePicker(false)} />}
            {openFilters &&
                <Lookup
                    filtersList={filterValues}
                    appliedFilters={selectedFilters}
                    onApply={onApply}
                    onClose={() => setOpenedFilters(false)}
                    getIcon={(filterName: string, option: string) => filterName === "source" && <span style={{ width: 35 }}>{<AuditSource source={option || ''} />}</span>}
                    getName={getFilterName}
                    title={t`Audit Log Filters`}
                />
            }
            <EmailDialog
                open={isEmailDialogOpen}
                onClose={() => setIsEmailDialogOpen(false)}
                setEmails={setEmails}
                emails={emails}
                shareAuditsByEmail={shareAuditsByEmail}
            />
            {selectedAudit && <AuditInfo
                audit={selectedAudit}
                auditActors={auditsFilters?.auditActors}
                close={() => setSelectedAudit(null)} />}
            {!!error && <ErrorBox error={error} onClose={() => setError(null)} />}
        </>
    );
};

export default MobileAudits;
