import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  //
  dataPoints: {
  },
  dataPoints__section: {
    borderBottom: '1px solid #D6C8D4',
  },
  dataPoints__section__instructions: {
    padding: '16px 37px',
    color: '#828282',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
  },
  dataPoints__list: {},
  dataPoints__listItem: {
    padding: '16px 37px',
    cursor: 'pointer',
    fontSize: '14px',
    //fontWeight: '500',
    lineHeight: '16.41px',
    color: '#545964',
    transition: '0.2s all',
    '&:hover': {
      backgroundColor: '#D6C8D4',
      color: '#4B1C4C',
    },
  },
  customAutocompleteField: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '1px 9px',
    },
  },  
  customTextField: {
    '& .MuiInputBase-input': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.06px',
      backgroundColor: 'transparent',
      padding: '10px 15px',
      borderRadius: '4px',
      color: '#828282',
    },
  },
}));

export default useStyles;
