import React from 'react';
import PropTypes from 'prop-types';

const PM1Sensor = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12.0546" cy="5.64364" r="0.736411" fill="white" />
		<circle cx="12.0546" cy="8.87252" r="0.736411" fill="white" />
		<circle cx="12.0546" cy="12.3192" r="0.736411" fill="white" />
		<circle cx="10.0755" cy="4.03292" r="0.736411" fill="white" />
		<circle cx="8.10793" cy="5.64364" r="0.736411" fill="white" />
		<circle cx="8.32571" cy="8.87252" r="0.736411" fill="white" />
		<circle cx="15.8288" cy="5.64364" r="0.736411" fill="white" />
		<circle cx="15.611" cy="8.87252" r="0.736411" fill="white" />
		<circle cx="10.0755" cy="13.884" r="0.736411" fill="white" />
		<circle cx="8.10793" cy="12.3192" r="0.736411" fill="white" />
		<circle cx="15.8288" cy="12.3192" r="0.736411" fill="white" />
		<circle cx="5.97842" cy="8.90096" r="0.736411" fill="white" />
		<circle cx="18.0216" cy="8.90096" r="0.736411" fill="white" />
		<circle cx="13.8957" cy="4.03292" r="0.736411" fill="white" />
		<circle cx="13.8957" cy="6.79448" r="0.678879" fill="white" />
		<circle cx="10.1216" cy="6.79448" r="0.678879" fill="white" />
		<circle cx="5.7606" cy="6.79448" r="0.678879" fill="white" />
		<circle cx="18.2394" cy="6.79448" r="0.678879" fill="white" />
		<circle cx="5.7606" cy="11.0074" r="0.678879" fill="white" />
		<circle cx="10.0755" cy="11.0074" r="0.678879" fill="white" />
		<circle cx="13.9532" cy="11.0074" r="0.678879" fill="white" />
		<circle cx="18.2394" cy="11.0074" r="0.678879" fill="white" />
		<circle cx="13.8957" cy="13.884" r="0.736411" fill="white" />
		<path d="M6.9125 19.6688H5.58291V19.1322H6.9125C7.16999 19.1322 7.37848 19.0912 7.53799 19.0092C7.69749 18.9271 7.8137 18.8132 7.88662 18.6674C7.96182 18.5215 7.99941 18.3552 7.99941 18.1683C7.99941 17.9974 7.96182 17.8368 7.88662 17.6864C7.8137 17.536 7.69749 17.4152 7.53799 17.3241C7.37848 17.2307 7.16999 17.184 6.9125 17.184H5.73672V21.6205H5.07705V16.6439H6.9125C7.28848 16.6439 7.60635 16.7089 7.86611 16.8387C8.12588 16.9686 8.32298 17.1486 8.45742 17.3788C8.59186 17.6066 8.65908 17.8676 8.65908 18.1615C8.65908 18.4805 8.59186 18.7528 8.45742 18.9784C8.32298 19.204 8.12588 19.376 7.86611 19.4945C7.60635 19.6107 7.28848 19.6688 6.9125 19.6688ZM9.30156 16.6439H9.94072L11.5711 20.701L13.198 16.6439H13.8406L11.8172 21.6205H11.3182L9.30156 16.6439ZM9.09307 16.6439H9.65703L9.74932 19.6791V21.6205H9.09307V16.6439ZM13.4817 16.6439H14.0457V21.6205H13.3895V19.6791L13.4817 16.6439ZM18.0548 16.6166V21.6205H17.4225V17.4061L16.1476 17.871V17.3002L17.9557 16.6166H18.0548Z" fill="white" />
	</svg>
);

PM1Sensor.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

PM1Sensor.defaultProps = {
	width: 24,
	height: 25
};

export default PM1Sensor;