import {
  Collapse,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { Check, ChevronRight, Close, Edit } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { TopBar } from "../../components";
import Button from "../../cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import pageStyles from "./editSystems.style";

const EditDetectedSystems = (props: any) => {
  const [dataByLine, setDataByLine] = useState<any>({});
  const [selectedLine, setSelectedLine] = useState<any>(null);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [selectedUnitName, setSelectedUnitName] = useState<any>(null);
  const [selectedSite, setSelectedSite] = useState<any>("");

  const { history, match: { params: { deviceId = "" } = {} } = {} } = props;

  const useStyles = makeStyles(pageStyles);
  const classes = useStyles();
  const types = useStoreState((state) => state.types);
  const unitTypesMirrror = useStoreState((state) => state.unitTypesMirrror);

  const getDeviceTree = useStoreActions((actions) => actions.getDeviceTree);

  const updateUnitSystem = useStoreActions((action) => action.units.updateUnitSystem);
  const updateUnitAction = useStoreActions((action) => action.units.updateUnit);
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  useEffect(() => {
    if (!deviceId) {
      history.push("/");
    }
    getDeviceTree({ id: deviceId }).then((device: any) => {
      let data: { [key: string]: { units: { [key: string]: any }; systems: { [key: string]: any }; isDaikinLine: boolean } } = {};
      const deviceUnits = device.units;
      const deviceSystems = device.systems;
      if (!_.isEmpty(deviceUnits)) {
        const deviceUnitsArr = Object.values(deviceUnits);
        for (let unitIdx in deviceUnitsArr) {
          const unit: any = deviceUnitsArr[unitIdx];
          const unitLine = unit.line;

          if (_.has(data, unitLine)) {
            data[unitLine].units[unit.id] = unit;
          } else {
            data[unitLine] = {
              units: { [unit.id]: unit },
              systems: {},
              isDaikinLine: false
            };
          }
        }
      }

      if (!_.isEmpty(deviceSystems)) {
        const deviceSystemsArr = Object.values(deviceSystems);
        for (let sysIdx in deviceSystemsArr) {
          const sys: any = deviceSystemsArr[sysIdx];
          const sysOption: any = {
            value: sys.id,
            label: sys.name,
            brand: sys.brand
          };
          const sysLine = sys.line;

          if (_.has(data, sysLine)) {
            data[sysLine].systems[sys.id] = sysOption;
          } else {
            //@ts-ignore
            data[sysLine] = { systems: { [sys.id]: sysOption } };
          }

          if (sysOption.brand === "DAIKIN_D3NET") {
            data[sysLine] = { ...data[sysLine], isDaikinLine: true };
          }
        }
      }
      setDataByLine(data);
      setSelectedSite(device.site);
    });
  }, [deviceId, getDeviceTree, history]);

  const selectLine = (key: any) => {
    if (selectedLine === key) {
      setSelectedLine(null);
    } else {
      setSelectedLine(key);
    }
  };

  const updateSystem = (
    unitId: string,
    oldSystem: string,
    newSystem: string,
    lineNumber: any
  ) => {
    updateUnitSystem({ unitId, oldSystem, newSystem })
      .then(() => {
        let dataCopy = { ...dataByLine };
        dataCopy[lineNumber].units[unitId].system = newSystem;
        setDataByLine(dataCopy);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  };

  const changeEditName = (lineNumber: any, unitId?: string) => {
    if (!lineNumber || !unitId) {
      setSelectedUnitName("");
      setSelectedUnit(null);
      return;
    }
    setSelectedUnitName(dataByLine[lineNumber].units[unitId].name);
    setSelectedUnit(unitId);
  };

  const updateNameLocally = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUnitName(event.target.value);
  };

  const updateUnit = (lineNumber: any, unitId: string, unit: any) => {
    updateUnitAction({ id: unitId, updatedData: unit })
      .then(() => {
        let copyData: any = { ...dataByLine };
        copyData[lineNumber].units[unitId] = {
          ...copyData[lineNumber].units[unitId],
          ...unit
        };
        setDataByLine(copyData);
        changeEditName(null);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  };

  const goNext = () => {
    history.push(`/site-management?selectedSite=${selectedSite}`);
  };

  return (
    <div className={classes.screenContainer}>
      <div className={classes.pageContainer}>
        <TopBar
          title={t`Found Systems and Units`}
          leftIconComponent={undefined}
          hideRightComponent
        />
        <div className={classes.pageContent}>
          {Object.keys(dataByLine).map((lineNumber: any) => {
            const units = Object.values(dataByLine[lineNumber].units);
            const systems = dataByLine[lineNumber].systems;
            systems["none"] = { value: "none", label: "None" };
            const systemsArr = Object.values(systems);

            return (
              <div key={`line-${lineNumber}`} className={classes.lineContainer}>
                <div
                  onClick={() => selectLine(lineNumber)}
                  className={classes.collapseHeader}
                >
                  <Typography
                    className={classes.normalText}
                  >{`Line ${lineNumber}`}</Typography>{" "}
                  <ChevronRight className={clsx(classes.arrow, { [classes.openArrow]: lineNumber === selectedLine })} />

                </div>
                <Collapse in={lineNumber === selectedLine}>
                  {units.map((unit: any) => {
                    const {
                      id: unitId,
                      name: unitName,
                      type: unitType,
                      system: unitSystem
                    } = unit;

                    const isSelected = selectedUnit === unitId;

                    return (
                      <div
                        key={`line-${lineNumber}--unit-${unitId}`}
                        className={classes.unitContainer}
                      >
                        <div className={classes.detailsRow}>
                          <Typography
                            className={classes.labelText}
                          >{t`Unit Name`}</Typography>
                          <TextField
                            variant={"outlined"}
                            disabled={!isSelected}
                            value={isSelected ? selectedUnitName : unitName}
                            onChange={updateNameLocally}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {isSelected ? (
                                    <>
                                      <Check
                                        className={classes.iconStyle}
                                        onClick={() =>
                                          updateUnit(lineNumber, unitId, {
                                            name: selectedUnitName
                                          })
                                        }
                                      />
                                      <Close
                                        className={clsx(
                                          classes.iconStyle,
                                          classes.cancelIcon
                                        )}
                                        onClick={() => changeEditName(null)}
                                      />
                                    </>
                                  ) : (
                                    <Edit
                                      className={classes.iconStyle}
                                      onClick={() =>
                                        changeEditName(lineNumber, unitId)
                                      }
                                    />
                                  )}
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                        <div className={classes.detailsRow}>
                          <Typography
                            className={classes.labelText}
                          >{t`Unit Type`}</Typography>
                          <Typography
                            className={classes.normalText}
                          >{unitTypesMirrror[unitType] + t` Unit`}</Typography>
                        </div>

                        <div className={classes.detailsRow}>
                          <Typography
                            className={classes.labelText}
                          >{t`Unit System`}</Typography>
                          <Select
                            value={systems[unitSystem]?.value || systems["none"]?.value}
                            variant="outlined"
                            className={classes.selectStyle}
                            placeholder={t`Unit Systems`}
                            MenuProps={{ classes: { paper: classes.menuItemsContainer } }}
                            onChange={(event: any) => {
                              const newsystem = event.target.value === "none" ? null : event.target.value;
                              updateSystem(
                                unitId,
                                unitSystem,
                                newsystem,
                                lineNumber
                              );
                            }
                            }
                          >
                            {!!systemsArr.length ?
                              systemsArr.map((system: any) => (
                                <MenuItem value={system.value} key={system.value}>{system.label}</MenuItem>
                              ))
                              : <MenuItem key={`no-systems-option`} disabled value={""}>
                                {t`No Systmes Options`}
                              </MenuItem>}
                          </Select>
                        </div>
                      </div>
                    );
                  })}
                </Collapse>
              </div>
            );
          })}
          <Button className={classes.nextBtn} onClick={goNext}>
            {t`Next`}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditDetectedSystems;
