import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  // Dialog styles
  dialogTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18.75px',
    textAlign: 'left',
    padding: '19px 24px',
    backgroundColor: '#4B1C46',
    borderBottom: '1px solid #D5D2D5',
    color: theme.palette.common.white,
    '& h2': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '18.75px',
      margin: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#fff',
  },
  dialogContent: {
    padding: '30px',
    width: '400px',
  },
  dialogActions: {
    borderTop: '1px solid #e0e0e0',
    padding: '16px 24px',
    backgroundColor: '#F6F5F6',
  },
}));

export default useStyles;