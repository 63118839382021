import {t} from 'ttag';

const isRequired = (value) => {
	if(Array.isArray(value)){
		return !value.length;
	}
	return !value;
};

const isNumbersOnly = (value) => {
	return isNaN(value);
};

const isLettersOnly = (value) => {
	return value.split('').some(char => !isNaN(char));
};

const isEmail = (value) => {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return !regex.test(value);
};

const commonErrorMessages = {
	required:t`This Field is Required`,
	numbersOnly:t`Please use only numbers`,
	lettersOnly:t`Please use only letters, no numbers allowed`,
	email:t`Please enter a valid Email address`
};

export const commonValidators = {
	required:{validator:isRequired, message:commonErrorMessages.required},
	numbersOnly:{validator:isNumbersOnly, message:commonErrorMessages.numbersOnly},
	lettersOnly:{validator:isLettersOnly,message:commonErrorMessages.lettersOnly},
	email:{validator:isEmail,message:commonErrorMessages.email}
};



