import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  view: {
    display: 'flex',
    height: '100%',
    overflowX: 'hidden'
  },
  contentArea: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'rgb(236, 235, 239)'
  },
  mainContainer: {
    padding: '0 16px 16px 16px',
    height: 'calc(100% - 140px)'
  },
  paper: {
    background: '#FFF',
    display: 'flex',
    height: '100%'
  },
  topContainer: {
    padding: '0 16px'
  },
  reportTypeSelect: {
    width: '250px',
    margin: '16px'
  },
  iframeContent: {
    width: '100%'
  }
}));

export default useStyles;
