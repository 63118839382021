import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  view: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgb(236, 235, 239)',
    maxHeight: '-webkit-fill-available',
    width: 'calc(100% - 256px)',
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    '& > p': {
      marginRight: 'auto',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '18.75px',
      color: '#4B1C46'
    }
  },
  tableContainer: {
    display: 'flex',
  },
  paperTableContainer: {
    boxSizing: 'border-box',
    margin: '1rem',
    marginTop: '1em',
    overflow: 'auto',
    height: '100%',
    padding: '1rem',
    maxHeight: 'calc(100% - 130px)',
    display: 'flex',
    flexFlow: 'column nowrap',
    minHeight: '250px',
  },
  table: {
    background: theme.palette.background.paper,
  },
  overWritePadding: {
    ...theme.tables.tableCell,
    padding: '0 15px',
    height: '35px',
  },
  tableHeadCell: {
    padding: '0 15px',
    height: 45,
    ...theme.tables.headerCell,
  },
  editIcon: {
    marginLeft: '-6px',
  },
  overWriteIcon: {
    '&:hover': {
      borderRadius: '0',
    },
  },
  deleteIcon: {
    fontSize: '1rem',
    cursor: 'pointer',
    color: 'initial',
  },
  anomaliesContainer: {
    height: '82%',
    display: 'flex',
    paddingBottom: '1rem',
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 16px',
    minHeight: '70px',
  },
  title: {
    height: '18px',
    width: '72px',
    color: '#312e34',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  '[class^="NavigationBar"]': {
    padding: '0',
  },
  smallWidth: { width: '20%', wordBreak: 'break-word' },
  mediumWidth: { width: '60%', wordBreak: 'break-word' },
  headerButton: {
    minHeight: '40px',
    maxHeight: '40px',
    minWidth: '169px',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: '#fff',
    textTransform: 'capitalize',
  },
  iconColor: {
    color: '#AAA2AA',
  },
  disabledIcon: {
    color: '#d5d0d5',
  },
  headerButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 16px',
    minHeight: '70px',
  },
  grayOval: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '136px',
    height: '136px',
    backgroundColor: 'rgba(170, 162, 170, 0.1)',
    borderRadius: '50%',
  },
  arrowIcon: { fontSize: '91px', transform: 'rotate(90deg)' },
  noUnitText: {
    fontSize: '26px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#545964',
    marginTop: '43px',
  },
  noContentContainer: {
    boxShadow: '0 2px 5px -5px rgba(180, 175, 203, 0.63)',
    backgroundColor: '#fefefe',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    height: '100%',
    alignSelf: 'center',
  },
  pageContentBox: {
    maxHeight: '100%',
    overflow: 'auto',
    maxWidth: 'calc(100vw - 240px)',
    display: 'flex',
    padding: '16px',
    height: '100%',
  },
  dialogStyle: {
    padding: '30px 26px 27px 26px',
    minWidth: '400px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogPaper: {
    width: 'fit-content',
    maxWidth: '95% !important',
    minWidth: '850px',
    height: 'fit-content',
    minHeight: '200px',
    maxHeight: 'calc(100vh - 140px)',
    borderRadius: '8px',
    boxShadow: '0 2px 11px 0 rgba(0, 0, 0, 0.15)',
    border: 'solid 1px #d5d2d5',
    backgroundColor: '#ffffff',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  dialogContent: {
    padding: '15px 20px 0px 20px',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: 1,
  },
  contentStyle: {
    paddingBottom: '22px',
    color: '#545964',
    fontWeight: 'normal',
    lineHeight: 'normal',
    fontSize: '18px',
    fontFamily: 'Roboto',
    textAlign: 'center',
    maxWidth: '100%',
    wordBreak: 'break-word',
  },
  textNotCentered: {
    textAlign: 'unset',
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  actionsContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: '20px',
    paddingTop: '10px',
    width: '100%',
    bottom: 0,
    left: 0,
    position: 'sticky',
    zIndex: 3,
  },
  boxContent: { overflow: 'auto', padding: '13px 10px', maxHeight: '368px' },
}));
export default useStyles;
