import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme)=>({
	clickable:{
		color: theme.overrides.MuiButton.outlinedPrimary.color,
		cursor:'pointer',
		fontWeight:'bolder',
	}
}));

export default useStyles;
