import React from "react";

function MobileAbout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g fill="#AAA2AA" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M17 5c6.627 0 12 5.373 12 12s-5.373 12-12 12S5 23.627 5 17 10.373 5 17 5zm0 1.6C11.256 6.6 6.6 11.256 6.6 17c0 5.744 4.656 10.4 10.4 10.4 5.744 0 10.4-4.656 10.4-10.4 0-5.744-4.656-10.4-10.4-10.4z"
        ></path>
        <path d="M15.878 15.317a1.123 1.123 0 112.244 0v7.296a1.122 1.122 0 11-2.244 0v-7.296zM17 12.509a1.122 1.122 0 11.002.001H17v-.001z"></path>
      </g>
    </svg>
  );
}

export default MobileAbout;