import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListIcon from '@icons/ListIcon';
import useStyles from './styles';

const ListControl = ({ mode, value, onChange, options, isDisabled }) => {
  const classes = useStyles();
  
  if (mode === 'edit') {
    return (
      <div className={classes.root}>
        <ListIcon />
      </div>
    );
  }

  return (
    <div>
      <Select
        disabled={isDisabled}
        value={value}
        variant="outlined"
        fullWidth
        className={classes.customSelectField}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((entry) => (
          <MenuItem key={entry.value} value={entry.value}>
            {entry.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ListControl;
