import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: "1rem",
      height: "100%",
      paddingTop: 0
    },

    header: {
      display: "flex",
      alignItems: "center",
      paddingBottom: "4.41px",
      justifyContent: "space-between"
    },
    table: {},

    tableWrapper: {
      height: "470px",
      overflowX: "auto"
    },

    "@media (max-width: 1600px)": {
      tableWrapper: {
        height: "550px"
      }
    },

    tableHeadStyle: {
      borderBottom: "none",
      // padding: "16px 15px !important",
      fontSize: "13px",
      padding: 0,
      color: "#79797d",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      backgroundColor: "#efeff2",
      // height: 56
      height: "45px"
    },

    tableCell: {
      fontSize: "0.8125rem !important",
      // padding: "0.2rem 0.2rem 0.2rem 0",
      padding: 0,
      textAlign: "center",
      // height: "3rem",
      height: "45px",
      "&:last-child": {
        paddingRight: "20px"
      }
    },

    tableCellAlignLeft: {
      fontSize: "0.8125rem !important",
      padding: "0 10px",
      paddingRight: "15px",
      textAlign: "left",
      width: "1%",
      whiteSpace: "nowrap"
    },
    nameCell: {
      fontSize: "0.8125rem !important",
      padding: "0 10px",
      paddingRight: "15px",
      textAlign: "left",
      width: "25ch",
      minWidth: "25ch",
      maxWidth: "25ch",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },

    "tableCell:last-child": {
      paddingRight: "1rem"
    },

    "tableCell:nth-last-child(2)": {
      paddingRight: "2rem"
    },

    tableRowUnplottable: {
      backgroundColor: "rgb(249, 249, 249)"
    },

    title: {
      padding: "0.5rem 0",
      color: "#4b1c46"
    },

    lastUpdatedTitle: {
      fontSize: "0.7rem",
      color: "#aaa2aa",
      marginRight: "5px"
    },

    thumbValue: {
      color: "rgb(75, 28, 70)",
      marginTop: "-5px",
      fontSize: "1.3rem"
    },

    thumbSelection: {
      color: "rgb(240, 81, 70)",
      marginTop: "-2px",
      fontSize: "1.7rem",
      opacity: 0.7
    },

    autoRefreshLabel: {
      fontSize: "0.85rem"
    },
    autoUpdate: {
      minWidth: "8rem"
    },
    removePaddingRight: {
      paddingRight: "5px"
    },
    grayCheckbox: {
      "&$checked": {
        color: "#736973"
      }
    },
    popper: {
      marginBottom: "-20px"
    },
    dialogTitle: {
      ...theme.dialogs.header,
      display: "flex",
      justifyContent: "space-between",
      padding: "12px",
      paddingRight: "5px",
      height: "60px",
      alignItems: "center",
      width: "350px"
    },
    addIcon: {
      color: "#aaa2aa",
      marginLeft: "3px"
    },
    pushedAddIcon: {
      background: "#EFEFF2"
    },
    paramsPopupButton: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "15px"
    },
    paramsPopupList: {
      width: "350px",
      overflow: "auto",
      height: "400px"
    },
    parametersHeader: {
      width: "50%",
      display: "flex",
      alignItems: "center"
    },
    autoUpdateContainer: {
      display: "flex",
      alignItems: "center"
    },
    smallCell: {
      maxWidth: "80px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    moveCursor: { cursor: "move !important" },
    yesBtn: {
      marginRight: "17px"
    },
    dialogActions: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "1rem"
    },
    titleContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #d5d2d5",
      padding: "16px 9px 16px 24px",
      color: "#29132e"
    },
    tableCellValue: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "4rem"
    },
    iconBtnStyle: {
      width: 30,
      height: 30,
      borderRadius: 6,
      padding: 0
    }
  })
);

export default useStyles;
