import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {t} from 'ttag';
import clsx from 'clsx';

import DateRangePicker from '../../../../../../../../../cool_widgets/DateRangePicker';

import classes from './ImmediateReportRangePicker.module.css';

//todo: add jsdoc
const ImmediateReportRangePicker = ({onChange,disabled,dateRange, error}) => {


	const handleDateChange = (dateRange) => {
		onChange(dateRange);
	};


	return (
		<div className={classes.container}>
			<div className={clsx(classes.wrapper,{[classes.errorDatePicker]:error})}>

				<Typography className={classes.label}>{t`Select Report Dates`}</Typography>

				<DateRangePicker 
					disabled={disabled}
					date={dateRange}
					setDate={handleDateChange}
					className={clsx(classes.datePicker,{[classes.errorDatePicker]:error})}
				/>
	

			</div>
			<Typography className={classes.error}>{error}</Typography>
		</div>
	);
};

ImmediateReportRangePicker.propTypes = {
	onChange:PropTypes.func.isRequired,
	dateRange:PropTypes.object,
	disabled:PropTypes.bool,
	checked:PropTypes.bool,
	error:PropTypes.string
};

ImmediateReportRangePicker.defaultProps = {
	disabled:false,
	checked:true,
	dateRange:null,
	error:''
};

export default ImmediateReportRangePicker;