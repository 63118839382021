import { alpha, createStyles } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: 60,
      height: 30,
      padding: 6
    },
    disabled: {},
    switchBase: {
      left: 6,
      padding: 1,
      color: theme.palette.colors?.switch?.color || "#D8D8D8",
      "&$checked": {
        left: 0,
        transform: "translateX(26px)",
        color: theme.palette.colors?.switch?.colorChecked || "#f05146",
        "& + $track": {
          backgroundColor: alpha(theme.palette.colors?.switch?.colorChecked ||  "#f05146", 0.25),
          border: "none",
          opacity: 1
        }
      },
      "&$focusVisible $thumb": {
        color: theme.palette.colors?.switch?.colorChecked || "#f05146"
      },
      "&$disabled": {
        color: alpha(theme.palette.colors?.switch?.color || "#D8D8D8", 0.6),
        "& + $track": {
          opacity: 0.5,
          backgroundColor: alpha(theme.palette.colors?.switch?.color || "#D8D8D8", 0.25)
        }
      }
    },
    thumb: {
      width: 28,
      height: 28
    },
    track: {
      borderRadius: 50 / 2,
      backgroundColor: alpha("#fdfdfe", 0.25),
      opacity: 1
    },
    checked: {},
    focusVisible: {},
    switchDisabled: {
      opacity: 0.5,
      "& span": {
        cursor: "default"
      }
    }
  });
