import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: 0,
    minWidth: "100%",
    zIndex: 999,
    height: "100%",
    minHeight: "100%",
    maxHeight: "100%",
    flexFlow: "column nowrap",
    position: "absolute",
    top: 0,
    margin: 0,
    borderRadius: 0,
    paddingBottom: 20,
    justifyContent: "space-between",
    background: "white"
  },
  titleContainer: {
    display: "flex", alignItems: "end", minHeight: "70px",
    borderBottom: "solid 1px #e5e2e5",
    padding: "0 20px",
    justifyContent: "space-between"
  },
  pageTitle: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 600,
    color: "#4b1c46",
    padding: "10px 0"
  },
  searchBox: {
    height: 52,
    width: "100%",
    margin: "20px 0",
    padding: "0 15px"
  },
  searchIcon: {
    transform: "scale(0.8695)"
  },
  inputRoot: {
    width: "100%",
    paddingLeft: 9,
    paddingRight: 4,
    height: "100%",
    borderRadius: "4px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: "0 15px",
    touchAction: "manipulation",
    color: "#4b4850" //charcoal-grey
  },
  closeIconStyle: {
    width: 25,
    height: 25,
    borderRadius: 6,
    padding: 0,
    "& svg": {
      transform: "scale(0.9)"
    },
    touchAction: "manipulation"
  },
  selectAllContainer: {
    justifyContent: "end",
    display: "flex",
    padding: "0 20px"
  },
  selectAllText: {
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    color: theme.overrides.MuiButton.outlinedPrimary.color
  },
  paramsList: {
    padding: "20px",
    overflowY: "auto",
    height: "calc(100% - 180px)"
  },
  param: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "20px 10px",
    alignItems: "center",
    borderBottom: theme.overrides.MuiButton.outlined.border
  },
  paramName: {
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    color: theme.overrides.MuiButton.outlined.color,
    wordBreak: "break-all",
    width: "calc(100% - 50px)"
  },
  arrowIcon: {
    "& >path": {
      fill: "#968896"
    }
  },
  iosTopHeight: {
    paddingTop: "40px",
    minHeight: "100px"
  },
  applyBtn: { margin: "15px 0", width: "100%", alignSelf: "center", height: "50px !important" }
}));
export default useStyles;
